import React, { useState } from 'react';
import 'intro.js/introjs.css';
import { Steps } from 'intro.js-react';

function useTour() {
  const [enabled, setEnabled] = useState(false);

  const introjsOptions = {
    showBullets: false,
    showStepNumbers: false,
    keyboardNavigation: true,
    exitOnOverlayClick: true,
    hidePrev: true,
    nextLabel: 'Next',
    prevLabel: 'Back',
    doneLabel: 'OK',
    hideNext: true
  };

  const steps = [
    {
      element: '#activate-card',
      intro: 'Activate your account to start making live transactions on Korapay',
      position: 'right',
      tooltipClass: 'myTooltipClass',
      highlightClass: 'myHighlightClass'
    },
    {
      element: '#checkout-menu',
      intro: 'Payment links let you receive payments from your customers. Create them here.',
      position: 'right',
      tooltipClass: 'myTooltipClass',
      highlightClass: 'myHighlightClass'
    },
    {
      element: '#settlements-menu',
      intro: 'You can track the money we have collected for you here.',
      position: 'right',
      tooltipClass: 'myTooltipClass',
      highlightClass: 'myHighlightClass'
    },
    {
      element: '#env-toggle',
      intro: `Switch between Test mode and Live mode after you've activated your account.`,
      tooltipClass: 'myTooltipClass',
      highlightClass: 'myHighlightClass'
    }
  ];

  const onExit = () => setEnabled(false);
  const initiateTour = () => setEnabled(true);

  const StepsComponent = () => <Steps enabled={enabled} steps={steps} initialStep={0} onExit={onExit} options={introjsOptions} />;

  return [initiateTour, StepsComponent];
}

export default useTour;
