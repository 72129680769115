import React, { useRef, useState, useEffect } from 'react';
import { useDebouncedCallback } from 'use-debounce';
import caret from '+assets/img/dashboard/caret.svg';
import search from '+assets/img/dashboard/search.svg';
import { ListDropdownProps } from '+types/common'
import './ListDropdown.scss';

const ListDropdown: React.FC<ListDropdownProps> = ({
  type,
  active,
  setActive,
  setValue,
  value,
  isFetching,
  list,
  className = '',
  defaultValue = '',
  onBlur = null,
  searchDropdown = true,
  displayText = true,
  disabled = false,
  disabledItems = [],
  placeholder
}) => {
  const dropdownOverlayRef = useRef(null);
  const dropdownButtonRef = useRef(null);
  const dropdownMenuRef = useRef(null);
  const dropdownListRef = useRef(null);
  const dropdownInputRef = useRef(null);
  const closeButtonRef = useRef(null);

  const [defaultText, setDefaultText] = useState(`Select a ${type?.toLowerCase()}`);
  const [searchQuery, setSearchQuery] = useState('');
  const [isSearching, setIsSearching] = useState(false);
  const [searchResults, setSearchResults] = useState(list);

  const searchList = useDebouncedCallback((query: string, originalList: ListItem[]) => {
    if (query?.trim() === '') {
      setIsSearching(false);
      return;
    }
    setIsSearching(true);
    setSearchResults(() => {
      const newList = originalList.filter(resultItem => resultItem.name?.toLowerCase().includes(query.trim()?.toLowerCase()));
      return newList;
    });
  }, 500);

  useEffect(() => {
    const dropdownOverlay = dropdownOverlayRef.current;
    if (dropdownOverlay && active) {
      closeButtonRef.current.focus();
      return;
    }
    dropdownButtonRef.current.focus();
    setSearchQuery('');
    setIsSearching(false);
  }, [active]);

  useEffect(() => {
    if (defaultValue && defaultValue !== defaultText && value !== '') {
      setDefaultText(defaultValue);
    }
  }, [value]);

  useEffect(() => {
    if (list?.length) {
      searchList(searchQuery, list);
    }
  }, [searchQuery, list]);

  const handleKeyboard = useRef((e: KeyboardEvent) => {
    const path = (e as unknown as { path: string })?.path || (e as { composedPath?: () => EventTarget[] })?.composedPath?.() || e.target;
    // Escape button closes the dropdown overlay
    if (e.keyCode === 27) {
      setActive(false);
      return;
    }
    // Handle searching on letter click
    if (dropdownOverlayRef?.current && !path?.includes(dropdownInputRef?.current)) {
      // Handle adding text
      if (e.keyCode >= 65 && e.keyCode <= 90) {
        dropdownInputRef.current.focus();
        return;
      }
    }
    // Spacebar and Down button open the tooltip
    if (e.target === dropdownButtonRef.current && (e.keyCode === 32 || e.keyCode === 40)) {
      setActive(true);
      return;
    }
    // Trapping tabbing inside overlay body
    if (dropdownOverlayRef?.current) {
      const lastItem = dropdownListRef.current.childNodes[dropdownListRef.current.childNodes.length - 1].childNodes[0];
      // Tabbing backwards inside the overlay at the first focusable item
      if (e.shiftKey && e.keyCode === 9 && e.target === closeButtonRef.current) {
        dropdownListRef.current.focus();
        return;
      }
      // Tabbing forward inside the overlay at the last focusable item
      if (e.keyCode === 9 && path.includes(dropdownListRef?.current) && e.target === lastItem) {
        closeButtonRef.current.focus();
        return;
      }
    }
    // Keyboard Actions in Dropdown List
    if (path.includes(dropdownListRef?.current)) {
      // Spacebar on button selects the item and closes dropdown
      if (e.keyCode === 32) {
        const buttonValue = e.target.textContent;
        setValue(buttonValue);
        setActive(false);
        return;
      }
      // Up arrow key aids navigation
      if (e.keyCode === 38) {
        const currentListItem = e.target.parentNode;
        const previousOption = currentListItem?.previousSibling?.childNodes[0];
        if (!previousOption) {
          return;
        }
        previousOption.focus();
        return;
      }
      // Down arrow key aids navigation
      if (e.keyCode === 40) {
        const currentListItem = e.target.parentNode;
        const nextOption = currentListItem?.nextSibling?.childNodes[0];
        if (!nextOption) {
          return;
        }
        nextOption.focus();
      }
    }
  });

  const closeOuter = useRef(e => {
    // e.preventDefault();
    const path = e?.path || e?.composedPath() || e.target;
    // Handles clicking on the overlay on mobile & clicking outside on desktop
    if (
      (e.target === dropdownOverlayRef?.current && !path.includes(dropdownMenuRef.current)) ||
      (dropdownOverlayRef?.current && !path.includes(dropdownOverlayRef.current) && !path.includes(dropdownButtonRef.current))
    ) {
      setActive(false);
    }
  });

  useEffect(() => {
    const closeOuterFunc = closeOuter.current;
    const handleKeyboardFunc = handleKeyboard.current;

    // Clicking outside closes the Dropdown
    window.addEventListener('click', closeOuterFunc);
    window.addEventListener('keydown', handleKeyboardFunc);

    return () => {
      window.removeEventListener('click', closeOuterFunc);
      window.removeEventListener('keydown', handleKeyboardFunc);
    };
  }, []);

  const generateResult = (listItem, query) => {
    const queryIndex = listItem.toLowerCase().indexOf(query?.toLowerCase().trim());
    // If search paramater is at the beginning of the listItem string
    if (queryIndex === 0) {
      return (
        <>
          <strong>{listItem.substr(queryIndex, query.length)}</strong>
          {listItem.substr(queryIndex + query.length)}
        </>
      );
    }
    // If search paramater is at the end of the listItem string
    if (queryIndex === listItem.length - query.length) {
      return (
        <>
          {listItem.substr(0, queryIndex)}
          <strong>{listItem.substr(queryIndex, query.length)}</strong>
        </>
      );
    }
    // If search parameter is within the book string
    if (queryIndex !== -1) {
      return (
        <>
          {listItem.substr(0, queryIndex)}
          <strong>{listItem.substr(queryIndex, query.length)}</strong>
          {listItem.substr(queryIndex + query.length)}
        </>
      );
    }
    return listItem;
  };

  const searchingList = () => {
    const currentList = isSearching ? searchResults : list;
    return currentList?.length > 0 ? (
      currentList.map(resultItem => {
        return (
          <li
            key={resultItem.code}
            role="option"
            aria-selected={resultItem.code?.toLowerCase() === value.code?.toLowerCase()}
            disabled={disabledItems?.includes(resultItem.name)}
          >
            <button
              type="button"
              onClick={() => {
                setValue(resultItem);
                setActive(false);
                return onBlur
                  ? setTimeout(() => {
                    onBlur();
                  }, 500)
                  : null;
              }}
              className={resultItem.code?.toLowerCase() === value.code?.toLowerCase() ? 'active' : ''}
              disabled={disabledItems?.includes(resultItem.name)}
            >
              {searchQuery ? generateResult(resultItem?.name, searchQuery) : resultItem?.name}
            </button>
          </li>
        );
      })
    ) : (
      <li>
        <p>No results found</p>
      </li>
    );
  };

  return (
    <>
      <button
        type="button"
        onClick={() => {
          if (list?.length) {
            setActive(!active);
          }
        }}
        aria-haspopup="listbox"
        aria-disabled={list?.length === 0}
        ref={dropdownButtonRef}
        aria-expanded={active}
        disabled={disabled}
        className={`${className} list-dropdown--button ${list?.length === 0 || isFetching || disabled ? 'disabled' : ''}`}
      >
        <span>{value?.name || placeholder || defaultText}</span>
        {isFetching && <span className="sr-only">Loading</span>}
        <img src={caret} alt="caret icon" aria-hidden />
      </button>

      {active && (
        <div className="list-dropdown--overlay" ref={dropdownOverlayRef}>
          <div className="list-dropdown--menu" ref={dropdownMenuRef}>
            <button type="button" className="sr-only" ref={closeButtonRef} onClick={() => setActive(false)}>
              Close Dropdown Menu
            </button>
            {searchDropdown && (
              <div className="list-dropdown--search">
                <input
                  type="search"
                  aria-label={`Search ${type}s`}
                  placeholder={`Search ${type}s`}
                  className="list-dropdown--input"
                  ref={dropdownInputRef}
                  value={searchQuery}
                  onChange={e => setSearchQuery(e.target.value)}
                  onBlur={e => setSearchQuery(e.target.value)}
                />
                <img src={search} alt="search icon" aria-hidden />
              </div>
            )}
            <div className={`list-dropdown--header ${isSearching ? 'header--searching' : ''}`}>
              {!isSearching && displayText && (
                <button
                  type="button"
                  onClick={() => {
                    setValue('all');
                    setActive(false);
                  }}
                >
                  All {type}s
                </button>
              )}
            </div>
            <p className="sr-only" id="dropdown--list">
              {type}s list.
            </p>
            <ul className="list-dropdown--list" role="listbox" aria-label="dropdown-list" tabIndex={0} ref={dropdownListRef}>
              {searchingList()}
            </ul>
          </div>
        </div>
      )}
    </>
  );
}

export default ListDropdown;
