import { useQueries } from 'react-query';
import { Link, Redirect, Route, Switch, useLocation } from 'react-router-dom';

import { useRolesSettings } from '+hooks';
import APIRequest from '+services/api-services';

import Scrollable from '../../Shared/Scrollable';
import ApiIntegrations from './ApiIntegrations';
import Appearance from './Appearance';
import EmailConfiguration from './EmailConfiguration';
import General from './General';
import Limits from './Limits';
import Merchant from './Merchant';
import PaymentPreferences from './PaymentPreferences';
import Security from './Security';
import SettlementsSettings from './Settlements';
import Team from './Teams';

const matchSettingsComponents = {
  settlement_setting: ['settings/settlements', null, SettlementsSettings],
  api_configuration: ['settings/api-integrations', null, ApiIntegrations],
  security: ['settings/security', null, Security],
  compliance: ['settings/compliance', null, Merchant],
  team: ['settings/teams', null, Team],
  limits: ['settings/limits', null, Limits],
  bank_transfer_setting: ['settings/payment', null, PaymentPreferences],
  checkout_customization: ['settings/appearance', null, Appearance],
  ip_address_configuration: ['settings/email-configuration', null, EmailConfiguration] // This was a make shift to display the tab, this should be updated after permissions have been added for email configuration
};
const api = new APIRequest();

function SettingsComponent() {
  const location = useLocation();
  const [routes, router] = useRolesSettings(matchSettingsComponents);

  useQueries([
    { queryKey: 'BANKS', queryFn: () => api.fetchBanks() },
    { queryKey: 'CURRENCIES', queryFn: api.fetchCurrencies }
  ]);
  return (
    <div className="os-tabs-w">
      <div className="os-tabs-controls os-tabs-complex settings-tab">
        <ul className="nav nav-tabs">
          <Scrollable>
            <li className="nav-item">
              <Link
                aria-expanded="false"
                className={location.pathname === '/dashboard/settings/general' ? 'nav-link active' : 'nav-link'}
                data-toggle="tab"
                to="/dashboard/settings/general"
              >
                <span className="tab-label">General</span>
              </Link>
            </li>
            {routes.settings.map(route => (route.key.includes('compliance') ? route : ''))}
            {routes.settings.map(route => (route.key.includes('settlements') ? route : ''))}
            {routes.settings.map(route => (route.key.includes('api-integrations') ? route : ''))}
            {routes.settings.map(route => (route.key.includes('teams') ? route : ''))}
            {routes.settings.map(route => (route.key.includes('security') ? route : ''))}
            {routes.settings.map(route => (route.key.includes('payment') ? route : ''))}
            {routes.settings.map(route => (route.key.includes('appearance') ? route : ''))}
            {routes.settings.map(route => (route.key.includes('limits') ? route : ''))}
            {routes.settings.map(route => (route.key.includes('email-configuration') ? route : ''))}
          </Scrollable>
        </ul>
      </div>
      <div>
        <Switch>
          <Route path="/dashboard/settings/general" component={General} />
          {router.map(route => route)}
          {router.length > 0 && (
            <>
              <Redirect path="/dashboard/settings" to="/dashboard/settings/general" />
              <Redirect path="/dashboard/settings/profile" to="/dashboard/settings/general" />
              <Redirect path="/dashboard/settings/business" to="/dashboard/settings/compliance" />
            </>
          )}
        </Switch>
      </div>
    </div>
  );
}

export default SettingsComponent;
