import { useState } from 'react';

import EmptyStateComponent from '+containers/Dashboard/Shared/EmptyState';

import { getIssuingPermissions } from '../utils';
import AccessRequestModal from './AccessRequestModal';

interface ISingleCardAccessRequest {
  activeTab: 'reserved_cards' | 'customer_cards';
  accessStatus: 'pending' | 'inactive' | 'active';
}

const AccessRequestPostOnboarding = ({ accessStatus, activeTab }: ISingleCardAccessRequest) => {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [stage, setStage] = useState<'RESERVED_CARDS' | 'CUSTOMER_CARDS'>('RESERVED_CARDS');
  const { canRequestCardAccess } = getIssuingPermissions();

  const handleClick = () => {
    if (activeTab === 'reserved_cards') setStage('RESERVED_CARDS');
    if (activeTab === 'customer_cards') setStage('CUSTOMER_CARDS');
    setModalIsOpen(true);
  };

  const emptyState = (() => {
    switch (activeTab) {
      case 'reserved_cards':
        return {
          heading: 'No reserved cards yet',
          message: 'Create, issue and manage USD virtual cards for your business'
        };
      case 'customer_cards':
      default:
        return {
          heading: 'No customer cards yet',
          message: 'Create, issue and manage USD virtual cards for your customers'
        };
    }
  })();

  const content = (() => {
    switch (accessStatus) {
      case 'pending':
        return <p>Your request has been received and is being reviewed. We will contact you shortly.</p>;

      case 'inactive':
        return (
          <button
            type="button"
            className="btn flex"
            style={{ backgroundColor: 'hsla(0, 0%, 100%, 1)', color: 'hsla(216, 90%, 55%, 1)', fontWeight: 500 }}
            onClick={handleClick}
          >
            <svg xmlns="http://www.w3.org/2000/svg" width={18} height={17} fill="none" viewBox="0 0 25 24">
              <path
                stroke="hsla(216, 90%, 55%, 1)"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="m22.5 2-11 11M22.5 2l-7 20-4-9-9-4 20-7Z"
              />
            </svg>
            Request Access
          </button>
        );

      default:
        return null;
    }
  })();

  return (
    <>
      <div className="flex column reset-margin" style={{ height: '60dvh' }}>
        <EmptyStateComponent heading={emptyState.heading} message={emptyState.message} />{' '}
        <div className="content-btn-wrapper" data-comp="card-request-cta">
          {canRequestCardAccess ? content : null}
        </div>
      </div>
      {modalIsOpen ? <AccessRequestModal onClose={() => setModalIsOpen(false)} gotoStage={stage} /> : null}
    </>
  );
};

export default AccessRequestPostOnboarding;
