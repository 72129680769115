import { useEffect, useState } from 'react';
import Copyable from '../../../Shared/Copyable';
import pendingCircles from '+assets/img/dashboard/pending-circles.gif';

import { formatAmount } from '+utils';
import '../index.scss';

const switchReversalReason = {
  underpayment: 'Underpayment',
  overpayment: 'Overpayment'
};

const ReversalsTab = ({ data }) => {
  const { currency, payment_reversals: reversals } = data || {};

  const [item, setItem] = useState([]);
  const [activeIndex, setActiveIndex] = useState(0);

  const Reversals = reversals?.filter(({ type }) => type === 'underpayment' || type === 'overpayment');
  const count = Reversals?.length;

  useEffect(() => {
    if (Reversals) setItem([Reversals[activeIndex]]);
  }, [JSON.stringify(Reversals)]);

  const switchRefundStatus = {
    success: { name: 'Successful', color: '#24B314', icon: '✓' },
    manual: { name: 'Manual', color: '#24B314', icon: '✓' },
    pending: {
      name: 'Processing...',
      color: 'rgba(16,38,73,.4)',
      icon: <img src={pendingCircles} alt="" id="pending-circles" />
    },
    processing: {
      name: 'Processing...',
      color: 'rgba(16,38,73,.4)',
      icon: <img src={pendingCircles} alt="" id="pending-circles" />
    },
    failed: { name: 'Failed', color: '#F32345', icon: '✗' }
  };

  return (
    <div>
      {Reversals?.length > 0 ? (
        <ul>
          <li id="payment-detail">
            <div style={{ width: '40%' }}>
              <p className="reversal-count">{`${count} ${count > 1 ? 'Reversals' : 'Reversal'} found`}</p>
              {Reversals.map((reversal, index) => {
                return (
                  <div
                    className="--container"
                    key={reversal.reference}
                    onClick={() => {
                      setItem([reversal]);
                      setActiveIndex(index);
                    }}
                    role="button"
                    tabIndex={0}
                  >
                    <p className={`reversal-amount ${activeIndex === index && 'active'}`}>
                      <label className="">Reversal of</label>
                      {`${formatAmount(reversal.amount || 0)} ${currency}`}
                    </p>
                  </div>
                );
              })}
            </div>
            <div className="customer-reversal-info" style={{ width: '60%' }}>
              <ul className="">
                <li>
                  <p>Reason for Reversal</p>
                  <p>{switchReversalReason[item[0]?.type]}</p>
                </li>
                <li>
                  <p>Reversal Destination</p>
                  <p>{item[0]?.destination === 'disbursement_wallet' ? `Balance` : 'Customer'}</p>
                </li>
                <li>
                  <p>Reversal Status</p>
                  <p
                    style={{
                      color: switchRefundStatus[item[0]?.status]?.color || ''
                    }}
                  >
                    {switchRefundStatus[item[0]?.status]?.name}
                  </p>
                </li>
                <li>
                  <p>Reference</p>
                  <Copyable text={item[0]?.reference} spanClassName={'reversal_ref'} />
                </li>
              </ul>
            </div>
          </li>
        </ul>
      ) : (
        <p className="no-refund">
          <i>No reversals for this transaction</i>
        </p>
      )}
    </div>
  );
};
export default ReversalsTab;
