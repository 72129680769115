import ViewAndDownload from '+containers/Dashboard/Shared/ViewandDownload';
import DocumentImage from '+assets/img/auth/document.svg';
import './index.scss';

interface IRelatedDocumentsProps {
  data: {
    identification: {
      url: string;
    };
    identification_back: {
      url: string;
    };
    proof_of_address: {
      type: string;
      document: {
        url: string;
      };
    };
    source_of_inflow: {
      url: string;
    };
    selfie: {
      url: string;
    };
  };
}

export default function RelatedDocuments({ data }: IRelatedDocumentsProps) {
  return (
    <div className="row">
      <div className="col-sm-12">
        <div className="payment-link-summary">
          <div className="heading-box-mmd">
            <h4 className="form-header payment-link-header">Related Documents</h4>
            <p className="form-desc payment-desc">
              This section securely stores the documents related to the business and business owner with whom this account holder is
              registered under.
            </p>
          </div>
        </div>
      </div>
      <div className="col-sm-12">
        <h4 className="form-header related-document">ID Image (front)</h4>
        <div className="related-document-content">
          <div>
            <img src={DocumentImage} alt="document" />
            <span className="--document-name">Document</span>
          </div>
          <div>
            <ViewAndDownload
              url={data?.identification?.url}
              info={data?.identification?.url}
              type="jpeg"
              filename={data?.identification?.url}
              disableView={false}
              disableDownload
              showDownloadButton={false}
            />
          </div>
        </div>
      </div>
      {data?.identification_back?.url && <div className="col-sm-12">
        <h4 className="form-header related-document">ID Image (Back)</h4>
        <div className="related-document-content">
          <div>
            <img src={DocumentImage} alt="document" />
            <span className="--document-name">Document</span>
          </div>
          <div>
            <ViewAndDownload
              url={data?.identification_back?.url}
              info={data?.identification_back?.url}
              type="jpeg"
              filename={data?.identification_back?.url}
              disableView={false}
              disableDownload
              showDownloadButton={false}
            />
          </div>
        </div>
      </div>}
      <div className="col-sm-12">
        <h4 className="form-header related-document">Business Identification</h4>
        <div className="related-document-content">
          <div>
            <img src={DocumentImage} alt="document" />
            <span className="--document-name">Document</span>
          </div>
          <div>
            <ViewAndDownload
              url={data?.source_of_inflow?.url}
              info={data?.source_of_inflow?.url}
              type="pdf"
              filename={data?.source_of_inflow?.url}
              disableView={false}
              disableDownload
              showDownloadButton={false}
            />
          </div>
        </div>
      </div>
      <div className="col-sm-12">
        <h4 className="form-header related-document">Proof of Address</h4>
        <div className="related-document-content">
          <div>
            <img src={DocumentImage} alt="document" />
            <span className="--document-name">{data?.proof_of_address?.type}</span>
          </div>
          <div>
            <ViewAndDownload
              url={data?.proof_of_address?.document?.url}
              info={data?.proof_of_address?.document?.url}
              type="pdf"
              filename={data?.proof_of_address?.document?.url}
              disableView={false}
              disableDownload
              showDownloadButton={false}
            />
          </div>
        </div>
      </div>
      <div style={{ marginBottom: '200px' }} className="col-sm-12">
        <h4 className="form-header related-document">Proof of Verification(selfie)</h4>
        <div className="related-document-content">
          <div>
            <img src={DocumentImage} alt="document" />
            <span className="--document-name">Document</span>
          </div>
          <div>
            <ViewAndDownload
              url={data?.selfie?.url}
              info={data?.selfie?.url}
              type="jpeg"
              filename={data?.selfie?.url}
              disableView={false}
              disableDownload
              showDownloadButton={false}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
