import React, { useEffect, useState } from 'react';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { useMutation, useQuery } from 'react-query';
import Modal from '+dashboard/Shared/Modal';

import useStore from '+store';
import { cleanInput, formatAmount, logError } from '+utils';
import { IBulkPayoutStartModalProps, ErrorResponse, PayOutMappingTypes } from '+types';
import { payoutMethodMapping } from '../../TransactionDetails/data';
import APIRequest from '+services/api-services';
import { useFeedbackHandler } from '+hooks';

const validationSchema = Yup.object({
  otp: Yup.string().required('Required').min(6, 'OTP must be at least 6 characters').max(8, 'OTP must be at most 8 characters')
});

const api = new APIRequest();
const publicApi = new APIRequest(process.env.REACT_APP_PUBLIC_MERCHANT_MIDDLEWARE_API_BASE);

const BulkPayoutStartModal = ({
  closeModal,
  actionBtn,
  bulkPayoutSummary,
  currency = 'NGN',
  payoutCount,
  totpIdentifier,
  twoFactorType,
  reference
}: IBulkPayoutStartModalProps) => {
  const defaultMerchant = useStore((store: any) => store.defaultMerchant);
  const MERCHANT_ENV = useStore(state => state.merchantEnv);
  const [balances, setBalances] = useState({});
  const { feedbackInit } = useFeedbackHandler();
  const [identifier, setIdentifier] = useState('')

  const { refetch: refetchBalance } = useQuery(['MERCHANT_BALANCES', MERCHANT_ENV], () => api.getBalances(), {
    onSuccess: data => setBalances(data?.data || {}),
    onError: e => {
      const error = e.response?.data;
      logError(error?.message);
      const notLive = error?.message?.includes('not been approved');
      const message = notLive ? 'Your account has not been approved yet' : 'There has been an error in getting your balances';
      feedbackInit({
        message,
        type: 'danger',
        action: {
          action: () => (notLive ? history.push('/dashboard/settings/business') : refetchBalance()),
          name: notLive ? 'Complete account setup' : 'Try again'
        }
      });
    }
  });

  const initiateBulkPayout = useMutation(
    (data: { reference: string; data: { two_factor_type: string } }) => publicApi.initiateBulkPayout(data?.reference, data?.data),
    {
      retry: false,
      onSuccess: data => {
        const {
          authData: { identifier }
        } = data;

        if (identifier) {
          setIdentifier(identifier);
        }
      },
      onError: (error: ErrorResponse) => {
        logError(error?.response?.data?.message);
        feedbackInit({
          message: error?.response?.data?.message || 'Something went wrong while initiating bulk payout',
          type: 'danger',
          componentLevel: true
        });
      }
    }
  );

  useEffect(() => {
    initiateBulkPayout.mutate({ reference, data: { two_factor_type: twoFactorType } });
  },[])

  const startBulkPayout = useMutation(
    (data: { auth_data: { code: string; two_factor_type: string; identifier: string } }) => publicApi.startBulkPayout(reference, data),
    {
      onSuccess: () => {
        actionBtn();
      },
      onError: (e: ErrorResponse) => {
        feedbackInit({
          message: e?.response?.data?.message || 'An error occurred while updating entries',
          type: 'danger',
          componentLevel: true
        });
      }
    }
  );

  const close = () => {
    closeModal(false);
  };

  const formik = useFormik({
    initialValues: {
      otp: ''
    },
    validationSchema,
    onSubmit: () => {}
  });

  const { setFieldValue, isValid, values, dirty } = formik;
  const canContinue = dirty && isValid;

  const modalContent = () => {
    let email = defaultMerchant?.userEmail;
    if (email?.includes('@')) {
      email = email.split('@');
      email = `${email[0].slice(0, 3)}*******@${email[1]}`;
    }
    const payoutMethod: PayOutMappingTypes = (bulkPayoutSummary?.type as PayOutMappingTypes) || 'bank_account';
    return (
      <div className="bulk-payout-otp-form" data-testid="bulk-payout-start-modal">
        <div className="bulk-payout-otp-form-summary">
          <h3>Some important details of your bulk payout:</h3>
          <div className="form-summary-item">
            <span>Total Amount</span>
            <span>
              {' '}
              {formatAmount(bulkPayoutSummary?.total_bulk_amount)} {currency}
            </span>
          </div>
          <div className="form-summary-item">
            <span>Payout Count</span>
            <span> {payoutCount}</span>
          </div>
          <div className="form-summary-item">
            <span>Type</span>
            <span> {payoutMethodMapping?.[payoutMethod]}</span>
          </div>
          <div className="form-summary-item">
            <span>Current Balance</span>
            <span>
              {' '}
              {formatAmount(balances[currency]?.available_balance)} {currency}
            </span>
          </div>
        </div>
        <p>
          To proceed, enter the authorization code or OTP (one-time PIN) that was sent to your email <b>({email})</b> or from your
          authenticator app.
        </p>
        <div className="otp-form-input-wrapper">
          <label htmlFor="otp">Authorization Code / OTP</label>
          <input
            data-testid="otp-input"
            type="text"
            name="otp"
            id="otp"
            className="form-control"
            placeholder="Enter Authorization Code / OTP"
            value={values.otp}
            onChange={e => setFieldValue('otp', cleanInput(e.target.value))}
            maxLength={8}
          />
        </div>
      </div>
    );
  };
  return (
    <Modal
      size="md"
      close={close}
      heading="Confirm bulk payout"
      description={
        <p style={{ color: '#414F5F', fontWeight: 400, display: 'block' }}>
          Please confirm that you want to proceed with this bulk payout. Once started, the bulk payout would not be stopped until all the
          transactions have been processed.
        </p>
      }
      content={modalContent()}
      justify="space-between"
      firstButtonText="Back"
      firstButtonAction={close}
      secondButtonText="Start"
      secondButtonAction={async () => {
        const { otp } = values;
        const data = { auth_data: { code: otp, two_factor_type: twoFactorType, identifier: twoFactorType === 'totp' ?  totpIdentifier : identifier } };
        await startBulkPayout.mutateAsync(data);
        actionBtn();
      }}
      completedHeading="Success"
      completedDescription="Bulk Payout Started Successfullly"
      secondButtonColor="#24B314"
      secondButtonDisable={!canContinue}
    />
  );
};

export default BulkPayoutStartModal;
