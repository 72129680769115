import { useQuery } from 'react-query';

import CurrencyPicker from '+containers/Dashboard/Shared/CurrencyPicker';
import ProductFeatureDropdown from '+containers/Dashboard/Shared/ProductFeatureDropdown';
import { useSearchQuery } from '+hooks';
import APIRequest from '+services/api-services';
import useStore from '+store';

import './index.scss';

const api = new APIRequest(process.env.REACT_APP_MERCHANT_MIDDLEWARE_API_BASE);

const Limits = () => {
  const searchQuery = useSearchQuery();
  const activeCurrency = searchQuery.value.currency ?? 'NGN';
  const setActiveCurrency = (currency: string) => searchQuery.setQuery({ currency });

  const availableCurrencies = useStore(state => state.availableCurrencies);

  const EnabledCurrency = availableCurrencies;

  const { data: merchantLimit } = useQuery(['MERCHANT_PRODUCT_LIMIT'], () => api.getMerchantTieringData(), {});

  return (
    <div className="limit-tab-wrapper">
      <div className="tier-heading-box">
        <div className="fees-heading-content">
          <h5 className="form-header">Limit & Product Access</h5>

          <div className="form-desc" style={{ border: 'none', marginBottom: '0', maxWidth: '24rem' }}>
            See maximum and minimum limits for available product features and how they apply to your organization.{' '}
          </div>
        </div>
        <div className="tier-currencies">
          <CurrencyPicker
            onChange={value => {
              setActiveCurrency(value);
            }}
            options={EnabledCurrency}
            activeCurrency={activeCurrency}
            id="merchant-fees__currency-switch"
            allowedOptions={['NGN']}
            showDropDown={false}
          />
          <p>Toggle the switch above to view limits for other currencies.</p>
        </div>
      </div>
      <div className="os-tabs-controls os-tabs-complex mx-0">
        <ul className="nav nav-tabs px-0 mb-2" />
      </div>
      <section>
        <div className="row mt-3 ">
          <div className="col-md-12">
            <ProductFeatureDropdown details={merchantLimit?.data} currency={activeCurrency} />
          </div>
        </div>
      </section>
    </div>
  );
};

export default Limits;
