import { FieldsListType } from '+containers/Dashboard/Shared/FilterModal';

export const verificationEventsFilterFields: FieldsListType = [
  {
    label: 'Unique Reference (Event Reference)',
    key: 'reference',
    type: 'text-input',
    inputProps: {
      placeholder: 'E.g, kpw_p0g8uS...',
      maxLength: 50
    }
  },
  {
    label: 'Created At',
    key: 'dateCreated',
    type: 'date-range',
    options: [
      {
        value: 'startDate',
        label: 'From'
      },
      {
        value: 'endDate',
        label: 'To'
      }
    ],
    applyMaxDate: true,
    applyMinDate: true
  },
  {
    label: 'Result',
    key: 'status',
    type: 'select',
    options: [
      {
        value: '',
        label: 'Result'
      },
      {
        value: 'failed',
        label: 'Failed'
      },
      {
        value: 'pending',
        label: 'Pending'
      },
      {
        value: 'not_found',
        label: 'Not found'
      },
      {
        value: 'pending',
        label: 'Pending'
      },
      {
        value: 'found',
        label: 'Found'
      }
    ]
  },
  {
    label: 'Keyword(s)',
    key: 'keyword',
    type: 'text-input',
    inputProps: { placeholder: 'Search anything...', maxLength: 50 }
  }
];

export const billingHistoryFilterFields: FieldsListType = [
  {
    label: 'Unique Reference (Event Reference)',
    key: 'reference',
    type: 'text-input',
    inputProps: {
      placeholder: 'E.g, kpw_p0g8uS...',
      maxLength: 50
    }
  },
  {
    label: 'Created At',
    key: 'dateCreated',
    type: 'date-range',
    options: [
      {
        value: 'startDate',
        label: 'From'
      },
      {
        value: 'endDate',
        label: 'To'
      }
    ],
    applyMaxDate: true,
    applyMinDate: true
  },
  {
    label: 'Type',
    key: 'verificationType',
    type: 'select',
    options: [
      {
        value: '',
        label: 'All'
      },
      {
        value: 'kyc',
        label: 'KYC'
      },
      {
        value: 'kyb',
        label: 'KYB'
      }
    ]
  },
  {
    label: 'Keyword(s)',
    key: 'keyword',
    type: 'text-input',
    inputProps: { placeholder: 'Search anything...', maxLength: 50 }
  },
  {
    label: 'Created At',
    key: 'dateCreated',
    type: 'date-range',
    options: [
      {
        value: 'date_from',
        label: 'From'
      },
      {
        value: 'date_to',
        label: 'To'
      }
    ],
    applyMaxDate: true,
    applyMinDate: true
  }
];

export type StatusIconsKeyType = 'valid' | 'undefined' | 'pending' | 'invalid';
export const statusIcons: Record<StatusIconsKeyType, string> = {
  valid: 'os-icon os-icon-check',
  undefined: 'os-icon os-icon-minus',
  pending: 'os-icon os-icon-clock',
  invalid: 'os-icon os-icon-close'
};
