import { useQuery } from 'react-query';
import APIRequest from '+services/api-services';
import useStore from '+store';

const api = new APIRequest(process.env.REACT_APP_AUTH_API_BASE);

export default function use2FAInfo({ onError, onSuccess, key = 'GET_TWOFA_HISTORY' } = {}) {
  const profile = useStore(state => state.profile);
  return useQuery([key, profile?.email], () => api.get2FAHistory(), {
    retry: false,
    onError,
    onSuccess
  });
}
