import Filesaver from 'file-saver';
import jwtDecode from 'jwt-decode';

import { ValidationObject } from '+containers/Dashboard/Identity/types/types';
import useStore from '+store';
import { Currency, filterOutFromObject } from '+types';
import { history } from '+utils';

import { Storage } from '../services/storage-services';

interface ILocation {
  pathname: string;
  search: string;
}

export const copyWithJS = (key: string, updateFunction: any) => {
  const selBox = document.createElement('textarea');
  selBox.style.position = 'fixed';
  selBox.style.left = '0';
  selBox.style.top = '0';
  selBox.style.opacity = '0';
  selBox.value = key;
  document.body.appendChild(selBox);
  selBox.focus();
  selBox.select();
  document.execCommand('copy');
  document.body.removeChild(selBox);
  return updateFunction;
};

export const updateFeedback = (state: any, setState: (arg0: any) => void, type: any, message: any) => {
  setState({
    ...state,
    feedBackVisible: true,
    feedBackType: type,
    feedBackMessage: message
  });
  setTimeout(() => {
    setState({ ...state, feedBackVisible: false });
  }, 2000);
};

export const switchStatus = (status: string) => {
  switch (status) {
    case 'success':
    case 'active':
    case 'won':
    case 'partial':
    case 'capture_success':
    case 'partial_completed':
    case 'completed':
    case 'partially_accepted':
    case 'accepted_arbitration':
    case 'accepted_pre_arbitration':
    case 'partially_accepted_pre_arbitration':
    case 'accepted':
    case 'complete':
    case 'delivered':
      return 'green';

    case 'failed':
    case 'inactive':
    case 'deleted':
    case 'auto_accepted':
    case 'declined':
    case 'declined_arbitration':
    case 'declined_pre_arbitration':
    case 'lost':
      return 'red';

    case 'pending_pre_arbitration':
    case 'delayed_pre_arbitration':
    case 'pending':
    case 'pending_auth':
    case 'require_auth':
    case 'requires_auth':
    case 'suspended':
      return 'yellow';

    case 'processing_pre_arbitration':
    case 'processing':
      return 'orange';

    case 'reversed':
    case 'pre_authorized':
      return 'blue';

    case 'manual':
      return 'light-blue';

    case 'rejected':
    case 'abandoned':
      return 'dark';

    case 'void_capture':
    case 'void_authorization':
      return 'grey';

    case 'invalid':
    case 'invalid_pre_arbitration':
    default:
      return 'dark-grey';
  }
};

export const switchStatusName = (status: string) => {
  switch (status) {
    case 'requires_auth':
      return 'Pending (Requires Authorization)';
    case 'flagged':
      return 'Flagged';
    default:
      return status;
  }
};

export const isRobot = () => {
  if (/HeadlessChrome/.test(window.navigator.userAgent)) return true;
  if (navigator.webdriver) return true;
  if (navigator.permissions) {
    navigator.permissions?.query({ name: 'notifications' }).then(permissionStatus => {
      if (window?.Notification?.permission === 'denied' && permissionStatus?.state === 'prompt') {
        return true;
      }
      return false;
    });
  }
  return false;
};

export const APIDownload = (response: any, filename: string, format: string): void => {
  const blob = new Blob([response.data], {
    type: response.headers['content-type']
  });
  const downloadUrl = window.URL.createObjectURL(blob);
  return Filesaver.saveAs(downloadUrl, `${filename}.${format}`);
};

export const downloadFileViaUrl = async (url: string, filename: string, contentType: string) => {
  const response = await fetch(url);
  const blob = await response.blob();
  return Filesaver.saveAs(new Blob([blob], { type: contentType }), `${filename}`);
};

export const checkTokenExpiration = (token: string) => {
  if (!token) return false;
  const decodedToken: Record<string, number> = jwtDecode(token);
  const currentTime = new Date().getTime() / 1000;
  const tokenExpirationTime = decodedToken?.exp;
  if (tokenExpirationTime) {
    return currentTime < tokenExpirationTime;
  }
  return false;
};

export const maskEmail = (email: string): string => {
  const [localPart, domain] = email.split('@');
  let maskedLocalPart = '';
  if (localPart.length > 4) {
    maskedLocalPart = localPart.slice(0, 4) + '*'.repeat(Math.min(localPart.length - 4, 3));
  } else {
    maskedLocalPart = localPart.charAt(0) + '*'.repeat(Math.max(localPart.length - 1, 0));
  }

  return `${maskedLocalPart}@${domain}`;
};

export const isAuthenticated = () => {
  let full = false;
  let partial = false;
  const { authDetails } = useStore.getState();
  const token = authDetails?.access_token;
  if (token) partial = true;
  full = checkTokenExpiration(token);
  return { full, partial };
};

export const filterOutEmptyValues = (value: {
  [x: string]: string | undefined | string[];
  'search[status][]'?: any;
  currency?: string;
  keyword?: string;
  status?: string[];
}) => {
  const a = value;
  Object.keys(value).forEach(
    key =>
      (!a[key] || a[key] === 0 || (typeof a[key] === 'string' && a[key].trim() === '') || Object.entries(a[key]).length === 0) &&
      delete a[key]
  );
  return a;
};

export const swapArrayPositions = (array: string | any[], a: number, b: number) => {
  if (!array?.length) return array;
  const newArray = [...array];
  [newArray[a], newArray[b]] = [newArray[b], newArray[a]];
  return newArray;
};

export const storeExportLink = (location: ILocation) => {
  const URL_CONSTANTS = '/dashboard';
  const urlPaths = {
    payins: 'pay-ins',
    payouts: 'payouts',
    balance_history: 'balances',
    login: 'auth/login',
    settlements: 'settlements',
    merchant_issuing_transaction_history: 'issuing'
  };
  const isExportDownload = location.pathname.includes(`${URL_CONSTANTS}/files`);
  const isExportLink = Storage.getItem('EXPORT_LINK');
  const { authDetails } = useStore.getState();
  const token = authDetails?.access_token;
  if (isExportDownload || isExportLink !== null) {
    let downloadUrl = location.search;

    if (!downloadUrl && isExportLink) {
      downloadUrl = isExportLink;
    }

    const params = new URLSearchParams(downloadUrl);
    const exportType = params.get('type');

    if (!exportType) return;

    if (isExportLink == null) {
      Storage.setItem('EXPORT_LINK', downloadUrl);
    }

    if (!token) {
      history.push(`/${urlPaths.login}`);
    } else {
      const path = urlPaths[exportType as keyof typeof urlPaths];

      if (!path) return;

      history.push(`${URL_CONSTANTS}/${path}`);
    }
  }
};

export const blockInvalidChars = (event: { preventDefault?: any; key?: any }) => {
  const regex = /[\\/[\]:¦<>+=;,*$!?(){}."'#%^&@_-]/;
  const { key } = event;
  if (regex.test(key)) {
    event.preventDefault();
  }
};

export const isObjectNotEmpty = obj => {
  return ![null, undefined].includes(obj) && Object.keys(obj)?.length > 0;
};

export const checkProductAccess = (productAccessConfig: Record<Currency, boolean>) => {
  let granted = false;
  Object.keys(productAccessConfig || {}).forEach(currency => {
    if (productAccessConfig[currency as Currency]) {
      granted = true;
    }
  });
  return granted;
};

export const formatCurrency = (currencyArray: string[]): { value: string; label: string }[] => {
  const data = currencyArray.map(currency => {
    return { value: currency, label: currency };
  });
  return data;
};

export const switchCountry = (countryCode: string): string => {
  switch (countryCode) {
    case 'NG':
      return 'Nigeria';
    case 'USA':
      return 'United States';
    default:
      return countryCode;
  }
};

export const filteredOutObjectProperty: filterOutFromObject<string | number | symbol, string[]> = (object, notAllowed) =>
  object && Object.keys(object).length > 0
    ? Object.keys(object)
      .filter(key => !notAllowed.includes(key))
      .reduce((obj, key) => {
        return {
          ...obj,
          [key]: object[key]
        };
      }, {})
    : {};

export const isNullish = <T>(value: T | undefined | null) => {
  return value === null || value === undefined;
};

declare global {
  interface Window {
    clarity: (set?: string, event?: string, data?: string) => void;
  }
}

export const smartTrack = (event: string, data: string) => {
  if (window && typeof window.clarity === 'function') {
    window.clarity('set', event, data);
  } else {
    throw new Error('Clarity is not loaded');
  }
};

export const findMismatchKeys = (obj: ValidationObject, parentKey = ''): string[] => {
  let keys: string[] = [];
  for (const key in obj) {
    if (typeof obj[key] === 'object' && obj[key] !== null) {
      keys = [...keys, ...findMismatchKeys(obj[key] as ValidationObject, parentKey ? `${parentKey}.${key}` : key)];
    } else if (key === 'match' && obj[key] === false) {
      keys.push(parentKey);
    }
  }
  return keys;
};
