import { useState } from 'react';
import { NetworkStatusType } from '+types';

const useNetworkAvailabilityStatus = () => {
  const [networkStatus, setNetworkStatus] = useState<NetworkStatusType>(null);

  const showNetworkStatus = () => {
    switch (networkStatus) {
      case 'closed':
        return <small className="text-danger">This bank is unavailable</small>;
      case 'available':
      case 'delayed':
        return (
          <small className="validation-text success">
            <span className="os-icon os-icon-checkmark" role="status"></span> This bank is available
          </small>
        );
      default:
        return null;
    }
  };

  const getMessage = (status: NetworkStatusType) => {
    switch (status) {
      case 'available':
        return '(Delivers instantly)';
      case 'delayed':
        return '(Processed later)';
      case 'closed':
        return '(Unfortunately, the withdrawal cannot be processed, there are some issues with our processor. Please try again later)';
      default:
        return '';
    }
  };

  return {
    networkStatus,
    setNetworkStatus,
    showNetworkStatus,
    getMessage,
  };
};

export default useNetworkAvailabilityStatus;
