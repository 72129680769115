import { allowTextandSpace, cleanInput, filterUserInput, stripNonNumeric } from '+utils';

import { IReservedCardRequestForm } from '../types';
import {
  maxAddressCharLength,
  maxDescriptionLength,
  maxPostalCodeLength,
  minCharLength,
  reservedCardMonthlyPaymentOptions,
  reservedCardPlansDoc,
  subscriptionPlanOptions,
  useCaseOptions
} from './accessRequestModalData';

const ReservedCardRequestForm = ({ countriesList, statesList, formikBag, merchantName }: IReservedCardRequestForm) => {
  const { values, errors, touched, getFieldProps, setFieldValue, handleBlur } = formikBag;
  const [firstName, lastName] = merchantName;

  return (
    <div className="stack-lg fade-in">
      <div className="stack-sm">
        <div id="name-on-card" className="rvc-label reset-margin stack-sm" data-label-props="lg bold">
          <strong className="block">Name on card</strong>
          <p className="subtext">This name will be used as default cardholder&apos;s name for all your reserved cards</p>
        </div>

        <div data-layout="switcher">
          <div>
            <label htmlFor="first-name" className="rvc-label">
              First Name
            </label>
            <input
              id="first-name"
              placeholder="Enter first name"
              aria-describedby="name-on-card"
              className="form-control"
              name="firstName"
              value={firstName}
              readOnly
            />
          </div>

          <div>
            <label htmlFor="last-name" className="rvc-label">
              Last Name
            </label>
            <input
              id="last-name"
              placeholder="Enter last name"
              aria-describedby="name-on-card"
              className="form-control"
              name="lastName"
              value={lastName}
              readOnly
            />
          </div>
        </div>
      </div>

      <div className="request-access-form-group reset-margin stack-md">
        <p id="billing-address" className="rvc-label reset-margin" data-label-props="lg bold light">
          Billing Address
          <span className="block subtext">This address will be used as default billing address for all your reserved cards</span>
        </p>

        <div className="request-access-form-group stack-sm">
          <label className="rvc-label" htmlFor="country">
            Country
          </label>
          <select
            id="country"
            className="form-control"
            {...getFieldProps?.('country')}
            aria-describedby="billing-address"
            aria-errormessage="country-error"
            aria-invalid={touched?.country && errors?.country ? 'true' : 'false'}
          >
            <option value="">- Select a country -</option>
            {countriesList.map(({ value, label }) => (
              <option key={value} value={value}>
                {label}
              </option>
            ))}
          </select>
          <span id="country-error" data-feedback="invalid">
            {touched?.country && errors?.country}
          </span>
        </div>

        <div data-layout="switcher">
          <div>
            <label className="rvc-label" htmlFor="city">
              City
            </label>
            <input
              className="form-control"
              id="city"
              placeholder="Enter city"
              name="city"
              value={values?.city}
              onChange={e => setFieldValue?.('city', allowTextandSpace(e.target.value))}
              onBlur={handleBlur}
              minLength={minCharLength}
              maxLength={maxAddressCharLength}
              aria-errormessage="city-error"
              aria-invalid={touched?.city && errors?.city ? 'true' : 'false'}
            />
            <span id="city-error" data-feedback="invalid" aria-live="assertive">
              {touched?.city && errors?.city}
            </span>
          </div>

          <div>
            <label className="rvc-label" htmlFor="state">
              State
            </label>
            <select
              id="state"
              className="form-control"
              {...getFieldProps?.('state')}
              name="state"
              aria-describedby="billing-address"
              aria-errormessage="state-error"
              aria-invalid={touched?.state && errors?.state ? 'true' : 'false'}
              onBlur={handleBlur}
            >
              <option value="">- Select a state -</option>
              {statesList[values?.country]?.map(({ name }) => (
                <option key={name} value={name}>
                  {name}
                </option>
              ))}
            </select>
            <span id="state-error" data-feedback="invalid" aria-live="assertive">
              {touched?.state && errors?.state}
            </span>
          </div>
        </div>

        <div data-layout="switcher">
          <div>
            <label className="rvc-label" htmlFor="street">
              Street
            </label>
            <input
              className="form-control"
              id="street"
              placeholder="Enter street"
              value={values?.street}
              onChange={e => setFieldValue?.('street', cleanInput(e.target.value))}
              minLength={minCharLength}
              maxLength={maxAddressCharLength}
              name="street"
              onBlur={handleBlur}
              aria-errormessage="street-error"
              aria-invalid={touched?.street && errors?.street ? 'true' : 'false'}
            />
            <span id="street-error" data-feedback="invalid" aria-live="assertive">
              {touched?.street && errors?.street}
            </span>
          </div>

          <div>
            <label className="rvc-label" htmlFor="postal-code">
              Postal code
            </label>
            <input
              className="form-control"
              id="postal-code"
              placeholder="Enter postal code"
              value={values?.postalCode}
              onChange={e => setFieldValue?.('postalCode', stripNonNumeric(e.target.value))}
              name="postalCode"
              minLength={minCharLength}
              maxLength={maxPostalCodeLength}
              onBlur={handleBlur}
              aria-errormessage="postal-error"
              aria-invalid={touched?.postalCode && errors?.postalCode ? 'true' : 'false'}
              inputMode="numeric"
            />
            <span id="postal-error" data-feedback="invalid" aria-live="assertive">
              {touched?.postalCode && errors?.postalCode}
            </span>
          </div>
        </div>
      </div>

      <div className="reset-margin stack-md">
        <label className="rvc-label" data-label-props="light bold" htmlFor="reserved-card-monthly-payment">
          What is your current monthly payment value?
        </label>
        <p className="subtext">The total amount you can process per month using the reserved cards.</p>
        <select
          className="form-control"
          id="reserved-card-monthly-payment"
          {...getFieldProps?.('reservedCardMonthlyPaymentValue')}
          aria-invalid={touched?.reservedCardMonthlyPaymentValue && errors?.reservedCardMonthlyPaymentValue ? 'true' : 'false'}
          aria-errormessage="reserved-card-monthly-payment-error"
        >
          <option value="">- Select payment value -</option>
          {reservedCardMonthlyPaymentOptions.map(option => (
            <option key={option.label} value={option.value}>
              {option.label}
            </option>
          ))}
        </select>
        <span id="reserved-card-monthly-payment-error" data-feedback="invalid" aria-live="assertive">
          {touched?.reservedCardMonthlyPaymentValue && errors?.reservedCardMonthlyPaymentValue}
        </span>
      </div>

      <div>
        <label className="rvc-label" htmlFor="subscription-plan">
          Select a preferred subscription plan{' '}
          <a href={reservedCardPlansDoc} target="_blank" rel="noopener noreferrer" style={{ color: 'hsla(216, 90%, 55%, 1)' }}>
            see plans
          </a>
        </label>
        <select
          className="form-control"
          id="subscription-plan"
          {...getFieldProps?.('subscriptionPlan')}
          aria-invalid={touched?.subscriptionPlan && errors?.subscriptionPlan ? 'true' : 'false'}
          aria-errormessage="pci-dss-error"
        >
          <option value="">- Select subscription plan -</option>
          {subscriptionPlanOptions.map(({ value, label }) => (
            <option key={value} value={value}>
              {label}
            </option>
          ))}
        </select>
        <span id="monthly-payment-error" data-feedback="invalid">
          {touched?.subscriptionPlan && errors?.subscriptionPlan}
        </span>
      </div>

      <div>
        <label className="rvc-label" htmlFor="use-case">
          Select what you&apos;d like to use these cards for
        </label>
        <select
          id="use-case"
          className="form-control"
          {...getFieldProps?.('useCase')}
          aria-invalid={touched?.useCase && errors?.useCase ? 'true' : 'false'}
          aria-errormessage="usecase-error"
        >
          <option value="">- Select use case -</option>
          {useCaseOptions.map(({ value, label }) => (
            <option key={value} value={value}>
              {label}
            </option>
          ))}
        </select>
        <span id="usecase-error" data-feedback="invalid" aria-live="assertive">
          {touched?.useCase && errors?.useCase}
        </span>
      </div>

      <div className="stack-sm">
        <label className="rvc-label" htmlFor="more-use-case">
          Tell us more about your use case (optional)
        </label>
        <textarea
          id="more-use-case"
          name="otherUseCase"
          className="form-control"
          rows={3}
          maxLength={maxDescriptionLength}
          value={values?.description}
          onChange={e => setFieldValue?.('description', filterUserInput(e.target.value))}
          aria-describedby="word-count"
        />
        <span
          id="word-count"
          className="block text-sm"
          aria-live="polite"
          style={{ textAlign: 'right' }}
        >{`${values?.description.length}/${maxDescriptionLength}`}</span>
      </div>
    </div>
  );
};

export default ReservedCardRequestForm;
