import { useState } from 'react';
import clsx from 'clsx';

import { capitalize } from '+utils';

import Modal from '../../../Shared/Modal';
import { CardEventsResponseType, EventVerbType } from '../../types';

const reasons: Partial<Record<EventVerbType, string>> = {
  suspended:
    'A suspended card has a locked balance and cannot be funded or used to make payments. To reactivate a suspended card, click ‘Manage Card’ at the top-right corner of the card page',
  terminated:
    'A terminated card can no longer be funded or used to make payments. Also, funds in the balance of the terminated card are returned to the Issuing Balance.',
  expired:
    'Once the expiry date of an issued card is reached, it can no longer be funded or used to make payments. You would need to issue a new card to the cardholder.'
};

const inactiveStatus = ['deactivated', 'suspended', 'terminated', 'expired'];

const StatusNotification = ({ status, event: cardEvent }: { status: EventVerbType; event: CardEventsResponseType }) => {
  const [showNotif, setShowNotif] = useState(false);

  return inactiveStatus.includes(status) ? (
    <section className={clsx('warning-bar', status)}>
      <span>This card has been {['deactivated', 'suspended'].includes(status) ? 'suspended' : status}!</span>{' '}
      {cardEvent?.event && (
        <span>
          <a className="link" onClick={() => showNotif(true)}>
            Learn why
          </a>
          <Modal
            visible={showNotif}
            heading={`Card ${capitalize(status)}`}
            description={<span className="reason-description">{reasons[status] || ''}</span>}
            close={() => setShowNotif(false)}
            content={
              <div className="reason-body">
                <p>This card was {status} due to the following reason(s):</p>
                <li>{cardEvent.reason}</li>
              </div>
            }
            firstButtonText="Close"
            hideSecondButton
          />
        </span>
      )}
    </section>
  ) : null;
};

export default StatusNotification;
