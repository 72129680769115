/* eslint-disable camelcase */
import React, { useState } from 'react';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';

import { useFeedbackHandler, useSearchQuery } from '+hooks';
import APIRequest from '+services/api-services';
import useStore from '+store';
import {
  capitalize,
  copyWithJS,
  formatAmount,
  getDate,
  getTime,
  history,
  logBreadCrumb,
  logError,
  switchCurrency,
  switchStatus
} from '+utils';
import { breadCrumbEvents } from '+utils/bugsnag-events';

import LoadingPlaceholder from '../../Shared/LoadingPlaceholder';
import Modal from '../../Shared/Modal';
import SecondaryDetails from '../../Shared/SecondaryDetails';
import Table from '../../Shared/Table';

const api = new APIRequest();

export default function PaymentLinkDetails() {
  const { feedbackInit } = useFeedbackHandler();
  const { id } = useParams();
  const searchQuery = useSearchQuery();
  const paginationPage = searchQuery.value.page || '1';
  const limit = searchQuery.value.limit || '10';

  const [state, setState] = useState({
    modalHeader: '',
    modalType: '',
    modalVisible: false,
    modalAction: null,
    copied: false
  });

  const MERCHANT_ENV = useStore(store => store.merchantEnv);

  const { data: linkDetail, isLoading, refetch: refetchLinkDetails } = useQuery(
    'PAYMENT_LINK_DETAILS',
    () => api.getSinglePaymentLink(id),
    {
      onError: e => {
        const error = e.response?.data;
        logError(error?.message);
        feedbackInit({
          message: 'There has been an error getting the details for this payment link. ',
          type: 'danger',
          action: {
            action: () => refetchLinkDetails(),
            name: 'Try again'
          }
        });
        return history.push(`/dashboard/payment-links`);
      }
    }
  );

  const { data: linkTransactions, isLoading: trxnsLoading, refetch: trnxRefetch } = useQuery(
    ['PAYMENT_LINK_TRANSACTIONS', paginationPage, limit, MERCHANT_ENV],
    () => api.getLinkTransactions(id, paginationPage, limit),
    {
      keepPreviousData: true,
      onError: e => {
        const error = e.response?.data;
        logError(error?.message);
        feedbackInit({
          message: 'There has been an error getting the transactions for this payment link. ',
          type: 'danger',
          action: {
            action: () => trnxRefetch(),
            name: 'Try again'
          }
        });
        return history.push(`/dashboard/payment-links`);
      }
    }
  );

  const modalContent = type => {
    switch (type) {
      case 'active':
        return 'Are you sure you want to make this payment link active?';
      case 'inactive':
        return 'Are you sure you want to deactivate this payment link?';
      default:
        return 'Are you sure you want to delete this payment link? Note that this action cannot be undone.';
    }
  };

  const updateLinkStatus = async () => {
    try {
      const statusData = { status: linkDetail?.status === 'active' ? 'inactive' : 'active' };
      await api.updatePaymentLink(statusData, linkDetail.reference);
      refetchLinkDetails();
    } catch (error) {
      logError(error);
      setState({ ...state, modalVisible: false });
      feedbackInit({ message: 'Payment link status could not be updated. Please try again', type: 'danger' });
      throw error;
    }
  };

  const deleteLink = async () => {
    try {
      await api.deletePaymentLink(linkDetail.reference);
      setTimeout(() => {
        setState({ ...state, modalVisible: false });
        history.push(`/dashboard/payment-links`);
      }, 1500);
    } catch (error) {
      logError(error);
      setState({ ...state, modalVisible: false });
      feedbackInit({ message: 'Payment link status could not be deleted right now. Please try again', type: 'danger' });
      throw error;
    }
  };

  const paging = linkTransactions?.paging;

  const Transactions = () => {
    return linkTransactions?.data ? (
      <>
        {linkTransactions?.data.map(each => (
          <div
            key={`transaction_${each.reference}`}
            className="div-table --payment-link-details-table --row"
            onClick={() => {
              history.push(`/dashboard/pay-ins/${each.reference}`);
              logBreadCrumb({ event: breadCrumbEvents.paymentLinks.singleLinkClicked, data: { slug: each.reference } });
            }}
            onKeyDown={() => {
              history.push(`/dashboard/pay-ins/${each.reference}`);
              logBreadCrumb({ event: breadCrumbEvents.paymentLinks.singleLinkClicked, data: { slug: each.reference } });
            }}
            role="button"
            tabIndex={0}
          >
            <div>
              <span className="body-row-header">Status:</span>
              <span className={`status-pill smaller ${switchStatus(each.status)}`} />
              <span>{capitalize(each.status)}</span>
            </div>
            <div>
              <span className="body-row-header">Transaction Id:</span>
              <span style={{ textTransform: 'uppercase' }}>{each?.transaction_reference}</span>
            </div>
            <div>
              <span className="body-row-header">Customer:</span>
              <span>{each?.customer_name}</span>
            </div>
            <div>
              <span className="body-row-header">Transaction Date:</span>
              <span>{getDate(each?.transaction_date)}</span>
              <span className="annotation" style={{ marginLeft: '5px' }}>
                {getTime(each?.transaction_date)}
              </span>
            </div>
            <div className="bolder">
              <span className="body-row-header">Amount ({each?.currency}):</span>
              <span>{each?.amount}</span>
              <span className="annotation" style={{ marginLeft: '5px' }}>
                {each.currency}
              </span>
            </div>
          </div>
        ))}
      </>
    ) : null;
  };

  const linkDetails = [
    {
      Amount:
        linkDetail?.plan?.amount > 0
          ? `${linkDetail?.plan?.currency} ${formatAmount(linkDetail.plan.amount || 0)}`
          : `This payment link accepts variable amounts`,
      Currency: switchCurrency[linkDetail?.plan?.currency || 'NGN'],
      Type: linkDetail?.plan?.is_recurring ? 'Subscription' : 'One Time',
      'Date Created': getDate(linkDetail?.createdAt),
      Description: linkDetail?.description || 'Not Available',
      Expires: linkDetail?.plan?.expiry ? getDate(linkDetail.plan.expiry) : 'This payment link does not expire'
    },
    {
      'Send receipts': linkDetail?.send_email_notification
        ? 'This payment link sends receipts to customers'
        : 'This payment link does not send receipts',
      'Fee Bearer': linkDetail?.merchant_bears_cost ? 'Merchant' : 'Customer'
    }
  ];

  return (
    <>
      <div className="row">
        <button
          type="button"
          className="btn btn-link mb-3"
          onClick={() => {
            history.goBack();
            logBreadCrumb({ event: breadCrumbEvents.paymentLinks.goBackbuttonClicked });
          }}
        >
          <i className="os-icon os-icon-arrow-left7" />
          <span>Back to Payment Link Home</span>
        </button>
      </div>
      <div className="row">
        <div className="col-lg-12">
          <div className="element-wrapper">
            <div className="element-box">
              <div className="checkout-details-box">
                <div className="checkout-details-heading">
                  <div className="checkout-details-title">
                    <h3 className="checkout-title">{capitalize(linkDetail?.name || '')}</h3>
                    <div className="cta-container">
                      {linkDetail?.status === 'expired' ? (
                        <p className="expired-link">This Checkout has expired</p>
                      ) : (
                        <div className="status-toggler">
                          <span className="status-toggler-label">Status: </span>
                          <div className="status-toggler-content" style={{ marginTop: '2px' }}>
                            <div
                              className={`os-toggler-w ${linkDetail?.status === 'active' ? 'checkout-on' : 'off'}`}
                              onClick={() => {
                                setState({
                                  ...state,
                                  modalVisible: true,
                                  modalHeader: 'Change Link Status ?',
                                  modalType: linkDetail?.status === 'active' ? 'inactive' : 'active',
                                  modalAction: updateLinkStatus
                                });
                                logBreadCrumb({ event: breadCrumbEvents.paymentLinks.toggleButtonClicked });
                              }}
                              role="button"
                              tabIndex={0}
                              onKeyDown={() => {
                                setState({
                                  ...state,
                                  modalVisible: true,
                                  modalHeader: 'Change Link Status ?',
                                  modalType: linkDetail?.status === 'active' ? 'inactive' : 'active',
                                  modalAction: updateLinkStatus
                                });
                                logBreadCrumb({ event: breadCrumbEvents.paymentLinks.toggleButtonClicked });
                              }}
                            >
                              <div className="os-toggler-i">
                                <span className="os-toggler-pill" />
                              </div>
                            </div>
                            <span className="status-toggler-state">{linkDetail?.status === 'active' ? 'Active' : 'Inactive'}</span>
                          </div>
                        </div>
                      )}
                      <div className="button-holder ml-4">
                        <button
                          type="button"
                          className="delete-checkout"
                          onClick={() => {
                            setState({
                              ...state,
                              modalVisible: true,
                              modalHeader: 'Delete Payment Link ?',
                              modalType: 'delete',
                              modalAction: deleteLink
                            });
                            logBreadCrumb({ event: breadCrumbEvents.paymentLinks.deleteButtonClicked });
                          }}
                        >
                          Delete
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className="checkout-link-handler">
                    <div className={`checkout-link ${state.copied && '--copied'}`}>
                      {state.copied ? (
                        <p>Link copied!</p>
                      ) : (
                        <>
                          <p>
                            {MERCHANT_ENV === 'test'
                              ? `${process.env.REACT_APP_CHECKOUT_TEST}${id}`
                              : `${process.env.REACT_APP_CHECKOUT_LIVE}${id}`}
                          </p>
                          <button
                            type="button"
                            className="btn btn-grey"
                            onClick={() => {
                              const url =
                                MERCHANT_ENV === 'test'
                                  ? `${process.env.REACT_APP_CHECKOUT_TEST}${id}`
                                  : `${process.env.REACT_APP_CHECKOUT_LIVE}${id}`;
                              setState({ ...state, copied: true });
                              setTimeout(() => {
                                setState({ ...state, copied: false });
                              }, 800);
                              logBreadCrumb({ event: breadCrumbEvents.paymentLinks.copyButtonClicked });
                              return copyWithJS(url, null);
                            }}
                          >
                            <i className="os-icon os-icon-copy" />
                            <span>Copy Link</span>
                          </button>
                        </>
                      )}
                    </div>
                    <div className="checkout-banner">
                      <div className="banner_icon">
                        <span className="os-icon os-icon-info" />
                      </div>
                      <p>
                        You can receive payments from customers by sharing this link with them, or embedding the link in your website.{' '}
                        <a href={`${window.location.origin}/demo`} target="noopener noreferrer">
                          See an example of how payment links work.
                        </a>
                      </p>
                    </div>
                  </div>
                </div>
                {isLoading ? <LoadingPlaceholder type="text" /> : <SecondaryDetails title="Payment Link Details" data={linkDetails} />}
                <div className="checkout-details-content ">
                  <div className="box-header pt-4 pb-3 ml-3" style={{ fontWeight: 600 }}>
                    Transactions ({paging ? paging.total_items : 0})
                  </div>
                  <Table
                    hasPagination
                    tableClassName="--payment-link-details-table"
                    headings={[
                      {
                        value: 'Status'
                      },
                      {
                        value: 'Transaction Id'
                      },
                      {
                        value: 'Customer'
                      },
                      {
                        value: 'Transaction Date'
                      },
                      {
                        value: 'Amount'
                      }
                    ]}
                    totalItems={paging?.total_items || 0}
                    pageSize={paging?.page_size || 0}
                    loading={trxnsLoading}
                    current={paging?.current}
                    limitAction={value => searchQuery.setQuery({ limit: String(value) })}
                    actionFn={p => searchQuery.setQuery({ page: String(p) })}
                    annotation="transactions"
                    hidden={linkTransactions?.data.length < 1}
                    emptyStateHeading="There are no transactions yet"
                    emptyStateMessage="Copy and share your payment link with customers to start seeing transactions."
                    background="#f5f6f6"
                  >
                    <Transactions />
                  </Table>
                </div>
                {state.modalVisible && (
                  <Modal
                    size="sm"
                    close={() => setState({ ...state, modalVisible: false })}
                    visible={state.modalVisible}
                    heading={state.modalHeader}
                    themeColor={state.modalType === 'delete' ? '#e65252' : null}
                    description={modalContent(state.modalType)}
                    secondButtonAction={state.modalAction}
                    completedHeading="Payment Link Updated"
                    completedDescription={`This payment link has been succesfully ${state.modalType === 'delete' ? 'deleted' : 'updated'}`}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
