import { logError } from '+utils';

export function IsJsonString(str: string) {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
}

export class Storage {
  static getItem(item: string) {
    const value = sessionStorage.getItem(item);
    if (value === undefined || value === null) return null;
    return value;
  }

  static setItem(item: string, itemValue: string | null) {
    try {
      let value = itemValue ?? "";
      if (typeof itemValue === 'object' && itemValue !== null) {
        value = JSON.stringify(itemValue);
      }
      sessionStorage.setItem(item, value);
    } catch (error) {
      logError(error);
    }
  }

  static removeItem(item: string) {
    try {
      sessionStorage.removeItem(item);
    } catch (error) {
      logError(error);
    }
  }

  static checkExpiration() {
    let userTokenExpiration;
    try {
      userTokenExpiration = Storage.getItem('Korapay_Merchant_userTokenExpiration');
    } catch (error) {
      return null;
    }
    return userTokenExpiration || null;
  }
}
