/* eslint-disable react/jsx-props-no-spreading */
import { useEffect, useRef, useState } from 'react';
import Flags from 'country-flag-icons/react/3x2';
import { useMutation } from 'react-query';
import Select, { components, GroupBase, MultiValueProps, OptionProps, StylesConfig, ValueContainerProps } from 'react-select';
import { AxiosError } from 'axios';

import ToolTip from '+containers/Dashboard/Shared/Tooltip';
import Modal from '+dashboard/Shared/Modal';
import { closeFeedback, feedbackInit } from '+hooks/feedbackHandler';
import APIRequest from '+services/api-services';
import { logError, queryClient } from '+utils';

import {
  EventType,
  IAvailableCountry,
  IdReqPayloadType,
  IdRequestData,
  ILabeledValue,
  IVerificationType,
  SubValueType
} from '../../types/types';

import InfoSymbol from '+assets/img/dashboard/info-symbol.png';

import './IdAccessRequestModal.scss';

const api = new APIRequest(process.env.REACT_APP_PUBLIC_MERCHANT_MIDDLEWARE_API_BASE);
export interface IdAccessRequestModalProps {
  onClose: () => void;
  onAccessRequested: (status: boolean) => void;
  requestsInfo: IdRequestData;
}

const colourStyles: StylesConfig<any, true> = {
  control: styles => ({ ...styles, backgroundColor: '#fff' }),
  option: styles => {
    return {
      ...styles,
      backgroundColor: '#fff',

      ':active': {
        ...styles[':active'],
        backgroundColor: '#fff'
      }
    };
  }
};

const Option = (props: OptionProps<IAvailableCountry, boolean, GroupBase<IAvailableCountry>>) => {
  const Flag = Flags[props.data.value.toUpperCase() as keyof typeof Flags];
  return (
    <div>
      <components.Option {...props}>
        <div className="form-check mb-1" onClick={() => null}>
          <input
            checked={props.isSelected}
            onChange={() => null}
            className="form-check-input"
            type="checkbox"
            value=""
            id="defaultCheck1"
          />
          <Flag width={20} height={15} className="mr-1 mb-0 mt-1" />
          <span onClick={() => null} className="form-check-label mb-0 mt-1">
            {props.label}
          </span>
        </div>
      </components.Option>
    </div>
  );
};

const MultiValue = (props: MultiValueProps<IAvailableCountry, boolean, GroupBase<IAvailableCountry>>) => {
  const Flag = Flags[props.data.value.toUpperCase() as keyof typeof Flags];
  return (
    <components.MultiValue {...props}>
      <Flag width={20} height={15} className="mr-1 mb-1" />
      <span>{props.data.label}</span>
    </components.MultiValue>
  );
};

const ValueContainer = ({ children, ...props }: ValueContainerProps<unknown, boolean, GroupBase<unknown>>) => {
  let [values, input] = children as any;

  let displayValue = values;

  if (values?.length > 3) {
    displayValue = [values[0], values[1], values[2], `+${values?.length - 3} more`];
  }

  return <components.ValueContainer {...props}>{[displayValue, input]}</components.ValueContainer>;
};
function IdAccessRequestModal({ onClose, requestsInfo, onAccessRequested }: IdAccessRequestModalProps) {
  const [selectedCountries, setSelectedCountries] = useState<IAvailableCountry[]>([]);
  const [isOtherSelected, setIsOtherSelected] = useState<boolean>(false);
  const [isTermsSelected, setIsTermsSelected] = useState<boolean>(false);
  const [isKYCCountrySelected, setIsKYCCountrySelected] = useState<boolean>(false);
  const [isKYBCountrySelected, setIsKYBCountrySelected] = useState<boolean>(false);
  const [isSelectedUseCaseValid, setIsSelectedUseCaseValid] = useState<boolean>(false);
  const [otherValue, setOtherValue] = useState<string>('');
  const [selectVerificationCategory, setSelectVerificationCategory] = useState<Record<keyof typeof EventType, boolean>>({
    kyc: false,
    kyb: false
  });
  const [isVerificationTypeSelected, setIsVerificationTypeSelected] = useState<boolean>(false);

  const [reqPayload, setReqPayload] = useState<IdReqPayloadType>({} as IdReqPayloadType);

  let kybCountries = requestsInfo.available_countries.filter(country => country.verification_types.some(type => type.label === 'KYB'));
  let kycCountries = requestsInfo.available_countries.filter(country => country.verification_types.some(type => type.label === 'KYC'));

  const seletctedUsecases = useRef<Record<keyof typeof EventType, string[]>>({
    kyc: [],
    kyb: []
  });

  const sendRequest = useMutation(async () => api.requestIdentityAccess(reqPayload), {
    onSuccess: data => {
      onAccessRequested(true);
    },
    onError: (e: Error | AxiosError) => {
      logError(e);
      feedbackInit({
        componentLevel: true,
        message: e.response?.data.message || 'We were unable to process your request. Please try again',
        type: 'danger'
      });
      setTimeout(() => {
        closeFeedback();
      }, 2500);
    }
  });

  const updateVerificationType = () => {
    let updatedRes = reqPayload;
    selectedCountries.forEach((country: IAvailableCountry) => {
      const countryAbbr = country.value;
      country.verification_types.forEach((verificationType: IVerificationType) => {
        const type = verificationType.value as keyof typeof EventType;
        const verificationEnabledValue = {
          [verificationType.value]: {
            enable: selectVerificationCategory[type]
          }
        };

        updatedRes = {
          ...updatedRes,
          [countryAbbr]: {
            ...updatedRes[countryAbbr],
            [verificationType.value]: {
              ...(updatedRes[countryAbbr][type] as object),
              ...verificationEnabledValue[verificationType.value]
            }
          }
        };

        if ((updatedRes[countryAbbr][type] as SubValueType)?.enable === false) {
          delete updatedRes[countryAbbr][type];
        }
      });
    });
    setReqPayload(updatedRes);
  };

  useEffect(() => {
    if (selectVerificationCategory.kyb || selectVerificationCategory.kyc) {
      setIsVerificationTypeSelected(true);
    } else {
      setIsVerificationTypeSelected(false);
    }
  }, [selectVerificationCategory]);

  const updateFutureUseCases = (value: string, isChecked: boolean, isOther?: boolean) => {
    const currentValues = reqPayload['future_use_cases'] || [];

    if (isOther) {
      const index = currentValues.indexOf(otherValue);
      if (index >= 0) {
        currentValues.splice(index, 1);
      }
    }

    if (isChecked) {
      if (value === 'other') {
        setIsOtherSelected(isChecked);
        return;
      }
      currentValues.push(value);
    } else {
      let val = value;
      if (value === 'other') {
        setIsOtherSelected(isChecked);
        if (otherValue === '') {
          return;
        }
        val = otherValue;
        setOtherValue('');
      }
      const index = currentValues.indexOf(value);
      currentValues.splice(index, 1);
    }

    setReqPayload(prev => ({
      ...prev,
      future_use_cases: currentValues
    }));
  };

  // Always update the kyc/kyb enable value when the selected countries change
  useEffect(() => {
    if (selectedCountries.length > 0) {
      updateVerificationType();
      setIsKYBCountrySelected(kybCountries.some(value => selectedCountries.includes(value)));
      setIsKYCCountrySelected(kycCountries.some(value => selectedCountries.includes(value)));
    } else {
      setIsKYBCountrySelected(false);
      setIsKYCCountrySelected(false);
    }
  }, [selectVerificationCategory, selectedCountries]);

  // Reset the verification category when the selected countries change
  useEffect(() => {
    if (selectedCountries.length < 1) {
      setSelectVerificationCategory({
        kyc: false,
        kyb: false
      });
    }
  }, [selectedCountries, setSelectVerificationCategory]);

  useEffect(() => {
    let isValidCountrySelection = true;
    let isValidUseCases = true;

    selectedCountries.forEach(country => {
      if (!isValidCountrySelection) return;
      let countryUseCases: string[] = [];
      Object.keys(reqPayload[country.value]).forEach(label => {
        if (label === 'enable') {
          return;
        }
        countryUseCases = [...countryUseCases, ...Object.keys(reqPayload[country.value][label])].filter(lb => lb !== 'enable');
      });
      if (countryUseCases.length < 1) {
        isValidCountrySelection = false;
        return;
      }
    });

    // Ensure atleast a kyc usecase is selected if kyb options is selected.
    if (selectVerificationCategory.kyc) {
      isValidUseCases = !!seletctedUsecases.current.kyc.length;
    }
    if (selectVerificationCategory.kyb) {
      isValidUseCases = !!seletctedUsecases.current.kyb.length;
    }

    setIsSelectedUseCaseValid(isValidUseCases && isValidCountrySelection);
  }, [reqPayload, selectedCountries]);

  const updateVerificationUseCase = (country: string, type: keyof typeof EventType, useCase: string, value: boolean) => {
    let useCases = seletctedUsecases.current[type];
    if (value) {
      useCases = [...useCases, useCase];
      seletctedUsecases.current[type] = useCases;
    } else {
      useCases = useCases.filter(useCaseItem => useCaseItem !== useCase);
      seletctedUsecases.current[type] = useCases;
    }
    let currentType = reqPayload[country][type];

    if (!value) {
      delete currentType[useCase];
    } else {
      currentType = {
        ...currentType,
        [useCase]: {
          enable: value
        }
      };
    }

    setReqPayload(prev => ({
      ...prev,
      [country]: {
        ...reqPayload[country],
        [type]: {
          ...currentType
        }
      }
    }));
  };

  const renderInitialForm = () => (
    <div className="id-access-modal">
      {/* Countries of interest */}
      <div className="mb-4">
        <h6 className="title">Country(s) of Interest</h6>
        <p className="subtitle mb-4">
          Select the country or countries where you intend to perform Know Your Customer (KYC) or Know Your Business (KYB) procedures.
        </p>

        <Select
          isMulti
          name="countries"
          hideSelectedOptions={false}
          backspaceRemovesValue={false}
          closeMenuOnSelect={false}
          placeholder="Select country(s)"
          isClearable={false}
          onChange={(value, action) => {
            const response = reqPayload;
            setSelectedCountries(value as IAvailableCountry[]);

            if (action.action === 'deselect-option' || 'remove-value') {
              delete response[action?.option?.value ?? action?.removedValue?.value];
            }
            if (action.action === 'select-option') {
              {
                response[action.option.value] = {
                  enable: true
                };
              }
            }

            setReqPayload(response);
          }}
          options={requestsInfo.available_countries}
          components={{ ValueContainer, MultiValue, Option }}
          styles={colourStyles}
          className="basic-multi-select"
          classNamePrefix="select"
        />
      </div>

      {/* Verification Categories */}

      <div className="row mt-5">
        <div className="col-12">
          <h6 className="title">Verification Category</h6>
          {Object.entries(EventType).map(([key, value]) => {
            const disabled = key === 'kyb' ? !isKYBCountrySelected : !isKYCCountrySelected;
            return (
              <>
                <div className="form-check mt-1" key={key}>
                  <input
                    className="form-check-input"
                    type="checkbox"
                    value={key}
                    checked={selectVerificationCategory[key as keyof typeof EventType] === true}
                    id={key}
                    disabled={disabled}
                    onChange={e => {
                      if (selectedCountries.length < 1) return;
                      const isChecked = e.target.checked;
                      const value = e.target.value;
                      setSelectVerificationCategory({
                        ...selectVerificationCategory,
                        [value]: isChecked
                      });
                    }}
                  />
                  <label className=" text-dark font-weight-normal form-check-label mt-1" htmlFor={key}>
                    {value}
                  </label>
                </div>
                {value === EventType.kyb && <small>This category is currently available only in Nigeria.</small>}
              </>
            );
          })}
        </div>
      </div>

      {/* Available use case */}
      <div className="row mt-5 mb-5">
        <div className="col-12">
          <h6 className="title">Available Use Case</h6>
          <p className="use-case-title mb-2">
            <span>ID Verification & Lookup</span>
            <ToolTip
              image={InfoSymbol}
              message={<em>Verify identity documents and/or compare the data submitted with data extracted from the documents</em>}
            />
          </p>
          <p className="subtitle m-0">
            Select available verification type(s) based on your country(s) of interest and verification category for this use case.
          </p>

          {selectedCountries.map((country: IAvailableCountry) => (
            <div className="row mt-4 border-bottom pb-3">
              <div className="col-4 pr-0">
                <p className="country-text">{country.label}</p>
              </div>{' '}
              <div className="col-8 pl-0">
                <div className="row">
                  {country.verification_types.map((verificationType: IVerificationType) => (
                    <div className="col-12">
                      <small className="bold">
                        Know Your {verificationType.value === 'kyb' ? 'Business' : 'Customer'} ({verificationType.label})
                      </small>

                      {verificationType.identity_types.map((identityType: ILabeledValue) => (
                        <div className="form-check mb-1">
                          <input
                            className="form-check-input usecases-input"
                            type="checkbox"
                            value={identityType.value}
                            checked={reqPayload[country.value]?.[verificationType.value]?.[identityType.value]?.enable === true}
                            id={identityType.value}
                            disabled={selectedCountries.length < 1 || reqPayload[country.value]?.[verificationType.value]?.enable !== true}
                            onChange={e => {
                              const isChecked = e.target.checked;
                              const value = e.target.value;
                              updateVerificationUseCase(country.value, verificationType.value as keyof typeof EventType, value, isChecked);
                            }}
                          />
                          <label className=" text-dark font-weight-normal form-check-label mt-1" htmlFor={identityType.value}>
                            {identityType.label}
                          </label>
                        </div>
                      ))}
                    </div>
                  ))}
                </div>
              </div>
            </div>
          ))}

          {selectedCountries.length < 1 && (
            <p className="font-italic font-weight-light mt-4 light-text">
              Select a country of interest to see verification types for this use case.
            </p>
          )}
        </div>
      </div>

      {/* Future use cases */}
      <div className="mb-5">
        <h6 className="title">Future Use Cases</h6>
        <p className="subtitle mb-4">
          While these use cases are not yet available, choosing the ones you would like to see on our platform will help us prioritize their
          development.
        </p>

        {/* Other Countries */}
        <div className="mb-4">
          <h6 className="title">
            Preferred Country(s)
            <ToolTip
              image={InfoSymbol}
              message={
                <em>Although your preferred country may not be available right now, informing us will help us prioritize adding it.</em>
              }
            />
          </h6>

          <Select
            isMulti
            name="preferred_countries"
            hideSelectedOptions={false}
            isDisabled={selectedCountries.length < 1}
            backspaceRemovesValue={false}
            closeMenuOnSelect={false}
            placeholder="Tell us your preferred country(s)..."
            isClearable={false}
            onChange={(value, action) => {
              const selectedValues: string[] = value.map((country: IAvailableCountry) => country.value);
              if (selectedValues.length < 1) {
                setReqPayload(prev => {
                  const { preferred_countries, ...rest } = prev;
                  return rest;
                });
              } else {
                setReqPayload(prev => ({
                  ...prev,
                  preferred_countries: selectedValues
                }));
              }
            }}
            options={requestsInfo.other_countries}
            components={{ ValueContainer, MultiValue, Option }}
            styles={colourStyles}
            className="basic-multi-select"
            classNamePrefix="select"
          />
        </div>

        <div className="row">
          <div className="col-12">
            {requestsInfo.other_verification_classes.map((verification: any) => (
              <div className="form-check mb-1">
                <input
                  className="form-check-input"
                  type="checkbox"
                  disabled={selectedCountries.length < 1}
                  value={verification.value}
                  id={verification.label}
                  onChange={e => {
                    updateFutureUseCases(e.target.value, e.target.checked);
                  }}
                />
                <label className=" text-dark font-weight-normal form-check-label mt-1" htmlFor={verification.label}>
                  {verification.label}
                </label>
              </div>
            ))}
            <div className="form-check">
              <input
                className="form-check-input"
                type="checkbox"
                value="other"
                disabled={selectedCountries.length < 1}
                id="other"
                onChange={e => {
                  updateFutureUseCases(e.target.value, e.target.checked);
                }}
              />
              <label className=" text-dark font-weight-normal form-check-label  mt-1" htmlFor="other">
                Other
              </label>
            </div>
            {isOtherSelected && (
              <div className="mt-1">
                <small className="mt-1 mb-1">Enter your preferred use case if it's not listed above</small>
                <input
                  type="text"
                  className="form-control w-100"
                  id="other"
                  placeholder="Tell us your specific use case..."
                  onChange={e => {
                    updateFutureUseCases(e.target.value, true, true);
                    setOtherValue(e.target.value);
                  }}
                />
              </div>
            )}
          </div>
        </div>
      </div>

      {/* Terms and Conditions */}
      <div className="mb-2">
        <h6 className="title">Terms and conditions</h6>
        <div className="row">
          <div className="col-12">
            <div className="form-check align-items-start">
              <input
                className="form-check-input"
                type="checkbox"
                value="terms"
                id="terms"
                maxLength={50}
                checked={isTermsSelected}
                disabled={selectedCountries.length < 1}
                onChange={e => {
                  setIsTermsSelected(e.target.checked);
                }}
              />
              <label className="form-check-label text-dark font-weight-normal" htmlFor="terms">
                I have read and I accept{' '}
                <a
                  href="https://www.korahq.com/terms-of-use"
                  className="font-weight-bold"
                  target="noopener noreferrer"
                  style={{ marginLeft: '0.3rem' }}
                >
                  Kora&apos;s terms of service
                </a>{' '}
                and{' '}
                <a
                  href="https://www.korahq.com/merchant-service"
                  className="font-weight-bold"
                  target="noopener noreferrer"
                  style={{ marginLeft: '0.3rem' }}
                >
                  Kora&apos;s Identity service agreement.
                </a>
              </label>
            </div>
          </div>
        </div>
      </div>
    </div>
  );

  const modalPropOptions: Partial<Record<'shared' | 'initial', Record<string, any>>> = {
    shared: {
      close: () => {
        onClose();
      },
      firstButtonAction: () => {
        onClose();
      },
      secondButtonAction: async () => {
        queryClient.invalidateQueries('MERCHANT_IDENTITY_ACCESS');
        await sendRequest.mutateAsync();
      },
      secondButtonActionIsTerminal: true,
      completedHeading: 'Request submitted',
      completedDescription: (
        <>
          <p className="mb-4">Your request for identity service access has been submitted successfully.</p>
          <p>
            Our team will review your request and get back to you shortly. You will receive an email notification once your access has been
            granted.
          </p>
        </>
      )
    },
    initial: {
      heading: 'Request for Access to Identity Service',
      description:
        'Please fill out the form below to request access to our identity verification service. Providing accurate information will help us process your request quickly.',
      secondButtonDisable:
        selectedCountries.length < 1 ||
        !isTermsSelected ||
        !isVerificationTypeSelected ||
        !isSelectedUseCaseValid ||
        (isOtherSelected && !otherValue),
      content: renderInitialForm(),
      secondButtonText: 'Done'
    }
  };

  const modalProps = {
    ...modalPropOptions.shared,
    ...modalPropOptions.initial
  };

  return (
    <div>
      <Modal {...modalProps} />
    </div>
  );
}

export default IdAccessRequestModal;
