import { ReactNode, useState } from 'react';
import { useQuery, useQueryClient } from 'react-query';

import Table from '+containers/Dashboard/Shared/Table';
import { Tab, TabList, TabPanel, TabPanels, Tabs } from '+containers/Shared/Tabs';
import { useFeedbackHandler, useSearchQuery } from '+hooks';
import { Currency } from '+types';
import { formatAmount, logError } from '+utils';
import useStore from '+store';

import Filter from '../components/Filter/Filter';
import FundIssuingWalletModal from '../components/FundIssuingWalletModal';
import Summary from '../components/Summary';
import { api, balanceSubtabsTableProps, computeTableTitle, exportActionOptions, getBalanceSubtabsFilterProps, publicApi } from '../constants';
import { useFetchIssuanceTables, useFetchIssuingExport } from '../hooks';
import { BalanceSubtabKeyType, IError } from '../types';
import { getIssuingPermissions } from '../utils';
import FundingDepositsRow from './components/FundingDepositsRow';
import IssuingHistoryRow from './components/IssuingHistoryRow';

const subtabs: Array<{ label: string; key: BalanceSubtabKeyType; renderElement: (arg: any) => ReactNode }> = [
  {
    label: 'Issuing Balance History',
    key: 'issuing_balance_history',
    renderElement: ({ rowData, currency }: { rowData: any; currency: Currency }) => (
      <IssuingHistoryRow rowData={rowData} currency={currency} />
    )
  },
  {
    label: 'Funding Deposits',
    key: 'funding_deposits',
    renderElement: ({ rowData, currency }: { rowData: any; currency: Currency }) => (
      <FundingDepositsRow rowData={rowData} currency={currency} />
    )
  }
];

const IssuingBalance = () => {
  const queryClient = useQueryClient();
  const { feedbackInit } = useFeedbackHandler();
  const { value: searchQueryValue, setQuery } = useSearchQuery();
  const limit = searchQueryValue?.limit ?? '10';
  const page = searchQueryValue?.page ?? '1';
  const { merchantEnv: MERCHANT_ENV } = useStore.getState() as { merchantEnv: string };
  const subtab = (searchQueryValue?.subtab as BalanceSubtabKeyType) ?? subtabs[0].key;
  const currency = (searchQueryValue?.currency as Currency) ?? 'USD';
  const exportAction = useFetchIssuingExport({ resourceType: `All-${subtab}`, exportFn: exportActionOptions[subtab], currency });
  const [recordIsFiltered, setRecordIsFiltered] = useState(false);
  const [showWalletFunding, setShowWalletFunding] = useState(false);
  const { canViewWalletHistory, canViewWalletBalance, canFundWallet } = getIssuingPermissions();

  const { tableData, isFetching: isFetchingTableData, refetch: refetchTableData } = useFetchIssuanceTables({
    currency,
    page,
    limit,
    tab: subtab,
    queryValue: searchQueryValue
  });

  const { data: walletBalance, refetch: refetchBalance } = useQuery(
    ['ISSUING_BALANCE', MERCHANT_ENV],
    () => publicApi.fetchIssuingBalance(currency as string),
    {
      onSuccess: data => useStore.setState({ issuingBalance: { ...data } }),
      onError: (e: IError) => {
        logError(e);
        const message = e.response?.data?.message;
        feedbackInit({
          message,
          type: 'danger',
          action: {
            action: refetchBalance,
            name: 'Try again'
          }
        });
      },
      enabled: canViewWalletBalance
    }
  );

  const { data: balances, refetch: refetchBalances } = useQuery(['MERCHANT_BALANCES', MERCHANT_ENV], () => api.getBalances(), {
    onError: (e: IError) => {
      logError(e);
      const message = e.response?.data?.message || 'There has been an error in getting your balance';
      feedbackInit({
        message,
        type: 'danger',
        action: {
          action: refetchBalances,
          name: 'Try again'
        }
      });
    },
    enabled: canFundWallet
  });

  const { refetch: refetchFees } = useQuery(['ISSUING_FEES', MERCHANT_ENV], () => api.fetchSubscriptionFees(), {
    onSuccess: data => useStore.setState({ issuingFees: { ...data } }),
    onError: (e: IError) => {
      logError(e);
      const message = e.response?.data?.message || 'There has been an error in getting issuing subscription fees';
      feedbackInit({
        message,
        type: 'danger',
        action: {
          action: refetchFees,
          name: 'Try again'
        }
      });
    }
  });

  const paging = tableData?.data?.paging;
  const availableBalance = walletBalance?.[currency]?.available_balance;
  const tableProps = balanceSubtabsTableProps[subtab];
  const filterProps = getBalanceSubtabsFilterProps()[subtab];

  return (
    <div>
      <div className="d-flex w-100 flex-wrap flex-sm-nowrap">
        <div className="flex-grow-1 info-summary-container">
          <Summary
            label={`Issuing Balance (${currency})`}
            value={availableBalance}
            valueFormatter={formatAmount}
            description="For card issuing and transactions"
          />
        </div>

        <div>
          <div className="page-action-btns">
            <button onClick={() => setShowWalletFunding(true)} className="btn btn-primary p-2" type="button">
              <span className="os-icon os-icon-plus" />
              <span>Add Funds</span>
            </button>
          </div>
        </div>
      </div>
      <Tabs defaultValue={subtab} onChange={subtabValue => setQuery({ tab: searchQueryValue.tab, subtab: subtabValue })}>
        <div>
          <TabList className="mt-4">
            {subtabs.map(({ key, label }) => (
              <Tab value={key} key={`${key}-tab`}>
                {label}
              </Tab>
            ))}
          </TabList>
          <div className="divide-y" />
        </div>

        <TabPanels>
          {subtabs.map(({ key, renderElement }) => {
            return (
              <TabPanel key={`${key}-panel`} value={key} className="transaction_table_comp table-container">
                <Filter
                  totalItems={Number(paging?.total_items)}
                  title={computeTableTitle({
                    filtered: recordIsFiltered,
                    activeTab: subtab as string,
                    pageItemCount: Number(paging?.total_items)
                  })}
                  actions={filterProps.actions}
                  filterModalHeading={filterProps.filterModalHeading}
                  filterModalDescription={filterProps.filterModalDescription}
                  filterFields={filterProps.filterFields}
                  exportType={filterProps.exportType}
                  exportHeading={filterProps.exportHeading}
                  exportDescription={filterProps.exportHeading}
                  onChangeIsFiltered={setRecordIsFiltered}
                  isFiltered={recordIsFiltered}
                  exportAction={exportAction}
                />

                <Table
                  tableClassName={`--history-table ${tableProps.tableClassName}`}
                  headings={tableProps.headings}
                  hasPagination
                  borderedTable
                  loading={isFetchingTableData}
                  current={paging?.current}
                  limitAction={value => setQuery({ limit: String(value) })}
                  pageSize={paging?.page_size}
                  actionFn={value => setQuery({ page: String(value) })}
                  totalItems={paging?.total_items || 0}
                  emptyStateHeading={tableProps.emptyStateHeading}
                  emptyStateMessage={
                    <>
                      <span>{tableProps.emptyStateMessage}</span>

                      {canViewWalletHistory && (
                        <button type="button" className="refetch-button" onClick={() => refetchTableData()}>
                          <i className="os-icon os-icon-rotate-ccw" style={{ marginRight: '5px' }} />
                          Refresh
                        </button>
                      )}
                    </>
                  }
                >
                  {renderElement({ rowData: tableData?.data?.data, currency })}
                </Table>
              </TabPanel>
            );
          })}
        </TabPanels>
      </Tabs>

      {showWalletFunding ? (
        <FundIssuingWalletModal
          balances={balances?.data as Partial<Record<Currency, { available_balance: number }>>}
          currency={currency}
          onClose={() => setShowWalletFunding(false)}
          onFundingSuccess={() => {
            queryClient.invalidateQueries('ISSUING_BALANCE');
            queryClient.invalidateQueries('MERCHANT_BALANCES');
            queryClient.invalidateQueries('ISSUANCE_BAL_HIST');
            queryClient.invalidateQueries('ISSUANCE_FUNDING_DEPOSITS');
          }}
        />
      ) : null}
    </div>
  );
};

export default IssuingBalance;
