import React from 'react';

import { Currency } from '+types';

import { CardChargebacksResponseType, CardEventsResponseType, CardTransactionsResponseType } from '../../types';
import BalanceHistoryRow from '../components/BalanceHistoryRow';
import ChargebacksRow from '../components/ChargebacksRow';
import EventsRow from '../components/EventsRow';
import TransactionsRow from '../components/TransactionsRow';

interface ICardDetailsTableProps {
  canExportCardTrxns?: boolean;
  canExportEvents?: boolean;
  canExportChargebacks?: boolean;
  currency?: Currency;
}
export const cardDetailsTableProps = ({ canExportCardTrxns, canExportEvents, canExportChargebacks, currency }: ICardDetailsTableProps) => ({
  transactions: {
    tableTitle: 'Card Transactions',
    headings: [{ value: 'Status' }, { value: 'Transaction ID' }, { value: 'Type' }, { value: 'Date/Time' }, { value: 'Amount' }],
    emptyStateHeading: 'No transactions found',
    emptyStateMessage: 'It seems there are no transactions here.',
    tableClassName: '',
    renderChildren: (rowData: Array<CardTransactionsResponseType>) =>
      React.createElement(TransactionsRow, {
        rowData
      }),
    tableActions: [...(canExportCardTrxns ? ['export'] : []), 'filter']
  },
  balance_history: {
    tableTitle: 'Balance Histories',
    headings: [
      { value: 'Date' },
      { value: 'Details' },
      { value: `Amount (${currency ?? 'N/A'})` },
      { value: `Balance After (${currency ?? 'N/A'})` }
    ],
    emptyStateHeading: 'No record found',
    emptyStateMessage: 'It seems there is no balance history information to show.',
    tableClassName: 'balance-history-table',
    renderChildren: (rowData: Array<CardTransactionsResponseType>) =>
      React.createElement(BalanceHistoryRow, {
        rowData,
        currency
      }),
    tableActions: ['filter']
  },
  events: {
    tableTitle: 'Events',
    headings: [{ value: 'Date' }, { value: 'Event' }, { value: 'Event ID' }, { value: 'Description' }],
    emptyStateHeading: 'No event found',
    emptyStateMessage: 'It seems there are no card events here.',
    tableClassName: 'balance-history-table events-table',
    renderChildren: (rowData: Array<CardEventsResponseType>) => React.createElement(EventsRow, { rowData }),
    tableActions: [...(canExportEvents ? ['export'] : [])]
  },

  chargebacks: {
    tableTitle: 'Chargebacks',
    headings: [
      { value: 'Status' },
      { value: 'Chargeback ID' },
      { value: 'Vendor' },
      { value: 'Cardholder' },
      { value: 'Date Escalated' },
      { value: 'Chargeback Amount' }
    ],
    emptyStateHeading: 'No chargeback found',
    emptyStateMessage: 'It seems there are no chargebacks here.',
    tableClassName: 'issued-cards-table',
    renderChildren: (rowData: Array<CardChargebacksResponseType>) => React.createElement(ChargebacksRow, { rowData, currency }),
    tableActions: [...(canExportChargebacks ? ['export'] : []), 'filter']
  }
});
