
import { getDate, getTime, capitalize, formatAmount, capitalizeRemovedash, history, switchStatus } from '+utils';

export const conversionTableData = (data: string[]) => {

  const onRowClick = (id: string) => {
    return history.push(`/dashboard/conversions/${id}`);
  };

  return (
    <div>
      {data?.map((item: any) => {
        return (
          <div
            tabIndex={0}
            className="div-table --history-table --row"
            key={item.reference}
            role="button"
            onClick={() => onRowClick(item.reference)}
            onKeyDown={() => onRowClick(item.reference)}
          >
            <div className="--txn-status" style={{ display: 'flex', alignItems: 'center' }}>
              <span className="body-row-header">Status:</span>
              <span className={`status-pill smaller ${switchStatus(item?.status)}`} />
              <span>{capitalizeRemovedash(item.status)}</span>
            </div>
            <div>
              <span className="body-row-header">Conversions ID:</span>
              <span>{capitalize(item.reference)}</span>
            </div>
            <div>
              <span className="body-row-header">Converted from:</span>
              <span>{`${formatAmount(item?.source_amount)} ${item?.source_currency}`}</span>
            </div>
            <div>
              <span className="body-row-header">Converted to:</span>
              <span>
                <span className={item?.status === 'success' ? 'destination_amount' : ''}>{`${formatAmount(item?.converted_amount)} `}</span> {' '}
                {item?.destination_currency}
              </span>
            </div>
            <div>
              <span className="body-row-header">Date/Time:</span>
              <span>
                {getDate(item.created_at)}
                <span className="annotation trxn-date">{getTime(item.transaction_date)}</span>
              </span>
            </div>
          </div>
        );
      })}
    </div>
  );
};
