import React from 'react';
import useStore from '+store';
import './index.scss';
const WithdrawWarning = () => {
  const MERCHANT_ENV = useStore(state => state.merchantEnv);
  return (
    MERCHANT_ENV === 'test' && (
      <div className="element-box withdraw-warning-note px-3 py-2">
        <p>
          <span>Please Note:</span> You are currently in <span>Test Mode!</span> The operations conducted here are simulations, and balances
          do not represent actual withdrawable funds.
        </p>
      </div>
    )
  );
};

export default WithdrawWarning;
