/* eslint-disable no-plusplus */
/* eslint-disable no-unused-vars */
/* eslint-disable react/require-default-props */
import React, { createContext, forwardRef, useCallback, useContext, useMemo, useState } from 'react';
import composeClassNames from 'clsx';

type TabsContextType = {
  tabsId: string | number;
  isTabActive: (arg: string) => boolean;
  onSelectTab: (arg: string) => void;
};

interface ITabs {
  children: React.ReactNode;
  defaultValue: string;
  className?: string;
  onChange?: (arg: string) => void;
}

interface IShared {
  children: React.ReactNode;
  className?: string;
}

interface ITabList extends IShared {}

interface ITab extends IShared {
  value: string;
  onClick?: () => void;
}

interface ITabPanels extends IShared {}

interface ITabPanel extends IShared {
  value: string;
}

let id = 0;
const generateId = () => ++id;
const makeId = (...args: Array<string | number>) => args.filter(val => val != null).join('--');

const TabsContext = createContext<TabsContextType | undefined>(undefined);

function useTabsContext() {
  const context = useContext(TabsContext);
  if (context === undefined) throw new Error(`Tab* Component must be rendered as a child of Tabs`);
  return context;
}

export const Tabs = forwardRef<HTMLDivElement, ITabs>(({ children, defaultValue, className, onChange, ...props }, forwardedRef) => {
  const [activeValue, setActiveValue] = useState(defaultValue);
  const INTERNAL_CLASS_NAME = '';
  const tabsId = makeId('tab', generateId());
  const isTabActive = useCallback((value: string) => value === activeValue, [activeValue]);
  const onSelectTab = useCallback((tabVal: string) => {
    onChange && onChange(tabVal);
    setActiveValue(tabVal);
  }, []);
  const memoizedContext = useMemo(() => ({ tabsId, isTabActive, onSelectTab }), [isTabActive, onSelectTab]);

  return (
    <div className={composeClassNames(INTERNAL_CLASS_NAME, className)} ref={forwardedRef} {...props}>
      <TabsContext.Provider value={memoizedContext}>{children}</TabsContext.Provider>
    </div>
  );
});

export const TabList = forwardRef<HTMLUListElement, ITabList>(({ children, className, ...props }, forwardedRef) => {
  const INTERNAL_CLASS_NAME = 'tabs__tablist';
  return (
    <ul role="tablist" className={composeClassNames(INTERNAL_CLASS_NAME, className)} ref={forwardedRef} {...props}>
      {children}
    </ul>
  );
});

export const Tab = forwardRef<HTMLButtonElement, ITab>(({ children, value, className, ...props }, forwardedRef) => {
  const INTERNAL_CLASS_NAME = 'tabs__tab';
  const { tabsId, isTabActive, onSelectTab } = useTabsContext();
  const panelId = makeId(tabsId, 'panel', value);
  const controllerId = makeId(tabsId, 'controller', value);
  const isActive = isTabActive(value);
  const onSelect = () => {
    onSelectTab(value);
  };

  return (
    <li>
      <button
        onClick={onSelect}
        type="button"
        id={controllerId}
        aria-controls={panelId}
        aria-selected={isActive}
        tabIndex={isActive ? 0 : -1}
        role="tab"
        className={composeClassNames(INTERNAL_CLASS_NAME, { active: isActive }, className)}
        ref={forwardedRef}
        {...props}
      >
        {children}
      </button>
    </li>
  );
});

export const TabPanels = forwardRef<HTMLDivElement, ITabPanels>(({ children, className, ...props }, forwardedRef) => {
  const INTERNAL_CLASS_NAME = 'tabs__panels';
  return (
    <div className={composeClassNames(INTERNAL_CLASS_NAME, className)} ref={forwardedRef} {...props}>
      {children}
    </div>
  );
});

export const TabPanel = forwardRef<HTMLDivElement, ITabPanel>(({ children, value, className, ...props }, forwardedRef) => {
  const INTERNAL_CLASS_NAME = 'tabs__panel';
  const { tabsId, isTabActive } = useTabsContext();
  const isActive = isTabActive(value);
  const panelId = makeId(tabsId, 'panel', value);
  const controllerId = makeId(tabsId, 'controller', value);

  return isActive ? (
    <div
      tabIndex={isActive ? 0 : -1}
      hidden={!isActive ? true : undefined}
      aria-labelledby={controllerId}
      id={panelId}
      role="tab"
      className={composeClassNames(INTERNAL_CLASS_NAME, className)}
      ref={forwardedRef}
      {...props}
    >
      {children}
    </div>
  ) : null;
});
