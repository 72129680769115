const queryParams = {
  currency: 'currency',
  page: 'page',
  limit: 'limit',
  tab: 'tab',
  subtab: 'subtab',
  sorterType: 'sorterType'
};

export default queryParams;
