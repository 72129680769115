import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

function usePageHeader() {
  const location = useLocation();
  const [header, setHeader] = useState<string | null>('');

  useEffect(() => {
    const heading = getPageHeader();
    setHeader(heading);
  }, [location.pathname]);

  const pageHeaders: Record<string, string> = {
    '/dashboard/home': 'Dashboard',
    '/dashboard/pay-ins': 'Pay-ins',
    '/dashboard/payouts': 'Payouts',
    '/dashboard/virtual-accounts': 'Virtual Accounts',
    '/dashboard/balances': 'Balances',
    '/dashboard/audit-logs': 'Audit Logs',
    '/dashboard/settings/general': 'General',
    '/dashboard/settings/compliance': 'Compliance',
    '/dashboard/settings/teams': 'Team',
    '/dashboard/settings/security': 'Security',
    '/dashboard/settings/api-integrations': 'API Configuration',
    '/dashboard/payment-links': 'Payment Links',
    '/dashboard/settlements': 'Settlements',
    '/dashboard/disputes': 'Disputes',
    '/dashboard/bulk-payout': 'Bulk Payouts',
    '/dashboard/conversions': 'Conversions',
    '/dashboard/identity': 'Identity'
  };

  const partialPageHeaders = [
    { path: '/dashboard/payouts/', header: 'Transaction Details' },
    { path: '/dashboard/pay-ins/', header: 'Transaction Details' },
    { path: '/dashboard/disputes/refunds/', header: 'Refund Details' },
    { path: '/dashboard/disputes/chargebacks/', header: 'Chargeback Details' },
    { path: '/dashboard/virtual-accounts/', header: 'Virtual Account Details' },
    { path: '/dashboard/settings', header: 'Settings' },
    { path: '/dashboard/payment-links/new', header: 'New Payment Link' },
    { path: '/dashboard/payment-links/', header: 'Payment Link Details' },
    { path: '/dashboard/settlements/', header: 'Settlement Details' },
    { path: '/dashboard/issuing', header: 'Issuing' },
    { path: 'dashboard/bulk-payout/', header: 'Bulk Payout Details' },
    { path: 'dashboard/identity/verification/', header: 'Verification Event Details' }
  ];

  const getPageHeader = () => {
    if (pageHeaders[location.pathname]) {
      return pageHeaders[location.pathname];
    }

    const partialHeader = partialPageHeaders.find(({ path }) => location.pathname.includes(path));

    return partialHeader ? partialHeader.header : null;
  };

  return header;
}

export default usePageHeader;
