import React from 'react';

import Cluster from '+containers/Dashboard/Shared/Cluster';
import Copyable from '+containers/Dashboard/Shared/Copyable';
import Modal from '+containers/Dashboard/Shared/Modal';
import Switcher from '+containers/Dashboard/Shared/Switcher';
import { formatCreditCardNumber, isNullish } from '+utils';

import { ICardInfoModal, SchemeType } from '../types';

import mcLogo from '+assets/img/dashboard/mastercard-label.svg';
import visaLogo from '+assets/img/dashboard/visa-label.svg';

const schemeLogoOptions: Record<SchemeType, string> = {
  mastercard: mcLogo,
  visa: visaLogo
};

const CardInfoModal: React.FC<ICardInfoModal> = ({ close, open, cardInfo }) => {
  const { scheme = 'visa', holderName, label, pan, expiryMonth, expiryYear, cvv, billing } = cardInfo || {};

  const renderCardInfo = () => {
    return (
      <div className="card-info stack fade-in">
        <Cluster justify="start" space="0.5em" className="card-info-top">
          {label}
          <img alt={scheme} src={schemeLogoOptions[scheme]} />
        </Cluster>
        <ul className="card-info-body">
          <li>
            <Switcher className="card-info-item">
              <span>Cardholder&apos;s Name</span>
              {!isNullish(holderName) ? <Copyable text={`${holderName?.first_name} ${holderName?.last_name}`} /> : 'Not Available'}
            </Switcher>
          </li>
          <li>
            <Switcher className="card-info-item">
              <span>Card Number</span>
              {!isNullish(pan) ? <Copyable text={pan} textModifier={formatCreditCardNumber} /> : 'Not Available'}
            </Switcher>
          </li>
          <li>
            <Switcher className="card-info-item">
              <span>Expiry Date</span>
              {!isNullish(expiryMonth || expiryYear) ? <Copyable text={`${expiryMonth}/${expiryYear}`} /> : 'Not Available'}
            </Switcher>
          </li>
          <li>
            <Switcher className="card-info-item">
              <span>CVV</span>
              {!isNullish(cvv) ? <Copyable text={cvv} /> : 'Not Available'}
            </Switcher>
          </li>
          <li>
            <Switcher className="card-info-item">
              <span>Billing Address</span>
              {!isNullish(billing) ? (
                <Copyable
                  text={`${billing?.address1}, ${billing?.address2 ? `${billing?.address2}, ` : ''}${billing?.city}, ${billing?.country}`}
                />
              ) : (
                'Not Available'
              )}
            </Switcher>
          </li>
        </ul>
        <Cluster className="card-info-footer" justify="flex-end">
          <button type="button" onClick={close}>
            Close
          </button>
        </Cluster>
      </div>
    );
  };

  return <Modal showButtons={false} visible={open} heading="Card Details" content={renderCardInfo()} close={() => close()} />;
};

export default CardInfoModal;
