import { useQuery } from 'react-query';
import APIRequest from '+services/api-services';

const api = new APIRequest();

export default function useMerchantKYC(onError, onSuccess) {
  return useQuery('MERCHANT_KYC', () => api.fetchMerchantKYC(), {
    retry: false,
    onError,
    onSuccess
  });
}
