import { useState, useEffect } from 'react';
import { useCopyToClipboard } from 'react-use';
import copyIcon from '+assets/img/dashboard/copy-icon-light.svg';
import './index.scss';

interface ICopyableProps {
  text?: string;
  showOnHover?: boolean;
  copyText?: string;
  copyColor?: string;
  spanClassName?: string;
  buttonClassName?: string;
  showCopyText?: boolean;
  textModifier?: (x: any) => any;
}

const Copyable = ({
  text = 'Not Available',
  showOnHover = false,
  copyText = '',
  copyColor = '#4acfb2',
  spanClassName = '',
  buttonClassName = '',
  showCopyText = false,
  textModifier = x => x,
}: ICopyableProps) => {

  const [copied, setCopied] = useState(false);
  const [value, setValue] = useState('');
  const [hovered, setHovered] = useState(false);
  const [, copyToClipboard] = useCopyToClipboard();

  useEffect(() => {
    setValue(textModifier(text));
  }, [text]);

  return (
    <span
      aria-label={`Copy ${text}`}
      role="button"
      data-testid="copyable-span"
      tabIndex={0}
      className={`copyable__comp ${spanClassName}`}
      style={copied ? { color: copyColor } : {}}
      onMouseEnter={() => {
        if (showOnHover) {
          setHovered(true);
          const newValue = `${value.substr(0, value.length - 5)}...`;
          setValue(newValue);
        }
      }}
      onMouseLeave={() => {
        if (showOnHover) {
          setHovered(false);
          setValue(textModifier(text));
        }
      }}
    >
      {value}
      {(hovered || !showOnHover) && (
        <button
          type="button"
          className={`copy-button ${buttonClassName}`}
          style={copied ? { backgroundColor: '#24b314', border: 'none' } : { border: 'none' }}
          hidden={text === 'Not Available'}
          onClick={() => {
            copyToClipboard(text);
            (function _() {
              setCopied(true);
              setTimeout(() => {
                setCopied(false);
                setHovered(false);
                setValue(textModifier(text));
              }, 800);
            })();
          }}
        >
          <img id="copy-icon" src={copyIcon} alt="" />
          {showCopyText && <span>{copied ? copyText || 'Copied!' : 'Copy'}</span>}
        </button>
      )}
    </span>
  );
};

export default Copyable;
