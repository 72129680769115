import React, { useEffect, useState } from 'react';
import DatePicker from 'react-datepicker';
import dayjs from 'dayjs';
import Select from 'react-select';
import makeAnimated from 'react-select/animated';
import 'react-datepicker/dist/react-datepicker.css';
import useStore from '+store';
import { cleanInput, filteredOutObjectProperty, formatCurrency } from '+utils';
import { useReducerState, useSearchQuery } from '+hooks';
import arrowRight from '+assets/img/dashboard/arrow-right.svg';
import calendar from '+assets/img/dashboard/calendar.svg';
import search from '+assets/img/dashboard/search-thin.svg';
import '../index.scss';

const statusOption = {
  transactions: [
    { value: '', label: 'All', color: '#f1e9d3' },
    { value: 'processing', label: 'Processing', color: '#f1e9d3' },
    { value: 'success', label: 'Success', color: '#d5eace' },
    { value: 'flagged', label: 'Flagged', color: '#d5eace' },
    { value: 'failed', label: 'Failed', color: '#e6c8ce' },
    { value: 'expired', label: 'Expired', color: '#d3d9e0' },
    { value: 'rejected', label: 'Rejected', color: '#d3d9e0' }
  ],
  account_holder: [
    { value: '', label: 'All', color: '#f1e9d3' },
    { value: 'approved', label: 'Approved', color: '#f1e9d3' },
    { value: 'pending', label: 'Pending', color: '#e6c8ce' },
    { value: 'rejected', label: 'Rejected', color: '#e6c8ce' },
    { value: 'suspended', label: 'Suspended', color: '#e6c8ce' },
    { value: 'deactivated', label: 'Deactivated', color: '#e6c8ce' }
  ],
  account_number: [
    { value: '', label: 'All', color: '#f1e9d3' },
    { value: 'active', label: 'Active', color: '#f1e9d3' },
    { value: 'pending', label: 'Pending', color: '#e6c8ce' },
    { value: 'suspended', label: 'Suspended', color: '#e6c8ce' },
    { value: 'deactivated', label: 'Deactivated', color: '#e6c8ce' }
  ],
  upgrade_request: [
    { value: '', label: 'All', color: '#f1e9d3' },
    { value: 'pending', label: 'Pending', color: '#e6c8ce' },
    { value: 'approved', label: 'Approved', color: '#f1e9d3' },
    { value: 'declined', label: 'Declined', color: '#e6c8ce' }
  ]
};

const TierStatus = [
  { value: '1', label: 'Tier 1' },
  { value: '2', label: 'Tier 2' }
];

const TypeStatus = [
  { value: 'individual', label: 'Individual' },
  { value: 'corporate', label: 'Corporate' }
];

interface IVirtualAccountsFilterProp {
  totalCount: number;
  Tab?: string;
  filterText?: string;
  type: string;
}

const initialState = {
  status: '',
  tier: '',
  accountType: '',
  currency: '',
  searchTerm: '',
  defaultSearch: '',
  dateCreated: null,
  clearFilter: false,
  selectRef: []
};

const showClearButtonKeys = Object.keys(initialState);
function VirtualAccountsFilter({ totalCount, Tab = 'Account Holder', filterText, type }: IVirtualAccountsFilterProp) {
  const searchQuery = useSearchQuery();

  const activeTab = searchQuery.value.tab || Tab;
  const activeAccountHolderTab = searchQuery.value.accountHolderTab;
  const activeCurrency = searchQuery.value.currency || 'NGN';
  const [state, setState] = useReducerState(initialState);
  const [selectRef, setSelectRef] = useState([]);

  const availableCurrencies = useStore(storedState => storedState.availableCurrencies);
  useEffect(() => {
    const filterKeys = Object.keys(searchQuery.value);
    const showKeys = showClearButtonKeys.some(key => filterKeys.indexOf(key) !== -1);
    if (filterKeys.length > 0 && showKeys) {
      setState({
        clearFilter: true,
        status: searchQuery.value.status || '',
        tier: searchQuery.value.tier || '',
        accountType: searchQuery.value.accountType || '',
        currency: searchQuery.value.currency || '',
        searchTerm: searchQuery.value.searchTerm || '',
        dateCreated: searchQuery.value.startDate || null
      });
    } else {
      setState({ ...state, clearFilter: false });
    }
  }, [searchQuery.value]);

  const handleClearFilter = async () => {
    setState(initialState);
    searchQuery.clearAll(['tab', 'page']);
  };

  const animatedComponents = makeAnimated();

  const filterTransactions = async () => {
    const values = filteredOutObjectProperty(state, ['clearFilter']);
    return searchQuery.setQuery({
      ...values,
      dateCreated: state.dateCreated,
      tier: state.tier,
      accountType: state.accountType,
      currency: state.currency,
      searchTerm: state.searchTerm,
      status: state.status
    });
  };

  return (
    <section className="virtual_account-filter" data-testid="virtual_account-filter-wrapper">
      <div className="virtual_account-filter__top">
        <div>
          <span style={{ color: '#414F5F' }}>
            ({totalCount || 0}) {activeTab}
          </span>
          {state.clearFilter && (
            <>
              <span className="divider-sm" />
              <button type="button" data-testid="clear-btn" onClick={handleClearFilter}>
                {' '}
                <svg xmlns="http://www.w3.org/2000/svg" width="21" height="20" fill="none" viewBox="0 0 21 20" style={{ width: '1rem' }}>
                  <path
                    fill="#AABDCE"
                    d="M3.426 2.926c3.902-3.9 10.247-3.9 14.149 0 3.9 3.901 3.9 10.248 0 14.15A9.976 9.976 0 0110.5 20a9.975 9.975 0 01-7.074-2.924c-3.901-3.902-3.901-10.249 0-14.15zM6.374 12.95a.833.833 0 101.179 1.178L10.5 11.18l2.946 2.948a.835.835 0 001.18-1.18l-2.947-2.946 2.947-2.948a.833.833 0 10-1.179-1.179L10.5 8.822 7.553 5.874a.833.833 0 10-1.18 1.18L9.322 10l-2.947 2.948z"
                  />
                </svg>
                &nbsp;
                <span>Clear</span>
              </button>
            </>
          )}
        </div>
      </div>
      <div className="virtual_account-filter__search-w filter-section">
        <div className="element-search-content filter-body w-100">
          <div className="form-group filter-object filter-object-md w-auto --no-max-width mr-0">
            <Select
              id="status"
              aria-label="status"
              closeMenuOnSelect
              placeholder="All Status"
              components={animatedComponents}
              ref={c => setSelectRef(c)}
              isMulti={false}
              options={statusOption[type]}
              styles={{
                control: (styles, { isFocused, isSelected }) => ({
                  ...styles,
                  fontSize: '13px',
                  fontWeight: 300,
                  padding: '0px 10px',
                  border: isFocused ? null : '2px solid #dde2ec',
                  color: isSelected && '#000000'
                }),
                option: (styles, { data, isFocused }) => {
                  return {
                    ...styles,
                    fontSize: '13px',
                    fontWeight: 400,
                    backgroundColor: isFocused ? data.color : null,
                    color: '#000000'
                  };
                },
                singleValue: defaultStyle => ({ ...defaultStyle, color: '#000000' })
              }}
              onChange={value => setState({ status: value.value })}
            />
          </div>

          <div className="form-group position-relative filter-object flex-grow-1 w-auto --no-max-width --search-container mr-0">
            <img src={search} alt="search icon" aria-hidden />
            <input
              type="search"
              className="form-control"
              placeholder="Search anything"
              value={state.searchTerm}
              onChange={e => setState({ searchTerm: cleanInput(e.target.value) })}
              style={{ border: '2px solid #dde2ec' }}
              data-testid="searchTerm"
            />
          </div>

          <div
            className="form-group filter-object d-flex filter-object-sm w-auto mr-0"
            style={{ '--calendar-image': `url("${calendar}")`, minWidth: '180px', gap: '10px' }}
          >
            <DatePicker
              selected={state.dateCreated ? new Date(state.dateCreated) : undefined}
              dateFormat="dd-MM-yyyy"
              popperPlacement="bottom-end"
              style={{ borderRadius: '0px 4px 4px 0px', border: '2px solid #dde2ec', height: '38px' }}
              className="form-control date-select pl-4 date-picker"
              onChange={date => setState({ dateCreated: dayjs(dayjs(date)).format('YYYY-MM-DD') })}
              placeholderText="Date Created"
              calendarClassName="custom-datepicker"
              data-testid="start-date"
            />
          </div>

          {activeTab === 'Account Number' && ['GBP', 'EUR'].includes(activeCurrency) && (
            <div className="form-group filter-object filter-object-md w-auto --no-max-width mr-0">
              <Select
                id="tier"
                aria-label="tier"
                closeMenuOnSelect
                placeholder="Tier"
                components={animatedComponents}
                ref={c => setSelectRef(c)}
                isMulti={false}
                options={TierStatus}
                styles={{
                  control: (styles: any, { isFocused }: any) => ({
                    ...styles,
                    fontSize: '13px',
                    fontWeight: 300,
                    border: isFocused ? null : '2px solid #dde2ec'
                  }),
                  option: (styles, { data, isFocused }) => {
                    return {
                      ...styles,
                      fontSize: '13px',
                      fontWeight: 400,
                      backgroundColor: isFocused ? data.color : null,
                      color: '#000000'
                    };
                  },
                  multiValue: (styles, { data }) => {
                    return {
                      ...styles,
                      backgroundColor: data.color
                    };
                  }
                }}
                onChange={value => setState({ tier: value.value })}
              />
            </div>
          )}

          {activeTab === 'Account Holder' && (
            <div className="form-group filter-object filter-object-md w-auto --no-max-width mr-0">
              <Select
                id="types"
                aria-label="types"
                closeMenuOnSelect
                placeholder="Types"
                components={animatedComponents}
                ref={c => setSelectRef(c)}
                isMulti={false}
                options={TypeStatus}
                styles={{
                  control: (styles, { isFocused }) => ({
                    ...styles,
                    fontSize: '13px',
                    fontWeight: 300,
                    border: isFocused ? null : '2px solid #dde2ec'
                  }),
                  option: (styles, { data, isFocused }) => {
                    return {
                      ...styles,
                      fontSize: '13px',
                      fontWeight: 400,
                      color: '#000000'
                    };
                  },
                  multiValue: (styles, { data }) => {
                    return {
                      ...styles,
                      backgroundColor: data.color
                    };
                  }
                }}
                onChange={value => setState({ accountType: value.value })}
              />
            </div>
          )}

          {activeAccountHolderTab === 'Account Numbers' && (
            <div className="form-group filter-object filter-object-md w-auto --no-max-width mr-0">
              <Select
                id="currency"
                aria-label="currency"
                closeMenuOnSelect
                placeholder="Currency"
                components={animatedComponents}
                ref={c => setSelectRef(c)}
                isMulti={false}
                options={formatCurrency(availableCurrencies)}
                styles={{
                  control: (styles, { isFocused }) => ({
                    ...styles,
                    fontSize: '13px',
                    fontWeight: 300,
                    border: isFocused ? null : '2px solid #dde2ec'
                  }),
                  option: (styles, { data, isFocused }) => {
                    return {
                      ...styles,
                      fontSize: '13px',
                      fontWeight: 400,
                      color: '#000000'
                    };
                  },
                  multiValue: (styles, { data }) => {
                    return {
                      ...styles,
                      backgroundColor: data.color
                    };
                  }
                }}
                onChange={value => setState({ currency: value.value })}
              />
            </div>
          )}

          <button
            aria-label="filter transactions"
            type="button"
            className="settlement-filter__filter-button"
            onClick={() => filterTransactions()}
          >
            <img src={arrowRight} alt="arrow right icon" aria-hidden />
          </button>
        </div>
      </div>
    </section>
  );
}

export default VirtualAccountsFilter;
