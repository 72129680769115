import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { switchRefundStatus } from './switchRefundStatus';
import { formatAmount, getDate, getTime } from '+utils';
import Copyable from '+containers/Dashboard/Shared/Copyable';
import '../index.scss';

const RefundsTab = ({ data, transactionType, currency }) => {
  const [item, setItem] = useState([]);
  const [activeIndex, setActiveIndex] = useState(0);
  const count = data?.length;

  useEffect(() => {
    if (data) setItem([data[activeIndex]]);
  }, [count, transactionType]);

  const isPayinTransaction = transactionType === 'pay-ins';
  const isIssuedCardTransaction = transactionType === 'card-transactions';
  const formatStatus = status =>
    !isIssuedCardTransaction && ['failed', 'processing', 'pending', 'manual'].includes(status.toLowerCase())
      ? 'processing'
      : status.toLowerCase();

  return (
    <div>
      {data?.length > 0 ? (
        <ul>
          <li id="payment-detail">
            <div style={{ width: '40%' }}>
              <p className="reversal-count">{`${count} ${count > 1 ? 'Refunds' : 'Refund'} found`}</p>
              {data.map((refund, index) => {
                return (
                  <div
                    className="--container"
                    key={refund.reference}
                    onClick={() => {
                      setItem([refund]);
                      setActiveIndex(index);
                    }}
                    role="button"
                    tabIndex={0}
                    onKeyDown={null}
                  >
                    <p className={`reversal-amount ${activeIndex === index && 'active'}`}>
                      <label htmlFor="refund-label" className="">
                        Refund of
                      </label>
                      {`${formatAmount(refund.amount || 0)} ${currency}`}
                    </p>
                  </div>
                );
              })}
            </div>
            <div className="customer-reversal-info" style={{ width: '60%' }}>
              <ul className="">
                <li>
                  <p>Refund ID</p>
                  <Copyable text={item[0]?.reference} spanClassName={'reversal_ref'} />
                </li>

                {isIssuedCardTransaction && (
                  <li>
                    <p>Refund Amount</p>
                    <p>{`${item[0]?.amount} ${currency}`}</p>
                  </li>
                )}
                {isPayinTransaction && (
                  <>
                    <li>
                      <p>Channel</p>
                      <p>Refund</p>
                    </li>
                    <li>
                      <p>Refund Destination</p>
                      <p>{item[0]?.destination === 'disbursement_wallet' ? `Balance` : 'Customer'}</p>
                    </li>
                  </>
                )}
                <li>
                  <p>Refund Status</p>
                  <p
                    style={{
                      color: switchRefundStatus[formatStatus(item[0]?.status || '')]?.color || ''
                    }}
                  >
                    {switchRefundStatus[formatStatus(item[0]?.status || '')]?.name}
                  </p>
                </li>
                {item[0]?.created_at && (
                  <li>
                    <p>Date Created</p>
                    <p>{item[0]?.created_at ? `${getDate(item[0]?.created_at)} | ${getTime(item[0]?.created_at)}` : '-- | --'}</p>
                  </li>
                )}
                {isIssuedCardTransaction && (
                  <li>
                    <p>Date Completed</p>
                    <p>
                      {item[0]?.date_completed ? `${getDate(item[0]?.date_completed)} | ${getTime(item[0]?.date_completed)}` : '-- | --'}
                    </p>
                  </li>
                )}
                <li>
                  <p>Reason for Refund</p>
                  <p>{item[0]?.reversal_reason || item[0]?.description || 'Not Available'}</p>
                </li>
              </ul>
              {isPayinTransaction && (
                <Link to={`/dashboard/disputes/refunds/${item[0]?.reference}`} style={{ fontWeight: 600 }}>
                  <button className="btn btn-primary" type="button">
                    View Details
                  </button>
                </Link>
              )}
            </div>
          </li>
        </ul>
      ) : (
        <p className="no-refund">
          <i>No refunds for this transaction</i>
        </p>
      )}
    </div>
  );
};
export default RefundsTab;
