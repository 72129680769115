interface IClusterPropsType {
  children: React.ReactNode;
  justify?: string;
  align?: string;
  space?: string;
  className?: string;
}

const Cluster: React.FC<IClusterPropsType> = ({ children, justify = 'center', align = 'center', space = '1rem', className }) => {
  return (
    <div style={{ display: 'flex', justifyContent: justify, alignItems: align, gap: space }} data-layout="cluster" className={className}>
      {children}
    </div>
  );
};

export default Cluster;
