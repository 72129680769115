import { InputHTMLAttributes, useEffect, useReducer, useState } from 'react';
import DatePicker, { ReactDatePickerProps } from 'react-datepicker';
import dayjs from 'dayjs';
import qs from 'qs';

import useFeedbackHandler from '+hooks/feedbackHandler';
import { backwardAmountInput, cleanInput, filteredOutObjectProperty, filterOutEmptyValues, history } from '+utils';

import Modal, { ModalProps } from './Modal';

import 'react-datepicker/dist/react-datepicker.css';

type OptionsType = Array<OptionType>;

type OptionType = {
  label?: string;
  value?: string;
};

type TypeOfFieldType = 'text-input' | 'selection-based-input' | 'checkboxes' | 'radio' | 'date' | 'date-range' | 'select' | '';

export type FieldType = {
  label: string;

  key?: string;

  type: TypeOfFieldType;

  inputProps?: InputHTMLAttributes<HTMLInputElement>;

  /* Different from input element attribute "type" */
  inputType?: 'amount';
  applyAmountFormat?: boolean;

  /* Props for the select input eg. placeholder, etc */
  selectProps?: Record<string, unknown>;

  /* Can be used for checkboxes options */
  options?: OptionsType | string[];

  datePickerProps?: Partial<ReactDatePickerProps>;
  applyMinDate?: boolean;
  applyMaxDate?: boolean;

  infoText?: string;

  /* When using a selection-based-input, you can specify a key for the select option */
  selectionKey?: string;

  validate?: (value: string) => string | void;
};

export type FieldsListType = Array<FieldType>;

type FilterModalProps = {
  fields: FieldsListType;

  /* A function to handle filter action. If not added, url query params are updated by default */
  handleFilter?: (data: any) => void;

  /* Callback after filtering has been handled */
  onHandleFilter?: () => void;

  /* Inform parent of url query params that are not related to filters */
  setNonFilterParams?: (data: object) => void;
} & ModalProps;

type ToggleGroupProps = {
  checked: boolean;
  label: string;
  onChange: (checked: boolean) => void;
};

type ErrorType = Partial<Record<string, string>>;

function ToggleGroup({ checked = false, label = '', onChange = () => {} }: ToggleGroupProps) {
  return (
    <div className="">
      <label className="filter-toggle">
        <input type="checkbox" checked={checked} onChange={e => onChange?.(e.target.checked)} />
        {label}
      </label>
    </div>
  );
}

export function parseQueryParams() {
  return qs.parse(window.location.search, {
    ignoreQueryPrefix: true,
    comma: true
  });
}

export function updateQueryParams(obj = {}) {
  const newQuery = qs.stringify(obj, { arrayFormat: 'comma', commaRoundTrip: true, encodeValuesOnly: true });
  history.replace(`?${newQuery}`);
}

export function getExistingFiltersFromQuery(fields: FieldsListType = []) {
  const queryParams = filteredOutObjectProperty(parseQueryParams(), ['tab']);
  if (fields.length > 0) {
    const params = {};
    fields.forEach(field => {
      const retrieveValue = key => {
        if (queryParams[key]) {
          params[key] = queryParams[key];
        }
      };
      if (field.type === 'date-range') {
        return field?.options?.forEach(item => {
          retrieveValue(item.value);
        });
      }
      retrieveValue(field.key);
      retrieveValue(field.selectionKey);
    });
    return params;
  }
  return queryParams;
}

/**
 * Should be used for fields that may not have a direct connection
 * between the fieldKey and the keys of the input it handles.
 * E.g Date range where fieldKey is 'date' and input keys are 'dateFrom' and 'dateTo'
 */
class Event {
  subscriptions: Record<string, Function> = {};

  subscribe = (eventId: string, callback: Function) => {
    this.subscriptions[eventId] = callback;
  };

  publish = (eventId: string, data: any) => {
    (this.subscriptions[eventId] || (() => {}))();
    delete this.subscriptions[eventId];
  };

  clearSubscriptions = () => {
    this.subscriptions = {};
  };
}

const filterEvent = new Event();

export default function FilterModal({
  heading,
  description,
  visible,
  close,
  fields = [],
  handleFilter,
  onHandleFilter,
  setNonFilterParams: setParentNonFilterParams
}: FilterModalProps) {
  const [checkedFields, setCheckedFields] = useState<Record<string, {} | null>>({});
  const [nonFilterParams, setNonFilterParams] = useState({});
  const [errors, setErrors] = useReducer((prev: ErrorType, next: ErrorType) => ({ ...prev, ...next }), {});

  const { feedbackInit } = useFeedbackHandler();

  const doErrorsExist = () => {
    const actualErrors = filterOutEmptyValues(errors);
    const errorKeys = Object.keys(actualErrors);
    if (!errorKeys.length) {
      return false;
    }

    let exists = false;
    errorKeys.forEach(key => {
      if (checkedFields[key]) {
        exists = true;
      }
    });
    return exists;
  };

  const showExistingFilters = () => {
    const queryParams = parseQueryParams();
    const otherParams = { ...queryParams };
    const checked: Record<string, any> = {};
    fields.forEach(field => {
      const checkKeyValue = key => {
        if (queryParams[key]) {
          checked[key] = queryParams[key];
          delete otherParams[key];
        }
      };

      checkKeyValue(field.key);
      checkKeyValue(field.selectionKey);
      if (field.type === 'date-range') {
        field?.options?.forEach(({ value }: OptionType) => {
          checkKeyValue(value);
        });
      }
    });
    setCheckedFields(checked);
    setNonFilterParams(otherParams);
    setParentNonFilterParams?.(otherParams);
  };

  useEffect(() => {
    showExistingFilters();
  }, [window.location.search]);

  const handleDefault = (filterFields: {}) => {
    updateQueryParams({ ...nonFilterParams, ...filterFields });
  };

  const applyFilter = () => {
    if (doErrorsExist()) {
      feedbackInit({
        message: 'Please enter correct values',
        type: 'warning',
        componentLevel: true
      });
      return;
    }

    const validFields = {};
    Object.keys(checkedFields).forEach(key => {
      const fieldValue = checkedFields[key];
      if (!fieldValue && fieldValue !== 0) {
        return;
      }
      // remove subfilters without main filters
      if (fields.find(item => item.selectionKey === key && !checkedFields[item.key] && checkedFields[item.key] !== 0)) return;
      validFields[key] = fieldValue;
    });
    if (handleFilter) {
      handleFilter(validFields);
    } else {
      handleDefault(validFields);
    }
    onHandleFilter?.();
    close();
  };

  const renderField = (field: FieldType) => {
    const {
      label,
      key,
      selectionKey = '',
      type,
      options = [],
      inputProps = {},
      datePickerProps = {},
      validate,
      applyMaxDate,
      applyMinDate,
      applyAmountFormat
    } = field;
    const fieldKey = key || label;

    const onChangeInput = value => {
      setCheckedFields({ ...checkedFields, [fieldKey]: value });
      const errMsg = validate?.(value);
      setErrors({ [fieldKey]: errMsg || '' });
    };

    switch (type) {
      case 'checkboxes':
        return (
          <div className="options-flex-group">
            {options.map(item => {
              const key = typeof item === 'string' ? item : item.value || item.label;
              const optionLabel = typeof item === 'string' ? item : item.label || item.value;
              return (
                <ToggleGroup
                  onChange={val =>
                    setCheckedFields({
                      ...checkedFields,
                      [fieldKey]: val ? [...checkedFields[fieldKey], key] : checkedFields[fieldKey].filter(item => item !== key)
                    })
                  }
                  checked={checkedFields[fieldKey].includes(key)}
                  key={key}
                  label={optionLabel as string}
                />
              );
            })}
          </div>
        );

      case 'selection-based-input':
        return (
          <div className="selection-input-wrapper">
            <select
              name="type"
              className="form-control"
              onChange={e =>
                selectionKey &&
                setCheckedFields({
                  ...checkedFields,
                  [selectionKey as string]: e.target.value
                })
              }
              value={checkedFields[selectionKey] as string}
            >
              {options.map(item => (
                <option key={item.value} value={item.value}>
                  {item.label}
                </option>
              ))}
            </select>

            <input
              className="form-control"
              value={(checkedFields[fieldKey] as string) || ''}
              onChange={e => {
                setCheckedFields({
                  ...checkedFields,
                  [selectionKey]: checkedFields[selectionKey] || options[0].value,
                  [fieldKey]: applyAmountFormat ? backwardAmountInput(e.target.value) : e.target.value
                });
              }}
              {...inputProps}
            />
          </div>
        );

      case 'select':
        return (
          <select
            name="type"
            className="form-control"
            onChange={e => setCheckedFields({ ...checkedFields, [fieldKey]: e.target.value })}
            value={checkedFields[fieldKey]}
          >
            {options.map(item => (
              <option key={item.value} value={item.value}>
                {item.label}
              </option>
            ))}
          </select>
        );

      case 'date':
        return (
          <DatePicker
            selected={checkedFields[fieldKey] && new Date(checkedFields[fieldKey])}
            dateFormat="dd-MM-yyyy"
            onChange={date => setCheckedFields({ ...checkedFields, [fieldKey]: date })}
            placeholderText="Select a date"
            wrapperClassName="datepicker"
            {...datePickerProps}
          />
        );

      case 'date-range':
        // remove values when field is disabled
        filterEvent.subscribe(fieldKey, (fieldValue: boolean) => {
          if (!fieldValue) {
            const values = options.map(item => item.value);
            values.forEach(value => {
              setCheckedFields(prevState => ({ ...prevState, [value]: undefined }));
            });
          }
        });

        return (
          <div className="row-container">
            {options.map(({ value, label, datePickerProps: pickerProps }, index) => {
              const firstOptionValue = checkedFields[options[0]?.value];
              const secondOptionValue = checkedFields[options[1]?.value];
              const defaultMin = applyMinDate ? new Date('2018-01-01') : undefined;
              const defaultMax = applyMaxDate ? new Date() : undefined;
              const minDate = index > 0 && firstOptionValue ? new Date(firstOptionValue) : defaultMin;
              const maxDate = index === 0 && secondOptionValue ? new Date(secondOptionValue) : defaultMax;

              const getValidDate = date => {
                const d = new Date(date);
                if (d instanceof Date && !isNaN(d)) {
                  return d;
                }
                return undefined;
              };

              const onChangeDate = date => {
                setCheckedFields({ ...checkedFields, [value]: date ? dayjs(date).format('YYYY-MM-DD') : undefined });
              };

              return (
                <div key={value}>
                  <label htmlFor={value}>{label}</label>
                  <DatePicker
                    id={value}
                    selected={checkedFields[value] && getValidDate(checkedFields[value])}
                    dateFormat="dd-MM-yyyy"
                    onChange={onChangeDate}
                    minDate={minDate}
                    maxDate={maxDate}
                    placeholderText="Select a date"
                    wrapperClassName="datepicker"
                    {...datePickerProps}
                    {...pickerProps}
                  />
                </div>
              );
            })}
          </div>
        );

      case 'text-input':
        return (
          <input
            className="form-control"
            onChange={e => {
              if (inputProps?.type === 'number' && inputProps.maxLength < e.target.value.length) {
                return;
              }
              onChangeInput(cleanInput(e.target.value));
            }}
            value={checkedFields[fieldKey]}
            {...inputProps}
          />
        );

      default:
        return null;
    }
  };

  const renderContent = () => {
    return (
      <div className="filter-modal-container">
        {fields.map(field => {
          const { label, key, type, infoText } = field;
          const fieldKey = key || label;
          const checked = checkedFields[fieldKey] !== undefined && checkedFields[fieldKey] !== null;

          const defaultValueOptions: Partial<Record<TypeOfFieldType, any>> = {
            'text-input': '',
            'selection-based-input': '',
            date: '',
            radio: '',
            checkboxes: [],
            select: {},
            'date-range': true
          };
          const defaultValue = defaultValueOptions[type] ?? {};

          return (
            <div key={fieldKey} className="field-wrapper">
              <div className="toggle-group-wrapper">
                <ToggleGroup
                  checked={checked}
                  label={label}
                  onChange={value => {
                    setCheckedFields({ ...checkedFields, [fieldKey]: value ? defaultValue : null });
                    filterEvent.publish(fieldKey, value);
                  }}
                />
              </div>

              {checked && (
                <>
                  {renderField(field)}
                  {infoText && <p className="info-text">{infoText}</p>}
                  {errors[fieldKey] && <p className="error-msg">{errors[fieldKey]}</p>}
                </>
              )}
            </div>
          );
        })}
      </div>
    );
  };

  return (
    <Modal
      visible={visible}
      close={close}
      heading={heading}
      description={description}
      firstButtonText="Close"
      firstButtonAction={close}
      secondButtonText="Apply Filter"
      secondButtonAction={applyFilter}
      content={renderContent()}
      secondButtonActionIsTerminal={false}
    />
  );
}
