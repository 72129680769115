import pendingCircles from '+assets/img/dashboard/pending-circles.gif';

const switchRefundStatus = {
  success: { name: 'Successful', color: '#24B314', icon: '✓' },
  manual: { name: 'Manual', color: '#24B314', icon: '✓' },
  pending: {
    name: 'Pending',
    color: 'rgba(16,38,73,.4)',
    icon: <img src={pendingCircles} alt="" id="pending-circles" />
  },
  processing: {
    name: 'Processing...',
    color: 'rgba(16,38,73,.4)',
    icon: <img src={pendingCircles} alt="" id="pending-circles" />
  },
  failed: { name: 'Failed', color: '#F32345', icon: '✗' },
  partially_paid: { name: 'Partially Paid', color: '#24B314' },
  fully_paid: { name: 'Paid', color: '#24B314' },
  accepted: { name: 'Accepted', color: '#24b314' },
  partially_accepted: { name: 'Accepted (Partially)', color: '#48ceb0' },
  invalid: { name: 'Invalid', color: '#94a7b7' },
  declined: { name: 'Declined', color: '#f32345' },
  pending_pre_arbitration: { name: 'Pre-arbitration Pending', color: '#ffb447' },
  processing_pre_arbitration: { name: 'Pre-arbitration Processing ', color: '#fa9500' },
  accepted_pre_arbitration: { name: 'Pre-arbitration Accepted', color: '#24b314' },
  partially_accepted_pre_arbitration: { name: 'Pre-arbitration Accepted (Partially)', color: '#24b314' },
  delayed_pre_arbitration: { name: 'Pre-arbitration Delayed', color: '#f32345' },
  invalid_pre_arbitration: { name: 'Pre-arbitration Invalid', color: '#94a7b7' },
  declined_pre_arbitration: { name: 'Pre-arbitration Declined', color: '#f32345' },
  accepted_arbitration: { name: 'Arbitration Accepted', color: '#f32345' },
  declined_arbitration: { name: 'Arbitration Declined', color: '#f32345' }
};

export { switchRefundStatus };
