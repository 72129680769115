/* eslint-disable react/jsx-props-no-spreading */
import Icon from '+containers/Dashboard/Shared/Icons';
import Modal, { IModalProps } from '+containers/Dashboard/Shared/Modal';
import { useReducerState } from '+hooks';
import useStore from '+store';
import { ICreateEmailConfigurationSlice } from '+types';

import UpdateIntervalModal from './UpdateIntervalModal';
import UpdateUnsubscribeReason from './UpdateUnsubscribeReason';

function EmailConfigurationModal() {
  const state = useStore<ICreateEmailConfigurationSlice>(store => store as ICreateEmailConfigurationSlice);
  const [inputs, setInputs, resetInputs] = useReducerState({ interval: '', reason: '' });

  const getModlProps = () => {
    switch (state.modalStage) {
      case 'init':
        return {
          heading: 'You Have Unsaved Changes',
          description: 'Would you like to save your edits before performing another task? Unsaved progress will be lost.',
          firstButtonText: 'Discard',
          secondButtonText: 'Save Changes',
          secondButtonAction: state.saveFn,
          secondButtonActionIsTerminal: state.isCompleteAfterSave,
          modalStage: 'init',
          showButtons: true,
          completedHeading: 'Success!',
          completedDescription: 'Your email notification settings have been updated successfully.',
          showImage: true,
          size: 'sm',
          maxHeight: 'sm',
          headerClassName: 'onsave-title',
          hideCompletedAction: true
        };
      case 'email-subscription-interval':
        return {
          heading: <h5>Subscribe to Product Update</h5>,
          completedHeading: <h3 className="pt-3">Subscribed</h3>,
          completedDescription: <p className="px-4">You have successfully subscribed to product update report emails from this account.</p>,
          showImage: true,
          completedImage: <Icon name="circleSuccessEnvelope" width={100} height={100} />,
          hideCompletedAction: true,
          size: 'md',
          maxHeight: 'md',
          secondButtonText: 'Subscribe',
          completedActionText: '',
          secondButtonActionIsTerminal: true,
          secondButtonDisable: !inputs.interval,
          secondButtonAction: async () => {
            await state.saveFn(inputs.interval);
          },
          content: <UpdateIntervalModal value={inputs.interval} onChange={(interval: string) => setInputs({ interval })} />
        };
      case 'email-unsubscription':
        return {
          heading: <h5>Unsubscribe from product update report emails</h5>,
          completedHeading: <h3 className="pt-3">Unsubscribed</h3>,
          completedDescription: <p className="px-4">You will no longer receive product updates from this account.</p>,
          showImage: true,
          completedImage: <Icon name="circleCancelEnvelope" width={100} height={100} />,
          completedActionText: '',
          size: 'md',
          maxHeight: 'md',
          firstButtonText: 'Back',
          secondButtonText: 'Proceed',
          secondButtonActionIsTerminal: true,
          secondButtonDisable: !inputs.reason,
          secondButtonColor: 'red',
          footerButtonClassName: 'email-unsubscribe-modal-footer',
          secondButtonAction: async () => {
            await state.saveFn(inputs.reason);
          },
          content: <UpdateUnsubscribeReason value={inputs.reason} onChange={(reason: string) => setInputs({ reason })} />
        };
      case 'complete':
        return {
          modalStage: 'complete',
          completedHeading: 'Success!',
          completedDescription: 'Your email notification settings have been updated successfully.',
          showImage: true,
          size: 'sm',
          maxHeight: 'sm',
          headerClassName: 'onsave-title',
          hideCompletedAction: true
        };
      default:
        return {};
    }
  };

  return state.showEmailConfigModal ? (
    <Modal
      {...(getModlProps() as IModalProps)}
      visible={state.showEmailConfigModal}
      close={() => {
        state.setShowEmailConfigModal(false);
        resetInputs();
      }}
    />
  ) : null;
}

export default EmailConfigurationModal;
