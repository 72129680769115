import React from 'react';
import { useQueryClient } from 'react-query';
import { capitalizeRemovedash, maskBetweenSetRange } from '+utils';
import FileSvg from '+assets/img/dashboard/file.svg';
import FilePNG from '+assets/img/dashboard/file.png';

const VerificationDocument = () => {
  const merchantVerificationDocumentTable = () => {
    const queryClient = useQueryClient();
    const defaultBusiness = queryClient.getQueryData('MERCHANT_KYC');
    const documents = defaultBusiness?.details?.documents;
    return (
      <div className="mt-4">
        {documents?.map(document => {
          return (
            <a
              href={document?.file?.url}
              className="div-table --account-table document-row --row mb-2"
              key={document?.type}
              download
              target="_blank"
              rel="noopener noreferrer"
            >
              <div>
                <img src={FileSvg} srcSet={FilePNG} alt="document" loading="lazy" />
                <span style={{ textTransform: 'capitalize' }}>{document?.type ? capitalizeRemovedash(document?.type) : 'nil'}</span>
              </div>
              <div>
                <span>
                  {document?.file?.description ? maskBetweenSetRange(capitalizeRemovedash(document?.file?.description), 4, 9) : 'nil'}
                </span>
              </div>
            </a>
          );
        })}
      </div>
    );
  };
  return (
    <div className="verification-doc__main">
      <h5>Verification Documents</h5>
      <span className="description">Uploaded documents provided based on your business type.</span>
      {merchantVerificationDocumentTable()}
    </div>
  );
};

export default VerificationDocument;
