/* eslint-disable react/jsx-props-no-spreading */
import { useEffect, useState } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { AxiosError } from 'axios';

import Countdown from '+containers/Dashboard/Balances/components/Countdown';
import { useReducerState } from '+hooks';
import useFeedbackHandler from '+hooks/feedbackHandler';
import APIRequest from '+services/api-services';
import useStore from '+store';
import { PermissionColumnType, UserRoleType } from '+types';
import { cleanInput, logError } from '+utils';

import Modal from '../../../Shared/Modal';
import { serializeSelectedPermission, twoFactor } from './data';
import PermissionsTable from './PermissionsTable';

import removeIcon from '+assets/img/dashboard/remove-user.svg';
import user from '+assets/img/dashboard/user.svg';

import './index.scss';

interface INewRoleState {
  title: string;
  permissions: Record<string, typeof PermissionColumnType>;
}

interface ITeamModalsProps {
  close: () => void;
  type: string;
  roles: any[];
  role: UserRoleType | null;
  memberDetails: any;
  updateType: (type: string) => void;
  permissions: Record<string, typeof PermissionColumnType[]>;
  presentUser: { [id: string]: any };
}

const api = new APIRequest();

const TeamModals = ({ close: closeModal, type, roles, role, memberDetails, updateType, permissions, presentUser }: ITeamModalsProps) => {
  const { profile } = useStore();
  const queryClient = useQueryClient();
  const { feedbackInit, closeFeedback } = useFeedbackHandler();
  const [state, setState] = useState({
    email: '',
    role: '',
    slug: ''
  });
  const [authData, setAuthData] = useReducerState({
    code: '',
    identifier: '',
    twoFactorType: null,
    codeSent: true,
    countdownCompleted: false
  });

  const [newRole, setNewRole] = useReducerState<INewRoleState>({
    title: '',
    permissions: {}
  });

  const [userRole, setUserRole] = useReducerState<INewRoleState>({
    title: role?.name || '',
    permissions: role?.permissions || {}
  });

  const [finalSubmissionState, setFinalSubmissionState] = useState<string>('');
  const email = memberDetails?.email;
  const slug = memberDetails.userRole?.slug;

  const close = () => {
    closeModal();
    setNewRole({ title: '', permissions: {} });
    setAuthData({ code: '', identifier: '', twoFactorType: null });
    setFinalSubmissionState('');
  };

  const onRoleChange = (
    key: keyof INewRoleState,
    value: string | Record<string, typeof PermissionColumnType>,
    roleState: INewRoleState,
    setRoleState: (value: Partial<INewRoleState>) => void,
    accessiblePermissions: typeof PermissionColumnType[]
  ) => {
    if (key === 'title') {
      const newValue = value as string;
      setRoleState({ title: newValue });
    }

    if (key === 'permissions') {
      const newValue = value as Record<string, typeof PermissionColumnType>;
      const valueKeyProperty = Object.keys(newValue)[0];
      const valueProperty = Object.values(newValue)[0];
      const permission = roleState.permissions[valueKeyProperty];
      const serializedPermissions = serializeSelectedPermission(valueProperty);
      const serializedExistingPermissions = serializeSelectedPermission(permission);
      const hasPermission = serializedPermissions.includes(permission);
      const permissionIndex = serializedPermissions.indexOf(permission);
      const isChecked =
        (valueProperty === permission
          ? serializedPermissions.indexOf(valueProperty) >= permissionIndex
          : serializedExistingPermissions.includes(valueProperty)) && hasPermission;

      if (isChecked) {
        const lastSerializedPermission = serializedPermissions[permissionIndex - 1];
        const newSerializedPermission = accessiblePermissions.includes(lastSerializedPermission)
          ? lastSerializedPermission
          : serializedPermissions[permissionIndex - 2];
        const newUserPermissions = Object.assign({}, { ...roleState.permissions, [valueKeyProperty]: newSerializedPermission });
        if (!newUserPermissions[valueKeyProperty]) delete newUserPermissions[valueKeyProperty];

        setRoleState({ permissions: newUserPermissions });
      } else {
        const property = serializedPermissions[serializedPermissions.length - 1];

        if (serializedExistingPermissions.includes(property)) {
          const filterPermissions = serializedPermissions.filter((_, index) => index < serializedPermissions.indexOf(property));
          if (filterPermissions.length === 0) {
            const newUserPermissions = Object.assign({}, { ...roleState.permissions, [valueKeyProperty]: property });
            delete newUserPermissions[valueKeyProperty];
            setRoleState({ permissions: newUserPermissions });
          } else {
            setRoleState({
              permissions: { ...roleState.permissions, [valueKeyProperty]: filterPermissions[filterPermissions.length - 1] }
            });
          }
          return;
        }

        setRoleState({
          permissions: { ...roleState.permissions, [valueKeyProperty]: serializedPermissions[serializedPermissions.length - 1] }
        });
      }
    }
  };

  const onError = (error: AxiosError<{ message: string }>, timeout = true) => {
    const data = error?.response?.data;
    logError(data);
    feedbackInit({
      isActive: true,
      message: data?.message || 'We are sorry, something went wrong. Please try again.',
      type: 'danger',
      componentLevel: true
    });
    if (timeout) {
      setTimeout(() => {
        closeFeedback();
      }, 5000);
    }
  };

  const handleCountdownCompletion = () => {
    setAuthData({ countdownCompleted: true });
    setAuthData({ codeSent: false });
  };

  const changeRole = useMutation(
    () =>
      api.changeMemberRole({
        email,
        role_id: +state.role,
        authorization: {
          two_factor_type: authData.twoFactorType,
          code: authData.code,
          identifier: authData.identifier
        }
      }),
    {
      onMutate: () => {
        closeFeedback();
      },
      onSuccess: () => {
        queryClient.invalidateQueries(['TEAMS_AND_ROLES']);
        queryClient.invalidateQueries(['TEAM_MEMBERS']);
      },
      onError: e => {
        const error = e?.response?.data;
        logError(e);
        feedbackInit({
          message: error?.message ?? 'There has been an error changing role. Please try again.',
          type: 'danger',
          componentLevel: true
        });
      }
    }
  );

  const createCustomRole = useMutation(
    () =>
      api.createCustomRole({
        name: newRole.title,
        permissions: newRole.permissions,
        authorization: {
          two_factor_type: authData.twoFactorType,
          code: authData.code,
          identifier: authData.identifier
        }
      }),
    {
      onMutate: () => {
        closeFeedback();
      },
      onSuccess: () => {
        queryClient.invalidateQueries(['TEAMS_AND_ROLES']);
        queryClient.invalidateQueries(['TEAM_MEMBERS']);
      },
      onError: e => {
        const error = e?.response?.data;
        logError(e);
        feedbackInit({
          message: error?.message ?? 'There has been an error changing role. Please try again.',
          type: 'danger',
          componentLevel: true
        });
      }
    }
  );

  const updateCustomRole = useMutation(
    () =>
      api.updateCustomRole(role?.id || 0, {
        name: userRole.title,
        permissions: userRole.permissions,
        authorization: {
          two_factor_type: authData.twoFactorType,
          code: authData.code,
          identifier: authData.identifier
        }
      }),
    {
      onMutate: () => {
        closeFeedback();
      },
      onSuccess: () => {
        queryClient.invalidateQueries(['TEAMS_AND_ROLES']);
        queryClient.invalidateQueries(['TEAM_MEMBERS']);
      },
      onError: e => {
        const error = e?.response?.data;
        logError(e);
        feedbackInit({
          message: error?.message ?? 'There has been an error changing role. Please try again.',
          type: 'danger',
          componentLevel: true
        });
      }
    }
  );

  const sendInvite = useMutation(
    () =>
      api.inviteTeamMember({
        email: state.email,
        role_slug: state.slug,
        authorization: {
          two_factor_type: authData.twoFactorType,
          code: authData.code,
          identifier: authData.identifier
        }
      }),
    {
      onMutate: () => {
        closeFeedback();
      },
      onSuccess: () => {
        queryClient.invalidateQueries(['TEAMS_AND_ROLES']);
        queryClient.invalidateQueries(['TEAM_MEMBERS']);
      },
      onError: e => {
        const error = e?.response?.data;
        logError(e);

        const message = error?.message ?? 'There has been an error sending invite. Please try again.';

        feedbackInit({
          message,
          type: 'danger',
          componentLevel: error?.message.startsWith('invalid code provided')
        });
        if (!error?.message.startsWith('invalid code provided')) close();
      }
    }
  );

  const resendInvite = useMutation(
    () =>
      api.inviteTeamMember({
        email,
        role_slug: slug
      }),
    {
      onMutate: () => {
        closeFeedback();
      },
      onSuccess: () => {
        queryClient.invalidateQueries(['TEAMS_AND_ROLES']);
        queryClient.invalidateQueries(['TEAM_MEMBERS']);
      },
      onError: e => {
        const error = e?.response?.data;
        logError(e);
        feedbackInit({
          message: error?.message,
          type: 'danger'
        });
        close();
      }
    }
  );

  const removeMember = useMutation(
    () =>
      api.removeMember({
        email,
        authorization: {
          two_factor_type: authData.twoFactorType,
          code: authData.code,
          identifier: authData.identifier
        }
      }),
    {
      onMutate: () => {
        closeFeedback();
      },
      onSuccess: () => {
        queryClient.invalidateQueries(['TEAMS_AND_ROLES']);
        queryClient.invalidateQueries(['TEAM_MEMBERS']);
      },
      onError: e => {
        const error = e?.response?.data;
        logError(e);
        feedbackInit({
          message: error?.message ?? `There has been an error removing ${email}, Please try again.`,
          type: 'danger'
        });
        close();
      }
    }
  );

  const removeRole = useMutation(
    () =>
      api.removeRole({
        roleId: role?.id as number
      }),
    {
      onMutate: () => {
        closeFeedback();
      },
      onSuccess: () => {
        queryClient.invalidateQueries(['TEAMS_AND_ROLES']);
        queryClient.invalidateQueries(['TEAM_MEMBERS']);
      },
      onError: e => {
        const error = e?.response?.data;
        logError(e);
        feedbackInit({
          message: error?.message ?? `There has been an error removing ${state.role}. Please try again.`,
          type: 'danger'
        });
        close();
      }
    }
  );

  const initiateOTP = useMutation(() => api.initiateOTPToken({ action: 'merchant_team_management' }), {
    onSuccess: responseData => {
      const { data: initiateOTPData } = responseData;
      setAuthData({
        identifier: initiateOTPData.identifier,
        twoFactorType: initiateOTPData.type
      });
      updateType('otpStage');
    },
    onError: error => onError(error)
  });

  const resendOTP = useMutation((payload: { identifier: string }) => api.resendOTPAction(payload), {
    onError: error => onError(error)
  });

  const initiateToken = () => {
    initiateOTP.mutate();
  };

  const onResendToken = () => {
    resendOTP.mutate({
      identifier: authData.identifier
    });
  };

  const getExtraOtpModalProps = () => {
    switch (finalSubmissionState) {
      case 'change-role':
        return {
          completedHeading: 'Role Changed',
          completedDescription: `Team member role changed successfully.`,
          secondButtonAction: changeRole.mutateAsync
        };
      case 'invite-team-member':
        return {
          completedHeading: 'Invite Sent',
          completedDescription: `An invite to join your team has been sent to ${state.email}.`,
          secondButtonAction: sendInvite.mutateAsync
        };
      case 'remove-team-member':
        return {
          completedHeading: 'Team Member Removed',
          completedDescription: 'Team member removed successfully.',
          secondButtonAction: removeMember.mutateAsync,
          completedImage: removeIcon
        };
      case 'add-role-permission':
        return {
          completedHeading: 'New Role Created',
          completedDescription: 'You have successfully created a new role to your organisation.',
          secondButtonAction: createCustomRole.mutateAsync
        };
      case 'confirm-update-permission':
        return {
          completedHeading: 'Permissions Updated',
          secondButtonAction: updateCustomRole.mutateAsync
        };

      default:
        return {};
    }
  };

  const onOTPInitialize = (submissionState: string) => {
    initiateToken();
    setFinalSubmissionState(submissionState);
  };

  const getOtpStageDescription = () => {
    switch (authData.twoFactorType) {
      case twoFactor.TOTP:
        return (
          <>
            Enter the six-digit code from your <b>authenticator app</b> in the space provided below to confirm this transaction.
          </>
        );
      case twoFactor.OTP:
        return <>Enter the authorization code or OTP (one-time PIN) that was sent to your email ( {profile?.email}) </>;
      default:
        return '';
    }
  };

  const getOtpStageFirstBtn = () => {
    if (authData.twoFactorType === twoFactor.TOTP_RECOVERY_CODE) {
      return async () => {
        setAuthData({ twoFactorType: twoFactor.TOTP });
      };
    }
    return undefined;
  };

  const otpContent = () => {
    let inputLabel = 'Verification code';
    let inputPlaceholder = 'Enter verification code';
    if (authData.twoFactorType === twoFactor.TOTP) {
      inputLabel = 'Authentication Code';
      inputPlaceholder = 'Enter authentication code';
    } else if (authData.twoFactorType === twoFactor.TOTP_RECOVERY_CODE) {
      inputLabel = 'Recovery Code';
      inputPlaceholder = 'Enter recovery code';
    }
    return (
      <div className="bankForm-container">
        {getOtpStageDescription()}
        <div className="element-box saved-bank-container mb-2 p-2">
          <div>
            {authData.twoFactorType === twoFactor.TOTP_RECOVERY_CODE && (
              <p>Can't use your authenticator app? Enter one of your previously saved recovery codes to validate this transaction.</p>
            )}
          </div>
        </div>
        <label htmlFor="amount">{inputLabel}</label>
        <input
          type="number"
          name="pin"
          maxLength={7}
          autoComplete="one-time-code"
          placeholder={inputPlaceholder}
          value={authData.code}
          data-testid="otp-input"
          onChange={e => {
            const formattedInput = cleanInput(e.target.value);
            setAuthData({ code: formattedInput });
          }}
        />
        {authData.twoFactorType === twoFactor.TOTP && (
          <div className="recovery-code">
            Cant access authenticator app?{' '}
            <span onClick={() => setAuthData({ twoFactorType: twoFactor.TOTP_RECOVERY_CODE })} className="recovery-code-btn">
              Confirm using recovery codes
            </span>
          </div>
        )}
        {authData.twoFactorType === twoFactor.OTP && (
          <label htmlFor="amount" className="withdraw-label mt-2 small">
            <span>
              <span className="dark">If you didn’t receive a code? </span>
              {!authData.codeSent || authData.countdownCompleted ? (
                <button
                  type="button"
                  className="btn btn-link"
                  onClick={async () => {
                    setAuthData({ codeSent: true });
                    setAuthData({ countdownCompleted: false });
                    onResendToken();
                  }}
                >
                  Resend code.
                </button>
              ) : (
                <span style={{ marginLeft: '7px' }}>
                  {`Resend code in `} <Countdown callbackFn={handleCountdownCompletion} countFrom={30} /> {`secs.`}
                </span>
              )}
            </span>
          </label>
        )}
      </div>
    );
  };

  const getSelectedRole = () => {
    if (state.role) return roles.find(role => role.id === state.role)?.name;
    return roles.find(role => role.id === memberDetails?.userRole?.id)?.name;
  };

  const modalActions = () => {
    let content;
    switch (type) {
      case 'remove-team-member':
        content = {
          heading: 'Remove Team Member',
          description:
            'Please confirm that you want to remove this team member from this organization. They will no longer have access to this dashboard except they are re-invited.',
          content: '',
          firstButtonText: 'Go Back',
          secondButtonText: 'Yes, Remove',
          firstButtonAction: close,
          secondButtonAction: () => {
            onOTPInitialize('remove-team-member');
          },
          completedActionText: 'Close',
          completedImage: removeIcon,
          secondButtonColor: '#F32345',
          firstButtonColor: '#102649',
          firstButtonBackground: '#E0E7F0',
          secondButtonActionIsTerminal: false
        };
        break;

      case 'remove-role':
        content = {
          heading: 'Remove Role',
          description: `Please confirm that you want to remove ${role?.name} role from this organization. This role will no longer exist on the system except they are re-added.`,
          content: '',
          firstButtonText: 'Go Back',
          secondButtonText: 'Yes, Remove',
          firstButtonAction: close,
          completedActionText: 'Close',
          secondButtonColor: '#F32345',
          firstButtonColor: '#102649',
          firstButtonBackground: '#E0E7F0',
          completedHeading: 'Role Removed',
          completedDescription: 'Role removed successfully.',
          secondButtonAction: removeRole.mutateAsync,
          completedImage: removeIcon
        };
        break;

      case 'resend-invite':
        content = {
          heading: 'Resend Invite',
          description: `Do you want to resend an invite to ${email}?`,
          content: '',
          secondButtonText: 'Yes, Resend',
          secondButtonAction: () => resendInvite.mutateAsync(),
          completedHeading: 'Invite Resent',
          completedDescription: `We have sent another invitation to ${email} to join your business account`,
          secondButtonColor: '#2376F3',
          firstButtonColor: '#102649',
          firstButtonBackground: '#E0E7F0'
        };
        break;
      case 'change-role':
        content = {
          heading: 'Change Role',
          description: (
            <>
              <p className="team-permission-modal-desc">
                Modify the role of team members, select from the options below to change their current role. Additionally, you can{' '}
                <button type="button" onClick={() => updateType('add-role-permission')}>
                  Create a New Role
                </button>{' '}
                with more tailored permissions.
              </p>
            </>
          ),
          content: (
            <div className="form-group">
              <label htmlFor="role" style={{ fontWeight: 600 }}>
                Team Member's Role
              </label>

              <div className="team-role-display">
                <img src={user} alt="User Icon" style={{ marginRight: '0.38rem', width: '0.95rem', height: '0.95rem' }} />
                <p>{getSelectedRole()}</p>
              </div>

              <div className="team-radio-wrapper">
                {roles
                  .filter(role => (presentUser?.userRole?.name !== 'Owner' ? role.name !== 'Owner' : true))
                  ?.map(role => (
                    <label className="team-radio-check" key={role.id}>
                      <input
                        type="radio"
                        value={role.id}
                        onChange={e => setState({ ...state, role: Number(e.target.value) })}
                        checked={state.role === role.id}
                      />
                      {role.name} {role.category === 'system' && <span>(Default) </span>}
                    </label>
                  ))}
              </div>
            </div>
          ),
          secondButtonText: 'Save',
          secondButtonAction: () => {
            onOTPInitialize('change-role');
          },
          secondButtonActionIsTerminal: false,
          secondButtonColor: '#2376F3',
          firstButtonColor: '#102649',
          firstButtonBackground: '#E0E7F0',
          modalClassName: 'team-permission-modal'
        };
        break;

      case 'confirm-update-permission':
        content = {
          modalStage: 'init',
          heading: 'Update user permissions',
          description: (
            <>
              To apply the requested changes <b> {userRole.title} </b>, will be logged out immediately and would need to sign in again. Are
              you sure you want to proceed?
            </>
          ),
          size: 'sm',
          maxHeight: 'sm',
          firstButtonText: 'Cancel',
          secondButtonText: 'Proceed',
          firstButtonAction: close,
          secondButtonAction: () => onOTPInitialize('confirm-update-permission'),
          secondButtonActionIsTerminal: false
        };
        break;

      case 'initiate-update-permission':
        content = {
          size: 'md',
          firstButtonText: 'Cancel',
          firstButtonAction: close,
          secondButtonText: 'Save',
          secondButtonAction: () => updateType('confirm-update-permission'),
          secondButtonActionIsTerminal: false,
          modalClassName: 'team-permission-modal',
          heading: 'Edit Permissions',
          completedHeading: 'Permissions Updated',
          content: (
            <div>
              <label htmlFor="role" style={{ fontWeight: 600 }}>
                Editing permissions for :
              </label>

              <div className="team-role-display team-display-role">
                <img src={user} alt="User Icon" style={{ marginRight: '0.38rem', width: '0.95rem', height: '0.95rem' }} />
                <p>{userRole.title}</p>
              </div>

              <p className="team-permission-modal-desc">
                Each permission has multiple actions represented as checkboxes. A disabled checkbox means that the action does not apply to
                that particular permission, or they cannot currently interact with that permission.
              </p>

              <div className="team-permission-modal-wrapper">
                <PermissionsTable
                  data={permissions}
                  permissions={userRole.permissions}
                  onSelect={(key, value, list) => onRoleChange('permissions', { [key]: value }, userRole, setUserRole, list)}
                />
              </div>
            </div>
          )
        };
        break;

      case 'add-role-permission':
        content = {
          size: 'md',
          firstButtonText: 'Cancel',
          firstButtonAction: close,
          secondButtonText: 'Save',
          secondButtonAction: () => onOTPInitialize('add-role-permission'),
          secondButtonActionIsTerminal: false,
          secondButtonDisable: !newRole.title || Object.values(newRole.permissions).flat().length === 0,
          modalClassName: 'team-permission-modal',
          heading: 'Add New Role',
          description: 'Create a new team member role and assign permissions.',
          content: (
            <div>
              <div>
                <h6>Role Title</h6>
                <input
                  value={newRole.title}
                  onChange={({ target: { value } }) => onRoleChange('title', value, newRole, setNewRole)}
                  className="team-permission-input"
                  placeholder="Enter role name / title"
                />
              </div>
              <div className="team-permission-desc">
                <h6>Assign permissions to new role</h6>
                <p className="team-permission-modal-desc">
                  Each permission has multiple actions represented as checkboxes. A disabled checkbox means that the action does not apply
                  to that particular permission, or they cannot currently interact with that permission.
                </p>
              </div>

              <div className="team-permission-modal-wrapper">
                <PermissionsTable
                  data={permissions}
                  permissions={newRole.permissions}
                  onSelect={(key, value, list) => onRoleChange('permissions', { [key]: value }, newRole, setNewRole, list)}
                />
              </div>
            </div>
          )
        };
        break;
      case 'otpStage':
        const extraProps = getExtraOtpModalProps();
        content = {
          heading: `Confirm ${authData.twoFactorType === twoFactor.TOTP_RECOVERY_CODE ? 'using recovery code' : 'your identity'}`,
          description: '',
          content: otpContent(),
          firstButtonText: authData.twoFactorType === twoFactor.TOTP_RECOVERY_CODE ? 'Back' : undefined,
          secondButtonText: 'Confirm',
          firstButtonAction: getOtpStageFirstBtn(),
          completedActionText: 'Close',
          completedAction: close,
          modalClassName: 'team-permission-otp-modal',
          ...extraProps,
          secondButtonActionIsTerminal: authData.code.length >= 6,
          secondButtonAction: async () => {
            if (authData.code.length < 6) {
              return feedbackInit({
                message: 'Code length must be at least 6 characters long',
                type: 'danger',
                componentLevel: true
              });
            }
            await extraProps?.secondButtonAction?.();
          }
        };
        break;
      default:
        content = {
          heading: `Invite Team Member`,
          description: 'Invite a team member to give them access to your business dashboard.',
          content: (
            <>
              <div className="form-group">
                <label htmlFor="email" style={{ fontWeight: 600 }}>
                  Email Address
                </label>
                <input
                  type="email"
                  name="email"
                  className="form-control modal-input"
                  placeholder="name@example.com"
                  onChange={e => setState({ ...state, email: e.target.value })}
                  required
                />
              </div>
              <div className="form-group">
                <label htmlFor="role" style={{ fontWeight: 600 }}>
                  Choose Role
                </label>
                <select
                  className="form-control modal-input"
                  name="format"
                  onChange={e => setState({ ...state, slug: e.target.value })}
                  value={state.slug}
                  required
                >
                  <option value="">Select role</option>
                  {roles
                    .filter(role => (presentUser?.userRole?.name !== 'Owner' ? role.name !== 'Owner' : true))
                    .map(role => (
                      <option key={role.id} value={role.slug}>
                        {role.name}
                      </option>
                    ))}
                </select>
              </div>
            </>
          ),
          secondButtonText: 'Send Invite',
          secondButtonAction: () => {
            onOTPInitialize('invite-team-member');
          },
          secondButtonDisable: !state.email || !state.slug,
          secondButtonActionIsTerminal: false,
          secondButtonColor: '#2376F3',
          firstButtonColor: '#102649',
          firstButtonBackground: '#E0E7F0'
        };
        break;
    }

    return {
      close,
      ...content,
      secondButtonDisable: initiateOTP.isLoading || content?.secondButtonDisable,
      secondButtonText: initiateOTP.isLoading ? (
        <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true" />
      ) : (
        content.secondButtonText
      )
    };
  };
  return <Modal {...modalActions()} />;
};

export default TeamModals;
