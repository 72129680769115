
import { useEffect, useState } from 'react';
import useStore from '+store';
import { PermissionsType } from '+types';

const usePermissions = (param: keyof PermissionsType) => {
  const [permission, setPermission] = useState<string | null>(null);
  const permissions = useStore(state => state.permissions);

  useEffect(() => {
    if (permissions) {
      setPermission(permissions[param] || null);
    }
  }, [param]);

  return permission;
};

export default usePermissions;
