/* eslint-disable no-nested-ternary */
import { useEffect, useState, useRef } from 'react';
import { Formik, Form, Field } from 'formik';
import { useMutation } from 'react-query';
import APIRequest from '+services/api-services';
import Feedback from '+shared/Feedback';
import { logError, history, maskBetweenSetRange, capitalizeFirst, logBreadCrumb } from '+utils';
import { breadCrumbEvents } from '+utils/bugsnag-events';
import './index.scss';

const api = new APIRequest();

interface IConfirm2FAFormProps {
  twoFAParams: Record<string, string>;
  resetTwoFARequired: () => void;
  email?: string;
}

const Confirm2FAForm = ({
  twoFAParams,
  resetTwoFARequired,
  email
}: IConfirm2FAFormProps) => {
  const [feedback, setFeedback] = useState({ message: '', visible: false, type: 'danger' });
  const [isLoading, setIsLoading] = useState(false);
  const timerInterval = useRef(null);
  const [codeSent, setCodeSent] = useState(true);
  const [time, setTime] = useState(30);
  const [type, setCodeType] = useState(twoFAParams?.type);

  useEffect(() => {
    setCodeType(twoFAParams?.type);
  }, [twoFAParams?.type]);

  useEffect(() => {
    if (codeSent) {
      if (timerInterval.current) {
        clearInterval(timerInterval.current);
      }

      timerInterval.current = setInterval(() => {
        if (time <= 0) {
          clearInterval(timerInterval.current);
          setCodeSent(false);
        } else {
          setTime(time - 1);
        }
      }, 1000);
    }
    return () => clearInterval(timerInterval.current);
  }, [time]);

  const TwoFAMutation = useMutation(data => api.TwoFALogin(data), {
    retry: false,
    onSuccess: () => {
      history.push('/dashboard');
    },
    onError: e => {
      setIsLoading(false);
      const error = e.response?.data;
      logError(error?.message);
      const errorMessage = () => {
        if (error?.error === 'not_authorized' || error?.error === 'not_found') {
          return `Your account has been suspended, please contact support@korapay.com for more info`;
        }
        if (error?.message === 'invalid code provided') {
          return `Please enter a valid ${type === 'totp' ? 'authentication' : 'recovery'} code and try again`;
        }
        if (error?.message?.includes('invalid code provided, you have')) {
          return `${capitalizeFirst(error?.message)}`;
        }
        if (error?.message === 'Maximum login attempts reached, your account has been locked. Please contact support') {
          return `Maximum login attempts reached, your account has been locked. Please contact support`;
        }
        return 'There seems to be an issue signing you in. Please contact support@korapay.com for more info.';
      };
      setFeedback({ ...feedback, visible: true, message: `${errorMessage()}` });
    }
  });

  const ResendOtp = useMutation(data => api.resendLoginOtp(data));

  const confirm2FA = async ({ code }) => {
    logBreadCrumb({ event: breadCrumbEvents.signin['2FAButtonClicked'], data: { email } });
    setIsLoading(true);
    TwoFAMutation.mutate({ identifier: twoFAParams?.identifier, code, type });
  };

  return (
    <>
      {feedback.visible ? <Feedback type={feedback.type} message={feedback.message} /> : ''}

      <div className="headings">
        {type === 'otp' ? (
          <>
            <h2>Confirm your identity</h2>
            <p>
              Please enter the authentication code that was sent to <br /> {maskBetweenSetRange(email, 1, 7)} in order to proceed
            </p>
          </>
        ) : type === 'totp' ? (
          <>
            <h2>Confirm your identity</h2>
            <p>
              To proceed, use your two-factor authenticator app on your <br /> device to view your valid verification code, then fill-in
              below.
            </p>
          </>
        ) : (
          <>
            <h2>Login using Recovery Code</h2>
            <p>
              Can't use your authenticator app? Enter one of your <br /> previously saved recovery codes to restore access.
            </p>
          </>
        )}
      </div>
      <Formik
        initialValues={{ code: '' }}
        onSubmit={confirm2FA}
        validate={values => {
          const errors = {};
          if (values.code.length !== 0 && values.code.length < 6) errors.code = 'Invalid verification code';
          return errors;
        }}
      >
        {({ values, setFieldValue, errors, touched }) => {
          return (
            <Form>
              {errors.code && touched.code && <Feedback type="danger" message={errors.code} />}
              <Field name="code">
                {({ field }) => (
                  <div className="input__wrap">
                    <label htmlFor={field.name} className="input-label">
                      {type === 'totp_recovery_code' ? 'Recovery Code' : 'Your Verification Code'}
                    </label>
                    <input
                      autoComplete="one-time-code"
                      aria-label="code"
                      {...field}
                      maxLength="10"
                      value={values.code || ''}
                      style={{ letterSpacing: '10px', textAlign: 'center' }}
                      onChange={e => {
                        const {
                          target: { value }
                        } = e;
                        setFeedback({ message: '', visible: false, type: 'danger' });
                        setFieldValue('code', value.replace(/[A-Z<>%$!.+]/gi, ''));
                      }}
                    />
                  </div>
                )}
              </Field>
              <button className="btn-kpy --full-blue" type="submit" style={{ margin: '1.5rem 0' }} disabled={isLoading}>
                {isLoading ? (
                  <span className="spinner-border spinner-border-sm" style={{ marginRight: '0.5rem' }} role="status" aria-hidden="true" />
                ) : null}
                {isLoading ? <span style={{ marginLeft: '0.5rem' }}>Verifying...</span> : 'Log In Safely'}
              </button>
            </Form>
          );
        }}
      </Formik>
      {type === 'otp' ? (
        <p className="twofactor-invalid">
          Didn&apos;t receive a verification code?{' '}
          {!codeSent ? (
            <span
              onClick={() => {
                setCodeSent(true);
                setTime(30);
                logBreadCrumb({ event: breadCrumbEvents.signin.resendOtpButtonClicked, data: { email } });
                ResendOtp.mutate({ identifier: twoFAParams?.identifier });
              }}
              aria-hidden="true"
            >
              Resend
            </span>
          ) : (
            <span className="resend-timer">Resend code in {time} secs.</span>
          )}
        </p>
      ) : type === 'totp' ? (
        <p className="twofactor-invalid">
          Can&apos;t reach your authenticator app?
          <span
            onClick={() => {
              setCodeType('totp_recovery_code');
              logBreadCrumb({ event: breadCrumbEvents.signin.recoveryCodeClicked });
            }}
          >
            Login using Recovery Code
          </span>
        </p>
      ) : (
        <p className="twofactor-invalid">
          <span
            onClick={() => {
              setCodeType('totp');
              logBreadCrumb({ event: breadCrumbEvents.signin.loginButtonClicked });
            }}
          >
            Go Back
          </span>
        </p>
      )}
    </>
  );
}

export default Confirm2FAForm;
