import { useEffect } from 'react';
import { useMutation } from 'react-query';

import {
  ChargebackModalStageType,
  IChargebackModal,
  IChargebackModalState,
  IChargebackRequestPayload
} from '+containers/Dashboard/Issuing/types';
import Modal from '+dashboard/Shared/Modal';
import { useFeedbackHandler, useReducerState } from '+hooks';
import APIRequest from '+services/api-services';
import { ChargebackCategoryType, ChargebackStatusType, ISODateString } from '+types/transaction-types';
import { addDaysToDate, createDateObject, getDifferenceInDays, logError } from '+utils';
import useStore from '+store';

import IssuanceChargebackForm from './IssuanceChargebackForm';

const api: APIRequest = new APIRequest(process.env.REACT_APP_PUBLIC_MERCHANT_MIDDLEWARE_API_BASE);

const REM_DAYS_FOR_ESCALATION = Number(process.env.REACT_APP_CARD_ISSUANCE_ESCALATION_PERIOD || '2');
const REM_DAYS_FOR_VISA_REESCALATION = Number(process.env.REACT_APP_CARD_ISSUANCE_VISA_REESCALATION_TIME || '2');
const REM_DAYS_FOR_MASTER_REESCALATION = Number(process.env.REACT_APP_CARD_ISSUANCE_VISA_REESCALATION_TIME || '2');

const IssuingChargebackModal = ({
  modalIsVisible = false,
  closeModal,
  transactionAmount,
  transactionStatus,
  transactionCurrency,
  transactionDate,
  actualResolutionDate,
  expectedResolutionDate,
  issuingBalance,
  chargebackCategory,
  reference,
  refundedAmount,
  chargebackStatus,
  cardScheme,
  refetchTransactionDetails
}: IChargebackModal) => {
  const issuingFees = useStore(state => state.issuingFees);
  const chargebackFee = issuingFees?.customer?.chargeback;

  const modalStages: Record<ChargebackModalStageType, ChargebackModalStageType> = {
    INITIAL: 'INITIAL',
    CONFIRMATION: 'CONFIRMATION'
  };

  const initialModalState: IChargebackModalState = {
    modalStage: modalStages.INITIAL,
    modalStageIsLoading: false,
    chargebackAmount: '',
    minChargebackAmount: '',
    maxChargebackAmount: '',
    chargebackCategory: 'none',
    chargebackCategoriesListIsOpen: false,
    reasonForChargeback: '',
    evidenceOfFileUpload: undefined,
    fileName: '',
    formIsDisabled: false
  };

  const [state, setState] = useReducerState(initialModalState);

  const { feedbackInit, closeFeedback } = useFeedbackHandler();

  const MIN_CHARGEBACK_AMOUNT_IN_USD = '1';

  useEffect(() => {
    if (chargebackStatus === 'partially_accepted') {
      const unpaidBalance = +transactionAmount - +refundedAmount!;
      setState({ maxChargebackAmount: `${unpaidBalance}`, minChargebackAmount: MIN_CHARGEBACK_AMOUNT_IN_USD });
    } else
      setState({
        maxChargebackAmount: transactionAmount,
        minChargebackAmount: MIN_CHARGEBACK_AMOUNT_IN_USD
      });

    setState({ chargebackCategory });

    if (showWarningOnCriteriaFailure()) setState({ formIsDisabled: true });
  }, [chargebackStatus, transactionAmount, refundedAmount, modalIsVisible, issuingBalance, chargebackCategory]);

  const chargebackAmountIsValid = () => {
    if (+state.chargebackAmount! < +state.minChargebackAmount!) return false;
    if (+state.chargebackAmount! > +state.maxChargebackAmount!) return false;
    return true;
  };

  const requiredInputsAreValid = () => {
    if (!chargebackAmountIsValid()) return false;
    if (!state.chargebackCategory) return false;
    if (!state.reasonForChargeback) return false;
    return true;
  };

  const disableSubmitButton = () => {
    if (state.modalStageIsLoading) return true;
    if (showWarningOnCriteriaFailure()) return true;
    if (!requiredInputsAreValid()) return true;
    return false;
  };

  const visaReescalationDeadline = addDaysToDate(actualResolutionDate as ISODateString, REM_DAYS_FOR_VISA_REESCALATION);
  const escalationDeadline = addDaysToDate(transactionDate, REM_DAYS_FOR_ESCALATION);
  const currentDate = createDateObject();

  const escalationIsValid = getDifferenceInDays((currentDate as unknown) as Date, (escalationDeadline as unknown) as Date) < 0;

  const masterCardReescalationIsValid =
    getDifferenceInDays((currentDate as unknown) as Date, (expectedResolutionDate as unknown) as Date) < 0;

  const visaCardReescalationIsValid =
    getDifferenceInDays((currentDate as unknown) as Date, (visaReescalationDeadline as unknown) as Date) < 0;

  const showWarningOnCriteriaFailure = () => {
    if (transactionStatus === 'success') {
      if (issuingBalance < chargebackFee && !escalationIsValid) {
        return (
          <>
            This chargeback cannot be escalated because the{' '}
            <strong>
              transaction was initiated over {REM_DAYS_FOR_ESCALATION} days ago and you do not have the required ${chargebackFee} processing
              fee
            </strong>{' '}
            in your issuing balance.
          </>
        );
      }

      if (issuingBalance < chargebackFee) {
        return (
          <>
            This chargeback cannot be escalated because you do not have the required <strong>minimum balance of ${chargebackFee}</strong> in
            your issuing balance for chargeback processing. Please fund your balance and try again.
          </>
        );
      }

      if (!escalationIsValid) {
        return (
          <>
            This chargeback cannot be escalated because the{' '}
            <strong>transaction was initiated over {REM_DAYS_FOR_ESCALATION} days ago</strong>.
          </>
        );
      }
    }

    if (['declined', 'partially_accepted'].includes(chargebackStatus as string)) {
      if (cardScheme === 'mastercard' && !masterCardReescalationIsValid)
        return (
          <>
            This chargeback cannot be escalated because you have exceeded the <strong>Master card re-escalation deadline</strong>.
          </>
        );

      if (cardScheme === 'visa' && !visaCardReescalationIsValid)
        return (
          <>
            This chargeback cannot be escalated because you have exceeded the <strong>Visa card re-escalation deadline</strong>.
          </>
        );
    }
    return null;
  };

  const switchApiMethodByStatus = (status: ChargebackStatusType) => {
    switch (status) {
      case 'declined':
      case 'partially_accepted':
      case 'invalid':
        return (payload: IChargebackRequestPayload) =>
          api.reescalateIssuedCardChargeback(reference, payload as Required<IChargebackRequestPayload>);
      default:
        return (payload: IChargebackRequestPayload) => api.escalateIssuedCardChargeback(payload);
    }
  };

  const { mutateAsync } = useMutation(switchApiMethodByStatus(chargebackStatus as ChargebackStatusType), {
    onError: error => {
      setState({
        modalStage: modalStages.INITIAL,
        modalStageIsLoading: false
      });
      feedbackInit({
        message: error.response?.data?.message || 'We are sorry, we could not (re)escalate your chargeback right now. Please try again',
        type: 'danger',
        componentLevel: true
      });
      setTimeout(() => {
        closeFeedback();
      }, 2500);
      logError(error.response?.data);
    }
  });

  const chargebackCanBeReescalated = ['partially_accepted', 'declined', 'invalid'].includes(chargebackStatus as string);

  const generateDescription = () => {
    if (chargebackCanBeReescalated && cardScheme === 'visa')
      return `re-escalated within ${REM_DAYS_FOR_VISA_REESCALATION} days after initial escalation`;
    if (chargebackCanBeReescalated && cardScheme === 'mastercard')
      return `re-escalated within ${REM_DAYS_FOR_MASTER_REESCALATION} days after initial escalation`;
    return `escalated within ${REM_DAYS_FOR_ESCALATION} days after it was initiated`;
  };

  const modalPropsOptions = {
    SHARED: {
      visible: modalIsVisible,
      heading: chargebackCanBeReescalated ? `Re-escalate Chargeback` : `Escalate Chargeback`,
      firstButtonText: 'Cancel',
      secondButtonDisable: disableSubmitButton(),
      secondButtonActionIsTerminal: state.modalStage === modalStages.CONFIRMATION,
      close: () => {
        setState({
          ...initialModalState
        });
        refetchTransactionDetails();
        closeModal();
      }
    },
    INITIAL: {
      description: (
        <div style={{ fontSize: '14px' }}>
          {`Fill in the details below to declare a dispute on this transaction. A transaction can only be ${generateDescription()}.`}
        </div>
      ),
      firstButtonAction: () => {
        setState({
          ...initialModalState
        });
        closeModal();
      },
      secondButtonText: 'Submit',
      secondButtonAction: () => setState({ modalStage: modalStages.CONFIRMATION }),
      content: (
        <IssuanceChargebackForm
          updateModalState={setState}
          chargebackStatus={chargebackStatus as ChargebackStatusType}
          chargebackCategory={state.chargebackCategory as ChargebackCategoryType}
          warningOnCriteriaFailure={showWarningOnCriteriaFailure() as JSX.Element}
          currency={transactionCurrency}
          chargebackAmount={state.chargebackAmount}
          isDisabled={state.formIsDisabled}
          minChargebackAmount={state.minChargebackAmount}
          maxChargebackAmount={state.maxChargebackAmount}
          reasonForChargeback={state.reasonForChargeback}
          evidenceOfFileUpload={state.evidenceOfFileUpload as string}
          fileName={state.fileName as string}
        />
      )
    },
    CONFIRMATION: {
      description: `Are you sure you want to declare a dispute on this transaction? You will be charged a $${chargebackFee} processing fee for this chargeback.`,
      firstButtonAction: () => setState({ modalStage: modalStages.INITIAL }),
      secondButtonText: 'Yes, submit',
      secondButtonAction: async () => {
        if (!showWarningOnCriteriaFailure()) {
          setState({ modalStageIsLoading: true });

          const collatedChargebackData: IChargebackRequestPayload = {
            amount: state?.chargebackAmount as string,
            evidence: state?.evidenceOfFileUpload as string,
            ...(chargebackCanBeReescalated && { reason: state?.reasonForChargeback as string }),
            ...(!chargebackCanBeReescalated && {
              description: state?.reasonForChargeback as string,
              category: state?.chargebackCategory as ChargebackCategoryType,
              transaction_reference: reference as string
            })
          };

          await mutateAsync(collatedChargebackData);
        }
      },
      size: 'sm',
      content: null,
      completedHeading: chargebackCanBeReescalated ? 'Chargeback Re-escalated' : 'Chargeback Escalated',
      completedDescription: `The chargeback has been ${chargebackCanBeReescalated ? 're-escalated' : 'escalated'} successfully.`
    }
  };

  const modalProps = {
    ...modalPropsOptions.SHARED,
    ...modalPropsOptions[state.modalStage]
  };

  return <Modal {...modalProps} />;
};

export default IssuingChargebackModal;
