import ReactGA from 'react-ga';

import { history } from '+utils';

import Button from './Button';

import KpyLogo from '+assets/img/logos/logo-kpy-ent.png';
import KpyLogoSvg from '+assets/img/logos/logo-kpy-ent.svg';

import './index.scss';

interface IErrorPageProps {
  context?: string;
  clearError: () => void;
}

const ErrorPage = ({
  context = 'fallback',
  clearError,
}: IErrorPageProps) => {
  const errorContext = {
    fallback: {
      heading: 'Well this is unexpected',
      description: (
        <span>
          An error has occurred and we’re working to fix the problem! We’ll be up and running shortly. Please
          <a href="/contact">Contact us</a>
          for immediate help or our
          <a href="/contact" target="_blank">
            Help center
          </a>
          for information.
        </span>
      ),
      backgroundUrl: ''
    },
    '404': {
      heading: 'Oops, page not found',
      description:
        'We are very sorry for the inconvenience. It looks like you’re trying to access a page that has been deleted or never even existed.',
      backgroundUrl: '../../../assets/img/external/404.png'
    }
  };

  return (
    <main className="external__main">
      <div className="error__wrapper" style={{ background: `url("${errorContext[context as keyof typeof errorContext].backgroundUrl}")` }}>
        <div className="logo-container">
          <ReactGA.OutboundLink eventLabel="KPYLogo" to="." trackerNames={['Logo']} className="logo-tag">
            <img src={KpyLogo} srcSet={KpyLogoSvg} alt="Korapay-Logo" loading="lazy" />
          </ReactGA.OutboundLink>
        </div>
        <div className="error-content">
          <h1>{errorContext[context as keyof typeof errorContext].heading}</h1>
          <p className="description">{errorContext[context as keyof typeof errorContext].description}</p>
        </div>
        <Button
          className="btn-kpy --full-green"
          title="Go back"
          onClick={() => {
            if (clearError) clearError();
            history.goBack();
          }}
        />
      </div>
    </main>
  );
}

export default ErrorPage;
