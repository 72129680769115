import React, { useEffect, useState } from 'react';
import { useMutation, useQuery } from 'react-query';

import useFeedbackHandler from '+hooks/feedbackHandler';
import APIRequest from '+services/api-services';
import useStore from '+store';
import { logError, maskBetweenSetRange } from '+utils';

import Copyable from '../../Shared/Copyable';
import Modal from '../../Shared/Modal';
import Tooltip from '../../Shared/Tooltip';
import NotificationURLs from './components/notification';

import warning from '+assets/img/dashboard/warning-icon.svg';

import './index.scss';

const api = new APIRequest();

const ApiIntegrationsComponent = () => {
  const { feedbackInit, closeFeedback } = useFeedbackHandler();
  const [state, setState] = useState({
    modalVisible: false,
    modalType: '',
    refreshAPIKeysModal: {
      isOpen: false
    },
    activeId: '',
    privateCopied: false,
    publicCopied: false,
    password: '',
    buttonDisabled: true
  });

  useEffect(() => {
    if (state.password !== '') {
      setState(prevState => ({
        ...prevState,
        buttonDisabled: false
      }));
    } else {
      setState(prevState => ({
        ...prevState,
        buttonDisabled: true
      }));
    }
  }, [state.password]);

  const MERCHANT_ENV = useStore(store => store.merchantEnv);
  const passwordErrors = ['invalid request data', 'Password is invalid'];

  const { data, refetch: refetchTokens, isLoading } = useQuery(['FETCH_SAVED_TOKENS', MERCHANT_ENV], () => api.fetchTokens(), {
    refetchOnReconnect: false,
    onError: e => {
      const error = e.response?.data;
      logError(error?.message);
      feedbackInit({
        message: 'There has been an error fetching your API keys',
        type: 'danger',
        action: {
          action: () => refetchTokens(),
          name: 'Try again'
        }
      });
    }
  });

  const refreshTokens = useMutation(() => api.refreshTokens({ password: state.password }), {
    onMutate: () => {
      closeFeedback();
    },
    onSuccess: () => {
      refetchTokens();
      setState(prevState => ({ ...prevState, password: '' }));
    },
    onError: e => {
      const error = e?.response?.data;
      logError(e);
      setState(prevState => ({
        ...prevState,
        password: ''
      }));
      feedbackInit({
        message: passwordErrors.includes(error?.message) ? 'Password is invalid' : 'There has been an error generating your API keys.',
        type: 'danger',
        componentLevel: true
      });
    }
  });

  const generateEncryptionToken = useMutation(() => api.generateEncryptionToken({ password: state.password }), {
    onMutate: () => {
      closeFeedback();
    },
    onSuccess: () => {
      refetchTokens();
      setState(prevState => ({ ...prevState, password: '' }));
    },
    onError: e => {
      const error = e?.response?.data;
      logError(e);
      setState(prevState => ({
        ...prevState,
        password: ''
      }));
      feedbackInit({
        message: passwordErrors.includes(error?.message) ? 'Password is invalid' : 'There has been an error generating your API keys.',
        type: 'danger',
        componentLevel: true
      });
    }
  });

  const apiKeys = data?.data;

  const showAPIKeys = () => {
    return !apiKeys || Object.keys(apiKeys).length === 0 ? (
      <p className="rule-box" style={{ padding: '10px' }}>
        {!isLoading && 'Please generate your public and private keys'}
      </p>
    ) : (
      <div className="key__container">
        <div className="key__row">
          <h6>Public Key</h6>
          <p>
            <Copyable text={apiKeys?.public_key} copyText="Public key copied!" textModifier={val => maskBetweenSetRange(val, 12, 45)} />
          </p>
        </div>

        <div className="key__row">
          <h6>Secret Key</h6>
          <p>
            <Copyable text={apiKeys?.secret_key} copyText="Secret key copied!" textModifier={val => maskBetweenSetRange(val, 12, 45)} />
          </p>
        </div>
      </div>
    );
  };

  const showEncryptionKeys = () => {
    return !apiKeys || Object.keys(apiKeys).length === 0 ? (
      <p className="rule-box" style={{ padding: '10px' }}>
        {!isLoading && 'Please generate your encryption keys'}
      </p>
    ) : (
      <div className="key__container">
        <div className="key__row">
          <h6 aria-describedby="encryption-info">
            Encryption Key{' '}
            <Tooltip
              type="encryption"
              message={
                <p>
                  The encryption key is required during integration and used to encrypt sensitive payment data sent to the payment gateway.
                </p>
              }
            />
          </h6>
          <p>
            <Copyable
              text={apiKeys?.encryption_key}
              copyText="Encryption key copied!"
              textModifier={val => maskBetweenSetRange(val, 12, 45)}
            />
          </p>
        </div>
      </div>
    );
  };

  return (
    <>
      <div id="apiTab" className="">
        <div className="element-box ac-element-box ac-wrapper">
          <h5 className="form-header">Korapay APIs</h5>
          <div className="form-desc ac-subtitle">
            Korapay authenticates your API requests using your account’s API keys. Note that your API keys in test mode are different from
            the keys in live mode.{' '}
            <a href="https://developers.korapay.com/docs/api-keys" target="_blank" rel="noopener noreferrer">
              See our documentation
            </a>{' '}
            to learn more about your API keys.
          </div>
          <div className="element-box-content ac-element-box-content">
            <div className="header-container ac-content--header">
              <h6>API Keys</h6>
              <button className="btn ac-button" type="button" onClick={() => setState({ ...state, modalVisible: true, modalType: 'api' })}>
                <span>Generate new API Keys</span>
              </button>
            </div>
            {showAPIKeys()}
          </div>
          <div className="element-box-content ac-element-box-content">
            <div className="header-container ac-content--header">
              <h6>Encryption</h6>
              <button
                className="btn ac-button"
                type="button"
                onClick={() => setState({ ...state, modalVisible: true, modalType: 'encryption' })}
              >
                <span>Generate new Encryption Key</span>
              </button>
            </div>
            {showEncryptionKeys()}
          </div>
        </div>
        <div className="ac-wrapper">
          <NotificationURLs />
        </div>
      </div>
      {state.modalVisible && state.modalType === 'api' && (
        <Modal
          size="md"
          close={() => setState({ ...state, modalVisible: false, modalType: '', password: '' })}
          visible={state.modalVisible}
          heading="Generate New API Keys?"
          description={
            <p className="modal-custom-wrapper modal-custom-description">
              <img src={warning} alt="warning icon" />
              Generating new keys will expire your existing public and secret keys and replace them with new keys. This action cannot be
              undone.
            </p>
          }
          content={
            <div className="modal-password-check">
              <label htmlFor="modal--user-password">
                <h6>To confirm this action, enter your password</h6>
              </label>
              <input
                autoComplete="off"
                className="form-control modal-password-check--input"
                placeholder="Enter Password"
                type="password"
                value={state.password}
                id="modal--user-password"
                name="modal--user-password"
                maxLength="50"
                onChange={e => {
                  const { value } = e.target;
                  setState(prevState => ({ ...prevState, password: value }));
                }}
                onKeyDown={e => {
                  if (e.key === 'Enter') {
                    e.preventDefault();
                    const secondButton = document.querySelectorAll('.modal-footer button')[1];
                    secondButton.click();
                  }
                }}
              />
            </div>
          }
          secondButtonAction={() => refreshTokens.mutateAsync()}
          completedHeading="API Keys Updated"
          completedDescription="Your API keys have been updated successfully"
          secondButtonColor="#2376F3"
          secondButtonText="Yes, Confirm"
          secondButtonDisable={state.buttonDisabled}
        />
      )}
      {state.modalVisible && state.modalType === 'encryption' && (
        <Modal
          size="md"
          close={() => setState({ ...state, modalVisible: false, modalType: '', password: '' })}
          visible={state.modalVisible}
          heading="Generate Encryption Key?"
          description={
            <p className="modal-custom-wrapper modal-custom-description">
              <img src={warning} alt="warning icon" />
              Generating new keys will expire your existing encryption key and replace it with a new key. This action cannot be undone.
            </p>
          }
          content={
            <div className="modal-password-check">
              <label htmlFor="modal--user-password">
                <h6>To confirm this action, enter your password</h6>
              </label>
              <input
                autoComplete="off"
                className="form-control modal-password-check--input"
                placeholder="Enter Password"
                type="password"
                value={state.password}
                id="modal--user-password"
                name="modal--user-password"
                maxLength="50"
                onChange={e => {
                  const { value } = e.target;
                  setState(prevState => ({ ...prevState, password: value }));
                }}
                onKeyDown={e => {
                  if (e.key === 'Enter') {
                    e.preventDefault();
                    const secondButton = document.querySelectorAll('.modal-footer button')[1];
                    secondButton.click();
                  }
                }}
              />
            </div>
          }
          secondButtonAction={() => generateEncryptionToken.mutateAsync()}
          completedHeading="Encryption Key Updated"
          completedDescription="Your Encryption key has been updated successfully"
          secondButtonColor="#2376F3"
          secondButtonText="Yes, Confirm"
          secondButtonDisable={state.buttonDisabled}
        />
      )}
    </>
  );
};

export default React.memo(ApiIntegrationsComponent);
