/* eslint-disable class-methods-use-this */
/* eslint-disable camelcase */
import axios, { AxiosError, AxiosInstance, AxiosRequestConfig } from 'axios';
import createAuthRefreshInterceptor from 'axios-auth-refresh';
import qs from 'qs';

import { IdReqPayloadType } from '+containers/Dashboard/Identity/types/types';
import { TConversionsPayload } from '+containers/Dashboard/Settings/Settlements/components/Conversions/data';
import { Storage } from '+services/storage-services';
import { BulkDataT, ChargebackCategoryType, IEmailSubscriptionResponse, ISubscriptionVariable, TeamMemberType } from '+types';
import { IBankNameEnquiry, IBVNDetails, IMobileNameEnquiry, IVerificationDetails } from '+types/apiServices-types';
import { filterOutEmptyValues, history, logError, Logger, queryClient } from '+utils';

import useStore from '../store';
import APIServiceError from './error-services';

const APIBaseURL = process.env.REACT_APP_MERCHANT_MIDDLEWARE_API_BASE || 'http://localhost:3000/api';
const subscription = useStore.subscribe(() => { }); // create a listener to the store
subscription(); // invoke the listener

export default class APIRequest {
  private instance: AxiosInstance;

  private store = useStore;

  private abortController: AbortController;

  refreshAuthLogic: () => Promise<void>;

  constructor(baseURL?: string | undefined) {
    this.abortController = new AbortController();
    this.instance = axios.create({
      baseURL: baseURL || APIBaseURL,
      timeout: 60000,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      signal: this.abortController.signal
    });

    // Function that will be called to refresh authorization
    // eslint-disable-next-line consistent-return
    this.refreshAuthLogic = async (): Promise<void> => {
      const { authDetails } = useStore.getState() as any;
      const refreshToken = authDetails?.refresh_token;
      const refreshTokenExpiration = authDetails?.refresh_token_expires_in;
      const currentTimestamp = Math.floor(Date.now() / 1000);
      if (refreshToken && currentTimestamp < refreshTokenExpiration) {
        try {
          await this.refresh(process.env.REACT_APP_MERCHANT_MIDDLEWARE_API_BASE, refreshToken);
          Logger.info('Access token refreshed, retrying previous request');
          return Promise.resolve();
        } catch (error) {
          logError(error);
        }
      }
      this.logout('/auth/login');
    };

    this.instance.interceptors.request.use(
      (config: { headers?: any; url?: string | undefined; paramsSerializer?: any }) => {
        const { authDetails } = useStore.getState() as any;
        const configCopy = config;
        const refreshToken = authDetails?.refresh_token;
        const userTokenExpiration = authDetails?.expires_in;
        configCopy.headers = { ...config.headers, Authorization: this.setAuthorization() };
        configCopy.paramsSerializer = (params: any) => {
          const result = qs.stringify(params, { arrayFormat: 'brackets' });
          return `${decodeURIComponent(result)}`;
        };

        // Handle refresh token for API calls that aren't auth
        if (configCopy?.url?.includes('/auth') || configCopy.url?.includes('/oauth')) return configCopy;

        // Set refresh threshold to two minutes
        const refreshThreshold = Math.floor((new Date().getTime() + 120000) / 1000);

        if (refreshToken && userTokenExpiration < refreshThreshold) {
          this.refresh(process.env.REACT_APP_MERCHANT_MIDDLEWARE_API_BASE, refreshToken).catch(error => logError(error));
        }
        return configCopy;
      },
      (error: any) => {
        return Promise.reject(error);
      }
    );

    this.instance.interceptors.request.use((config: AxiosRequestConfig<any>) => {
      const { sessionActive } = useStore.getState() as any;
      const currentPath = window?.location?.pathname || '';
      const onDashboard = currentPath.includes('dashboard') || currentPath.includes('auth/kyc');
      if (sessionActive !== true && onDashboard) {
        if (currentPath?.includes('auth/kyc')) history.push('/auth/login');
        this.abortController.abort('session inactive');
      }
      return config;
    }, undefined);

    /**
     * It is important for the auth refresh interceptor to be called BEFORE
     * the other error interceptor below. The later rejects an error as an instance
     * of APIServiceError whereas the actual error object is needed for the first.
     */
    createAuthRefreshInterceptor(this.instance, this.refreshAuthLogic, {
      shouldRefresh: error => {
        const status = error?.status || error?.response?.status;
        const fromAuth = ['/auth/signin', '/auth/signup', '/auth/two-factor/complete'].includes(error.response?.config?.url ?? '');
        if (status === 401 && !fromAuth) {
          return true;
        }
        return false;
      }
    });

    this.instance.interceptors.response.use(undefined, (error: AxiosError) => {
      if (error.config && error.config.headers) {
        const headers = { ...error.config.headers };
        delete headers.Authorization;
        error.config.headers = headers;
      }

      if (!error.response) {
        if (process.env.NODE_ENV !== 'production') {
          Logger.error('Response: ', 'Network Error');
        }
        return Promise.reject(
          new APIServiceError({
            status: 500,
            data: {
              message: 'Network Error, try again',
              error: 'server_error',
              data: null
            }
          })
        );
      }

      if (process.env.NODE_ENV !== 'production') {
        Logger.warn('Response: ', error.response);
      }

      return Promise.reject(new APIServiceError(error.response));
    });
  }

  // ******************************** //
  // AUTH APIS
  // ******************************** //

  setAuthorization = () => {
    const { authDetails, tempToken } = this.store.getState() as any;
    const userToken = authDetails?.access_token;
    const tempAccessToken = tempToken;
    if (tempAccessToken && !userToken) return `Bearer ${tempAccessToken}`;
    if (userToken) return `Bearer ${userToken}`;
    return null;
  };

  setEnvironmentHeader = async () => {
    const { authDetails, merchantEnv } = useStore.getState() as any;
    if (!merchantEnv) return authDetails?.account?.merchants[0]?.env;
    return this.store.getState()?.merchantEnv;
  };

  clearHeader() {
    delete this.instance.defaults.headers.common.Authorization;
  }

  signup = async (data: void) => {
    const response = await this.instance.post('/auth/signup', data);
    return response;
  };

  resend = async (data: void) => {
    const response = await this.instance.post('/auth/signup/resend-verification-email', data);
    return response.data;
  };

  verifyToken = async (data: { verification_token: string; email: string }) => {
    const response = await this.instance.post('/auth/signup/verify-user', data);
    return response.data;
  };

  initiateReset = async (data: any) => {
    const response = await this.instance.post('/auth/reset-password/initiate', data);
    return response.data;
  };

  completeReset = async (data: any) => {
    const response = await this.instance.post('/auth/reset-password/complete', data);
    return response.data;
  };

  verifyReset = async (data: { email: any; code: any }) => {
    const response = await this.instance.get(`/users/password/reset/verify?address=${data.email}&code=${data.code}`);
    return response.data;
  };

  signIn = async (data: void) => {
    const response = await this.instance.post('/auth/signin', data);
    if (response.data.message === 'A verification email has been sent to your email address') {
      return 'Unverified';
    }
    const authResponse = response.data;

    if (authResponse?.data?.two_factor_auth?.required) {
      useStore.setState({ tempToken: authResponse.data.access_token });
      return {
        identifier: authResponse.data.two_factor_auth.identifier,
        type: authResponse.data.two_factor_auth.type
      };
    }

    // IN THIS CASE, THE USER IS NOT USING 2FA
    useStore.setState({ authDetails: authResponse?.data });
    return null;
  };

  logout = (destination: null | string = null) => {
    this.store.setState({
      authDetails: {},
      tempToken: '',
      profile: {},
      defaultMerchant: {},
      sessionActive: false,
      merchantEnv: null
    });
    this.store.destroy(); // destroy the listener
    this.store.persist.clearStorage(); // clear the store from storage
    Storage.removeItem('merchantID');
    queryClient.setDefaultOptions({
      queries: {
        enabled: false,
        refetchOnWindowFocus: false
      }
    });
    if (destination) history.push(destination);
  };

  setMerchantData = async (data: {
    account: { first_name: any; last_name: any; email: any; phone: any; avatar: any; created_at: any; two_factor_enabled: any };
    merchant: { env: any; can_go_live: any };
  }) => {
    if (data) {
      this.store.setState({
        profile: {
          firstName: data?.account?.first_name,
          lastName: data?.account?.last_name,
          email: data?.account?.email,
          phone: data?.account?.phone,
          avatar: data?.account?.avatar,
          created: data?.account?.created_at
        },
        merchantEnv: data?.merchant?.env,
        is2FactorEnabled: data?.account?.two_factor_enabled,
        canGoLive: data?.merchant?.can_go_live,
        sessionActive: true,
        defaultMerchant: {
          ...data?.merchant,
          userEmail: data?.account?.email
        }
      });
    }
  };

  setMerchantCurrency = async (data: { available_currencies: any; default_currency: any }) => {
    if (data) {
      const availableCurrency = data?.available_currencies;
      const defaultCurrency = data?.default_currency;
      let arrCurrency = [];
      const NGNIndex = availableCurrency.indexOf('NGN');
      const KESIndex = availableCurrency.indexOf('KES');
      const USDIndex = availableCurrency.indexOf('USD');
      if (NGNIndex > -1) arrCurrency.push(availableCurrency[NGNIndex]);
      if (USDIndex > -1) arrCurrency.push(availableCurrency[USDIndex]);
      if (KESIndex > -1) arrCurrency.push(availableCurrency[KESIndex]);
      /**
       * the 3 ifs is to arrange the currency in the order that we want ['NGN', 'USD', 'KES'];
       * the filter and spread below is to spread any other currency to be added in the future and
       * also ensure there is no duplicate currency added to the array.
       */
      arrCurrency = [...arrCurrency, ...availableCurrency]?.filter((v, i, a) => a?.indexOf(v) === i);
      this.store.setState({
        availableCurrencies: arrCurrency,
        defaultCurrency
      });
    }
  };

  setMerchantAccess = async (data: { status: any; data: any }) => {
    if (data.status) {
      queryClient.setQueryData('ACCESS_CONTROL', { ...data?.data });
    }
  };

  loginAttempt = async (data: any) => {
    const response = await this.instance.post('/auth', { ...data });
    const authResponse = response.data.data;
    if (authResponse.two_factor_auth.required) {
      useStore.setState({ tempToken: authResponse.access_token });
      return {
        identifier: authResponse.two_factor_auth.identifier,
        type: authResponse.two_factor_auth.type
      };
    }

    // IN THIS CASE, THE USER IS NOT USING 2FA
    useStore.setState({ authDetails: authResponse });
    return null;
  };

  TwoFALogin = async data => {
    const response = await this.instance.post('/auth/sign-in/two-factor/complete', data);
    const authResponse = response.data.data;
    useStore.setState({ tempToken: null, authDetails: authResponse });
    return null;
  };

  resendLoginOtp = async data => {
    const response = await this.instance.post('/auth/sign-in/two-factor/otp/resend', data);
    return response.data;
  };

  refresh = async (url: string | undefined, token: any) => {
    if (token) {
      const response = await this.instance.post(`${url}/auth/refresh-token`, { refresh_token: token });
      const authResponse = response.data.data;
      useStore.setState({ authDetails: authResponse });
    }
    return null;
  };

  bootstrap = async () => {
    const merchantResponse = await this.instance.get('/merchants/get');
    this.getCurrency();
    return this.setMerchantData(merchantResponse?.data?.data);
  };

  getProductAccess = async () => {
    const { defaultMerchant } = useStore.getState() as any;
    const response = await this.instance.get('/settings/products/access', {
      headers: { 'www-environment': await this.setEnvironmentHeader() }
    });

    useStore.setState({ defaultMerchant: { ...defaultMerchant, ...{ productAccess: response.data.data } } });
  };

  getCurrency = async () => {
    const response = await this.instance.get('/merchants/payment-configurations', {
      headers: { 'www-environment': await this.setEnvironmentHeader() }
    });
    return this.setMerchantCurrency(response?.data?.data);
  };

  updateMerchantEnv = async (data: any) => {
    const response = await this.instance.post('/merchants/profile/set-environment', data);
    return response.data;
  };

  initiatePasswordReset = async (data: void) => {
    const response = await this.instance.post('/users/password/reset/initiate', data);
    return response.data;
  };

  completePasswordReset = async (data: void) => {
    const response = await this.instance.post('/users/password/reset/complete', data);
    return response.data;
  };

  changePassword = async (data: any) => {
    const response = await this.instance.put('/profile/password', data);
    return response.data;
  };

  emailLookup = async (data: any) => {
    const response = await this.instance.post('/auth/get-started', data);
    return response.data;
  };

  verifyGetStartedTokenUser = async (data: any) => {
    const response = await this.instance.post('/auth/get-started/verify', data);
    return response.data;
  };

  verifyGetStartedTokenTeam = async (token: any) => {
    const response = await this.instance.get(`/users/teams/invites/${token}`);
    return response.data;
  };

  completeGetStarted = async (data: any) => {
    const response = await this.instance.post('/auth/get-started/complete', data);
    return response.data;
  };

  completeGetStartedTeam = async (data: any) => {
    const response = await this.instance.post('users/teams/onboard', data);
    return response.data;
  };

  initiateTwoFaRegistration = async (data: void) => {
    const response = await this.instance.post('/auth/two-factor/initiate', data);
    return response.data;
  };

  getinitTwoFASetup = async (data: void) => {
    const response = await this.instance.post('/2fa/totp/register/initiate', data);
    return response.data;
  };

  activatetwoFA = async (data: { code: string }) => {
    const response = await this.instance.post('/2fa/totp/register/complete', data, {
      headers: { 'www-environment': 'live' }
    });
    return response.data;
  };

  get2FAHistory = async () => {
    const response = await this.instance.get('/2fa/totp');
    return response.data;
  };

  twoFAAuthorization = async () => {
    const response = await this.instance.post('/2fa/authorization/initiate', { type: 'totp' });
    return response.data;
  };

  deactivateTwoFA = async (data: { code: string; identifier: any }) => {
    const response = await this.instance.post('/2fa/totp/deactivate', data, {
      headers: { 'www-environment': 'live' }
    });
    return response.data;
  };

  setNotificationUrls = async (data: void) => {
    const response = await this.instance.post('/merchants/profile/notification-urls', data, {
      headers: { 'www-environment': await this.setEnvironmentHeader() }
    });
    return response.data;
  };

  getNotificationUrls = async () => {
    const response = await this.instance.get('/merchants/profile/notification-urls', {
      headers: { 'www-environment': await this.setEnvironmentHeader() }
    });
    return response.data.data;
  };

  // ******************************** //
  // KYC APIS
  // ******************************** //

  businessType = async (data: void) => {
    const response = await this.instance.put('/kyc/business-type', data);
    return response.data;
  };

  businessProfile = async (data: any) => {
    const response = await this.instance.put('/kyc/business-profile', data);
    return response.data;
  };

  businessDoc = async (data: any) => {
    const response = await this.instance.put('/kyc/documents', data);
    return response.data;
  };

  businessRep = async (data: void) => {
    const response = await this.instance.put('/kyc/representatives', data);
    return response.data;
  };

  settlementAccount = async (data: any) => {
    const response = await this.instance.put('/kyc/settlement-accounts', data);
    return response.data;
  };

  unsupportedCountries = async (data: void) => {
    const response = await this.instance.post('/kyc/unsupported-countries', data);
    return response.data;
  };

  getKYCMetaData = async (metaData: string) => {
    const response = await this.instance.get(`/kyc/meta-data?keys[]=${metaData}`);
    return response.data;
  };

  resetKyc = async () => {
    const response = await this.instance.patch('/kyc/reset');
    return response.data;
  };

  skipComplianceCategory = async (data: any) => {
    const response = await this.instance.post('/kyc/skip-category', data);
    return response.data;
  };

  detailsVerification = async (data: IVerificationDetails) => {
    const response = await this.instance.post('/kyc/id/verify', data, {
      headers: { 'www-environment': 'live' }
    });
    return response.data;
  };

  bvnSubmission = async (data: IBVNDetails) => {
    const response = await this.instance.put('/kyc/bvn', data, {
      headers: { 'www-environment': 'live' }
    });
    return response.data;
  };

  getMerchantTieringData = async () => {
    const response = await this.instance.get(`/merchants/product-limits`);
    return response.data;
  };

  // ******************************** //
  // TEAM/ROLES APIS
  // ******************************** //

  fetchTeamMembers = async () => {
    const response = await this.instance.get('/users/teams');
    return response.data as { data: TeamMemberType[] };
  };

  fetchTeam = async () => {
    const response = await this.instance.get('/users/roles');
    return response.data;
  };

  fetchRoles = async (page: number, limit: number, sortingParams: {} | undefined, showAll: boolean | undefined) => {
    const response = await this.instance.get('/roles', {
      params: { page, limit, showAll, ...sortingParams }
    });
    return response.data;
  };

  inviteTeamMember = async (data: {
    email: any;
    role_slug: any;
    authorization: { two_factor_type: string; code: string; identifier: string };
  }) => {
    const response = await this.instance.post('/users/teams/invites', data);
    return response.data;
  };

  removeMember = async (data: { email: any; authorization: { two_factor_type: string; code: string; identifier: string } }) => {
    const response = await this.instance.post('/users/teams/invites/remove', data);
    return response.data;
  };

  changeMemberRole = async (data: any) => {
    const response = await this.instance.patch('/users/teams/roles', data);
    return response.data;
  };

  createCustomRole = async (data: any) => {
    const response = await this.instance.post('/roles', data);
    return response.data;
  };

  updateCustomRole = async (id: number, data: any) => {
    const response = await this.instance.patch(`/roles/${id}`, data);
    return response.data;
  };

  fetchPermissions = async () => {
    const response = await this.instance.get('/permissions');
    return response.data;
  };

  removeRole = async (data: { roleId: number }) => {
    const response = await this.instance.delete(`/roles/${data.roleId}`);
    return response.data;
  };

  // ******************************** //
  // CHECKOUT APIS
  // ******************************** //
  createPaymentLink = async (data: void) => {
    const response = await this.instance.post('/payment-requests', data, {
      headers: { 'www-environment': await this.setEnvironmentHeader() }
    });
    return response.data;
  };

  getAllPaymentLinks = async (page: string, limit: string, query: any, currency: string) => {
    const params = {
      page,
      limit,
      ...filterOutEmptyValues({
        'search[status][]': query,
        currency
      })
    };
    const response = await this.instance.get(`/payment-requests`, {
      params,
      headers: { 'www-environment': await this.setEnvironmentHeader() }
    });
    return response.data.data;
  };

  getSinglePaymentLink = async (slug: any) => {
    const response = await this.instance.get(`/payment-requests/${slug}/get`, {
      headers: { 'www-environment': await this.setEnvironmentHeader() }
    });
    return response.data.data;
  };

  updatePaymentLink = async (data: { status: string }, ref: any) => {
    const response = await this.instance.put(`/payment-requests/${ref}`, data, {
      headers: { 'www-environment': await this.setEnvironmentHeader() }
    });
    return response.data;
  };

  deletePaymentLink = async (ref: any) => {
    const response = await this.instance.delete(`/payment-requests/${ref}`, {
      headers: { 'www-environment': await this.setEnvironmentHeader() }
    });
    return response.data;
  };

  getLinkTransactions = async (id: any, page: string, limit: string) => {
    const response = await this.instance.get(`/payment-requests/${id}/transactions`, {
      headers: { 'www-environment': await this.setEnvironmentHeader() },
      params: { page, limit }
    });
    return response.data.data;
  };

  checkLink = async (value: void) => {
    const response = await this.instance.post(
      '/payment-requests/resolve-link-slug',
      {
        slug: value
      },
      {
        headers: { 'www-environment': await this.setEnvironmentHeader() }
      }
    );
    return response.data;
  };

  // ******************************** //
  // MERCHANT SERVICES APIS
  // ******************************** //
  getMerchantPaymentPreference = async () => {
    const response = await this.instance.get('/settings/bank-transfers', {
      headers: { 'www-environment': await this.setEnvironmentHeader() }
    });
    return response?.data?.data;
  };

  setMerchantPaymentPreference = async (data: void) => {
    const response = await this.instance.put('/settings/bank-transfers', data, {
      headers: { 'www-environment': await this.setEnvironmentHeader() }
    });
    return response.data;
  };

  resetMerchantPaymentPreference = async () => {
    const response = await this.instance.delete('/settings/bank-transfers/reset', {
      headers: { 'www-environment': await this.setEnvironmentHeader() }
    });
    return response.data;
  };

  getMerchantAppearanceSettings = async () => {
    const response = await this.instance.get('/settings/checkout/customization', {
      headers: { 'www-environment': await this.setEnvironmentHeader() }
    });
    return response?.data?.data;
  };

  setMerchantAppearanceSettings = async (data: void) => {
    const response = await this.instance.put('/settings/checkout/customization', data, {
      headers: { 'www-environment': await this.setEnvironmentHeader() }
    });
    return response.data;
  };

  createMerchant = async (data: any) => {
    const response = await this.instance.post(`/merchants`, data);
    return response.data;
  };

  updateMerchantDetails = async (data: void) => {
    const response = await this.instance.put('/merchants/business-profile', data);
    return response.data;
  };

  getMerchantStatus = async () => {
    const response = await this.instance.get('/merchants/verifications/status');
    return response.data.data;
  };

  getMerchantsSavedDocs = async () => {
    const response = await this.instance.get(`/merchants/profile/documents`);
    return response.data;
  };

  addMerchantDoc = async (data: any) => {
    const response = await this.instance.post(`/merchants/profile/documents`, data);
    return response.data;
  };

  fetchMerchantFees = async () => {
    const response = await this.instance.get(`/merchants/transaction-fees`, {
      headers: { 'www-environment': 'live' }
    });
    return response.data;
  };

  fetchMerchantVirtualAccount = async () => {
    const response = await this.instance.get(`/merchants/profile/bank-account`, {
      headers: { 'www-environment': await this.setEnvironmentHeader() }
    });
    return response.data.data;
  };

  fetchMerchantKYC = async () => {
    const response = await this.instance.get(`/merchants/info`);
    return response.data.data;
  };

  // ******************************** //
  // PROFILE APIS
  // ******************************** //

  fetchTokens = async () => {
    const response = await this.instance.get('/auth/auth-keys', {
      headers: { 'www-environment': await this.setEnvironmentHeader() }
    });
    return response.data;
  };

  refreshTokens = async (data: { password: string }) => {
    const response = await this.instance.post('/auth/api-keys/generate', data, {
      headers: { 'www-environment': await this.setEnvironmentHeader() }
    });
    return response.data;
  };

  generateEncryptionToken = async (data: { password: string }) => {
    const response = await this.instance.post('/auth/encryption-key/generate', data, {
      headers: { 'www-environment': await this.setEnvironmentHeader() }
    });
    return response.data;
  };

  deleteToken = async (id: any) => {
    const response = await this.instance.delete(`/api/profile/tokens/${id}`, {
      headers: { 'www-environment': await this.setEnvironmentHeader() }
    });
    return response.data;
  };

  // ******************************** //
  // BALANCES APIS
  // ******************************** //
  getBalances = async () => {
    const response = await this.instance.get(`merchants/profile/balances`, {
      headers: { 'www-environment': await this.setEnvironmentHeader() }
    });
    useStore.setState({
      merchantBalanceDetails: response.data.data
    });
    return response.data;
  };

  getBalanceHistory = async (currency: string, page: string | number, limit: string) => {
    const response = await this.instance.get(`/transactions/balances/${currency}/transactions`, {
      headers: { 'www-environment': await this.setEnvironmentHeader() },
      params: { page, limit }
    });
    return response.data;
  };

  exportBalanceHistory = async (currency: string, format: any, date_from: any, date_to: any, sortingParams: any) => {
    const response = await this.instance.get(`/transactions/balances/${currency}/transactions?fieldsToExport[]=${sortingParams}`, {
      headers: { 'www-environment': await this.setEnvironmentHeader() },
      params: { format, date_from, date_to },
      responseType: 'blob'
    });
    return response;
  };

  getRollingReserveHistory = async (currency: string, page: string | number, limit: string) => {
    const response = await this.instance.get(`/transactions/rolling-reserves/${currency}/transactions`, {
      headers: { 'www-environment': await this.setEnvironmentHeader() },
      params: { page, limit }
    });
    return response.data;
  };

  exportRollingReserveHistory = async (currency: string, format: any, dateFrom: any, dateTo: any) => {
    const response = await this.instance.get(`/transactions/rolling-reserves/${currency}/transactions`, {
      headers: { 'www-environment': await this.setEnvironmentHeader() },
      params: { format, dateFrom, dateTo },
      responseType: 'blob'
    });
    return response;
  };

  // ******************************** //
  // TRANSACTION APIS
  // ******************************** //

  getTransactions = async (
    kind: string,
    page: string,
    limit: string,
    sortingParams: { status: string[] | undefined },
    currency: string
  ) => {
    const response = await this.instance.get(`/transactions/${kind}`, {
      headers: { 'www-environment': await this.setEnvironmentHeader() },
      params: { page, limit, ...sortingParams, currency }
    });
    return response.data.data;
  };

  exportTransactions = async (
    kind: string,
    sortingParams: { status: string[] | undefined },
    format: any,
    currency: string,
    fieldsToExport: any
  ) => {
    const response = await this.instance.get(`/transactions/${kind}`, {
      params: { format, currency, ...sortingParams, 'fieldsToExport[]': fieldsToExport },
      headers: { 'www-environment': await this.setEnvironmentHeader() },
      responseType: 'blob'
    });
    return response;
  };

  getFileDownload = async (link: string) => {
    const response = await this.instance.get(`/exports/reports/${link}`, {
      headers: { 'www-environment': await this.setEnvironmentHeader() }
    });
    return response;
  };

  getSingleTransaction = async (kind: string, ref: string) => {
    const response = await this.instance.get(`/transactions/${kind}/${ref}`, {
      headers: { 'www-environment': await this.setEnvironmentHeader() }
    });
    return response.data.data;
  };

  calculateTransferFees = async (data: {
    type: string; // invoke the listener
    currency: any;
    channels: string[];
    amount: void;
  }) => {
    const response = await this.instance.post('/fees/calculate', data, {
      headers: { 'www-environment': await this.setEnvironmentHeader() }
    });
    return response.data.data;
  };

  initiateTransfer = async (data: void) => {
    const response = await this.instance.post('/transfers/initiate', data, {
      headers: { 'www-environment': await this.setEnvironmentHeader() }
    });
    return response.data.data;
  };

  resendOTPAction = async data => {
    const response = await this.instance.post('/auth/two-factor/otp/resend', data, {
      headers: { 'www-environment': await this.setEnvironmentHeader() }
    });
    return response.data;
  };

  processTransfer = async (data: void) => {
    const response = await this.instance.post('/transfers/process', data, {
      headers: { 'www-environment': await this.setEnvironmentHeader() }
    });
    return response.data.data;
  };

  confirmWalletBeneficiary = async (data: { email: void; currency: any }) => {
    const response = await this.instance.post('/transfers/beneficiary/resolve', data, {
      headers: { 'www-environment': await this.setEnvironmentHeader() }
    });
    return response.data.data;
  };

  getBulkPayouts = async (currency: string, page: string, limit: string, sortingParams: { status: string[] | undefined }) => {
    const response = await this.instance.get(`/transactions/bulk-transfers`, {
      headers: { 'www-environment': await this.setEnvironmentHeader() },
      params: { currency, page, limit, ...sortingParams }
    });
    return response.data.data;
  };

  getBulkPayoutTransactionList = async (bulkReference: string, page: number, limit: number, sortingParams: {}) => {
    const response = await this.instance.get(`/transactions/bulk/${bulkReference}/payouts`, {
      headers: { 'www-environment': await this.setEnvironmentHeader() },
      params: { page, limit, ...sortingParams }
    });
    return response.data.data;
  };

  getBulkPayoutSummary = async (bulkReference: string) => {
    const response = await this.instance.get(`/transactions/bulk/${bulkReference}`, {
      headers: { 'www-environment': await this.setEnvironmentHeader() }
    });
    return response.data.data;
  };

  discardBulkPayout = async (bulkReference: void) => {
    const response = await this.instance.patch(`/transactions/bulk/${bulkReference}/cancel`, {
      headers: { 'www-environment': await this.setEnvironmentHeader() }
    });
    return response.data.data;
  };

  startBulkPayout = async (bulkReference: string, data: { auth_data: { code: string; two_factor_type: string; identifier: string } }) => {
    const response = await this.instance.patch(`/transactions/bulk/${bulkReference}/start`, data, {
      headers: { 'www-environment': await this.setEnvironmentHeader() }
    });
    return response.data.data;
  };

  editBulkPayout = async (bulkReference: string, data: { currency: string; payouts: BulkDataT }) => {
    const response = await this.instance.patch(`/transactions/bulk/${bulkReference}`, data, {
      headers: { 'www-environment': await this.setEnvironmentHeader() }
    });
    return response.data.data;
  };

  initiateBulkPayout = async (bulkReference: string, data: { two_factor_type: string }) => {
    const response = await this.instance.patch(`/transactions/bulk/${bulkReference}/initiate`, data, {
      headers: { 'www-environment': await this.setEnvironmentHeader() }
    });
    return response.data.data;
  };

  exportBulkTransfers = async (format: string, sortingParams: {}, currency: string, fieldsToExport: string, batch_reference: any) => {
    const response = await this.instance.get(`/transactions/payouts?batchReference=${batch_reference}`, {
      params: { format, ...sortingParams, currency, 'fieldsToExport[]': fieldsToExport },
      headers: { 'www-environment': await this.setEnvironmentHeader() },
      responseType: 'blob'
    });
    return response;
  };

  submitBulkPayout = async (data: { type: any; currency: any; description: string; payouts: never[] }) => {
    const response = await this.instance.post('/transactions/bulk/upload', data, {
      headers: { 'www-environment': await this.setEnvironmentHeader() }
    });
    return response.data;
  };

  repeatBulkPayout = async (
    payload: {
      custom_description: string;
      option: string;
      type: string;
    },
    id: string
  ) => {
    const response = await this.instance.post(`/transactions/bulk/${id}/repeat`, payload, {
      headers: { 'www-environment': await this.setEnvironmentHeader() }
    });
    return response.data;
  };

  getRelatedTransactions = async (ref: string) => {
    const response = await this.instance.get(`/transactions/payments/${ref}?limit=3`, {
      headers: { 'www-environment': await this.setEnvironmentHeader() }
    });

    return response.data.data;
  };

  // ******************************** //
  // VIRTUAL BANK APIS
  // ******************************** //

  getAccessControl = async (currency: string) => {
    const response = await this.instance.get(`/settings/products/visibility?currency=${currency}`);
    return this.setMerchantAccess(response?.data);
  };

  getVirtualBankAccounts = async (page, limit, sortingParams) => {
    const response = await this.instance.get(`/virtual-bank-account`, {
      headers: { 'www-environment': await this.setEnvironmentHeader() },
      params: { page, limit, ...sortingParams }
    });
    return response.data.data;
  };

  getVirtualBankAccount = async (id: any) => {
    const response = await this.instance.get(`/virtual-bank-account/${id}`, {
      headers: { 'www-environment': await this.setEnvironmentHeader() }
    });
    return response.data;
  };

  getVirtualBankAccountTransactions = async (id, page = 1, limit = 25, sortingParams) => {
    const response = await this.instance.get(`/virtual-bank-account/${id}/transactions`, {
      headers: { 'www-environment': await this.setEnvironmentHeader() },
      params: { page, limit, ...sortingParams }
    });
    return response.data.data;
  };

  getAccountHoldersTransactions = async (id, page = 1, limit = 25, sortingParams) => {
    const response = await this.instance.get(`/virtual-bank-account/account-holders/${id}/transactions`, {
      headers: { 'www-environment': await this.setEnvironmentHeader() },
      params: { page, limit, ...sortingParams }
    });
    return response.data;
  };

  getAllVirtualBankAccountHolders = async (page = 1, limit = 25, sortingParams) => {
    const response = await this.instance.get(`/virtual-bank-account/account-holders`, {
      headers: { 'www-environment': await this.setEnvironmentHeader() },
      params: { page, limit, ...sortingParams }
    });
    return response.data.data;
  };

  getVirtualBankAccountHolder = async (accountReference, page = 1, limit = 25) => {
    const response = await this.instance.get(`/virtual-bank-account/account-holders/${accountReference}`, {
      headers: { 'www-environment': await this.setEnvironmentHeader() },
      params: { page, limit }
    });
    return response.data.data;
  };

  getVirtualBankAccountHolderEvents = async (accountReference, page = 1, limit = 25, dateFrom, dateTo) => {
    let params = {};
    if (dateFrom && dateTo) {
      params = { page, limit, date_from: dateFrom, date_to: dateTo };
    } else {
      params = { page, limit };
    }
    const response = await this.instance.get(`/virtual-bank-account/account-holders/${accountReference}/events`, {
      headers: { 'www-environment': await this.setEnvironmentHeader() },
      params
    });
    return response.data.data;
  };

  manageAccountHolder = async data => {
    const response = await this.instance.post(`/virtual-bank-account/account-holders/manage`, data, {
      headers: { 'www-environment': await this.setEnvironmentHeader() }
    });
    return response.data.data;
  };

  manageAccountNumber = async data => {
    const response = await this.instance.post(`/virtual-bank-account/manage`, data, {
      headers: { 'www-environment': await this.setEnvironmentHeader() }
    });
    return response.data.data;
  };

  upgradeVirtualAccount = async data => {
    const response = await this.instance.post(`/virtual-bank-account/upgrade`, data, {
      headers: { 'www-environment': await this.setEnvironmentHeader() }
    });
    return response.data.data;
  };

  upgradeVirtualAccountRequests = async (page = 1, limit = 25, sortingParams) => {
    const response = await this.instance.get(`/virtual-bank-account/upgrade-requests`, {
      headers: { 'www-environment': await this.setEnvironmentHeader() },
      params: { page, limit, ...sortingParams }
    });
    return response.data.data;
  };

  singleVirtualAccountUpgradeRequest = async reference => {
    const response = await this.instance.get(`/virtual-bank-account/upgrade-requests/${reference}`, {
      headers: { 'www-environment': await this.setEnvironmentHeader() }
    });
    return response.data.data;
  };

  getVirtualAccountKycDetails = async reference => {
    const response = await this.instance.get(`/virtual-bank-account/account-holders/${reference}/kyc-details`, {
      headers: { 'www-environment': await this.setEnvironmentHeader() }
    });
    return response.data.data;
  };

  // ******************************** //
  // ************** //
  // ANALYTICS APIS
  // ************** //
  // ******************************** //

  getPerformanceAnalytics = async (startDate: string, endDate: any, currency: undefined) => {
    const response = await this.instance.get(`/merchants/analytics/transactions/${currency}/count`, {
      params: { startDate, endDate }
    });
    return response.data.data;
  };

  getTransactionAnalytics = async (filter = null, currency: any) => {
    const response = await this.instance.get(`/merchants/analytics/transactions/graph/${currency}/${filter}`);
    return response.data;
  };

  // ******************************** //
  // SETTLEMENT APIS
  // ******************************** //

  getSettlementSummary = async (currency: string) => {
    const response = await this.instance.get('/settlements/summary', {
      headers: { 'www-environment': await this.setEnvironmentHeader() },
      params: { currency }
    });
    return response.data;
  };

  getSettlements = async (page: string, limit: string, status: string, sortingParams: Record<string, any>, currency: string) => {
    const response = await this.instance.get('/settlements', {
      headers: { 'www-environment': await this.setEnvironmentHeader() },
      params: { status, page, limit, ...sortingParams, currency }
    });
    return response.data.data;
  };

  getSettlement = async (settlementId: any) => {
    const response = await this.instance.get(`/settlements/${settlementId}`, {
      headers: { 'www-environment': await this.setEnvironmentHeader() }
    });
    return response.data;
  };

  getSettlementAccount = async () => {
    const response = await this.instance.get('/merchants/settlement-accounts');
    return response.data;
  };

  updateSettlementAccount = async (data: void) => {
    const response = await this.instance.put('/merchants/settlement-accounts', data);
    return response.data;
  };

  getSingleSettlementTransactions = async (settlementId: any, page = 1, limit = 25) => {
    const response = await this.instance.get(`/settlements/${settlementId}/transactions`, {
      headers: { 'www-environment': await this.setEnvironmentHeader() },
      params: { page, limit }
    });
    return response.data.data;
  };

  exportSettlements = async (status: string, format: any, sortingParams: Record<string, any>, currency: string, fieldsToExport: any) => {
    const response = await this.instance.get('/settlements', {
      headers: { 'www-environment': await this.setEnvironmentHeader() },
      params: { status, format, ...sortingParams, currency, 'fieldsToExport[]': fieldsToExport },
      responseType: 'blob'
    });
    return response;
  };

  exportSettlementTransactions = async (settlementId: any, format: any) => {
    const response = await this.instance.get(`/settlements/${settlementId}/transactions`, {
      headers: { 'www-environment': await this.setEnvironmentHeader() },
      params: { format },
      responseType: 'blob'
    });
    return response;
  };

  getSettlementConfig = async () => {
    const response = await this.instance.get(`/settings/settlement`, {
      headers: { 'www-environment': await this.setEnvironmentHeader() }
    });
    return response.data;
  };

  getCurrencyConversionsSettlementConfig = async (currency: string) => {
    const response = await this.instance.get(`/settings/settlement/conversions`, {
      headers: { 'www-environment': await this.setEnvironmentHeader() },
      params: { currency }
    });
    return response.data;
  };

  updateConversionsSettlementConfig = async (data: TConversionsPayload, currency: string) => {
    const response = await this.instance.patch(`/settings/settlement/conversions?currency=${currency}`, data, {
      headers: { 'www-environment': await this.setEnvironmentHeader() }
    });
    return response.data;
  };

  updateSettlementConfig = async (data: void) => {
    const response = await this.instance.put(`/settings/settlement`, data, {
      headers: { 'www-environment': await this.setEnvironmentHeader() }
    });
    return response.data;
  };

  // ******************************** //
  // WALLETS APIS
  // ******************************** //

  fetchWallets = async () => {
    const response = await this.instance.get('/merchants/profile/wallets', {
      headers: { 'www-environment': await this.setEnvironmentHeader() }
    });
    return response.data;
  };

  // ******************************** //
  // UTILS APIS
  // ******************************** //

  fetchBanks = async (code: undefined) => {
    const url = code ? `/misc/banks?countryCode=${code}` : `/misc/banks`;
    const response = await this.instance.get(url);
    return response.data;
  };

  fetchMobileMoneyOperators = async (code: string) => {
    const response = await this.instance.get(`/misc/mobile-money?countryCode=${code}`);
    return response.data;
  };

  fetchCountries = async (
    boolValue: boolean | undefined,
    { includeStates, forCardPayment }: { includeStates?: boolean; forCardPayment?: boolean } = {}
  ) => {
    const response = await this.instance.get(`/misc/countries`, {
      params: {
        forSignup: boolValue,
        includeStates,
        forCardPayment
      }
    });
    return response.data;
  };

  fetchCurrencies = async () => {
    const response = await this.instance.get('/misc/currencies');
    return response.data;
  };

  bankEnquiry = async (data: IBankNameEnquiry, useCurrentEnvironment?: boolean) => {
    const response = await this.instance.post('/misc/banks/resolve', data, {
      ...(useCurrentEnvironment && {
        headers: { 'www-environment': await this.setEnvironmentHeader() }
      })
    });
    return response.data;
  };

  uploadFiles = async (file: any) => {
    const response = await this.instance.post(`/files?field_name=files`, file);
    return response.data;
  };

  mobileNumberEnquiry = async (data: IMobileNameEnquiry) => {
    const response = await this.instance.post('/misc/mobile-money/resolve', data, {
      headers: { 'www-environment': await this.setEnvironmentHeader() }
    });
    return response.data;
  };

  getStates = async (countryCode: number) => {
    const response = await this.instance.get(`/misc/countries/${countryCode}/states`);
    return response.data;
  };

  downloadFile = async url => {
    const response = await this.instance.get(url, {
      responseType: 'blob'
    });
    return response;
  };

  // ******************************** //
  // AUDIT LOGS APIS
  // ******************************** //

  fetchUserLogs = async (page: number, limit: number, dateFrom: any, dateTo: undefined) => {
    let params = {};
    if (dateFrom && dateTo) {
      params = { page, limit, date_from: dateFrom, date_to: dateTo };
    } else {
      params = { page, limit };
    }
    const response = await this.instance.get('/merchants/profile/audit-logs', {
      params,
      headers: { 'www-environment': 'live' }
    });
    return response.data.data;
  };

  // ******************************** //
  // EXTERNAL APIS
  // ******************************** //

  contactSales = async (data: void) => {
    const response = await this.instance.post(`/contacts`, data);
    return response.data;
  };

  // ******************************** //
  // DEPOSIT REQUEST APIS
  // ******************************** //

  fetchAllDepositRequests = async (state: string | null, page: any, limit: any) => {
    if (state === null || state === 'All') {
      const response = await this.instance.get(`/deposit-requests?page=${page}&limit=${limit}`, {
        headers: { 'www-environment': await this.setEnvironmentHeader() }
      });
      return response.data;
    }

    if (state === 'Submitted') {
      const response = await this.instance.get(`/deposit-requests?state=submitted_approved&page=${page}&limit=${limit}`, {
        headers: { 'www-environment': await this.setEnvironmentHeader() }
      });
      return response.data;
    }

    const response = await this.instance.get(`/deposit-requests?state=${state.toLowerCase()}&page=${page}&limit=${limit}`, {
      headers: { 'www-environment': await this.setEnvironmentHeader() }
    });
    return response.data;
  };

  fetchADepositRequest = async (id: any) => {
    const response = await this.instance.get(`/deposit-requests/${id}`, {
      headers: { 'www-environment': await this.setEnvironmentHeader() }
    });
    return response.data;
  };

  createDepositRequest = async (data: any) => {
    const response = await this.instance.post('/deposit-requests', data, {
      headers: { 'www-environment': await this.setEnvironmentHeader() }
    });
    return response.data;
  };

  confirmDepositRequest = async (id: any) => {
    const response = await this.instance.post(`/deposit-requests/${id}/confirm`, {
      headers: { 'www-environment': await this.setEnvironmentHeader() }
    });
    return response.data;
  };

  // ******************************** //
  // MOBILE MONEY APIS
  // ******************************** //

  fetchMobileMoneyNetwork = async (code: any) => {
    const url = code ? `/misc//mobile-money?countryCode=${code}` : `/misc//mobile-money`;
    const response = await this.instance.get(url, {
      headers: { 'www-environment': await this.setEnvironmentHeader() }
    });
    return response.data;
  };

  networkOperatorAvailability = async data => {
    const response = await this.instance.post('/transactions/payouts/availability', data, {
      headers: { 'www-environment': await this.setEnvironmentHeader() }
    });
    return response.data;
  };

  // ******************************** //
  // INITIATE OTP API
  // ******************************** //

  initiateOTPToken = async data => {
    const response = await this.instance.post(`/auth/two-factor/initiate`, data, {
      headers: { 'www-environment': await this.setEnvironmentHeader() }
    });
    return response.data;
  };

  // ******************************** //
  // IP WHITELISTING APIS
  // ******************************** //

  fetchIPs = async () => {
    const url = `/auth/ip-addresses`;
    const response = await this.instance.get(url, {
      headers: { 'www-environment': await this.setEnvironmentHeader() }
    });
    return response.data;
  };

  enableDisableIP = async (data: any) => {
    const response = await this.instance.patch(`/auth/ip-addresses/status`, data, {
      headers: { 'www-environment': await this.setEnvironmentHeader() }
    });
    return response.data;
  };

  removeIP = async (data: any) => {
    const response = await this.instance.post(`/auth/ip-addresses/remove`, data, {
      headers: { 'www-environment': await this.setEnvironmentHeader() }
    });
    return response.data;
  };

  updateIP = async (data: any) => {
    const response = await this.instance.patch('/auth/ip-addresses', data, {
      headers: { 'www-environment': await this.setEnvironmentHeader() }
    });
    return response.data;
  };

  addIP = async (data: any) => {
    const response = await this.instance.post(`/auth/ip-addresses`, data, {
      headers: { 'www-environment': await this.setEnvironmentHeader() }
    });
    return response.data;
  };

  // ******************************** //
  // REFUND APIS
  // ******************************** //

  getRefunds = async (page: string, limit: string, sortingParams: { status: string[] }, currency: string) => {
    const response = await this.instance.get(`/refunds`, {
      headers: { 'www-environment': await this.setEnvironmentHeader() },
      params: { page, limit, ...sortingParams, currency }
    });

    return response.data.data;
  };

  exportRefunds = async (sortingParams: any, format: any, currency: any, fieldsToExport: any) => {
    const response = await this.instance.get('/refunds', {
      headers: { 'www-environment': await this.setEnvironmentHeader() },
      params: { format, ...sortingParams, currency, 'fieldsToExport[]': fieldsToExport },
      responseType: 'blob'
    });
    return response;
  };

  initiateRefund = async (ref: any, data: void) => {
    const response = await this.instance.post(`/refunds/${ref}/initiate`, data, {
      headers: { 'www-environment': await this.setEnvironmentHeader() }
    });
    return response.data.data;
  };

  getSingleRefund = async (ref: string) => {
    const response = await this.instance.get(`/refunds/${ref}`, {
      headers: { 'www-environment': await this.setEnvironmentHeader() }
    });
    return response.data.data;
  };

  validateTransaction = async (ref: void) => {
    const response = await this.instance.get(`/refunds/${ref}/validate`, {
      headers: { 'www-environment': await this.setEnvironmentHeader() }
    });
    return response.data.data;
  };

  getRefundSummary = async () => {
    const response = await this.instance.get(`/refunds/reports/summary`, {
      headers: { 'www-environment': await this.setEnvironmentHeader() }
    });
    return response.data.data;
  };

  // ******************************** //
  // CARD ISSUANCE APIS
  // ******************************** //

  requestCardAccess = async (payload, cardType = 'virtual') => {
    const response = await this.instance.post(`/access/card-issuance/${cardType}`, payload);
    return response;
  };

  fetchSubscriptionFees = async () => {
    const response = await this.instance.get('merchants/card-issuance/subscription/fees');
    return response?.data?.data;
  };

  validateBvn = async payload => {
    const response = await this.instance.post('/identity/bvn/validate', payload);
    return response?.data?.data;
  };

  fetchAccessRequestStatus = async (cardType = 'virtual') => {
    const response = await this.instance.get(`access/card-issuance/${cardType}/access-validation`);
    return response?.data?.data;
  };

  fetchIssuingBalanceHistory = async ({
    currency,
    page,
    limit,
    toExport,
    format,
    fieldsToExport,
    ...otherParams
  }: {
    currency: any;
    page?: string;
    limit?: string;
    toExport?: any;
    format?: any;
    fieldsToExport?: any;
  }) => {
    const response = await this.instance.get('/transactions/card-issuance/wallets/transactions', {
      headers: { 'www-environment': await this.setEnvironmentHeader() },
      params: { ...(toExport ? { format, fieldsToExport } : { page, limit }), currency, ...otherParams },
      ...(toExport && { responseType: 'blob' })
    });
    return toExport ? response : response?.data;
  };

  fetchIssuingBalance = async (currency: string) => {
    const response = await this.instance.get('/wallets/card-issuance/balances', {
      params: { currency },
      headers: { 'www-environment': await this.setEnvironmentHeader() }
    });
    return response.data?.data;
  };

  fetchFundingDeposits = async ({
    page,
    limit,
    toExport,
    id,
    format,
    fieldsToExport,
    ...otherParams
  }: {
    page?: string;
    limit?: string;
    toExport?: any;
    id?: string | number;
    format?: any;
    fieldsToExport?: any;
  }) => {
    const response = await this.instance.get('/transactions/card-issuance/wallets/deposit-requests', {
      headers: { 'www-environment': await this.setEnvironmentHeader() },
      params: { ...(toExport ? { format, fieldsToExport } : { page, limit, reference: id }), ...otherParams },
      ...(toExport && { responseType: 'blob' })
    });
    return toExport ? response : response?.data;
  };

  exportIssuingBalanceHistory = async ({ currency, format, fieldsToExport }) => {
    const response = await this.instance.get('/transactions/card-issuance/wallets/transactions', {
      params: { currency, format, fieldsToExport },
      headers: { 'www-environment': await this.setEnvironmentHeader() },
      responseType: 'blob'
    });
    return response;
  };

  fetchBillingStats = async ({ currency }: { currency: string }) => {
    const response = await this.instance.get('/billings/statistics', {
      headers: { 'www-environment': await this.setEnvironmentHeader() },
      params: { currency, cardType: 'virtual' }
    });
    return response.data;
  };

  fetchBillingHistory = async ({
    page,
    limit,
    currency,
    toExport,
    exportFormat,
    exportFields,
    format,
    fieldsToExport,
    ...otherParams
  }: {
    page?: string;
    limit?: string;
    currency?: any;
    toExport?: any;
    exportFormat?: any;
    exportFields?: any;
    format?: any;
    fieldsToExport?: any;
  }) => {
    const response = await this.instance.get('/billings', {
      headers: { 'www-environment': await this.setEnvironmentHeader() },
      params: { ...(toExport ? { exportFormat, exportFields } : { page, limit }), ...otherParams },
      ...(toExport && { responseType: 'blob' })
    });
    return toExport ? response : response?.data;
  };

  fetchBillingDetails = async (id: string) => {
    const response = await this.instance.get(`/billings/${id}`, {
      headers: { 'www-environment': await this.setEnvironmentHeader() }
    });
    return response.data.data;
  };

  fundCardIssuingWallet = async (payload: { amount: string; currency: string }) => {
    const response = await this.instance.post('/wallets/card-issuance/funding/initiate', payload, {
      headers: { 'www-environment': await this.setEnvironmentHeader() }
    });
    return response.data;
  };

  fetchIssuedCards = async ({
    cardCategory,
    page,
    limit,
    toExport,
    exportFormat,
    exportFields,
    ...filterParams
  }: {
    cardCategory: 'reserved' | 'customer';
    page?: any;
    limit?: any;
    toExport?: any;
    exportFormat?: any;
    exportFields?: any;
  }) => {
    const response = await this.instance.get('/cards', {
      headers: { 'www-environment': await this.setEnvironmentHeader() },
      params: { cardCategory, ...(toExport ? { exportFormat, exportFields } : { page, limit }), ...filterParams },
      ...(toExport && { responseType: 'blob' })
    });
    return toExport ? response : response?.data;
  };

  fetchIssuedCardsStats = async ({ cardCategory }) => {
    const response = await this.instance.get('/cards/statistics', {
      headers: { 'www-environment': await this.setEnvironmentHeader() },
      params: { cardCategory }
    });
    return response.data.data;
  };

  getSingleIssuedCard = async (ref: any) => {
    const response = await this.instance.get(`/cards/${ref}`, {
      headers: { 'www-environment': await this.setEnvironmentHeader() }
    });
    return response.data.data;
  };

  fetchIssuedCardTransactions = async (
    ref: any,
    {
      page,
      limit,
      toExport,
      exportFormat,
      exportFields,
      ...filterParams
    }: { limit?: string; page?: string; toExport?: boolean; exportFormat?: any; exportFields?: any }
  ) => {
    const response = await this.instance.get(`/cards/${ref}/transactions`, {
      headers: { 'www-environment': await this.setEnvironmentHeader() },
      params: { ...(toExport ? { exportFormat, exportFields } : { page, limit }), ...filterParams },
      ...(toExport && { responseType: 'blob' })
    });
    return toExport ? response : response?.data;
  };

  getSingleIssuedCardTransaction = async (ref: string) => {
    const response = await this.instance.get(`/transactions/card-issuance/${ref}`, {
      headers: { 'www-environment': await this.setEnvironmentHeader() }
    });
    return response.data.data;
  };

  fetchCardEvents = async (
    ref: any,
    {
      page,
      limit,
      toExport,
      exportFormat,
      exportFields,
      ...filterParams
    }: { limit?: string; page?: string; toExport?: boolean; exportFormat?: any; exportFields?: any }
  ) => {
    const response = await this.instance.get(`/cards/${ref}/events`, {
      headers: { 'www-environment': await this.setEnvironmentHeader() },
      params: { ...(toExport ? { exportFormat, exportFields } : { page, limit }), ...filterParams },
      ...(toExport && { responseType: 'blob' })
    });
    return toExport ? response : response?.data;
  };

  fetchReservedCardholderName = async () => {
    const response = await this.instance.get(`/cardholders/reserved`);
    return response?.data?.data;
  };

  initiateCardFunding = async ({ amount, description, cardRef }: { amount: string; description: string; cardRef: string }) => {
    const response = await this.instance.post(`cards/${cardRef}/funding/initiate`, {
      amount,
      description
    });
    return response?.data;
  };

  authorizeCardFunding = async ({ auth, cardRef }) => {
    const response = await this.instance.post(`cards/funding/authorize`, {
      reference: cardRef,
      auth
    });
    return response?.data;
  };

  authorizeCardIssuingFunding = async ({ auth, reference }) => {
    const response = await this.instance.post(`wallets/card-issuance/funding/authorize`, {
      reference,
      auth
    });
    return response?.data;
  };

  /** Activate or deactivate card */
  updateCardStatus = async ({
    cardRef,
    action,
    reason,
    initiator,
    description
  }: {
    cardRef: string;
    action: string;
    reason: string;
    initiator: string;
    description: string;
  }) => {
    const response = await this.instance.patch(
      `cards/${cardRef}/status`,
      { action, reason, initiator, description },
      { headers: { 'www-environment': await this.setEnvironmentHeader() } }
    );
    return response.data.data;
  };

  terminateCard = async ({
    cardRef,
    reason,
    initiator,
    description
  }: {
    cardRef: string;
    reason: string;
    initiator: string;
    description: string;
  }) => {
    const response = await this.instance.patch(
      `cards/${cardRef}/terminate`,
      { reason, initiator, description },
      { headers: { 'www-environment': await this.setEnvironmentHeader() } }
    );
    return response.data.data;
  };

  fetchIssuanceTxStatistics = async () => {
    const response = await this.instance.get('/transactions/card-issuance/statistics', {
      headers: { 'www-environment': await this.setEnvironmentHeader() }
    });
    return response.data.data;
  };

  fetchAllIssuanceTransactions = async ({
    page,
    limit,
    toExport,
    exportFormat,
    exportFields,
    ...filterParams
  }: {
    page?: any;
    limit?: any;
    toExport?: any;
    exportFields?: any;
    exportFormat?: any;
  }) => {
    const response = await this.instance.get('/transactions/card-issuance', {
      headers: { 'www-environment': await this.setEnvironmentHeader() },
      params: { ...(toExport ? { exportFormat, exportFields } : { page, limit }), ...filterParams },
      ...(toExport && { responseType: 'blob' })
    });
    return toExport ? response : response?.data;
  };

  initiateReservedCardCreation = async body => {
    const response = await this.instance.post('/cards/reserved/initiate', body);
    return response.data;
  };

  authorizeReservedCardCreation = async body => {
    const response = await this.instance.post('/cards/reserved/authorize', body);
    return response.data;
  };

  fetchAllChargebacksForIssuedCard = async ({
    cardId,
    page,
    limit,
    toExport,
    exportFormat,
    exportFields,
    ...filterParams
  }: {
    cardId: string;
    page: string;
    limit: string;
    exportFormat?: string;
    exportFields?: string;
    [x: string]: string;
  }) => {
    const response = await this.instance.get('/chargebacks/card-issuance', {
      headers: { 'www-environment': await this.setEnvironmentHeader() },
      params: { cardId, ...(toExport ? { exportFormat, exportFields } : { page, limit }), ...filterParams },
      ...(toExport && { responseType: 'blob' })
    });
    return toExport ? response : response.data;
  };

  fetchAllIssuedCardChargebacks = async ({
    page,
    limit,
    toExport,
    exportFormat,
    exportFields,
    ...filterParams
  }: {
    page?: any;
    limit?: any;
    toExport?: any;
    exportFormat?: any;
    exportFields?: any;
  }) => {
    const response = await this.instance.get('/chargebacks/card-issuance', {
      headers: { 'www-environment': await this.setEnvironmentHeader() },
      params: { ...(toExport ? { exportFormat, exportFields } : { page, limit }), ...filterParams },
      ...(toExport && { responseType: 'blob' })
    });
    return toExport ? response : response.data;
  };

  fetchSingleIssuedCardChargeback = async (ref: string) => {
    const response = await this.instance.get(`/chargebacks/card-issuance/${ref}`);
    return response.data.data;
  };

  escalateIssuedCardChargeback = async (payload: {
    amount: string;
    category?: ChargebackCategoryType | undefined;
    description?: string | undefined;
    reason?: string | undefined;
    evidence: string;
    ref?: string | undefined;
    transaction_reference?: string | undefined;
  }) => {
    const response = await this.instance.post('/chargebacks/card-issuance', payload, {
      headers: { 'www-environment': await this.setEnvironmentHeader() }
    });
    return response;
  };

  reescalateIssuedCardChargeback = async (
    ref: string,
    payload: Required<{
      amount: string;
      category?: ChargebackCategoryType | undefined;
      description?: string | undefined;
      reason?: string | undefined;
      evidence: string;
      ref?: string | undefined;
      transaction_reference?: string | undefined;
    }>
  ) => {
    const response = await this.instance.patch(`/chargebacks/card-issuance/${ref}/re-escalate`, payload);

    return response.data;
  };

  // ******************************** //
  // CHARGEBACKS APIS
  // ******************************** //

  getChargeBacks = async (page: string, limit: string, sortingParams: { status: string[] }, currency: string) => {
    const response = await this.instance.get(`/chargebacks`, {
      headers: { 'www-environment': await this.setEnvironmentHeader() },
      params: { page, limit, ...sortingParams, currency }
    });

    return response.data.data;
  };

  getChargeBackSummary = async () => {
    const response = await this.instance.get(`/chargebacks/summary`, {
      headers: { 'www-environment': await this.setEnvironmentHeader() }
    });
    return response.data.data;
  };

  getSingleChargeBack = async (ref: string) => {
    const response = await this.instance.get(`/chargebacks/${ref}`, {
      headers: { 'www-environment': await this.setEnvironmentHeader() }
    });
    return response.data.data;
  };

  updateChargeBack = async (ref: any, data: void) => {
    const response = await this.instance.patch(`/chargebacks/${ref}`, data, {
      headers: { 'www-environment': await this.setEnvironmentHeader() }
    });
    return response.data.data;
  };

  exportChargeBacks = async (sortingParams: any, format: any, currency: any, fieldsToExport: any) => {
    const response = await this.instance.get('/chargebacks', {
      headers: { 'www-environment': await this.setEnvironmentHeader() },
      params: { format, ...sortingParams, currency, 'fieldsToExport[]': fieldsToExport },
      responseType: 'blob'
    });
    return response;
  };

  exportAllIssuanceTransactions = async ({ format, fieldsToExport }) => {
    const response = await this.instance.get('/transactions/card-issuance', {
      params: { format, fieldsToExport }
    });
    return response;
  };

  // ******************************** //
  // SWAP WALLET APIS (CONVERSIONS)
  // ******************************** //
  initiateConversion = async (data: { amount: string; from_currency: string; to_currency: string }) => {
    const response = await this.instance.post('/conversions/rates', data, {
      headers: { 'www-environment': await this.setEnvironmentHeader() }
    });
    return response.data;
  };

  processConversion = async (payload: IConversionsQueryData) => {
    const response = await this.instance.post('/conversions', payload, {
      headers: { 'www-environment': await this.setEnvironmentHeader() }
    });
    return response.data;
  };

  getConversions = async (page: any, limit: any, sortingParams: {}) => {
    const response = await this.instance.get(`/conversions`, {
      headers: { 'www-environment': await this.setEnvironmentHeader() },
      params: { page, limit, ...sortingParams }
    });
    return response?.data;
  };

  getSingleConversions = async (id: string) => {
    const response = await this.instance.get(`/conversions/${id}`, {
      headers: { 'www-environment': await this.setEnvironmentHeader() }
    });
    return response?.data?.data?.data;
  };

  exportConversions = async (format: any, sortingParams: Record<string, any>, fieldsToExport: any) => {
    const response = await this.instance.get('/conversions', {
      headers: { 'www-environment': await this.setEnvironmentHeader() },
      params: { format, ...sortingParams, 'fieldsToExport[]': fieldsToExport },
      responseType: 'blob'
    });
    return response;
  };

  // ******************************** //
  // EMAIL CONFIGURATION APIS
  // ******************************** //

  getConfigurations = async () => {
    const response = await this.instance.get(`/notification-configurations`, {
      headers: { 'www-environment': await this.setEnvironmentHeader() }
    });
    return response.data.data;
  };

  updateConfigurations = async (ref: any, data: any) => {
    const response = await this.instance.put(`/notification-configurations/${ref}`, data, {
      headers: { 'www-environment': await this.setEnvironmentHeader() }
    });
    return response.data.data;
  };

  setPayoutEmailConfig = async (data: void) => {
    const response = await this.instance.put('/notification-configurations/payout', data, {
      headers: { 'www-environment': await this.setEnvironmentHeader() }
    });
    return response.data;
  };

  setPayinEmailConfig = async (data: void) => {
    const response = await this.instance.put('/notification-configurations/payin', data, {
      headers: { 'www-environment': await this.setEnvironmentHeader() }
    });
    return response.data;
  };

  setDisputeEmailConfig = async (data: void) => {
    const response = await this.instance.put('/notification-configurations/dispute', data, {
      headers: { 'www-environment': await this.setEnvironmentHeader() }
    });
    return response.data;
  };

  setMarketingEmailConfig = async (data: void) => {
    const response = await this.instance.put('/notification-configurations/marketing', data, {
      headers: { 'www-environment': await this.setEnvironmentHeader() }
    });
    return response.data;
  };

  setCardIssuanceEmailConfig = async (data: void) => {
    const response = await this.instance.put('/notification-configurations/card-issuance', data, {
      headers: { 'www-environment': await this.setEnvironmentHeader() }
    });
    return response.data;
  };

  setSupportEmailConfig = async (data: void) => {
    const response = await this.instance.put('/notification-configurations/support-email', data, {
      headers: { 'www-environment': await this.setEnvironmentHeader() }
    });
    return response.data;
  };

  setSubscribeEmailConfig = async (data: ISubscriptionVariable) => {
    const response = await this.instance.put('/transactions/report/configuration', data, {
      headers: { 'www-environment': await this.setEnvironmentHeader() }
    });
    return response.data;
  };

  getSubscribeEmailConfig = async () => {
    const response = await this.instance.get(`/transactions/report/configuration`, {
      headers: { 'www-environment': await this.setEnvironmentHeader() }
    });
    return response.data.data as IEmailSubscriptionResponse;
  };

  // ******************************** //
  // WEBHOOK APIS
  // ******************************** //

  getWebhookNotification = async (ref: string) => {
    const response = await this.instance.get(`/webhooks/notifications/${ref}`, {
      headers: { 'www-environment': await this.setEnvironmentHeader() }
    });
    return response.data.data;
  };

  resendWebhookNotification = async data => {
    const response = await this.instance.post(`/webhooks/notifications/initiate`, data);
    return response.data;
  };

  // ******************************** //
  // IDENTITY APIS
  // ******************************** //

  getAllVerifications = async ({
    page,
    limit,
    toExport,
    exportFormat,
    exportFields,
    ...filterParams
  }: {
    page: any;
    limit: any;
    toExport?: any;
    exportFields?: any;
    exportFormat?: any;
  }) => {
    const response = await this.instance.get('/identities/verifications', {
      headers: { 'www-environment': await this.setEnvironmentHeader() },
      params: { ...(toExport ? { exportFormat, exportFields } : { page, limit }), ...filterParams },
      ...(toExport && { responseType: 'blob' })
    });
    return toExport ? response : response?.data;
  };

  getSingleVerification = async (id: string) => {
    const response = await this.instance.get(`/identities/verifications/${id}`, {
      headers: { 'www-environment': await this.setEnvironmentHeader() }
    });
    return response?.data?.data;
  };

  getIdentityServiceAccess = async () => {
    const response = await this.instance.get(`/identities/access`, {
      headers: { 'www-environment': await this.setEnvironmentHeader() }
    });
    const identityServiceAccess = response.data.data;

    if (identityServiceAccess) {
      this.store.setState({ identityServiceAccess });
    }
  };

  getVerificationBillingHistory = async ({
    page,
    limit,
    toExport,
    exportFormat,
    exportFields,
    ...filterParams
  }: {
    page: any;
    limit: any;
    toExport?: any;
    exportFields?: any;
    exportFormat?: any;
  }) => {
    const response = await this.instance.get('/identities/billings', {
      headers: { 'www-environment': await this.setEnvironmentHeader() },
      params: { ...(toExport ? { exportFormat, exportFields } : { page, limit }), ...filterParams },
      ...(toExport && { responseType: 'blob' })
    });
    return toExport ? response : response?.data;
  };

  getIdentityAccessConfig = async () => {
    const response = await this.instance.get(`/identities/access/requests/info`, {
      headers: { 'www-environment': await this.setEnvironmentHeader() }
    });
    return response.data.data;
  };

  requestIdentityAccess = async (data: IdReqPayloadType) => {
    const response = await this.instance.post(`/identities/access`, data, {
      headers: { 'www-environment': await this.setEnvironmentHeader() }
    });
    return response.data.data;
  };
}
