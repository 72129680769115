import Bugsnag from '@bugsnag/js';

export { default as history } from './history';
export { default as helmetApp } from './helmet-config';
export { default as Logger } from './logger';
export { default as actions } from './mixpanel';
export { default as queryClient } from './queryClient';
export { default as identifyClarityUser } from './clarity';
export * from './formats';
export * from './utilFunctions';
export * from './inputValidationRules';
export { default as formatStatus } from './format-status';
export { default as queryParams } from './queryParams';
export * from './constants';
export * from './conversions-helper';

export const logError = error => {
  if (error?.response) return Bugsnag.notify(error);
  return Bugsnag.notify(new Error(error));
};

export const logBreadCrumb = ({ event, data }) => {
  const metadata = data || {};
  Bugsnag.leaveBreadcrumb(event, metadata, 'user');
};
