import { useState } from 'react';
import { useMutation } from 'react-query';
import { AxiosError } from 'axios';

import APIRequest from '+services/api-services';
import { TwoFactorAuthType } from '+types';
import { logError } from '+utils';

import useFeedbackHandler from './feedbackHandler';

const apiRequest = new APIRequest(process.env.REACT_APP_MERCHANT_MIDDLEWARE_API_BASE);

type StateType = {
  code: string;
  identifier: string;
  two_factor_type: TwoFactorAuthType;
};

type ErrorType = AxiosError<{ message: string }>;

interface IUseOTPAuth {
  feedbackTimeout?: number;
}

const useOTPAuth = ({ feedbackTimeout = 5000 }: IUseOTPAuth = {}) => {
  const { feedbackInit, closeFeedback } = useFeedbackHandler();
  const [countdownIsCompleted, setCountdownIsCompleted] = useState(false);
  const [authState, setAuthState] = useState<StateType>({
    code: '',
    identifier: '',
    two_factor_type: 'otp'
  });

  const { mutateAsync: mutateOTPResend } = useMutation(apiRequest.resendOTPAction, {
    onError: (error: ErrorType) => {
      logError(error);
      const message = error.response?.data.message || 'We are sorry, something went wrong. Please try again.';
      feedbackInit({
        message,
        type: 'danger',
        componentLevel: true
      });
      if (feedbackTimeout) setTimeout(() => closeFeedback(), feedbackTimeout);
    }
  });

  const updateAuthState = (arg: Partial<StateType>) => {
    setAuthState(prev => ({ ...prev, ...arg }));
  };

  const resendOTP = async () => {
    await mutateOTPResend({ identifier: authState.identifier });
  };

  const updateCountdownStatus = (arg: boolean) => {
    setCountdownIsCompleted(arg);
  };

  return {
    authState,
    countdownIsCompleted,
    resendOTP,
    updateAuthState,
    updateCountdownStatus
  };
};

export default useOTPAuth;
