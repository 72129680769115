
import { CurrencyData } from '+types';
import { UnitedKingdomBanks, UnitedStateBanks } from './ListofForeignBanks';

export const foreignCurrencies = ['USD', 'GBP'];

export const data: CurrencyData[] = [
  {
    currency: 'USD',
    paymentMethods: [
      { type: 'aba_routing', label: 'AbaRouting' },
      { type: 'bic_swift', label: 'BicSwift' },
      { type: 'sort_code', label: 'SortCode' }
    ]
  },
  {
    currency: 'GBP',
    paymentMethods: [
      { type: 'sort_code', label: 'SortCode' }
    ]
  }
];

export const buttonType = [
  {
    label: 'Local Transfer',
    value: 'local'
  },
  {
    label: 'International Transfer',
    value: 'international'
  }
];

export const initialValues = {
  first_name: '',
  last_name: '',
  account_number: '',
  payment_method: '',
  swift_code: '',
  routing_number: '',
  country: '',
  recipient_address: '',
  city: '',
  state: '',
  zip_code: '',
  sender_email: '',
  account_name: '',
  bank_name: ''
};

export const defaultForeignCountriesMap: { [key: string]: string } = {
  USD: 'US',
  GBP: 'GB'
};

export const bankListMapping: { [key: string]: string[] } = {
  USD: UnitedStateBanks,
  GBP: UnitedKingdomBanks,
};
