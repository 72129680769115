import { useState } from 'react';
import { useQuery } from 'react-query';

import Table from '+containers/Dashboard/Shared/Table';
import { useFeedbackHandler, useSearchQuery } from '+hooks';
import useStore from '+store';
import { logError } from '+utils';

import AccessRequestPostOnboarding from '../components/AccessRequestPostOnboarding';
import Filter from '../components/Filter/Filter';
import Summary from '../components/Summary';
import { computeTableTitle, customerCardsTableProps, getCustomerCardsFilterProps } from '../constants';
import { publicApi } from '../constants/apis';
import exportActionOptions from '../constants/exportActionOptions';
import { useFetchIssuanceTables, useFetchIssuingExport } from '../hooks';
import { AllCardsResponseType, IError, IIssuingStoreState } from '../types';
import { getIssuingPermissions } from '../utils';
import CustomerCardsRow from './components/CustomerCardsRow';

const CustomerCardsIndex = () => {
  const { merchantEnv: MERCHANT_ENV } = useStore.getState() as IIssuingStoreState;
  const [recordIsFiltered, setRecordIsFiltered] = useState(false);
  const { value: searchQueryValue, setQuery } = useSearchQuery();
  const { feedbackInit } = useFeedbackHandler();
  const { canViewCardList } = getIssuingPermissions();
  const limit = searchQueryValue?.limit || '10';
  const page = searchQueryValue?.page || '1';
  const tab = searchQueryValue?.tab as 'customer_cards';
  const exportAction = useFetchIssuingExport({ resourceType: `All-${tab}`, exportFn: exportActionOptions[tab] });
  const filterProps = getCustomerCardsFilterProps();

  const { tableData, isFetching: isFetchingTableData, refetch: refetchTableData } = useFetchIssuanceTables({
    page,
    limit,
    tab,
    queryValue: searchQueryValue
  });

  const { data: customerCardStats, refetch: refetchCustomerCardsStats } = useQuery(
    ['CUSTOMER_CARD_STATS', MERCHANT_ENV],
    () => publicApi.fetchIssuedCardsStats({ cardCategory: 'customer' }),
    {
      onError: (e: IError) => {
        logError(e);
        const message = e.response?.data?.message;
        feedbackInit({
          message,
          type: 'danger',
          action: {
            action: refetchCustomerCardsStats,
            name: 'Try again'
          }
        });
      }
    }
  );

  const paging = tableData?.data?.paging;

  return (
    <div>
      <div className="d-flex w-100 flex-wrap flex-sm-nowrap">
        <div className="flex-grow-1 info-summary-container">
          <Summary label="Total Cards Issued" value={customerCardStats?.total_cards} description="All cards issued to customers" />
          <Summary
            label="Active Cards"
            value={customerCardStats?.total_active_cards}
            description="Cards being used to perform transactions"
          />
        </div>
      </div>

      <Filter
        totalItems={paging?.total_items as number}
        title={computeTableTitle({
          singularTitle: 'card',
          pluralTitle: 'cards',
          filtered: recordIsFiltered,
          activeTab: tab as string,
          pageItemCount: paging?.total_items as number
        })}
        actions={filterProps.actions}
        filterModalHeading={filterProps.filterModalHeading}
        filterModalDescription={filterProps.filterModalDescription}
        filterFields={filterProps.filterFields}
        exportType={filterProps.exportType}
        quickFilterType={tab}
        exportHeading={filterProps.exportHeading}
        exportDescription={filterProps.exportHeading}
        onChangeIsFiltered={setRecordIsFiltered}
        isFiltered={recordIsFiltered}
        exportAction={exportAction}
      />

      <section className="transaction_table_comp table-container">
        <Table
          tableClassName={`--history-table ${customerCardsTableProps.tableClassName}`}
          headings={customerCardsTableProps.headings}
          hasPagination
          borderedTable
          loading={isFetchingTableData}
          current={paging?.current}
          limitAction={value => setQuery({ limit: String(value) })}
          pageSize={paging?.page_size}
          actionFn={value => setQuery({ page: String(value) })}
          totalItems={paging?.total_items || 0}
          emptyStateHeading={customerCardsTableProps.emptyStateHeading}
          emptyStateMessage={
            <>
              <span>{customerCardsTableProps.emptyStateMessage}</span>
              {canViewCardList ? (
                <button type="button" className="refetch-button" onClick={() => refetchTableData()}>
                  <i className="os-icon os-icon-rotate-ccw" />
                  Refresh
                </button>
              ) : null}
            </>
          }
        >
          <CustomerCardsRow rowData={tableData?.data?.data as Array<AllCardsResponseType>} />
        </Table>
      </section>
    </div>
  );
};

const CustomerCards = () => {
  const { cardIssuanceCardAccess } = useStore.getState() as IIssuingStoreState;

  if (cardIssuanceCardAccess?.access?.customer?.status !== 'active') {
    return <AccessRequestPostOnboarding activeTab="customer_cards" accessStatus={cardIssuanceCardAccess?.access?.customer?.status} />;
  }
  return <CustomerCardsIndex />;
};
export default CustomerCards;
