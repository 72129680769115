import Copyable from '+dashboard/Shared/Copyable';

import { ICardThumbnail } from '../types';
import { cardColorOptions } from './cardCreationModalData';

import mcLogo from '+assets/img/dashboard/mastercard.svg';
import visaLogo from '+assets/img/dashboard/visa.svg';

import './index.scss';

const getCardColorProp = (color: string) => {
  const targetObj = cardColorOptions.find(({ label }) => label === color) || cardColorOptions[2];
  return targetObj;
};

export default function CardThumbnail({ cardDetails, color, applyOverlay = false, onOverlayClick }: ICardThumbnail) {
  const { status = '', scheme = '', holderName = '', expiresAt = '', lastFourDigits = '' } = cardDetails || {};

  const schemeLogoOptions: Record<string, string> = {
    mastercard: mcLogo,
    visa: visaLogo
  };

  const targetColorProps = getCardColorProp(color as string);

  return (
    <div className="card-thumbnail__comp">
      <div className="card-wrapper" style={{ backgroundImage: `linear-gradient(${targetColorProps.value})` }}>
        <div className="top">
          <div className={`status-indicator lg ${status} capitalize status`}>{status}</div>
          <div className="scheme-logo">
            <img alt={scheme} src={schemeLogoOptions[scheme]} />
          </div>
        </div>
        <div className="middle">
          <span className="hidden-char">••••</span>
          <span className="hidden-char">••••</span>
          <span className="hidden-char">••••</span>
          <span className="digits">
            {applyOverlay ? lastFourDigits : <Copyable text={lastFourDigits} buttonClassName="copy-button-payref" />}
          </span>
        </div>
        <div className="bottom">
          <div className="card-info-unit">
            <p className="label">expires</p>
            <p className="value">{expiresAt}</p>
          </div>
          <div className="card-info-unit">
            <p className="label">Cardholder</p>
            <p className="value">{holderName}</p>
          </div>
        </div>
      </div>
      {applyOverlay && (
        <button tabIndex={0} type="button" className="click-overlay" onClick={onOverlayClick}>
          Click to view details
        </button>
      )}
    </div>
  );
}
