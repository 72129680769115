import React, { useState } from 'react';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';

import Copyable from '+containers/Dashboard/Shared/Copyable';
import LoadingPlaceholder from '+containers/Dashboard/Shared/LoadingPlaceholder';
import Table from '+containers/Dashboard/Shared/Table';
import { useSearchQuery } from '+hooks';
import useFeedbackHandler from '+hooks/feedbackHandler';
import useClickOutside from '+hooks/useClickOutside';
import APIRequest from '+services/api-services';
import {
  capitalize,
  capitalizeRemovedash,
  filteredOutObjectProperty,
  formatAmount,
  getDate,
  getTime,
  history,
  logBreadCrumb,
  logError,
  switchStatus
} from '+utils';
import { breadCrumbEvents } from '+utils/bugsnag-events';

import SecondaryDetails from '../../Shared/SecondaryDetails';
import VirtualAccountsFilter from '../Shared/VirtualAccountsFilter';
import ManageAccountsNumberModal from '../VirtualAccountsModal/ManageAccountNumbers';

import DropdownIcon from '+assets/img/dashboard/dropdownIcon.svg';
import InfoIcon from '+assets/img/dashboard/exclamation.svg';
import SettilngsIcon from '+assets/img/dashboard/settings.svg';
import Tier2Icon from '+assets/img/dashboard/TierIcon.svg';
import VBAIcon from '+assets/img/dashboard/vba-icon.svg';
import Tier1Icon from '+assets/img/dashboard/vbaicontier1.svg';
import Warning from '+assets/img/dashboard/warning.svg';
import BankLogo from '+assets/img/dashboard/banklogo.svg';

const api = new APIRequest(process.env.REACT_APP_PUBLIC_MERCHANT_MIDDLEWARE_API_BASE);

export default function VirtualBankAccountDetails() {
  const { feedbackInit } = useFeedbackHandler();
  const searchQuery = useSearchQuery();
  const paginationPage = searchQuery.value.page || '1';
  const limit = searchQuery.value.limit || '25';
  const status = searchQuery.value.status || '';
  const sortingParams = {
    ...filteredOutObjectProperty(searchQuery.value, ['page', 'tab', 'limit', 'kycinfoTab', 'eventTab', 'accountHolderTab', 'status'])
  };
  const { id } = useParams();
  const [state, setState] = useState({ modalVisibility: false, type: '', action: '', openDropdown: false });
  const menuRef = useClickOutside(() => {
    if (state.openDropdown) {
      setState(prev => {
        return { ...prev, openDropdown: false };
      });
    }
  }, true);

  const { data: detailsData, isLoading: isFetchingDetails } = useQuery(
    `${id?.toUpperCase()}_DETAILS`,
    () => api.getVirtualBankAccount(id),
    {
      onError: error => {
        logError(error);
        feedbackInit({
          message: "There has been an error this account's information",
          type: 'danger'
        });
      }
    }
  );

  const { data: vbaTrxns, isFetching } = useQuery(
    [`${id?.toUpperCase()}_TRANSACTIONS`, paginationPage, limit, sortingParams, status],
    () =>
      api.getVirtualBankAccountTransactions(id, paginationPage, limit, {
        ...sortingParams,
        ...(['success', 'failed', 'processing', 'flagged'].includes(status) && { status: [status] })
      }),
    {
      keepPreviousData: true,
      onError: error => {
        logError(error);
        feedbackInit({
          message: "There has been an error getting this account's transactions",
          type: 'danger'
        });
      }
    }
  );

  const paging = vbaTrxns?.paging || {};
  const transactions = vbaTrxns?.data || [];
  const data = detailsData?.data || {};

  const manageMenuOptions = [
    ...(data?.account_status === 'suspended'
      ? [{ text: 'Request Reactivation', type: 'reactivate' }]
      : [
          ...((data?.tier !== 1 || data?.pending_upgrade_request) ? [] :  [{ text: 'Upgrade Tier', type: 'default' }]),
          { text: 'Suspend Account Number', type: 'suspend' },
          { text: 'Deactivate Account Number', type: 'deactivate' }
        ])
  ];

  const vbaDetails = [
    {
      'Account Number': data?.account_number ? (
        <Copyable text={data?.account_number} copyText="Copied" showCopyText={false} />
      ) : (
        'Not Available'
      ),
      'Account Name': data.account_name || 'Not Available',
      'Bank Name': `${capitalizeRemovedash(data?.bank_name || 'Not Available')}`,
      ...(data?.iban && { IBAN: data?.iban }),
      ...(data?.currency === 'USD' && {
        'Fedwire Routing Code': data?.payment_schemes?.fedwire?.routing_code ? (
          <Copyable text={data?.payment_schemes?.fedwire?.routing_code} textModifier={x => `${x?.slice(0, 4)}***`} />
        ) : (
          'Not Available'
        ),
        'Fedwire Memo': data?.payment_schemes?.fedwire?.memo ? (
          <Copyable text={data?.payment_schemes?.fedwire?.memo} textModifier={x => `${x?.slice(0, 4)}***`} />
        ) : (
          'Not Available'
        ),
        'Bank Address': data?.payment_schemes?.fedwire?.bank_name || 'Not Available'
      }),
      'Account Status': (
        <>
          <span className={`status-pill smaller ${switchStatus(data.account_status)}`} />
          {capitalize(data.account_status)}
        </>
      ),
      Email: data?.email || 'Not Available',
      ...(['GBP', 'EUR', 'USD'].includes(data?.currency) && {
        'Account Type': data?.account_type || 'Not Available',
        'Account Tier': `Tier ${data?.tier}` || 'Not Available'
      }),
      Currency: data?.currency || 'Not Available'
    },
    {
      ...(data?.currency === 'USD' && {
        'ACH Routing Code': data?.payment_schemes?.ach?.routing_code ? (
          <Copyable text={data?.payment_schemes?.ach?.routing_code} textModifier={x => `${x?.slice(0, 4)}***`} />
        ) : (
          'Not Available'
        ),
        'ACH Memo': data?.payment_schemes?.ach?.memo ? (
          <Copyable text={data?.payment_schemes?.ach?.memo} textModifier={x => `${x?.slice(0, 4)}***`} />
        ) : (
          'Not Available'
        ),
        'Swift Code': data?.payment_schemes?.swift?.swift_code ? (
          <Copyable text={data?.payment_schemes?.swift?.swift_code} textModifier={x => `${x?.slice(0, 4)}***`} />
        ) : (
          'Not Available'
        ),
        'Swift Memo': data?.payment_schemes?.swift?.memo ? (
          <Copyable text={data?.payment_schemes?.swift?.memo} textModifier={x => `${x?.slice(0, 4)}***`} />
        ) : (
          'Not Available'
        )
      }),
      'Account Reference': <Copyable text={data.account_reference} showOnHover />,
      ...(!['GBP', 'EUR', 'USD'].includes(data?.currency) && {
        'Customer Name': data.customer?.name || 'Not Available',
        'Customer Email': data.customer?.email || 'Not Available'
      }),
      'Unique ID': <Copyable text={data.unique_id} showOnHover textModifier={x => x?.toUpperCase()} />,
      'Total Amount Received': `${formatAmount(data.total_transacted_amount || 0)} ${data.currency || 'NGN'}`,
      ...(['GBP', 'EUR', 'USD'].includes(data?.currency) && {
        'Max Funding Limit (Daily)': data?.transaction_limit
          ? `${formatAmount(data?.transaction_limit[data?.account_type][data?.tier === 1 ? 'tier_one' : 'tier_two']?.daily || 0)} ${
              data?.currency || 'NGN'
            }`
          : 'Not Available',
        'Max Funding Limit (Monthly)': data?.transaction_limit
          ? `${formatAmount(data?.transaction_limit[data?.account_type][data?.tier === 1 ? 'tier_one' : 'tier_two']?.monthly || 0)} ${
              data?.currency || 'NGN'
            }`
          : 'Not Available',
        'Max Funding Limit (Per Transaction)': data?.transaction_limit
          ? `${formatAmount(data?.transaction_limit[data?.account_type][data?.tier === 1 ? 'tier_one' : 'tier_two']?.single || 0)} ${
              data?.currency || 'NGN'
            }`
          : 'Not Available'
      }),
      'Date Created': `${getDate(data.created_at)}`
    }
  ];

  const redirectOnClick = (firstroute, route, redirectReference = '') => {
    history.push(`/dashboard/${firstroute}${route && `/${route}`}${redirectReference && `/${redirectReference}`}`);
  };

  const accountTransactions = () => {
    return (
      <>
        {transactions.map(each => {
          return (
            <div
              key={`settlement_${each.reference}`}
              className="div-table --column-5 --vba-trxns --row"
              onClick={() => {
                redirectOnClick('pay-ins', each?.reference);
                logBreadCrumb({
                  event: breadCrumbEvents.virtualAccounts.accountTableClicked,
                  data: { reference: each?.reference }
                });
              }}
              onKeyDown={() => {
                redirectOnClick('pay-ins', each?.reference);
                logBreadCrumb({
                  event: breadCrumbEvents.virtualAccounts.accountTableClicked,
                  data: { reference: each?.reference }
                });
              }}
              role="button"
              tabIndex={0}
            >
              <div>
                <span className="body-row-header">Status:</span>
                <span className={`status-pill smaller ${switchStatus(each?.status)}`} />
                <span>{capitalize(each?.status)}</span>
                {each?.status === 'flagged' && (
                  <span style={{ marginLeft: '10px' }}>
                    <img width="15px" height="15px" src={Warning} alt="warning" />
                  </span>
                )}
              </div>
              <div>
                <span className="body-row-header">Transaction ID:</span>
                <span style={{ color: '#007bff', fontWeight: '500', textTransform: 'uppercase' }}>{each.reference}</span>
              </div>

              <div>
                <span className="body-row-header">Transaction Date:</span>
                <span>{getDate(each.created_at)}</span>
                <span className="annotation" style={{ marginLeft: '5px' }}>
                  {getTime(each.created_at)}
                </span>
              </div>
              <div>
                <span className="body-row-header">Amount:</span>
                <span>
                  <span style={{ fontWeight: '600' }}>{formatAmount(each.amount)} </span>
                  {data?.currency || 'NGN'}
                </span>
              </div>
            </div>
          );
        })}
      </>
    );
  };

  return (
    <>
      <div className="row">
        <button type="button" className="btn btn-link" onClick={() => history.goBack()}>
          <i className="os-icon os-icon-arrow-left7" />
          <span style={{ fontSize: '13px', fontWeight: '500' }}>Go Back</span>
        </button>
      </div>
      <div className="row">
        <div className="col-sm-12">
          <div className="element-wrapper">
            <div className="content-details-box">
              <div className="content-details-head" style={{ display: 'flex', flexDirection: 'column', border: '0' }}>
                <div style={{ display: 'flex', width: '100%' }}>
                  <div className="content-details-title">
                    <div>
                      <img src={VBAIcon} alt="" style={{ width: '3.2rem', paddingTop: '0.3rem' }} />
                    </div>
                    <div className="cd-title ml-3">
                      <h5 className="cd-maintitle" aria-label="Account Number">
                        <Copyable text={data?.account_number} copyText="Copied" showCopyText={false} />
                      </h5>
                      <div className='cd-bankdetails'>
                        <img width={15} height={15} src={BankLogo} alt="bank" />
                        <p className="cd-subtitle">{capitalize(data?.bank_name || 'Not Available')}</p>
                      </div>
                  
                    </div>
                    {['EUR', 'GBP', 'USD'].includes(data?.currency) && (
                      <div className="cd-tier">
                        <img src={data.tier === 1 ? Tier1Icon : Tier2Icon} alt="" style={{ width: '1.0rem' }} />
                        <p>{data?.pending_upgrade_request ? 'Pending Tier Upgrade' : `Tier ${data.tier}`}</p>
                      </div>
                    )}
                  </div>
                  {!['deactivated', 'pending', 'rejected'].includes(data?.account_status) &&
                    ['EUR', 'GBP', 'USD'].includes(data?.currency) && (
                      <div className="view-account-holder">
                        <p
                          className="view-text"
                          onClick={() => redirectOnClick('virtual-accounts', 'holders', data?.account_holder?.reference)}
                          onKeyDown={() => redirectOnClick('virtual-accounts', 'holders', data?.account_holder?.reference)}
                        >
                          View Account Holder
                        </p>
                        {data.account_status !== 'deactivated' && !data?.requested_status ? (
                          <div
                            onClick={() =>
                              setState(prev => {
                                return { ...prev, openDropdown: !state.openDropdown };
                              })
                            }
                            onKeyDown={() =>
                              setState(prev => {
                                return { ...prev, openDropdown: !state.openDropdown };
                              })
                            }
                            ref={menuRef}
                            className="manage-account-holder"
                          >
                            <img src={SettilngsIcon} alt="" />
                            <p>Manage Account Number</p>
                            <img className="dropdown-icon" src={DropdownIcon} alt="" />

                            {state.openDropdown && (
                              <div className="dropdown element-box box-style ellipsis__nav withdrawal-options menu-items account-actions">
                                {manageMenuOptions.map(action => (
                                  <button
                                    type="button"
                                    onClick={() =>
                                      setState(prev => {
                                        return { ...prev, modalVisibility: true, type: action.type, action: action.type };
                                      })
                                    }
                                    onKeyDown={() =>
                                      setState(prev => {
                                        return { ...prev, modalVisibility: true, type: action.type, action: action.type };
                                      })
                                    }
                                    className={action.type}
                                  >
                                    {action.text}
                                  </button>
                                ))}
                              </div>
                            )}
                          </div>
                        ) : (
                          <div className="pending-reactivation">
                            <img src={InfoIcon} alt="more info" />
                            <p>Reactivation Pending</p>
                          </div>
                        )}
                      </div>
                    )}
                </div>
                <div className="virtual-account-summary vba-banner-wrapper">
                  {isFetchingDetails ? (
                    <LoadingPlaceholder type="text" content={4} />
                  ) : (
                    <SecondaryDetails title="Account Number Summary" data={vbaDetails} />
                  )}
                  {data.account_status === 'suspended' && (
                    <section className="vba-banner">
                      This account has been suspended!{' '}
                      {['GBP', 'EUR', 'USD'].includes(data?.currency) && (
                        <span
                          onClick={() =>
                            setState(prev => {
                              return { ...prev, modalVisibility: true, type: 'reason', action: 'Suspension' };
                            })
                          }
                        >
                          Learn Why
                        </span>
                      )}
                    </section>
                  )}
                  {data.account_status === 'deleted' && (
                    <section className="vba-banner deleted">This account has been deleted and is no longer in use!</section>
                  )}
                  {data.account_status === 'deactivated' && (
                    <section className="vba-banner deleted">
                      This account has been deactivated and is no longer in use!{' '}
                      {['GBP', 'EUR', 'USD'].includes(data?.currency) && (
                        <span
                          onClick={() =>
                            setState(prev => {
                              return { ...prev, modalVisibility: true, type: 'reason', action: 'Deactivation' };
                            })
                          }
                        >
                          Learn Why
                        </span>
                      )}
                    </section>
                  )}
                </div>
              </div>
              <div className="content-details-body mt-1" style={{ boder: '0' }}>
                <VirtualAccountsFilter type="transactions" Tab="Transactions" totalCount={paging?.total_items} />
                <Table
                  tableClassName=" --column-5 --vba-trxns"
                  headings={[
                    {
                      value: 'Status'
                    },
                    {
                      value: 'Transaction ID'
                    },
                    {
                      value: 'Date'
                    },
                    {
                      value: 'Amount'
                    }
                  ]}
                  hasPagination
                  loading={isFetching}
                  current={paginationPage}
                  totalItems={paging?.total_items || 0}
                  pageSize={paging?.page_size || 0}
                  actionFn={current => searchQuery.setQuery({ page: current })}
                  emptyStateMessage={<p style={{ maxWidth: '200px', marginTop: '-20px' }}>There are no transactions for this account.</p>}
                  background="#f5f6f6"
                  tableWrapperClassName="vba-container sub"
                >
                  {accountTransactions()}
                </Table>
              </div>
              {state.modalVisibility && (
                <ManageAccountsNumberModal
                  close={() =>
                    setState(prev => {
                      return { ...prev, modalVisibility: false };
                    })
                  }
                  type={state.type}
                  reference={data.unique_id}
                  tier={data.tier}
                  action={state.action}
                  data={data?.transaction_limit}
                  currency={data?.currency}
                  actionReason={data?.status_reason}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
