import React, { useEffect, useRef } from 'react';
import { useMutation } from 'react-query';
import { useParams } from 'react-router-dom';
import { useReducerState, useFeedbackHandler } from '+hooks';
import { cleanInput, history, logError, blockInvalidChars } from '+utils';
import APIRequest from '+services/api-services';
import { Currency } from '+types/common';
import { ModalProps } from '+types/bulk-payouts'
import RadioButton from '../../Shared/RadioButton';
import Modal from '../../Shared/Modal';

const publicApi = new APIRequest(process.env.REACT_APP_PUBLIC_MERCHANT_MIDDLEWARE_API_BASE);

const repeat = {
  all: 'Create bulk payout from all transactions',
  successful: 'Create bulk payout from only successful transactions',
  failed: 'Create bulk payout from only failed transactions'
};

export default function RepeatBulkPayout({ close, type, currency }: ModalProps): JSX.Element {
  const inputRef = useRef<HTMLInputElement>(null);
  const { id } = useParams<{ id: string }>();
  const { feedbackInit, closeFeedback } = useFeedbackHandler();
  const [state, setState] = useReducerState({
    activeCurrency: 'NGN' as Currency,
    loading: false,
    inputValue: '',
    radioValue: 'all_payouts',
    isProcessing: false,
    type: '',
    message: ''
  });

  const payload = {
    custom_description: state.inputValue,
    option: state.radioValue,
    type: type
  };

  const { mutateAsync: repeatBulkPayout } = useMutation('REPEAT_BULKPAYOUT', () => publicApi.repeatBulkPayout(payload, id), {
    onSuccess: async data => {
      const response = data?.data;
      setState({ retrievedData: response || {}, data: response || null });
    },
    onError: error => {
      logError(error);
      feedbackInit({
        componentLevel: true,
        message: error.response?.data?.message || 'There has been an error submiting bulk payout',
        type: 'danger'
      });
      setTimeout(() => {
        closeFeedback();
      }, 2000);
    }
  });

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    const inputValue = cleanInput(e.target.value);
    setState({ inputValue, type: '', message: '' });
    if (inputValue.trim() === '') {
      setState({ type: 'onError', message: 'Please enter the bulk payout description.' });
    } else if (inputValue.length < 5) {
      setState({
        type: 'onError',
        message: 'Bulk payout description must be more than 5 letters.'
      });
      return false;
    }
  };

  const validateForm = () => {
    if (state.inputValue.trim() !== '' && state.radioValue !== '' && state.inputValue.length >= 5) {
      return true;
    } else {
      return false;
    }
  };

  const checkFormAndValidate = validateForm();

  const showValidation = () => {
    const { validating, type, message } = state;
    if (message) {
      let validationClass = '';
      if (type === 'onError') {
        validationClass = 'error-text';
      }
      if (type === 'onSuccess') {
        validationClass = 'success-text';
      }
      return (
        <div style={{ padding: '0.735rem 0' }}>
          {validating && (
            <span
              className="spinner-border spinner-border-sm"
              style={{ opacity: '0.6', marginRight: '0.5rem' }}
              role="status"
              aria-hidden="true"
            />
          )}
          <span className={`validation-text ${validationClass}`}>{message}</span>
        </div>
      );
    }
    return null;
  };

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, [state.inputValue]);

  const repeatBulkpayoutModal = {
    content: (
      <div data-testid="bulk-payout-repeat-modal">
        <div className="form-group mb-4">
          <label htmlFor="description" className="withdraw-label">
            <span className="dark">
              Custom Bulk Description <span className="optional">(*Please provide bulk payout description.)</span>
            </span>
          </label>
          <input
            ref={inputRef}
            className="form-control"
            data-testid="transaction-id"
            type="text"
            name="transactionReference"
            value={state.inputValue}
            onChange={handleInputChange}
            onKeyDown={blockInvalidChars}
            placeholder="SMGC-MAY-23-SALARY"
          />
          {showValidation()}
        </div>
        <div className="form-group mb-4">
          <label htmlFor="payoutType" className="withdraw-label">
            <span className="dark">Select the bulk transaction you prefer</span>
          </label>
          <div className="">
            <RadioButton
              checked={state.radioValue === 'all_payouts'}
              disabled={false}
              onChange={() => {
                setState({ radioValue: 'all_payouts' });
              }}
              label={repeat.all}
            />
          </div>
          <div className="">
            <RadioButton
              checked={state.radioValue === 'successful_payouts'}
              disabled={false}
              onChange={() => {
                setState({ radioValue: 'successful_payouts' });
              }}
              label={repeat.successful}
            />
          </div>
          <div className="">
            <RadioButton
              checked={state.radioValue === 'failed_payouts'}
              disabled={false}
              onChange={() => {
                setState({ radioValue: 'failed_payouts' });
              }}
              label={repeat.failed}
            />
          </div>
        </div>
        <div className="element-box refund-request-note p-3">
          <div>
            <p style={{ fontSize: '0.85rem' }}>
              <strong>Note :</strong> Some transactions may have failed due to issues with the recipients details. Including them in the new
              bulk payout may not rectify these issues. .
            </p>
          </div>
        </div>
      </div>
    )
  };

  return (
    <Modal
      close={close}
      visible={true}
      heading={<header>Repeat Bulk Payout</header>}
      description={
        <small>
          You can repeat a completed bulk payout with all its transactions without having to upload a new bulk payout file. This is
          especially useful for some payrolls and bulk transactions that do not change often.
        </small>
      }
      content={repeatBulkpayoutModal.content}
      secondButtonText="Continue"
      secondButtonDisable={!checkFormAndValidate}
      secondButtonAction={async () => {
        setState({ loading: true });
        try {
          setState({ isProcessing: true });
          await repeatBulkPayout();
        } finally {
          setState({ isProcessing: true });
        }
      }}
      completedHeading="Bulk payout successfully initiated"
      completedAction={() => history.push(`/dashboard/bulk-payout?currency=${currency}`)}
    />
  );
}
