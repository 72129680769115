/* eslint-disable no-nested-ternary */
import React, { useEffect, useRef, useState } from 'react';
import { Field, Formik, useFormik } from 'formik';

import ListDropdown from '+dashboard/Shared/ListDropdown';
import Modal from '+dashboard/Shared/Modal';
import { useAccountValidation, useGetFee, useNetworkAvailabilityStatus } from '+hooks';
import useFeedbackHandler from '+hooks/feedbackHandler';
import useCountriesAndStates from '+hooks/useCountriesAndStates';
import useStore from '+store';
import { BankPayoutsModalProps, TransferData, WithdrawalDetails } from '+types';
import {
  backwardAmountInput,
  capitalize,
  capitalizeRemovedash,
  cleanInput,
  formatAmount,
  history,
  logBreadCrumb,
  twoFAInputValidation
} from '+utils';
import { breadCrumbEvents } from '+utils/bugsnag-events';

import ForeignCurrencies from '../ForeignCurrencies';
import { foreignCurrencies, initialValues } from '../ForeignCurrencies/components/ForeignWithdrawalRequestformData';
import Countdown from './Countdown';
import WithdrawWarning from './WithdrawWarning';

export default function BankPayoutsModal({
  banks,
  close,
  currency,
  balance = 0,
  isFetchingBanks,
  initiateTransfer,
  processTransfer,
  resendOTP,
  paymentRef,
  identifier,
  maxPayoutLimit,
  minPayoutLimit,
  settlementAccounts = {},
  withdrawalLimit,
  get2FAType,
  networkOperator,
  type: modalType,
  onFormSubmit
}: BankPayoutsModalProps) {
  const defaultMerchant = useStore((store: any) => store.defaultMerchant);
  const { feedbackInit, closeFeedback } = useFeedbackHandler();
  const formRef = useRef(null);
  const [selected, setSelectedAccount] = useState('not-default');
  const [nextTouched, setNextTouched] = useState(false);
  const [bankListOpen, setBankListOpen] = useState(false);
  const [type, setType] = useState('');
  const [codeSent, setCodeSent] = useState(true);
  const [countdownCompleted, setCountdownCompleted] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);
  const findSettlementAccount = Object.entries(settlementAccounts).find(([keys, values]) => {
    if (keys === currency) return values;
    return false;
  });

  const handleCountdownCompletion = () => {
    setCountdownCompleted(true);
    setCodeSent(false);
  };

  const savedBank = findSettlementAccount && findSettlementAccount[1][0];
  const [twoFactorType, setTwoFactorType] = useState('otp');

  const { networkStatus, setNetworkStatus, showNetworkStatus, getMessage } = useNetworkAvailabilityStatus();

  const canEditName = ['ZAR'].includes(currency);
  const isForeignTrx = !!foreignCurrencies.includes(currency);
  const { countriesList, statesList } = useCountriesAndStates();

  const foreignCurrenciesFormValidation = (values: typeof initialValues) => {
    const errors: Partial<typeof initialValues> = {};

    if (!values.first_name) errors.first_name = 'First name is required';
    if (!values.last_name) errors.last_name = 'Last name is required';
    if (!values.account_number) errors.account_number = 'Account number is required';
    if (values.account_number?.length > 0 && values.account_number?.length < 8) {
      errors.account_number = 'Account number should be a minimum  of 8 digits';
    }
    if (!values.payment_method) errors.payment_method = 'Payment method is required';
    if (!values.recipient_address) errors.recipient_address = 'Recipient address is required';
    if (values.routing_number && (values.routing_number?.length < 6 || values.routing_number?.length > 9)) {
      errors.routing_number = 'Routing number should be between 6 and 9 digits';
    }
    if (values.swift_code && (values.swift_code?.length < 8 || values.swift_code?.length > 11)) {
      errors.swift_code = 'Swift code should be between 8 and 11 characters';
    }
    if (!values.bank_name) errors.bank_name = 'Bank name is required';

    return errors;
  };

  const { values, touched, errors, isValid, getFieldProps, setFieldValue, handleBlur } = useFormik({
    initialValues,
    onSubmit: val => {
      handleForeignTransferData(val);
    },
    validate: foreignCurrenciesFormValidation
  });

  let payload = {};

  const handleForeignTransferData = (values: typeof initialValues) => {
    payload = {
      ...values,
      account_number: values.account_number,
      payment_method: values.payment_method,
      swift_code: values.swift_code,
      routing_number: values.routing_number,
      zip_code: values.zip_code,
      sender_email: values.sender_email,
      country: values.country,
      city: values.city,
      state: values.state,
      first_name: values.first_name,
      last_name: values.last_name,
      account_name: `${values.first_name} ${values.last_name}`,
      bank_name: values.bank_name
    };

    if (typeof onFormSubmit === 'function') {
      onFormSubmit();
    }
  };

  const {
    validateBankAccount,
    debounceValidateBankAccount,
    resetValidationDetails,
    bankDetails: withdrawalDetails,
    setBankDetails: setWithdrawalDetails,
    accountNumMaxLength,
    showValidationStatus,
    isAcctNumLengthValid
  } = useAccountValidation({
    currency,
    formRef,
    canEditName
  });
  const maxWithdrawalLimit = withdrawalLimit?.settlement_account ?? maxPayoutLimit;

  const checkLimitAndValidate = ({ debounce }, values, ...otherPayloads) => {
    closeFeedback();

    const { account_number: inputtedAcctNum, bank_code: inputtedBankCode } = values;
    if (!inputtedBankCode || !isAcctNumLengthValid(currency, inputtedAcctNum)) {
      return false;
    }
    const isSettlementAccount = () => {
      const match = settlementAccounts?.[currency]?.find(
        acct => acct?.account_details?.account_number === inputtedAcctNum && acct?.account_details?.bank_code === inputtedBankCode
      );
      return !!match;
    };

    if (!isSettlementAccount() && +withdrawalDetails?.amount > +withdrawalLimit?.other_accounts) {
      feedbackInit({
        message: `Your maximum payout limit to a non-settlement account is ${formatAmount(
          withdrawalLimit?.other_accounts
        )} ${currency} per day.`,
        type: 'warning',
        componentLevel: true
      });
      return false;
    }
    return debounce ? debounceValidateBankAccount(values, ...otherPayloads) : validateBankAccount(values, ...otherPayloads);
  };

  const transferData: TransferData = {
    two_factor_type: twoFactorType,
    reference: '',
    destination: {
      type: 'bank_account',
      amount: withdrawalDetails.amount,
      narration: withdrawalDetails.description || 'Withdrawal from Merchant Balance',
      currency,
      bank_account: {
        ...(isForeignTrx ? {} : { bank: withdrawalDetails.bankName }),
        account: isForeignTrx ? values.account_number : withdrawalDetails.bankNumber,
        ...(isForeignTrx && { account_name: `${values.first_name} ${values.last_name}` }),

        ...(isForeignTrx && {
          first_name: values.first_name,
          last_name: values.last_name,
          bank_name: values.bank_name,
          address_information: {
            country: { USD: 'US', GBP: 'GB' }[currency]!,
            state: values.state,
            city: values.city,
            zip_code: values.zip_code,
            street: values.recipient_address,
            full_address: values.recipient_address
          },
          payment_method: {
            type: values.payment_method,
            code: values.swift_code || values.routing_number
          }
        })
      },
      customer: {
        name: isForeignTrx ? `${values.first_name} ${values.last_name}` : withdrawalDetails.username
      }
    }
  };

  useEffect(() => {
    if (parseFloat(withdrawalDetails.amount ?? '0') > (maxWithdrawalLimit ?? 0)) {
      feedbackInit({
        message: `Your maximum payout limit is ${formatAmount(maxWithdrawalLimit)} ${currency} per transaction.`,
        type: 'warning',
        componentLevel: true
      });
    } else {
      closeFeedback();
    }
  }, [withdrawalDetails.amount, selected]);

  useEffect(() => {
    setTwoFactorType(modalType);
  }, [modalType]);

  useEffect(() => {
    get2FAType && get2FAType(twoFactorType);
    setWithdrawalDetails(details => ({ ...details, pin: '' }));
  }, [twoFactorType]);

  const setDisabled = () => {
    if (isForeignTrx) {
      return !!Object.values(foreignCurrenciesFormValidation(values)).length;
    }
    if (['ZAR'].includes(currency) && (!networkStatus || networkStatus === 'closed')) {
      return true;
    }
    if (isProcessing) return true;
    if (withdrawalDetails?.showNameInput && !withdrawalDetails?.username?.trim()) return true;
    switch (type) {
      case 'otpStage':
        if (twoFAInputValidation(twoFactorType, withdrawalDetails.pin)) return false;
        return true;
      case 'addAmount':
        // eslint-disable-next-line no-case-declarations
        const number = parseFloat(withdrawalDetails.amount);
        if (number >= minPayoutLimit && number <= maxWithdrawalLimit) {
          if (number <= parseFloat(balance) - (parseFloat(fee) || 0)) return false;
          return true;
        }
        break;
      default:
        if (selected === 'default-content' && withdrawalDetails?.username?.length >= 4) return false;
        return !withdrawalDetails?.isValidated;
    }
    return true;
  };

  const { fee, fetchingFee, setFee } = useGetFee({ currency, currentAmount: withdrawalDetails?.amount });
  const withdrawalLimitExist = 'other_accounts' in withdrawalLimit;

  const selectAmountContent = () => {
    return (
      <>
        <div className="element-box withdraw-detail-container p-3">
          <div>
            <p>
              A{' '}
              {withdrawalLimitExist ? (
                <>
                  <strong>
                    maximum of {currency} {formatAmount(withdrawalLimit?.settlement_account)}
                  </strong>{' '}
                  can be withdrawn in a single transaction to your settlement account or{' '}
                  <strong>
                    maximum of {currency} {formatAmount(withdrawalLimit?.other_accounts)}{' '}
                  </strong>
                  per day to other accounts.{' '}
                </>
              ) : (
                <>
                  <strong>
                    minimum of {currency} {formatAmount(minPayoutLimit)}
                  </strong>{' '}
                  and a{' '}
                  <strong>
                    maximum of {currency} {formatAmount(maxPayoutLimit)}
                  </strong>{' '}
                  can be withdrawn in a single transaction.
                </>
              )}
            </p>
          </div>
        </div>
        <div className="form-group">
          <label htmlFor="amount" className="withdraw-label">
            <span className="dark">Amount to withdraw</span>
            <span className="small">
              Fee charged:{' '}
              {fetchingFee ? (
                <span
                  className="spinner-border spinner-border-sm"
                  style={{ opacity: '0.4', marginLeft: '5px' }}
                  role="status"
                  aria-hidden="true"
                />
              ) : (
                <span className="dark">{fee}</span>
              )}
            </span>
          </label>
          <input
            type="number"
            data-testid="withdraw-amount"
            className="form-control"
            name="amount"
            value={withdrawalDetails?.amount || ''}
            placeholder="eg 1,000.00"
            maxLength={11}
            onChange={e => {
              const formattedAmount = backwardAmountInput(e.target.value);
              setWithdrawalDetails((details: WithdrawalDetails) => ({ ...details, amount: formattedAmount }));
            }}
          />
          <label htmlFor="amount" className="withdraw-label mt-2 small">
            <span>
              Minimum amount:{' '}
              <span className={`dark ${parseFloat(withdrawalDetails?.amount) < minPayoutLimit && 'red'}`} id="min">
                {currency} {formatAmount(minPayoutLimit)}
              </span>
            </span>
            <span>
              Available balance:{' '}
              <span className={`dark ${parseFloat(withdrawalDetails?.amount) > balance && 'red'}`}>
                {currency} {formatAmount(balance)}
              </span>
            </span>
          </label>
        </div>
        <div className="form-group">
          <label htmlFor="description" className="withdraw-label">
            <span className="dark">
              Description <span style={{ opacity: 0.7 }}>(Optional)</span>
            </span>
          </label>
          <input
            rows={2}
            maxLength={150}
            value={withdrawalDetails?.description || ''}
            className="form-control"
            name="description"
            onChange={e => {
              const formattedInput = cleanInput(e.target.value);
              setWithdrawalDetails((details: WithdrawalDetails) => ({ ...details, description: formattedInput }));
            }}
          />
        </div>
        <WithdrawWarning />
      </>
    );
  };

  const selectAccountContent = () => {
    switch (selected) {
      case 'default-content':
        return (
          <>
            <legend className="account-legend">
              <span>
                <button
                  type="button"
                  className="btn btn-link"
                  onClick={() => {
                    setSelectedAccount('not-default');
                  }}
                >
                  Or, add account details
                </button>
              </span>
            </legend>
            {settlementAccounts[currency] ? (
              savedBank?.status === 'active' ? (
                <div className="form-group">
                  <label htmlFor="saved-accounts" className="withdraw-label">
                    <span className="dark">Choose a saved account</span>
                  </label>
                  <select name="saved-accounts" className="form-control saved-account">
                    <option value="0">
                      {savedBank?.account_details?.account_number} - {savedBank?.bank?.name} ({savedBank?.account_details?.account_name})
                    </option>
                  </select>
                </div>
              ) : (
                <div className="form-group">
                  <p className="withdrawal-under-review">Account is Under Review</p>
                </div>
              )
            ) : (
              <article className={`no-saved-account ${nextTouched && 'blinking'}`}>
                <p className="no-bankdetails">You do not have any bank accounts stored at the moment.</p>
                <article>
                  <p>You can add an account in the compliance section, to be used upon withdrawal.</p>
                  <button type="button" className="btn btn-primary bold" onClick={() => history.replace('/dashboard/settings/compliance')}>
                    ＋ Add Account
                  </button>
                </article>
              </article>
            )}
          </>
        );
      default:
        if (foreignCurrencies.includes(currency)) {
          return (
            <ForeignCurrencies
              currency={currency}
              countriesList={countriesList}
              statesList={statesList}
              requestFormProps={{
                values,
                touched,
                errors,
                isValid,
                getFieldProps,
                setFieldValue,
                handleBlur
              }}
            />
          );
        }
        return (
          <div className="bankForm-container">
            <legend className="account-legend">
              <span>
                <button
                  type="button"
                  className="btn btn-link"
                  onClick={() => {
                    setSelectedAccount('default-content');
                    if (settlementAccounts[currency]) {
                      setWithdrawalDetails(details => ({
                        ...details,
                        bankName: savedBank?.account_details?.bank_code,
                        bankNumber: savedBank?.account_details?.account_number,
                        username: savedBank?.account_details?.account_name
                      }));
                    }
                  }}
                >
                  Or, send to your settlement account
                </button>
              </span>
            </legend>
            <Formik
              initialValues={{
                account_name: '',
                account_number: '',
                bank_code: '',
                bank_name: '',
                resolving_bank: false,
                enquiry_message: '',
                status: ''
              }}
              innerRef={formRef}
            >
              {({ values, setFieldValue }) => {
                return (
                  <>
                    <div className="form-group">
                      <Field name="bank">
                        {({ field }) => (
                          <>
                            <label htmlFor="bank" className="withdraw-label">
                              <span className="dark">Bank</span>
                            </label>
                            <ListDropdown
                              {...field}
                              list={['ZAR'].includes(currency) ? networkOperator : banks}
                              type="bank"
                              className="banks-list"
                              value={{ code: values.bank_code, name: values.bank_name }}
                              active={bankListOpen}
                              setActive={(open: boolean | ((prevState: boolean) => boolean)) => setBankListOpen(open)}
                              isFetching={isFetchingBanks}
                              onBlur={setNetworkStatus(values.status?.toLowerCase())}
                              setValue={(value: { code: number; name: string; status: string }) => {
                                setFieldValue('bank_code', value.code);
                                setFieldValue('bank_name', value.name);
                                setFieldValue('status', value.status);
                                resetValidationDetails();
                                checkLimitAndValidate(
                                  { debounce: false },
                                  { ...values, bank_code: value.code, bank_name: value.name },
                                  setFieldValue
                                );
                              }}
                            />
                          </>
                        )}
                      </Field>
                      <small className="network-status">{values.status && showNetworkStatus()}</small>
                    </div>
                    <div className="form-group">
                      <Field name="account_number">
                        {({ field }) => (
                          <>
                            <label htmlFor="account_number" className="withdraw-label">
                              <span className="dark">Account Number</span>
                            </label>
                            <input
                              {...field}
                              placeholder="0000000000"
                              maxLength={accountNumMaxLength}
                              type="number"
                              onChange={e => {
                                resetValidationDetails();
                                setFieldValue('account_number', e.target.value);
                                checkLimitAndValidate({ debounce: true }, { ...values, account_number: e.target.value }, setFieldValue);
                              }}
                            />
                          </>
                        )}
                      </Field>
                    </div>
                    <div className="form-group">
                      {showValidationStatus()}
                      {withdrawalDetails?.showNameInput && (
                        <Field name="account_name">
                          {({ field }) => (
                            <>
                              <label htmlFor="account_name" className="withdraw-label">
                                <span className="dark">Customer&apos;s Name</span>
                              </label>
                              <input
                                {...field}
                                name="account_name"
                                placeholder="Customer name"
                                readOnly={!withdrawalDetails?.canEditName}
                                className={!withdrawalDetails?.canEditName ? 'read-only' : ''}
                                onChange={e => {
                                  const formattedInput = cleanInput(e.target.value);
                                  setFieldValue('account_name', formattedInput);
                                  setWithdrawalDetails(details => ({
                                    ...details,
                                    username: formattedInput
                                  }));
                                }}
                              />
                            </>
                          )}
                        </Field>
                      )}
                    </div>
                    <span className=" withdrawal_info p-3" style={{ marginBottom: '9px', display: 'block' }}>
                      Please, ensure that the customer or recipient's details are correct to avoid any potential issue with the transaction.
                    </span>
                  </>
                );
              }}
            </Formik>
          </div>
        );
    }
  };

  const otpContent = () => {
    let email = defaultMerchant?.userEmail;
    if (email?.includes('@')) {
      email = email.split('@');
      email = `${email[0].slice(0, 3)}*******@${email[1]}`;
    }
    let inputLabel = 'One Time PIN (OTP)';
    let inputPlaceholder = '';
    let AuthenticationText;
    if (twoFactorType === 'totp') {
      inputLabel = 'Authentication Code';
      inputPlaceholder = 'Enter authentication code';
      AuthenticationText = (
        <>
          To proceed, enter the authorization code from your <b>authenticator app</b> in the space provided below to confirm this
          transaction.
        </>
      );
    } else if (twoFactorType === 'totp_recovery_code') {
      inputLabel = 'Recovery Code';
      inputPlaceholder = 'Enter recovery code';
    }

    return (
      <div className="bankForm-container">
        <div className="element-box mb-2 p-2">
          <div>
            {twoFactorType === 'totp_recovery_code' ? (
              <p>Can't use your authenticator app? Enter one of your previously saved recovery codes to validate this transaction.</p>
            ) : (
              <div className="otp-form-container" style={{ color: '#F3F4F8', fontWeight: 400, fontSize: '12px' }}>
                <div className="otp-form-summary">
                  <p>Some important details of your payout:</p>
                  <div className="form-summary-item">
                    <span>Amount</span>
                    <span>
                      {' '}
                      {formatAmount(withdrawalDetails.amount || 0)} {currency}
                    </span>
                  </div>
                  <div className="form-summary-item">
                    <span>Customer’s name </span>
                    <span> {transferData?.destination?.customer.name || 'Not Provided'}</span>
                  </div>
                  <div className="form-summary-item">
                    <span>Payment Type</span>
                    <span>
                      {' '}
                      {capitalizeRemovedash(
                        isForeignTrx ? transferData?.destination?.bank_account?.payment_method?.type : transferData?.destination?.type
                      )}
                    </span>
                  </div>
                  {!isForeignTrx && (
                    <>
                      <div className="form-summary-item">
                        <span>Account Number </span>
                        <span> {transferData?.destination?.bank_account?.account}</span>
                      </div>
                      <div className="form-summary-item">
                        <span>Bank Name</span>
                        <span> {withdrawalDetails?.resolvedBankName}</span>
                      </div>
                    </>
                  )}
                  {isForeignTrx && (
                    <>
                      <div className="form-summary-item">
                        <span>Routing Number</span>
                        <span>{transferData?.destination?.bank_account?.payment_method?.code}</span>
                      </div>
                      <div className="form-summary-item">
                        <span>Account Number</span>
                        <span>{transferData?.destination?.bank_account?.account}</span>
                      </div>
                      <div className="form-summary-item">
                        <span>Bank Name</span>
                        <span>{transferData?.destination?.bank_account?.bank_name}</span>
                      </div>
                    </>
                  )}
                  <div className="form-summary-item">
                    <span>Current Balance</span>
                    <span>
                      <strong>
                        {' '}
                        {formatAmount(balance || 0)} {currency}
                      </strong>
                    </span>
                  </div>
                </div>
                {['ZAR'].includes(currency) && (
                  <div className={`network_status ${networkStatus?.toLowerCase()}_network_status`}>
                    <span>Status: </span>
                    {capitalize(networkStatus)}
                    <span>{getMessage(networkStatus)}</span>
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
        <p className="auth-info-text">
          {twoFactorType === 'otp' ? (
            <>
              To proceed, enter the OTP (one-time PIN) that was sent to your email <b>({email})</b>.
            </>
          ) : (
            <>{AuthenticationText}</>
          )}
        </p>
        <label htmlFor="amount">{inputLabel}</label>
        <input
          type="number"
          name="pin"
          maxLength={7}
          autoComplete="one-time-code"
          placeholder={inputPlaceholder}
          value={withdrawalDetails.pin || ''}
          data-testid="otp-input"
          onChange={e => {
            const formattedInput = cleanInput(e.target.value);
            setWithdrawalDetails(details => ({ ...details, pin: formattedInput }));
            setIsProcessing(false);
          }}
        />
        {twoFactorType === 'totp' && (
          <div className="recovery-code">
            Can't access authenticator app?{' '}
            <button type="button" onClick={() => setTwoFactorType('totp_recovery_code')} className="recovery-code-btn">
              Confirm using recovery codes
            </button>
          </div>
        )}
        {twoFactorType === 'otp' && (
          <label htmlFor="amount" className="withdraw-label mt-2 small">
            <span>
              <span className="dark">If you didn’t receive a code? </span>
              {!codeSent || countdownCompleted ? (
                <button
                  type="button"
                  className="btn btn-link"
                  onClick={async () => {
                    setCodeSent(true);
                    setCountdownCompleted(false);
                    await resendOTP.mutateAsync({
                      identifier
                    });
                  }}
                >
                  Resend code.
                </button>
              ) : (
                <span style={{ marginLeft: '7px' }}>
                  {`Resend code in `} <Countdown callbackFn={handleCountdownCompletion} countFrom={30} /> secs.
                </span>
              )}
            </span>
          </label>
        )}
      </div>
    );
  };

  const getOtpStageDescription = () => {
    switch (twoFactorType) {
      case 'totp':
        return (
          <>
            Enter the authenticator code from your <b>authenticator app</b> in the space provided below to confirm this transaction.
          </>
        );
      case 'otp':
        return 'To confirm this transaction, you need to enter the one-time PIN (OTP) that was just sent to your email.';
      default:
        return '';
    }
  };

  const getOtpStageFirstBtn = () => {
    if (twoFactorType === 'totp_recovery_code') {
      return async () => {
        setTwoFactorType('totp');
      };
    }
    return undefined;
  };

  const switchWithdrawModal = (kind: string) => {
    let content;
    switch (kind) {
      case 'addAmount':
        content = {
          heading: `Withdraw Funds to Bank Account`,
          description: 'Please enter the details of the transaction to proceed.',
          content: selectAmountContent(),
          firstButtonText: 'Back',
          firstButtonAction: () => {
            setType('init');
            setNextTouched(false);
          },
          secondButtonText: 'Next',
          secondButtonAction: async () => {
            try {
              setIsProcessing(true);
              logBreadCrumb({ event: breadCrumbEvents.balances.attemptWithdrawButton('Withdraw', 'bank account') });
              await initiateTransfer.mutateAsync(transferData);
              setType('otpStage');
              setNextTouched(false);
            } finally {
              setIsProcessing(false);
            }
          }
        };
        break;
      case 'otpStage':
        content = {
          heading: `Confirm ${twoFactorType === 'totp_recovery_code' ? 'using recovery code' : 'transaction'}`,
          description: getOtpStageDescription(),
          content: otpContent(),
          firstButtonText: twoFactorType === 'totp_recovery_code' ? 'Back' : undefined,
          secondButtonText: 'Yes, Confirm',
          firstButtonAction: getOtpStageFirstBtn(),
          secondButtonAction: async () => {
            const verifyData = {
              payment_reference: paymentRef,
              auth_data: {
                two_factor_type: twoFactorType,
                identifier,
                code: withdrawalDetails.pin
              }
            };
            setIsProcessing(true);
            if (processTransfer) {
              await processTransfer.mutateAsync(verifyData);
            }
          },
          completedHeading: 'Withdrawal successful',
          completedDescription: 'Your payment has been initiated.',
          completedActionText: 'View payment details',
          completedAction: () => history.push(`/dashboard/payouts/${paymentRef}`)
        };
        break;
      default:
        content = {
          heading: selected === 'default-content' ? 'Send to Bank Account' : 'Withdraw Funds to Bank Account',
          description:
            selected === 'default-content'
              ? 'Select an account to which you want to make this withdrawal.'
              : 'Select your destination bank account and fill in the details to withdraw.',
          content: selectAccountContent(),
          firstButtonText: 'Cancel',
          secondButtonText: 'Next',
          secondButtonAction: () => {
            closeFeedback();
            setType('addAmount');
          }
        };
        break;
    }
    return {
      size: 'md',
      close: () => {
        setType('init');
        setSelectedAccount('non-default');
        setFee('0.00');
        setWithdrawalDetails({
          bankEnquiry: {
            visible: null,
            message: null,
            type: null
          }
        });
        close();
      },
      secondButtonDisable: setDisabled(),
      secondButtonActionIsTerminal: kind === 'otpStage',
      ...content
    };
  };
  return <Modal {...switchWithdrawModal(type)} />;
}
