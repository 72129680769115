import React, { useState } from 'react';

import bulkPayoutInfo3 from '+assets/img/dashboard/bulkpayoutToolTip/bulkpayout_error_info_modal.svg';
import bulkPayoutInfo1 from '+assets/img/dashboard/bulkpayoutToolTip/bulkpayout_info.svg';
import bulkPayoutInfo4 from '+assets/img/dashboard/bulkpayoutToolTip/bulkpayout_start.svg';
import bulkPayoutInfo2 from '+assets/img/dashboard/bulkpayoutToolTip/bulkpayout_upload_info_modal.svg';

const bankPayoutSampleFile = process.env.REACT_APP_SAMPLE_FILE_BANK_PAYOUT;
const mobileMoneySampleFile = process.env.REACT_APP_SAMPLE_FILE_MOBILE_MONEY;

const useBulkPayoutInfoModal = (isBankTrx: boolean) => {
  const [showLearnMoreModal, setShowLearnMoreModal] = useState<boolean>(false);
  const getBulkPayoutInfoProps = () => {
    const sampleFile = isBankTrx ? bankPayoutSampleFile : mobileMoneySampleFile;

    return {
      onSubmit: () => setShowLearnMoreModal(false),
      secondButtonFinalText: 'Done',
      removeIconOnFinalStep: true,
      hasFirstButton: true,
      data: [
        {
          title: 'Multiple payouts without hassle!',
          description:
            'You can make payouts to multiple beneficiaries simultaneously with the easy-to-use bulk payout tool. Works like magic!',
          image: bulkPayoutInfo1
        },
        {
          title: 'First, upload bulk payout file',
          description: (
            <>
              Create and upload your bulk payout file with the individual payouts as entries on each row.{' '}
              <a href={sampleFile} className="link-text" download rel="noopener noreferrer">
                Download a sample Bulk Payout file
              </a>
            </>
          ),
          image: bulkPayoutInfo2
        },
        {
          title: 'Check your entries for errors',
          description: 'Edit your entries to make sure that there are no errors on the details of each transfer.',
          image: bulkPayoutInfo3
        },
        {
          title: 'Start your bulk payout!',
          description:
            'Once you’ve checked that there are no errors on your entries, simply start the bulk payout to process your payouts.',
          image: bulkPayoutInfo4
        }
      ]
    };
  };

  return {
    showLearnMoreModal,
    setShowLearnMoreModal,
    getBulkPayoutInfoProps
  };
};
export default useBulkPayoutInfoModal;
