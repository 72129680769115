import { ReactNode } from 'react';
import arrow from '+assets/img/dashboard/accordion-arrow.svg';
import './index.scss';

interface IAccordionProps {
  className?: string;
  showToggle?: boolean;
  defaultOpen?: boolean;
  title: ReactNode;
  content: ReactNode;
  customToggle?: ReactNode;
}

const Accordion = ({
  className = '',
  showToggle = true,
  defaultOpen = false,
  title,
  content,
  customToggle = <img className="toggler-arrow" src={arrow} alt="" />,
}: IAccordionProps) => {
  return (
    <details className={`accordion__comp ${className}`} open={defaultOpen}>
      <summary>
        <section>{title}</section>
        {showToggle && customToggle}
      </summary>
      <section className="accordion-content">{content}</section>
    </details>
  );
};

export default Accordion;
