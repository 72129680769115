import React, { Fragment, FunctionComponentElement } from 'react';

import Copyable from '+containers/Dashboard/Shared/Copyable';
import { MasterCard, Visa } from '+containers/Shared/Icons';
import { TIcon } from '+containers/Shared/Icons/types';
import { capitalize, capitalizeRemovedash, getDateAndTime, getTwoDigitYear, isNullish } from '+utils';

import { CardDetailResponseType } from '../types';

const cardLogoOptions: Record<string, FunctionComponentElement<TIcon>> = {
  visa: React.createElement(Visa, { title: 'visa', titleId: 'visa', className: 'card-scheme' }),
  mastercard: React.createElement(MasterCard, { title: 'mastercard', titleId: 'mastercard', className: 'card-scheme' })
};

const mapStatusToColor = (status: 'terminated' | 'deactivated' | 'suspended') => {
  const options = {
    terminated: 'failure',
    deactivated: 'warn',
    suspended: 'warn'
  };
  return options[status] || status;
};

export const generateCardSummary = ({ cardDetails: data, cardId: ref }: { cardDetails: CardDetailResponseType; cardId: string }) => {
  const getExpiryDate = () => {
    let formattedYear;
    if (data?.expiry_month && data?.expiry_year) {
      formattedYear = getTwoDigitYear(data?.expiry_year);
      return `${data?.expiry_month} / ${formattedYear}`;
    }
    return null;
  };

  const expiryDate = getExpiryDate();

  return [
    {
      label: 'Card Status',
      before: React.createElement('span', {
        className: `status-indicator lg ${mapStatusToColor(data?.status)}`
      }),
      value: ['requires_auth', 'require_auth'].includes(data?.status)
        ? 'Pending (requires authorization)'
        : capitalizeRemovedash(data?.status ?? '')
    },
    {
      label: 'Cardholder Name',
      value: !isNullish(data?.card_holder) ? `${data?.card_holder?.first_name} ${data?.card_holder?.last_name}` : 'Not Available'
    },
    {
      label: 'Expires At',
      value: !isNullish(expiryDate) ? expiryDate : 'Not Available'
    },
    {
      label: 'Card Scheme',
      value: data?.brand ? React.createElement(Fragment, null, cardLogoOptions[data?.brand], capitalize(data?.brand)) : 'Not Available'
    },
    {
      label: 'Created At',
      value: !isNullish(data?.date_created) ? getDateAndTime(data?.date_created) : 'Not Available'
    },
    {
      label: 'Unique Card ID',
      value: React.createElement(Copyable, {
        text: ref,
        buttonClassName: 'copy-button-payref'
      })
    },
    {
      label: 'Billing Address',
      value: !isNullish(data?.billing)
        ? `${data?.billing?.address1}, ${data?.billing?.address2 ? `${data?.billing?.address2}, ` : ''}${data?.billing?.city}, ${
            data?.billing?.country
          }`
        : 'Not Available'
    }
  ];
};
