import { TRenderSvg } from './types';

const MasterCard: TRenderSvg = ({
  title,
  titleId,
  descriptionId = '',
  description = '',
  width = '36',
  height = '28',
  fill = 'none',
  viewBox = '0 0 36 28',
  className = ''
}) => (
  <svg
    width={width}
    height={height}
    viewBox={viewBox}
    fill={fill}
    xmlns="http://www.w3.org/2000/svg"
    aria-labelledby={`${titleId} ${descriptionId}`}
    aria-hidden={!!title}
    className={className}
  >
    <title id={titleId}>{title}</title>
    <desc id={descriptionId}>{description}</desc>
    <g filter="url(#filter0_d_29181_4740)">
      <path
        d="M30.6087 2.29102H5.3913C4.07062 2.29102 3 3.38649 3 4.73782V20.7533C3 22.1046 4.07062 23.2001 5.3913 23.2001H30.6087C31.9294 23.2001 33 22.1046 33 20.7533V4.73782C33 3.38649 31.9294 2.29102 30.6087 2.29102Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.2109 18.4837C16.9367 19.6312 15.2838 20.3239 13.4777 20.3239C9.44765 20.3239 6.18066 16.875 6.18066 12.6205C6.18066 8.36593 9.44765 4.91699 13.4777 4.91699C15.2838 4.91699 16.9367 5.60973 18.2109 6.75727C19.4851 5.60973 21.138 4.91699 22.9441 4.91699C26.9742 4.91699 30.2411 8.36593 30.2411 12.6205C30.2411 16.875 26.9742 20.3239 22.9441 20.3239C21.138 20.3239 19.4851 19.6312 18.2109 18.4837Z"
        fill="#ED0006"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.2109 18.4837C19.7799 17.0707 20.7748 14.9682 20.7748 12.6205C20.7748 10.2727 19.7799 8.17019 18.2109 6.75727C19.4852 5.60973 21.138 4.91699 22.9442 4.91699C26.9742 4.91699 30.2412 8.36593 30.2412 12.6205C30.2412 16.875 26.9742 20.3239 22.9442 20.3239C21.138 20.3239 19.4852 19.6312 18.2109 18.4837Z"
        fill="#F9A000"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.2108 6.75684C19.7797 8.16981 20.7746 10.2722 20.7746 12.62C20.7746 14.9678 19.7797 17.0702 18.2108 18.4832C16.6418 17.0702 15.647 14.9678 15.647 12.62C15.647 10.2722 16.6418 8.16981 18.2108 6.75684Z"
        fill="#FF5E00"
      />
    </g>
    <defs>
      <filter
        id="filter0_d_29181_4740"
        x="-1"
        y="-0.754883"
        width="38"
        height="29"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
        <feOffset dy="1" />
        <feGaussianBlur stdDeviation="1.5" />
        <feColorMatrix type="matrix" values="0 0 0 0 0.494118 0 0 0 0 0.556863 0 0 0 0 0.694118 0 0 0 0.4 0" />
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_29181_4740" />
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_29181_4740" result="shape" />
      </filter>
    </defs>
  </svg>
);

export { MasterCard };
