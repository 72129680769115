/* eslint-disable camelcase */
/* eslint-disable react/jsx-props-no-spreading */
import React, { useState, useRef } from 'react';
import { Formik, Field } from 'formik';
import { useMutation } from 'react-query';
import { logError, switchCurrency, cleanInput } from '+utils';
import { useAccountValidation } from '+hooks/useAccountValidation';
import useFeedbackHandler from '+hooks/feedbackHandler';
import { useBanks } from '+hooks';
import APIRequest from '+services/api-services';
import Modal from '../../../Shared/Modal';
import ListDropdown from '../../../Shared/ListDropdown';
import useStore from '+store';

const api = new APIRequest();

interface IBankReplacementModalProps {
  isFetchingBanks: boolean;
  banks?: Array<Record<string, any>>;
  close: () => void;
  showRequestSentModal: () => void;
  secondbuttonDisable: boolean;
}

const BankReplacementModal = ({
  close,
  showRequestSentModal,
}: IBankReplacementModalProps) => {
  const { feedbackInit, closeFeedback } = useFeedbackHandler();
  const formRef = useRef(null);
  const [bankListOpen, setBankListOpen] = useState(false);
  const [state, setState] = useState({
    currency: 'NGN',
    bank_code: '',
    account_name: '',
    account_number: '',
    modalVisible: false,
    bankEnquiry: {
      visible: false,
      message: 'Verifying bank account...',
      type: 'secondary'
    }
  });

  const {
    validateBankAccount,
    debounceValidateBankAccount,
    resetValidationDetails,
    bankDetails,
    showValidationStatus,
    accountNumMaxLength
  } = useAccountValidation({
    currency: state.currency,
    formRef,
    canEditName: true
  });

  const { data: banks } = useBanks(state.currency);
  const availableCurrencies = useStore(state => state.availableCurrencies);

  const handleSubmit = async () => {
    const { account_number, bank_code, account_name } = formRef.current.values;
    if (bankDetails?.canEditName && !(account_name?.length > 2)) {
      return;
    }
    const updateSettlement = {
      currency: state.currency,
      details: {
        bank_code,
        account_number,
        ...(bankDetails?.canEditName && { account_name })
      }
    };
    await mutateAsync(updateSettlement);
    closeFeedback();
  };

  const { mutateAsync } = useMutation(data => api.updateSettlementAccount(data), {
    onSuccess: () => {
      close();
      setState({
        ...state,
        account_number: '',
        buttonDisabled: true,
        modalVisible: false
      });
      showRequestSentModal();
      setTimeout(() => {
        closeFeedback();
      }, 5000);
    },
    onError: error => {
      close();
      logError(error);
      window.scrollTo(0, 0);
      setState({ ...state, buttonDisabled: true, modalVisible: false, account_number: '' });
      if (
        error.response.data.message.includes('Account details is already being reviewed') ||
        error.response.data.message.includes('Account details was rejected, please use a different account') ||
        error.response.data.message.includes('Account number has already been added and approved')
      ) {
        feedbackInit({
          message: `Duplicate account details submitted, please use a different account`,
          type: 'danger'
        });
      } else {
        feedbackInit({
          message: `There's an issue updating your settlement account details. Try again later`,
          type: 'danger'
        });
      }
      setTimeout(() => {
        closeFeedback();
      }, 5000);
    }
  });

  const selectAccountContent = () => {
    return (
      <div className="bankForm-container">
        <Formik
          initialValues={{
            account_name: '',
            account_number: '',
            bank_code: '',
            bank_name: '',
            resolving_bank: false,
            enquiry_message: ''
          }}
          innerRef={formRef}
          validate={values => {
            const errors = {};
            if (bankDetails?.canEditName && values.account_name?.length < 3) {
              errors.account_name = 'Please enter a valid name';
            }
            return errors;
          }}
        >
          {({ values, setFieldValue, errors }) => {
            return (
              <>
                <div className="form-group">
                  <Field name="currency">
                    {({ field }) => (
                      <>
                        <label htmlFor="account_number" className="withdraw-label">
                          <span className="dark">Currency</span>
                        </label>

                        <select
                          {...field}
                          name="currency-select"
                          component="select"
                          className="form-control"
                          required
                          value={state.currency}
                          onChange={e => {
                            e.persist();
                            setState({ ...state, currency: e?.target?.value });
                          }}
                        >
                          {availableCurrencies?.map(currency => (
                            <option value={currency} key={currency}>
                              {switchCurrency[currency]}
                            </option>
                          ))}
                          {availableCurrencies?.length === 0 && <option value="">Not Available</option>}
                        </select>
                      </>
                    )}
                  </Field>
                </div>

                <div className="form-group">
                  <Field name="bank">
                    {({ field }) => (
                      <>
                        <label htmlFor="account_number" className="withdraw-label">
                          <span className="dark">Bank</span>
                        </label>
                        <ListDropdown
                          {...field}
                          list={banks?.data}
                          type="bank"
                          className="banks-list settlement-bank-list"
                          value={{ code: values?.bank_code, name: values?.bank_name }}
                          active={bankListOpen}
                          setActive={open => setBankListOpen(open)}
                          onBlur={null}
                          setValue={value => {
                            validateBankAccount({ ...values, bank_code: value.code, bank_name: value.name }, setFieldValue);
                            setFieldValue('bank_code', value.code);
                            setFieldValue('bank_name', value.name);
                          }}
                        />
                      </>
                    )}
                  </Field>
                </div>
                <div className="form-group">
                  <Field name="account_number">
                    {({ field }) => (
                      <>
                        <label htmlFor="account_number" className="withdraw-label">
                          <span className="dark">Account Number</span>
                        </label>
                        <input
                          name="account_number"
                          {...field}
                          component="input"
                          placeholder="E.g. 0123456789"
                          maxLength={accountNumMaxLength}
                          onChange={e => {
                            resetValidationDetails();
                            field.onChange?.(e);
                            debounceValidateBankAccount({ ...values, account_number: e.target.value }, setFieldValue);
                          }}
                        />
                      </>
                    )}
                  </Field>
                </div>
                <div className="form-group">
                  {showValidationStatus()}
                  <Field name="account_name">
                    {({ field }) => (
                      <>
                        <label htmlFor="account_name" className="withdraw-label">
                          <span className="dark">Customer</span>
                        </label>
                        <input
                          {...field}
                          name="account_name"
                          placeholder="E.g. Ciroma Chukwuma"
                          {...(!bankDetails.canEditName && {
                            readOnly: true,
                            className: 'read-only'
                          })}
                          onChange={e => {
                            setFieldValue('account_name', cleanInput(e.target.value));
                          }}
                        />
                        <em className="text-danger">{errors?.account_name}</em>
                      </>
                    )}
                  </Field>
                </div>
              </>
            );
          }}
        </Formik>
      </div>
    );
  };

  return (
    <Modal
      close={() => {
        setState({
          ...state,
          bankEnquiry: {
            visible: null,
            message: null,
            type: null
          }
        });
        close();
      }}
      secondButtonDisable={!bankDetails?.isValidated}
      showImage={false}
      heading="Replace Bank Account"
      description="To update your bank account, please fill out the form below. This account should have the same name as the one in your profile."
      content={selectAccountContent()}
      firstButtonText="Cancel"
      secondButtonText="Proceed"
      secondButtonAction={handleSubmit}
      secondButtonActionIsTerminal={false}
    />
  );
};

export default BankReplacementModal;
