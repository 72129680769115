/* eslint-disable no-nested-ternary */
/* eslint-disable camelcase */
import React, { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { Route, Switch } from 'react-router-dom';

import { useFeedbackHandler, useSearchQuery } from '+hooks';
import useExportDownloader from '+hooks/useExportDownloader';
import APIRequest from '+services/api-services';
import useStore from '+store';
import { APIDownload, durationMonth, filteredOutObjectProperty, getDate, queriesParams } from '+utils';

import AdvanceExportModal from '../Shared/AdvanceExportModal';
import ExportFilterModal from '../Shared/ExportFilterModal';
import FilterComponent from '../Shared/Filter';
import TransactionTableComponent from '../Shared/HistoryTable';
import SearchComponent from '../Shared/Search';
import TransactionDetails from '../TransactionDetails';

import searchClose from '+assets/img/dashboard/search-close.svg';

import './index.scss';

const api = new APIRequest(process.env.REACT_APP_PUBLIC_MERCHANT_MIDDLEWARE_API_BASE);

function PayInComponent() {
  const { profile, permissions } = useStore();

  const { feedbackInit } = useFeedbackHandler();
  const searchQuery = useSearchQuery();

  const activeCurrency = searchQuery.value.currency || 'NGN';
  const limit = searchQuery.value.limit || '10';
  const paginationPage = searchQuery.value.page || '1';
  const status = searchQuery.value.status || undefined;
  const sorterType = searchQuery.value.sorterType || null;
  const isFilterVisible = Boolean(searchQuery.value.isFilterVisible);
  const dateFrom = searchQuery.value.dateFrom || durationMonth(3)[0];
  const dateTo = searchQuery.value.dateTo || durationMonth(3)[1];

  const sortingParams = {
    status: typeof status === 'string' ? [status] : status,
    dateFrom,
    dateTo,
    ...filteredOutObjectProperty(searchQuery.value, [
      queriesParams.currency,
      queriesParams.page,
      queriesParams.limit,
      queriesParams.sorterType,
      queriesParams.status,
      queriesParams.isFilterVisible
    ])
  };

  const [exportModalVisible, setExportModalVisible] = useState(false);
  const [showLargeExportModal, setLargeExportModal] = useState(false);
  const MERCHANT_ENV = useStore(store => store.merchantEnv);
  const availableCurrencies = useStore(store => store.availableCurrencies);

  const { data, isFetching, refetch } = useQuery(
    ['PAY_IN_TRANSACTIONS', paginationPage, limit, sortingParams, MERCHANT_ENV, activeCurrency],
    () => api.getTransactions('payins', paginationPage, limit, sortingParams, activeCurrency),
    {
      keepPreviousData: true,
      onError: () => {
        let message = 'There has been an error getting your pay-ins.';
        if (sorterType) message = `There has been an error in finding results for your ${sorterType}. `;
        feedbackInit({
          message,
          type: 'danger',
          action: {
            action: () => refetch(),
            name: 'Try again'
          }
        });
      }
    }
  );

  const transactions = data?.data || [];
  const paging = data?.paging;

  const exportFile = async (format, fieldToExport) => {
    const parameterizeArray = (key, arr) => {
      arr = arr.map(encodeURIComponent);
      return arr.join(`&${key}[]=`);
    };
    const fields = parameterizeArray('fieldsToExport', fieldToExport);

    try {
      const res = await api.exportTransactions('payins', sortingParams, format, activeCurrency, fields);
      if (res.status === 202) {
        setLargeExportModal(true);
      } else {
        const type = format === 'csv' ? 'csv' : 'xlsx';
        APIDownload(res, `Pay-ins at ${getDate(Date.now())}`, type);
        feedbackInit({
          message: `Pay-ins successfully downloaded`,
          type: 'success'
        });
      }
      setExportModalVisible(false);
    } catch (error) {
      setExportModalVisible(false);
      feedbackInit({
        message: `There has been an error exporting your pay-ins`,
        type: 'danger'
      });
    }
  };

  const { getDownload } = useExportDownloader('Payins');

  useEffect(() => {
    getDownload();
  }, []);

  const showClearButton =
    (searchQuery.value.dateFrom || searchQuery.value.dateTo) && Object.values(sortingParams || {}).filter(item => !!item).length > 0;

  return (
    <>
      <ExportFilterModal close={() => setLargeExportModal(false)} email={profile.email} visible={showLargeExportModal} />
      <div className="content-i">
        <section className="os-tabs-w">
          <div className="os-tabs-controls os-tabs-complex">
            <ul className="nav nav-tabs">
              {availableCurrencies?.map(currency => (
                <li className="nav-item" key={currency}>
                  <button
                    type="button"
                    className={`nav-link ${activeCurrency === currency ? 'active' : ''} ${
                      !availableCurrencies?.includes(currency) ? 'disabled' : ''
                    }`}
                    onClick={() => {
                      if (!availableCurrencies?.includes(currency)) return;
                      searchQuery.setQuery({ currency }, true);
                    }}
                  >
                    {currency}
                  </button>
                </li>
              ))}
            </ul>
          </div>
        </section>
        <div className="d-flex align-items-center justify-content-between payins__top-menu mb-2">
          <div className="d-flex align-items-center">
            {paging?.total_items > 0 && (
              <span className="--filtered-text">
                {paging?.total_items > 1 ? `${paging?.total_items} Transactions` : `${paging?.total_items} Transaction`}
                {showClearButton && ' (Filtered)'}
              </span>
            )}
            {!isFilterVisible && showClearButton && (
              <>
                <span className="divider-sm" />
                <button
                  type="button"
                  className="btn btn-sm btn-secondary clear-btn"
                  style={{ background: 'none', border: 'none', color: '#2376F3', fontWeight: 500 }}
                  onClick={() => searchQuery.clearAll()}
                >
                  <img src={searchClose} alt="close" aria-hidden />
                  <span>Clear</span>
                </button>
              </>
            )}
          </div>
          <div className="element-actions d-sm-block">
            <div className="search-filter-box">
              {permissions.payin === 'export' && (
                <>
                  <button
                    type="button"
                    className="btn btn-sm btn-secondary export-btn"
                    style={{ background: 'none', border: 'none', color: '#2376F3', fontWeight: 500 }}
                    hidden={transactions.length === 0}
                    onClick={() => setExportModalVisible(true)}
                  >
                    <i className="os-icon os-icon-arrow-up-right" />
                    <span>Export</span>
                  </button>
                  <span className="divider-sm" />
                </>
              )}
              {!isFilterVisible && (
                <SearchComponent
                  handleSearchQuery={param => {
                    if (param) return searchQuery.setQuery({ page: '1', sorterType: 'search', keyword: param });
                    return searchQuery.setQuery({ page: '1', sorterType: null, keyword: '' });
                  }}
                />
              )}
              <button
                className="btn btn-secondary btn-sm filter-btn"
                type="button"
                style={{ background: 'none', border: 'none', color: '#2376F3', fontWeight: 500 }}
                onClick={() => searchQuery.setQuery({ isFilterVisible: isFilterVisible ? undefined : true })}
              >
                <i className="os-icon os-icon-sliders" />
                <span>{isFilterVisible ? 'Hide' : 'Show'} Filter</span>
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-sm-12">
          <div className="element-wrapper">
            <FilterComponent
              handleFilterQuery={param => {
                searchQuery.setQuery({ page: 1, sorterType: 'filter', ...param }, true);
              }}
              type="pay-in"
              activeCurrency={activeCurrency}
            />
            <TransactionTableComponent
              type="pay-in"
              data={transactions}
              isFetching={isFetching}
              paging={paging || {}}
              emptyStateMessage={
                <>
                  <span>It seems there are no transactions here.</span>
                  <button type="button" className="refetch-button" onClick={() => refetch()}>
                    <i className="os-icon os-icon-rotate-ccw" style={{ marginRight: '5px' }} />
                    Refresh
                  </button>
                </>
              }
              emptyStateHeading="No transactions found"
              pagingAction={c => searchQuery.setQuery({ page: String(c) })}
              limitAction={c => searchQuery.setQuery({ limit: String(c) })}
            />
            {exportModalVisible && (
              <AdvanceExportModal
                openExport={exportModalVisible}
                setOpenExport={setExportModalVisible}
                exportAction={exportFile}
                type="pay-in"
                currency={activeCurrency}
                showSuccessModal={false}
              />
            )}{' '}
          </div>
        </div>
      </div>
    </>
  );
}

export default function PayInPage() {
  return (
    <Switch>
      <Route exact path="/dashboard/pay-ins" component={PayInComponent} />
      <Route path="/dashboard/pay-ins/:id">
        <TransactionDetails />
      </Route>
    </Switch>
  );
}
