const renderStyledJson = (data: Record<string, string>) => <pre>{renderObject(data)}</pre>;

const renderObject = (data: Record<string, string>) => {
  return (
    <pre>
      <span className="curly-brace">&#123;</span>
      {Object.entries(data).map(([key, value]) => {
        const tempValue = value !== null && value !== undefined ? value : 'null';
        return (
          <div key={key}>
            <span className="key">{JSON.stringify(key)}:</span>
            {typeof tempValue === 'object' ? (
              renderObject(tempValue)
            ) : (
              <span className={`value ${typeof value === 'number' && 'number'}`}>{JSON.stringify(tempValue)}</span>
            )}
          </div>
        );
      })}
      <span className="curly-brace">&#125;</span>
    </pre>
  );
};

export default renderStyledJson;
