interface IAlertBubbleProps {
  children: React.ReactNode;
  className?: string;
  variant?: 'yellow' | 'grey';
}

const AlertBubble: React.FC<IAlertBubbleProps> = ({ children, variant = 'yellow', className, ...props }) => {
  const color = variant === 'yellow' ? 'yellow' : 'grey';

  return (
    <div role="alert" data-alert-bubble-variant={color} className={className} {...props}>
      {children}
    </div>
  );
};

export default AlertBubble;
