/* eslint-disable camelcase */
/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect, useRef, useState } from 'react';
import { useQuery } from 'react-query';

import { useFeedbackHandler, useGetFee, useMobileValidation } from '+hooks';
import APIRequest from '+services/api-services';
import Modal from '+shared/Modal';
import useStore from '+store';
import { BankPayoutsModalProps, TError, TransferData, WithdrawalDetails } from '+types';
import {
  backwardAmountInput,
  capitalizeRemovedash,
  cleanInput,
  countryMobileCode,
  currencyToCountryCode,
  formatAmount,
  history,
  logBreadCrumb,
  logError,
  roundNumber,
  stripNonNumeric,
  twoFAInputValidation
} from '+utils';
import { breadCrumbEvents } from '+utils/bugsnag-events';

import WithdrawWarning from './WithdrawWarning';

const apiRequest = new APIRequest(process.env.REACT_APP_MERCHANT_MIDDLEWARE_API_BASE);

export default function MobilePayoutsModal({
  close,
  currency,
  balance = 0,
  initiateTransfer,
  processTransfer,
  resendOTP,
  paymentRef,
  identifier,
  maxPayoutLimit,
  minPayoutLimit,
  get2FAType,
  type: modalType
}: BankPayoutsModalProps) {
  const defaultMerchant = useStore((store: any) => store.defaultMerchant);
  const { feedbackInit, closeFeedback } = useFeedbackHandler();
  const timerInterval = useRef<NodeJS.Timeout | null>(null);
  const [type, setType] = useState('');
  const [codeSent, setCodeSent] = useState(true);
  const [time, setTime] = useState(0);
  const [twoFactorType, setTwoFactorType] = useState('otp');

  const { withdrawalDetails, setWithdrawalDetails, showMobileName } = useMobileValidation({ currency, maxPayoutLimit, minPayoutLimit });

  const transferData: TransferData = {
    two_factor_type: twoFactorType,
    reference: '',
    destination: {
      type: 'mobile_money',
      amount: withdrawalDetails.amount,
      narration: withdrawalDetails.description || 'Withdrawal from Merchant Balance',
      currency,
      mobile_money: {
        operator: withdrawalDetails.operatorCode,
        mobile_number: `${countryMobileCode[currency]}${withdrawalDetails.mobileNo}`
      },
      customer: {
        name: withdrawalDetails.username
      }
    }
  };

  const { data: mobileMoneyOperatorData, refetch } = useQuery(
    'mobile_money_network',
    () => apiRequest.fetchMobileMoneyNetwork(currencyToCountryCode[currency]),
    {
      onError: (error: TError) => {
        logError(error);
        feedbackInit({
          message: error.response?.data?.message || 'There has been an error getting network providers. Please refresh the page.',
          type: 'danger',
          componentLevel: true,
          action: {
            action: () => refetch(),
            name: 'Try again'
          }
        });
      },
      enabled: !!currency
    }
  );

  useEffect(() => {
    if (codeSent && type === 'otpStage') {
      if (timerInterval.current) {
        clearInterval(timerInterval.current);
      }
      timerInterval.current = setInterval(() => {
        if (time <= 0) {
          clearInterval(timerInterval.current);
          setCodeSent(false);
        } else {
          setTime(time - 1);
        }
      }, 1000);
    }
    return () => clearInterval(timerInterval.current!);
  }, [time]);

  useEffect(() => {
    setTwoFactorType(modalType);
  }, [modalType]);

  useEffect(() => {
    get2FAType && get2FAType(twoFactorType);
    setWithdrawalDetails((details: WithdrawalDetails) => ({ ...details, pin: '' }));
  }, [twoFactorType]);

  useEffect(() => {
    if (parseFloat(withdrawalDetails.amount) > maxPayoutLimit) {
      feedbackInit({
        message: `Your maximum payout limit is ${formatAmount(maxPayoutLimit)} ${currency} per transaction.`,
        type: 'error',
        componentLevel: true
      });
    } else {
      closeFeedback();
    }
  }, [withdrawalDetails.amount]);

  const setDisabled = () => {
    let error = true;
    const number = parseFloat(String(withdrawalDetails?.amount));

    switch (type) {
      case 'withdrawAmount':
        if (number >= minPayoutLimit && number <= maxPayoutLimit) {
          if (number <= parseFloat(balance) - (parseFloat(fee) || 0)) error = false;
        }

        if (['XAF', 'XOF'].includes(currency) && number % 5 !== 0) {
          error = true;
        }
        return error;
      case 'otpStage':
        if (twoFAInputValidation(twoFactorType, withdrawalDetails.pin)) return false;
        return true;
      default:
        const operatorCodeLength = withdrawalDetails.operatorCode.length;
        const mobileNumberLength = withdrawalDetails.mobileNo.length;
        const isCurrencyXOF = currency === 'XOF' && mobileNumberLength === 10;
        if (operatorCodeLength && ((mobileNumberLength === 9 && currency !== 'XOF') || isCurrencyXOF)) {
          return false;
        }
        if (currency === 'GHS' && showMobileName && withdrawalDetails.username) {
          return true;
        }
        return true;
    }
  };

  const { fee, fetchingFee, setFee } = useGetFee({ currency, currentAmount: withdrawalDetails.amount, channel: 'mobile_money' });

  const defaultWithdrawContent = () => {
    return (
      <div>
        <div className="form-group">
          <label htmlFor="operatorCode" className="withdraw-label">
            <span className="dark">Mobile Network</span>
          </label>
          <select
            aria-label="select"
            className="form-control"
            name="operatorCode"
            onChange={e => {
              const selectedOperator = mobileMoneyOperatorData?.data?.find((operator: any) => operator.slug === cleanInput(e.target.value));
              if (selectedOperator) {
                const { slug, code } = selectedOperator;
                setWithdrawalDetails((details: WithdrawalDetails) => ({ ...details, operatorCode: slug, mobileMoneyCode: code }));
              }
            }}
          >
            <option value="">Select a Mobile Network</option>
            {mobileMoneyOperatorData?.data?.map((operator: any) => (
              <option value={operator.slug} key={operator.id}>
                {operator.name}
              </option>
            ))}
          </select>
        </div>
        <div className="form-group">
          <label htmlFor="mobileNo" className="withdraw-label">
            <span className="dark">Mobile Number</span>
          </label>
          <div className="input-group">
            <div className="input-group-prepend">
              <div className="input-group-text">{`+${countryMobileCode[currency]}`}</div>
            </div>
            <input
              name="mobileNo"
              component="input"
              className="form-control"
              value={withdrawalDetails.mobileNo || ''}
              type="text"
              onChange={e => {
                const formattedInput = stripNonNumeric(e.target.value);
                setWithdrawalDetails((details: WithdrawalDetails) => ({ ...details, mobileNo: formattedInput }));
              }}
              placeholder="e.g 0123456789"
            />
          </div>
        </div>
        {['GHS'].includes(currency) ? (
          <>
            {showMobileName && withdrawalDetails.username && (
              <div className="form-group">
                <label htmlFor="username" className="withdraw-label">
                  <span className="dark">
                    Customer's Name <span style={{ opacity: 0.7 }} />
                  </span>
                </label>
                <input
                  rows="2"
                  maxLength="150"
                  value={withdrawalDetails.username}
                  className="form-control"
                  name="username"
                  placeholder="Customer's name"
                  disabled
                />
              </div>
            )}
          </>
        ) : (
          <div className="form-group">
            <label htmlFor="username" className="withdraw-label">
              <span className="dark">
                Customer's Name <span style={{ opacity: 0.7 }}>(optional)</span>
              </span>
            </label>
            <input
              rows="2"
              maxLength="150"
              value={withdrawalDetails.username}
              className="form-control"
              name="username"
              onChange={e => {
                const formattedInput = cleanInput(e.target.value);
                setWithdrawalDetails((details: WithdrawalDetails) => ({
                  ...details,
                  username: formattedInput
                }));
              }}
              placeholder="Customer's name"
            />
          </div>
        )}
        <span className="withdrawal_info p-2" style={{ marginBottom: '9px', display: 'block' }}>
          Please, ensure that the customer or recipient's details are correct to avoid any potential issue with the transaction.
        </span>
        <WithdrawWarning />
      </div>
    );
  };

  const withdrawAmountContent = () => {
    const amountTooLow = (withdrawalDetails.amount ?? 10) < minPayoutLimit;

    let info = (
      <>
        Minimum amount:{' '}
        <span className={`dark ${amountTooLow ? 'red' : ''}`} id="min">
          {currency} {formatAmount(minPayoutLimit)}
        </span>
      </>
    );

    if (!amountTooLow && withdrawalDetails.amount && withdrawalDetails.amount % 5 !== 0) {
      if (['XAF', 'XOF'].includes(currency)) {
        info = (
          <span className="dark red" id="min">
            Amount must be in multiples of 5,
            <br />
            e.g{' '}
            <strong>
              {roundNumber(withdrawalDetails.amount, 5, 'floor')} or {roundNumber(withdrawalDetails.amount, 5, 'ceil')}
            </strong>
          </span>
        );
      }
    }

    return (
      <>
        <div className="element-box withdraw-detail-container p-3">
          <div>
            <p>
              A{' '}
              <strong>
                minimum of {currency} {formatAmount(minPayoutLimit)}
              </strong>{' '}
              and a{' '}
              <strong>
                maximum of {currency} {formatAmount(maxPayoutLimit)}
              </strong>{' '}
              can be withdrawn in a single transaction.
            </p>
          </div>
        </div>
        <div className="form-group">
          <label htmlFor="amount" className="withdraw-label">
            <span className="dark">Amount to withdraw </span>
            <span className="small">
              Fee charged:{' '}
              {fetchingFee ? (
                <span
                  className="spinner-border spinner-border-sm"
                  style={{ opacity: '0.4', marginLeft: '5px' }}
                  role="status"
                  aria-hidden="true"
                />
              ) : (
                <span className="dark">{fee}</span>
              )}
            </span>
          </label>
          <input
            type="number"
            className="form-control"
            name="amount"
            value={withdrawalDetails.amount || ''}
            placeholder="eg 1,000.00"
            maxLength={11}
            onChange={e => {
              const formattedAmount = backwardAmountInput(e.target.value);
              setWithdrawalDetails((details: WithdrawalDetails) => ({ ...details, amount: formattedAmount }));
            }}
          />
          <label htmlFor="amount" className="withdraw-label mt-2 small">
            <span>{info}</span>
            <span>
              Available balance:{' '}
              <span className={`dark ${parseFloat(withdrawalDetails.amount) > balance && 'red'}`}>
                {currency} {formatAmount(balance)}
              </span>
            </span>
          </label>
        </div>
        <div className="form-group">
          <label htmlFor="description" className="withdraw-label">
            <span className="dark">
              Description <span style={{ opacity: 0.7 }}>(optional)</span>
            </span>
          </label>
          <input
            rows={2}
            maxLength={150}
            value={withdrawalDetails.description || ''}
            className="form-control"
            name="description"
            onChange={e => {
              const formattedInput = cleanInput(e.target.value);
              setWithdrawalDetails((details: WithdrawalDetails) => ({ ...details, description: formattedInput }));
            }}
            placeholder="Enter a description"
          />
        </div>
      </>
    );
  };

  const otpContent = () => {
    let email = defaultMerchant?.userEmail;
    if (email?.includes('@')) {
      email = email.split('@');
      email = `${email[0].slice(0, 3)}*******@${email[1]}`;
    }
    let inputLabel = 'One Time PIN (OTP)';
    let inputPlaceholder = '';
    let AuthenticationText;
    if (twoFactorType === 'totp') {
      inputLabel = 'Authentication Code';
      inputPlaceholder = 'Enter authentication code';
      AuthenticationText = (
        <>
          To proceed, enter the authorization code from your <b>authenticator app</b> in the space provided below to confirm this
          transaction.
        </>
      );
    } else if (twoFactorType === 'totp_recovery_code') {
      inputLabel = 'Recovery Code';
      inputPlaceholder = 'Enter recovery code';
    }
    return (
      <div className="bankForm-container">
        <div className="element-box mb-2 p-0">
          <div>
            {twoFactorType === 'totp_recovery_code' ? (
              <div className="saved-bank-container">
                <p>Can't use your authenticator app? Enter one of your previously saved recovery codes to validate this transaction.</p>
              </div>
            ) : (
              <div className="otp-form-container" style={{ color: '#F3F4F8', fontWeight: 400, fontSize: '12px' }}>
                <div className="otp-form-summary">
                  <p>Some important details of your payout:</p>
                  <div className="form-summary-item">
                    <span>Amount</span>
                    <span>
                      {' '}
                      {formatAmount(withdrawalDetails.amount || 0)} {currency}
                    </span>
                  </div>
                  <div className="form-summary-item">
                    <span>Customer’s name </span>
                    <span> {transferData.destination.customer.name || 'Not Provided'}</span>
                  </div>
                  <div className="form-summary-item">
                    <span>Type</span>
                    <span> {capitalizeRemovedash(transferData.destination.type)}</span>
                  </div>
                  <div className="form-summary-item">
                    <span>Current Balance</span>
                    <span>
                      {' '}
                      {formatAmount(balance || 0)} {currency}
                    </span>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
        <p className="auth-info-text">
          {twoFactorType === 'otp' ? (
            <>
              To proceed, enter the OTP (one-time PIN) that was sent to your email <b>({email})</b>.
            </>
          ) : (
            <>{AuthenticationText}</>
          )}
        </p>
        <label htmlFor="amount">
          <strong> {inputLabel}</strong>
        </label>
        <input
          type="number"
          name="pin"
          maxLength={7}
          value={withdrawalDetails.pin || ''}
          autoComplete="one-time-code"
          placeholder={inputPlaceholder}
          onChange={e => {
            const formattedInput = cleanInput(e.target.value);
            setWithdrawalDetails((details: WithdrawalDetails) => ({ ...details, pin: formattedInput }));
          }}
        />
        {twoFactorType === 'totp' && (
          <div className="recovery-code">
            Can't access authenticator app?{' '}
            <button type="button" onClick={() => setTwoFactorType('totp_recovery_code')} className="recovery-code-btn">
              Confirm using recovery codes
            </button>
          </div>
        )}
        {twoFactorType === 'otp' && (
          <label htmlFor="amount" className="withdraw-label mt-2 small">
            <span>
              <span className="dark">If you didn’t receive a code? </span>
              {!codeSent ? (
                <button
                  type="button"
                  className="btn btn-link"
                  onClick={async () => {
                    setCodeSent(true);
                    setTime(30);
                    await resendOTP.mutateAsync(
                      {
                        identifier
                      },
                      {
                        onError: error => {
                          logError(error);
                          feedbackInit({
                            message: error.response?.data?.message || 'Failed to resend OTP, Please try again',
                            type: 'warning',
                            componentLevel: true
                          });
                        }
                      }
                    );
                  }}
                >
                  Resend code.
                </button>
              ) : (
                <span style={{ marginLeft: '7px' }}>Resend code in {time} secs.</span>
              )}
            </span>
          </label>
        )}
      </div>
    );
  };

  const getOtpStageDescription = () => {
    return <p>Please review the withdrawal information and confirm that you want to proceed with this withdrawal.</p>;
  };

  const getOtpStageFirstBtn = () => {
    if (twoFactorType === 'totp_recovery_code') {
      return async () => {
        setTwoFactorType('totp');
      };
    }
    return undefined;
  };

  const switchWithdrawModal = (kind: string) => {
    let content;
    switch (kind) {
      case 'withdrawAmount':
        content = {
          heading: `Withdraw Funds to Mobile Money`,
          description: 'Please enter the details of the transaction to proceed.',
          content: withdrawAmountContent(),
          secondButtonText: 'Next',
          firstButtonText: 'Back',
          firstButtonAction: () => {
            setType('init');
          },
          secondButtonAction: async () => {
            logBreadCrumb({ event: breadCrumbEvents.balances.attemptWithdrawButton('Next', 'mobile money') });
            await initiateTransfer.mutateAsync(transferData);
            setType('otpStage');
            setTime(30);
          }
        };
        break;
      case 'otpStage':
        content = {
          heading: `Confirm ${twoFactorType === 'totp_recovery_code' ? 'using recovery code' : 'Withdrawal'}`,
          description: getOtpStageDescription(),
          content: otpContent(),
          firstButtonText: twoFactorType === 'totp_recovery_code' ? 'Back' : undefined,
          secondButtonText: 'Yes, Confirm',
          firstButtonAction: getOtpStageFirstBtn(),
          secondButtonAction: async () => {
            const verifyData = {
              payment_reference: paymentRef,
              auth_data: {
                two_factor_type: twoFactorType,
                identifier,
                code: withdrawalDetails.pin
              }
            };
            await processTransfer.mutateAsync(verifyData);
          },
          completedHeading: 'Success',
          completedDescription: `You have successfully withdrawn funds to a ${currency} mobile money number.`,
          completedActionText: 'View payment details',
          completedAction: () => history.push(`/dashboard/payouts/${paymentRef}`)
        };
        break;
      default:
        content = {
          heading: `Mobile Money Withdrawal`,
          description: 'Please enter the details of the transaction to proceed.',
          content: defaultWithdrawContent(),
          secondButtonText: 'Next',
          secondButtonAction: () => {
            closeFeedback();
            setType('withdrawAmount');
          }
        };
        break;
    }
    return {
      size: 'md',
      close: () => {
        setType('init');
        setFee('0.00');
        setWithdrawalDetails({
          bankEnquiry: {
            visible: null,
            message: null,
            type: null
          }
        });
        close();
      },
      secondButtonDisable: setDisabled(),
      secondButtonActionIsTerminal: kind === 'otpStage',
      ...content
    };
  };

  return <Modal {...switchWithdrawModal(type)} />;
}
