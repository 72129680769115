import { useEffect } from 'react';
import DatePicker from 'react-datepicker';
import dayjs from 'dayjs';
import { useSearchQuery, useReducerState } from '+hooks';
import { cleanInput, filteredOutObjectProperty } from '+utils';
import 'react-datepicker/dist/react-datepicker.css';
import arrowRight from '+assets/img/dashboard/arrow-right.svg';
import calendar from '+assets/img/dashboard/calendar.svg';
import search from '+assets/img/dashboard/search-thin.svg';

const paymentMethods = {
  NGN: {
    card: 'Card Payments',
    bank_transfer: 'Bank Transfer',
    virtual_bank_account: 'Virtual Bank Accounts',
    reserved_bank_account: 'Reserved Bank Account',
    pay_with_bank: 'Pay with Bank (Direct Debit)'
  },
  USD: {
    card: 'Card Payments'
  },
  KES: {
    card: 'Card Payments',
    mobile_money: 'Mobile Money'
  },
  GHS: {
    mobile_money: 'Mobile Money'
  }
};

const initialState = {
  paymentMethod: '',
  settlementReference: '',
  clearFilter: false,
  startDate: undefined,
  endDate: undefined
};

const showClearButtonKeys = [
  'paymentMethod',
  'expectedSettlementDateTo',
  'expectedSettlementDateFrom',
  'settlementReference',
  'dateSettledFrom',
  'dateSettledTo'
];

interface ISettlementsFilterProps {
  totalCount?: number;
  openExportModal?: () => void;
  userAccess?: string;
}

const SettlementsFilter = ({
  totalCount = 0,
  openExportModal = () => {},
  userAccess = ''
}: ISettlementsFilterProps) => {
  const searchQuery = useSearchQuery();
  const status = searchQuery.value.tab || 'pending';
  const activeCurrency = searchQuery.value.currency || 'NGN';

  const [state, setState] = useReducerState(initialState);

  useEffect(() => {
    const filterKeys = Object.keys(searchQuery.value);
    const showKeys = showClearButtonKeys.some(key => filterKeys.indexOf(key) !== -1);
    if (filterKeys.length > 0 && showKeys) {
      setState({
        clearFilter: true,
        paymentMethod: searchQuery.value.paymentMethod || '',
        settlementReference: searchQuery.value.settlementReference || '',
        startDate: searchQuery.value.expectedSettlementDateFrom || searchQuery.value.dateSettledFrom || null,
        endDate: searchQuery.value.expectedSettlementDateTo || searchQuery.value.dateSettledTo || null
      });
    } else {
      setState({ ...initialState, clearFilter: false });
    }
  }, [searchQuery.value]);

  const handleClearFilter = () => {
    setState(initialState);
    searchQuery.clearAll(['tab', 'page', 'currency']);
  };

  const filterTransactions = () => {
    const values = filteredOutObjectProperty(state, ['startDate', 'endDate', 'clearFilter']);
    if (status === 'pending') {
      return searchQuery.setQuery({
        expectedSettlementDateFrom: state.startDate,
        expectedSettlementDateTo: state.endDate,
        ...values
      });
    }
    if (status === 'settled') {
      return searchQuery.setQuery({
        ...values,
        dateSettledFrom: state.startDate,
        dateSettledTo: state.endDate
      });
    }
  };

  return (
    <section className="settlement-filter">
      <div className="settlement-filter__top">
        <div>
          <span>
            {totalCount} {totalCount === 1 ? 'Settlement' : 'Settlements'} {state.clearFilter && '(filtered results)'}
          </span>
          {state.clearFilter && (
            <>
              <span className="divider-sm" />
              <button type="button" onClick={() => handleClearFilter()}>
                {' '}
                <svg xmlns="http://www.w3.org/2000/svg" width="21" height="20" fill="none" viewBox="0 0 21 20" style={{ width: '1rem' }}>
                  <path
                    fill="#AABDCE"
                    d="M3.426 2.926c3.902-3.9 10.247-3.9 14.149 0 3.9 3.901 3.9 10.248 0 14.15A9.976 9.976 0 0110.5 20a9.975 9.975 0 01-7.074-2.924c-3.901-3.902-3.901-10.249 0-14.15zM6.374 12.95a.833.833 0 101.179 1.178L10.5 11.18l2.946 2.948a.835.835 0 001.18-1.18l-2.947-2.946 2.947-2.948a.833.833 0 10-1.179-1.179L10.5 8.822 7.553 5.874a.833.833 0 10-1.18 1.18L9.322 10l-2.947 2.948z"
                  />
                </svg>
                &nbsp;
                <span>Clear</span>
              </button>
            </>
          )}
        </div>
        <div>
          {(userAccess === 'manage' || userAccess === 'export') && (
            <button
              type="button"
              className="btn btn-secondary"
              onClick={openExportModal}
              style={{ background: 'none', border: 'none', color: '#2376F3', paddingRight: 0 }}
            >
              <i className="os-icon os-icon-arrow-up-right" />
              <span>Export Settlements</span>
            </button>
          )}
        </div>
      </div>

      <div className="settlement-filter__search-w filter-section">
        <div className="element-search-content filter-body w-100">
          <div className="form-group position-relative filter-object filter-object-xl flex-grow-1 w-auto --no-max-width --search-container">
            <img src={search} alt="search icon" aria-hidden />
            <input
              type="search"
              className="form-control"
              placeholder="Search by Settlement ID, customer name, anything ..."
              value={state.settlementReference}
              onChange={e => setState({ settlementReference: cleanInput(e.target.value) })}
              style={{ border: '1.5px solid #EAF2FE' }}
            />
          </div>

          <div className="form-group filter-object filter-object-ssm w-auto --no-max-width">
            <select
              name="type"
              className="form-control"
              onChange={e => setState({ paymentMethod: e.target.value })}
              value={state.paymentMethod}
              style={{ border: '1.5px solid #EAF2FE', width: '150px' }}
            >
              <option value="">Select method</option>
              {Object.keys(paymentMethods[activeCurrency] || {}).map(paymentMethod => (
                <option key={paymentMethod} value={paymentMethod}>
                  {paymentMethods[activeCurrency][paymentMethod]}
                </option>
              ))}
            </select>
          </div>

          <div
            className="form-group filter-object d-flex filter-object-sm w-auto"
            style={{ '--calendar-image': `url("${calendar}")`, minWidth: '300px' }}
          >
            <DatePicker
              selected={state.startDate ? new Date(state.startDate) : undefined}
              dateFormat="dd-MM-yyyy"
              popperPlacement="bottom-end"
              style={{ borderRadius: '0px 4px 4px 0px', border: '0.5px solid red' }}
              className="form-control date-select pl-4 date-picker"
              onChange={date => setState({ startDate: dayjs(dayjs(date)).format('YYYY-MM-DD') })}
              maxDate={new Date()}
              placeholderText="From"
              calendarClassName="custom-datepicker"
            />
            <DatePicker
              selected={state.endDate ? new Date(state.endDate) : undefined}
              dateFormat="dd-MM-yyyy"
              popperPlacement="bottom-end"
              style={{ borderRadius: '0px 4px 4px 0px' }}
              className="form-control date-select pl-4 date-picker"
              minDate={state.startDate ? new Date(state.startDate) : undefined}
              maxDate={new Date()}
              onChange={date => setState({ endDate: dayjs(dayjs(date)).format('YYYY-MM-DD') })}
              placeholderText="To"
              calendarClassName="custom-datepicker"
            />
          </div>

          <button
            aria-label="filter transactions"
            type="button"
            className="settlement-filter__filter-button"
            onClick={() => filterTransactions()}
            aria-controls="filtertransactions"
          >
            <img src={arrowRight} alt="arrow right icon" aria-hidden />
          </button>
        </div>
      </div>
    </section>
  );
}

export default SettlementsFilter;
