/* eslint-disable react/require-default-props */
import React from 'react';
import { Link } from 'react-router-dom';
import ImgEmptyState from '+assets/img/dashboard/empty-state.png';

export default function EmptyStateComponent({
  heading,
  message
}: {
  heading: string | React.ReactNode;
  message?: string | React.ReactNode;
}) {
  return (
    <div className="empty-state-content" data-testid="empty-state-content">
      <div className="empty-state-image">
        <img alt="Nothing" src={ImgEmptyState} loading="lazy" />
      </div>
      <p className="heading">{heading}</p>
      {message ? (
        <p>{message}</p>
      ) : (
        <p>
          It looks like there are no results yet. You can <Link to="/dashboard/home">make a payment</Link> &nbsp;or&nbsp;
          <Link to="/dashboard/payment-links/new">get paid</Link> easily.
        </p>
      )}
    </div>
  );
}
