import APIRequest from '+services/api-services';
import APIServiceError from '+services/error-services';

const merchantMiddlewareBaseURL = process.env.REACT_APP_MERCHANT_MIDDLEWARE_API_BASE || 'http://localhost:3000';
const merchantMiddlewareAPIRequest = new APIRequest(merchantMiddlewareBaseURL);

const useMerchantAuth = () => {
  const bootstrap = async () => await merchantMiddlewareAPIRequest.bootstrap();

  const updateMerchantEnv = async data => await merchantMiddlewareAPIRequest.updateMerchantEnv(data);

  const getProductAccess = async () => await merchantMiddlewareAPIRequest.getProductAccess();

  const handleLogout = (destination = '/') => merchantMiddlewareAPIRequest.logout(destination);

  const verifyGetStartedTokenTeam = async (data: string | null) => {
    try {
      const response = await merchantMiddlewareAPIRequest.verifyGetStartedTokenTeam(data);
      return response.data;
    } catch (error) {
      if (error instanceof APIServiceError) {
        throw error;
      }
      throw error;
    }
  };

  const completeGetStartedTeam = async (data: {
    verification_token: any;
    first_name: any;
    last_name: any;
    password: any;
    phone: string;
  }) => {
    try {
      const response = await merchantMiddlewareAPIRequest.completeGetStartedTeam(data);
      return response;
    } catch (error) {
      if (error instanceof APIServiceError) {
        throw error;
      }
      throw error;
    }
  };

  return { bootstrap, handleLogout, updateMerchantEnv, getProductAccess, verifyGetStartedTokenTeam, completeGetStartedTeam };
};

export default useMerchantAuth;
