import React, { useEffect, useState } from 'react';
import { useQuery, useQueryClient } from 'react-query';
import { AxiosError } from 'axios';

import CurrencyPicker from '+containers/Dashboard/Shared/CurrencyPicker';
import ProductFeatureDropdown from '+containers/Dashboard/Shared/ProductFeatureDropdown';
import { useSearchQuery } from '+hooks';
import useFeedbackHandler from '+hooks/feedbackHandler';
import APIRequest from '+services/api-services';
import useStore from '+store';
import { capitalize, formatPhoneNumber, getDate, logError, maskBetweenSetRange, switchCurrency } from '+utils';

import Copyable from '../../Shared/Copyable';
import Table from '../../Shared/Table';

import Info from '+assets/img/auth/info.svg';
import defaultUserLogo from '+assets/img/dashboard/default-company-logo.svg';
import DefaultIcon from '+assets/img/dashboard/star.svg';

import './index.scss';

const api = new APIRequest();

const GeneralComponent = () => {
  const queryClient = useQueryClient();
  const { feedbackInit } = useFeedbackHandler();
  const { authDetails, profile } = useStore();

  const MERCHANT_ENV = useStore(store => store.merchantEnv);
  const defaultMerchant = useStore(store => store.defaultMerchant);
  const defaultCurrency = useStore(state => state.defaultCurrency);
  const availableCurrencies = useStore(state => state.availableCurrencies);
  const EnabledCurrency = availableCurrencies;
  const searchQuery = useSearchQuery();
  const activeCurrency = searchQuery.value.currency ?? 'NGN';
  const setActiveCurrency = (currency: string) => searchQuery.setQuery({ currency });

  const merchantKyc = queryClient.getQueryData('MERCHANT_KYC');

  const userToken = authDetails?.access_token;
  const [userRole, setUserRole] = useState('');

  const { data, refetch, isFetching } = useQuery(['VIRTUAL_ACCOUNT', MERCHANT_ENV], api.fetchMerchantVirtualAccount, {
    enabled: MERCHANT_ENV === 'live',
    onError: (err: AxiosError<{ message: string }>) => {
      const error = err.response?.data;
      logError(error?.message);
      if (err?.response?.status !== 404) {
        feedbackInit({
          message: error?.message || 'There has been an error in getting your reserved bank account details',
          type: 'danger',
          action: {
            action: () => refetch(),
            name: 'Try again'
          }
        });
      }
    }
  });

  const { data: merchantLimit } = useQuery(['MERCHANT_PRODUCT_LIMIT'], () => api.getMerchantTieringData(), {
    onError: (error: AxiosError<{ message: string }>) => {
      feedbackInit({
        message: error?.response?.data?.message || `There has been an error fetching the product limit for this merchant.`,
        type: 'danger'
      });
    }
  });

  const virtualAccounts = data?.data || {};

  const sortedRBAs = virtualAccounts?.reserved_bank_accounts?.sort(a => {
    if (a?.preferred) return -1;
    return 1;
  });

  const handleImageErr = e => {
    e.target.src = defaultUserLogo;
  };

  useEffect(() => {
    if (userToken) {
      const jwt = JSON.parse(atob(userToken.split('.')[1]));
      setUserRole(jwt?.permissions?.name || '');
    }
  }, [userToken]);

  return (
    <div className="general-settings active" id="profileTab">
      <section className="element-box gs-user-profile-w gs-element-box">
        <div className="user-profile">
          <div className="up-head-w">
            <div className="up-main-info light">
              <div className="user-avatar-w">
                <div className="user-avatar">
                  <img alt="" src={profile?.avatar || defaultUserLogo} onError={handleImageErr} />
                </div>
              </div>
              <div className="up-heading">
                <h1 className="up-header">
                  {profile?.firstName || ''} {profile?.lastName || ''}
                </h1>
                <h5 className="up-sub-header">{userRole || ''}</h5>
                <h5 className="up-sub-header dark">{defaultMerchant?.name || ''}</h5>
              </div>
            </div>
            {/* <button type="button" className="up-edit-button">
              Edit Profile
            </button> */}
          </div>

          <div className="up-details">
            <div>
              <h6>Email</h6>
              <p className={profile?.email ? 'active' : null}>{profile?.email || 'Email not provided'}</p>
            </div>
            <div>
              <h6>Website</h6>
              {merchantKyc?.details?.business_profile?.website ? (
                <a href={merchantKyc.details.business_profile.website} target="_blank" rel="noopener noreferrer" className="website-link">
                  {merchantKyc?.details?.business_profile?.website}
                </a>
              ) : (
                <p>Website not provided</p>
              )}
            </div>
            <div>
              <h6>Phone</h6>
              <p className={profile?.phone ? 'active' : null}>
                {profile?.phone ? formatPhoneNumber(profile?.phone) : 'Phone not provided'}
              </p>
            </div>
            <div className="up-details--description">
              <h6>About</h6>
              <p className={defaultMerchant?.description ? 'active' : null} title={defaultMerchant?.description}>
                {defaultMerchant?.description || 'No information provided'}
              </p>
            </div>
          </div>

          <div className="up-joined-date">
            <p>Joined: {getDate(profile?.created)}</p>
          </div>
        </div>
      </section>

      <section className="gs-scroll-w">
        <div className="element-box gs-element-box">
          <div className="gs-info">
            <h4 className="settings-header">General Business Settings</h4>

            <div className="settings-box">
              <div className="settings-group">
                <div className="settings-item gs-item">
                  <div className="settings-item-info gs-item-info">
                    <div className="info-title">Currencies</div>
                    <div className="info-desc">
                      These are all the currencies with which you are allowed to transact on Korapay. Your currencies are determined by the
                      compliance check done on your account. Please, contact your account manager to find out more.
                    </div>

                    <div className="available-currency">
                      <div className="gs-pills">
                        {availableCurrencies?.map(currency => (
                          <div key={currency}>
                            {defaultCurrency === currency && <img src={DefaultIcon} alt="star" className="default-star" />}
                            <span>{switchCurrency[currency]}</span>
                          </div>
                        ))}
                      </div>

                      <div className="default-currency-info align-items-center">
                        <img src={DefaultIcon} alt="star" className="default-star" />
                        <span>Default Currency</span>
                        <img src={Info} alt="star" />
                      </div>
                    </div>
                  </div>
                </div>
                <legend>
                  <span />
                </legend>

                <div className="settings-item gs-item">
                  <div className="settings-item-info gs-item-info">
                    <div className="info-title">Products</div>
                    <div className="info-desc">
                      These are the products on Korapay to which you have access. Your product offerings are determined by the compliance
                      check that was done on your account. Please, contact your account manager to learn more.
                    </div>
                    <div className="mt-3 ml-n3">
                      <CurrencyPicker
                        options={EnabledCurrency}
                        onChange={value => {
                          setActiveCurrency(value);
                        }}
                        activeCurrency={activeCurrency}
                        id="balances__currency-switch"
                        allowedOptions={['NGN']}
                        showDropDown={false}
                      />
                    </div>
                    <div className="gs-pills">
                      <div>Pay-in API</div>
                      <div>Pay-ins via Checkout</div>
                      <div>Pay-out API</div>
                    </div>
                  </div>
                  <div className="row mt-3 ">
                    <div className="col-md-12">
                      <ProductFeatureDropdown details={merchantLimit?.data} currency={activeCurrency} />
                    </div>
                  </div>
                </div>

                {MERCHANT_ENV === 'live' && (
                  <>
                    <legend>
                      <span />
                    </legend>

                    <div className="settings-item gs-item">
                      <div className="settings-item-info gs-item-info">
                        <div className="info-title">Reserved Bank Accounts</div>
                        <div className="info-desc">
                          Use your Reserved Bank Account (RBA) to fund your balance at any time. RBAs are real bank accounts that are linked
                          to the BVN you provided during your Compliance Check.
                        </div>
                      </div>

                      <div className="vba-container">
                        <Table
                          tableClassName="vba-table"
                          // borderedTable={false}
                          headings={[
                            {
                              value: 'Name'
                            },
                            {
                              value: 'Bank'
                            },
                            {
                              value: 'Account Number'
                            },
                            {
                              value: 'BVN',
                              className: 'text-uppercaase'
                            }
                          ]}
                          loading={isFetching}
                          emptyStateMessage="No Reserved Bank Account available."
                          children={
                            <>
                              {sortedRBAs?.map(virtualAccount => (
                                <div key={virtualAccount?.account_number} className="vba-table --row">
                                  <div className="--txn-status">
                                    <p className="mobile-heading">Name</p>
                                    <p className="content">{virtualAccount?.account_name || 'Not Available'}</p>
                                  </div>
                                  <div>
                                    <p className="mobile-heading">Bank</p>
                                    <p className="content">{capitalize(virtualAccount?.bank_name || 'Not Available')}</p>
                                  </div>
                                  <div>
                                    <p className="mobile-heading">Account Number</p>
                                    <p className="content acc-number --hover">
                                      <Copyable text={virtualAccount?.account_number || 'Not Available'} />
                                    </p>
                                  </div>
                                  <div>
                                    <p className="mobile-heading">BVN</p>
                                    <p className="content">
                                      {defaultMerchant?.settlement_bank?.bvn
                                        ? maskBetweenSetRange(defaultMerchant.settlement_bank.bvn, 1, 8)
                                        : 'Not Available'}
                                    </p>
                                  </div>
                                </div>
                              ))}
                            </>
                          }
                          tableWrapperClassName="vba-container sub"
                        />
                      </div>
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default React.memo(GeneralComponent);
