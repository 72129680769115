import React from 'react';
import Filesaver from 'file-saver';
import { usePDF } from '@react-pdf/renderer';
import RecoveryCodeComponent from './RecoveryCodeComponent';

const DownloadRecoveryCodes = ({ recoveryCodes, setHasDownload, fetchTwoFAHistory, hasDownload }) => {
  const codesDoc = <RecoveryCodeComponent data={recoveryCodes} />;
  const [docInstance] = usePDF({ document: codesDoc });


  const blob = docInstance.blob || '';
  return (
    <>
      <div className="recovery-codes-section">
        <div className="codes-description">
          <h2 className="form-header">Two Factor Enabled</h2>
          <p>
            As an alternative, backup codes can be used to gain a separate access to your account. <br /> Backup codes can be used in place
            of your main authentication to login to your dashboard. <br /> It is important to keep them safe, because these codes are only
            revealed once.
          </p>
        </div>
        <p>Backup Codes</p>
        <div className="recovery-codes col-md-9 col-xl-6">
          <p>Download the emergency backup codes provided below and keep them in a safe place.</p>
          <div className="codes">
            {recoveryCodes?.map((code, index) => {
              return <div key={index}>{code}</div>;
            })}
          </div>

          <button
            type="button"
            className="btn btn-link mt-4"
            onClick={() => {
              Filesaver.saveAs(blob, 'Recovery Codes');
              setHasDownload(true);
            }}
          >
            <i className="os-icon os-icon-download" />
            <span>Download</span>
          </button>
        </div>
      </div>

      <div className="button-row mt-4">
        <button
          className="btn"
          type="button"
          disabled={!hasDownload}
          style={{ borderColor: '#24B314', color: 'white', backgroundColor: '#24B314', width: 'auto' }}
          onClick={fetchTwoFAHistory}
        >
          Done
        </button>
      </div>
    </>
  );
};

export default DownloadRecoveryCodes;
