import { useMutation } from 'react-query';
import { AxiosError } from 'axios';
import APIRequest from '+services/api-services';
import { useFeedbackHandler } from '+hooks';
import { logError } from '+utils';
import { IConversionsQueryData } from '+types'

const publicApi = new APIRequest(process.env.REACT_APP_PUBLIC_MERCHANT_MIDDLEWARE_API_BASE);


const useProcessConversion = (
  conversionsQueryData: IConversionsQueryData,
  onConversionSuccess?: (success: boolean) => void
) => {
  const { feedbackInit } = useFeedbackHandler();

  const { mutateAsync: processConversionMutation } = useMutation(
    () => publicApi.processConversion(conversionsQueryData),
    {
      onSuccess: async (res) => {
        const response = res?.data;
        await response;
        if (onConversionSuccess) {
          onConversionSuccess(true);
        }
      },
      onError: (e: Error | AxiosError) => {
        logError(e);
        let errMsg = e.response?.data?.message;
        if (errMsg === 'invalid request data') {
          const errors = e.response?.data?.data;
          const keys = Object.keys(errors);
          if (keys.length) {
            errMsg = errors[keys[0]]?.message || errMsg;
          }
        }
        feedbackInit({
          componentLevel: true,
          message: errMsg || 'We were unable to process wallet funding. Please try again',
          type: 'danger'
        });
        if (onConversionSuccess) {
          onConversionSuccess(false);
        }
      }
    }
  );

  return { processConversionMutation };
};

export default useProcessConversion;
