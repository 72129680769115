import { useFeedbackHandler } from '+hooks';
import { APIDownload, getDate, logError } from '+utils';

interface UseFetchIssuingExport {
  resourceType: string;
  cardId?: string | number;
  exportFn?: (arg?: any) => Promise<{ [x: string]: any }>;
  currency?: string;
}

export default function useFetchIssuingExport({ resourceType, cardId, exportFn, currency }: UseFetchIssuingExport) {
  const { feedbackInit } = useFeedbackHandler();
  let exportAction;

  if (exportFn) {
    exportAction = async (format: 'csv' | 'xlsx', fieldsToExport: any, close: () => void) => {
      try {
        const resource = await exportFn({ cardId, fieldsToExport, format, currency });
        if (resource.status === 202) {
          return { handleLargeExports: true };
        }
        const type = format === 'csv' ? 'csv' : 'xlsx';
        APIDownload(resource, `${resourceType}-${getDate(Date.now())}`, type);
        feedbackInit({
          message: 'Successfully exported',
          type: 'success'
        });
        close();
      } catch (err) {
        logError(err);
        feedbackInit({
          message: 'There has been an error completing your export',
          type: 'danger',
          componentLevel: true
        });
      }
    };
  }

  return exportAction;
}
