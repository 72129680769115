import { twoFAInputValidation } from '+utils';

import { IFormikValues, StageValidationType } from '../types';

export const stageValidationOptions: Partial<StageValidationType<IFormikValues>> = {
  CREATE: formValues => {
    const { cardScheme, cardLabel } = formValues;
    const errors: Partial<IFormikValues> = {};

    if (!cardScheme) errors.cardScheme = 'Please select a card scheme';
    if (!cardLabel) errors.cardLabel = 'Please provide a label';

    return errors;
  },
  MANAGE: formValues => {
    const { limitPeriod, maxSpend } = formValues;
    const errors: Partial<IFormikValues> = {};

    if (!limitPeriod) errors.limitPeriod = 'Please select a limit period';
    if (!maxSpend) errors.maxSpend = 'Please enter a maximum spend';

    return errors;
  },
  CONFIRM: (formValues, authType, otpLabel) => {
    const { otp } = formValues;
    const errors: Partial<IFormikValues> = {};

    if (!twoFAInputValidation(authType, otp)) errors.otp = `Please enter a valid ${otpLabel?.toLowerCase()}`;

    return errors;
  }
};

export const currencyOptions = [
  { label: '- Select currency -', value: '' },
  { label: 'USD', value: 'USD' }
];

export const cardSchemeOptions = [
  { label: '- Select card scheme -', value: '' },
  { label: 'Mastercard', value: 'mastercard' },
  { label: 'Visa', value: 'visa' }
];

export const limitPeriodOptions = [
  { label: '- Select period -', value: '' },
  { label: 'Daily', value: 'daily' },
  { label: 'Weekly', value: 'weekly' },
  { label: 'Monthly', value: 'monthly' }
];

export const cardColorOptions = [
  { label: 'orange', value: '234.22deg, hsl(40, 100%, 64%) 2.32%, hsl(331, 87%, 31%) 138.6%' },
  { label: 'dark green', value: '222.14deg, hsl(187, 60%, 34%) -26.33%, hsl(217, 100%, 5%) 101.8%' },
  { label: 'purple', value: '220.68deg, hsl(253, 60%, 34%) 1.85%, hsl(217, 100%, 5%) 103.06%' }
];
