import React, { useState } from 'react';
import { useCSVReader } from 'react-papaparse';
import { capitalize } from '+utils';
import RoundUpload from '+assets/img/dashboard/round-upload.svg';
import UploadCancel from '+assets/img/dashboard/upload-cancel.svg';
import UploadFile from '+assets/img/dashboard/csv-file.svg';
import './index.scss';

type CSVParsedDataT = Array<string[]>;

type CSVParsedResponse = {
  data: CSVParsedDataT;
  meta: string[];
  error: string[];
};

interface CSVFileReaderProps {
  onChange: (data: CSVParsedDataT) => void;
}

const CSVFileReader: React.FC<CSVFileReaderProps> = ({ onChange }) => {
  const { CSVReader } = useCSVReader();
  const [hoverZone, setHoverZone] = useState(false);

  return (
    <CSVReader
      onUploadAccepted={(results: CSVParsedResponse) => {
        if (onChange) {
          onChange(results.data);
        }
        setHoverZone(false);
      }}
      config={{
        transform: (value: string) => (value === '' ? null : value)
      }}
    >
      {({ getRootProps, acceptedFile, ProgressBar, getRemoveFileProps }: any) => {
        const removeFile = e => {
          getRemoveFileProps().onClick(e);
          onChange([]);
        };
        return (
          <>
            <div className="upload_container">
              <div className="upload_container">
                <div className={`csv_upload_container ${acceptedFile ? '-selected' : ''}`}>
                  <div className={`csv_upload_container-content ${acceptedFile || hoverZone ? '-selected' : ''}`}>
                    {!acceptedFile ? (
                      <div
                        data-testid="file-upload"
                        className="csv_upload_info"
                        {...getRootProps()}
                        onDragOver={(event: DragEvent) => {
                          event.preventDefault();
                          setHoverZone(true);
                        }}
                        onDragLeave={(event: DragEvent) => {
                          event.preventDefault();
                          setHoverZone(false);
                        }}
                      >
                        <span>
                          <img src={RoundUpload} alt="upload-icon" />{' '}
                        </span>
                        <p className="csv_upload_info_text">Drop your file here or click to browse</p>
                      </div>
                    ) : (
                      <>
                        <span className="csv_upload_info-wrapper">
                          <div className="csv_upload_info-selected">
                            <span>
                              <img src={UploadFile} alt="upload-file" />
                            </span>
                            <p className="csv_upload_info-selected_filename">{capitalize(acceptedFile.name)}</p>
                          </div>
                          <span onClick={removeFile}>
                            <span role="button" tabIndex={0}>
                              <img src={UploadCancel} alt="upload-cancel" />
                            </span>
                          </span>
                        </span>
                        <ProgressBar style={{ backgroundColor: 'green' }} />
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </>
        );
      }}
    </CSVReader>
  );
};

export default CSVFileReader;
