
export const UnitedStateBanks = [
  "Ally Bank",
  "Ally Financials",
  "Ameris Bancorp",
  "Arvest Bank",
  "Associated Banc-Corp",
  "Axos Bank",
  "Bank of America",
  "Bank of America Merrill Lynch",
  "Bank of Hawaii",
  "Bank of Montreal (BMO)",
  "Barclays US",
  "Beal Bank",
  "BNP Paribas (USA)",
  "BOK Financial Corporation",
  "Capital One 360",
  "Capital One Financial Corp.",
  "Cathay Bank (acquired HSBC Bank USA)",
  "Central Bancompany",
  "Charles Schwab Corporation",
  "Chime Bank",
  "CIBC Bank USA",
  "Citi Group Global Markets",
  "Citi Group Inc.",
  "Citizens Financial Group",
  "Columbia Bank",
  "Comerica Bank",
  "Commerce Bancshares",
  "Commerzbank",
  "Credit Suisse",
  "Credit Suisse Holdings (USA) Inc.",
  "Customers Bancorp",
  "Deutsche Bank",
  "Discover Bank",
  "Discover Financial Services",
  "DSRM National Bank",
  "East West Bank",
  "Eastern Bank",
  "Evercore Partners",
  "Fifth Third Bancorp",
  "First BanCorp",
  "First Citizens BancShares",
  "First Citizens National Bank",
  "First Hawaiian Bank",
  "First Interstate BancSystem",
  "First State Community Bank",
  "Fulton Financial Corporation",
  "Glacier Bancorp",
  "Goldman Sachs Asset Management",
  "Goldman Sachs Group, Inc",
  "Hancock Whitney Bank",
  "Heartland Financial USA",
  "Home BancShares (commonly called Centennial bank)",
  "Hope Bancorp",
  "HSBC Bank USA",
  "Huntington Bancshares",
  "Independent Bank Corp.",
  "ING Bank",
  "Intesa Sanpaolo",
  "Jefferies Financial Group",
  "JP Morgan Chase & Co.",
  "KeyBank",
  "Lazard",
  "Lending Club Bank (acquired Radius bank)",
  "M&T Bank",
  "Marcus by Goldman Sachs",
  "Mizuho Financial Group",
  "Moelis & Company",
  "Morgan Stanley",
  "MUFG Americas Holdings Corporation",
  "NBKC Bank",
  "New York Community Bank",
  "Nomura Securities International",
  "Northern Trust Corporation",
  "Old National Bank",
  "Pacific Premier Trust",
  "PacWest Bancorp (to be merged with Bank of California)",
  "Penns Woods Bancorp",
  "Pilot Grove Savings bank",
  "Pinnacle Financial Partners",
  "Piper Sandler Companies",
  "PNC Financial Services Group",
  "Prosperity Bancshares",
  "Raymond James Financial",
  "Regions Financial Corporation",
  "Santander Bank",
  "Simmons Bank",
  "Société Générale (North America)",
  "SoFi Bank",
  "South State Bank",
  "Southern Missouri Bancorp",
  "Standard Chartered USA",
  "Stifel Financial Corp.",
  "Sumitomo Mitsui Banking Corporation (SMBC)",
  "TD (Toronto Dominion) Bank",
  "TD Cowen Inc.",
  "Texas Capital Bank",
  "The Bank of New York (BNY) Mellon",
  "The Peoples State bank",
  "Tompkins Community Bank",
  "Tri-County Bank",
  "Truist Financial Corp.",
  "U.S. Bancorp",
  "UBS",
  "UBS Investment Bank",
  "United Bank (West Virginia)",
  "United Community Bank",
  "US Bancorp",
  "Valley National Bank",
  "Varo Bank",
  "Washington Federal",
  "Webster Bank",
  "Wells Fargo & Co.",
  "Wells Fargo Securities",
  "Wesbanco Bank",
  "Western Alliance Bancorporation",
  "Wintrust Financial",
];

export const UnitedKingdomBanks = [
  "ADIB (UK) Ltd",
  "Ahli United Bank (UK) plc",
  "AIB Group (UK) plc",
  "Al Rayan Bank plc",
  "Aldermore Bank plc",
  "Alliance Trust Savings Limited",
  "Allica Bank",
  "Alpha Bank London Limited",
  "ANZ Bank (Europe) Limited",
  "Arbuthnot Latham & Co Limited",
  "Atom Bank",
  "Atom Bank plc",
  "Axis Bank UK Limited",
  "Bank and Clients plc",
  "Bank Leumi (UK) plc",
  "Bank Mandiri (Europe) Limited",
  "Bank of America Merrill Lynch International Limited",
  "Bank of Baroda (UK) Ltd",
  "Bank of Beirut (UK) Ltd",
  "Bank of Ceylon (UK) Ltd",
  "Bank of China (UK) Ltd",
  "Bank of Communications (UK) Limited",
  "Bank of Cyprus UK Limited",
  "Bank of India UK",
  "Bank of Ireland (UK) Plc",
  "Bank of Ireland UK",
  "Bank of London and The Middle East plc",
  "Bank of London, The",
  "Bank of Scotland plc",
  "Bank of the Philippine Islands (Europe) plc",
  "Bank Saderat plc",
  "Bank Sepah International plc",
  "Barclays",
  "Barclays Bank plc",
  "Barclays Bank UK plc",
  "BIRA Bank Ltd",
  "BMCE Bank International plc",
  "British Arab Commercial Bank plc",
  "Brown Shipley & Co Limited",
  "C. Hoare & Co",
  "CAF Bank Ltd",
  "Cambridge & Counties Bank Limited",
  "Cashplus Bank",
  "Cater Allen Limited",
  "Charity Bank Limited",
  "Charter Court Financial Services Limited",
  "China Construction Bank (London) Limited",
  "CIBC World Markets plc",
  "ClearBank Ltd",
  "Close Brothers Limited",
  "Clydesdale Bank plc",
  "Coutts & Company",
  "Credit Suisse (UK) Limited",
  "Credit Suisse International",
  "Crown Agents Bank Limited",
  "Danske Bank UK",
  "DB UK Bank Limited",
  "Diamond Bank (UK) plc",
  "Duncan Lawrie Limited",
  "EFG Private Bank Limited",
  "Europe Arab Bank plc",
  "FBN Bank (UK) Ltd",
  "FCE Bank plc",
  "FCMB Bank (UK) Limited",
  "Fiinu Bank Limited",
  "Gatehouse Bank plc",
  "GE Capital Bank Limited",
  "Ghana International Bank plc",
  "Goldman Sachs International Bank",
  "Griffin",
  "Guaranty Trust Bank (UK) Limited",
  "Gulf International Bank (UK) Limited",
  "Habib Bank Zurich Plc",
  "Habibsons Bank Limited",
  "Hampden & Co Plc",
  "Hampshire Trust Bank Plc",
  "Havin Bank Ltd",
  "HBL Bank UK",
  "HSBC Bank Plc",
  "HSBC Holdings",
  "HSBC Private Bank (UK) Limited",
  "HSBC Trust Company (UK) Ltd",
  "HSBC UK",
  "ICBC (London) plc",
  "ICBC Standard Bank Plc",
  "ICICI Bank UK Plc",
  "iFAST Global Bank",
  "Investec Bank PLC",
  "Itau BBA International PLC",
  "J.P. Morgan Europe Limited",
  "J.P. Morgan International Bank Limited",
  "J.P. Morgan Securities plc",
  "Jordan International Bank Plc",
  "Julian Hodge Bank Limited",
  "KEB Hana Bank",
  "Kexim Bank (UK) Ltd",
  "Kingdom Bank Ltd",
  "Kookmin Bank International Limited",
  "Lloyds Bank Plc",
  "Lloyds Bank Private Banking Limited",
  "Lloyds Banking Group",
  "Macquarie Bank International Ltd",
  "Marks & Spencer Financial Services Plc",
  "Masthaven Bank Limited",
  "Melli Bank plc",
  "Methodist Chapel Aid Limited",
  "Metro Bank",
  "Metro Bank PLC",
  "Mizuho International Plc",
  "Monument Bank Limited",
  "Monzo",
  "Monzo Bank Ltd",
  "Morgan Stanley Bank International Limited",
  "MUFG",
  "National Bank of Egypt (UK) Limited",
  "National Bank of Kuwait (International) Plc",
  "National Westminster Bank Plc",
  "NatWest Group",
  "Nomura Bank International Plc",
  "Northern Bank Limited",
  "Northern Trust Global Services Ltd",
  "OakNorth Bank Limited",
  "OneSavings Bank Plc",
  "Paragon Bank Plc",
  "PCF Group Plc",
  "Perenna Bank Plc",
  "Persia International Bank Plc",
  "Philippine National Bank (Europe) Plc",
  "Punjab National Bank (International) Limited",
  "QIB (UK) Plc",
  "R. Raphael & Sons Plc",
  "Rathbone Investment Management Limited",
  "RBC Europe Limited",
  "Reliance Bank Ltd",
  "Royal Bank of Scotland Plc, The",
  "Sainsbury's Bank Plc",
  "Santander UK",
  "Santander UK Plc",
  "Schroder & Co Ltd",
  "Scotiabank Europe Plc",
  "Scottish Widows Bank Plc",
  "Secure Trust Bank Plc",
  "SG Kleinwort Hambros Bank Limited",
  "Shawbrook Bank Limited",
  "Silicon Valley Bank UK Limited",
  "Smith & Williamson Investment Services Limited",
  "Sonali Bank (UK) Limited",
  "Standard Chartered Bank",
  "Starling Bank",
  "Starling Bank Limited",
  "State Bank of India (UK) Limited",
  "Sumitomo Mitsui Banking Corporation Europe Limited",
  "Tandem Bank",
  "Tandem Bank Limited",
  "TD Bank Europe Limited",
  "Tesco Personal Finance Plc",
  "The Access Bank UK Limited",
  "The Bank of New York Mellon (International) Limited",
  "The Co-operative Bank",
  "The Co-operative Bank plc",
  "TSB Bank",
  "TSB Bank plc",
  "Turkish Bank (UK) Ltd",
  "UBS Limited",
  "Ulster Bank Ltd",
  "Union Bank of India (UK) Limited",
  "Union Bank UK Plc",
  "United National Bank Limited",
  "United Trust Bank Limited",
  "Unity Trust Bank Plc",
  "Vanquis Bank Limited",
  "Virgin Money",
  "Virgin Money UK",
  "VTB Capital plc",
  "Weatherbys Bank Limited",
  "Wesleyan Bank Limited",
  "Westpac Europe Ltd",
  "Wyelands Bank Plc",
  "Yorkshire Bank",
  "Zenith Bank (UK) Limited",
  "Zopa Bank Ltd"
]
