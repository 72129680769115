import styled from 'styled-components';

import { history } from '+utils';

const LinkStyle = styled.span`
  align-self: center;

  a {
    align-self: center;
  }
`;

interface IButtonProps {
  className: string;
  title: string;
  type: string;
  disabled: boolean;
  onClick: () => void;
  to: string;
  style: any;
}

function Button({
  className,
  title,
  type,
  disabled,
  style,
  to,
  onClick
}: IButtonProps) {
  const goTo = (page: string) => {
    return history.push(`${page}`);
  };

  let btnClassName;
  switch (type) {
    case 'link':
      btnClassName = 'btn--link';
      return (
        <LinkStyle>
          <button className={`btn ${btnClassName} ${className}`} onClick={() => goTo(to)} type="button" style={style} disabled={disabled}>
            {title}
          </button>
        </LinkStyle>
      );
    case 'full':
      btnClassName = '--full';
      break;
    case 'secondary-green':
      btnClassName = '--sec-green';
      break;
    case 'secondary-blue':
      btnClassName = '--sec-blue';
      break;
    case 'auth-form':
      btnClassName = '--auth-submit-form';
      break;
    default:
      btnClassName = '';
  }

  return to ? (
    <LinkStyle>
      <button className={`btn ${btnClassName} ${className}`} type="button" onClick={() => goTo(to)} style={style} disabled={disabled}>
        {title}
      </button>
    </LinkStyle>
  ) : (
    <LinkStyle>
      <button
        className={`btn ${btnClassName} ${className}`}
        type="submit"
        style={style}
        onClick={event => onClick(event)}
        disabled={disabled}
      >
        {title}
      </button>
    </LinkStyle>
  );
}

export default Button;
