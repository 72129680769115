/* eslint-disable camelcase */
/* eslint-disable react/jsx-props-no-spreading */
import { useEffect, useState } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { AxiosError } from 'axios';
import { useFormik } from 'formik';

import CountdownTimer from '+dashboard/Shared/CountdownTimer';
import CustomCheckbox from '+dashboard/Shared/CustomCheckbox';
import Modal, { IModalProps } from '+dashboard/Shared/Modal';
import { useFeedbackHandler, useOTPAuth } from '+hooks';
import APIRequest from '+services/api-services';
import useStore from '+store';
import { backwardAmountInput, filterUserInput, formatAmount, logError, maskEmail, stripNonNumeric, twoFAInputValidation } from '+utils';

import { useManageCardActions } from '../hooks';
import { CardOpsModalStageType, ICardOpsModal, OtpResponseType } from '../types';
import { getOtpDescriptors } from '../utils';

const publicApi = new APIRequest(process.env.REACT_APP_PUBLIC_MERCHANT_MIDDLEWARE_API_BASE);
const MIN_CHAR_LENGTH = 3;
const MAX_CHAR_LENGTH = 100;

export default function CardOperationsModal({
  onClose = () => null,
  cardId,
  action: operation,
  balance,
  currency,
  suspensionReason
}: ICardOpsModal) {
  const {
    profile,
    defaultMerchant: { email: merchantEmail }
  } = useStore() as { profile: { firstName: string; lastName: string }; defaultMerchant: { email: string } };
  const queryClient = useQueryClient();
  const [stage, setStage] = useState<CardOpsModalStageType>(operation);
  const [cardActionConfirmed, setCardActionConfirmed] = useState(false);
  const [maxAttemptsReached, setMaxAttemptsReached] = useState(false);
  const [fundingRef, setFundingRef] = useState('');
  const [canContinue, setCanContinue] = useState(false);
  const { updateCountdownStatus, countdownIsCompleted, authState, resendOTP, updateAuthState } = useOTPAuth();
  const { otpLabel, otpPlaceholder } = getOtpDescriptors(authState.two_factor_type);

  const handleCloseOnStatusUpdate = () => {
    onClose();
    queryClient.invalidateQueries('CUSTOMER_CARD_DETAILS');
    queryClient.invalidateQueries('RESERVED_CARD_DETAILS');
  };

  const handleCloseOnCardFunding = () => {
    onClose();
    queryClient.invalidateQueries(['ISSUING_BALANCE']);
    queryClient.invalidateQueries(['ISSUED_CARD_TRXNS']);
    queryClient.invalidateQueries(['ISSUED_CARD_EVENTS']);
    queryClient.invalidateQueries(['ISSUED_CARD_CHARGEBACKS']);
  };

  const options = {
    ...(operation === 'fundCard'
      ? {
          onSuccess: (data: OtpResponseType) => {
            const { reference, auth } = data.data;
            setFundingRef(reference);
            updateAuthState({
              ...auth
            });
          }
        }
      : {})
  };

  const { mutateAsync } = useManageCardActions({ ref: cardId, action: operation, options });
  const { feedbackInit, closeFeedback } = useFeedbackHandler();

  const { mutateAsync: mutateOtpAuthorization } = useMutation(publicApi.authorizeCardFunding, {
    onError: (e: Error & AxiosError<any>) => {
      logError(e);
      let errMsg = e.response?.data?.message;
      if (errMsg === 'invalid request data') {
        const errors = e.response?.data?.data;
        const keys = Object.keys(errors);
        if (keys.length) {
          errMsg = errors[keys[0]]?.message || errMsg;
        }
      }
      if (e.response?.data?.message?.includes('0 attempt left')) {
        errMsg = 'You have exceeded the maximum number of attempts. Please restart this transaction.';
        setMaxAttemptsReached(true);
      } else {
        errMsg = e.response?.data.message || 'There has been an error';
      }

      feedbackInit({
        componentLevel: true,
        message: errMsg,
        type: 'danger'
      });

      setTimeout(() => {
        if (e.response?.data?.message?.includes('0 attempt left')) {
          handleCloseOnCardFunding();
        }
        closeFeedback();
      }, 5000);
    }
  });

  const { setFieldValue, dirty, isValid, values, getFieldProps, touched, errors, handleBlur } = useFormik({
    initialValues: {
      reason: '',
      reasonDescription: '',
      confirmCardAction: false,
      limitPeriod: '',
      currency,
      maxSpend: '',
      fundingAmount: '',
      sourceOfFund: 'issuingBalance',
      otp: ''
    },
    validate: formValues => {
      // eslint-disable-next-line no-unused-vars
      const formErrors: Partial<{ [P in keyof typeof formValues]: string }> = {};
      const MIN_FUNDABLE_AMOUNT = 5;

      if (['suspend', 'terminate'].includes(stage)) {
        if (!formValues.reason) formErrors.reason = 'Reason required';
        if (!formValues.reasonDescription) formErrors.reasonDescription = 'Please provide a description';
        if (formValues.reasonDescription && formValues.reasonDescription.length < MIN_CHAR_LENGTH)
          formErrors.reasonDescription = 'Description cannot be less than 3 characters';
      }

      if (['confirmSuspend', 'confirmTerminate'].includes(stage)) {
        if (formValues.confirmCardAction !== true) formErrors.confirmCardAction = 'Please confirm this action';
      }

      if (stage === 'manageCardLimits') {
        if (!formValues.limitPeriod) formErrors.limitPeriod = 'Please select a limit period';
        if (!formValues.maxSpend) formErrors.maxSpend = 'Please enter the maximum spend for this card';
      }

      if (stage === 'fundCard') {
        if (!formValues.sourceOfFund) formErrors.sourceOfFund = 'Please select a source of fund';
        if (!formValues.fundingAmount) formErrors.fundingAmount = 'Please enter the amount to be funded';
        if (formValues.fundingAmount && +formValues.fundingAmount < MIN_FUNDABLE_AMOUNT)
          formErrors.fundingAmount = `Amount should be at least ${MIN_FUNDABLE_AMOUNT} USD`;
        if (formValues.sourceOfFund === 'issuingBalance') {
          if (formValues.fundingAmount && +formValues.fundingAmount > +balance)
            formErrors.fundingAmount =
              'You do not have sufficient funds in your issuing balance for this amount. Kindly top up the balance or change the amount.';
        }
      }

      if (stage === 'confirmCardFunding') {
        if (!twoFAInputValidation(authState.two_factor_type, formValues.otp))
          formErrors.otp = `Please enter a valid ${otpLabel.toLowerCase()}`;
      }

      return formErrors;
    },
    onSubmit: () => {}
  });

  const handleSubmit = async () => {
    switch (stage) {
      case 'reactivate':
        await reactivateCard();
        break;
      case 'suspend':
        setStage('confirmSuspend');
        break;
      case 'confirmSuspend':
        await suspendCard();
        break;
      case 'terminate':
        setStage('confirmTerminate');
        break;
      case 'confirmTerminate':
        await terminateCard();
        break;
      case 'manageCardLimits':
        setStage('confirmCardLimits');
        break;
      case 'confirmCardLimits':
        manageCardLimits();
        break;
      case 'fundCard':
        await sendOtp();
        updateCountdownStatus(false);
        break;
      case 'confirmCardFunding':
        await authorizeCardFunding();
        break;
      default:
        break;
    }
  };
  const toggleActionConfirmation = (checked: boolean) => {
    setCardActionConfirmed(checked);
    setFieldValue('confirmCardAction', checked);
  };

  const handleOtpResend = async () => {
    updateCountdownStatus(false);
    await resendOTP();
  };

  useEffect(() => {
    if (values.otp === '' && stage === 'confirmCardFunding') {
      setCanContinue(false);
    } else {
      setCanContinue(stage !== 'reactivate' ? dirty && isValid : true);
    }
  }, [values, stage, isValid, dirty]);

  const { reason, reasonDescription } = values;

  const reasonText = reason === 'other' ? reasonDescription : reason;

  const suspendCard = async () => {
    await mutateAsync({
      action: 'suspend',
      reason: reasonText,
      initiator: `${profile?.firstName} ${profile?.lastName}`,
      description: reasonDescription
    });
  };

  const reactivateCard = async () => {
    await mutateAsync({
      action: 'activate',
      reason: 'Reactivate card',
      initiator: `${profile?.firstName} ${profile?.lastName}`
    });
  };

  const terminateCard = async () => {
    await mutateAsync({
      reason: reasonText,
      initiator: `${profile?.firstName} ${profile?.lastName}`,
      description: reasonDescription
    });
  };

  const sendOtp = async () => {
    await mutateAsync({
      amount: values.fundingAmount,
      description: 'card funding'
    });
    setStage('confirmCardFunding');
  };

  const authorizeCardFunding = async () => {
    await mutateOtpAuthorization({
      cardRef: fundingRef,
      auth: {
        ...authState,
        code: values.otp
      }
    });
  };

  const manageCardLimits = () => {};

  const goToPrevStageorClose = () => {
    switch (stage) {
      case 'confirmCardLimits':
        setStage('manageCardLimits');
        return;
      case 'confirmTerminate':
        setStage('terminate');
        return;
      case 'confirmSuspend':
        setStage('suspend');
        return;
      case 'confirmCardFunding':
        if (authState.two_factor_type === 'totp_recovery_code') {
          updateAuthState({ two_factor_type: 'totp' });
          return;
        }
        setStage('fundCard');
        updateCountdownStatus(true);
        return;
      default:
        onClose();
    }
  };

  const sourceOfFundOptions = [
    { label: '- Select a source of fund -', value: '' },
    { label: `Issuing Balance (${formatAmount(balance)} ${values.currency?.toUpperCase()})`, value: 'issuingBalance' }
  ];

  function renderActionForm({ action = 'suspend' }) {
    return (
      <div className="stack-lg">
        {action === 'terminate' && (
          <div
            className="flex reset-margin"
            style={{ alignItems: 'start', backgroundColor: 'hsla(349, 100%, 91%, 1)', padding: '1rem', borderRadius: '.5rem' }}
          >
            <svg width="40" height="40" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M12.5747 7.21635L5.51632 18.9997C5.37079 19.2517 5.29379 19.5374 5.29298 19.8284C5.29216 20.1195 5.36756 20.4056 5.51167 20.6585C5.65579 20.9113 5.86359 21.122 6.11441 21.2696C6.36523 21.4171 6.65032 21.4965 6.94132 21.4997H21.058C21.349 21.4965 21.6341 21.4171 21.8849 21.2696C22.1357 21.122 22.3435 20.9113 22.4876 20.6585C22.6317 20.4056 22.7071 20.1195 22.7063 19.8284C22.7055 19.5374 22.6285 19.2517 22.483 18.9997L15.4247 7.21635C15.2761 6.97144 15.0669 6.76895 14.8173 6.62842C14.5677 6.48789 14.2861 6.41406 13.9997 6.41406C13.7132 6.41406 13.4316 6.48789 13.182 6.62842C12.9324 6.76895 12.7232 6.97144 12.5747 7.21635V7.21635Z"
                stroke="hsla(350, 90%, 55%, 1)"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
                transform="translate(0, -10)"
              />
              <path
                d="M14 11.5V14.8333"
                stroke="#F32345"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
                transform="translate(0, -10)"
              />
              <path
                d="M14 18.167H14.0083"
                stroke="#F32345"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
                transform="translate(0, -10)"
              />
            </svg>
            <p style={{ color: 'hsla(350, 90%, 55%, 1)', fontSize: '0.95rem' }}>
              Terminated cards will be permanently blocked and will no longer be able to perform transactions.
            </p>
          </div>
        )}
        <div className="form-group">
          <label htmlFor="reason" className="withdraw-label">
            <span className="dark">Why do you want to {action} this card?</span>
          </label>
          <select
            className="form-control"
            id="reason"
            name="reason"
            onChange={e => setFieldValue('reason', e.target.value)}
            value={values?.reason}
          >
            {reasons.map(option => (
              <option key={option.value} value={option.value}>
                {option.label}
              </option>
            ))}
          </select>
        </div>

        <div className="form-group">
          <label htmlFor="reasonDescription" className="withdraw-label">
            <span className="dark">Tell us more about your reason to {action} this card</span>
          </label>
          <textarea
            id="reasonDescription"
            name="reasonDescription"
            className="form-control"
            onChange={e => setFieldValue('reasonDescription', filterUserInput(e.target.value))}
            onBlur={handleBlur}
            value={values.reasonDescription}
            rows={3}
            minLength={MIN_CHAR_LENGTH}
            maxLength={MAX_CHAR_LENGTH}
            aria-describedby="word-count"
          />
          <div className="flex width-full reset-margin" data-justify="between">
            {touched.reasonDescription && errors.reasonDescription && (
              <p style={{ color: 'red', fontSize: '.875rem' }}>{errors.reasonDescription}</p>
            )}
            <span
              id="word-count"
              className="ml-auto"
              aria-live="polite"
              style={{ fontSize: '.875rem' }}
            >{`${values.reasonDescription.length}/${MAX_CHAR_LENGTH}`}</span>
          </div>
        </div>
      </div>
    );
  }

  const renderConfirmCardAction = ({ action = 'suspend' }) => (
    <div className="stack-lg" data-modal-stage="change-status">
      {action === 'suspend' && (
        <div className="flex reset-margin">
          <svg xmlns="http://www.w3.org/2000/svg" width={40} height={41} fill="none" viewBox="0 0 20 21">
            <path
              fill="hsla(216, 90%, 55%, 1)"
              d="M10 .5C4.477.5 0 4.977 0 10.5s4.477 10 10 10 10-4.477 10-10-4.477-10-10-10Zm0 5A1.25 1.25 0 1 1 10 8a1.25 1.25 0 0 1 0-2.5Zm1.875 10h-3.75a.625.625 0 1 1 0-1.25h.625V10.5h-.625a.625.625 0 1 1 0-1.25h2.5c.345 0 .625.28.625.625v4.375h.625a.625.625 0 1 1 0 1.25Z"
            />
          </svg>
          <p className="disclaimer" data-modal-stage="confirm-suspend">
            <strong>Important:</strong> Once you confirm, card balance will be blocked and you won&apos;t be able to perform any transaction
            on it.
          </p>
        </div>
      )}

      <CustomCheckbox
        text={
          <p className="checkbox-text" data-modal-stage="change-status">
            Yes, I understand the implications of this action
          </p>
        }
        checked={cardActionConfirmed}
        onChange={toggleActionConfirmation}
        className="custom-checkbox"
      />
    </div>
  );

  const renderCardManagement = () => {
    return (
      <div className="reset-margin reserved-vcard-container stack-lg">
        <div className="stack-sm">
          <label htmlFor="limit-period" className="semibold">
            Select limit period
          </label>
          <select className="form-control" id="limit-period" {...getFieldProps('limitPeriod')}>
            {limitPeriodOptions.map(({ value, label }) => (
              <option value={value} key={value}>
                {label}
              </option>
            ))}
          </select>
        </div>

        <div className="stack-sm">
          <label htmlFor="max-spend" className="semibold">
            Maximum spend for this period ({values.currency.toUpperCase()})
          </label>
          <input
            type="text"
            name="maxSpend"
            id="max-spend"
            value={values.maxSpend}
            onChange={e => setFieldValue('maxSpend', backwardAmountInput(stripNonNumeric(e.target.value)))}
            className="form-control"
            placeholder="Enter amount"
            aria-describedby="max-spend-range"
          />
          <span id="max-spend-range" className="flex width-full text-sm" data-justify="between" role="status">
            <span>
              Minimum amount: <strong>{values.currency.toUpperCase()} 100</strong>
            </span>
            <span>
              Maximum amount: <strong>{values.currency.toUpperCase()} 100</strong>
            </span>
          </span>
        </div>
      </div>
    );
  };

  const renderCardFunding = () => {
    return (
      <div className="reset-margin reserved-vcard-container stack-lg">
        <div className="stack-sm">
          <label htmlFor="source-of-fund" className="text-sbold">
            Source of funds
          </label>
          <select
            className="form-control"
            id="source-of-fund"
            {...getFieldProps('sourceOfFund')}
            style={{ pointerEvents: 'none', backgroundColor: 'hsla(207, 47%, 96%, 1)' }}
            tabIndex={-1}
          >
            {sourceOfFundOptions.map(({ value, label }) => (
              <option value={value} key={value}>
                {label}
              </option>
            ))}
          </select>
          <p className="sr-only">
            You&apos;re now transferring from your issuing balance. You have a current balance of {formatAmount(balance)}{' '}
            {values.currency.toUpperCase()}
          </p>
        </div>

        <div className="stack-sm">
          <label htmlFor="funding-amount" className="text-sbold">
            Amount ({values.currency.toUpperCase()})
          </label>
          <input
            type="text"
            name="fundingAmount"
            id="funding-amount"
            value={values.fundingAmount}
            onChange={e => setFieldValue('fundingAmount', backwardAmountInput(stripNonNumeric(e.target.value)))}
            onBlur={handleBlur}
            className="form-control"
            placeholder="Enter amount"
          />
          {touched.fundingAmount && errors.fundingAmount && <p style={{ color: 'red', fontSize: '.875rem' }}>{errors.fundingAmount}</p>}
        </div>
      </div>
    );
  };

  const renderConfirmCardFunding = () => {
    const MIN_LENGTH = 6;
    const MAX_LENGTH = 11;
    return (
      <div className="reserved-vcard-container stack-xl">
        <p>
          To proceed, enter{' '}
          {authState.two_factor_type === 'otp' ? (
            <>
              {' '}
              the OTP (one-time PIN) that was sent to your email (<strong>{maskEmail(merchantEmail)}</strong>).
            </>
          ) : null}
          {authState.two_factor_type === 'totp' ? 'the authentication code from your authenticator app' : null}
          {authState.two_factor_type === 'totp_recovery_code' ? 'a recovery code' : null}
        </p>

        <div className="stack-sm" data-modal-stage="">
          <label htmlFor="otp" className="rvc-label">
            {otpLabel}
          </label>
          <input
            type="text"
            name="otp"
            id="otp"
            value={values.otp}
            onChange={e => setFieldValue('otp', stripNonNumeric(e.target.value))}
            onBlur={handleBlur}
            className="form-control"
            placeholder={otpPlaceholder}
            inputMode="numeric"
            autoComplete="one-time-code"
            minLength={MIN_LENGTH}
            maxLength={MAX_LENGTH}
            disabled={maxAttemptsReached}
          />

          {errors.otp && touched.otp ? (
            <p className="error-msg mt-1" style={{ fontSize: '13px', color: '#F32345' }}>
              {errors.otp}
            </p>
          ) : null}

          {authState.two_factor_type === 'totp' ? (
            <div className="otp-cta">
              <span>Can&apos;t access authenticator app?</span>
              <button
                type="button"
                className="semibold btn btn-link"
                onClick={() => updateAuthState({ two_factor_type: 'totp_recovery_code' })}
              >
                Confirm using recovery codes
              </button>
            </div>
          ) : null}

          {authState.two_factor_type === 'otp' ? (
            <div className="otp-cta with-countdown">
              <span>You didn&apos;t receive a code?</span>
              {countdownIsCompleted ? (
                <button disabled={maxAttemptsReached} type="button" className="semibold btn btn-link" onClick={handleOtpResend}>
                  Resend code.
                </button>
              ) : (
                <CountdownTimer targetTime={30} onCountdownEnd={() => updateCountdownStatus(true)} />
              )}
            </div>
          ) : null}
        </div>
      </div>
    );
  };

  const modalPropOptions: Record<CardOpsModalStageType | 'sharedProps', Partial<IModalProps>> = {
    sharedProps: {
      close: onClose,
      completedHeading: 'Done!',
      firstButtonAction: goToPrevStageorClose,
      secondButtonAction: handleSubmit,
      secondButtonText: 'Continue',
      secondButtonDisable: !canContinue
    },
    suspend: {
      heading: <span style={{ fontSize: '1.25rem' }}>Suspend Card</span>,
      description: (
        <span style={{ fontSize: '0.925rem' }}>
          Suspending a card makes its balance blocked. Thus, transactions cannot be carried out on such card.
        </span>
      ),
      content: renderActionForm({ action: 'suspend' }),
      secondButtonActionIsTerminal: false
    },
    confirmSuspend: {
      heading: <span style={{ fontSize: '1.25rem' }}>Confirm card suspension</span>,
      description: <span style={{ fontSize: '0.925rem' }}>Please confirm that you want to suspend this virtual card.</span>,
      secondButtonActionIsTerminal: true,
      secondButtonText: 'Yes, Confirm',
      firstButtonText: 'Back',
      completedDescription: 'You have successfully suspended this virtual card.',
      size: 'sm',
      content: renderConfirmCardAction({ action: 'suspend' }),
      close: handleCloseOnStatusUpdate
    },
    reactivate: {
      secondButtonActionIsTerminal: true,
      heading: 'Confirm reactivation',
      description: (
        <span>
          {suspensionReason ? `This card was suspended due to ${suspensionReason}.` : null}
          Please confirm that you want to reactivate this card.
        </span>
      ),
      completedDescription: 'You have successfully reactivated this virtual card.',
      close: handleCloseOnStatusUpdate
    },
    terminate: {
      heading: <span style={{ fontSize: '1.25rem' }}>Terminate Card</span>,
      description: <span style={{ fontSize: '0.925rem' }}>Please follow the prompt below to terminate this card.</span>,
      content: renderActionForm({ action: 'terminate' }),
      secondButtonActionIsTerminal: false,
      secondButtonAction: () => setStage('confirmTerminate'),
      secondButtonExtraStyle: 'btn-red'
    },
    confirmTerminate: {
      heading: <span style={{ fontSize: '1.25rem' }}>Confirm card termination</span>,
      description: <span style={{ fontSize: '0.925rem' }}>Please confirm that you want to terminate this virtual card.</span>,
      secondButtonActionIsTerminal: true,
      secondButtonText: 'Yes, Confirm',
      completedDescription: 'You have successfully terminated this virtual card.',
      secondButtonExtraStyle: 'btn-red',
      size: 'sm',
      content: renderConfirmCardAction({ action: 'terminate' }),
      close: handleCloseOnStatusUpdate
    },
    manageCardLimits: {
      heading: <div className="text-lg">Manage virtual card spending limit</div>,
      description: (
        <div className="text-md">This limit defines the amount of money that is accessible on this virtual card per period selected.</div>
      ),
      secondButtonActionIsTerminal: false,
      secondButtonText: <span className="text-md">Continue</span>,
      firstButtonText: <span className="text-md">Cancel</span>,
      content: renderCardManagement()
    },
    confirmCardLimits: {
      secondButtonActionIsTerminal: true,
      heading: <div className="text-lg">Confirm change(s) to card limit</div>,
      secondButtonText: <span className="text-md">Yes, Confirm</span>,
      firstButtonText: <span className="text-md">Back</span>,
      description: (
        <span className="text-md">
          Kindly confirm that you want to make changes to card spending limit. New limit will take effect immediately.
        </span>
      ),
      completedHeading: 'Success',
      completedDescription: 'You have successfully updated reserved virtual card limits.',
      size: 'sm'
    },
    fundCard: {
      heading: <div className="text-lg">Fund Virtual Card</div>,
      description: <div className="text-md">Enter the details below to fund virtual card</div>,
      secondButtonActionIsTerminal: false,
      secondButtonText: <span className="text-md">Continue</span>,
      firstButtonText: <span className="text-md">Cancel</span>,
      content: renderCardFunding()
    },
    confirmCardFunding: {
      secondButtonDisable: !canContinue || maxAttemptsReached,
      secondButtonActionIsTerminal: true,
      heading: <div className="text-lg">Confirm card funding</div>,
      secondButtonText: <span className="text-md">Proceed</span>,
      firstButtonText: <span className="text-md">Back</span>,
      description: (
        <span className="text-md">
          Please confirm that you want to transfer{' '}
          <strong>{`${formatAmount(values.fundingAmount)} ${values.currency.toUpperCase()}`}</strong> from your{' '}
          <strong>issuing balance</strong> to card balance. This action cannot be undone.
        </span>
      ),
      completedHeading: 'Success',
      completedDescription: 'You have successfully funded virtual card from your issuing balance.',
      content: renderConfirmCardFunding(),
      size: 'sm',
      close: handleCloseOnCardFunding
    }
  };

  const modalProps = {
    ...modalPropOptions.sharedProps,
    ...(modalPropOptions[stage] || {})
  };

  return (
    <div>
      <Modal {...(modalProps as IModalProps)} />
    </div>
  );
}

const reasons = [
  { label: '- Select an option -', value: '' },
  { label: 'Suspected Fraud', value: 'fraud' },
  { label: 'Card Compromise', value: 'compromise' },
  { label: "Cardholder's request", value: 'request' },
  { label: 'Other', value: 'other' }
];

const limitPeriodOptions = [
  { label: '- Select period -', value: '' },
  { label: 'Daily', value: 'daily' },
  { label: 'Weekly', value: 'weekly' },
  { label: 'Monthly', value: 'monthly' }
];
