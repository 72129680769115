import { useState } from 'react';
import { useQuery } from 'react-query';
import { Route, Switch } from 'react-router-dom';
import { useShallow } from 'zustand/react/shallow';

import { getExistingFiltersFromQuery } from '+containers/Dashboard/Shared/FilterModal';
import Table from '+containers/Dashboard/Shared/Table';
import { useFeedbackHandler, useSearchQuery, useSurveyTrigger } from '+hooks';
import APIRequest from '+services/api-services';
import useStore from '+store';
import { DefaultMerchantType } from '+types/defaultMerchant';
import { capitalize, filteredOutObjectProperty, history, queriesParams } from '+utils';

import Filter from '../Issuing/components/Filter/Filter';
import IdentityAccessRequest from './AccessRequest';
import AccessBanner from './components/AccessBanner';
import PageHeader from './components/PageHeader';
import { billingHistoryFilterFields, verificationEventsFilterFields } from './data/identity-data';
import identityTableDataPairs from './tables/identityTableDataPairs';
import { IdentityServiceAccessType } from './types/types';
import VerificationDetails from './VerificationDetails';

import './index.scss';

const api = new APIRequest(process.env.REACT_APP_PUBLIC_MERCHANT_MIDDLEWARE_API_BASE);
const SURVEY_ID = process.env.REACT_APP_ATLAS_QUERY || '';

export function IdentityComponent() {
  const { feedbackInit } = useFeedbackHandler();
  const searchQuery = useSearchQuery();
  const tabs = ['Verification Events', 'Billing'];
  const [recordIsFiltered, setRecordIsFiltered] = useState(false);
  const { identityServiceAccess, defaultMerchant: MERCHANT_ENV } = useStore(
    useShallow((state: unknown) => {
      const typedState = state as { identityServiceAccess: IdentityServiceAccessType; defaultMerchant: DefaultMerchantType };
      return typedState;
    })
  );

  const activeTab = searchQuery.value.tab || 'Verification Events';
  const limit = searchQuery.value.limit || '10';
  const page = searchQuery.value.page || '1';
  const sortingParams = {
    ...filteredOutObjectProperty((searchQuery.value as unknown) as Record<string, string[]>, [
      queriesParams.currency,
      queriesParams.page,
      queriesParams.limit,
      queriesParams.tab,
      queriesParams.subTab,
      queriesParams.sorterType
    ])
  };

  const navigateToServiceConfiguration = () => history.push('/dashboard/identity/service-configuration');
  const { data: events, isFetching: isEventFetching, refetch: eventRefetch } = useQuery(
    ['IDENTITY_VERIFICATIONS', page, limit, sortingParams, MERCHANT_ENV],
    () => api.getAllVerifications({ limit, page, ...getExistingFiltersFromQuery(verificationEventsFilterFields) }),
    {
      keepPreviousData: true,
      onError: () => {
        feedbackInit({
          message: 'There has been an error getting identity verification events.',
          type: 'danger',
          action: {
            action: () => eventRefetch(),
            name: 'Try again'
          }
        });
      },
      enabled: activeTab === 'Verification Events'
    }
  );

  const { data: verificationCountData } = useQuery(
    ['VERIFICATION_COUNT', MERCHANT_ENV],
    () => api.getAllVerifications({ limit, page, ...{ 'status[]': 'found' } }),
    {}
  );

  const { data: billingHistory, isFetching: isBillingFetching, refetch: billingRefetch } = useQuery(
    ['BILLING_HISTORY', page, limit, sortingParams, MERCHANT_ENV],
    () => api.getVerificationBillingHistory({ limit, page, ...getExistingFiltersFromQuery(billingHistoryFilterFields) }),
    {
      keepPreviousData: true,
      onError: () => {
        feedbackInit({
          message: 'There has been an error billing history.',
          type: 'danger',
          action: {
            action: () => billingRefetch(),
            name: 'Try again'
          }
        });
      },
      enabled: activeTab === 'Billing'
    }
  );

  const tableDataPairs = identityTableDataPairs({
    verificationData: events?.data?.data,
    verificationRefetch: eventRefetch,
    billingData: billingHistory?.data?.data,
    billingRefetch
  });

  let paging, loading, refetch;

  switch (activeTab) {
    case 'Verification Events':
      paging = events?.data?.paging;
      loading = isEventFetching;
      refetch = eventRefetch;
      break;
    case 'Billing':
      paging = billingHistory?.data?.paging;
      loading = isBillingFetching;
      refetch = billingRefetch;
      break;
    default:
  }

  const tableData = { ...tableDataPairs[activeTab], loading, refetch } || {};
  useSurveyTrigger({
    count: verificationCountData?.data?.paging?.total_items,
    surveyId: +SURVEY_ID,
    milestones: [10, 100, 1000],
    id: 'Identity'
  });

  return (
    <>
      <PageHeader disableConfigButton={true} navigateToServiceConfiguration={navigateToServiceConfiguration} />
      {(!identityServiceAccess?.has_access || !identityServiceAccess?.active) && (
        <AccessBanner identityServiceAccess={identityServiceAccess} />
      )}
      <div className="content-i">
        <section className="os-tabs-w">
          <div className="os-tabs-controls os-tabs-complex">
            <ul className="nav nav-tabs" role="tablist">
              {tabs.map(tab => (
                <li
                  className="nav-item"
                  key={tab}
                  onClick={() => {
                    searchQuery.setQuery({ tab }, true);
                  }}
                  onKeyDown={() => searchQuery.setQuery({ tab }, true)}
                  role="tab"
                  tabIndex={0}
                >
                  <div role="button" className={`nav-link ${activeTab === tab && 'active'}`} aria-label={`${tab}`}>
                    {capitalize(tab)}
                  </div>
                </li>
              ))}
            </ul>
          </div>
        </section>
        {identityServiceAccess?.has_access ? (
          <>
            <Filter
              totalItems={paging?.total_items}
              title={tableData.tableTitle}
              actions={tableData.tableActions}
              isFiltered={recordIsFiltered}
              onChangeIsFiltered={setRecordIsFiltered}
              {...(tableData.filterProps && tableData.filterProps)}
              onHandleFilter={() => {
                tableData?.filterProps?.onHandleFilter?.();
              }}
            />

            <section className="table-container">
              <Table
                tableClassName={`${tableData.tableClassName}`}
                headings={tableData.headings}
                hasPagination
                loading={tableData.loading || false}
                current={paging?.current}
                limitAction={value => searchQuery.setQuery({ limit: String(value) })}
                pageSize={paging?.page_size}
                actionFn={value => searchQuery.setQuery({ page: String(value) })}
                totalItems={paging?.total_items || 0}
                emptyStateHeading={tableData.emptyStateHeading}
                emptyStateMessage={
                  <>
                    <span>{tableData.emptyStateMessage}</span>
                    {tableData.refetch && (
                      <button
                        type="button"
                        className="refetch-button"
                        onClick={(event: React.MouseEvent<HTMLButtonElement>) => tableData?.refetch()}
                      >
                        <i className="os-icon os-icon-rotate-ccw" style={{ marginRight: '5px' }} />
                        Refresh
                      </button>
                    )}
                  </>
                }
              >
                {tableDataPairs[activeTab].renderChildren()}
              </Table>
            </section>
          </>
        ) : (
          <p className="font-italic font-weight-light">
            This service has not yet been configured for you. Kindly request access to Identity.
          </p>
        )}
      </div>
    </>
  );
}

export default function Identity() {
  const { identityServiceAccess, defaultMerchant: MERCHANT_ENV } = useStore(
    useShallow((state: unknown) => {
      const typedState = state as { identityServiceAccess: IdentityServiceAccessType; defaultMerchant: DefaultMerchantType };
      return typedState;
    })
  );

  const hasAccess: boolean = identityServiceAccess?.has_access;
  return (
    <Switch>
      <Route exact path="/dashboard/identity" component={hasAccess ? IdentityComponent : IdentityAccessRequest} />
      {hasAccess && (
        <Route path="/dashboard/identity/verification/:id">
          <VerificationDetails />
        </Route>
      )}
    </Switch>
  );
}
