import React, { useEffect, useMemo, useState } from 'react';
import Modal from './Modal';
import CustomCheckbox from './CustomCheckbox';
import './SecurityTipModal.scss';

interface ISecurityTipModalProps {
  visible: boolean;
  close: () => void;
  size: 'md' | 'lg' | 'sm';
  data: {
    title: string;
    description: string;
    image: string;
  }[];
  hasCheckBox: boolean;
  hasFirstButton: boolean;
  firstButtonText: string;
  firstButtonIcon: React.ReactNode | null;
  secondButtonText: string;
  secondButtonIcon: React.ReactNode | null;
  firstButtonClassName: string;
  secondButtonClassName: string;
  onSubmit: () => void;
  removeIconOnFinalStep: boolean;
  secondButtonFinalText: string;
  checked: boolean;
  onCheckedChange: () => void;
}

const SecurityTipModal: React.FC<ISecurityTipModalProps> = ({
  visible,
  close,
  size = 'md',
  data = [],
  hasCheckBox,
  hasFirstButton,
  firstButtonText = 'Back',
  firstButtonIcon = <i className="os-icon os-icon-arrow-left7 mr-1" />,
  secondButtonText = 'Next',
  secondButtonIcon = <i className="os-icon os-icon-arrow-right7 ml-1" />,
  firstButtonClassName = '',
  secondButtonClassName = '',
  onSubmit,
  removeIconOnFinalStep,
  secondButtonFinalText = '',
  checked,
  onCheckedChange
}) => {
  const [step, setStep] = useState(0);

  const content = data[step];

  const isFinal = step === data.length - 1;

  const onClose = () => {
    close();
    setStep(0);
  };

  const onNext = () => {
    if (isFinal) {
      onSubmit?.();
      onClose();
      return;
    }
    setStep(step + 1);
  };

  const onPrev = () => {
    if (step === 0) return;
    setStep(step - 1);
  };

  const modalContent = (
    <div className="stm-content">
      <div className="stm-image">
        <img src={content?.image} alt="" />
      </div>
      <div className="stm-content-body">
        <div className="stm-text">
          <h4>{content?.title}</h4>
          <p>{content?.description}</p>
        </div>
        {data?.length > 1 && (
          <div className="stm-carousel-btn-wrapper">
            {data?.map((item, key) => (
              <button
                type="button"
                className={key === step ? 'active' : ''}
                key={item?.title}
                onClick={() => setStep(key)}
                onKeyDown={() => null}
                aria-label="step"
              />
            ))}
          </div>
        )}
      </div>
      <div className={`stm-footer ${hasCheckBox ? 'has-check' : ''}`}>
        {hasCheckBox && (
          <CustomCheckbox
            text="Dont Show Me Again"
            className="text-primary stm-checkbox"
            checked={checked}
            onChange={() => onCheckedChange?.()}
          />
        )}
        <div className="stm-btn-wrapper">
          {hasFirstButton && step !== 0 ? (
            <button type="button" className={`btn stm-first-btn ${firstButtonClassName}`} onClick={onPrev}>
              {firstButtonIcon} {firstButtonText}
            </button>
          ) : (
            <span />
          )}
          <button type="button" className={`btn stm-second-btn ${isFinal ? 'is-final' : ''} ${secondButtonClassName}`} onClick={onNext}>
            {isFinal && secondButtonFinalText ? secondButtonFinalText : secondButtonText}
            {!isFinal && secondButtonIcon}
            {isFinal && !removeIconOnFinalStep ? secondButtonIcon : null}
          </button>
        </div>
      </div>
    </div>
  );

  return <Modal heading="" visible={visible} close={onClose} showButtons={false} size={size} content={modalContent} />;
};
export default SecurityTipModal;
