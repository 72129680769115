import { useState } from 'react';

import { capitalizeRemovedash, formatAmount, getTwoDigitYear, isNullish } from '+utils';

import CardInfoModal from '../../components/CardInfoModal';
import CardThumbnail from '../../components/CardThumbnail';
import { ICardInfo } from '../../types';
import CardSummary from './CardSummary';

const CardInformation = ({ cardDetails: data, cardId: ref, cardCategory }: ICardInfo) => {
  const [cardInfoModalIsActive, setCardInfoModalIsActive] = useState(false);
  const {
    status,
    brand,
    color,
    balance,
    billing,
    currency,
    expiry_month: expiryMonth,
    expiry_year: expiryYear,
    card_holder: cardHolder,
    last_four: lastFour,
    pan,
    cvv,
    label
  } = data || {};

  const formatExpiryDate = () => {
    let formattedYear;
    if (expiryMonth && expiryYear) {
      formattedYear = getTwoDigitYear(expiryYear);
      return `${expiryMonth} / ${formattedYear}`;
    }
    return null;
  };

  const expiryDate = formatExpiryDate();

  return (
    <div className="summary-section">
      <div className="content mt-4">
        <CardSummary cardData={data} cardId={ref} />

        <div className="card-side">
          <div className="mb-4 card-container">
            <CardThumbnail
              cardDetails={{
                status: ['requires_auth', 'require_auth'].includes(status)
                  ? 'Pending (requires authorization)'
                  : capitalizeRemovedash(status ?? ''),
                scheme: brand,
                holderName: !isNullish(cardHolder)
                  ? `${cardHolder?.first_name} ${cardHolder?.first_name !== cardHolder?.last_name ? cardHolder?.last_name : ''}`
                  : 'N/A',
                expiresAt: !isNullish(expiryDate) ? expiryDate : 'N/A',
                lastFourDigits: lastFour
              }}
              color={color}
              applyOverlay={cardCategory === 'reserved_cards'}
              onOverlayClick={() => setCardInfoModalIsActive(true)}
            />
          </div>

          <div className="info-container">
            <div className="balance-value">
              <span className="digit">{!isNullish(balance) ? formatAmount(balance) : 'N/A'}</span>
              <span className="currency"> {currency}</span>
            </div>
            <div className="balance-label">Available Balance</div>
          </div>
        </div>
      </div>

      <CardInfoModal
        close={() => setCardInfoModalIsActive(false)}
        open={cardInfoModalIsActive}
        cardInfo={{ holderName: cardHolder, label, cvv, pan, expiryMonth, expiryYear, billing, scheme: brand }}
      />
    </div>
  );
};

export default CardInformation;
