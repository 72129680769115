
import React from 'react';
import { ArrowsLeftRight, CaretDown } from '+containers/Shared/Icons';
import DisplayCountdown from './DisplayCountdown';
import { ICurrencyConversionFormProps } from '+types';

const CurrencyConversionForm: React.FC<ICurrencyConversionFormProps> = (props) => {
  const {
    formType,
    payloadCurrency,
    currencyArray,
    handleSelectChange,
    handleSwitchCurrency,
    handleProcessConversion,
    state,
    availableBalance,
    errorMessage,
    checkLimitAndValidate,
    formatAmount,
    rate,
    toAmount,
    isLoading,
    amount,
    handleInputChange,
    cleanInput,
    amountInputRef,
    currentCount,
    initiateConversion
  } = props;

  switch (formType) {
    case 'main':
      return (
        <div className="conversions-wrapper">
          <div className="conversion-forms">
            <div className="form-group conversions-form">
              <label htmlFor="mobileNo" className="withdraw-label">
                <span className="dark">When you convert</span>
              </label>
              <div className="input-group">
                <div className="input-group-prepend">
                  <div className="select-cur">
                    <select
                      className='curr-dropdown'
                      value={payloadCurrency.from}
                      onChange={(e) => handleSelectChange(e, "currencyFrom")}
                      style={{ appearance: 'none', padding: '0.5em 2em 0.5em 1em' }}>
                      {currencyArray.map(currency => (
                        <option key={currency} value={currency}>
                          {currency}
                        </option>
                      ))}
                    </select>
                    <span
                      className='caret'>
                      <CaretDown className="caret-down" />
                    </span>
                  </div>
                </div>
                <input
                  className="form-control"
                  name="amount"
                  type="number"
                  maxLength={11}
                  ref={amountInputRef}
                  value={cleanInput(amount)}
                  onChange={handleInputChange}
                  placeholder="0"
                />
              </div>
              {errorMessage && (
                <small
                  className="text-danger"
                  dangerouslySetInnerHTML={{ __html: errorMessage }}
                />
              )}
            </div>
            <button
              type="button"
              onClick={handleSwitchCurrency}
              className="handle-switch-currency btn-margin"
            >
              <ArrowsLeftRight className="btn-arrows-left-right" />
            </button>
            <div className="form-group conversions-form currency-to">
              <label htmlFor="mobileNo" className="withdraw-label">
                <span className="dark">You’ll Receive</span>
              </label>
              <div className="input-group currency-to-amount">
                <div className="input-group-prepend">
                  <div className="select-cur">
                    <select
                      className='curr-dropdown'
                      value={payloadCurrency.to}
                      onChange={(e) => handleSelectChange(e, "currencyTo")}
                      style={{ appearance: 'none', padding: '0.5em 2em 0.5em 1em' }}
                    >
                      {currencyArray.map(currency => (
                        <option key={currency} value={currency}>
                          {currency}
                        </option>
                      ))}
                    </select>
                    <span className='caret'>
                      <CaretDown className="caret-down" />
                    </span>
                  </div>
                </div>
                <input
                  className="form-control currency-to-input"
                  type="number"
                  name="amount"
                  value={''}
                  onChange={() => { }}
                  placeholder={checkLimitAndValidate ? `${formatAmount(toAmount || 0)}` : ''}
                />
                <button
                  className="btn btn-primary convert-btn"
                  style={{ width: '110px' }}
                  disabled={!checkLimitAndValidate}
                  onClick={handleProcessConversion}
                >
                  {state.isProcessing ? (
                    <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true" />
                  ) : (
                    <>
                      <span>Convert</span>
                      <i className='os-icon os-icon-arrow-up-right' />
                    </>
                  )}
                </button>
              </div>
            </div>
          </div>
          <div className="conversion-sumarry">
            <div className='conversion-summary-item'>
              <span>Balance:</span>
              <span className='available-balance'>{formatAmount(availableBalance)}{' '}{payloadCurrency.from}</span>
            </div>
            <div className='conversion-summary-item exchange_rate_wrapper'>
              <ul>
                <li>
                  <div style={{ display: 'flex' }}>
                    {isLoading === true ? (
                      <span
                        className="spinner-border spinner-border-sm ml-2"
                        style={{ opacity: '0.4' }}
                        role="status"
                        aria-hidden="true"
                      />
                    ) : (
                      checkLimitAndValidate && (
                        <>
                          <span>Rate:</span>
                          <span className='swaps-amount'>
                            {`${'USD'} ${formatAmount(1)} `} - {`${currencyArray[0] === 'USD' ? 'NGN' : currencyArray[0]} ${formatAmount(rate || 0)} `}
                          </span>
                          {<DisplayCountdown currentCount={currentCount} />}
                        </>
                      )
                    )}
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div >
      );
    case 'modal':
      return (
        <>
          <div className="form-group convert-form">
            <div className='conversion-label'>
              <label htmlFor="conversion-label" className="withdraw-label">
                <span className="dark">When you convert</span>
              </label>
              <label className="withdraw-label balance">
                <span className="">Balance:</span>
                <span className='dark'>{formatAmount(availableBalance)} {payloadCurrency.from}</span>
              </label>
            </div>
            <div className="input-group">
              <div className="input-group-prepend">
                <div className="select-cur">
                  <select
                    className='curr-dropdown'
                    value={payloadCurrency.from}
                    onChange={(e) => handleSelectChange(e, "currencyFrom")}
                    style={{ appearance: 'none', padding: '0.5em 2em 0.5em 1em' }}>
                    {currencyArray.map(currency => (
                      <option key={currency} value={currency}>
                        {currency}
                      </option>
                    ))}
                  </select>
                  <span
                    className='caret'>
                    <CaretDown className="caret-down" />
                  </span>
                </div>
              </div>
              <input
                className="form-control"
                name="amount"
                type="number"
                maxLength={11}
                value={cleanInput(amount)}
                onChange={handleInputChange}
                placeholder="0"
              />
            </div>
            {errorMessage && (
              <small
                className="text-danger"
                dangerouslySetInnerHTML={{ __html: errorMessage }}
              />
            )}
          </div>
          <button
            type="button"
            onClick={handleSwitchCurrency}
            className="handle-switch-currency"
          >
            <ArrowsLeftRight className="btn-arrows-left-right" />
          </button>
          <div className="form-group convert-form btn-margin">
            <label htmlFor="conversion-label" className="withdraw-label">
              <span className="dark">You’ll receive</span>
            </label>
            <div className="input-group">
              <div className="input-group-prepend">
                <div className="select-cur">
                  <select
                    className='curr-dropdown'
                    value={payloadCurrency.to}
                    onChange={(e) => handleSelectChange(e, "currencyTo")}
                  >
                    {currencyArray.map(currency => (
                      <option key={currency} value={currency}>
                        {currency}
                      </option>
                    ))}
                  </select>
                  <span
                    className='caret'>
                    <CaretDown className="caret-down" />
                  </span>
                </div>
              </div>
              <input
                className="form-control currency-to-input"
                name="amount"
                type="number"
                maxLength={11}
                value={''}
                onChange={e => { }}
                placeholder={checkLimitAndValidate ? `${formatAmount(toAmount || 0)}` : ''}
              />
            </div>
          </div>
          <div className="conversion-sumarry">
            <div className='conversion-summary-item exchange-rate'>
              <ul>
                <li>
                  <div style={{ display: 'flex' }}>
                    {initiateConversion?.isLoading === true ? (
                      <span
                        className="spinner-border spinner-border-sm ml-2"
                        style={{ opacity: '0.4' }}
                        role="status"
                        aria-hidden="true"
                      />
                    ) : (
                      checkLimitAndValidate && (
                        <>
                          <span>Rate:</span>
                          <span className='swaps-amount'>
                            {`${'USD'} ${formatAmount(1)} `} - {`${currencyArray[0] === 'USD' ? 'NGN' : currencyArray[0]} ${formatAmount(rate || 0)} `}
                          </span>
                          {<DisplayCountdown currentCount={currentCount} />}
                        </>
                      )
                    )}
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </>
      );
    default:
      return null;
  }
};

export default CurrencyConversionForm;
