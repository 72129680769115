/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useEffect, useState } from 'react';
import { useQuery, useQueryClient } from 'react-query';
import { Link, useLocation } from 'react-router-dom';
import { AxiosError } from 'axios';
import clsx from 'clsx';

import ExportFilterModal from '+dashboard/Shared/ExportFilterModal';
import Table from '+dashboard/Shared/Table';
import Tooltip from '+dashboard/Shared/Tooltip';
import { useBanks, useExportDownloader, useFeedbackHandler, usePermissions, useSearchQuery } from '+hooks';
import APIRequest from '+services/api-services';
import useStore from '+store';
import {
  APIDownload,
  capitalize,
  capitalizeRemovedash,
  formatAmount,
  getDate,
  getTime,
  history,
  logError,
  swapArrayPositions,
  switchCurrency
} from '+utils';

import AdvanceExportModal from '../Shared/AdvanceExportModal';
import AddFunds from './components/AddFunds';
import Withdraw from './components/Withdraw';
import ConvertFunds from './components/ConvertFunds';

import info from '+assets/img/dashboard/information-button.svg';

import './index.scss';

const api = new APIRequest();
const publicApi = new APIRequest(process.env.REACT_APP_PUBLIC_MERCHANT_MIDDLEWARE_API_BASE);
function Balances() {
  const queryClient = useQueryClient();
  const location = useLocation();
  const searchQuery = useSearchQuery();
  const userAccess = usePermissions('balance');
  queryClient.setQueryData('SAVED_ROLE', location.state);

  const { profile, merchantBalanceDetails } = useStore();
  const [showLargeExportModal, setLargeExportModal] = useState(false);

  const { feedbackInit } = useFeedbackHandler();
  const [balances, setBalances] = useState({});
  const [buffer, setBuffer] = useState([]);
  const [loadingList, setLoadingList] = useState([]);
  const [convertFunds, setConvertFunds] = useState(true);
  const [modalStates, setModalStates] = useState({
    withdrawModal: false,
    fundsModal: false,
    exportModal: false,
    convertFundsModal: false
  });
  const activeTab = searchQuery.value.tab || 'Balance';
  const activeCurrency = searchQuery.value.currency || 'NGN';
  const currentPage = searchQuery.value.page || '1';
  const limit = searchQuery.value.limit || '10';

  const defaultMerchant = useStore(state => state.defaultMerchant);
  const availableCurrencies = useStore(state => state.availableCurrencies);
  const MERCHANT_ENV = useStore(state => state.merchantEnv);
  const getCurrencyHistory = currency => buffer.find(data => data.type === currency);
  const computePage = c => (c === activeCurrency ? currentPage : 1);

  const tabs = ['Balance', 'Reserve'];

  const switchBalanceDescription = {
    available_balance: 'For withdrawals or payouts.',
    pending_balance: 'Funds waiting to be settled.',
    ledger_balance: 'Aggregate of your current balances.',
    reserve_balance: 'Held temporarily in Reserve'
  };

  const { data: banks, isFetching } = useBanks(activeCurrency);

  const { data: virtualAccount, refetch: refetchVirtualAccount } = useQuery(
    ['VIRTUAL_ACCOUNT', MERCHANT_ENV],
    api.fetchMerchantVirtualAccount,
    {
      enabled: MERCHANT_ENV === 'live',
      onError: (err: AxiosError<{ message: string }>) => {
        const error = err.response?.data;
        logError(error?.message);
        if (err?.response?.status !== 404) {
          feedbackInit({
            message: error?.message || 'There has been an error in getting your reserved bank account details',
            type: 'danger',
            action: {
              action: () => refetchVirtualAccount(),
              name: 'Try again'
            }
          });
        }
      }
    }
  );

  const { data: getBalanceData, isFetching: fetchingBalances, refetch: refetchBalance } = useQuery(
    ['MERCHANT_BALANCES', MERCHANT_ENV],
    () => api.getBalances(),
    {
      onSuccess: data => setBalances(data?.data || {}),
      onError: e => {
        const error = e.response?.data;
        logError(error?.message);
        const notLive = error?.message?.includes('not been approved');
        const message = notLive ? 'Your account has not been approved yet' : 'There has been an error in getting your balances';
        feedbackInit({
          message,
          type: 'danger',
          action: {
            action: () => (notLive ? history.push('/dashboard/settings/business') : refetchBalance()),
            name: notLive ? 'Complete account setup' : 'Try again'
          }
        });
      }
    }
  );

  const { data: reserveData, refetch: refetchReserve } = useQuery(
    ['ROLLING_RESERVE', activeCurrency, computePage(activeCurrency), limit],
    () => publicApi.getRollingReserveHistory(activeCurrency, computePage(activeCurrency), limit),
    {
      enabled: merchantBalanceDetails?.[activeCurrency]?.hasOwnProperty('reserve_balance'),
      onError: () => {
        feedbackInit({
          message: `There has been an error in getting this merchant's ${activeCurrency} rolling reserve `,
          type: 'danger',
          action: {
            action: () => refetchReserve(),
            name: 'Try again'
          }
        });
      }
    }
  );

  const { data: settlementAccounts } = useQuery('SETTLEMENT_ACCOUNTS', api.getSettlementAccount, {
    select: response => {
      let accountBuffer = {};
      Object.keys(response.data).forEach(key => {
        const filteredAccount = response.data[key].filter(x => x.status === 'active');
        accountBuffer = { ...accountBuffer, ...{ [`${key}`]: filteredAccount } };
      });
      return accountBuffer;
    },
    onError: e => {
      const error = e.response?.data;
      logError(error?.message);
    }
  });

  const { getDownload } = useExportDownloader('Balances');

  useEffect(() => {
    getDownload(publicApi);
  }, []);

  const reserveBalance = reserveData?.data;
  const updateHistory = newData => {
    if (!buffer.find(x => x.type === newData.type)) {
      setBuffer(prevBuffer => [...prevBuffer, newData]);
    } else {
      setBuffer(prevBuffer => prevBuffer.map(x => (newData.type === x.type ? newData : x)));
    }
  };

  useEffect(() => {
    const getBalances = async () => {
      try {
        setLoadingList(prevLoadingList => [...prevLoadingList, activeCurrency]);
        const balanceHistory = await publicApi.getBalanceHistory(activeCurrency, computePage(activeCurrency), limit);
        updateHistory({ type: activeCurrency, ...balanceHistory?.data });
        setLoadingList(prevLoadingList => prevLoadingList.filter(item => item !== activeCurrency));
      } catch (error) {
        setLoadingList(prevLoadingList => prevLoadingList.filter(item => item !== activeCurrency));
        logError(error);
        feedbackInit({
          message: `There has been an error fetching the balance history for ${switchCurrency[activeCurrency]}.`,
          type: 'danger'
        });
      }
    };
    getBalances();
  }, [balances, currentPage, limit, activeTab, activeCurrency]);

  const exportBalanceHistory = async (format, fieldToExport, from, to) => {
    const parameterizeArray = (key, arr) => {
      arr = arr.map(encodeURIComponent);
      return arr.join(`&${key}[]=`);
    };
    const handleTimeDescription = () => {
      if (!from && !to) return 'of all time';
      if (!from && to) return `up to ${to}`;
      if (from && !to) return `from ${from}`;
      return `from: ${from} to: ${to}`;
    };
    try {
      const res = await publicApi.exportBalanceHistory(
        activeCurrency,
        format,
        from,
        to,
        parameterizeArray('fieldsToExport', fieldToExport)
      );
      if (res.status === 202) {
        setLargeExportModal(true);
      } else {
        const type = format === 'csv' ? 'csv' : 'xlsx';
        APIDownload(res, `${activeCurrency} balance history ${handleTimeDescription()}`, type);
        feedbackInit({
          message: `${switchCurrency[activeCurrency]} successfully downloaded`,
          type: 'success'
        });
      }
      setModalStates({ ...modalStates, exportModal: false });
    } catch (error) {
      logError(error);
      setModalStates({ ...modalStates, exportModal: false });
      feedbackInit({
        message: `There has been an error exporting your ${switchCurrency[activeCurrency]} balances`,
        type: 'danger'
      });
      throw error;
    }
  };

  const exportRollingReserveHistory = async (format, fieldToExport, from, to) => {
    const handleTimeDescription = () => {
      if (!from && !to) return 'of all time';
      if (!from && to) return `up to ${to}`;
      if (from && !to) return `from ${from}`;
      return `from: ${from} to: ${to}`;
    };
    const parameterizeArray = (key, arr) => {
      arr = arr.map(encodeURIComponent);
      return arr.join(`&${key}[]=`);
    };
    try {
      const res = await publicApi.exportRollingReserveHistory(
        activeCurrency,
        format,
        from,
        to,
        parameterizeArray('fieldsToExport', fieldToExport)
      );
      if (res.status === 202) {
        setLargeExportModal(true);
      } else {
        const type = format === 'csv' ? 'csv' : 'xlsx';
        APIDownload(res, `${activeCurrency} reserve balance history ${handleTimeDescription()}`, type);
        feedbackInit({
          message: `${switchCurrency[activeCurrency]} successfully downloaded`,
          type: 'success'
        });
      }
      setModalStates({ ...modalStates, exportModal: false });
    } catch (error) {
      logError(error);
      feedbackInit({
        message: `There has been an error exporting your ${switchCurrency[activeCurrency]} reserve balance history`,
        type: 'danger'
      });
      throw error;
    }
  };

  const renderLink = (type, reference, direction) => {
    if (type === 'settlement') {
      return (
        <Link to={`/dashboard/settlements/${reference}`} style={{ fontWeight: 600 }}>
          {reference?.toUpperCase()}
        </Link>
      );
    }
    return (
      <Link to={`/dashboard/${direction === 'credit' ? 'pay-ins' : 'payouts'}/${reference}`} style={{ fontWeight: 600 }}>
        {reference?.toUpperCase()}
      </Link>
    );
  };

  const filterHistory = (type, description, reference, direction) => {
    const notAvailable = [undefined, null, '', ' '];
    if (type === 'payout_reversal') {
      return <span>{description}</span>;
    }
    if (description?.startsWith('Settlement')) {
      return (
        <>
          Settlement for{' '}
          <Link to={`/dashboard/settlements/${reference}`} style={{ fontWeight: 600 }}>
            {reference?.toUpperCase()}
          </Link>
        </>
      );
    }
    if (description?.startsWith('Chargeback')) {
      let rcDescription;
      if (description?.includes('processed')) {
        rcDescription = description.split('processed for ');
      } else if (description?.includes('deduction')) {
        rcDescription = description.split('deduction for ');
      }
      if (rcDescription) {
        return (
          <>
            {rcDescription[0]} deduction for -{' '}
            <Link to={`/dashboard/disputes/chargebacks/${reference}`} style={{ fontWeight: 600 }}>
              {reference?.toUpperCase()}
            </Link>
          </>
        );
      }
    }
    if (description?.startsWith('Refund')) {
      const rcDescription = description.split('processed for ');
      return (
        <>
          {rcDescription[0]} processed for -{' '}
          <Link to={`/dashboard/disputes/refunds/${reference}`} style={{ fontWeight: 600 }}>
            {reference?.toUpperCase()}
          </Link>
        </>
      );
    }
    if (description?.startsWith('\n')) {
      const word = description.split('\n');
      const newDescription = word[1].trim();
      if (newDescription?.startsWith('overpayment') || newDescription?.startsWith('underpayment')) {
        const ovDescription = newDescription.split('for ');
        return (
          <>
            Reversal for <span style={{ textTransform: 'capitalize' }}>{ovDescription[0]}</span> processed for -{' '}
            <Link to={`/dashboard/pay-ins/${ovDescription[1]}`} style={{ fontWeight: 600 }}>
              {ovDescription[1]?.toUpperCase()}
            </Link>
          </>
        );
      }
    }
    if (description?.startsWith('Rolling')) {
      const rcDescription = description.split(new RegExp(' for ', 'i'));
      return (
        <>
          {capitalize(rcDescription[0].replace('Rolling', ''))}
          {' for '}
          <Link to={`/dashboard/settlements/${reference}`} style={{ fontWeight: 600 }}>
            {reference?.toUpperCase()}
          </Link>
        </>
      );
    }
    if (notAvailable.includes(description)) return 'Not Available';
    return (
      <>
        {description}
        {!notAvailable.includes(reference) && (
          <>
            {' - '}
            {renderLink(type, reference, direction)}
          </>
        )}
      </>
    );
  };

  const renderBalanceHistory = () => {
    const activeList = getCurrencyHistory(activeCurrency);
    return (
      <div>
        {activeList?.data?.map(each => (
          <div className="div-table --balance-history --row" key={each.created_at + each.source_reference}>
            <div>
              <span className="body-row-header">Date/Time:</span>
              <span>{getDate(each.history_date)}</span>
              <span className="annotation" style={{ marginLeft: '5px' }}>
                {getTime(each.history_date)}
              </span>
            </div>
            <div>
              <span className="body-row-header">Details:</span>
              <span
                className="trim"
                style={{
                  pointerEvents: each.source_type === 'wallet_conversion' ? 'none' : 'auto'
                }}
              >
                {filterHistory(each.source_type, each.description, each.source_reference, each.direction)}
              </span>
            </div>

            <div>
              <span className="body-row-header">Amount ({activeCurrency}):</span>
              <span style={{ fontWeight: 600, color: each.direction === 'debit' ? '#F32345' : '#24B314' }}>{`${each.direction === 'debit' ? '-' : '+'
                }${formatAmount(each.amount)}`}</span>
            </div>
            <div>
              <span className="body-row-header">Balance After ({activeCurrency}):</span>
              <span style={{ color: 'rgba(0, 10, 26, 0.4)' }}>{formatAmount(each.balance_after)}</span>
            </div>
          </div>
        ))}
      </div>
    );
  };

  const renderReserveBalanceHistory = () => (
    <div>
      {reserveBalance?.data?.map(each => (
        <div className="div-table --balance-history --row --reserve-history" key={each.transaction_reference}>
          <div>
            <span className="body-row-header">Date/Time:</span>
            <span>{getDate(each.history_date)}</span>
            <span className="annotation" style={{ marginLeft: '5px' }}>
              {getTime(each.history_date)}
            </span>
          </div>
          <div>
            <span className="body-row-header">Details:</span>
            <span style={{ maxWidth: '500px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
              {filterHistory(each.source_type, each.description, each.transaction_reference, each.direction)}
            </span>
          </div>

          <div>
            <span className="body-row-header">Amount ({activeCurrency}):</span>
            <span style={{ fontWeight: 600, color: each.direction === 'debit' ? '#F32345' : '#24B314' }}>{`${each.direction === 'debit' ? '-' : '+'
              }${formatAmount(each.amount)}`}</span>
          </div>

          <div>
            <span className="body-row-header">Balance After ({activeCurrency}):</span>
            <span style={{ color: 'rgba(0, 10, 26, 0.4)' }}>{formatAmount(each.balance_after)}</span>
          </div>

          <div>
            <span className="body-row-header">Release Date:</span>
            <span style={{ color: 'rgba(0, 10, 26, 0.4)' }}>
              {each.source_type === 'rolling_reserve' || !each.available_on ? '--' : getDate(each.available_on)}
            </span>
          </div>
        </div>
      ))}
    </div>
  );

  const handleWithdrawButtonStatus = currentCurrency => {
    if (balances[currentCurrency]?.available_balance <= 0) {
      return true;
    }
    return !!(!defaultMerchant?.payout_limits?.disbursement_wallet?.USD?.min && currentCurrency === 'USD');
  };

  return (
    <>
      <ExportFilterModal close={() => setLargeExportModal(false)} email={profile.email} visible={showLargeExportModal} />
      <section className="balances__page">
        <section className="os-tabs-w">
          {!fetchingBalances && (
            <div className="os-tabs-controls os-tabs-complex">
              <ul className="nav nav-tabs" role="tablist">
                {Object.keys(balances).map(currency => (
                  <li className="nav-item" key={currency} role="tab">
                    <button
                      type="button"
                      data-testid="currency-tab"
                      className={`nav-link ${activeCurrency === currency ? 'active' : ''} ${!availableCurrencies?.includes(currency) ? 'disabled' : ''
                        }`}
                      onClick={() => {
                        if (!availableCurrencies?.includes(currency)) return;
                        searchQuery.setQuery({ currency, page: '1' });
                        searchQuery.setQuery({ currency, page: '1' });
                      }}
                    >
                      {currency}
                    </button>
                  </li>
                ))}
              </ul>
            </div>
          )}
        </section>
        <section className="history_summary_details">
          <div className="header-container">
            <div className="flex-1">
              <section className="balances__summary">
                {swapArrayPositions(Object.keys(balances?.[activeCurrency] || {}), 0, 1).map(balance => {
                  if (balance === 'ledger_balance' || balance === 'is_default') return null;

                  return (
                    <div
                      key={balance}
                      className={clsx('info-summary-item', { greyed: balance === 'pending_balance' || balance === 'reserve_balance' })}
                    >
                      <p>
                        {MERCHANT_ENV === 'test' && 'Test '}
                        {capitalizeRemovedash(balance)} ({activeCurrency})
                        {balance === 'reserve_balance' && (
                          <Tooltip
                            type="reserve balance info"
                            image={info}
                            message={
                              <section className="--reserve-tooltip">
                                <p>
                                  <strong>What is Reserve Balance?</strong>
                                </p>
                                <p>Your Reserve Balance shows the total amount of funds temporarily held in Reserve after settlement.</p>
                                <p>
                                  These funds are used to cater to chargebacks and refunds. However, in the case where no chargeback or
                                  refund occurs during the hold period, they are returned to your available balance.{' '}
                                </p>
                              </section>
                            }
                          />
                        )}
                      </p>
                      <p className="value">
                        {balances?.[activeCurrency]?.[balance] || balances?.[activeCurrency]?.[balance] === 0
                          ? formatAmount(balances[activeCurrency][balance])
                          : '--.--'}
                      </p>
                      <p className="description">{switchBalanceDescription[balance]}</p>
                    </div>
                  );
                })}
              </section>
            </div>

            <div className="history_summary_heading">
              <div className="__buttons">
                <AddFunds
                  virtualAccount={virtualAccount?.data || {}}
                  visible={modalStates.fundsModal}
                  close={() => setModalStates({ ...modalStates, fundsModal: true })}
                  currency={activeCurrency}
                  minWalletPayoutLimit={
                    defaultMerchant?.payout_limits?.disbursement_wallet?.[activeCurrency]?.min ||
                    (['NGN'].includes(activeCurrency) ? 1000 : 100)
                  }
                  balances={balances}
                  refetchBalance={refetchBalance}
                  walletPayoutLimit={defaultMerchant?.payout_limits?.disbursement_wallet?.[activeCurrency]?.max}
                  availableCurrency={defaultMerchant?.available_currency}
                />
                {defaultMerchant?.available_currency?.includes(activeCurrency) && userAccess === 'manage' && (
                  <Withdraw
                    banks={banks?.data || []}
                    currency={activeCurrency}
                    balances={balances}
                    refetchBalance={refetchBalance}
                    isFetchingBanks={isFetching}
                    minBankPayoutLimit={defaultMerchant?.payout_limits?.bank_account?.[activeCurrency]?.min || 1000}
                    minWalletPayoutLimit={
                      defaultMerchant?.payout_limits?.disbursement_wallet?.[activeCurrency]?.min ||
                      (['NGN'].includes(activeCurrency) ? 1000 : 100)
                    }
                    minMobileMoneyLimit={defaultMerchant?.payout_limits?.mobile_money?.[activeCurrency]?.min || 100}
                    bankPayoutLimit={defaultMerchant?.payout_limits?.bank_account?.[activeCurrency]?.max || 5000000}
                    walletPayoutLimit={defaultMerchant?.payout_limits?.disbursement_wallet?.[activeCurrency]?.max}
                    mobileMoneyLimit={defaultMerchant?.payout_limits?.mobile_money?.[activeCurrency]?.max || 150000}
                    disabled={handleWithdrawButtonStatus(activeCurrency)}
                    payoutLimitDetails={defaultMerchant?.payout_limits}
                    bankWithdrawalLimit={defaultMerchant?.withdrawal_limits?.bank_account?.[activeCurrency] || {}}
                    settlementAccounts={settlementAccounts}
                  />
                )}
                {['NGN', 'USD'].includes(activeCurrency) && (
                  <ConvertFunds
                    visible={modalStates.convertFundsModal}
                    close={() => setModalStates({ ...modalStates, convertFundsModal: true })}
                    currency={activeCurrency}
                    minPayoutLimit={defaultMerchant?.payout_limits?.disbursement_wallet?.[activeCurrency]?.min ||
                      (['NGN'].includes(activeCurrency) ? 1000 : 100)}
                    balances={balances}
                    refetchBalance={refetchBalance}
                    maxPayoutLimit={defaultMerchant?.payout_limits?.disbursement_wallet?.[activeCurrency]?.max}
                  />
                )}
              </div>
            </div>
          </div>
        </section>

        <section className="os-tabs-w">
          <div className="os-tabs-controls os-tabs-complex balances__history-tabs">
            <ul className="nav nav-tabs" role="tablist">
              {tabs.map(tab => {
                if (!balances[activeCurrency]?.hasOwnProperty('reserve_balance') && tab === 'Reserve') return null;
                return (
                  <li
                    className="nav-item"
                    key={tab}
                    onClick={() => {
                      searchQuery.setQuery({ tab, currency: activeCurrency }, true);
                    }}
                    onKeyUp={() => searchQuery.setQuery({ tab, currency: activeCurrency }, true)}
                    role="tab"
                    tabIndex={0}
                  >
                    <button type="button" className={`nav-link ${activeTab === tab && 'active'}`}>
                      {tab}
                      {' History '}
                    </button>
                  </li>
                );
              })}
            </ul>
            <div className="balances__history-export-button">
              <div>
                {(userAccess === 'manage' || userAccess === 'export') && (
                  <button
                    type="button"
                    className="btn btn-secondary"
                    style={{ background: 'none', border: 'none', color: '#2376F3' }}
                    onClick={() => setModalStates({ ...modalStates, exportModal: true })}
                  >
                    <i className="os-icon os-icon-arrow-up-right" />
                    <span>Export History</span>
                  </button>
                )}
              </div>
            </div>
          </div>
        </section>
        <Table
          hasPagination={!loadingList.includes(activeCurrency)}
          tableClassName={`--balance-history ${activeTab === 'Reserve' && ' --reserve-history'}`}
          headings={
            activeTab === 'Balance'
              ? [
                {
                  value: 'Date/Time'
                },
                {
                  value: 'Details'
                },
                {
                  value: `Amount (${activeCurrency})`
                },
                {
                  value: `Balance After (${activeCurrency})`
                }
              ]
              : activeTab === 'Reserve' && [
                {
                  value: 'Date/Time'
                },
                {
                  value: 'Details'
                },
                {
                  value: `Amount (${activeCurrency})`
                },
                {
                  value: `Balance After (${activeCurrency})`
                },
                {
                  value: 'Release Date'
                }
              ]
          }
          totalItems={
            activeTab === 'Balance'
              ? getCurrencyHistory(activeCurrency)?.paging
                ? getCurrencyHistory(activeCurrency).paging.total_items
                : 0
              : (activeTab === 'Reserve' && reserveBalance?.paging?.total_items) || 0
          }
          pageSize={
            activeTab === 'Balance'
              ? getCurrencyHistory(activeCurrency)?.paging
                ? getCurrencyHistory(activeCurrency).paging.page_size
                : 0
              : (activeTab === 'Reserve' && reserveBalance?.paging?.page_size) || 0
          }
          loading={Object.keys(balances).length === 0 || loadingList.includes(activeCurrency)}
          current={parseInt(currentPage, 10)}
          limitAction={c => searchQuery.setQuery({ limit: String(c) })}
          actionFn={current => searchQuery.setQuery({ page: String(current) })}
          annotation="links"
          emptyStateHeading="No entries yet"
          emptyStateMessage={`There is no ${activeTab === 'Reserve' ? 'reserve ' : ''}balance history yet.`}
          tableWrapperClassName="element-box-tp"
        >
          {activeTab === 'Balance' ? renderBalanceHistory() : activeTab === 'Reserve' && renderReserveBalanceHistory()}
        </Table>
        {modalStates.exportModal && (
          <AdvanceExportModal
            openExport={modalStates.exportModal}
            setOpenExport={() => setModalStates({ ...modalStates, exportModal: false })}
            exportAction={activeTab === 'Reserve' ? exportRollingReserveHistory : exportBalanceHistory}
            type="balances"
            dateRange
            showSuccessModal={false}
          />
        )}
      </section>
    </>
  );
}

export default Balances;
