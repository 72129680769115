type queriesParamType = 'current' | 'limit' | 'page' | 'tab' | 'subTab' | 'sorterType' | 'currency' | 'status' | 'isFilterVisible';

// eslint-disable-next-line no-unused-vars
export const queriesParams: { [k in queriesParamType]: queriesParamType } = {
  current: 'current',
  currency: 'currency',
  page: 'page',
  limit: 'limit',
  tab: 'tab',
  subTab: 'subTab',
  sorterType: 'sorterType',
  status: 'status',
  isFilterVisible: 'isFilterVisible'
};

export const cardStatus = {
  pre_authorized: 'Pre-Authorized',
  void_authorization: 'Voided (Auth)',
  void_capture: 'Voided (Capture)'
};
