import { useEffect, useRef } from 'react';
import { actions } from '+utils';
import APIRequest from '+services/api-services';
import useStore from '+store';

const api = new APIRequest();
const cacheTime = process.env.REACT_APP_CACHE_TIME || 15
const MAX_INACTIVE_DURATION = cacheTime * 60 * 1000;

export default function useInactivityTracker() {
  const timeout = useRef(null);
  const didSessionTimeout = useRef(false);

  function logoutUserOut() {
    didSessionTimeout.current = true;
    api.logout();
    actions.track('merchant logged out');
    delete window.visitedKorapay;
    useStore.setState({ sessionActive: false });
  }

  function resetActivity() {
    if (timeout.current) {
      clearTimeout(timeout.current);
    }

    timeout.current = setTimeout(() => {
      logoutUserOut();
      removeListeners();
    }, MAX_INACTIVE_DURATION);
  }

  function removeListeners() {
    events.forEach(evtName => {
      document.removeEventListener(evtName, resetActivity, true);
    });
  }

  const events = ['mousemove', 'keydown', 'scroll', 'touchstart'];

  function addListeners() {
    events.forEach(evtName => {
      document.addEventListener(evtName, resetActivity, true);
    });
  }

  useEffect(() => {
    addListeners();
    resetActivity(); // initial run

    return () => {
      removeListeners();
      if (timeout.current) {
        clearTimeout(timeout.current);
      }
    };
  }, []);

  return {
    didSessionTimeout: didSessionTimeout.current
  };
}
