import React, { useEffect } from 'react';
import { useMutation } from 'react-query';
import { useDebouncedCallback } from 'use-debounce';
import { useCountdown } from './Countdown';
import { useProcessConversion, useFeedbackHandler, useConversionLogics } from '+hooks';
import {
  backwardAmountInput,
  cleanInput,
  logError,
  formatAmount,
} from '+utils';
import Modal from '+shared/Modal';
import APIRequest from '+services/api-services';
import { IFundByWalletModalProps, WalletModal, ListDropdownProps } from '+types';
import CurrencyConversionForm from '+containers/Dashboard/Shared/CurrencyConversionForm';

import './index.scss';

const publicApi = new APIRequest(process.env.REACT_APP_PUBLIC_MERCHANT_MIDDLEWARE_API_BASE);

const ConversionsModal: React.FC<IFundByWalletModalProps & WalletModal & ListDropdownProps> = ({ close, currency }) => {
  const { feedbackInit, closeFeedback } = useFeedbackHandler();
  const {
    state,
    setState,
    rate,
    toAmount,
    availableBalance,
    minConversionLimit,
    maxConversionLimit,
    errorMessage,
    validateAmount,
    setErrorMessage,
    queryData,
    conversionsQueryData,
    currencyArray } = useConversionLogics();

  const { amount, data, payloadCurrency } = state;

  useEffect(() => {
    if (currency) {
      setState({ payloadCurrency: { ...state.payloadCurrency, from: currency, to: currency === 'USD' ? currencyArray[0] : 'USD' } });
    }
  }, [currency]);

  const handleCountdownCompletion = async () => {
    setState({ countdownCompleted: true, isLoading: true });
    await initiateConversion.mutateAsync();
    setState({ countdownCompleted: false });
  };

  const initiateConversion = useMutation('SWAP_WALLET', () => publicApi.initiateConversion(queryData), {
    onSuccess: async data => {
      const response = data?.data;
      setState({ exchange: response || {}, data: response || null });
      setState({ countdownCompleted: false, isLoading: false });
    },
    onError: (error: any) => {
      logError(error);
      feedbackInit({
        componentLevel: true,
        message: error.response?.data?.message || 'There has been an error getting swap information',
        type: 'danger'
      });
      if (error?.response?.data?.message?.includes('Too many requests' || 'invalid request data') && !checkLimitAndValidate) {
        setTimeout(() => {
          close();
        }, 7000);
      }
    }
  });

  const { processConversionMutation } = useProcessConversion(conversionsQueryData);

  const validate = () => {
    if (data !== null && amount !== '' && !initiateConversion.isLoading) {
      const parsedAmount = parseFloat(amount);
      const maxLimit = maxConversionLimit !== undefined ? Math.min(maxConversionLimit, availableBalance) : availableBalance;

      return parsedAmount >= minConversionLimit && parsedAmount <= maxLimit;
    }

    return false;
  };

  const checkLimitAndValidate = validate();

  const { currentCount, resetCountdown } = useCountdown({
    countFrom: state.countdown,
    isStartCount: state.startCount,
    isRestartCountdown: true,
    callbackFn: handleCountdownCompletion
  });

  useEffect(() => {
    if (checkLimitAndValidate) {
      setState({ startCount: true });
    } else {
      setState({ startCount: false });
      resetCountdown();
    }
  }, [checkLimitAndValidate]);

  const debouncedFetchData = useDebouncedCallback(async () => {
    if (validateAmount()) {
      const result = await initiateConversion.mutateAsync();
      setState({ apiData: result?.data });
    }
  }, 1000);

  const handleSelectChange = (e: React.ChangeEvent<HTMLSelectElement>, name: string): void => {
    const selectedCurrency = e.target.value;

    if (name === "currencyFrom") {
      setState({
        payloadCurrency: {
          ...state.payloadCurrency,
          from: selectedCurrency,
        },
      });
    } else if (name === "currencyTo") {
      setState({
        payloadCurrency: {
          ...state.payloadCurrency,
          to: selectedCurrency,
        },
      });
    }
    debouncedFetchData();
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const inputValue = e.target.value;
    const formattedAmount = backwardAmountInput(inputValue);
    if (inputValue === '') {
      setState({ apiData: null, amount: inputValue });
      return;
    }
    setState({ amount: formattedAmount as typeof amount });
    debouncedFetchData();
    if (inputValue < amount || inputValue === '') {
      setState({ data: null });
      return;
    }
  };

  const handleSwitchCurrency = () => {
    const { from, to } = state.payloadCurrency;
    setState({
      payloadCurrency: {
        from: to,
        to: from
      }
    });
  };

  const switchWalletModal = () => {
    const content = {
      modalSmClassName: '.modal-content-sm',
      heading: 'Quick Currency Conversion',
      description: 'Easy and instant conversions between currencies. Note that exchange rates are dynamic and may change over time.',
      content: (
        <CurrencyConversionForm
          formType="modal"
          payloadCurrency={payloadCurrency}
          currencyArray={currencyArray}
          handleSelectChange={handleSelectChange}
          handleSwitchCurrency={handleSwitchCurrency}
          state={state}
          availableBalance={availableBalance}
          errorMessage={errorMessage}
          checkLimitAndValidate={checkLimitAndValidate}
          formatAmount={formatAmount}
          rate={rate}
          toAmount={toAmount}
          isLoading={initiateConversion.isLoading}
          amount={amount}
          handleInputChange={handleInputChange}
          cleanInput={cleanInput}
          currentCount={currentCount}
          initiateConversion={initiateConversion}
        />
      ),
      firstButtonText: 'Cancel',
      secondButtonText: 'Convert',
      secondButtonActionIsTerminal: true,
      secondButtonDisable: !checkLimitAndValidate,
      firstButtonAction: close,
      secondButtonAction: async () => { await processConversionMutation() },
      completedHeading: 'Conversion Successful',
      completedDescription: 'You have successfully completed this  conversion.',
      completedActionText: 'Dismiss',
      completedAction: close
    };

    return {
      size: 'md',
      close: close,
      ...content
    };
  };

  return (
    <Modal {...switchWalletModal()} />
  );
};

export default ConversionsModal;
