import { ISODateString } from '+types/transaction-types';
import { downloadFileViaUrl, getDate, getTime } from '+utils';

export type FileDownloadListProps = {
  title?: string | JSX.Element;
  data: Array<{ dateOfUpload?: ISODateString; fileUrl: string; fileName?: string }>;
};

const FileDownloadList = ({ data, title = `Supporting documents uploaded by merchant to validate this chargeback.` }: Props) => {
  const handleDownload = (url: URL) => downloadFileViaUrl(url, 'evidence', 'application/pdf');

  return (
    <>
      {data?.length ? (
        <div className="chargeback-docs__container">
          <>
            <p style={{ color: '#9fa6ae', fontWeight: 400 }}>{title}</p>
            <ul className="chargeback-docs__list">
              {data.map(({ dateOfUpload, fileUrl, fileName }, index) => (
                <li className="chargeback-docs__item" key={dateOfUpload}>
                  <span className="chargeback-docs__info">
                    <svg width="18" height="21" viewBox="0 0 18 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M18 7.40723V19.4002C18.0009 19.5315 17.976 19.6618 17.9266 19.7834C17.8772 19.9051 17.8043 20.0159 17.7121 20.1094C17.6199 20.2029 17.5101 20.2773 17.3892 20.3284C17.2682 20.3795 17.1383 20.4063 17.007 20.4072H0.993C0.729813 20.4072 0.477391 20.3027 0.291196 20.1167C0.105001 19.9307 0.000265042 19.6784 0 19.4152V1.39923C0 0.862227 0.447 0.407227 0.998 0.407227H11V6.40723C11 6.67244 11.1054 6.9268 11.2929 7.11433C11.4804 7.30187 11.7348 7.40723 12 7.40723H18ZM18 5.40723H13V0.410227L18 5.40723Z"
                        fill="#292B2C"
                      />
                    </svg>
                    <span>{`Evidence ${index + 1}`}</span>
                    <span>
                      <span>{getDate(dateOfUpload)}</span> · <span>{getTime(dateOfUpload)}</span>
                    </span>
                  </span>
                  <a href={fileUrl} target="_blank" rel="noopener noreferrer" className="chargeback-docs__link">
                    <span>Download</span>
                    <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M2.92892 18.0613C4.81769 19.9501 7.32893 20.9902 10 20.9902C12.6711 20.9902 15.1823 19.9501 17.0711 18.0613C18.9598 16.1725 20 13.6613 20 10.9902C20 8.31916 18.9598 5.80792 17.0711 3.91916C15.1823 2.03039 12.6711 0.990235 10 0.990235C7.32889 0.990235 4.81768 2.03039 2.92892 3.91916C1.04015 5.80792 -1.10774e-06 8.31912 -8.74228e-07 10.9902C-6.40712e-07 13.6613 1.04015 16.1726 2.92892 18.0613ZM5.58033 11.8913C5.67999 11.6508 5.9147 11.4939 6.17509 11.4939L7.82626 11.4939L7.82626 6.01779C7.82626 5.66226 8.11449 5.37402 8.47002 5.37402L11.53 5.37402C11.8855 5.37402 12.1737 5.66226 12.1737 6.01779L12.1737 11.4939L13.825 11.4939C14.0853 11.4939 14.3201 11.6507 14.4197 11.8913C14.5193 12.1319 14.4643 12.4088 14.2801 12.5929L10.4552 16.4179C10.3345 16.5386 10.1708 16.6064 10 16.6064C9.82932 16.6064 9.66554 16.5386 9.54486 16.4179L5.71995 12.5929C5.53578 12.4088 5.48068 12.1319 5.58033 11.8913Z"
                        fill="#2376F3"
                      />
                    </svg>
                  </a>
                </li>
              ))}
            </ul>
          </>
        </div>
      ) : (
        <div style={{ color: '#a9afbc', fontWeight: 400, fontStyle: 'italic', fontSize: '1rem', textAlign: 'center' }}>
          No documents uploaded for this chargeback
        </div>
      )}
    </>
  );
};

export default FileDownloadList;
