import { useState } from 'react';
import { useQuery, useQueryClient } from 'react-query';
import { useParams } from 'react-router-dom';

import FileDownloadList from '+containers/Dashboard/Shared/FileDownloadList';
import IssuanceChargebackHistory from '+containers/Dashboard/TransactionDetails/components/IssuanceChargebackHistory';
import IssuanceChargebackModal from '+containers/Dashboard/TransactionDetails/components/IssuanceChargebackModal';
import { chargebacksTrxnMessage } from '+containers/Dashboard/TransactionDetails/data';
import TransactionDetails from '+containers/Dashboard/TransactionDetailsNew';
import { ActionButtons } from '+containers/Dashboard/TransactionDetailsNew/types';
import { useFeedbackHandler, useSearchQuery } from '+hooks';
import APIRequest from '+services/api-services';
import { Currency, IChargebackTransactionDetails, ISODateString } from '+types';
import { history } from '+utils';
import useStore from '+store';

import {
  generateCardholderFrom,
  generateDocumentsFrom,
  generateMoreDetailsFrom,
  generateSummaryFrom
} from './TransactionDetailsUtils/Chargeback';

const publicApi: APIRequest = new APIRequest(process.env.REACT_APP_PUBLIC_MERCHANT_MIDDLEWARE_API_BASE);

const ChargebackTransactionDetails = () => {
  const { id } = useParams<{ id: string }>();
  const { permissions, issuingBalance } = useStore.getState();
  const { value: searchQueryValue } = useSearchQuery();
  const currency = (searchQueryValue?.currency as Currency) ?? 'USD';
  const { feedbackInit } = useFeedbackHandler();
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const cachedIssuingBalance = issuingBalance?.[currency]?.available_balance ?? 0;

  const { data, isLoading, refetch } = useQuery<IChargebackTransactionDetails>(
    `TRANSACTION_DETAILS_${id}`,
    () => publicApi.fetchSingleIssuedCardChargeback(id),
    {
      onError: () => {
        feedbackInit({
          message: `There has been an error fetching the details for the transaction: ${id.toUpperCase()}.`,
          type: 'danger'
        });
        history.goBack();
      }
    }
  );

  const summaryList = generateSummaryFrom(data as IChargebackTransactionDetails);
  const moreDetailsList = generateMoreDetailsFrom(data as IChargebackTransactionDetails);
  const cardholderInfoList = generateCardholderFrom(data as IChargebackTransactionDetails);
  const documentsList = generateDocumentsFrom((data as IChargebackTransactionDetails)?.status_history);
  const canBeReescalated = ['partially_accepted', 'declined', 'invalid'].includes(data?.status as string);
  const actionButtons: Array<ActionButtons> = [
    {
      children: (
        <>
          Re-escalate... <i className="os-icon os-icon-corner-up-right" />
        </>
      ),
      onClick: () => setModalIsOpen(true),
      disabled: !canBeReescalated,
      variant: 'secondary',
      hidden:
        ['accepted', 'accepted_pre_arbitration', 'declined_pre_arbitration'].includes(data?.status as string) ||
        permissions.card_issuance_chargeback !== 'manage'
    }
  ];

  return (
    <>
      <TransactionDetails>
        <TransactionDetails.Header
          heading={(data?.amount as unknown) as number}
          currency={data?.currency}
          status={chargebacksTrxnMessage[(data as IChargebackTransactionDetails)?.status]?.name}
          statusBg={chargebacksTrxnMessage[(data as IChargebackTransactionDetails)?.status]?.backgroundColor}
          statusColor={chargebacksTrxnMessage[(data as IChargebackTransactionDetails)?.status]?.color}
          actionButtons={actionButtons}
          summaries={summaryList}
          isLoading={isLoading}
        />

        <TransactionDetails.Section isLoading={isLoading} heading="More Transaction Details" summaries={moreDetailsList} />

        <TransactionDetails.Section isLoading={isLoading} heading="Cardholder's Information" summaries={cardholderInfoList} />

        <TransactionDetails.Section isLoading={isLoading} heading="Chargeback Timeline">
          <IssuanceChargebackHistory data={(data as IChargebackTransactionDetails)?.status_history} />
        </TransactionDetails.Section>

        <TransactionDetails.Section isLoading={isLoading} heading="Chargeback Documents">
          <FileDownloadList data={documentsList} />
        </TransactionDetails.Section>
      </TransactionDetails>

      <IssuanceChargebackModal
        modalIsVisible={modalIsOpen}
        transactionCurrency={data?.currency as string}
        transactionAmount={data?.amount as string}
        transactionDate={data?.transaction_date as ISODateString}
        issuingBalance={cachedIssuingBalance as number}
        actualResolutionDate={data?.actual_resolution_date as ISODateString}
        expectedResolutionDate={data?.expected_resolution_date as ISODateString}
        chargebackStatus={data?.status as IChargebackTransactionDetails['status']}
        chargebackCategory={data?.category as IChargebackTransactionDetails['category']}
        reference={data?.reference as string}
        refundedAmount={data?.accepted_amount as string}
        cardScheme={data?.card_brand as IChargebackTransactionDetails['card_brand']}
        refetchTransactionDetails={refetch}
        closeModal={() => setModalIsOpen(false)}
      />
    </>
  );
};

export default ChargebackTransactionDetails;
