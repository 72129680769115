import { useState } from 'react';
import dayjs from 'dayjs';
import DatePicker from 'react-datepicker';
import CustomCheckbox from './CustomCheckbox';
import { capitalizeRemovedash, durationToday, durationWeek } from '+utils';
import Modal from './Modal';

interface IExportModalProps {
  close: () => void;
  action: (format?: string, durations?: any) => void;
  dateRange?: boolean;
  options?: Record<string, string> | null;
}

const ExportModal = ({ close, action, dateRange = false, options = null }: IExportModalProps) => {
  function markAll() {
    const newObj: Record<string, boolean> = {};
    Object.keys(options || {}).map(i => {
      newObj[i] = true;
    });
    return newObj;
  }

  const [format, setFormat] = useState('csv');
  const [startDate, setStartDate] = useState<Date | null>(null);
  const [endDate, setEndDate] = useState<Date | null>(null);
  const [rangeSelected, setRangeSelected] = useState('all_time');
  const [selectedColumn, setSelectedColumn] = useState('all');
  const [details, setDetails] = useState({
    kind: markAll(),
    format: 'csv',
    selected: Object.keys(options || {})
  });

  const rangeOptions = ['today', 'last_7_days', 'all_time', 'custom_range'];

  const switchDuration: Record<string, string[] | null[] > = {
    today: durationToday(),
    last_7_days: durationWeek(),
    all_time: [null, null]
  };

  const getDuration = () => {
    const start = startDate ? dayjs(startDate).format('YYYY-MM-DD') : null;
    const end = endDate ? dayjs(endDate).format('YYYY-MM-DD') : null;
    if (rangeSelected === 'custom_range') return [start, end];
    return switchDuration[rangeSelected];
  };

  const selectedKinds = () => {
    return Object.keys(details.kind).filter(kind => details.kind[kind]);
  };

  const ExportContent = () => (
    <div className="row">
      <div className="form-group col-12">
        <label htmlFor="format">Export As</label>
        <select className="form-control" name="format" onChange={e => setFormat(e.target.value)} value={format}>
          <option value="csv">CSV (.csv)</option>
          <option value="excel">Excel 2007 and later (.xlsx)</option>
        </select>
      </div>

      {dateRange && (
        <section className="form-group col-12">
          <label htmlFor="format mb-2">Date Range</label>
          <section>
            {rangeOptions.map(option => (
              <div className="form-check" key={option}>
                <label>
                  <input
                    className="form-check-input"
                    type="radio"
                    checked={rangeSelected === option}
                    onChange={e => {
                      if (e.target.checked) setRangeSelected(option);
                    }}
                  />
                  {capitalizeRemovedash(option)}
                </label>
              </div>
            ))}
          </section>
        </section>
      )}

      {rangeSelected === 'custom_range' && (
        <section className="form-group col-12" style={{ display: 'flex' }}>
          <div className="form-group filter-object" style={{ marginRight: '10px' }}>
            <label htmlFor="from">From</label>
            <DatePicker
              selected={startDate}
              dateFormat="yyyy-MM-dd"
              onChange={date => setStartDate(date)}
              placeholderText="dd-mm-yyyy"
              calendarClassName="custom-datepicker"
            />
          </div>
          <div className="form-group filter-object">
            <label htmlFor="label">To</label>
            <DatePicker
              selected={endDate}
              dateFormat="yyyy-MM-dd"
              minDate={startDate || null}
              onChange={date => setEndDate(date)}
              placeholderText="dd-mm-yyyy"
              calendarClassName="custom-datepicker"
            />
          </div>
        </section>
      )}

      {Object.keys(options || {}).length > 0 && (
        <>
          <div className="form-group col-12">
            <label htmlFor="column">What column(s) would you like to have in the exported file?</label>
            <select
              className="form-control"
              name="column"
              onChange={e => {
                setSelectedColumn(e.target.value);
              }}
              value={selectedColumn}
            >
              <option value="all">All</option>
            </select>
          </div>
          <div className="d-flex flex-wrap">
            {Object.keys(options || {}).map(option => (
              <div className="col-sm-4" key={option}>
                <div className="mb-3" key={option}>
                <CustomCheckbox
                    disabled={selectedColumn === 'all'}
                    checked={details.kind[option]}
                    onChange={value => {
                      details.kind[option] = value;
                      setDetails({ ...details, selected: selectedKinds() });
                    }}
                    text={options ? options[`${option}`] : ''}
                  />
                </div>
              </div>
            ))}
          </div>
        </>
      )}
    </div>
  );

  return (
    <Modal
      close={close}
      heading="Export Transactions"
      description="Choose how you would like to export these transactions."
      content={<ExportContent />}
      size="md"
      secondButtonText="Export"
      secondButtonColor="#2376F3"
      secondButtonAction={() => action(format, ...getDuration())}
      completedHeading="Export Complete"
      completedDescription="Your file has been exported and is ready for download."
    />
  );
}


export default ExportModal;
