/* eslint-disable no-unused-vars */
import { useState } from 'react';
import { useQuery, useQueryClient } from 'react-query';

import Table from '+containers/Dashboard/Shared/Table';
import { useFeedbackHandler, useSearchQuery } from '+hooks';
import useStore from '+store';
import { Currency } from '+types';
import { logError } from '+utils';

import AccessRequestPostOnboarding from '../components/AccessRequestPostOnboarding';
import CardCreationModal from '../components/CardCreationModal';
import Filter from '../components/Filter/Filter';
import FundIssuingWalletModal from '../components/FundIssuingWalletModal';
import Summary from '../components/Summary';
import { computeTableTitle, exportActionOptions, getReservedCardsFilterProps, publicApi, reservedCardsTableProps } from '../constants';
import { useFetchIssuanceTables, useFetchIssuingExport } from '../hooks';
import { IError, IIssuingStoreState } from '../types';
import { getIssuingPermissions } from '../utils';
import ReservedCardsRow from './components/ReservedCardsRow';

const PRE_FUND_AMOUNT = Number(process.env.REACT_APP_MIN_RVC_PREFUND_AMOUNT || '10000');

const ReservedCardsIndex = () => {
  const queryClient = useQueryClient();
  const { value: searchQueryValue, setQuery } = useSearchQuery();
  const limit = searchQueryValue?.limit ?? '10';
  const page = searchQueryValue?.page ?? '1';
  const tab = searchQueryValue?.tab as 'reserved_cards';
  const currency = (searchQueryValue?.currency as Currency) ?? 'USD';
  const { merchantEnv: MERCHANT_ENV, cardIssuanceCardAccess, issuingBalance, issuingFees } = useStore(store => store) as IIssuingStoreState;
  const [recordIsFiltered, setRecordIsFiltered] = useState(false);
  const { canViewCardList } = getIssuingPermissions();
  const exportAction = useFetchIssuingExport({ resourceType: tab, exportFn: exportActionOptions[tab] });
  const { feedbackInit, closeFeedback } = useFeedbackHandler();
  const [showCardCreation, setShowCardCreation] = useState(false);
  const [showWalletFunding, setShowWalletFunding] = useState(false);
  const balance = issuingBalance?.[currency]?.available_balance;
  const cardCreationFee = issuingFees?.reserved?.issuance;
  const balanceIsEnough = Number(balance ?? '0') > cardCreationFee + PRE_FUND_AMOUNT;
  const filterProps = getReservedCardsFilterProps();

  const { tableData, isFetching: isFetchingTableData, refetch: refetchTableData } = useFetchIssuanceTables({
    page,
    limit,
    tab,
    queryValue: searchQueryValue
  });

  const { data: reservedCardStats, refetch: refetchReservedCardsStats } = useQuery(
    ['RESERVED_CARD_STATS', MERCHANT_ENV],
    () => publicApi.fetchIssuedCardsStats({ cardCategory: 'reserved' }),
    {
      onError: (e: IError) => {
        logError(e);
        const message = e.response?.data?.message;
        feedbackInit({
          message,
          type: 'danger',
          action: {
            action: refetchReservedCardsStats,
            name: 'Try again'
          }
        });
      }
    }
  );

  const { data: merchantName } = useQuery('ISSUING_MERCHANT_NAME', () => publicApi.fetchReservedCardholderName(), {
    onError: (e: IError) => {
      logError(e);
      const message = e.response?.data?.message || 'There has been an error fetching the merchant name';
      feedbackInit({
        message,
        type: 'danger',
        componentLevel: false
      });
      setTimeout(() => closeFeedback(), 5000);
    },
    enabled: cardIssuanceCardAccess?.access?.reserved?.status === 'active'
  });
  const paging = tableData?.data?.paging;

  return (
    <div>
      <div className="d-flex w-100 flex-wrap flex-sm-nowrap">
        <div className="flex-grow-1 info-summary-container">
          <Summary label="Total Cards Issued" value={reservedCardStats?.total_cards} description="All cards issued for my business" />
          <Summary
            label="Active Cards"
            value={reservedCardStats?.total_active_cards}
            description="Cards being used to perform transactions"
          />
        </div>

        <div>
          <div className="page-action-btns">
            <button onClick={() => setShowCardCreation(true)} className="btn btn-primary p-2" type="button">
              <span className="os-icon os-icon-plus" />
              <span>New Virtual Card</span>
            </button>
          </div>
        </div>
      </div>

      <Filter
        totalItems={paging?.total_items as number}
        title={computeTableTitle({
          singularTitle: 'card',
          pluralTitle: 'cards',
          filtered: recordIsFiltered,
          activeTab: tab as string,
          pageItemCount: paging?.total_items as number
        })}
        actions={filterProps.actions}
        exportType={filterProps.exportType as string}
        quickFilterType={tab}
        exportHeading={filterProps.exportHeading as string}
        exportDescription={filterProps.exportHeading as string}
        onChangeIsFiltered={setRecordIsFiltered}
        isFiltered={recordIsFiltered}
        exportAction={exportAction}
      />

      <section className="transaction_table_comp table-container">
        <Table
          tableClassName={`--history-table ${reservedCardsTableProps.tableClassName}`}
          headings={reservedCardsTableProps.headings}
          hasPagination
          borderedTable
          loading={isFetchingTableData}
          current={paging?.current}
          limitAction={value => setQuery({ limit: String(value) })}
          pageSize={paging?.page_size}
          actionFn={value => setQuery({ page: String(value) })}
          totalItems={paging?.total_items || 0}
          emptyStateHeading={reservedCardsTableProps.emptyStateHeading}
          emptyStateMessage={
            <>
              <span>{reservedCardsTableProps.emptyStateMessage}</span>
              {canViewCardList ? (
                <button type="button" className="refetch-button" onClick={() => refetchTableData()}>
                  <i className="os-icon os-icon-rotate-ccw" style={{ marginRight: '5px' }} />
                  Refresh
                </button>
              ) : null}
            </>
          }
        >
          <ReservedCardsRow rowData={tableData?.data?.data} />
        </Table>
      </section>

      {showCardCreation ? (
        <CardCreationModal
          onClose={() => setShowCardCreation(false)}
          gotoStage={balanceIsEnough ? 'CREATE' : 'FUND'}
          merchantInfo={merchantName}
          onFundBalance={() => setShowWalletFunding(true)}
        />
      ) : null}

      {showWalletFunding ? (
        <FundIssuingWalletModal
          balances={issuingBalance as Record<Currency, { available_balance: number }>}
          currency={currency}
          onClose={() => setShowWalletFunding(false)}
          onFundingSuccess={() => queryClient.invalidateQueries('ISSUING_BALANCE')}
        />
      ) : null}
    </div>
  );
};

const ReservedCards = () => {
  const { cardIssuanceCardAccess } = useStore.getState() as IIssuingStoreState;

  if (cardIssuanceCardAccess?.access?.reserved?.status !== 'active') {
    return <AccessRequestPostOnboarding activeTab="reserved_cards" accessStatus={cardIssuanceCardAccess?.access?.reserved?.status} />;
  }

  return <ReservedCardsIndex />;
};

export default ReservedCards;
