import { CardDetailResponseType } from '../../types';
import { generateCardSummary } from '../../utils';

const CardSummary = ({ cardData, cardId }: { cardData: CardDetailResponseType; cardId: string }) => {
  const cardSummary = generateCardSummary({ cardDetails: cardData, cardId });

  return (
    <div className="info-side">
      <h6 className="mb-3">Summary</h6>
      {cardSummary.map(item => (
        <div key={item.label} className="summary-item">
          <span className="prop">{item?.label}</span>
          <span className="value">
            {item?.before}
            {item?.value}
          </span>
        </div>
      ))}
    </div>
  );
};
export default CardSummary;
