import { balanceHistoryFilterFields, issuedCardChargebacksFilterField, singleCardTxFilterFields } from '../../data';

type ComputeTableTitleProps = {
  filtered: boolean;
  activeTab: string;
  pageItemCount: string | number;
  title?: string;
};

export const computeTableTitle = ({ filtered, pageItemCount, title = 'Transaction' }: ComputeTableTitleProps) => {
  return `${filtered ? 'Filtered ' : 'All '}${title} (${pageItemCount || 0})`;
};

export const cardDetailsFilterProps = {
  transactions: {
    filterModalHeading: 'Filter Card Transactions',
    filterModalDescription: 'Use any of the following advanced filter controls to find more card transactions.',
    filterFields: singleCardTxFilterFields,
    exportType: 'card-transactions',
    exportHeading: 'Export Card Transactions',
    exportDescription: 'Choose how you would like to export these records.'
  },
  balance_history: {
    filterModalHeading: 'Filter Balance History',
    filterModalDescription: 'Use any of the following advanced filter controls to find more card transactions.',
    filterFields: balanceHistoryFilterFields,
    exportType: 'card-transactions',
    exportHeading: 'Export Balance History',
    exportDescription: 'Choose how you would like to export these records.'
  },
  events: {
    exportType: 'card-events',
    exportHeading: 'Export Card Events',
    exportDescription: 'Choose how you would like to export these records.'
  },
  chargebacks: {
    filterModalHeading: 'Filter Card Transactions',
    filterModalDescription: 'Use any of the following advanced filter controls to find more card transactions.',
    filterFields: issuedCardChargebacksFilterField,
    quickFilterType: 'issued-card-chargebacks',
    exportType: 'issued-card-chargebacks',
    exportHeading: 'Export Chargebacks',
    exportDescription: 'Choose how you would like to export these transactions.'
  }
};
