import React, { useEffect, useRef, useState } from 'react';
import { useMutation, useQuery } from 'react-query';
import { useFormik } from 'formik';

import Modal from '+containers/Dashboard/Shared/Modal';
import Table from '+containers/Dashboard/Shared/Table';
import useFeedbackHandler from '+hooks/feedbackHandler';
import APIRequest from '+services/api-services';
import useStore from '+store';
import { cleanInput, history, logError, twoFAInputValidation } from '+utils';
import { getDate, getTime } from '+utils/formats';

import ActionButton from './components/ActionButton';
import IPNotification from './components/IPNotification';

import ArrowLeft from '+assets/img/dashboard/arrow-left.svg';
import DeleteIcon from '+assets/img/dashboard/delete.svg';
import EditIcon from '+assets/img/dashboard/edit.svg';

import './index.scss';

const apiRequest = new APIRequest(process.env.REACT_APP_MERCHANT_MIDDLEWARE_API_BASE);
interface IPAddressType {
  ip_address?: string;
  description?: string;
  created_at?: string;
}

type HandleActionType = 'edit' | 'add' | 'disable' | 'delete' | 'enable';

type ActionType =
  | 'add_merchant_ip_address'
  | 'update_merchant_ip_address'
  | 'remove_merchant_ip_address'
  | 'update_merchant_ip_address_status';

function IPWhiteList() {
  const { feedbackInit, closeFeedback } = useFeedbackHandler();
  const [ipEnabled, setIpEnabled] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [type, setType] = useState<'otpStage' | 'formStage'>();
  const [currentIP, setCurrentIP] = useState<IPAddressType | undefined>();
  const [completeHeader, setCompleteHeader] = useState('');
  const [disableBtn, setDisableBtn] = useState(false);
  const [twoFactorType, setTwoFactorType] = useState<'otp' | 'totp' | 'totp_recovery_code'>('otp');
  const [authData, setAuthData] = useState({
    code: '',
    identifier: '',
    two_factor_type: ''
  });
  const [action, setAction] = useState<ActionType | ''>('');
  const [timeLeft, setTimeLeft] = useState(60);
  const intervalRef = useRef<NodeJS.Timer | undefined>();
  const ipRegex = /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/;
  const ipv6Regex = /^(([0-9a-fA-F]{1,4}:){7,7}[0-9a-fA-F]{1,4}|([0-9a-fA-F]{1,4}:){1,7}:|([0-9a-fA-F]{1,4}:){1,6}:[0-9a-fA-F]{1,4}|([0-9a-fA-F]{1,4}:){1,5}(:[0-9a-fA-F]{1,4}){1,2}|([0-9a-fA-F]{1,4}:){1,4}(:[0-9a-fA-F]{1,4}){1,3}|([0-9a-fA-F]{1,4}:){1,3}(:[0-9a-fA-F]{1,4}){1,4}|([0-9a-fA-F]{1,4}:){1,2}(:[0-9a-fA-F]{1,4}){1,5}|[0-9a-fA-F]{1,4}:((:[0-9a-fA-F]{1,4}){1,6})|:((:[0-9a-fA-F]{1,4}){1,7}|:)|fe80:(:[0-9a-fA-F]{0,4}){0,4}%[0-9a-zA-Z]{1,}|::(ffff(:0{1,4}){0,1}:){0,1}((25[0-5]|(2[0-4]|1{0,1}[0-9]){0,1}[0-9])\.){3,3}(25[0-5]|(2[0-4]|1{0,1}[0-9]){0,1}[0-9])|([0-9a-fA-F]{1,4}:){1,4}:((25[0-5]|(2[0-4]|1{0,1}[0-9]){0,1}[0-9])\.){3,3}(25[0-5]|(2[0-4]|1{0,1}[0-9]){0,1}[0-9]))$/;
  const maxIpAddressCount = process.env.REACT_APP_MAXIMUM_IP_ADDRESS_WHITELIST_COUNT || 10;

  useEffect(() => {
    intervalRef.current = setInterval(() => {
      setTimeLeft(t => t - 1);
    }, 1000);
    if (timeLeft <= 0) {
      clearInterval(intervalRef.current);
    }
    return () => clearInterval(intervalRef.current);
  }, [timeLeft]);

  useEffect(() => {
    if (action && !['update_merchant_ip_address', 'add_merchant_ip_address'].includes(action)) {
      initiateToken();
    }
  }, [action]);

  const defaultMerchant = useStore((store: any) => store.defaultMerchant);

  const { data: ipList, refetch: refetchIPList, isFetching } = useQuery(['IP_WHITELIST'], () => apiRequest.fetchIPs(), {
    onSuccess: (data: any) => {
      if (data.data?.active) {
        setIpEnabled(true);
      } else {
        setIpEnabled(false);
      }
    },
    onError: (error: any) => onError(error, false)
  });

  const initiateOTP = useMutation(() => apiRequest.initiateOTPToken({ action: 'ip_address_whitelist_management' }), {
    onSuccess: (data: any) => {
      const { data: initiateOTPData } = data;
      setAuthData(details => ({
        ...details,
        identifier: initiateOTPData?.identifier
      }));
      setTwoFactorType(initiateOTPData?.type);
    },
    onError: (error: any) => onError(error)
  });

  const resendOTP = useMutation((data: { identifier: string }) => apiRequest.resendOTPAction(data), {
    onError: (error: any) => onError(error)
  });

  const addIP = useMutation((data: any) => apiRequest.addIP(data), {
    onSuccess: () => {
      refetchIPList();
    },
    onError: (error: any) => onError(error)
  });

  const updateIP = useMutation((data: any) => apiRequest.updateIP(data), {
    onSuccess: () => {
      refetchIPList();
    },
    onError: (error: any) => onError(error)
  });

  const removeIP = useMutation((data: any) => apiRequest.removeIP(data), {
    onSuccess: () => {
      refetchIPList();
    },
    onError: (error: any) => onError(error)
  });

  const enableDisableIP = useMutation((data: any) => apiRequest.enableDisableIP(data), {
    onSuccess: async () => {
      await refetchIPList();
    },
    onError: (error: any) => onError(error)
  });

  const initiateToken = () => {
    if (action) {
      initiateOTP.mutate();
      setTimeLeft(60);
    }
  };

  const onResendToken = () => {
    if (action) {
      resendOTP.mutate({
        identifier: authData.identifier
      });
      setTimeLeft(60);
    }
  };

  const getModalCloseCondition = (error: string) => {
    return error.includes('already added') || error.includes('too many attempts');
  };

  const onError = (error: any, timeout = true) => {
    const data = error?.response?.data;
    if (getModalCloseCondition(data?.message)) {
      setDisableBtn(true);
    }
    logError(error);
    feedbackInit({
      isActive: true,
      message: data?.message || 'We are sorry, something went wrong. Please try again.',
      type: 'danger',
      componentLevel: true
    });
    if (timeout) {
      setTimeout(() => {
        closeFeedback();
        if (getModalCloseCondition(data?.message)) close();
      }, 5000);
    }
  };

  const close = () => {
    setCurrentIP({ ip_address: '' });
    setShowModal(false);
    setAuthData({
      two_factor_type: '',
      code: '',
      identifier: ''
    });
    setAction('');
    setCompleteHeader('');
    setDisableBtn(false);
  };

  const handleAction = (actionType: HandleActionType, ip?: IPAddressType) => {
    setShowModal(true);
    if (['edit', 'add'].includes(actionType)) {
      setType('formStage');
    } else {
      setType('otpStage');
    }
    switch (actionType) {
      case 'edit':
        setCurrentIP(ip);
        setAction('update_merchant_ip_address');
        break;
      case 'add':
        setAction('add_merchant_ip_address');
        break;
      case 'delete':
        setCurrentIP(ip);
        setAction('remove_merchant_ip_address');
        break;
      default:
        setAction('update_merchant_ip_address_status');
        break;
    }
  };

  const getFormSecondBtnAction = () => {
    switch (action) {
      case 'add_merchant_ip_address':
        return function () {
          setType('otpStage');
          initiateToken();
        };

      case 'update_merchant_ip_address':
        return async function () {
          const data = {
            ip_address: currentIP?.ip_address,
            description: currentIP?.description
          };
          await updateIP.mutateAsync(data);
        };
      default:
        return function () {
          close();
        };
    }
  };

  const getOTPSecondBtnAction = () => {
    switch (action) {
      case 'add_merchant_ip_address':
        return async function () {
          const data = {
            ...currentIP,
            authorization: {
              code: authData?.code,
              identifier: authData?.identifier,
              two_factor_type: twoFactorType
            }
          };
          await addIP.mutateAsync(data);
        };
      case 'remove_merchant_ip_address':
        return async function () {
          const data = {
            ip_address: currentIP?.ip_address,
            authorization: {
              code: authData?.code,
              identifier: authData?.identifier,
              two_factor_type: twoFactorType
            }
          };
          await removeIP.mutateAsync(data);
        };
      default:
        return async function () {
          setCompleteHeader(`${ipEnabled ? 'Disable' : 'Enable'} IP Whitelist`);
          const data = {
            action: ipEnabled ? 'disable' : 'enable',
            authorization: {
              code: authData?.code,
              identifier: authData?.identifier,
              two_factor_type: twoFactorType
            }
          };
          await enableDisableIP.mutateAsync(data);
        };
    }
  };

  const getSecondBtnText = () => {
    switch (action) {
      case 'add_merchant_ip_address':
        if (type === 'formStage') return 'Add IP Address';
        return 'Verify';
      case 'remove_merchant_ip_address':
        return 'Yes, Delete';
      case 'update_merchant_ip_address':
        if (type === 'formStage') return 'Update';
        return 'Verify';
      default:
        return ipEnabled ? 'Yes, Disable' : 'Yes, Enable';
    }
  };

  const getModalHeading = () => {
    switch (action) {
      case 'add_merchant_ip_address':
        return 'Add IP Address';
      case 'remove_merchant_ip_address':
        return 'Delete IP Address';
      case 'update_merchant_ip_address':
        return 'Edit IP Address';
      default:
        return `${ipEnabled ? 'Disable' : 'Enable'} IP Whitelist`;
    }
  };

  const getModalBtnColor = () => {
    switch (action) {
      case 'remove_merchant_ip_address':
        return '#F32345';
      case 'update_merchant_ip_address_status':
        if (ipEnabled) return '#F32345';
        return undefined;
      default:
        return undefined;
    }
  };

  const getModalLastStage = () => {
    switch (action) {
      case 'update_merchant_ip_address':
        return 'formStage';
      default:
        return 'otpStage';
    }
  };

  const customValidation = () => {
    switch (type) {
      case 'formStage':
        if (action === 'add_merchant_ip_address') {
          return disableBtn || !(currentIP?.ip_address && canContinue);
        }
        return disableBtn || !(currentIP?.ip_address && currentIP?.description);
      default:
        return !twoFAInputValidation(twoFactorType, authData?.code);
    }
  };

  const renderIPList = () => {
    return (
      <div>
        {ipList?.data?.ip_addresses?.map((each: IPAddressType) => {
          return (
            <div className="div-table --ipwhitelist --row" key={each.ip_address}>
              <div>
                <span className="body-row-header">IP:</span>
                <span title={each.ip_address}>{each.ip_address}</span>
              </div>
              <div>
                <span className="body-row-header">Descripiton:</span>
                <span title={each.description}>{each.description}</span>
              </div>

              <div>
                <span className="body-row-header">Date Added:</span>
                <span>{`${getDate(each.created_at)} ${getTime(each.created_at)}`}</span>
              </div>
              <div className="action-btn-wrapper">
                <span>
                  <ActionButton img={EditIcon} label="Edit" action={() => handleAction('edit', each)} />
                </span>
              </div>
              <div className="action-btn-wrapper">
                <span>
                  <ActionButton img={DeleteIcon} label="Delete" action={() => handleAction('delete', each)} />
                </span>
              </div>
            </div>
          );
        })}
      </div>
    );
  };

  const getOtpInfo = () => {
    let email = defaultMerchant?.userEmail;
    if (email?.includes('@')) {
      email = email.split('@');
      email = `${email[0].slice(0, 3)}*******@${email[1]}`;
    }
    switch (action) {
      case 'add_merchant_ip_address':
      case 'update_merchant_ip_address':
        return (
          <div className="ip-otp-info --no-color">
            {twoFactorType === 'otp' && (
              <p>
                To verify your identity, enter the seven-digit security code sent to your email <b>{email}</b>
              </p>
            )}
            {twoFactorType === 'totp' && (
              <p>
                Enter the <b>six-digit</b> code from your <span className="colored-text">authenticator app</span> in the space provided
                below to confirm your identity.
              </p>
            )}
          </div>
        );
      case 'remove_merchant_ip_address':
        return (
          <div className="ip-otp-info --no-color">
            {twoFactorType === 'otp' && (
              <p>
                Enter the verification code sent to your email <b>{email}</b> to confirm you want to remove <b>{currentIP?.ip_address}</b>{' '}
                from your IP address whitelist.
              </p>
            )}
            {twoFactorType === 'totp' && (
              <p>
                Are you sure you want to disable IP Whitelist? Enter the <b>six-digit</b> code from your{' '}
                <span className="colored-text">authenticator app</span> in the space provided below to proceed.
              </p>
            )}
          </div>
        );
      default:
        return (
          <div className="ip-otp-info --no-color">
            {/* {ipEnabled ? ( */}
            {twoFactorType === 'otp' && ipEnabled && (
              <p>
                Are you sure you want to disable IP Whitelist? Enter the verification code sent to your email <b>{email}</b> to proceed.
              </p>
            )}
            {twoFactorType === 'totp' && ipEnabled && (
              <p>
                Are you sure you want to disable IP Whitelist? Enter the <b>six-digit</b> code from your{' '}
                <span className="colored-text">authenticator app</span> in the space provided below to proceed.
              </p>
            )}
            {twoFactorType === 'otp' && !ipEnabled && (
              <p>
                When IP Whitelist is enabled, only authorized team members will be able to make payouts. To proceed, enter the verification
                code sent to your email <b>{email}</b>.
              </p>
            )}
            {twoFactorType === 'totp' && !ipEnabled && (
              <p>
                Enter the <b>six-digit</b> code from your <span className="colored-text">authenticator app</span> in the space provided
                below to proceed.
              </p>
            )}
          </div>
        );
    }
  };

  const otpContent = () => {
    let inputLabel = 'One Time PIN (OTP)';
    let inputPlaceholder = '7-digit code';
    if (twoFactorType === 'totp') {
      inputLabel = 'Authentication Code';
      inputPlaceholder = 'Enter authentication code';
    } else if (twoFactorType === 'totp_recovery_code') {
      inputLabel = 'Recovery Code';
      inputPlaceholder = 'Enter recovery code';
    }
    return (
      <div className="bankForm-container">
        {twoFactorType === 'totp_recovery_code' ? (
          <div className="ip-otp-info --no-color">
            <p>Can&apos;t use your authenticator app? Enter one of your previously saved recovery codes to validate this transaction.</p>
          </div>
        ) : (
          getOtpInfo()
        )}

        <label htmlFor="pin">{inputLabel}</label>
        <input
          type="number"
          name="pin"
          maxLength={7}
          placeholder={inputPlaceholder}
          autoComplete="one-time-code"
          value={authData?.code}
          onChange={e => {
            const formattedInput = cleanInput(e.target.value);
            setAuthData(details => ({ ...details, code: formattedInput }));
          }}
        />
        {twoFactorType === 'totp' && (
          <div className="recovery-code">
            Can&apos;t access authenticator app?{' '}
            <button type="button" onClick={() => setTwoFactorType('totp_recovery_code')} className="recovery-code-btn" role="link">
              Confirm using recovery codes
            </button>
          </div>
        )}
        {twoFactorType === 'otp' && (
          <div className="resend">
            <span>Didn’t receive any email?</span>
            {!timeLeft ? (
              <button type="button" onClick={onResendToken} className="link-text">
                Resend
              </button>
            ) : (
              <span> Wait {timeLeft} seconds...</span>
            )}
          </div>
        )}
      </div>
    );
  };

  const formik = useFormik({
    initialValues: {
      ip_address: '',
      description: ''
    },
    validate: values => {
      const errors: Partial<typeof values> = {};
      if (!values.ip_address) errors.ip_address = 'IP Address is required';
      if (!(ipRegex.test(values.ip_address) || ipv6Regex.test(values.ip_address))) {
        errors.ip_address = `IP address not valid`;
      }

      return errors;
    },
    onSubmit: () => {}
  });

  const { errors, setFieldValue, dirty, isValid } = formik;
  const canContinue = dirty && isValid;

  const formContent = () => {
    return (
      <>
        <div className="form-group">
          <label htmlFor="amount" className="withdraw-label">
            <span className="dark">IP Address</span>
          </label>

          <input
            readOnly={action === 'update_merchant_ip_address'}
            type="text"
            className="form-control"
            required
            name="ip-address"
            value={currentIP?.ip_address}
            placeholder="Example: 127.0.0.1, 2001:0::876A:130B"
            onChange={e => {
              const formattedValue = cleanInput(e.target.value);
              setCurrentIP(details => ({ ...details, ip_address: formattedValue }));
              setFieldValue('ip_address', formattedValue);
            }}
          />
          {errors.ip_address ? (
            <p className="error-msg mt-1" style={{ fontSize: '13px', color: '#F32345' }}>
              {errors.ip_address}
            </p>
          ) : null}
        </div>
        <div className="form-group">
          <label htmlFor="description" className="withdraw-label">
            <span className="dark">
              Description {action === 'add_merchant_ip_address' && <span style={{ opacity: 0.7 }}>(optional)</span>}
            </span>
          </label>
          <input
            maxLength={100}
            value={currentIP?.description}
            className="form-control"
            name="description"
            onChange={e => {
              const formattedInput = cleanInput(e.target.value);
              setCurrentIP(details => ({ ...details, description: formattedInput }));
            }}
          />
        </div>
      </>
    );
  };

  const switchIPModal = (kind: 'otpStage' | 'formStage' | undefined) => {
    let content;
    const heading = getModalHeading();
    const secondBtnColor = getModalBtnColor();
    const secondButtonText = getSecondBtnText();
    switch (kind) {
      case 'otpStage':
        content = {
          content: otpContent(),
          secondButtonText,
          firstButtonText: action === 'add_merchant_ip_address' ? 'Back' : 'Cancel',
          secondButtonAction: getOTPSecondBtnAction(),
          secondButtonColor: secondBtnColor,
          firstButtonAction: () => {
            if (twoFactorType === 'totp_recovery_code') {
              return setTwoFactorType('totp');
            }
            if (action === 'add_merchant_ip_address') return setType('formStage');
            return close();
          }
        };
        break;
      default:
        content = {
          content: formContent(),
          secondButtonText: action === 'add_merchant_ip_address' ? 'Add IP Address' : 'Update',
          firstButtonText: 'Cancel',
          secondButtonAction: getFormSecondBtnAction(),
          firstButtonAction: () => {
            return close();
          }
        };
        break;
    }

    return {
      close: () => {
        close();
      },
      secondButtonActionIsTerminal: kind === getModalLastStage(),
      secondButtonDisable: customValidation(),
      firstButtonDisable: disableBtn,
      completedHeading: completeHeader || heading,
      completedDescription: 'Completed',
      completedActionText: '',
      heading,
      description: '',
      ...content
    };
  };
  return (
    <div className="row ipwhitelist">
      <div className="col-sm-12">
        <button type="button" className="ipwhitelist-back-btn" onClick={() => history.push('/dashboard/settings/security')}>
          <img src={ArrowLeft} alt="" /> Back
        </button>
        <div className="ipwhitelist-header">
          <div>
            <div>
              <h2>IP Whitelisting</h2>
              <span className={`${ipEnabled ? 'enabled' : 'disabled'}`}>{ipEnabled ? 'Enabled' : 'Disabled'}</span>
            </div>

            <p>
              {`Set up IP whitelisting to prevent unauthorized access. This restricts access to only authorised IP addresses
              (${maxIpAddressCount} maximum). The whitelist can only be enabled or disabled for the Live or Test mode you're currently using.`}
            </p>
          </div>
          <div>
            <button
              type="button"
              className="btn btn-primary"
              disabled={ipList?.data?.ip_addresses?.length >= +maxIpAddressCount}
              onClick={() => handleAction('add')}
            >
              <span className="os-icon os-icon-plus" />
              <span>Add IP Address</span>
            </button>
            <button
              type="button"
              className={`btn btn-${ipEnabled ? 'danger' : 'success'}`}
              onClick={() => handleAction(ipEnabled ? 'disable' : 'enable')}
            >
              {`${ipEnabled ? 'Disable' : 'Enable'} IP Whitelist`}
            </button>
          </div>
        </div>
        <Modal visible={showModal} {...switchIPModal(type)} size="md" />
        <IPNotification />
        <legend />
        <h3 className="ip-table-header">IP Addresses</h3>

        <Table
          tableClassName="--ipwhitelist"
          totalItems={ipList?.data?.ip_addresses?.length || 0}
          headings={[
            {
              value: 'IP'
            },
            {
              value: 'Description'
            },
            {
              value: `Date Added`
            },
            {
              value: ``
            },
            {
              value: ''
            }
          ]}
          loading={isFetching}
          annotation="IP Addresses"
          emptyStateHeading="No entries yet"
          EmptyStateMessage="There is no IP addresses yet."
          tableWrapperClassName="element-box-tp"
        >
          {renderIPList()}
        </Table>
      </div>
    </div>
  );
}

export default IPWhiteList;
