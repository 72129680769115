import React, { useEffect, useRef, useState } from 'react';

import Modal from '+shared/Modal';
import useStore from '+store';

import './index.scss';

interface IInitiateTwoFaModalProps {
  close: () => void;
  resend: () => void;
  visible: boolean;
  identifier: string;
  initiate2fa: () => void;
}

const InitiateTwoFaModal = ({ close, resend, visible, identifier, initiate2fa }: IInitiateTwoFaModalProps) => {
  const user = useStore(state => state.profile);
  const timerInterval = useRef(null);
  const [code, setCode] = useState(null);
  const [codeSent, setCodeSent] = useState(true);
  const [time, setTime] = useState(30);
  const [type, setType] = useState('');

  useEffect(() => {
    if (codeSent) {
      if (timerInterval.current) {
        clearInterval(timerInterval.current);
      }

      timerInterval.current = setInterval(() => {
        if (time <= 0) {
          clearInterval(timerInterval.current);
          setCodeSent(false);
        } else {
          setTime(time - 1);
        }
      }, 1000);
    }
    return () => clearInterval(timerInterval.current);
  }, [time]);

  const setDisabled = () => {
    if (!code) return true;
  };

  const onClose = () => {
    setCode(null);
    close();
  };

  const defaultTwoFaContent = () => {
    return (
      <>
        <div className="twoFa-note py-2">
          <div>
            <p>
              To verify your identity, enter the security code sent to your email <span>{user?.email}</span>
            </p>
          </div>
        </div>

        <div className="form-group mb-4 mt-2">
          <label className="withdraw-label">
            <span className="dark">Verification Code</span>
          </label>
          <input
            className="form-control"
            type="number"
            value={code}
            placeholder="Enter code"
            onChange={e => {
              setCode(e.target.value);
            }}
          />
        </div>
        <div className="twoFa-note mb-4">
          <p>
            Didn&apos;t receive any email?{' '}
            {!codeSent ? (
              <span
                className="email-resend"
                onClick={() => {
                  setCodeSent(true);
                  setTime(30);
                  resend();
                }}
                aria-hidden="true"
              >
                Resend
              </span>
            ) : (
              <span className="resend-timer">Resend code in {time} secs.</span>
            )}
          </p>
        </div>
      </>
    );
  };

  const switchTwoFAModal = kind => {
    let content;
    switch (kind) {
      default:
        content = {
          heading: 'Confirm Your Identity',
          content: defaultTwoFaContent(),
          firstButtonAction: onClose,
          secondButtonText: 'Verify',
          secondButtonAction: async () => {
            const requestData = {
              code,
              identifier: identifier.identifier
            };
            await initiate2fa.mutateAsync(requestData);
            close();
          }
        };
        break;
    }
    return {
      size: 'md',
      close: onClose,
      secondButtonDisable: setDisabled(),
      ...content
    };
  };

  return <section>{visible && <Modal {...switchTwoFAModal(type)} />}</section>;
};

export default InitiateTwoFaModal;
