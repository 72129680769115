import { useQuery } from 'react-query';
import APIRequest from '+services/api-services';
import { formatAmount, durationToday } from '+utils';
import useFeedbackHandler from '+hooks/feedbackHandler';

const api = new APIRequest();

interface IAnalyticsOverviewProps {
  currency: string;
}

const AnalyticsOverview = ({ currency }: IAnalyticsOverviewProps) => {
  const { feedbackInit } = useFeedbackHandler();

  const { data } = useQuery([`ANALYTICS_CHART`, currency], () => api.getPerformanceAnalytics(...durationToday(), currency.toLowerCase()), {
    onError: () => {
      feedbackInit({
        message: `There has been an error fetching the transactions status.`,
        type: 'danger'
      });
    }
  });
  const overview = data || {};

  return (
    <section className="element-box element-analytics">
      <div className="analytics-overview__comp">
        <div className="el-tablo">
          <div className="value">{formatAmount(overview.payinsTotal || 0)}</div>
          <div className="label">Received Today ({currency})</div>
        </div>
        <div className="el-tablo">
          <div className="value">{formatAmount(overview.payoutsTotal || 0)}</div>
          <div className="label">Sent Today ({currency})</div>
        </div>
        <div className="el-tablo">
          <div className="value">{overview.allTransactionsCount || '--'}</div>
          <div className="label">Total Transactions</div>
        </div>
      </div>
    </section>
  );
};


export default AnalyticsOverview;
