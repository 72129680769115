interface ISwitcherPropsType extends React.AriaAttributes {
  children: React.ReactNode;
  space?: string;
  className?: string;
}

const Switcher: React.FC<ISwitcherPropsType> = ({ children, className, space = '1rem', ...props }) => {
  return (
    <div style={{ gap: space }} data-layout="switcher" className={className} {...props}>
      {children}
    </div>
  );
};

export default Switcher;
