import { ChargebackStatusType, StatusType } from '+types';

const formatStatus = (status: StatusType | ChargebackStatusType) => {
  switch (status) {
    case 'pending':
      return 'processing';
    case 'approved':
      return 'success';
    case 'declined':
      return 'failed';
    default:
      return status;
  }
};

export default formatStatus;
