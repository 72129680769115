import { useEffect } from 'react';
import DatePicker from 'react-datepicker';
import dayjs from 'dayjs';
import { useSearchQuery, useReducerState } from '+hooks';
import { cleanInput, filteredOutObjectProperty } from '+utils';
import 'react-datepicker/dist/react-datepicker.css';
import arrowRight from '+assets/img/dashboard/arrow-right.svg';
import calendar from '+assets/img/dashboard/calendar.svg';
import search from '+assets/img/dashboard/search-thin.svg';
import { IFilterProps } from '+types';

const dropdownOptions = [
  {
    label: 'All status',
    value: 'All status'
  },
  {
    label: 'Success',
    value: 'success'
  },
  {
    label: 'Failed',
    value: 'failed'
  },
  {
    label: 'Processing',
    value: 'processing'
  }
];

const initialState = {
  status: '',
  reference: '',
  clearFilter: false,
  startDate: undefined,
  endDate: undefined
};

const showClearButtonKeys = [
  'status',
  'reference',
  'dateFrom',
  'dateTo'
];

const ConversionsFilter = ({
  totalCount = 0,
  openExportModal = () => { }
}: IFilterProps) => {
  const searchQuery = useSearchQuery();
  const status = searchQuery.value.tab || 'success';
  const activeCurrency = searchQuery.value.currency || 'NGN';

  const [state, setState] = useReducerState(initialState);

  useEffect(() => {
    const filterKeys = Object.keys(searchQuery.value);
    const showKeys = showClearButtonKeys.some(key => filterKeys.indexOf(key) !== -1);
    if (filterKeys.length > 0 && showKeys) {
      setState({
        clearFilter: true,
        status: searchQuery.value.status || '',
        reference: searchQuery.value.reference || '',
        startDate: searchQuery.value.dateFrom || searchQuery.value.dateFrom || null,
        endDate: searchQuery.value.dateTo || searchQuery.value.dateTo || null
      });
    } else {
      setState({ ...initialState, clearFilter: false });
    }
  }, [searchQuery.value]);

  useEffect(() => {
    handleClearFilter();
  }, [activeCurrency, status]);

  const handleClearFilter = () => {
    setState(initialState);
    searchQuery.clearAll(['tab', 'page', 'currency']);
  };

  const filterTransactions = () => {
    const values = filteredOutObjectProperty(state, ['startDate', 'endDate', 'clearFilter']);
    if (status === 'success') {
      return searchQuery.setQuery({
        dateFrom: state.startDate,
        dateTo: state.endDate,
        ...values
      });
    }
    if (status === 'failed') {
      return searchQuery.setQuery({
        ...values,
        dateFrom: state.startDate,
        dateTo: state.endDate
      });
    }
  };

  return (
    <section className="settlement-filter">
      <div className="settlement-filter__top">
        <div>
          <span>
            {totalCount} {totalCount === 1 ? 'Transactions' : ' Transactions are on this table'} {state.clearFilter && '(filtered results)'}
          </span>
          {state.clearFilter && (
            <>
              <span className="divider-sm" />
              <button type="button" onClick={() => handleClearFilter()}>
                {' '}
                <svg xmlns="http://www.w3.org/2000/svg" width="21" height="20" fill="none" viewBox="0 0 21 20" style={{ width: '1rem' }}>
                  <path
                    fill="#AABDCE"
                    d="M3.426 2.926c3.902-3.9 10.247-3.9 14.149 0 3.9 3.901 3.9 10.248 0 14.15A9.976 9.976 0 0110.5 20a9.975 9.975 0 01-7.074-2.924c-3.901-3.902-3.901-10.249 0-14.15zM6.374 12.95a.833.833 0 101.179 1.178L10.5 11.18l2.946 2.948a.835.835 0 001.18-1.18l-2.947-2.946 2.947-2.948a.833.833 0 10-1.179-1.179L10.5 8.822 7.553 5.874a.833.833 0 10-1.18 1.18L9.322 10l-2.947 2.948z"
                  />
                </svg>
                &nbsp;
                <span>Clear</span>
              </button>
            </>
          )}
        </div>
        <div>
          {(
            <button
              type="button"
              className="btn btn-secondary"
              onClick={openExportModal}
              style={{ background: 'none', border: 'none', color: '#2376F3', paddingRight: 0 }}
            >
              <i className="os-icon os-icon-arrow-up-right" />
              <span>Export Conversions</span>
            </button>
          )}
        </div>
      </div>

      <div className="settlement-filter__search-w filter-section">
        <div className="element-search-content filter-body w-100">
          <div className="form-group position-relative filter-object filter-object-xl flex-grow-1 w-auto --no-max-width --search-container">
            <img src={search} alt="search icon" aria-hidden />
            <input
              type="search"
              className="form-control"
              placeholder="Search by conversion ID..."
              value={state.reference}
              onChange={e => setState({ reference: cleanInput(e.target.value) })}
              style={{ border: '1.5px solid #EAF2FE' }}
            />
          </div>
          <div className="form-group filter-object filter-object-ssm w-auto --no-max-width">
            <select
              name="type"
              className="form-control"
              onChange={e => setState({ status: e.target.value })}
              value={state.status}
              style={{ border: '1.5px solid #EAF2FE', width: '150px' }}
              data-testid="filter-select"
            >
              {dropdownOptions?.map(d => (
                <option key={d.label} value={d.value}>
                  {d.label}
                </option>
              ))}
            </select>
          </div>

          <div
            className="form-group filter-object d-flex filter-object-sm w-auto"
            style={{ '--calendar-image': `url("${calendar}")`, minWidth: '300px' }}
          >
            <DatePicker
              selected={state.startDate ? new Date(state.startDate) : undefined}
              dateFormat="dd-MM-yyyy"
              popperPlacement="bottom-end"
              style={{ borderRadius: '0px 4px 4px 0px', border: '0.5px solid red' }}
              className="form-control date-select pl-4 date-picker"
              onChange={date => setState({ startDate: dayjs(dayjs(date)).format('YYYY-MM-DD') })}
              maxDate={new Date()}
              placeholderText="From"
              calendarClassName="custom-datepicker"
            />
            <DatePicker
              selected={state.endDate ? new Date(state.endDate) : undefined}
              dateFormat="dd-MM-yyyy"
              popperPlacement="bottom-end"
              style={{ borderRadius: '0px 4px 4px 0px' }}
              className="form-control date-select pl-4 date-picker"
              minDate={state.startDate ? new Date(state.startDate) : undefined}
              maxDate={new Date()}
              onChange={date => setState({ endDate: dayjs(dayjs(date)).format('YYYY-MM-DD') })}
              placeholderText="To"
              calendarClassName="custom-datepicker"
            />
          </div>
          <button
            aria-label="filter transactions"
            type="button"
            className="settlement-filter__filter-button"
            onClick={() => filterTransactions()}
            aria-controls="filtertransactions"
          >
            <img src={arrowRight} alt="arrow right icon" aria-hidden />
          </button>
        </div>
      </div>
    </section>
  );
}

export default ConversionsFilter;
