import React, { useState } from 'react';
import ForeignWithdrawalRequestform from './components/ForeignWithdrawalRequestform';
import ButtonGroup from './components/SwitchTransferButtonGroups';
import { buttonType } from './components/ForeignWithdrawalRequestformData';
import { ForeignBankProps } from '+types';
import { useSearchQuery } from '+hooks';
import './index.scss';

export default function ForeignCurrencies({ countriesList, statesList, requestFormProps }: ForeignBankProps) {
  const [type, setType] = useState('local');
  const [selectedButtonType, setSelectedButtonType] = useState<string | null>('local');
  const searchQuery = useSearchQuery();
  const activeCurrency = searchQuery.value.currency || 'NGN';

  const switchTransferType = (value: string) => {
    setType(value);
    setSelectedButtonType(value);
  };

  return (
    <>
      <ButtonGroup
        buttonType={buttonType}
        type={type}
        switchTransferType={switchTransferType}
      />
      <ForeignWithdrawalRequestform
        buttonType={selectedButtonType || ''}
        requestFormProps={requestFormProps}
        countriesList={countriesList}
        statesList={statesList}
        currency={activeCurrency}
      />
    </>
  );
}
