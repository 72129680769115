import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { switchRefundStatus } from './switchRefundStatus';
import Copyable from '+dashboard/Shared/Copyable';
import { formatAmount, getDate, getTime } from '+utils';
import '../index.scss';

const ChargebacksTab = ({ data, transactionType, currency }) => {
  const [item, setItem] = useState([]);
  const [activeIndex, setActiveIndex] = useState(0);
  const count = data?.length;

  useEffect(() => {
    if (data) setItem([data[activeIndex]]);
  }, [count]);

  const isPayinTransaction = transactionType === 'pay-ins';
  const isIssuedCardTransaction = transactionType === 'card-transactions';
  const UrlToDisputeTransactionDetails = isPayinTransaction
    ? '/dashboard/disputes/chargebacks/'
    : '/dashboard/issuing/issued-card-chargebacks/';

  return (
    <div>
      {data?.length > 0 ? (
        <ul>
          <li id="payment-detail">
            <div style={{ width: '40%' }}>
              <p className="reversal-count">{`${count} ${count > 1 ? 'Chargebacks' : 'Chargeback'} found`}</p>
              {data.map((charge, index) => {
                return (
                  <div
                    className="--container"
                    key={charge?.reference}
                    onClick={() => {
                      setItem([charge]);
                      setActiveIndex(index);
                    }}
                    role="button"
                    tabIndex={0}
                  >
                    <p className={`reversal-amount ${activeIndex === index && 'active'}`}>
                      <label className="">Chargeback of</label>
                      {`${formatAmount(charge.amount || 0)} ${currency}`}
                    </p>
                  </div>
                );
              })}
            </div>
            <div className="customer-reversal-info" style={{ width: '60%' }}>
              <ul className="">
                <li>
                  <p style={{ textAlign: 'left' }}>{transactionType === 'pay-ins' ? 'Reference' : 'Chargeback ID'}</p>
                  <p style={{ display: 'inline-block', textAlign: 'right', minWidth: 0, overflowWrap: 'break-word' }}>
                    <Copyable text={item[0]?.reference} spanClassName="reversal_ref" />
                  </p>
                </li>
                {isIssuedCardTransaction && (
                  <>
                    <li>
                      <p>Chargeback Amount</p>
                      <p>{`${item[0]?.amount} ${currency}`}</p>
                    </li>
                    <li>
                      <p>Escalated Amount</p>
                      <p>{`${item[0]?.amount} ${currency}`}</p>
                    </li>
                    <li>
                      <p>Accepted Amount</p>
                      <p>{`${item[0]?.accepted_amount} ${currency}`}</p>
                    </li>
                  </>
                )}
                {isPayinTransaction && (
                  <li>
                    <p>Chargeback Destination</p>
                    <p>{item[0]?.destination === 'disbursement_wallet' ? `Balance` : 'Customer'}</p>
                  </li>
                )}
                <li>
                  <p>Chargeback Status</p>
                  <p
                    style={{
                      color: switchRefundStatus[item[0]?.status]?.color || ''
                    }}
                  >
                    {switchRefundStatus[item[0]?.status]?.name}
                  </p>
                </li>
                {isIssuedCardTransaction && (
                  <>
                    <li>
                      <p>Date Escalated</p>
                      <p>
                        {item[0]?.escalation_date
                          ? getDate(item[0]?.escalation_date) + ' | ' + getTime(item[0]?.escalation_date)
                          : '-- | --'}
                      </p>
                    </li>
                    <li>
                      <p>Date Completed</p>
                      <p>
                        {item[0]?.actual_resolution_date
                          ? getDate(item[0]?.actual_resolution_date) + ' | ' + getTime(item[0]?.actual_resolution_date)
                          : '-- | --'}
                      </p>
                    </li>
                  </>
                )}
                {isPayinTransaction && (
                  <li>
                    <p>Reason for Chargeback</p>
                    <p>Chargeback</p>
                  </li>
                )}
              </ul>
              <Link to={`${UrlToDisputeTransactionDetails}${item[0]?.reference}`} style={{ fontWeight: 600 }}>
                <button className="btn btn-primary" type="button">
                  View Details
                </button>
              </Link>
            </div>
          </li>
        </ul>
      ) : (
        <p className="no-refund">
          <i>No chargebacks for this transaction</i>
        </p>
      )}
    </div>
  );
};
export default ChargebacksTab;
