export const GuideList = [
  {
    title: 'Introducing Teams!',
    type: 'team'
  },
  {
    title: 'IP Address Whitelisting',
    type: 'ip'
  },
  {
    title: 'Audit Logs',
    type: 'audit'
  }
];
