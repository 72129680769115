import { useState } from 'react';
import { colours } from '+utils';
import Copyable from '../../../Shared/Copyable';
import '../index.scss';

interface ColourPickerProps {
  initialColor: string;
  onColorChange: (color) => void;
}

const CustomColourPicker = ({ initialColor, onColorChange }: ColourPickerProps) => {
  const [colour, setColour] = useState(initialColor);

  const handleColorChange = (color: string) => {
    setColour(color);
    onColorChange(color);
  };
  return (
    <div className="custom-colour-wrapper">
      <p className="title">Custom color</p>
      <div style={{ background: colour }} className="colour-display-screen">
        <p style={{ color: colour === '#FFFFFF' ? '#000' : '' }}>{colour}</p>
      </div>
      <div className="hex-codename">
        <span>{colour}</span>
        <Copyable text={colour} buttonClassName="copy-color" textModifier={x => ''} copyText="Copied" showCopyText={false} />
      </div>
      <p className="title">Presets</p>
      <div className="preset-color-wrapper">
        {colours.map(color => (
          <span onClick={() => handleColorChange(color)} className="preset-colour" style={{ background: color, cursor: 'pointer' }} />
        ))}
      </div>
    </div>
  );
};

export default CustomColourPicker;
