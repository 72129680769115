import { useState } from 'react';

import useClickOutside from '+hooks/useClickOutside';

import ellipsisIcon from '+assets/img/dashboard/ellipsis-icon.svg';
import removeIcon from '+assets/img/dashboard/remove-icon.svg';
import resendIcon from '+assets/img/dashboard/resend-icon.svg';
import settingsIcon from '+assets/img/dashboard/settings-icon.svg';

interface INavModalProps {
  setState?: (data?: Record<string, any>) => void;
  setItem?: (data?: Record<string, any>) => void;
  each?: Record<string, any>;
  activeTab?: string;
  hideRemove?: boolean;
}

const NavModal = ({ setState = () => null, setItem = () => null, each = {}, activeTab = '', hideRemove = false }: INavModalProps) => {
  const wrapperRef = useClickOutside(() => {
    setNavModal(false);
  });
  const [navModal, setNavModal] = useState(false);

  const itemsMapper: Record<
    string,
    Array<{
      onClick: () => void;
      hide: boolean;
      icon: string;
      iconAlt: string;
      title: string;
      style?: React.CSSProperties;
    }>
  > = {
    'Team Members': [
      {
        onClick: () => setState({ modalType: 'change-role' }),
        hide: false,
        icon: settingsIcon,
        iconAlt: 'settings-icon',
        title: 'Change role'
      },
      {
        onClick: () => setState({ modalType: 'resend-invite' }),
        hide: each.status !== 'invite_expired',
        icon: resendIcon,
        iconAlt: 'resend-icon',
        title: 'Resend invite'
      },
      {
        onClick: () => setState({ modalType: 'remove-team-member' }),
        hide: false,
        icon: removeIcon,
        iconAlt: 'remove-icon',
        title: 'Remove',
        style: { color: '#ee0005' }
      }
    ],
    Roles: [
      {
        onClick: () => setState({ modalType: 'initiate-update-permission' }),
        hide: false,
        icon: settingsIcon,
        iconAlt: 'settings-icon',
        title: 'Edit Permissions',
        style: { color: '#ee0005' }
      },
      {
        onClick: () => setState({ modalType: 'remove-role' }),
        hide: hideRemove,
        icon: removeIcon,
        iconAlt: 'remove-icon',
        title: 'Remove',
        style: { color: '#ee0005' }
      }
    ]
  };

  return (
    <div ref={wrapperRef}>
      <button
        type="button"
        className="ellipsis__icon"
        onClick={() => {
          setNavModal(value => !value);
          setItem(each);
        }}
      >
        <img src={ellipsisIcon} alt="ellipsis-icon" />
      </button>
      {navModal && (
        <ul className="element-box ellipsis__nav bg-white">
          {itemsMapper[activeTab]
            .filter(item => !item.hide)
            .map(item => (
              <li
                key={item.title}
                role="presentation"
                className="ellipsis__item ellipsis-item"
                style={item?.style}
                onClick={() => {
                  item.onClick();
                  setNavModal(false);
                }}
              >
                <img className="ellipsis__icon" src={item.icon} alt={item.iconAlt} />
                <span>{item.title}</span>
              </li>
            ))}
        </ul>
      )}
    </div>
  );
};

export default NavModal;
