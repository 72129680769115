import  { Suspense } from 'react';
import './index.scss';

interface ISuspenseLoaderProps {
  message?: string;
}

export const SuspenseLoader = ({ message = '' }: ISuspenseLoaderProps) => {
  return (
    <section className="suspense-loader">
      <div className="suspense-image--loader">
        <img src="https://korablobstorage.blob.core.windows.net/modal-bucket/kpy-k-standalome.svg" alt="Korapay logo" />
        <p>{message}</p>
      </div>
    </section>
  );
}

export default function LazyComponent(Component) {
  return props => (
    <Suspense
      fallback={
        <section className="suspense-loader">
          <div className="suspense-image--loader">
            <img src="https://korablobstorage.blob.core.windows.net/modal-bucket/kpy-k-standalome.svg" alt="Korapay logo" />
          </div>
        </section>
      }
    >
      <Component {...props} />
    </Suspense>
  );
}
