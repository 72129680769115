import { useState } from 'react';
import { useQuery } from 'react-query';

import { feedbackInit } from '+hooks/feedbackHandler';
import APIRequest from '+services/api-services';
import useStore from '+store';
import { DefaultMerchantType } from '+types/defaultMerchant';
import { logError } from '+utils';

import { IdentityServiceAccessType, IdRequestData } from '../types/types';
import IdAccessRequestModal from './IdAccessRequestModal/IdAccessRequestModal';

import './index.scss';

const api = new APIRequest(process.env.REACT_APP_PUBLIC_MERCHANT_MIDDLEWARE_API_BASE);

const IdentityAccessRequest = () => {
  const [showModal, setShowModal] = useState(false);
  const { identityServiceAccess, defaultMerchant: MERCHANT_ENV } = useStore.getState() as {
    identityServiceAccess: IdentityServiceAccessType;
    defaultMerchant: DefaultMerchantType;
  };
  const [accessRequestSuccess, setAccessRequestSuccess] = useState(identityServiceAccess?.requested_access);

  const { data: requestsInfo, isLoading, isFetching, refetch: fetchData } = useQuery<IdRequestData>(
    ['IDENTITY_ACCESS_INFO'],
    () => api.getIdentityAccessConfig(),
    {
      keepPreviousData: true,
      enabled: false,
      onError: error => {
        logError(error);
        feedbackInit({
          message: 'There has been an error getting identity request info.',
          type: 'danger',
          action: {
            action: () => fetchData(),
            name: 'Try again'
          }
        });
      },
      onSuccess: () => {
        setShowModal(true);
      }
    }
  );
  return (
    <div className="identity-access-request h-100">
      <div className=" row justify-content-center h-100">
        <div className="col-lg-5 h-100 d-flex justify-content-center align-items-center">
          <div className="id-request-card text-center">
            <span className="access-request-header-icon ">
              <i className="os-icon os-icon-fingerprint" style={{ fontSize: '60px' }} />
            </span>
            <h2 className="id-request-title mt-4">Build trust and safety for your business with Identity</h2>
            <p className="id-request-subtitle mt-4">
              Keep your business protected with Identity - Kora's robust KYC engine that enables businesses to verify and authenticate the
              identity of their customers.
            </p>

            {accessRequestSuccess ? (
              <h6 className="px-4 access-requested-text mt-5 mb-4">
                Your request has been received and is being reviewed. We will contact you shortly.
              </h6>
            ) : (
              <button disabled={isLoading} type="button" className="btn btn-primary p-3 mt-5 mb-4" onClick={() => fetchData()}>
                <span> Request Access</span>{' '}
                {isFetching && (
                  <span className="spinner-border spinner-border-sm" style={{ marginRight: '0.5rem' }} role="status" aria-hidden="true" />
                )}
              </button>
            )}

            <div>
              <a
                href="https://developers.korapay.com/docs"
                target="noopener noreferrer"
                className="text-primary font-weight-bold text-decoration-none"
              >
                <i className="os-icon os-icon-book-open font-weight-bold mr-2" />
                See Developer Guide
              </a>
            </div>
          </div>
        </div>
      </div>

      {showModal ? (
        <IdAccessRequestModal
          onClose={() => {
            setShowModal(false);
          }}
          onAccessRequested={status => setAccessRequestSuccess(status)}
          requestsInfo={requestsInfo as IdRequestData}
        />
      ) : null}
    </div>
  );
};

export default IdentityAccessRequest;
