import React, { useState } from 'react';
import { IBulkPayoutConfirmModalProps } from '+types/bulk-payouts';
import Modal from '+dashboard/Shared/Modal';

const BulkPayoutConfirmModal = ({
  confirmModal,
  close,
  secondButtonAction,
  heading,
  completedHeading,
  completedDescription,
  secondButtonColor,
  secondButtonText,
  description,
  firstButtonText,
  completedImage
}: IBulkPayoutConfirmModalProps) => {
  const [loading, setLoading] = useState(false);
  return (
    <Modal
      size="md"
      close={() => close()}
      heading={heading}
      description={<p style={{ color: '#414F5F', fontWeight: 400, display: 'block' }}>{description}</p>}
      justify="space-between"
      firstButtonText={firstButtonText || 'Cancel'}
      firstButtonAction={() => close()}
      secondButtonText={secondButtonText}
      secondButtonAction={async () => {
        setLoading(true);
        secondButtonAction();
        setLoading(false);
      }}
      secondButtonDisable={loading}
      completedHeading={completedHeading}
      completedDescription={completedDescription}
      secondButtonColor={secondButtonColor || '#F32345'}
      visible={confirmModal}
      completedImage={completedImage}
    />
  );
};

export default BulkPayoutConfirmModal;
