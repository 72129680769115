import { useState } from 'react';
import search from '+assets/img/dashboard/search.svg';
import arrowRight from '+assets/img/dashboard/arrow-right.svg';
import './index.scss';

interface ISearchComponentProps {
  handleSearchQuery: (query: string) => void;
}

const SearchComponent = ({
  handleSearchQuery
}: ISearchComponentProps) => {
  const [searchQuery, setSearchQuery] = useState('');

  const handleSearch = () => {
    return handleSearchQuery(searchQuery);
  };

  return (
    <div className="search__container" style={{ margin: '0' }}>
      <img src={search} alt="search icon" className="search__icon" />
      <input
        type="search"
        aria-label="search transactions"
        name="searchHistory"
        id="searchtransactions"
        data-testid="searchtransactions"
        className="form-control form-control-sm"
        placeholder="Search..."
        value={searchQuery}
        style={{ fontFamily: 'Averta PE' }}
        onChange={e => {
          if (e.target.value.trim() === '') {
            setSearchQuery(e.target.value);
            handleSearchQuery('');
          } else {
            setSearchQuery(e.target.value);
          }
        }}
      />
      <button
        aria-label="search transactions"
        type="button"
        className="search__button"
        onClick={() => handleSearch(searchQuery)}
        aria-controls="searchtransactions"
        data-testid="searchbutton"
      >
        <img src={arrowRight} alt="arrow right icon" aria-hidden />
      </button>
    </div>
  );
}


export default SearchComponent;
