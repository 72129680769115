import React from 'react';
import '../index.scss';

type UpgradeRequestDetailsProps<T> = {
  data: T[];
};
const UpgradeRequestDetails = <T,>({ data }: UpgradeRequestDetailsProps<T>) => {
  return (
    <section className="upgrade_request_details">
      {data.map((group: T, index: number) => (
        <ul key={index} className="details-container">
          {Object.entries(group).map(([key, val]) => (
            <li className="details-list" key={key}>
              <span className="details-key">{key}</span>{' '}
              <span className="details-val" style={val === 'Not Available' ? { fontStyle: 'italic', color: '#DDE2EC' } : {}}>
                {val}
              </span>
            </li>
          ))}
        </ul>
      ))}
    </section>
  );
};

export default UpgradeRequestDetails;
