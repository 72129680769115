/* eslint-disable no-unused-vars */
import React, { useRef } from 'react';
import { useUnmount } from 'react-use';

import CustomCheckbox from '+containers/Dashboard/Shared/CustomCheckbox';
import ToolTip from '+containers/Dashboard/Shared/Tooltip';
import useStore from '+store';
import { ICreateEmailConfigurationSlice, IEmailCategory } from '+types';
import { EmailListValidation } from '+utils';

import InfoIcon from '+assets/img/dashboard/information-button.svg';

import './index.scss';

interface IConfigurationFormProps {
  configData: IEmailCategory;
  refetch: () => void;
}

const ConfigurationForm: React.FC<IConfigurationFormProps> = ({ configData, refetch }) => {
  const ref = useRef(null);
  const state = useStore<ICreateEmailConfigurationSlice>(store => store as ICreateEmailConfigurationSlice);

  const isFormInvalid = configData?.forms?.some(form => !!EmailListValidation(form?.value, form?.emailLimit));

  const disabled = isFormInvalid || configData.disableButton || configData.isLoading;

  const unMount = () => {
    if (state.updateInProgress || state.additionalContentModified) {
      if (disabled && !state.additionalContentModified) {
        state.setShowEmailConfigModal(false);
        state.setUpdateInProgress(false);
        return;
      }
      state.setModalStage('init');
      state.setShowEmailConfigModal(true);
      state.setSaveFn(configData.onSave);
      state.setUpdateInProgress(false);
      state.setAdditionalContentModified(false);
    }
  };
  useUnmount(unMount);
  return (
    <div className="configuration-form" ref={ref}>
      <h3>{configData?.title}</h3>
      <p className="description">{configData?.description}</p>
      {configData?.text && <p className="sub-text">{configData?.text}</p>}
      {configData?.options &&
        configData?.options?.map(option => {
          return (
            <div className="form-check mb-3" key={option?.name}>
              <label htmlFor={option.name} className="form-check-label checkbox-text">
                <CustomCheckbox
                  className="form-check-input"
                  checked={option?.value}
                  onChange={() => {
                    configData?.onCheckBoxChange?.(!option?.value, option?.name);
                    state.setUpdateInProgress(true);
                  }}
                  text=""
                />
                {option?.option}
              </label>
            </div>
          );
        })}
      <div className="configuration-form-heading">
        {configData?.forms?.map(form => {
          return (
            <div className="form-group mt-0 mb-0" key={form.name}>
              <label htmlFor="refund-amount" className="withdraw-label">
                <span className="dark" style={{ color: '#3E4B5B' }}>
                  {form?.formHeading}
                  <ToolTip image={InfoIcon} type="accepted_amount" message={<p>{form?.tooltip_note}</p>} />
                </span>
              </label>
              <input
                data-testid="config-email-input"
                value={form?.value}
                onChange={({ target: { value } }) => {
                  const splitValue = value.split(',');
                  if (splitValue.length > form?.emailLimit) {
                    return;
                  }
                  configData?.onTextChange(value, form?.name);
                  state.setUpdateInProgress(true);
                }}
                className={`form-control email-input ${
                  !!EmailListValidation(form?.value, form?.emailLimit) && !!form?.value ? 'error' : ''
                }`}
              />
              {form?.value && <p style={{ color: '#ff5661' }}>{EmailListValidation(form?.value, form?.emailLimit)}</p>}
            </div>
          );
        })}
      </div>

      {configData?.additionalContent}

      <div className="button-row">
        <button
          className="btn"
          type="button"
          onClick={async () => {
            try {
              await configData.onSave();
              if (!configData?.hideSuccessAfterSave) {
                state.setModalStage('complete');
                state.setShowEmailConfigModal(true);
              }
              refetch();
            } catch (error) {
              state.setShowEmailConfigModal(false);
            } finally {
              state.setUpdateInProgress(false);
            }
          }}
          disabled={disabled}
        >
          {configData.isLoading ? <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true" /> : null}
          {configData.isLoading ? <span style={{ marginLeft: '0.5rem' }}>Saving...</span> : `Save`}
        </button>
      </div>
    </div>
  );
};

export default ConfigurationForm;
