import { MouseEventHandler } from 'react';

const PageHeader = (props: { disableConfigButton: boolean; navigateToServiceConfiguration: MouseEventHandler<HTMLButtonElement> }) => (
  <div className="row">
    <div className="col-sm-12">
      <div className="bulk-payouts-desc">
        <div className="heading-box-mmd">
          <h4 className="form-header payment-link-header">Protect your business with Identity</h4>
          <p className="form-desc payment-desc">
            Verifying your customers&apos; identities with Kora helps you know who you are doing business with. It minimizes fraud, enhances
            risk operations, and makes your business safer and more trustworthy. With Kora&rsquo;s Identity service, you can easily build
            verification workflows, fortify your KYC, KYB and AML capabilities, and protect your business.{' '}
            <a href="https://developers.korapay.com/docs" target="noopener noreferrer" style={{ marginLeft: '0.3rem' }}>
              Learn more about how Identity works.
            </a>
          </p>
        </div>
      </div>
    </div>
  </div>
);

export default PageHeader;
