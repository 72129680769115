import { ErrorMessage } from 'formik';

interface FieldProps {
  name: string;
  value: string;
  onChange: () => void;
}

interface FormProps {
  touched: Record<string, any>;
  errors: Record<string, any>;
}

interface ICustomInputProps {
  field: FieldProps;
  form: FormProps;
  label: string;
  type?: string;
  [key: string]: any; 
}

const CustomInput = ({ field, form, label, type = 'text' , ...props}: ICustomInputProps) => {
  return (
    <div className="form-group">
      <label htmlFor={field.name}>{label}</label>
      <input className="form-control" {...field} {...props} type={type} autoComplete="false" />
      <ErrorMessage name={field.name}>
        {msg => (
          <div className="input__errors">
            <p>{msg}</p>
          </div>
        )}
      </ErrorMessage>
    </div>
  );
}


export default CustomInput;
