import React, { useRef, useState, ElementRef } from 'react';
import FundByBankModal from './FundByBankModal';
import { AddFundsProps } from '+types/common';

const AddFunds: React.FC<AddFundsProps> = ({
  virtualAccount,
}) => {
  const wrapperRef = useRef<ElementRef<'div'>>(null);
  const [fundByBank, setFundByBank] = useState(false);

  return (
    <div className="history_summary_option mb-3">
      <section ref={wrapperRef} style={{ position: 'relative' }}>
        <button
          className="btn btn-success"
          type="button"
          onClick={
            () => setFundByBank(true)
          }
          onKeyDown={(e) => {
            if (e.key === 'Enter' || e.key === ' ') {
              setFundByBank(true);
            }
          }}
        >
          <span className="os-icon os-icon-plus" />
          <span>Add Funds</span>
        </button>
      </section>
      {fundByBank && (
        <FundByBankModal visible={true} close={() => setFundByBank(false)} virtualAccount={virtualAccount} />
      )}
    </div>
  );
};

export default AddFunds;
