import { IFilter, ITableData } from '+containers/Dashboard/Issuing/types';
import { history } from '+utils';

import { billingHistoryFilterFields, verificationEventsFilterFields } from '../data/identity-data';
import { BillingHistoryDataType, VerificationDataType } from '../types/types';
import renderBillingHistory from './renderBillingHistory';
import renderEventsHistory from './renderEventsHistory';

interface IdentityTableDataPairsProps {
  verificationData: VerificationDataType[];
  verificationRefetch: () => void;
  billingData: BillingHistoryDataType[];
  billingRefetch: () => void;
}
export default ({
  verificationData,
  verificationRefetch,
  billingData,
  billingRefetch
}: IdentityTableDataPairsProps): Record<any, ITableData & { filterProps: IFilter }> => {
  return {
    'Verification Events': {
      tableTitle: 'Verification Events',
      headings: [
        { value: 'Reference' },
        { value: 'Individual / Business’' },
        { value: 'ID Status' },
        { value: 'Event Result' },
        { value: 'ID Type' },
        { value: 'Region' },
        { value: 'Date created' }
      ],
      emptyStateHeading: 'No events found',
      emptyStateMessage: 'It seems there are no events here.',
      tableClassName: 'identity-events-table',
      filterProps: {
        filterModalHeading: 'Filter Verification Events',
        filterModalDescription: 'Use any of the following advanced filter controls to find specific event.',
        filterFields: verificationEventsFilterFields,
        onHandleFilter: verificationRefetch,
        quickFilterType: 'verification-events',
        exportType: 'verification-events',
        exportHeading: 'Export Verification Events',
        exportDescription: 'Choose how you would like to export these Verification Events.'
      },
      renderChildren: () =>
        renderEventsHistory(verificationData, (ref?: string) => {
          history.push(`/dashboard/identity/verification/${ref}`);
        })
    },
    Billing: {
      tableTitle: 'Billing History',
      headings: [
        { value: 'Date' },
        { value: 'Billing for' },
        { value: 'Catergory' },
        { value: 'Verification Class' },
        { value: 'ID Type' },
        { value: 'Amount' },
        { value: 'Status' }
      ],
      emptyStateHeading: 'No billing history found',
      emptyStateMessage: 'It seems there are no history.',
      tableClassName: 'billing-history-table',
      tableActions: [''],
      filterProps: {
        filterModalHeading: 'Filter Billing History',
        filterModalDescription: 'Use any of the following advanced filter controls to find history',
        filterFields: billingHistoryFilterFields,
        onHandleFilter: billingRefetch,
        quickFilterType: 'billing-history',
        exportType: 'billing-history',
        exportHeading: 'Export Billing Events',
        exportDescription: 'Choose how you would like to export these History.'
      },
      renderChildren: () => renderBillingHistory(billingData, () => {})
    }
  };
};
