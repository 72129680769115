import React, { useEffect, useLayoutEffect, useState } from 'react';
import { useQueryClient } from 'react-query';
import { Link } from 'react-router-dom';
import useStore from '+store';
import { capitalizeRemovedash } from '+utils';
import BankAccounts from './components/BankAccounts';
import VerificationDocument from './components/VerificationDocument';
import BusinessRepresentative from './components/BusinessRepresentative';
import BusinessProfile from './components/BusinessProfile';
import Tooltip from '../../Shared/Tooltip';

import question from '+assets/img/dashboard/question-mark.svg';
import CheckMarkSVG from '+assets/img/dashboard/check-green.svg';
import CheckMarkPNG from '+assets/img/dashboard/check-green.png';
import WarningSVG from '+assets/img/dashboard/warning-outline.svg';
import WarningPNG from '+assets/img/dashboard/warning-outline.png';
import './index.scss';

const MerchantComponent = () => {
  const [activeTab, setActiveTab] = useState('Business Profile');
  const [tabs, setTabs] = useState(['Business Profile', 'Verification Documents', 'Business Representative', 'Bank Account']);
  const [feedbackVisible, setFeedbackVisible] = useState(false);
  const queryClient = useQueryClient();
  const merchantKYC = queryClient.getQueryData('MERCHANT_KYC');
  const MERCHANT_ENV = useStore(store => store.merchantEnv);

  useLayoutEffect(() => {
    if (merchantKYC?.business_type === 'individual') {
      const newTab = tabs.filter(tab => tab !== 'Business Representative');
      setTabs(newTab);
    }
  }, [merchantKYC]);

  useEffect(() => {
    const { hash } = window.location;
    if (hash) document.querySelector(`${hash}`).scrollIntoView({ behavior: 'smooth' });
    setFeedbackVisible(true);
  }, []);

  const handleCloseFeedback = () => setFeedbackVisible(null);

  const renderTabs = () => {
    switch (activeTab) {
      case 'Business Profile':
        return <BusinessProfile />;
      case 'Verification Documents':
        return <VerificationDocument />;
      case 'Business Representative':
        return <BusinessRepresentative />;
      case 'Bank Account':
        return <BankAccounts />;
      default:
        return <BusinessProfile />;
    }
  };

  const renderStatusFeedBack = () => {
    switch (merchantKYC?.compliance?.status) {
      case 'verified':
        return (
          <div className="live-status-feedback mt-2 mb-4">
            <div>
              <span>
                <img src={CheckMarkSVG} scrSet={CheckMarkPNG} alt="Check" />
              </span>
              <p className="live-status-feedback-info">
                Your account has been verified, and you can now turn on <span>Live Mode.</span>
              </p>
            </div>
            <div
              className="live-status-feedback-action"
              onClick={handleCloseFeedback}
              role="button"
              tabIndex="0"
              onKeyDown={handleCloseFeedback}
            >
              <p>Close</p>
            </div>
          </div>
        );
      case 'unverified':
      case 'kyc':
      case 'feedback':
        return (
          <div className="pending-status-feedback mt-2 mb-4">
            <div className="pending-status-feedback-info">
              <span>
                <div className="kyc-warning-heading">
                  <img src={WarningSVG} scrSet={WarningPNG} alt="Check" />
                  <p>Complete the step(s) below to verify your account.</p>
                </div>
                <div className="kyc-steps">
                  <ul>
                    <li> - Provide verification documents for business verification</li>
                    <li> - Add a bank to be settled in</li>
                    {merchantKYC?.compliance?.flagged_categories?.length > 0 &&
                      merchantKYC?.compliance?.flagged_categories.map(flaggedCategory => (
                        <li key={flaggedCategory}>{`- ${capitalizeRemovedash(flaggedCategory)}`}</li>
                      ))}
                  </ul>
                </div>
              </span>
              <Link to="/auth/kyc">
                <p className="pending-status-feedback-info__action">Proceed to KYC</p>
              </Link>
            </div>
          </div>
        );
      default:
        return null;
    }
  };

  const renderStatusText = () => {
    if (merchantKYC) {
      switch (merchantKYC?.compliance?.status) {
        case 'verified':
          return (
            <>
              <p className="live_status-verified">Verified</p>
              <Tooltip
                type="status-info"
                image={question}
                message={
                  <section className="live_status--status-tooltip">
                    <p>
                      <strong>Verified</strong>
                    </p>
                    <p>Your account is now active, and you can now make transactions and withdrawals to your bank account.</p>
                  </section>
                }
              />
            </>
          );
        case 'unverified':
          return (
            <>
              <p className="live_status-unverified">Unverified</p>
              <Tooltip
                type="status-info"
                image={question}
                message={
                  <section className="live_status--status-tooltip">
                    <p>
                      <strong>Unverified</strong>
                    </p>
                    <p>Unverified accounts are limited to Test Mode and cannot perform live transactions until they are confirmed.</p>
                  </section>
                }
              />
            </>
          );
        case 'ready':
          return (
            <>
              <p className="live_status-pending">Ready</p>
              <Tooltip
                type="status-info"
                image={question}
                message={
                  <section className="live_status--status-tooltip">
                    <p>
                      <strong>Ready</strong>
                    </p>
                    <p>Your KYC submission is in review, keep an eye out for feedback in your notifications.</p>
                  </section>
                }
              />
            </>
          );
        default:
          return null;
      }
    }
    return null;
  };

  return (
    <div className="">
      <div className="element-box merchant__main">
        <div className="element-box-heading merchant-settings__heading">
          <div className="heading-box-mmd">
            <h5 className="form-header">Compliance</h5>
            <div className="form-desc no-underline mb-2">
              Here you will find information about your company&apos;s compliance and verification status . Please keep in mind that any
              modifications you make below will need to be double-checked for compliance.
            </div>
          </div>
          <div className="live_status mt-2">
            <p className="live_status-label">Go Live Status:</p>
            <span>{renderStatusText()}</span>
          </div>
        </div>
        {feedbackVisible && MERCHANT_ENV === 'test' && renderStatusFeedBack()}
        <div className="tab-section">
          {merchantKYC?.compliance?.status !== 'verified' && <div className="unverified-overlay" />}
          <div className="os-tabs-w mb-3 ">
            <div className="os-tabs-controls os-tabs-complex">
              <ul className="nav nav-tabs">
                {tabs.map(complianceTab => (
                  <li className="nav-item" key={complianceTab}>
                    <button
                      type="button"
                      aria-expanded="false"
                      className={complianceTab === activeTab ? 'nav-link active' : 'nav-link'}
                      data-toggle="tab"
                      onClick={() => setActiveTab(complianceTab)}
                    >
                      <span className="tab-label">{complianceTab}</span>
                    </button>
                  </li>
                ))}
              </ul>
            </div>
          </div>
          <div>{renderTabs()}</div>
        </div>
      </div>
    </div>
  );
};

export default MerchantComponent;
