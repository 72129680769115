export const breadCrumbEvents = {
  signin: {
    resetButtonClicked: 'Reset password button clicked',
    loginButtonClicked: 'Log in button clicked',
    goBackbuttonClicked: 'User navigated back to log in page',
    '2FAButtonClicked': 'Log in Safely button clicked',
    resendOtpButtonClicked: 'Resend OTP Button clicked',
    recoveryCodeClicked: 'User selected recovery code login',
    otpCodeClicked: 'User selected recovery code login'
  },
  signup: {
    signUpButtonClicked: 'User clicked Create Account button'
  },
  disputes: {
    createRefundButton: 'User clicked on the Create Refund button',
    acceptChargeBackButton: 'User clicked on the Accept Chargeback button',
    declineChargeBackButton: 'User clicked on the Decline Chargeback button',
    attemptRefundButton: 'User clicked on the Yes! Refund button, to create a refund',
    attemptAcceptChargebackButton: 'User clicked on the Accept button, to accept a chargeback',
    attemptDeclineChargebackButton: 'User clicked on the Submit button, to decline a chargeback',
    tabClicked: (tab: string) => `User clicked on the ${tab} tab`,
    tableClicked: (tab: string) => `User clicked on the ${tab} table`
  },
  receipt: {
    viewReceiptButton: 'User clicked on the View Receipt button'
  },
  paymentLinkClicked: {
    tableClicked: (tab: string) => `User clicked on the ${tab} table`
  },
  paymentLinks: {
    tabClicked: (tab: string) => `User clicked on the ${tab} tab`,
    paymentLinkClicked: (tab: string) => `User clicked on a ${tab} payment link`,
    createPaymentLinkClicked: 'User clicked Create payment Link button',
    goBackbuttonClicked: 'User clicked the Back to Payment Link Home button',
    singleLinkClicked: 'User clicked on an individual transaction',
    toggleButtonClicked: 'User clicked the status toggle button',
    deleteButtonClicked: 'User clicked the Delete button',
    copyButtonClicked: 'User clicked the Copy Link button'
  },
  upload: {
    uploadFileInputClicked: 'User clicked on the Upload File Input'
  },
  settlements: {
    tabClicked: (tab: string) => `User clicked on the ${tab} settlement tab`,
    tableClicked: (tab: string) => `User clicked on an individual ${tab} settlement`,
    settlementTableClicked: `User clicked on an individual transaction on the settlement history table`
  },
  virtualAccounts: {
    tabClicked: (tab: string) => `User clicked on the ${tab} tab`,
    tableClicked: (tab: string) => `User clicked on an individual ${tab} account`,
    accountTableClicked: `User clicked on an individual transaction on the virtual account table`
  },
  balances: {
    withdrawButtonClicked: 'User clicked on the Withdraw button',
    verifyButtonClicked: 'User clicked on the Verify button to confirm merchant email',
    withdrawButtonOption: (tab: string) => `User clicked on the withdraw to ${tab} option`,
    attemptWithdrawButton: (tab: string, type: string) => `User clicked on the ${tab} button, to do a ${type} withdrawal`
  }
};
