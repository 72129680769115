import React from 'react';
import { capitalize, capitalizeFirst, formatAmount } from '+utils';
import LoadingPlaceholder from '+dashboard/Shared/LoadingPlaceholder';
import SecondaryDetails from '+dashboard/Shared/SecondaryDetails';
import EmptyState from '+dashboard/Shared/EmptyState';
import { IBulkPayoutSummaryProps } from '+types/bulk-payouts';
import './index.scss';

const ValidationInComplete = () => {
  return (
    <div className="bulk-payout__validation-in-complete" data-testid="validation-in-complete">
      <h2 className="title">Bulk Payout Summary</h2>
      <div className="content">
        <span className="spinner-border spinner-border-s text-primary" role="status" aria-hidden="true" />
        <h4>Your file is being Validated...</h4>
        <p>Please wait a few minutes for it to be completed. You will also be notified via email when it is completed. </p>
      </div>
    </div>
  );
};

const BulkPayoutSummary = ({
  dateCompleted,
  summaryData,
  activeCurrency,
  status,
  payCount,
  successful,
  failed,
  isFetching,
  validationComplete = true,
  draftTotalEntries
}: IBulkPayoutSummaryProps) => {

  const getStatusColor = () => {
    switch (status) {
      case 'failed':
        return 'red';
      case 'complete':
        return 'green';
      default:
        return 'yellow';
    }
  };

  const getSummaryDetails = () => {
    if (['draft', 'cancelled'].includes(status)) {
      return [
        {
          'Total Bulk Amount (incl. fees)': `${formatAmount(summaryData?.total_bulk_amount)} ${activeCurrency}`,
          'Date Completed': `${dateCompleted == null ? 'N/A' : dateCompleted}`,
          'No. of Entries (Payouts)': `${draftTotalEntries}`,
          'Valid Entries': <span className="valid">{summaryData?.validPayoutsCount}</span>,
          'Invalid Entries': <span className="invalid">{summaryData?.invalidPayoutsCount}</span>,
          'Queued Entries': 'Not started',
          Status: capitalizeFirst(status)
        }
      ];
    }
    return [
      {
        'Total Bulk Amount (incl. fees)': `${formatAmount(summaryData?.total_bulk_amount)} ${activeCurrency}`,
        'Date Completed': `${dateCompleted == null ? 'N/A' : dateCompleted}`,
        Status: (
          <>
            <span className={`status-pill smaller align-baseline ${getStatusColor()}`} />
            {capitalize(status)}
          </>
        )
      },
      {
        'Payout Count': payCount,
        'Successful Payouts': (
          <>
            <span className={`status-pill smaller ${'Successful Payout' ? 'green' : ''}`} />
            {successful}
          </>
        ),
        'Failed Payouts': (
          <>
            <span className={`status-pill smaller ${'Failed Payout' ? 'red' : ''}`} />
            {failed}
          </>
        )
      }
    ];
  };

  const transferDetails = getSummaryDetails();

  const renderSummary = () => {
    let content;

    if (isFetching) {
      content = <LoadingPlaceholder type="text" content={4} data-testid="loading-placeholder" />;
    } else if (!validationComplete) {
      content = <ValidationInComplete />;
    } else if (transferDetails.length > 0) {
      content = <SecondaryDetails title="Bulk Payout Summary" data={transferDetails} />;
    } else {
      content = <EmptyState message="There is an issue fetching the bulk transaction details" heading="" />;
    }

    return content;
  };
  return <div className="detail-box bulk-payout-summary">{renderSummary()}</div>;
};

export default BulkPayoutSummary;
