import { useReducer, useEffect } from 'react';
import useStore from '+store';
import { useValidateAmount, useMerchantBalances } from '+hooks';
import { getAvailableBalance, getMinConversionLimit, getMaxConversionLimit, createQueryData, createConversionsQueryData } from '+utils';
import { ConversionDataType, StateType } from '+types';

const initialState: StateType = {
  amount: '',
  apiData: null,
  data: null,
  exchange: {},
  countdownCompleted: false,
  isLoading: true,
  countdown: 40,
  startCount: false,
  loading: false,
  isProcessing: false,
  payloadCurrency: {
    from: '',
    to: '',
  },
};

const useConversionLogics = (currency?: string) => {
  const { balances } = useMerchantBalances();
  const defaultMerchant = useStore((state: { defaultMerchant: any }) => state.defaultMerchant);
  const currencyArray = Object.keys(defaultMerchant?.conversion_limits || {});
  const [state, setState] = useReducer((state: StateType, action: Partial<StateType>) => ({ ...state, ...action }), {
    ...initialState,
    payloadCurrency: {
      from: currencyArray[0] || '',
      to: currencyArray[1] || '',
    },
  });

  const { amount, data, payloadCurrency } = state;

  useEffect(() => {
    if (currency) {
      setState({ payloadCurrency: { ...payloadCurrency, from: currency } });
    }
  }, [currency]);

  const { rate, reference, to_amount: toAmount } = (data as unknown) as ConversionDataType || {};

  const availableBalance = getAvailableBalance(payloadCurrency.from, balances) || 0;
  const minConversionLimit = getMinConversionLimit(payloadCurrency.from, defaultMerchant.conversion_limits);
  const maxConversionLimit = getMaxConversionLimit(payloadCurrency.from, defaultMerchant.conversion_limits);

  const { errorMessage, validateAmount, setErrorMessage } = useValidateAmount({
    payloadCurrency,
    amount,
    minConversionLimit,
    maxConversionLimit: maxConversionLimit || 0,
    availableBalance,
  });

  const queryData = createQueryData(amount, payloadCurrency);
  const conversionsQueryData = createConversionsQueryData(queryData, reference);

  return {
    state,
    setState,
    rate,
    reference,
    toAmount,
    minConversionLimit,
    maxConversionLimit,
    errorMessage,
    validateAmount,
    setErrorMessage,
    queryData,
    conversionsQueryData,
    currencyArray,
    availableBalance,
  };
};

export default useConversionLogics;
