import { useState } from 'react';
import { useQuery, useQueryClient } from 'react-query';
import { useParams } from 'react-router-dom';

import IssuanceChargebackModal from '+containers/Dashboard/TransactionDetails/components/IssuanceChargebackModal';
import { switchTrxnMessage } from '+containers/Dashboard/TransactionDetails/data';
import TransactionDetails from '+containers/Dashboard/TransactionDetailsNew';
import { ActionButtons } from '+containers/Dashboard/TransactionDetailsNew/types';
import { useFeedbackHandler } from '+hooks';
import APIRequest from '+services/api-services';
import useStore from '+store';
import { Currency, ICardTransactionDetails, ISODateString } from '+types';
import { formatAmount, history, isObjectNotEmpty } from '+utils';

import { getIssuingPermissions } from '../utils';
import {
  generateChildrenGenerators,
  generateHolderInfoFrom,
  generateMoreDetailsFrom,
  generateSummaryFrom,
  generateSummaryGenerators
} from './TransactionDetailsUtils/CardTransaction';

const publicApi: APIRequest = new APIRequest(process.env.REACT_APP_PUBLIC_MERCHANT_MIDDLEWARE_API_BASE);

const CardTransactionDetails = () => {
  const { id } = useParams<{ id: string }>();
  const queryClient = useQueryClient();
  const { merchantEnv: MERCHANT_ENV, cardIssuanceCardAccess } = useStore.getState();
  const { canManageChargeback } = getIssuingPermissions();
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const { feedbackInit } = useFeedbackHandler();
  const issuingBalance = queryClient.getQueryData(['ISSUING_BALANCE', MERCHANT_ENV]);

  const { data, isLoading, refetch } = useQuery<ICardTransactionDetails>(
    `TRANSACTION_DETAILS_${id}`,
    () => publicApi.getSingleIssuedCardTransaction(id),
    {
      onError: () => {
        feedbackInit({
          message: `There has been an error fetching the details for the transaction: ${id.toUpperCase()}.`,
          type: 'danger'
        });
        history.goBack();
      }
    }
  );

  const fee = data?.fee ?? 0;
  const amount = data?.amount ?? 0;
  const totalAmount = fee + amount;
  const summaryList = generateSummaryFrom(data as ICardTransactionDetails);
  const moreDetailsList = generateMoreDetailsFrom(data as ICardTransactionDetails);
  const holderInfoList = generateHolderInfoFrom(data as ICardTransactionDetails);

  const cardTransactionHasDisputes = () => {
    if ((data && data?.refunds?.length > 0) || isObjectNotEmpty(data?.chargeback)) return true;
    return false;
  };

  const hideEscalation = () => {
    if (data?.type !== 'card_transaction') return true;
    if (data?.card?.reserved && cardIssuanceCardAccess?.access?.reserved?.status !== 'active') return true;
    if (cardIssuanceCardAccess?.access?.customer?.status !== 'active') return true;
    if (!canManageChargeback) return true;
    return false;
  };

  const actionButtons: Array<ActionButtons> = [
    {
      children: (
        <>
          Escalate... <i className="os-icon os-icon-corner-up-right" />
        </>
      ),
      onClick: () => setModalIsOpen(true),
      disabled: (Boolean(data?.status) && data?.status !== 'success') || cardTransactionHasDisputes(),
      hidden: hideEscalation(),
      variant: 'secondary'
    }
  ];

  const generateDisputes = () => ({
    refunds: data?.refunds && data?.refunds.length > 0 ? data?.refunds : [],
    chargebacks: data?.chargeback ? [data?.chargeback] : []
  });

  return (
    <>
      <TransactionDetails>
        <TransactionDetails.Header
          heading={formatAmount(totalAmount)}
          currency={data?.currency}
          status={switchTrxnMessage[(data as ICardTransactionDetails)?.status]?.name}
          statusBg={switchTrxnMessage[(data as ICardTransactionDetails)?.status]?.backgroundColor}
          statusColor={switchTrxnMessage[(data as ICardTransactionDetails)?.status]?.color}
          actionButtons={actionButtons}
          summaries={summaryList}
          isLoading={isLoading}
        />

        <TransactionDetails.Section isLoading={isLoading} heading="More Transaction Details" summaries={moreDetailsList} />

        <TransactionDetails.Section isLoading={isLoading} heading="Cardholder's Information" summaries={holderInfoList} />

        <TransactionDetails.Section isLoading={isLoading} heading="Disputes">
          <TransactionDetails.Disputes
            tabs={['refunds', 'chargebacks']}
            onGenerateDisputes={cardTransactionHasDisputes() ? generateDisputes : null}
            onGenerateSummaryGenerators={generateSummaryGenerators}
            onGenerateChildrenGenerators={generateChildrenGenerators}
            currency={data?.currency as Currency}
          />
        </TransactionDetails.Section>
      </TransactionDetails>

      <IssuanceChargebackModal
        modalIsVisible={modalIsOpen}
        transactionStatus={data?.status}
        transactionCurrency={data?.currency as string}
        transactionAmount={(data?.amount as unknown) as string}
        transactionDate={data?.date as ISODateString}
        issuingBalance={issuingBalance as number}
        reference={data?.reference as string}
        cardScheme={data?.card ? data?.card.brand : ('mastercard' as ICardTransactionDetails['card']['brand'])}
        refetchTransactionDetails={refetch}
        closeModal={() => setModalIsOpen(false)}
      />
    </>
  );
};

export default CardTransactionDetails;
