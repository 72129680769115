import LoadingPlaceholder from '+/containers/Dashboard/Shared/LoadingPlaceholder';
import { capitalize, getDate, getTime, switchCountry } from '+utils';

interface IKYCdetailsProps {
  data: {
    occupation: string;
    phone: string;
    address: {
      address: string;
      city: string;
      country: string;
      state: string;
      zip: string;
    };
    date_of_birth: string;
    date_verified: string;
    verified_identity: {
      type: string;
      number: string;
    };
  };
}
export default function KYCDetails({ data }: IKYCdetailsProps) {
  return (
    <section className="virtual-account-summary">
      {!data ? (
        <LoadingPlaceholder type="text" content={4} />
      ) : (
        <article>
          <ul className="virtual-account-summary-list">
            <li>
              <p>Occupation</p>
              <p className="summaary-value">{capitalize(data?.occupation)}</p>
            </li>
            <li>
              <p>Phone Number</p>
              <p className="phone-number">{data?.phone}</p>
            </li>
            <li>
              <p>{capitalize(data?.verified_identity?.type)}</p>
              <p className="summaary-value">{data?.verified_identity?.number}</p>
            </li>
            <li>
              <p>Date of Birth</p>
              <p className="summaary-value">
                <span style={{ color: '#414F5F' }}>{getDate(data?.date_of_birth)}</span>{' '}
                <span style={{ color: '#A9AFBC' }}>{getTime(data?.date_of_birth)}</span>
              </p>
            </li>
            <li>
              <p>Address</p>
              <p className="summaary-value">{capitalize(data?.address?.address)}</p>
            </li>
            <li>
              <p>City</p>
              <p className="summaary-value">{capitalize(data?.address?.city)}</p>
            </li>
            <li>
              <p>Country</p>
              <p className="summaary-value">{switchCountry(data?.address?.country)}</p>
            </li>
            <li>
              <p>State</p>
              <p className="summaary-value">{capitalize(data?.address?.state)}</p>
            </li>
            <li>
              <p>Zip Code</p>
              <p className="summaary-value">{data?.address?.zip}</p>
            </li>
          </ul>
        </article>
      )}
    </section>
  );
}
