import Copyable from '+containers/Dashboard/Shared/Copyable';
import { capitalizeFirst, capitalizeRemovedash, formatAmount, getDate, getTime } from '+utils/formats';

import { BillingHistoryDataType } from '../types/types';

function handleEnterClick(e: React.KeyboardEvent, onClick = () => { }) {
  if (e.key === 'Enter') {
    e.preventDefault();
    onClick();
  }
}

export default function renderBillingHistory(data: BillingHistoryDataType[], onClick: (ref?: string) => void) {
  return data?.map(tx => {
    const onClick = () => { };

    return (
      <div
        key={`transaction_${tx.reference}`}
        className="div-table --row billing-history-row"
        role="button"
        tabIndex={0}
        onClick={onClick}
        onKeyUp={e => handleEnterClick(e, onClick)}
      >
        <div className="--column">
          <span className="body-row-header">Date Created:</span>
          <span>
            <span>{getDate(tx.date_created)}</span> <span className="grey-text">{getTime(tx.date_created)}</span>
          </span>
        </div>
        <div className="--txn-status --column" style={{ display: 'flex' }}>
          <span className="body-row-header">Billing for:</span>
          <Copyable text={tx.verification_reference || 'Not Available'} />
        </div>

        <div className="--column">
          <span className="body-row-header">Category:</span>
          <span className="value text-uppercase">{tx.verification_type}</span>
        </div>

        <div className="--column">
          <span className="body-row-header">Verification Class:</span>
          <span className="value">{capitalizeRemovedash(tx?.type ?? '')?.replace('Id ', 'ID ') || 'N/A'}</span>
        </div>
        <div className="--column">
          <span className="body-row-header">ID Type:</span>
          <span className="value text-uppercase">{capitalizeRemovedash(tx?.identity_type)}</span>
        </div>
        <div className="--column">
          <span className="body-row-header">Amount:</span>
          <span className="value">
            {formatAmount(tx.amount)} {tx.currency}
          </span>
        </div>
        <div className="--column">
          <span className="body-row-header">Status:</span>
          <span className={`${tx.status === 'success' ? 'paid' : 'unpaid'}`}>
            {tx.status === 'success' ? 'Paid' : capitalizeFirst(tx.status)}
          </span>
        </div>
      </div>
    );
  });
}
