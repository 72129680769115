import React from 'react';
import { history } from '+utils';
import circleExclamentionLogo from '+assets/img/dashboard/circle_exclamation.svg';
import './index.scss';

type AccessDenialPropsType = {
  imgSrc?: string;
  title?: string;
  description?: string;
};

const AccessDenial: React.FC<AccessDenialPropsType> = ({ imgSrc, title, description }) => {
  return (
    <div className="invite-denial">
      <div className="content-i dashboard__accessdenial">
        <div className="content">
          <img src={imgSrc || circleExclamentionLogo} alt="Circle Exclamation Mark" />
          <h2 className="txt-access_denial"> {title || 'Access Denied!'}</h2>
          <p className="txt-access_denial-msg">
            {description ||
              'Sorry, you do not have the permission to perform this action or view this page. Please contact your administrator.'}{' '}
          </p>
          <button type="button" className="btn btn-sm btn-link access_denial-button" onClick={history.goBack}>
            Go back
          </button>
        </div>
      </div>
    </div>
  );
};

export default AccessDenial;
