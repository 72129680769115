import React, { useState } from 'react';

import Table from '+dashboard/Shared/Table';
import { IBulkPayoutTableProps } from '+types/bulk-payouts';
import { capitalize, capitalizeRemovedash, formatAmount, getDate, getTime, history, switchStatus } from '+utils';

import BulkPayoutFilter from './BulkPayoutFilter';

import checkIcon from '+assets/img/dashboard/check-circle.svg';
import closeIcon from '+assets/img/dashboard/close-icon-white-stroke.svg';
import cancelIcon from '+assets/img/dashboard/close-icon.svg';

const dropdownOptions = [
  {
    label: 'All status',
    value: 'All status'
  },
  {
    label: 'Success',
    value: 'success'
  },
  {
    label: 'Failed',
    value: 'failed'
  },
  {
    label: 'Pending',
    value: 'pending'
  },
  {
    label: 'Processing',
    value: 'processing'
  }
];
const pendingOrProcessing = 'Your bulk payout is being processed. You will be notified when it is done.';
const statusMsg = {
  pending: pendingOrProcessing,
  processing: pendingOrProcessing,
  complete: 'Bulk payouts complete'
};

const TableBanner = ({ status, close }: { status: keyof typeof statusMsg; close: () => void }) => {
  return (
    <div className={`bulk-payout__banner ${status}`}>
      <p>
        {status === 'complete' ? (
          <img src={checkIcon} alt="bulk payment completed" />
        ) : (
          <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true" />
        )}
        <span>{statusMsg[status]}</span>
      </p>
      <button type="button" onClick={() => close()}>
        {status === 'complete' ? <img src={closeIcon} alt="close banner" /> : <img src={cancelIcon} alt="close banner" />}
      </button>
    </div>
  );
};

const BulkPayoutTable = ({
  isFetching,
  paging,
  setPagination,
  refetch,
  pagination,
  data,
  setExportModalVisible,
  permissions,
  status
}: IBulkPayoutTableProps) => {
  const [showBanner, setShowBanner] = useState(true);

  const tableData = () => {
    return (
      <div>
        {data?.map((each: any) => (
          <div
            data-testid="status-row"
            className="div-table --history-table --row"
            key={each?.reference}
            onClick={() => history.push(`/dashboard/payouts/${each?.reference}`)}
            onKeyDown={() => history.push(`/dashboard/payouts/${each?.reference}`)}
            role="button"
            tabIndex={0}
          >
            <div className="--txn-status status">
              <span className="body-row-header">Status:</span>
              <span className={`status-pill smaller ${switchStatus(each?.status)}`} />
              <span>{capitalizeRemovedash(status === 'processing' ? 'processing' : each?.status)}</span>
            </div>
            <div>
              <span className="body-row-header">Transaction Date:</span>
              <span>
                {getDate(each?.transaction_date)}
                <span className="annotation trxn-date">{getTime(each?.transaction_date)}</span>
              </span>
            </div>
            <div>
              <span className="body-row-header bulk-payout-id">Bulk Payout ID:</span>
              <span>{each?.reference}</span>
            </div>
            <div>
              <span className="body-row-header">Customer:</span>
              <span>{capitalize(each?.customer.name)}</span>
            </div>
            <div>
              <span className="body-row-header amount">Amount:</span>
              <span>
                <span>{formatAmount(each?.amount)} </span>
                {each?.currency || 'NGN'}
              </span>
            </div>
          </div>
        ))}
      </div>
    );
  };

  return (
    <section className="transaction_table_comp">
      <BulkPayoutFilter
        handleFilterQuery={param => {
          setPagination({ ...pagination, page: 1, sorterType: 'filter', sortingParams: param });
        }}
        openExportModal={setExportModalVisible}
        totalCount={data?.length}
        status={status}
        activeCurrency="NGN"
        userAccess={permissions}
        previousFilter={false}
        setPreviousFilter={() => { }}
        dropdownOptions={dropdownOptions}
      />
      <Table
        tableClassName="--history-table"
        headings={[
          {
            value: 'Status'
          },
          {
            value: 'Transaction Date:'
          },
          {
            value: 'Bulk Payout ID '
          },
          {
            value: 'Customer'
          },
          {
            value: 'Amount'
          }
        ]}
        banner={showBanner && <TableBanner close={() => setShowBanner(false)} status={status} />}
        hasPagination
        loading={isFetching}
        current={paging?.current}
        totalItems={paging?.total_items || 0}
        pageSize={paging?.page_size}
        actionFn={page => setPagination({ ...pagination, page })}
        limitAction={limit => setPagination({ ...pagination, limit, page: 1 })}
        emptyStateHeading="No transactions found"
        emptyStateMessage={
          <>
            <span>It seems there are no transactions here.</span>
            <button type="button" className="refetch-button" onClick={() => refetch()}>
              <i className="os-icon os-icon-rotate-ccw" />
              Refresh
            </button>
          </>
        }
      >
        {tableData()}
      </Table>
    </section>
  );
};

export default BulkPayoutTable;
