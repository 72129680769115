import React, { useRef, useState, ElementRef } from 'react';
import ConversionsModal from './ConversionsModal';
import { AddFundsProps } from '+types/common';

const ConvertFunds: React.FC<AddFundsProps> = ({
  minWalletPayoutLimit,
  currency,
  balances,
  refetchBalance,
  walletPayoutLimit
}) => {
  const wrapperRef = useRef<ElementRef<'div'>>(null);
  const [convertFunds, setConvertFunds] = useState(false);

  return (
    <div className="history_summary_option">
      <section ref={wrapperRef} style={{ position: 'relative' }}>
        <button className="btn btn-convert-funds" type="button"
          onClick={() => { setConvertFunds(true) }}
        >
          <span className="os-icon os-icon-repeat" />
          <span>Convert Funds</span>
        </button>
      </section>
      {
        convertFunds && (
          <ConversionsModal
            visible={true}
            close={() => setConvertFunds(false)}
            currency={currency}
            minPayoutLimit={minWalletPayoutLimit}
            balances={balances}
            refetchBalance={refetchBalance}
            maxPayoutLimit={walletPayoutLimit}
          />
        )
      }
    </div >
  );
};

export default ConvertFunds;
