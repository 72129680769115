/* eslint-disable camelcase */
import { useState, useEffect } from 'react';
import { useMutation } from 'react-query';

import APIRequest from '+services/api-services';

const apiRequest = new APIRequest(process.env.REACT_APP_PUBLIC_MERCHANT_MIDDLEWARE_API_BASE);
export default function useGetFee({ type = 'disburse', currency, channel = 'bank_account', currentAmount }) {
  const [fee, setFee] = useState('0.00');
  const [fetchingFee, setFetchingFee] = useState(false);
  const getFee = useMutation(
    amount =>
      apiRequest.calculateTransferFees({
        type,
        currency,
        channels: [channel],
        amount
      }),
    {
      onSuccess: data => {
        const value = data[channel];
        setFetchingFee(false);
        const amount = value?.fee + value?.vat;
        setFee(Number.isNaN(amount) ? '0.00' : amount.toFixed(2));
      },
      onError: () => {
        setFee('N/A');
      }
    }
  );

  useEffect(() => {
    if (currentAmount > 0) {
      getFee.mutate(currentAmount, 500);
    }
  }, [currentAmount]);

  return { fee, fetchingFee, setFee };
}
