import { useState } from 'react';
import { useQuery } from 'react-query';

import { CreditCard } from '+containers/Shared/Icons';
import LoadingPlaceholder from '+dashboard/Shared/LoadingPlaceholder';
import { useFeedbackHandler } from '+hooks';
import APIRequest from '+services/api-services';
import { logError } from '+utils';
import useStore from '+store';

import CardOperationsModal from '../../components/CardOperationsModal';
import { CardCategoryType, CardOpType, IError } from '../../types';
import CardInformation from './CardInformation';
import CardManagement from './CardManagement';
import StatusNotification from './StatusNotification';
import { getIssuingPermissions } from '../../utils';

const publicApi = new APIRequest(process.env.REACT_APP_PUBLIC_MERCHANT_MIDDLEWARE_API_BASE);

const DetailsSection = ({ cardCategory, cardId: ref, currency }: { cardCategory: CardCategoryType; cardId: string; currency: string }) => {
  const { merchantEnv: MERCHANT_ENV, issuingBalance } = useStore.getState() as {
    merchantEnv: string;
    issuingBalance: { [x: string]: { available_balance: number } };
  };
  const [cardAction, setCardAction] = useState<CardOpType | ''>('');
  const availableBalance = issuingBalance?.[currency]?.available_balance ?? 0;
  const { feedbackInit } = useFeedbackHandler();
  const {canViewCardDetails} = getIssuingPermissions();

  const { data: cardDetails, isFetching: fetchingCardDetails, refetch: refetchCardDetails } = useQuery(
    [cardQueryConfig[cardCategory].queryKey, ref, MERCHANT_ENV],
    () => publicApi.getSingleIssuedCard(ref, { ...cardQueryConfig[cardCategory].queryFnArg }),
    {
      onError: (e: IError) => {
        logError(e);
        const message = e.response?.data?.message || 'There has been an error in getting your balance';
        feedbackInit({
          message,
          type: 'danger',
          action: {
            action: refetchCardDetails,
            name: 'Try again'
          }
        });
      },
      enabled: canViewCardDetails
    }
  );
  const { last_four: lastFour = '', brand = '', category } = cardDetails || {};

  if (fetchingCardDetails) return <LoadingPlaceholder type="text" />;

  return (
    <div>
      <div className="page-heading">
        <div className="id-area">
          <div className="avatar">
            <CreditCard title="" titleId="" className="image" />
          </div>
          <div className="texts">
            <h3 className="header-text">Card ending with **** {lastFour}</h3>
            <p className="description">
              {brand}
              <span className="ellipse" style={{ marginInline: '0.5rem' }} />
              {category} card
            </p>
          </div>
        </div>

        <CardManagement status={cardDetails?.status} cardCategory={cardCategory} onActionClick={setCardAction} />
      </div>
      <hr />

      <CardInformation cardDetails={cardDetails} cardCategory={cardCategory} cardId={ref} />

      <StatusNotification status={cardDetails?.status} event={cardDetails?.event} />

      {cardAction ? (
        <CardOperationsModal
          cardId={ref}
          action={cardAction}
          onClose={() => setCardAction('')}
          balance={availableBalance}
          currency={currency || 'usd'}
        />
      ) : null}
    </div>
  );
};

export default DetailsSection;

const cardQueryConfig = {
  customer_cards: {
    queryKey: 'CUSTOMER_CARD_DETAILS',
    queryFnArg: { cardCategory: 'customer' }
  },
  reserved_cards: {
    queryKey: 'RESERVED_CARD_DETAILS',
    queryFnArg: { cardCategory: 'reserved' }
  }
} as const;
