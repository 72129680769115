import useStore from '+store';

const PermissionKeys = {
  WALLET_BAL: 'card_issuance_wallet_balance',
  WALLET_HIST: 'card_issuance_wallet_history',
  CARD_DETAIL: 'card_issuance_card_detail',
  CARD_LIST: 'card_issuance_card_list',
  CHARGEBACK: 'card_issuance_chargeback',
  TRXN_DETAIL: 'card_issuance_card_transaction_detail',
  TRXN_LIST: 'card_issuance_card_transaction_list',
  FUND_WALLET: 'card_issuance_fund_wallet',
  CREATE_RESERVED_CARD: 'card_issuance_reserved_card_creation',
  FUND_RESERVED_CARD: 'card_issuance_reserved_card_funding',
  CARD_ACCESS_REQUEST: 'card_issuance_access_request',
  CARD_ISSUANCE_BILLING: 'card_issuance_billing'
};

const PermissionType = {
  VIEW: 'view',
  MANAGE: 'manage',
  EXPORT: 'export',
  NO_ACCESS: 'no_access'
};

export default function getIssuingPermissions() {
  const permissions = useStore.getState()?.permissions;

  // View Permissions
  const canViewWalletBalance = permissions[PermissionKeys.WALLET_BAL] === PermissionType.VIEW;

  const canViewWalletHistory = [PermissionType.VIEW, PermissionType.EXPORT].includes(permissions[PermissionKeys.WALLET_HIST]);

  const canViewCardList = [PermissionType.MANAGE, PermissionType.VIEW].includes(permissions[PermissionKeys.CARD_LIST]);

  const canViewCardDetails = [PermissionType.MANAGE, PermissionType.VIEW].includes(permissions[PermissionKeys.CARD_DETAIL]);

  const canViewCardTrxns = [PermissionType.EXPORT, PermissionType.VIEW].includes(permissions[PermissionKeys.TRXN_LIST]);

  const canViewTrxnDetails = permissions[PermissionKeys.TRXN_DETAIL] === PermissionType.VIEW;

  const canViewChargebacks = [PermissionType.MANAGE, PermissionType.EXPORT, PermissionType.VIEW].includes(
    permissions[PermissionKeys.CHARGEBACK]
  );

  const canViewBillingHistory = permissions[PermissionKeys.CARD_ISSUANCE_BILLING] === PermissionType.VIEW;

  // Management Permissions
  const canFundWallet = permissions[PermissionKeys.FUND_WALLET] === PermissionType.MANAGE;

  const canManageChargeback = permissions[PermissionKeys.CHARGEBACK] === PermissionType.MANAGE;

  const canManageCardDetails = permissions[PermissionKeys.CARD_DETAIL] === PermissionType.MANAGE;

  const canRequestCardAccess = permissions[PermissionKeys.CARD_ACCESS_REQUEST] === PermissionType.MANAGE;

  const canFundReservedCard = permissions[PermissionKeys.FUND_RESERVED_CARD] === PermissionType.MANAGE;

  const canCreateReservedCard = permissions[PermissionKeys.CREATE_RESERVED_CARD] === PermissionType.MANAGE;

  // Export Permissions
  const canExportCardTrxns = permissions[PermissionKeys.TRXN_LIST] === PermissionType.EXPORT;

  const canExportWalletHistory = permissions[PermissionKeys.WALLET_HIST] === PermissionType.EXPORT;

  const canExportCardList = permissions[PermissionKeys.CARD_LIST] === PermissionType.MANAGE;

  const canExportChargebacks = [PermissionType.MANAGE, PermissionType.EXPORT].includes(permissions[PermissionKeys.CHARGEBACK]);

  return {
    canRequestCardAccess,
    canExportCardTrxns,
    canExportCardList,
    canExportChargebacks,
    canExportWalletHistory,
    canViewCardTrxns,
    canViewTrxnDetails,
    canViewCardDetails,
    canViewCardList,
    canViewWalletBalance,
    canViewWalletHistory,
    canViewChargebacks,
    canViewBillingHistory,
    canFundWallet,
    canFundReservedCard,
    canCreateReservedCard,
    canManageChargeback,
    canManageCardDetails
  };
}
