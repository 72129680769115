/* eslint-disable react/no-array-index-key */
import './index.scss';

interface ILoadingPlaceholderProps {
  type: string;
  content?: number;
  background?: string;
}

const LoadingPlaceholder = ({
  type,
  content = 3,
  background = 'white'
}: ILoadingPlaceholderProps) => {
  const rowsArray = Array(3).fill(0);
  const contentArray = Array(content).fill(0);

  const textboxWidth = () => {
    const percent = Math.floor(Math.random() * (90 / content));
    const width = window.innerWidth < 700 ? Math.floor(Math.random() * (window.innerWidth * 0.8)) : window.innerWidth * (percent / 100);
    return `${width}px`;
  };

  return (
    <div className="table-loader-container" data-testid="table-loader">
      {type === 'table' && (
        <>
          {rowsArray.map((_, i) => (
            <div className="loader-row" key={i} style={{ backgroundColor: background }}>
              {contentArray.map((x, index) => (
                <div className="loader-content" key={index} style={{ width: `${Math.floor(Math.random() * (110 / content))}%` }} />
              ))}
            </div>
          ))}
        </>
      )}

      {type === 'text' && (
        <div className="textbox-loader-container">
          {contentArray.map((x, i) => (
            <div className="textbox-loader" key={i}>
              {rowsArray.map((_, index) => (
                <div className="loader-content" key={index} style={{ width: `${textboxWidth()}` }} />
              ))}
            </div>
          ))}
        </div>
      )}
    </div>
  );
}

export default LoadingPlaceholder;
