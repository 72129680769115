import { useQuery, useState } from 'react-query';
import APIRequest from '+services/api-services';
import { logError, currencyToCountryCode } from '+utils';
import { useFeedbackHandler } from '+hooks';
import { TError, MobilePayoutsModalProps } from '+types';

const api = new APIRequest();

export default function useMobileMoneyNetwork(currency: string) {
  const { feedbackInit } = useFeedbackHandler();
  const countryCode = currencyToCountryCode[currency.toUpperCase()];

  const result = useQuery(
    'mobile_money_network',
    () => api.fetchMobileMoneyNetwork(countryCode),
    {
      onError: (error: TError) => {
        logError(error);
        feedbackInit({
          message: error.response?.data?.message || 'There has been an error getting network providers. Please refresh the page.',
          type: 'danger',
          componentLevel: true,
          action: {
            action: () => result?.refetch(),
            name: 'Try again'
          }
        });
      },
      enabled: !!currency,
    }
  );

  return result;
};
