import React from 'react';
import clsx from 'clsx';
import ToolTip from '+dashboard/Shared/Tooltip';
import InfoIcon from '+assets/img/dashboard/information-button.svg';

type FormatterType = (arg: string | number) => string | number;

type SummaryProps = {
  label: string;
  toolTipInfo?: string;
  description: string;
  value: number | string;
  valueFormatter?: FormatterType;
  valueCustomStyles?: string;
  deemphasized?: boolean;
};

const formatValue = (formatter: FormatterType, value?: null | number | string) => {
  if ([undefined, null].includes(value as null | undefined)) return '--';
  if (formatter) return formatter(value as number | string);
  return value;
};

const Summary: React.FC<SummaryProps> = ({
  label,
  toolTipInfo,
  value,
  valueFormatter,
  valueCustomStyles,
  description,
  deemphasized = false
}) => {
  return (
    <div className={clsx('info-summary-item', { greyed: deemphasized })}>
      <div className="label">
        {label}
        {toolTipInfo && (
          <span>
            <ToolTip image={InfoIcon} message={toolTipInfo} />
          </span>
        )}
      </div>
      <div className={clsx('value', valueCustomStyles)}>{formatValue(valueFormatter, value)}</div>
      <p className="description">{description}</p>
    </div>
  );
};

export default Summary;
