import { Route, Switch } from 'react-router-dom';

import { useSearchQuery } from '+hooks';
import useFeedbackHandler from '+hooks/feedbackHandler';
import APIRequest from '+services/api-services';
import { BulkpayoutType, SwitchIconType } from '+types/bulk-payouts';
import { capitalizeFirst, capitalizeRemovedash, filteredOutObjectProperty, getDate, getTime, history, queriesParams } from '+utils';
import useStore from '+store';

import FilterComponent from '../Shared/Filter';
import Table from '../Shared/Table';
import BulkTransactionDetails from './BulkTransactionDetails';
import BulkPayoutFileUpload from './components/BulkPayoutFileUpload';
import { StatusRow } from './components/data';

import cancelled from '+assets/img/dashboard/cancelled_icon.png';
import complete from '+assets/img/dashboard/check.png';
import draft from '+assets/img/dashboard/draft-Icon.png';
import failed from '+assets/img/dashboard/failed_icon.png';
import pending from '+assets/img/dashboard/processing.png';
import processing from '+assets/img/dashboard/processing.png';
import searchClose from '+assets/img/dashboard/search-close.svg';

import './index.scss';

const api = new APIRequest(process.env.REACT_APP_PUBLIC_MERCHANT_MIDDLEWARE_API_BASE);

function BulkPayoutComponent() {
  const { feedbackInit } = useFeedbackHandler();

  const searchQuery = useSearchQuery();
  const activeCurrency = searchQuery.value.currency || 'NGN';
  const limit = searchQuery.value.limit || '10';
  const page = searchQuery.value.page || '1';
  const status = searchQuery.value.status || undefined;

  const isFilterVisible = Boolean(searchQuery.value.isFilterVisible || false);
  const sortingParams = {
    status: typeof status === 'string' ? [status] : status,
    ...filteredOutObjectProperty((searchQuery.value as unknown) as Record<string, string[]>, [
      queriesParams.currency,
      queriesParams.page,
      queriesParams.limit,
      queriesParams.sorterType,
      queriesParams.status,
      queriesParams.isFilterVisible
    ])
  };
  const MERCHANT_ENV = useStore(store => store.defaultMerchant);
  const defaultMerchant: BulkpayoutType = useStore((store: { defaultMerchant: BulkpayoutType }) => store.defaultMerchant);

  const navigateToBulkPayoutFileUpload = () => history.push(`/dashboard/bulk-payout/upload?currency=${activeCurrency}`);

  const { data: bulkTransfer, isFetching, refetch } = useQuery(
    ['BULK_PAYOUT', activeCurrency, page, limit, sortingParams, MERCHANT_ENV],
    () => api.getBulkPayouts(activeCurrency, page, limit, sortingParams),
    {
      keepPreviousData: true,
      onError: () => {
        const message = 'There has been an error getting bulk payouts.';
        feedbackInit({
          message,
          type: 'danger',
          action: {
            action: () => refetch(),
            name: 'Try again'
          }
        });
      }
    }
  );

  const data = bulkTransfer?.data || [];
  const paging = bulkTransfer?.paging || {};

  const switchIcon: SwitchIconType = {
    pending,
    complete,
    failed,
    draft,
    cancelled,
    processing
  };

  const onRowClick = (id: string, currency: string) => {
    return history.push(`/dashboard/bulk-payout/${id}/payout?currency=${currency}`);
  };

  const showClearButton = Object.values(sortingParams || {}).filter(item => !!item).length > 0;
  const tableData = (currency: string) => {
    return (
      <div>
        {data?.map((each: any) => {
          return (
            <div
              tabIndex={0}
              className="div-table --bulkpayouts-table --row"
              key={each.batch_reference}
              onClick={() => onRowClick(each.batch_reference, currency)}
              onKeyDown={() => onRowClick(each.batch_reference, currency)}
              role="button"
            >
              <div className="--txn-status status switch-status">
                <span className="body-row-header">Status:</span>
                <img
                  className="switch-status"
                  src={switchIcon[each?.status as keyof SwitchIconType]}
                  alt="statusIcon"
                  height={15}
                  width={15}
                />
                <span>{capitalizeRemovedash(each.status)}</span>
              </div>
              <div>
                <span className="body-row-header bulk-payout-id">Bulk Payout ID:</span>
                <span>{each.batch_reference}</span>
              </div>
              <div>
                <span className="body-row-header">Descripiton:</span>
                <span>{capitalizeFirst(each.description)}</span>
              </div>

              {each.failed_transactions ? (
                <StatusRow
                  isComplete={each?.status === 'complete'}
                  count={each.failed_transactions}
                  message="Failed payouts"
                  imgSrc={draft}
                />
              ) : (
                <div />
              )}

              <div>
                <span className="body-row-header">Date Created:</span>
                <span>
                  {getDate(each.created_at)}
                  <span className="annotation trxn-date">{getTime(each.created_at)}</span>
                </span>
              </div>
            </div>
          );
        })}
      </div>
    );
  };
  return (
    <>
      <div className="row">
        <div className="col-sm-12">
          <div className="bulk-payouts-desc">
            <div className="heading-box-mmd">
              <h4 role="heading" className="form-header payment-link-header">
                Send multiple transfers with Bulk Payouts
              </h4>
              <p className="form-desc payment-desc">
                With the Bulk Payout tool you can conveniently process multiple payouts to your customers simultaneously. By uploading a
                pre-filled bulk payout file, you can streamline your payout process and save valuable time and effort.
              </p>
              <p className="payout-api-link icon-w">
                <i className="os-icon os-icon-zap" />
                <span>Need super-fast bulk payouts on your applications? Check out</span>
                <a href="https://developers.korapay.com/docs/bulk-payouts-via-api" target="_blank" rel="noreferrer">
                  our APIs.
                </a>
              </p>
            </div>
            <div className="col-lg-3 col-md-4 text-right" style={{ marginRight: '-15px' }}>
              <div className=" __buttons new-bulkpayout-button">
                <button type="button" className="btn btn-primary" onClick={navigateToBulkPayoutFileUpload}>
                  <i className="os-icon os-icon-plus" />
                  <span>New Bulk Payout</span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="content-i">
        <section className="os-tabs-w">
          <div className="os-tabs-controls os-tabs-complex">
            <ul className="nav nav-tabs">
              {Object.keys(defaultMerchant?.payout_limits?.bulk_bank_account || {}).map(currency => (
                <li className="nav-item" key={currency}>
                  <button
                    type="button"
                    className={`nav-link ${activeCurrency === currency ? 'active' : ''}`}
                    onClick={() => {
                      searchQuery.setQuery({ currency }, true);
                    }}
                  >
                    {currency}
                  </button>
                </li>
              ))}
            </ul>
          </div>
        </section>
        <div className="d-flex align-items-center justify-content-between mb-2">
          <div className="d-flex align-items-center">
            {paging?.total_items > 0 && (
              <span className="--filtered-text">
                {paging?.total_items > 1 ? `All Transactions (${paging?.total_items})` : ` All Transactions (${paging?.total_items})`}
                {showClearButton && ' (Filtered)'}
              </span>
            )}
            {!isFilterVisible && showClearButton && (
              <>
                <span className="divider-sm" />
                <button type="button" className="btn btn-sm clear-btn clear-button" onClick={() => searchQuery.clearAll()}>
                  <img src={searchClose} alt="close" aria-hidden />
                  <span>Clear</span>
                </button>
              </>
            )}
          </div>
          <div className="element-actions d-sm-block">
            <div className="search-filter-box">
              <button
                className="btn-sm filter-btn filter-button"
                type="button"
                onClick={() => searchQuery.setQuery({ isFilterVisible: isFilterVisible ? undefined : String(true) })}
              >
                <i className="os-icon os-icon-sliders" />
                <span>{isFilterVisible ? 'Hide' : 'Show'} Filter</span>
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-md-12">
          <div className="element-wrapper">
            <FilterComponent type="bulk-payout" activeCurrency={activeCurrency} isBatchReference />
            <section className="transaction_table_comp bulk-payout-table">
              <Table
                tableClassName="--bulkpayouts-table"
                headings={[
                  {
                    value: 'Status'
                  },
                  {
                    value: 'Bulk Payout ID'
                  },
                  {
                    value: 'Description'
                  },
                  {
                    value: ''
                  },
                  {
                    value: 'Date Created'
                  }
                ]}
                hasPagination
                loading={isFetching}
                current={paging.current}
                totalItems={paging?.total_items || 0}
                pageSize={paging?.page_size || 0}
                actionFn={value => searchQuery.setQuery({ page: String(value) })}
                limitAction={value => searchQuery.setQuery({ limit: String(value) })}
                emptyStateHeading="No bulk payouts yet"
                emptyStateMessage={
                  <>
                    <span>It looks like you have not created any bulk payout at the moment.</span>
                    <button type="button" className="refetch-button" onClick={navigateToBulkPayoutFileUpload}>
                      <i className="os-icon os-icon-plus" />
                      Create Bulk Payout
                    </button>
                  </>
                }
              >
                {tableData(activeCurrency)}
              </Table>
            </section>
          </div>
        </div>
      </div>
    </>
  );
}
export default function BulkPayouts() {
  return (
    <Switch>
      <Route exact path="/dashboard/bulk-payout" component={BulkPayoutComponent} />
      <Route path="/dashboard/bulk-payout/upload" component={BulkPayoutFileUpload} />
      <Route path="/dashboard/bulk-payout/:id">
        <BulkTransactionDetails />
      </Route>
    </Switch>
  );
}
