import React, { useEffect, useLayoutEffect } from 'react';
import { useLocation } from 'react-router-dom';
import useFeedbackHandler, { FeedbackActionType } from '+hooks/feedbackHandler';
import './index.scss';

type FeedbackHandlerProps = {
  component: boolean;
};

export default function FeedbackHandler({ component = false }: FeedbackHandlerProps) {
  const location = useLocation();
  const { closeFeedback, ...restProps } = useFeedbackHandler();

  const { message, type, isActive, title, isClosable, action, statusCode, componentLevel } = restProps;

  useLayoutEffect(() => {
    if (action && typeof action === 'function') action();
    return () => {
      if (action && typeof action === 'function') action();
    };
  }, []);

  useEffect(() => {
    if (isActive) {
      closeFeedback();
    }
  }, [location.pathname, location.search]);

  const handleAction = () => {
    // INCASE YOU NEED TO PASS A FUNCTION TO THE FEEDBACK HANDLER E.G RELOAD PAGE
    ((action as unknown) as FeedbackActionType)?.action();
    closeFeedback();
  };

  const getStatusText = (code: number) => {
    switch (code) {
      case 404:
        return 'Not Found:';
      case 500:
        return 'Server Error:';
      default:
        return null;
    }
  };

  const onClose = () => {
    $('.feedback').addClass('close-feedback');
    closeFeedback();
  };

  return isActive ? (
    <div
      className={`feedback ${type} ${
        componentLevel ? `full-width component-level ${!component && 'd-none'}` : `full-width position-fixed ${component && 'd-none'}`
      }`}
      role="alert"
    >
      <section className="feedbck-message-section">
        {title ? <strong>{title} </strong> : null}
        {statusCode ? `${getStatusText(statusCode)} :` : null}
        <span>{message}</span>
        {action !== null ? (
          <button className="btn-action" onClick={handleAction} type="button">
            {action?.name}
          </button>
        ) : null}
      </section>
      {isClosable ? (
        <button aria-label="Close" className="close" data-dismiss="modal" type="button" onClick={onClose}>
          <span className="os-icon os-icon-close" style={{ color: 'inherit', fontSize: '12px' }} />
        </button>
      ) : null}
    </div>
  ) : null;
}
