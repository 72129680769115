import React from 'react';
import { useQueryClient } from 'react-query';
import { capitalizeFirst, maskBetweenSetRange } from '+utils';
import UserSvg from '+assets/img/dashboard/user.svg';
import UserPNG from '+assets/img/dashboard/user.png';

const BusinessRepresentative = () => {
  const queryClient = useQueryClient();
  const defaultBusiness = queryClient.getQueryData('MERCHANT_KYC');
  const representatives = defaultBusiness?.details?.representatives;
  const businessRepsTable = () => {
    return (
      <div className="mt-4">
        {representatives?.map(representative => {
          return (
            <a
              href={representative?.documents[0]?.file?.url}
              className="div-table --account-table reps-row --row mb-2"
              key={representative?.name}
              download
              target="_blank"
              rel="noopener noreferrer"
            >
              <div>
                <img src={UserSvg} srcSet={UserPNG} alt="document" loading="lazy" />
                <span style={{ textTransform: 'capitalize' }}>{representative?.name}</span>
                <span>{representative?.role?.label}</span>
              </div>
              <div>
                <span>{maskBetweenSetRange(capitalizeFirst(representative?.documents[0]?.file?.description), 4, 9)}</span>
              </div>
            </a>
          );
        })}
      </div>
    );
  };
  return (
    <div className="business-rep__main">
      <h5>Business Representatives</h5>
      <span className="description">Added representatives for your business/organization.</span>
      {businessRepsTable()}
    </div>
  );
};

export default BusinessRepresentative;
