
import React, { useState, useRef } from 'react';
import { useQuery } from 'react-query';
import { Route, Switch } from 'react-router-dom';

import useStore from '+store';
import TransactionDetails from '../TransactionDetails';
import Table from '../Shared/Table';

import { filteredOutObjectProperty, queriesParams, APIDownload, getDate } from '+utils';
import { useFeedbackHandler, useSearchQuery, } from '+hooks';

import APIRequest from '+services/api-services';
import { conversionTableData } from './components/data';
import ConvertCurrencies from './components/ConvertCurrencies';
import Modal from '+dashboard/Shared/Modal';
import ConversionsFilter from './components/ConversionsFilter';
import AdvanceExportModal from '../Shared/AdvanceExportModal';
import ExportFilterModal from '../Shared/ExportFilterModal';

const api = new APIRequest(process.env.REACT_APP_PUBLIC_MERCHANT_MIDDLEWARE_API_BASE);

function ConversionsComponent() {
  const amountInputRef = useRef(null);
  const { profile } = useStore();

  const { feedbackInit } = useFeedbackHandler();
  const searchQuery = useSearchQuery();
  const [isModalOpen, setIsModalOpen] = useState(false);

  const [exportModalVisible, setExportModalVisible] = useState(false);
  const [showLargeExportModal, setLargeExportModal] = useState(false);

  const MERCHANT_ENV = useStore(store => store.defaultMerchant);

  const limit = searchQuery.value.limit || '10';
  const page = searchQuery.value.page || '1';

  const status = searchQuery.value.status || undefined;
  const sortingParams = {
    status: typeof status === 'string' ? [status] : status,
    ...filteredOutObjectProperty((searchQuery.value as unknown) as Record<string, string[]>, [
      queriesParams.currency,
      queriesParams.page,
      queriesParams.limit,
      queriesParams.sorterType,
      queriesParams.status,
      queriesParams.isFilterVisible
    ])
  };

  const { data: conversions, isFetching, refetch } = useQuery(
    ['CONVERSIONS', page, limit, sortingParams, MERCHANT_ENV],
    () => api.getConversions(page, limit, sortingParams),
    {
      keepPreviousData: true,
      onError: () => {
        const message = 'There has been an error getting your conversions.';
        feedbackInit({
          message,
          type: 'danger',
          action: {
            action: () => refetch(),
            name: 'Try again'
          }
        });
      }
    }
  );

  const data = conversions?.data?.data || [];
  const paging = conversions?.data?.paging || {};

  const tableData = conversionTableData(data);
  const showClearButton = Object.values(sortingParams || {}).filter(item => !!item).length > 0;

  const handleConversionSuccess = (isSuccess: boolean) => {
    setIsModalOpen(isSuccess);
  };

  const conversionCompletedModal = () => {
    const content = {
      completedHeading: 'Conversion Successful',
      completedDescription: 'You have successfully completed this  conversion.',
      completedActionText: 'Dismiss',
      completedAction: () => setIsModalOpen(false)
    };

    return {
      visible: isModalOpen,
      hideCloseButton: true,
      size: 'md',
      close: () => () => setIsModalOpen(false),
      modalStage: 'complete',
      ...content
    };
  };

  const exportFile = async (format, fieldToExport) => {
    const parameterizeArray = (key, arr) => {
      arr = arr.map(encodeURIComponent);
      return arr.join(`&${key}[]=`);
    };
    const fields = parameterizeArray('fieldsToExport', fieldToExport);

    try {
      const res = await api.exportConversions(format, sortingParams, fields);
      if (res.status === 202) {
        setLargeExportModal(true);
      } else {
        const type = format === 'csv' ? 'csv' : 'xlsx';
        APIDownload(res, `Settlements at ${getDate(Date.now())}`, type);
        feedbackInit({
          message: `Settlements successfully downloaded`,
          type: 'success'
        });
      }
      setExportModalVisible(false);
    } catch (error) {
      setExportModalVisible(false);
      feedbackInit({
        message: `There has been an error exporting your settlements`,
        type: 'danger'
      });
    }
  };

  return (
    <div className="">
      <Modal {...conversionCompletedModal()} />
      <div className="row">
        <div className="col-sm-12">
          <div className="bulk-payouts-desc">
            <div className="heading-box-mmd">
              <h4 role="heading" className="form-header payment-link-header">
                Quick Currency Conversions
              </h4>
              <p className="form-desc payment-desc">
                Easy and instant conversions between currencies. Note that exchange rates are dynamic and may change over time.
              </p>
            </div>
          </div>
          {<ConvertCurrencies
            onConversionSuccess={handleConversionSuccess}
            amountInputRef={amountInputRef}
            onError={(message: string) => feedbackInit({
              message,
              type: 'danger'
            })}
          />}
        </div>
      </div>
      {<ExportFilterModal close={() => setLargeExportModal(false)} email={profile.email} visible={showLargeExportModal} />}
      {<ConversionsFilter
        openExportModal={() => setExportModalVisible(true)}
        totalCount={paging?.total_items}
      />}
      <div className="row">
        <div className="col-md-12">
          <div className="element-wrapper">
            <section className="transaction_table_comp bulk-payout-table">
              <Table
                tableClassName="--history-table"
                headings={[
                  {
                    value: 'Status'
                  },
                  {
                    value: 'Conversions ID'
                  },
                  {
                    value: 'Converted from'
                  },
                  {
                    value: 'Converted to'
                  },
                  {
                    value: 'Date / Time'
                  }
                ]}
                hasPagination
                loading={isFetching}
                current={paging.current}
                totalItems={paging?.total_items || 0}
                pageSize={paging?.page_size || 0}
                actionFn={value => searchQuery.setQuery({ page: String(value) })}
                limitAction={value => searchQuery.setQuery({ limit: String(value) })}
                emptyStateHeading="No conversions yet"
                emptyStateMessage={
                  <>
                    <span>It looks like you have not done any conversions yet, would you like to carry out one now?</span>
                    <button
                      type="button"
                      className="refetch-button"
                      onClick={() => amountInputRef.current?.focus()}
                    >
                      <i className="os-icon os-icon-plus" />
                      Convert now
                    </button>
                  </>
                }
              >
                {tableData}
              </Table>
            </section>
            {exportModalVisible && (
              <AdvanceExportModal
                openExport={exportModalVisible}
                setOpenExport={setExportModalVisible}
                exportAction={exportFile}
                type="conversions"
                showSuccessModal={false}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
export default function Conversions() {
  return (
    <Switch>
      <Route exact path="/dashboard/conversions" component={ConversionsComponent} />
      <Route path="/dashboard/conversions/:id">
        <TransactionDetails />
      </Route>
    </Switch>
  );
}
