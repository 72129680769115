import { useEffect, useState } from 'react';
import DatePicker from 'react-datepicker';
import dayjs from 'dayjs';

import { capitalizeRemovedash, durationToday, durationWeek } from '+utils';

import Modal from './Modal';

import './index.scss';

type TExportOptionsType =
  | 'pay-in'
  | 'pay-outs'
  | 'settlements'
  | 'balances'
  | 'refunds'
  | 'chargebacks'
  | 'bulk-payouts'
  | 'virtual-cards'
  | 'issuing-balance-history'
  | 'card-transactions'
  | 'card-events'
  | 'issued-card-chargebacks'
  | 'conversions';

function getOptions(type: TExportOptionsType, currency?: string) {
  switch (type) {
    case 'pay-in':
      return {
        payment_reference: 'Payment ID',
        settlement_reference: 'Settlement ID',
        transaction_reference: 'Transaction ID',
        payment_type: 'Payment Method',
        amount_paid: 'Amount Paid',
        amount_charged: 'Amount Charged',
        fee: 'Fee',
        vat: 'VAT',
        currency: 'Currency',
        status: 'Status',
        customer_name: 'Customer Name',
        customer_email: 'Customer Email',
        narration: 'Narration',
        transaction_date: 'Date Created',
        completed_at: 'Date Completed',
        masked_pan: 'PAN',
        reason_for_failure: 'Reason For Failure',
        bank_name: 'Bank Name',
        ...(currency === 'NGN' && { stamp_duty: 'Stamp Duty' })
      };
    case 'pay-outs':
      return {
        reference: 'Transaction ID',
        amount: 'Amount',
        fee: 'Fee',
        vat: 'VAT',
        amount_paid: 'Amount Paid',
        amount_charged: 'Amount Charged',
        currency: 'Currency',
        status: 'Status',
        customer_name: 'Customer Name',
        customer_email: 'Customer Email',
        narration: 'Narration',
        message: 'Message',
        trace_id: 'Session/Trace ID',
        bank_account: 'Bank Account',
        transaction_date: 'Date Created',
        completed_at: 'Date Completed',
        batch_reference: 'Bulk Payout Reference'
      };
    case 'settlements':
      return {
        settlement_reference: 'Settlement ID',
        payment_method: 'Payment Method',
        status: 'Status',
        currency: 'Currency',
        total_transaction_amount: 'Amount Created',
        fees_charged: 'Fees Charged',
        settlement_amount: 'Settlement Amount',
        expected_settlement_date: 'Expected Settlement Date',
        created_at: 'Date Created',
        settled_at: 'Date Settled',
        transaction_volume: 'Volume',
        settled_to: 'Settled To'
      };
    case 'balances':
      return {
        history_date: 'History Date',
        currency: 'Currency',
        direction: 'Direction',
        amount: 'Amount',
        transaction_reference: 'Transaction Reference',
        balance_before: 'Balance Before',
        balance_after: 'Balance After',
        description: 'Description'
      };
    case 'refunds':
      return {
        reference: 'Refund Reference',
        refund_amount: 'Refund Amount',
        amount_collected: 'Amount Collected',
        status: 'Status',
        transaction_reference: 'Transaction Reference',
        type: 'Type',
        payment_reference: 'Payment Reference',
        customer_name: 'Customer Name',
        customer_email: 'Customer Email',
        channel: 'Channel',
        currency: 'Currency',
        reversal_date: 'Reversal Date',
        completed_at: 'Completed Date'
      };
    case 'chargebacks':
      return {
        reference: 'Chargeback Reference',
        amount: 'Amount',
        approved_amount: 'Chargeback Amount',
        status: 'Status',
        payment_source_reference: 'Transaction Reference',
        payment_reference: 'Payment Reference',
        customer_name: 'Customer Name',
        customer_email: 'Customer Email',
        created_at: 'Date Created',
        deadline: 'Due In',
        currency: 'Currency'
      };
    case 'virtual-cards':
      return {
        reference: 'Reference ID',
        date_created: 'Date Created',
        status: 'Status',
        brand: 'Card Brand',
        holder_name: "Cardholder's Name",
        first_six: 'First Six Digits',
        last_four: 'Last Four Digits',
        expiry: 'Card Expiry Date',
        currency: 'Currency',
        type: 'Type'
      };
    case 'issuing-balance-history':
      return {
        history_date: 'Date',
        currency: 'Currency',
        amount: 'Amount',
        direction: 'Direction',
        balance_before: 'Balance Before',
        balance_after: 'Balance After',
        description: 'Description',
        transaction_reference: 'Transaction Reference'
      };
    case 'card-transactions':
      return {
        status: 'Status',
        amount: 'Amount',
        fee: 'Fee',
        currency: 'Currency',
        cross_currency: 'Cross Currency',
        card_holder_name: "Cardholder's Name",
        type: 'Type',
        description: 'Description',
        date: 'Date',
        reference: 'Transaction Reference'
      };
    case 'card-events':
      return {
        reference: 'Reference',
        event: 'Event',
        reason: 'Reason',
        creator: 'Creator',
        date: 'Date'
      };
    case 'issued-card-chargebacks':
      return {
        transaction_reference: 'Transaction ID',
        card_holder_name: 'Cardholder Name',
        last_four: 'Last Four Digits',
        reference: 'Chargeback ID',
        transaction_amount: 'Transaction Amount',
        card_expiry_date: 'Card Expiry Date',
        escalation_date: 'Date Escalated',
        amount: 'Chargeback Amount',
        transaction_type: 'Transaction Type',
        status: 'Status',
        first_six: 'First Six Digits',
        card_type: 'Card Type'
      };
    case 'bulk-payouts':
      return {
        status: 'Status',
        customer_name: 'Customer Name',
        amount: 'Amount',
        currency: 'Currency',
        transaction_date: 'Date Created',
        narration: 'Narration',
        message: 'Message',
        reference: 'Transaction ID',
        batch_reference: 'Bulk Payout Reference',
        trace_id: 'Trace ID'
      };
    case 'conversions':
      return {
        status: 'Status',
        reference: 'Reference',
        exchange_rate: 'Exchange Rate',
        service_charge: 'Service Charge',
        source_amount: 'Source Amount',
        converted_amount: 'Converted Amount',
        source_currency: 'Source Currency',
        destination_currency: 'Destination Currency',
        transaction_date: 'Date'
      };
    default:
      return {};
  }
}

interface IAdvanceExportModalProps {
  heading?: string;
  description?: string;
  openExport: boolean;
  setOpenExport: (open: boolean) => void;
  exportAction: () => void;
  type: string;
  dateRange?: boolean;
  showSuccessModal?: boolean;
  allowColumnSelection?: boolean;
  currency?: string;
}

const AdvanceExportModal = ({
  heading = 'Export Transactions',
  description = 'Choose how you would like to export these transactions',
  openExport,
  setOpenExport,
  exportAction,
  type,
  dateRange,
  showSuccessModal = true,
  allowColumnSelection = true,
  currency
}: IAdvanceExportModalProps) => {
  const rangeOptions = ['today', 'last_7_days', 'all_time', 'custom_range'];
  const switchDuration = {
    today: durationToday(),
    last_7_days: durationWeek(),
    all_time: [null, null]
  };

  const getDuration = () => {
    const start = startDate ? dayjs(startDate).format('YYYY-MM-DD') : null;
    const end = endDate ? dayjs(endDate).format('YYYY-MM-DD') : null;
    if (details.rangeSelected === 'custom_range') return [start, end];
    return switchDuration[details.rangeSelected];
  };

  const options = getOptions(type, currency);
  function markAll() {
    const newObj = {};
    // eslint-disable-next-line array-callback-return
    Object.keys(options).map(i => {
      newObj[i] = true;
    });
    return newObj;
  }

  const [format, setFormat] = useState('csv');
  const [disableAll, setDisableAll] = useState(true);
  const [selectedColumn, setSelectedColumn] = useState('all');
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [details, setDetails] = useState({
    kind: markAll(),
    rangeSelected: null,
    selected: Object.keys(options)
  });

  const selectedKinds = () => {
    return Object.keys(details.kind).filter(kind => details.kind[kind]);
  };

  useEffect(() => {
    if (Object.keys(options).length === details.selected.length) return setSelectedColumn('all');
    return setSelectedColumn('custom');
  }, [details]);

  useEffect(() => {
    if (selectedColumn === 'all') {
      setSelectedColumn('all');
      setDisableAll(true);
      setDetails({
        kind: markAll(),
        rangeSelected: 'today',
        selected: Object.keys(options)
      });
    } else {
      setDisableAll(false);
    }
  }, [selectedColumn]);

  const ExportComponent = () => (
    <div className="filter-body row">
      <div className="form-group col-12">
        <label htmlFor="format">Export As</label>
        <select className="form-control" name="format" onChange={e => setFormat(e.target.value)} value={format}>
          <option value="csv">CSV (file.csv)</option>
          <option value="excel">Excel 2007 and later (file.xlsx)</option>
        </select>
      </div>

      {dateRange && (
        <section className="form-group col-12">
          <label htmlFor="format mb-2">Date Range</label>
          <section style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', justifyContent: 'space-between' }}>
            {rangeOptions.map(option => (
              <div className="form-check" key={option}>
                <label>
                  <input
                    className="form-check-input"
                    type="radio"
                    checked={details.rangeSelected === option}
                    onChange={e => {
                      if (e.target.checked) setDetails(oldDetails => ({ ...oldDetails, rangeSelected: option }));
                    }}
                  />
                  {capitalizeRemovedash(option)}
                </label>
              </div>
            ))}
          </section>
        </section>
      )}

      {details.rangeSelected === 'custom_range' && (
        <section className="form-group col-12" style={{ display: 'flex' }}>
          <div className="form-group filter-object" style={{ marginRight: '10px' }}>
            <label htmlFor="from" style={{ marginRight: '10px' }}>
              From
            </label>
            <DatePicker
              selected={startDate}
              dateFormat="yyyy-MM-dd"
              onChange={date => setStartDate(date)}
              maxDate={new Date()}
              placeholderText="dd-mm-yyyy"
              calendarClassName="custom-datepicker"
            />
          </div>
          <div className="form-group filter-object">
            <label htmlFor="label" style={{ marginRight: '10px' }}>
              To
            </label>
            <DatePicker
              selected={endDate}
              dateFormat="yyyy-MM-dd"
              minDate={startDate || null}
              maxDate={new Date()}
              onChange={date => setEndDate(date)}
              placeholderText="dd-mm-yyyy"
              calendarClassName="custom-datepicker"
            />
          </div>
        </section>
      )}

      {allowColumnSelection && (
        <>
          <div className="form-group col-12">
            <label htmlFor="column">What column(s) would you like to have in the exported file?</label>
            <select
              className="form-control"
              name="column"
              onChange={e => {
                setSelectedColumn(e.target.value);
              }}
              value={selectedColumn}
            >
              <option value="all">All</option>
              <option value="custom">Custom ({details.selected.length})</option>
            </select>
          </div>
          <div className="d-flex flex-wrap">
            {Object.keys(options).map(option => (
              <div className="col-4" key={option}>
                <div className="form-check mb-3" key={option}>
                  <label className="form-check-label checkbox-text">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      checked={details.kind[option]}
                      disabled={disableAll}
                      onChange={e => {
                        details.kind[option] = e.target.checked;
                        setDetails({ ...details, selected: selectedKinds() });
                      }}
                    />
                    {options[`${option}`]}
                  </label>
                </div>
              </div>
            ))}
          </div>
        </>
      )}
    </div>
  );

  return (
    <Modal
      close={() => setOpenExport(false)}
      size="md"
      heading={heading}
      description={description}
      content={<ExportComponent />}
      visible={openExport}
      firstButtonText="Cancel"
      secondButtonText="Export"
      secondButtonAction={() =>
        dateRange ? exportAction(format, details.selected, ...getDuration()) : exportAction(format, details.selected)
      }
      secondButtonActionIsTerminal={showSuccessModal}
    />
  );
};

export default AdvanceExportModal;
