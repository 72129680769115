import React from 'react';

import TabSwitch from './TabSwitch';

import './index.scss';

interface ICurrencyPickerProps {
  options?: string[];
  onChange: (currency: string) => void;
  activeCurrency: string;
  id?: string;
  className?: string;
  label?: React.ReactNode | string;
  allowedOptions?: string[];
  showDropDown?: boolean;
}

const CurrencyPicker = ({
  options = [],
  onChange,
  activeCurrency,
  id = '',
  className,
  label = null,
  allowedOptions = [],
  showDropDown
}: ICurrencyPickerProps) => {
  return (
    <div className={className}>
      <div>
        {label}
        <TabSwitch
          options={options}
          activeTab={activeCurrency}
          setTab={onChange}
          id={id}
          className="ml-3"
          maxVisibleOptions={3}
          allowedOptions={allowedOptions}
          showDropDown={showDropDown}
        />
      </div>
    </div>
  );
};

export default CurrencyPicker;
