import React from 'react';

import './RadioButton.scss';

export interface RadioButtonProps {
  checked: boolean;
  onChange: () => void;
  label: string;
  disabled?: boolean;
  className?: string;
}

const RadioButton: React.FC<RadioButtonProps> = ({ checked, onChange, label, className, disabled = false }) => {
  return (
    <label
      className={`radio-container ${className || ''}`}
      onClick={!disabled ? onChange : undefined}
      onKeyDown={!disabled ? onChange : undefined}
    >
      <input checked={checked} type="radio" onChange={onChange} disabled={disabled} data-testid="refund-type" />
      {label}
    </label>
  );
};

export default RadioButton;
