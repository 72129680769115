import { ClockBlackFill, ClockWhiteFill } from '+containers/Shared/Icons';

const DisplayCountdown = ({ currentCount }: { currentCount: number }) => {
  const countdownText = `Rate valid for ${currentCount} secs.`;

  let classes = 'time_stamp';
  let Icon = ClockBlackFill;
  let textColor = 'text-dark';

  if (currentCount <= 10) {
    classes += ' bg-danger';
    textColor = 'text-white';
    Icon = ClockWhiteFill;
  } else if (currentCount <= 25) {
    classes += ' bg-warning';
  }

  return (
    <span className={`${classes} ${textColor}`}>
      <Icon className='clock' />
      {countdownText}
    </span>
  );
};

export default DisplayCountdown;
