/* eslint-disable prefer-template */
import React, { useState } from 'react';
import DatePicker from 'react-datepicker';
import { useMutation } from 'react-query';
import dayjs from 'dayjs';
import { Formik, Field, Form } from 'formik';
import { history, formatAmount, backwardAmountInput, cleanInput, logError, logBreadCrumb } from '+utils';
import { useFeedbackHandler, useSearchQuery } from '+hooks';
import APIRequest from '+services/api-services';
import useStore from '+store';
import { breadCrumbEvents } from '+utils/bugsnag-events';

const api = new APIRequest();

export default function CreatePaymentLink() {
  const { feedbackInit } = useFeedbackHandler();
  const searchQuery = useSearchQuery();
  const activeCurrency = searchQuery.value.currency || 'NGN';
  const [state, setState] = useState({
    loading: false,
    feedBackVisible: false,
    feedBackType: '',
    feedBackMessage: '',
    validated: 0,
    validating: false,
    type: '',
    message: '',
    expiryDate: null
  });
  const tomorrow = new Date();
  tomorrow.setDate(new Date().getDate() + 1);

  const MERCHANT_ENV = useStore(store => store.merchantEnv);
  const defaultMerchant = useStore(store => store.defaultMerchant);
  const availableCurrencies = useStore(store => store.availableCurrencies);

  const createLink = useMutation(data => api.createPaymentLink(data), {
    retry: false,
    onSuccess: res => {
      window.scrollTo(0, 0);
      feedbackInit({
        message: `${res.data.name} payment link successfully created`,
        type: 'success'
      });
      setState({ ...state, loading: false });
      setTimeout(() => {
        history.push(`/dashboard/payment-links`);
      }, 1500);
    },
    onError: e => {
      logError(e);
      setState({ ...state, loading: false });
      feedbackInit({
        message: 'There has been an error creating your payment link. Please try again.',
        type: 'danger'
      });
      setTimeout(() => {
        setState({ feedBackVisible: false });
      }, 2000);
    }
  });

  const checkPaymentLink = useMutation(slug => api.checkLink(slug), {
    retry: false,
    onSuccess: res => {
      if (res?.data?.is_available) {
        setState({ ...state, validating: false, validated: true, type: 'success', message: 'This link is available' });
      }
    },
    onError: error => {
      logError(error);
      setState({ ...state, validating: false, validated: false, type: 'danger', message: 'This link is not available' });
    }
  });

  const create = async values => {
    const { name, amount, recurring, description, slug, bear_cost: bearCost, currency } = values;
    const data = {
      name,
      amount,
      slug,
      description,
      merchant_bears_cost: bearCost === '1',
      currency,
      status: 'active',
      is_recurring: recurring,
      expiry: state.expiryDate ? dayjs(state.expiryDate).format('YYYY-MM-DD') : null
    };

    if (data.expiry === null) delete data.expiry;
    if (data.slug === null) delete data.slug;
    if (data.amount === 'NaN' || data.amount === null) delete data.amount;

    createLink.mutate(data);
  };

  const checkLink = async value => {
    if (value) {
      try {
        setState({ ...state, validating: true, type: '', message: 'Verifying your link' });
        await checkPaymentLink.mutateAsync(value);
      } catch (error) {
        logError(error);
        setState({ ...state, validating: false, validated: false, type: 'danger', message: 'Please try again' });
      }
      return true;
    }
    return false;
  };

  const showValidationStatus = () => {
    const { validating, type, message } = state;
    return (
      <div style={{ padding: '0.735rem 0' }}>
        {validating && (
          <span
            className="spinner-border spinner-border-sm"
            style={{ opacity: '0.6', marginRight: '0.5rem' }}
            role="status"
            aria-hidden="true"
          />
        )}
        <span className={`validation-text ${type}`}>{message}</span>
      </div>
    );
  };

  const formatSlug = value => {
    return `${value}`.replace(new RegExp(/([A-Z0-9_-])$/ + '|.', 'gi'), c => {
      return /([A-Z0-9_-])$/gi.test(c) ? c : '';
    });
  };

  const handleDateChange = date => {
    setState({ ...state, expiryDate: date });
  };

  return (
    <>
      <div className="row">
        <div className="col-sm-12">
          <button
            type="button"
            className="btn btn-link mb-3"
            onClick={() => {
              history.goBack();
              logBreadCrumb({ event: breadCrumbEvents.paymentLinks.goBackbuttonClicked });
            }}
          >
            <i className="os-icon os-icon-arrow-left7" />
            <span>Back to Payment Link Home</span>
          </button>
        </div>
      </div>

      <div className="row">
        <div className="col-lg-12">
          <div className="element-wrapper">
            <div className="element-box">
              <h5 className="form-header">New Payment Link</h5>
              <div className="form-desc">Complete the form below to create a new payment link to receive payments from your customers.</div>
              <div className="checkout-content-w">
                <Formik
                  initialValues={{
                    name: '',
                    description: '',
                    slug: null,
                    amount: null,
                    recurring: false,
                    interval: '',
                    bear_cost: '1',
                    currency: activeCurrency
                  }}
                  validate={values => {
                    const errors = {};
                    if (!values.name) errors.name = 'Your payment link requires a name';
                    if (!values.description) errors.description = 'Your payment link requires a description';
                    if (values.amount && values.amount < (defaultMerchant?.payin_limits?.[values.currency]?.min || 100)) {
                      errors.amount = `This amount should be a minimum of ${values.currency} ${
                        defaultMerchant?.payin_limits?.[values.currency]?.min
                      }.00`;
                    }
                    if (values.amount > (defaultMerchant?.payin_limits?.[values.currency]?.max || 5000000)) {
                      errors.amount = `You have exceeded the maximum amount of ${values.currency}  ${formatAmount(
                        defaultMerchant?.payin_limits?.[values.currency]?.max || 5000000
                      )} allowed for a payment link`;
                    }
                    return errors;
                  }}
                >
                  {({ values, errors, setFieldValue, touched }) => {
                    const disabled = () => {
                      if (!state.validated && values.slug) return true;
                      if (errors.name || errors.amount || errors.description) return true;
                      if (!values.name) return true;
                      return false;
                    };
                    return (
                      <>
                        <div className="col-lg-6">
                          <Form>
                            <fieldset>
                              <div className="form-group">
                                <Field name="checkout-name">
                                  {({ field }) => (
                                    <>
                                      <label htmlFor="checkout-name">Payment Link Name</label>
                                      <input
                                        {...field}
                                        name="name"
                                        component="input"
                                        className="form-control"
                                        placeholder="Give this payment link a name"
                                        value={values.name || ''}
                                        maxLength="50"
                                        onChange={e => setFieldValue('name', cleanInput(e.target.value))}
                                        required
                                      />
                                      {errors.name && touched.name && (
                                        <div className="input__errors">
                                          <p>{errors.name}</p>
                                        </div>
                                      )}
                                    </>
                                  )}
                                </Field>
                              </div>

                              <div className="form-group">
                                <Field name="checkout-amount">
                                  {({ field }) => (
                                    <>
                                      <label htmlFor="checkout-amount">
                                        Amount <span>(Optional)</span>
                                      </label>
                                      <div className="input-group">
                                        <div className="input-group-prepend">
                                          <select
                                            {...field}
                                            component="select"
                                            className="input-group-text pr-4 shadow-none"
                                            name="currency"
                                            value={values.currency || ''}
                                            onChange={e => {
                                              setFieldValue('currency', e.target.value);
                                              searchQuery.setQuery({ currency: e.target.value });
                                            }}
                                          >
                                            {(availableCurrencies || ['NGN']).map(currency => (
                                              <option key={currency} value={currency}>
                                                {currency}
                                              </option>
                                            ))}
                                          </select>
                                        </div>
                                        <input
                                          {...field}
                                          name="amount"
                                          component="input"
                                          className="form-control"
                                          value={values.amount || ''}
                                          placeholder={`Minimum of ${values.currency} ${
                                            defaultMerchant?.payin_limits?.[values.currency]?.min || 100
                                          }`}
                                          type="number"
                                          onChange={e => setFieldValue('amount', backwardAmountInput(e.target.value))}
                                        />
                                      </div>
                                      <span className="annotation">
                                        Leave empty to allow any payment above {values.currency}{' '}
                                        {defaultMerchant?.payin_limits?.[values.currency]?.min || 100}
                                      </span>
                                      {errors.amount && (
                                        <div className="input__errors">
                                          <p>{errors.amount}</p>
                                        </div>
                                      )}
                                    </>
                                  )}
                                </Field>
                              </div>

                              <div className="form-group">
                                <Field name="checkout-description">
                                  {({ field }) => (
                                    <>
                                      <label htmlFor="checkout-description">Description</label>
                                      <textarea
                                        {...field}
                                        name="description"
                                        component="input"
                                        className="form-control"
                                        placeholder="Maximum 150 characters"
                                        rows="3"
                                        maxLength="150"
                                        value={values.description || ''}
                                        onChange={e => setFieldValue('description', cleanInput(e.target.value))}
                                        required
                                      />
                                      {errors.description && touched.description && (
                                        <div className="input__errors">
                                          <p>{errors.description}</p>
                                        </div>
                                      )}
                                    </>
                                  )}
                                </Field>
                              </div>

                              <div className="form-group flex-column d-flex">
                                <Field>
                                  {() => (
                                    <>
                                      <label htmlFor="checkout-expiry">
                                        Expiry Date <span>(Optional)</span>
                                      </label>
                                      <DatePicker
                                        showPopperArrow={false}
                                        selected={state.expiryDate}
                                        dateFormat="dd/MM/yyyy"
                                        minDate={tomorrow}
                                        onChange={handleDateChange}
                                        placeholderText="Select an expiry date"
                                        calendarClassName="custom-datepicker"
                                      />
                                    </>
                                  )}
                                </Field>
                              </div>
                            </fieldset>
                            <fieldset>
                              <legend>
                                <span>Other settings</span>
                              </legend>
                              <div className="form-group">
                                <Field name="slug">
                                  {({ field }) => (
                                    <>
                                      <label htmlFor="slug">Custom Link (Optional)</label>
                                      <div className="input-group">
                                        <div className="input-group-prepend">
                                          <div className="input-group-text link-prepend">
                                            {MERCHANT_ENV === 'test'
                                              ? `${process.env.REACT_APP_CHECKOUT_TEST}`
                                              : `${process.env.REACT_APP_CHECKOUT_LIVE}`}
                                          </div>
                                        </div>
                                        <input
                                          {...field}
                                          name="slug"
                                          component="input"
                                          className="form-control"
                                          placeholder="your custom URL"
                                          maxLength="15"
                                          value={values.slug || ''}
                                          onBlur={() => checkLink(values.slug)}
                                          onKeyUp={() => setFieldValue('slug', formatSlug(values.slug))}
                                        />
                                      </div>
                                      {values.slug && showValidationStatus()}
                                    </>
                                  )}
                                </Field>
                              </div>

                              <div className="form-group">
                                <Field name="bear_cost">
                                  {({ field }) => (
                                    <>
                                      <label htmlFor="bear_cost">Who bears the fees on this payment link&apos;s transactions?</label>
                                      <select {...field} component="select" className="form-control" name="bear_cost">
                                        <option value="1">I will bear the fees</option>
                                        <option value="0">My customers will bear the fees</option>
                                      </select>
                                    </>
                                  )}
                                </Field>
                              </div>

                              <div className="info-detail-box">
                                If your customer makes a payment using this payment link, fees would be applied to the transaction according
                                to your fee description.
                              </div>

                              {/* <div className="form-check mb-3">
                                <Field name="show_logo">
                                  {({ field }) => (
                                    <label className="form-check-label">
                                      <input {...field} type="checkbox" className="form-check-input" checked={field.show_logo} />
                                      Show my business logo
                                    </label>
                                  )}
                                </Field>
                              </div> */}
                            </fieldset>

                            <div className="form-buttons-w button-row">
                              <button
                                className="btn btn-success"
                                type="button"
                                disabled={disabled()}
                                onClick={() => {
                                  setState({ ...state, loading: true });
                                  logBreadCrumb({ event: breadCrumbEvents.paymentLinks.createPaymentLinkClicked });
                                  create(values);
                                }}
                              >
                                {state.loading ? (
                                  <>
                                    <span
                                      className="spinner-border spinner-border-sm"
                                      style={{ marginRight: '0.5rem' }}
                                      role="status"
                                      aria-hidden="true"
                                    />
                                    <span>Creating...</span>
                                  </>
                                ) : (
                                  <span>Create Payment Link</span>
                                )}
                              </button>
                              <button
                                type="button"
                                className="btn btn-grey"
                                style={{ marginLeft: 5, marginRight: 5 }}
                                onClick={() => history.goBack()}
                              >
                                <span>Cancel</span>
                              </button>
                            </div>
                          </Form>
                        </div>

                        <div className="preview-box col-lg-6">
                          <p className="disclaimer-text">This is a preview of your payment link</p>
                          <div className="modal-mockup">
                            <div className="secured__banner">
                              <img src="https://korablobstorage.blob.core.windows.net/modal-bucket/Safety.svg" alt="" />
                              <p>
                                Secured by <span>Kora</span>
                              </p>
                            </div>
                            {/* {values.show_logo && (
                              <div className="merchant-logo">
                                <img src={defaultMerchant.avatar || defaultMerchantLogo} alt="logo" />
                              </div>
                            )} */}

                            <div className="checkout-header">
                              <h1 className="checkout-name">{values.name ? values.name : 'Payment link name'}</h1>
                              <p className="merchant-name">To {defaultMerchant?.name}</p>
                              <p className="description">{values.description ? values.description : 'Payment link description'}</p>
                            </div>

                            <div className="kpy-col-paymentLink-container">
                              {values.amount >= (defaultMerchant?.payin_limits?.[values.currency]?.min || 100) && (
                                <div className="amount-holder">
                                  Pay {values.currency} {formatAmount(values.amount)}
                                </div>
                              )}
                              <div className="checkout-form-container">
                                <div className="kpy-col-input-field">
                                  <label htmlFor="customer-name" className="kpy-col-label">
                                    Full Name
                                  </label>
                                  <input
                                    className="kpy-col-input pay-link"
                                    id="customer-name"
                                    name="customer-name"
                                    placeholder="John Doe"
                                    readOnly
                                  />
                                </div>

                                <div className="kpy-col-input-field">
                                  <label htmlFor="customer-email" className="kpy-col-label">
                                    Email
                                  </label>
                                  <input
                                    kpy-email-input="true"
                                    className="kpy-col-input pay-link"
                                    id="customer-email"
                                    name="customer-email"
                                    placeholder="email@example.com"
                                    readOnly
                                  />
                                </div>

                                {values.amount > defaultMerchant?.payin_limits?.[values.currency]?.min || (
                                  <div className="kpy-col-input-field ">
                                    <label htmlFor="link-amount" className="kpy-col-label">
                                      Amount ({values.currency})
                                    </label>
                                    <input
                                      className="kpy-col-input"
                                      name="customer-phone"
                                      type="text"
                                      placeholder={`Minimum ${values.currency} ${
                                        defaultMerchant?.payin_limits?.[values.currency]?.min || 100
                                      }`}
                                      readOnly
                                    />
                                  </div>
                                )}

                                <button className="btn-checkout" type="button">
                                  Proceed
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </>
                    );
                  }}
                </Formik>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
