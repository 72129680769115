import { useEffect, useState } from 'react';
import { Link, Route, useLocation } from 'react-router-dom';

import useStore from '+store';

export default function useRolesSettings(matchRoutesToComponents) {
  const location = useLocation();
  const { authDetails } = useStore();
  const userToken = authDetails?.access_token;

  const [permissions, setPermissions] = useState([]);
  const [permissionsAccess, setPermissionsAccess] = useState({});
  const displayPermissions = value => {
    let routeLabel;
    switch (value) {
      case 'team':
        routeLabel = 'Team';
        break;
      case 'api_configuration':
        routeLabel = 'API Configuration';
        break;
      case 'security':
        routeLabel = 'Security';
        break;
      case 'compliance':
        routeLabel = 'Compliance';
        break;
      case 'settlement_setting':
        routeLabel = 'Settlements';
        break;
      case 'bank_transfer_setting':
        routeLabel = 'Payment Preferences';
        break;
      case 'checkout_customization':
        routeLabel = 'Checkout Appearance';
        break;
      case 'limits':
        routeLabel = 'Limits';
        break;
      case 'ip_address_configuration': // This is make shift. should be updated once the email config permission has been added
        routeLabel = 'Notifications';
        break;
      default:
        routeLabel = value;
        break;
    }
    return routeLabel;
  };

  useEffect(() => {
    if (userToken) {
      const jwt = JSON.parse(atob(userToken.split('.')[1]));
      const updatedPermissions = { ...(jwt?.permissions?.permissions || {}), limits: 'manage' };

      setPermissionsAccess(updatedPermissions);
      setPermissions(Object.keys(updatedPermissions));
    }
  }, [userToken]);

  const createRoutes = () => {
    const routes = {
      base: [],
      settings: []
    };

    const router = [];

    const checkIfNestedRoute = route => {
      const [first, ...rest] = route.split('/');

      if (rest.length) {
        return first;
      }
      return 'base';
    };

    permissions.forEach(permission => {
      if (!matchRoutesToComponents[permission]) {
        return;
      }

      if (checkIfNestedRoute(matchRoutesToComponents[permission]?.[0]) === 'settings') {
        router.push(
          <Route
            path={`/dashboard/${matchRoutesToComponents[permission]?.[0]}`}
            key={`/dashboard/${matchRoutesToComponents[permission]?.[0]}`}
            component={matchRoutesToComponents[permission]?.[2]}
          />
        );
      }
    });

    permissions.forEach(permission => {
      const routeType = 'settings';
      if (matchRoutesToComponents[permission] !== undefined) {
        if (
          permissionsAccess[permission] === 'view' ||
          permissionsAccess[permission] === 'manage' ||
          permissionsAccess[permission] === 'export'
        ) {
          routes[routeType].push(
            <li className="nav-item" key={`/dashboard/${matchRoutesToComponents[permission]?.[0]}`}>
              <Link
                aria-expanded="true"
                className={location.pathname.includes(matchRoutesToComponents[permission]?.[0]) ? 'nav-link active' : 'nav-link'}
                data-toggle="tab"
                to={{ pathname: `/dashboard/${matchRoutesToComponents[permission]?.[0]}`, state: permissionsAccess[permission] }}
              >
                <span className="tab-label">{displayPermissions(permission)}</span>
              </Link>
            </li>
          );
        }
      }
    });
    return [routes, router];
  };

  return createRoutes();
}
