import APIRequest from '+services/api-services';
import { getExistingFiltersFromQuery } from '+containers/Dashboard/Shared/FilterModal';
import { issuedCardChargebacksFilterField, singleCardTxFilterFields } from '../../data';

const publicApi = new APIRequest(process.env.REACT_APP_PUBLIC_MERCHANT_MIDDLEWARE_API_BASE);

export const exportActionOptions = {
  transactions: ({ cardId: ref, fieldsToExport, format }) =>
    publicApi.fetchIssuedCardTransactions(ref, {
      toExport: true,
      exportFormat: format,
      exportFields: fieldsToExport,
      ...getExistingFiltersFromQuery(singleCardTxFilterFields)
    }),
  events: ({ cardId: ref, fieldsToExport, format }) =>
    publicApi.fetchCardEvents(ref, {
      toExport: true,
      exportFormat: format,
      exportFields: fieldsToExport
    }),
  chargebacks: ({ cardId, fieldsToExport, format }) =>
    publicApi.fetchAllChargebacksForIssuedCard({
      cardId,
      toExport: true,
      exportFormat: format,
      exportFields: fieldsToExport,
      ...getExistingFiltersFromQuery(issuedCardChargebacksFilterField)
    })
};
