import React, { useEffect, useRef, useState } from 'react';
import './Slider.scss';

interface SliderT {
  children: React.ReactNode;
}
function Slider({ children }: SliderT) {
  const [isOverflowing, setIsOverflowing] = useState(false);
  const sliderContentRef = useRef<HTMLDivElement>(null);
  const sliderContainerRef = useRef<HTMLDivElement>(null);

  function checkOverflow() {
    if (sliderContainerRef.current && sliderContentRef.current) {
      setIsOverflowing(sliderContentRef.current.scrollWidth > sliderContainerRef.current.clientWidth);
    }
  }

  useEffect(() => {
    window.addEventListener('resize', checkOverflow);

    checkOverflow();

    return () => {
      window.removeEventListener('resize', checkOverflow);
    };
  }, []);
  return (
    <div className="slider-container" ref={sliderContainerRef}>
      <div className={`slider-content ${isOverflowing ? 'overflowing' : ''}`} ref={sliderContentRef}>
        {children}
      </div>
    </div>
  );
}

export default Slider;
