import { StateCreator } from 'zustand';
import { ICreateTipsAndGuideSlice } from '+types/tips-and-guide';

const createTipsAndGuideSlice: StateCreator<ICreateTipsAndGuideSlice> = set => ({
  list: {},
  ip: {},
  audit: {},
  team: {},
  setList: value => set(state => ({ ...state, list: { ...state.list, [value.email]: value.value } })),
  setIp: value => set(state => ({ ...state, ip: { ...state.ip, [value.email]: { ...(state.ip?.[value.email] || {}), ...value.value } } })),
  setTeam: value =>
    set(state => ({ ...state, team: { ...state.team, [value.email]: { ...(state.team?.[value.email] || {}), ...value.value } } })),
  setAudit: value =>
    set(state => ({ ...state, audit: { ...state.audit, [value.email]: { ...(state.audit?.[value.email] || {}), ...value.value } } }))
});

export default createTipsAndGuideSlice;
