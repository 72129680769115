import { useQuery } from 'react-query';
import useStore from '+store';
import { useSearchQuery } from '+hooks';
import useFeedbackHandler from '+hooks/feedbackHandler';
import APIRequest from '+services/api-services';
import { switchStatus, capitalize, history, getDateAndTime, filteredOutObjectProperty, logError } from '+utils';
import Copyable from '+containers/Dashboard/Shared/Copyable';
import Table from '+containers/Dashboard/Shared/Table';
import VirtualAccountsFilter from '../Shared/VirtualAccountsFilter';

const api = new APIRequest(process.env.REACT_APP_PUBLIC_MERCHANT_MIDDLEWARE_API_BASE);
export default function AccountNumbers({ reference, name }: { reference: string; name: string }) {
  const searchQuery = useSearchQuery();
  const paginationPage = searchQuery.value.page || '1';
  const limit = searchQuery.value.limit || '10';
  const sortingParams = {
    ...filteredOutObjectProperty(searchQuery.value, ['page', 'tab', 'limit', 'kycinfoTab', 'eventTab', 'accountHolderTab'])
  };
  const { feedbackInit } = useFeedbackHandler();
  const MERCHANT_ENV = useStore(store => store.merchantEnv);

  const { data, isFetching, refetch } = useQuery(
    ['VIRTUAL_ACCOUNTS', paginationPage, limit, reference, MERCHANT_ENV, sortingParams],
    () => api.getVirtualBankAccounts(paginationPage, limit, { accountHolderReference: reference, ...sortingParams }),
    {
      keepPreviousData: true,
      onError: error => {
        logError(error);
        feedbackInit({
          message: 'There has been an issue while fetching your virtual bank accounts',
          type: 'danger',
          action: {
            action: () => refetch(),
            name: 'Try again'
          }
        });
      }
    }
  );
  const accounts = data?.data || [];
  const paging = data?.paging;

  const AccountNumber = () => {
    return (
      <div>
        {accounts?.map(each => {
          return (
            <div
              className="div-table --fvba-account-numbers --row"
              key={each?.korapay_reference}
              style={{ background: each?.status === 'suspended' ? '#FFFAF0' : '' }}
            >
              <div>
                <span className="body-row-header">Status:</span>
                <span className={`status-pill smaller ${switchStatus(each?.status)}`} />
                <span>{capitalize(each?.status)}</span>
              </div>
              <div
                onClick={() => history.push(`/dashboard/virtual-accounts/details/${each?.korapay_reference}`)}
                onKeyDown={() => history.push(`/dashboard/virtual-accounts/details/${each?.korapay_reference}`)}
                role="button"
                tabIndex={0}
              >
                <span className="body-row-header">Account Number:</span>
                <span style={{ color: '#A9AFBC' }}>
                  <span style={{ fontWeight: 600, color: '#414F5F' }}>{each?.account_number}</span>{' '}
                </span>
              </div>
              <div>
                <span className="body-row-header">Bank:</span>
                <span>{each?.bank_name || 'Not Available'}</span>
              </div>
              <div>
                <span className="body-row-header">Currency</span>
                <span>{each?.currency}</span>
              </div>
              <div>
                <span className="body-row-header">Tier:</span>
                <span>Tier {each?.tier}</span>
              </div>
              <div>
                <span className="body-row-header">Date Created:</span>
                <span>
                  <span>{`${getDateAndTime(each.created_at)}`}</span>
                </span>
              </div>
            </div>
          );
        })}
      </div>
    );
  };
  return (
    <div className="content-i">
      <div className="row">
        <div className="col-sm-12">
          <div className="element-wrapper">
            <div
              className="os-tabs-controls os-tabs-complex settlement-tabs"
              style={{ alignItems: 'center', borderBottom: '1px solid #dee2e6', marginRight: '0px' }}
            />
            <VirtualAccountsFilter
              type="account_number"
              Tab={`Account Number${paging?.total_items > 1 ? 's' : ''}`}
              totalCount={paging?.total_items}
              filterText={`assigned to ${name}`}
            />
            <Table
              tableClassName="--fvba-account-numbers"
              headings={[
                {
                  value: 'Status'
                },
                {
                  value: 'Account Number'
                },
                {
                  value: 'Bank'
                },
                {
                  value: 'Currency'
                },
                {
                  value: 'Tier'
                },
                {
                  value: 'Date Created'
                }
              ]}
              hasPagination
              loading={isFetching}
              current={paging?.current}
              totalItems={paging?.total_items}
              limitAction={limit => searchQuery.setQuery({ limit })}
              pageSize={paging?.page_size}
              actionFn={p => searchQuery.setQuery({ page: p })}
              annotation="accounts"
              EmptyStateHeading="No virtual bank accounts yet"
              EmptyStateMessage="You currently have not created any virtual bank accounts"
              tableWrapperClassName="vba-container sub"
            >
              {AccountNumber()}
            </Table>
          </div>
        </div>
      </div>
    </div>
  );
}
