import { useQuery } from 'react-query';
import APIRequest from '+services/api-services';

const api = new APIRequest();

export default function useCurrencies() {
  return useQuery('CURRENCIES', () => api.fetchCurrencies(), {
    enabled: true
  });
}
