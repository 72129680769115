import { useState } from 'react';
import { useQuery } from 'react-query';

import Table from '+containers/Dashboard/Shared/Table';
import { useFeedbackHandler, useSearchQuery } from '+hooks';
import { formatAmount, logError } from '+utils';
import useStore from '+store';

import Filter from '../components/Filter/Filter';
import Summary from '../components/Summary';
import { cardTransactionsTableProps, computeTableTitle, exportActionOptions, publicApi, getTransactionsFilterProps } from '../constants';
import { useFetchIssuanceTables, useFetchIssuingExport } from '../hooks';
import { AllTransactionsResponseType, IError } from '../types';
import { getIssuingPermissions } from '../utils';
import CardTransactionsRow from './components/CardTransactionsRow';

const CardTransactions = () => {
  const { merchantEnv: MERCHANT_ENV } = useStore.getState();
  const [recordIsFiltered, setRecordIsFiltered] = useState(false);
  const { value: searchQueryValue, setQuery } = useSearchQuery();
  const { feedbackInit } = useFeedbackHandler();
  const { canViewCardTrxns } = getIssuingPermissions();
  const limit = searchQueryValue?.limit || '10';
  const page = searchQueryValue?.page || '1';
  const tab = searchQueryValue?.tab as 'card_transactions';
  const currency = searchQueryValue?.currency || 'USD';
  const exportAction = useFetchIssuingExport({ resourceType: `All-${tab}`, exportFn: exportActionOptions[tab] });
  const filterProps = getTransactionsFilterProps();

  const { tableData, isFetching: isFetchingTableData, refetch: refetchTableData } = useFetchIssuanceTables({
    page,
    limit,
    tab,
    queryValue: searchQueryValue
  });

  const { data: cardTrxnStats, refetch: refetchCardTrxnStats } = useQuery(
    ['ISSUING_TRXN_STATISTICS', MERCHANT_ENV],
    () => publicApi.fetchIssuanceTxStatistics(),
    {
      onError: (e: IError) => {
        logError(e);
        const message = e.response?.data?.message;
        feedbackInit({
          message,
          type: 'danger',
          action: {
            action: refetchCardTrxnStats,
            name: 'Try again'
          }
        });
      }
    }
  );

  const paging = tableData?.data?.paging;

  return (
    <div>
      <div className="d-flex w-100 flex-wrap flex-sm-nowrap">
        <div className="flex-grow-1 info-summary-container">
          <Summary
            label={`Total Card Spend (${currency})`}
            value={cardTrxnStats?.total_transactions_value}
            valueFormatter={formatAmount}
            description="Value spent on issued cards"
          />
          <Summary label="Transaction Count" value={cardTrxnStats?.total_transactions} description="Transaction via issued cards" />
        </div>
      </div>

      <Filter
        totalItems={Number(paging?.total_items)}
        title={computeTableTitle({
          filtered: recordIsFiltered,
          activeTab: tab as string,
          pageItemCount: Number(paging?.total_items)
        })}
        actions={filterProps.actions}
        filterModalHeading={filterProps.filterModalHeading}
        filterModalDescription={filterProps.filterModalDescription}
        filterFields={filterProps.filterFields}
        exportType={filterProps.exportType}
        quickFilterType={tab}
        exportHeading={filterProps.exportHeading}
        exportDescription={filterProps.exportHeading}
        onChangeIsFiltered={setRecordIsFiltered}
        isFiltered={recordIsFiltered}
        exportAction={exportAction}
      />

      <section className="transaction_table_comp table-container">
        <Table
          tableClassName={`--history-table ${cardTransactionsTableProps.tableClassName}`}
          headings={cardTransactionsTableProps.headings}
          borderedTable
          hasPagination
          loading={isFetchingTableData}
          current={paging?.current}
          limitAction={value => setQuery({ limit: String(value) })}
          pageSize={paging?.page_size}
          actionFn={value => setQuery({ page: String(value) })}
          totalItems={paging?.total_items || 0}
          emptyStateHeading={cardTransactionsTableProps.emptyStateHeading}
          emptyStateMessage={
            <>
              <span>{cardTransactionsTableProps.emptyStateMessage}</span>

              {canViewCardTrxns && (
                <button type="button" className="refetch-button" onClick={() => refetchTableData()}>
                  <i className="os-icon os-icon-rotate-ccw" style={{ marginRight: '5px' }} />
                  Refresh
                </button>
              )}
            </>
          }
        >
          <CardTransactionsRow rowData={tableData?.data?.data as Array<AllTransactionsResponseType>} />
        </Table>
      </section>
    </div>
  );
};
export default CardTransactions;
