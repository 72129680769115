import React from 'react';
import { Document, Font, Page, StyleSheet, Text, View } from '@react-pdf/renderer';

import AvertaBold from '+assets/fonts/Averta/AvertaPE-Extrabold.otf';
import AvertaNormal from '+assets/fonts/Averta/AvertaPE-Regular.otf';
import AvertaSemiBold from '+assets/fonts/Averta/AvertaPE-Semibold.otf';
import { IBillingHistoryDetails } from '+types';
import { capitalizeRemovedash, getDate, getTime } from '+utils';

Font.register({
  family: 'Averta PE',
  fonts: [{ src: AvertaNormal }, { src: AvertaBold, fontWeight: 700 }, { src: AvertaSemiBold, fontWeight: 500 }]
});

const styles = StyleSheet.create({
  page: {
    fontSize: '16px',
    fontFamily: 'Averta PE'
  },
  header: {
    marginTop: 30,
    padding: 10,
    textAlign: 'center',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  amount: {
    fontWeight: 700,
    fontSize: 30,
    textAlign: 'center',
    marginTop: 4,
    marginBottom: 4,
    color: '#3E4B5B',
    letterSpacing: '-0.4px'
  },
  description: {
    fontWeight: 400,
    fontSize: '14px',
    color: '#3E4B5B'
  },
  section: {
    margin: 20,
    padding: 20,
    backgroundColor: '#F1F6FA',
    borderRadius: '8px'
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: 10
  },
  label: {
    fontWeight: 400,
    fontSize: 14,
    color: '#011454'
  },
  value: {
    fontWeight: 500,
    fontSize: 14,
    color: '#414F5F'
  }
});

const ReceiptTemplate = ({ receiptData }: { receiptData: IBillingHistoryDetails }) => {
  return (
    <Document>
      <Page size="A5" style={styles.page}>
        <View style={styles.header}>
          <Text style={styles.amount}>
            {receiptData?.currency}
            {receiptData.amount}
          </Text>
          <Text style={styles.description}>
            Payment has been received for <Text style={{ fontWeight: 600 }}>{receiptData?.reference}.</Text>
            View additional details below.
          </Text>
        </View>
        <View style={styles.section}>
          <View style={styles.row}>
            <Text style={styles.label}>Merchant</Text>
            <Text style={styles.value}>{receiptData?.merchant_name}</Text>
          </View>
          <View style={styles.row}>
            <Text style={styles.label}>Amount</Text>
            <Text style={styles.value}>
              {receiptData?.currency}
              {receiptData?.amount}
            </Text>
          </View>
          <View style={styles.row}>
            <Text style={styles.label}>Invoice Reference</Text>
            <Text style={styles.value}>{receiptData?.reference}</Text>
          </View>
          <View style={styles.row}>
            <Text style={styles.label}>Status</Text>
            <Text style={styles.value}>{capitalizeRemovedash(receiptData?.status)}</Text>
          </View>
          <View style={styles.row}>
            <Text style={styles.label}>Fee type</Text>
            <Text style={styles.value}>{capitalizeRemovedash(receiptData?.type)}</Text>
          </View>
          <View style={styles.row}>
            <Text style={styles.label}>Date,Time</Text>
            <Text style={styles.value}>
              {getDate(receiptData?.created_at)}, {getTime(receiptData?.created_at)}
            </Text>
          </View>
        </View>
      </Page>
    </Document>
  );
};

export default ReceiptTemplate;
