import {
  balanceHistoryFilterFields,
  billingHistoryFields,
  cardsFilterFields,
  issuedCardChargebacksFilterField,
  transactionsFilterFields
} from '../data';
import { BalanceSubtabKeyType, IFilter } from '../types';
import { getIssuingPermissions } from '../utils';

interface ComputeTableTitle {
  filtered: boolean;
  activeTab: string;
  pageItemCount: string | number;
  singularTitle?: string;
  pluralTitle?: string;
}

type FilterSubset =
  | 'title'
  | 'filterModalHeading'
  | 'filterModalDescription'
  | 'filterFields'
  | 'exportType'
  | 'quickFilterType'
  | 'exportHeading'
  | 'exportDescription'
  | 'actions';

type IFilterSubset = Pick<IFilter, FilterSubset>;

export const computeTableTitle = ({
  filtered,
  activeTab,
  pageItemCount,
  singularTitle = 'Transaction',
  pluralTitle = 'Transactions'
}: ComputeTableTitle) => {
  const isBalHistoryTab = activeTab === 'issuing_balance_history';
  const isBillingHistoryTab = activeTab === 'billing_history';
  const title = pageItemCount !== 1 ? pluralTitle : singularTitle;
  const filteredSuffix = filtered ? '(Filtered)' : '';

  if (isBalHistoryTab) {
    return `${filtered ? 'Filtered ' : 'All '}${title} (${pageItemCount || 0})`;
  }

  if (isBillingHistoryTab) {
    return `${title} ${filteredSuffix} (${pageItemCount || 0})`;
  }

  return `${pageItemCount || 0} ${title} ${filteredSuffix}`;
};

export const getBalanceSubtabsFilterProps = (): Record<BalanceSubtabKeyType, IFilterSubset> => {
  const { canExportWalletHistory } = getIssuingPermissions();
  return {
    issuing_balance_history: {
      filterModalHeading: 'Filter Issuing Balance History',
      filterModalDescription: 'Use any of the following advanced filter controls to find specific issuing history transactions.',
      filterFields: balanceHistoryFilterFields,
      exportType: 'issuing-balance-history',
      exportHeading: 'Export Issuing Balance History',
      exportDescription: 'Choose how you would like to export these transactions.',
      actions: [...(canExportWalletHistory ? ['export' as 'export'] : []), 'filter']
    },
    funding_deposits: {
      filterModalHeading: 'Filter Funding Deposits',
      filterModalDescription: 'Use any of the following advanced filter controls to find specific deposit history.',
      filterFields: balanceHistoryFilterFields,
      exportType: 'funding-deposits',
      exportHeading: 'Export Funding Deposits',
      exportDescription: 'Choose how you would like to export these transactions.',
      actions: ['filter']
    }
  };
};

export const getCustomerCardsFilterProps = (): IFilterSubset => {
  const { canExportCardList } = getIssuingPermissions();
  return {
    title: 'Customer Card',
    filterModalHeading: 'Filter Virtual Cards',
    filterModalDescription: 'Use any of the following advanced filter controls to find specific merchant virtual cards.',
    filterFields: cardsFilterFields,
    quickFilterType: 'customer-cards',
    exportType: 'virtual-cards',
    exportHeading: 'Export Virtual Cards',
    exportDescription: 'Choose how you would like to export these virtual cards.',
    actions: [...(canExportCardList ? ['export' as 'export'] : []), 'filter']
  };
};

export const getReservedCardsFilterProps = (): IFilterSubset => {
  const { canExportCardList } = getIssuingPermissions();
  return {
    title: 'Reserved Card',
    quickFilterType: 'reserved-cards',
    exportType: 'virtual-cards',
    exportHeading: 'Export Reserved Virtual Cards',
    exportDescription: 'Choose how you would like to export these virtual cards.',
    actions: [...(canExportCardList ? ['export' as 'export'] : [])]
  };
};

export const getTransactionsFilterProps = (): IFilterSubset => {
  const { canExportCardTrxns } = getIssuingPermissions();
  return {
    filterModalHeading: 'Filter Card Transactions',
    filterModalDescription: 'Use any of the following advanced filter controls to find more card transactions.',
    filterFields: transactionsFilterFields,
    quickFilterType: 'card-transactions',
    exportType: 'card-transactions',
    exportHeading: 'Export Card Transactions',
    exportDescription: 'Choose how you would like to export these transactions.',
    actions: [...(canExportCardTrxns ? ['export' as 'export'] : []), 'filter']
  };
};

export const getChargebacksFilterProps = (): IFilterSubset => {
  const { canExportChargebacks } = getIssuingPermissions();
  return {
    filterModalHeading: 'Filter Chargebacks',
    filterModalDescription: 'Use any of the following advanced filter controls to find more chargeback transactions.',
    filterFields: issuedCardChargebacksFilterField,
    quickFilterType: 'issued-card-chargebacks',
    exportType: 'issued-card-chargebacks',
    exportHeading: 'Export Chargebacks',
    exportDescription: 'Choose how you would like to export these transactions.',
    actions: [...(canExportChargebacks ? ['export' as 'export'] : []), 'filter']
  };
};

export const getBillingHistoryFilterProps = (): IFilterSubset => {
  return {
    filterModalHeading: 'Filter Billings History',
    filterModalDescription: 'Use any of the following advanced filter controls to find more billing history transactions.',
    filterFields: billingHistoryFields,
    quickFilterType: 'billings-history',
    exportType: 'billings-history',
    exportHeading: 'Export Billings History',
    exportDescription: 'Choose how you would like to export these transactions.',
    actions: ['export']
  };
};
