import React, { useState } from 'react';
import { useQuery } from 'react-query';

import useFeedbackHandler from '+hooks/feedbackHandler';
import APIRequest from '+services/api-services';
import { durationMonth, durationToday, durationWeek, getDate, getTime } from '+utils/formats';

import Table from '../Shared/Table';
import TabSwitch from '../Shared/TabSwitch';

import './index.scss';

const api = new APIRequest();

export default function AuditLogs() {
  const { feedbackInit } = useFeedbackHandler();
  const [state, setState] = useState({
    paginationPage: 1,
    tabs: { last_24_hours: durationToday(), last_7_days: durationWeek(), last_30_days: durationMonth(1), all_time: [null, null] },
    activeTab: 'all_time',
    limit: 25
  });

  const { data, isFetching, refetch } = useQuery(
    ['AUDIT_LOGS', state.paginationPage, state.limit, ...state.tabs[state.activeTab]],
    () => api.fetchUserLogs(state.paginationPage, state.limit, ...state.tabs[state.activeTab]),
    {
      keepPreviousData: true,
      onError: () => {
        feedbackInit({
          message: `There has been an error getting this admin activity log`,
          type: 'danger',
          action: {
            action: () => refetch(),
            name: 'Try again'
          }
        });
      }
    }
  );

  const paginate = async currentPage => {
    setState({ ...state, paginationPage: currentPage });
  };

  const paging = data?.paging;

  const renderEvents = () => {
    return (
      <>
        <table className="table table-lightborder audit-log-table">
          <tbody>
            {data?.data.map(event => (
              <tr key={Math.floor(1000 + Math.random() * 9000)}>
                <td style={{ width: '20%' }}>
                  {getDate(event.created_at)}&emsp;<span style={{ color: '#A9AFBC' }}>{getTime(event.created_at)}</span>
                </td>
                <td style={{ width: '60%' }}>
                  <span style={{ fontWeight: 500 }}>{event.event}</span> : {event.description}
                  {event.event === 'Initiated a transfer' ? <> - {JSON.parse(event?.other_info || '{}')?.payout_reference} </> : ''}
                </td>
                <td className="text-right lighter">{event.event_id}</td>
              </tr>
            ))}
          </tbody>
        </table>
        {data?.data.map(event => (
          <div key={Math.floor(1000 + Math.random() * 9000)} className="div-table --body audit-log-table-mobile">
            <div className="--txn-status">
              <span className="body-row-header">Timestamp:</span>
              <span>{`${getDate(event.createdAt)}, ${getTime(event.createdAt)}`}</span>
            </div>
            <div>
              <span className="body-row-header">Event:</span>
              <span>
                <span className="font-weight-bold">{event.event}</span> : {event.description}
                {event.event === 'Initiated a transfer' ? <> - {JSON.parse(event?.other_info || '{}')?.payout_reference} </> : ''}
              </span>
            </div>
            <div>
              <span className="body-row-header">Event ID:</span>
              <span>{event.event_id}</span>
            </div>
          </div>
        ))}
      </>
    );
  };

  return (
    <div className="element-box audit__element">
      <div className="os-tabs-w mb-3">
        <div className="os-tabs-controls">
          <ul className="nav">
            <li className="nav-item">
              <h4 className="element-header audit__header d-flex align-items-center">Events</h4>
            </li>
          </ul>
          <ul className="nav nav-pills smaller d-md-flex">
            {Object.keys(state.tabs).length > 0 && (
              <TabSwitch
                options={Object.keys(state.tabs || {})}
                activeTab={state.activeTab}
                setTab={value => setState({ ...state, activeTab: value })}
                className="ml-3"
                id="audit__logs"
              />
            )}
          </ul>
        </div>
        <div className="tab-content">
          <div className="tab-pane active" id="tab_activity">
            <Table
              hasPagination
              header={false}
              headings={[
                {
                  value: 'Timestamp',
                  className: 'lighter',
                  style: { width: '20%' }
                },
                {
                  value: 'Event',
                  className: 'lighter',
                  style: { width: '60%' }
                },
                {
                  value: 'Event ID',
                  className: 'text-right lighter',
                  style: { width: '20%' }
                }
              ]}
              totalItems={paging?.total_items || 0}
              pageSize={paging?.page_size || 0}
              loading={isFetching}
              current={state.paginationPage}
              actionFn={paginate}
              annotation="actions"
              emptyStateHeading="There are no audit logs yet"
              emptyStateMessage="You do not have any audit logs at the moment."
              children={renderEvents()}
              tableWrapperClassName="table-responsive"
            />
          </div>
        </div>
      </div>
    </div>
  );
}
