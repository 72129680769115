import { useQuery } from 'react-query';
import APIRequest from '+services/api-services';
import Modal from '+containers/Dashboard/Shared/Modal';
import ViewAndDownload from '+containers/Dashboard/Shared/ViewandDownload';
import { capitalize, capitalizeRemovedash, getDate, switchStatus } from '+utils';
import DocumentImage from '+assets/img/auth/document.svg';
import InfoIcon from '+assets/img/dashboard/exclamation.svg';
import Claret from '+assets/img/dashboard/dropdown.svg';
import Copyable from '+containers/Dashboard/Shared/Copyable';
import { IModalProps } from '+types/virtual-account-types';
import UpgradeRequestDetails from '../Shared/UpgradeRequestDetails';
import '../index.scss';

const api = new APIRequest(process.env.REACT_APP_PUBLIC_MERCHANT_MIDDLEWARE_API_BASE);

const UpgradeRequestModal = ({ close, reference }: IModalProps) => {
  const { data } = useQuery([`${reference}_UPGRADE_REQUEST`], () => api.singleVirtualAccountUpgradeRequest(reference));

  const requestDetails = [
    {
      'Account Name': data?.virtual_bank_account?.account_name || 'Not Available',
      'Account Number': <Copyable text={data?.virtual_bank_account?.account_number || 'Not Available'} showOnHover />,
      Bank: `${capitalizeRemovedash(data?.virtual_bank_account?.bank || 'Not Available')}`,
      'Account Holder': <span style={{ color: '#2376f3' }}>{data?.account_holder?.name || 'Not Available'}</span>,
      merchant: data?.merchant,
      'Upgrading from (Current tier)': data?.current_tier || 'Not Available',
      'Upgrading from (Requested tier)': data?.requested_tier || 'Not Available',
      'Date Requested': data?.created_at || 'Not Available',
      'Previous Request Declines': 0 || 'Not Available',
      'Reason For Upgrade': data?.upgrade_use_case || 'Not Available'
    }
  ];

  const accountHolderDetails = [
    {
      'Account Holder Name': <span style={{ color: '#2376f3' }}>{data?.account_holder?.name || 'Not Available'}</span>,
      'Account Holder Reference': <Copyable text={data?.account_holder?.reference} showOnHover />,
      Status: (
        <>
          <span className={`status-pill smaller ${switchStatus(data?.account_holder?.status)}`} />
          {capitalize(data?.account_holder?.status)}
        </>
      ),
      Type: data?.account_holder?.type,
      Email: data?.account_holder?.email,
      Phone: data?.account_holder?.phone,
      'Date Of Birth': getDate(data?.account_holder?.date_of_birth),
      Address: data?.account_holder?.address?.address,
      City: data?.account_holder?.address?.city,
      Country: data?.account_holder?.address?.country
    }
  ];

  return (
    <Modal
      close={close}
      heading="Account Upgrade Request"
      description="This is a request to upgrade an account number. See a summary of the request and the relevant information provided for the upgrade"
      content={
        <main>
          {data?.status !== 'pending' && (
            <div className={`upgrade-feedback ${data?.status}`} role="alert">
              This Request has been {data?.status}
            </div>
          )}
          <details className="upgrade-request-wrapper">
            <summary className="upgrade-request-summary">
              <div className="upgrade-request-header">
                <h5>Request Details</h5>
                <img src={Claret} alt="drop down icon" />
              </div>
              <p>This is a summary of the request below with some information submitted by requesting merchant.</p>
            </summary>
            <div className="upgrade-request-documents">
              <UpgradeRequestDetails data={requestDetails} />
            </div>
          </details>
          <details className="upgrade-request-wrapper">
            <summary className="upgrade-request-summary">
              <div className="upgrade-request-header">
                <h5>Supporting Documents</h5>
                <img src={Claret} alt="drop down icon" />
              </div>
              <p>These are the documents submitted for this upgrade.</p>
              <div className="upgrade-request-warning">
                <img style={{ margin: '0px 5px 0px 0px' }} src={InfoIcon} className="modal-text__image" alt="info icon" />
                <section className="upgrade-request-banner">Please note that supported documents must match use case for upgrade.</section>
              </div>
            </summary>
            <div className="related-document-content upgrade-request-documents">
              <div>
                <img src={DocumentImage} alt="document" />
                <span className="--document-name">Document</span>
              </div>
              <div style={{ cursor: 'pointer' }}>
                <ViewAndDownload
                  url={data?.upgrade_use_case_document?.url}
                  info={data?.upgrade_use_case_document?.url}
                  type="jpeg"
                  filename={data?.upgrade_use_case_document?.url}
                  disableView={false}
                  disableDownload
                  showDownloadButton={false}
                />
              </div>
            </div>
            <div />
          </details>
          <details className="upgrade-request-wrapper">
            <summary className="upgrade-request-summary">
              <div className="upgrade-request-header">
                <h5>Account Holder Information</h5>
                <img src={Claret} alt="drop down icon" />
              </div>
              <p>Here, you find detailed information on the account holder of the account to be upgraded.</p>
            </summary>
            <div className="upgrade-request-documents">
              <UpgradeRequestDetails data={accountHolderDetails} />
            </div>
          </details>
          <details className="upgrade-request-wrapper">
            <summary className="upgrade-request-summary">
              <div className="upgrade-request-header">
                <h5>Upgrade Request History</h5>
                <img src={Claret} alt="drop down icon" />
              </div>
              <p>Here’s a history of the decisions that have been made with regards to upgrading this account.</p>
            </summary>
            <div className="related-document-content upgrade-request-documents">
              <p className="no-record-request">No decision has been made previously on the request to upgrade this account.</p>
            </div>
          </details>
        </main>
      }
      hideSecondButton
      firstButtonText="Close"
    />
  );
};

export default UpgradeRequestModal;
