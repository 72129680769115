import APIRequest from '+services/api-services';
import { useState } from 'react';
import { useQuery } from 'react-query';
import {Country, StatesListType, CountryList} from '+types';

const api = new APIRequest();

const useCountriesAndStates = () => {
  const [countriesList, setCountriesList] = useState<CountryList[]>([]);
  const [statesList, setStatesList] = useState<StatesListType>({});

  const createStatesList = (listOfCountriesData: Array<Country>) => {
    const newState: StatesListType = listOfCountriesData.reduce((accum, next) => ({ ...accum, [next.iso2]: next.states }), {});
    setStatesList(newState);
  };

  useQuery('COUNTRIES_AND_STATES', () => api.fetchCountries(true, { includeStates: true, forCardPayment: undefined }), {
    onSuccess: data => {
      const options = data?.data.map((item: Country) => ({
        value: item.iso2,
        label: item.name
      }));
      setCountriesList(options);
      createStatesList(data?.data);
    }
  });

  return { countriesList, statesList };
};

export default useCountriesAndStates;
