import { useState } from 'react';
import clsx from 'clsx';

import { useClickOutside } from '+hooks';

import { CardOpType, CardStatusType } from '../../types';
import { getIssuingPermissions } from '../../utils';

const CardManagement = ({
  status,
  cardCategory,
  onActionClick
}: {
  status: CardStatusType;
  cardCategory: 'reserved_cards' | 'customer_cards';
  // eslint-disable-next-line no-unused-vars
  onActionClick: (args: CardOpType) => void;
}) => {
  const { canManageCardDetails, canFundReservedCard } = getIssuingPermissions();
  const [showMenu, setShowMenu] = useState(false);
  const canManageCard = canManageCardDetails && status !== 'terminated';
  const canFundCard = canFundReservedCard && cardCategory === 'reserved_cards';
  const menuRef = useClickOutside<HTMLDivElement>(() => {
    setShowMenu(false);
  });

  const menuOptions = [
    ...(['deactivated', 'suspended'].includes(status)
      ? [{ label: 'reactivate card', action: 'reactivate' }]
      : [{ label: 'suspend card', action: 'suspend' }]),
    ...(status === 'terminated' ? [] : [{ label: 'terminate card', action: 'terminate' }])
  ];

  return canManageCard ? (
    <div className="flex flex-md wrap">
      <div className="dropdown" ref={menuRef}>
        <button type="button" className="btn btn-secondary link-btn pr-0" onClick={() => setShowMenu(true)}>
          <span>Manage Card</span>
          <i className="os-icon os-icon-chevron-down icon" />
        </button>

        {showMenu && (
          <ul className="element-box box-style ellipsis__nav withdrawal-options menu-items">
            {menuOptions.map(({ label, action }) => (
              <li
                key={action}
                role="presentation"
                className="ellipsis__item"
                onClick={() => {
                  setShowMenu(false);
                  onActionClick(action as CardOpType);
                }}
              >
                <span className={clsx('capitalize', { 'danger-text': action === 'terminate' })}>{label}</span>
              </li>
            ))}
          </ul>
        )}
      </div>

      {canFundCard ? (
        <div>
          <button type="button" className="flex btn btn-primary fund-btn" onClick={() => onActionClick('fundCard')}>
            <i className="os-icon os-icon-plus icon" />
            <span className="m-0">Fund Card</span>
          </button>
        </div>
      ) : null}
    </div>
  ) : null;
};

export default CardManagement;
