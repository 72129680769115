import React, { useEffect } from 'react';
import { useMutation } from 'react-query'
import { useDebouncedCallback } from 'use-debounce';
import { useProcessConversion, useCountdown, useConversionLogics, useFeedbackHandler } from '+hooks';
import { IFundByWalletModalProps, WalletModal, ListDropdownProps, Iconversion } from '+types';
import {
  formatAmount,
  cleanInput,
  backwardAmountInput,
  logError,
} from '+utils'
import APIRequest from '+services/api-services';
import CurrencyConversionForm from '+containers/Dashboard/Shared/CurrencyConversionForm';
import './index.scss';

const publicApi = new APIRequest(process.env.REACT_APP_PUBLIC_MERCHANT_MIDDLEWARE_API_BASE);

const ConvertCurrencies: React.FC<IFundByWalletModalProps & WalletModal & ListDropdownProps & Iconversion> = ({ onConversionSuccess, amountInputRef, onError }) => {
  const {
    state,
    setState,
    rate,
    toAmount,
    availableBalance,
    minConversionLimit,
    maxConversionLimit,
    errorMessage,
    validateAmount,
    setErrorMessage,
    queryData,
    conversionsQueryData,
    currencyArray } = useConversionLogics();

  const { amount, data, payloadCurrency } = state;
  const { closeFeedback } = useFeedbackHandler();

  const { mutateAsync: initiateConversion, isLoading, } = useMutation('SWAP_WALLET', () => publicApi.initiateConversion(queryData), {
    onSuccess: async data => {
      const response = data?.data;
      setState({ exchange: response || {}, data: response || null });
      setState({ countdownCompleted: false, isLoading: false });
    },
    onError: (error: any) => {
      logError(error);
      onError(error.response?.data?.message || 'There has been an error getting swap information')

      if (error?.response?.data?.message?.includes('Too many requests' || 'invalid request data') && !checkLimitAndValidate) {
        setTimeout(() => {
          closeFeedback();
        }, 7000);
      }
    }
  });

  const { processConversionMutation } = useProcessConversion(conversionsQueryData, onConversionSuccess);

  const handleCountdownCompletion = async () => {
    setState({ countdownCompleted: true, isLoading: true });
    await initiateConversion();
    setState({ countdownCompleted: false });
  };

  const { currentCount, resetCountdown } = useCountdown({
    countFrom: state.countdown,
    isStartCount: state.startCount,
    isRestartCountdown: true,
    callbackFn: handleCountdownCompletion
  });

  const debouncedFetchData = useDebouncedCallback(async () => {
    if (payloadCurrency.from === payloadCurrency.to) return;

    const isValid = validateAmount();
    if (isValid && (payloadCurrency.from || payloadCurrency.to)) {
      setState({ apiData: null });
      try {
        const result = await initiateConversion();
        setState({ apiData: result?.data });
      } catch (error: any) {
        onError(error.response?.data?.message || 'Conversion can not be initiated, please try again')
      }
    }
  }, 1000);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const inputValue = e.target.value;
    const formattedAmount = backwardAmountInput(inputValue);
    if (inputValue === '') {
      setState({ apiData: null, amount: inputValue });
      return;
    }
    setState({ amount: formattedAmount as typeof amount, apiData: null });
    debouncedFetchData();
    if (inputValue < amount || inputValue === '') {
      setState({ data: null });
      return;
    }
  };

  const handleSelectChange = (e: React.ChangeEvent<HTMLSelectElement>, name: string): void => {
    const selectedCurrency = e.target.value;

    if (name === "currencyFrom") {
      setState({
        payloadCurrency: {
          ...state.payloadCurrency,
          from: selectedCurrency,
        },
      });
    } else if (name === "currencyTo") {
      setState({
        payloadCurrency: {
          ...state.payloadCurrency,
          to: selectedCurrency,
        },
      });
    }
    debouncedFetchData();
  };

  const handleSwitchCurrency = () => {
    const { from, to } = state.payloadCurrency;
    setState({
      payloadCurrency: {
        from: to,
        to: from
      }
    });
  };

  const validate = () => {
    if (data !== null && amount !== '' && !isLoading && (payloadCurrency.from !== "" && payloadCurrency.to !== "")) {
      const parsedAmount = parseFloat(amount);
      const maxLimit = maxConversionLimit !== undefined ? Math.min(maxConversionLimit, availableBalance) : availableBalance;

      return parsedAmount >= minConversionLimit && parsedAmount <= maxLimit;
    }

    return false;
  };

  const checkLimitAndValidate = validate();

  useEffect(() => {
    if (checkLimitAndValidate) {
      setState({ startCount: true });
    } else {
      setState({ startCount: false });
      resetCountdown();
    }
  }, [checkLimitAndValidate]);

  const handleProcessConversion = async () => {
    setState({ loading: true, isProcessing: true });
    try {
      setState({ isProcessing: true });
      await processConversionMutation();
    } finally {
      setState({
        amount: '',
        isProcessing: false,
      });
    }
  }

  return (
    <CurrencyConversionForm
      formType="main"
      payloadCurrency={payloadCurrency}
      currencyArray={currencyArray}
      handleSelectChange={handleSelectChange}
      handleSwitchCurrency={handleSwitchCurrency}
      handleProcessConversion={handleProcessConversion}
      state={state}
      availableBalance={availableBalance}
      errorMessage={errorMessage}
      checkLimitAndValidate={checkLimitAndValidate}
      formatAmount={formatAmount}
      rate={rate}
      toAmount={toAmount}
      isLoading={isLoading}
      amount={amount}
      handleInputChange={handleInputChange}
      cleanInput={cleanInput}
      amountInputRef={amountInputRef}
      currentCount={currentCount}
    />
  )
}

export default ConvertCurrencies;
