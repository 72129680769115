import { Currency } from '+types';
import { capitalize, formatAmount, formatStatus, getDate, getTime, history } from '+utils';

import { AllFundingDepositsResponseType } from '../../types';

const FundingDepositsRow = ({ rowData, currency }: { rowData: Array<AllFundingDepositsResponseType>; currency: Currency }) => {
  const handleClick = (reference: string) => history.push(`/dashboard/issuing/funding-deposit/${reference}`);

  return (
    <div>
      {rowData?.map?.(tx => {
        const status = formatStatus(tx.status);

        return (
          <div
            key={`transaction_${tx.reference}_${tx.amount}`}
            className="div-table --history-table --row balance-history-row"
            role="button"
            tabIndex={0}
            onClick={() => handleClick(tx.reference)}
            onKeyDown={e => {
              if (e.key === 'Enter') handleClick(tx.reference);
            }}
          >
            <div className="--txn-status --column" style={{ display: 'flex' }}>
              <span className="body-row-header">Status:</span>
              <span className={`status-indicator ${status === 'processing' ? 'warn' : status}`} />
              <span>{capitalize(status)}</span>
            </div>
            <div className="--column">
              <span className="body-row-header">Details:</span>
              <span className="value">
                Issuing balance funding
                {tx.reference ? (
                  <span style={{ color: '#007bff', fontWeight: '500', textTransform: 'uppercase' }}> - {tx.reference}</span>
                ) : null}
              </span>
            </div>
            <div className="--column">
              <span className="body-row-header">Date:</span>
              <span className="grey-text" style={{ marginLeft: '5px' }}>
                {getDate(tx.transaction_date)} {getTime(tx.transaction_date)}
              </span>
            </div>
            <div className="--column">
              <span className="body-row-header">Amount ({currency}):</span>
              <span>{formatAmount(tx.amount)}</span>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default FundingDepositsRow;
