export type IdentityServiceAccessType = {
  active: boolean;
  has_access: boolean;
  requested_access: boolean;
};

export type MetaDataType = {
  [key in 'document_details' | 'files']: Array<{
    key: string;
    description: string;
  }>;
};
export type VerificationMerchantType = {
  reference: string;
  name: string;
  email: string;
};

export type VerificationDataType = {
  reference: string;
  id: string;
  type: string;
  class: string;
  identity_type: string;
  identity_type_label: string;
  identity_type_description: string;
  full_name: string;
  country: string;
  requested_by: string;
  merchant: VerificationMerchantType;
  status: string;
  result: string;
  date_created: string;
  metadata: MetaDataType;
};
export type BillingHistoryDataType = {
  reference: string;
  verification_reference: string;
  amount: number;
  currency: string;
  narration: string;
  verification_type: string;
  verification_class: string;
  type: string;
  identity_type: string;
  status: string;
  date_created: string;
};

export const EventType = {
  kyc: 'Individual (KYC)',
  kyb: 'Business (KYB)'
} as const;

export const RegionType = {
  ng: 'Nigeria',
  ke: 'Kenya',
  gh: 'Ghana',
  za: 'South Africa',
  uk: 'United Kingdom',
  cm: 'Cameroon'
} as const;

export type ValidationObject = {
  [key: string]: boolean | string | number | ValidationObject | null;
};

export type ValidationValueT = {
  value: string;
  match: boolean;
  confidence_rating?: number;
};

export type ValidationT = {
  [key in 'first_name' | 'last_name' | 'date_of_birth' | 'selfie']: ValidationValueT;
};

export interface ILabeledValue {
  value: string;
  label: string;
}
export interface IVerificationType extends ILabeledValue {
  identity_types: ILabeledValue[];
}

export interface IAvailableCountry extends ILabeledValue {
  verification_types: IVerificationType[];
}

export interface IdRequestData {
  available_countries: IAvailableCountry[];
  other_countries: ILabeledValue[];
  available_verification_classes: ILabeledValue[];
  other_verification_classes: ILabeledValue[];
}
export type EnabledFeatureType = {
  enable: boolean;
};

export type SubValueType = EnabledFeatureType & {
  [key: string | keyof typeof EventType]: SubValueType | boolean;
};

export type IdReqPayloadType = {
  [countryCode: string]: any;
};
