import { useEffect, useRef } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';

import { IModalProps } from '+containers/Dashboard/Shared/Modal';
import { useFeedbackHandler, useReducerState } from '+hooks';
import APIRequest from '+services/api-services';
import { logError } from '+utils';

import {
  errorText,
  formatError,
  modalContent,
  successText,
} from './data';
import { TConversionsError, TConversionsPayload, TConversionsState, TCurrencyMarkup, TCurrencyStructure, TError } from '+types/conversions';

const api = new APIRequest();

export default function useConversions(activeCurrency: string) {
  const queryClient = useQueryClient();
  const { feedbackInit } = useFeedbackHandler();
  const [state, setState] = useReducerState<TConversionsState>({
    activeModal: '',
    settlementCurrency: '',
    currencyList: [],
    rateMarkup: '',
    enabled: false
  });

  const currencySettings = useRef<{
    [key: string]: {
      enabled: boolean;
      activated: boolean;
      rate: string;
      limit: string;
    };
  }>({});

  const { isFetching } = useQuery(['CONVERSIONS', activeCurrency], () => api.getCurrencyConversionsSettlementConfig(activeCurrency), {
    cacheTime: 0,

    onSuccess: data => {
      let currencyStructure = {} as TCurrencyStructure;
      let activatedCurrency: keyof TCurrencyStructure = '';
      let enabledCurrency = false;

      Object.entries((data.data || {}) as TCurrencyMarkup).forEach(([key, value]) => {
        if (value.activated) activatedCurrency = key;
        if (value.enabled) enabledCurrency = value.enabled;

        currencyStructure = {
          ...currencyStructure,
          [key]: {
            activated: value.activated,
            enabled: value.enabled,
            rate: (value.markup.kora.value || 0).toFixed(2),
            limit: (value.markup.merchant.limit || 0).toFixed(2)
          }
        };
      });

      currencySettings.current = currencyStructure;
      setState({ currencyList: Object.keys(currencyStructure)});

      if (!activatedCurrency || !enabledCurrency) {
        setState({settlementCurrency: '', rateMarkup: '', enabled: enabledCurrency});
        return;
      }

      setState({settlementCurrency: activatedCurrency, rateMarkup: currencyStructure[activatedCurrency].rate, enabled: enabledCurrency});
    },

    onError: (e: TError) => {
      logError(e);

      feedbackInit({
        message: e.response?.data.data?.message || `There has been an error getting currency conversion status`,
        type: 'danger'
      });
    }
  });

  const { mutateAsync: updateConversions } = useMutation(
    (arg: TConversionsPayload) => api.updateConversionsSettlementConfig({ from_currency: activeCurrency, to_currency: state.settlementCurrency, ...arg}, activeCurrency),
    {
      onSuccess: () => queryClient.invalidateQueries('CONVERSIONS'),
      onError: (e: TConversionsError) => {
        formatError(e);
        logError(e);

        feedbackInit({
          message: formatError(e) || `There has been an error with this settlement conversion process`,
          type: 'danger',
          componentLevel: true
        });
      }
    }
  );

  const disableSettlementBtn = () => {
    if (!currencySettings.current[state.settlementCurrency]) return true;
    const { rate, activated } = currencySettings.current[state.settlementCurrency];

    return !!(+rate === +state.rateMarkup && activated);
  };

  const toggleConversions = () => {
    if (!state.currencyList.length) return;
    if (!state.enabled) {
      setState({enabled: true});
      return;
    }

    if (currencySettings.current[state.settlementCurrency]?.enabled) {
      setState({activeModal: 'disableConversions'});
    } else setState({enabled: false});
  };

  const modalDetails = {
    enableConversion: {
      ...modalContent.enableConversion,
      content: modalContent.enableConversion.content({
        defaultCurrency: activeCurrency,
        rateMarkup: state.rateMarkup,
        currency: state.settlementCurrency
      }),
      secondButtonAction: async () => {
        const data = await updateConversions({
          enable: true,
          markup: +state.rateMarkup || 0,
        });
        if (data) setState({activeModal: 'enableConversionSuccess'});
      }
    },

    enableConversionSuccess: {
      ...modalContent.enableConversionSuccess,
      content: modalContent.enableConversionSuccess.content({
      close: () => setState({ activeModal: '' }),
      text: successText({ fromCurrency: state.settlementCurrency, toCurrency: activeCurrency })
      })
    },

    disableConversions: {
      ...modalContent.disableConversions,
      content: modalContent.disableConversions.content(activeCurrency),
      secondButtonAction: async () => {
        await updateConversions({ enable: false });
        setState({activeModal: 'disableConversionSuccess'});
      }
    },

    disableConversionSuccess: {
      ...modalContent.disableConversionSuccess,
      content: modalContent.enableConversionSuccess.content({ close: () => setState({ activeModal: '' }), text: errorText(activeCurrency) })
    }
  }[state.activeModal] as unknown as IModalProps;

  useEffect(() => {
    if (!currencySettings.current[state.settlementCurrency]) return;
    setState({rateMarkup: currencySettings.current[state.settlementCurrency].rate});
  }, [state.settlementCurrency]);

  return {
    ...state,
    setState,
    modalDetails,
    disableSettlementBtn,
    updateConversions,
    isFetching,
    toggleConversions,
  };
}
