import { IBalances } from '+types';

export function getAvailableBalance(currency: string, balances: IBalances): number | null {
  return Object.keys(balances).includes(currency) ? balances[currency]?.available_balance : null;
}

export function getMinConversionLimit(from: string, conversionLimits: any): number {
  return conversionLimits?.[from]?.min || (['NGN'].includes(from) ? 1000 : 100);
}

export function getMaxConversionLimit(from: string, conversionLimits: any): number | undefined {
  return conversionLimits?.[from]?.max;
}

export function createQueryData(amount: string, payloadCurrency: {
  from: string;
  to: string;
}) {
  return {
    amount: amount,
    from_currency: payloadCurrency.from,
    to_currency: payloadCurrency.to
  };
}

export function createConversionsQueryData(queryData: Record<string, any>, reference: string) {
  return {
    ...queryData,
    rate_reference: reference,
    type: 'wallet_conversion'
  };
}
