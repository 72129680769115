import { TwoFactorAuthType } from '+types';

export const getOtpDescriptors = (otpType: TwoFactorAuthType) => {
  if (otpType === 'totp') {
    return { otpLabel: 'Authentication code', otpPlaceholder: 'Enter authentication code' };
  }

  if (otpType === 'totp_recovery_code') {
    return { otpLabel: 'Recovery code', otpPlaceholder: 'Enter recovery code' };
  }

  return { otpLabel: 'Verification code', otpPlaceholder: 'Enter verification code' };
};
