/* eslint-disable no-unused-vars */
import { Dispatch } from 'react';
import { useQuery } from 'react-query';

import ToolTip from '+containers/Dashboard/Shared/Tooltip';
import { useFeedbackHandler } from '+hooks';
import APIRequest from '+services/api-services';
import useStore from '+store';
import { ICreateEmailConfigurationSlice, IEmailSubscriptionResponse, IEmailSubscriptionState, ISubscriptionVariable } from '+types';
import { EmailListValidation, logError } from '+utils';

import InfoIcon from '+assets/img/dashboard/information-button.svg';

import './index.scss';

const api = new APIRequest(process.env.REACT_APP_PUBLIC_MERCHANT_MIDDLEWARE_API_BASE);

const SubscribeConfiguration = ({
  updateSubscriptionState,
  subscriptionState,
  updateSubscribeEmailConfig,
  subscribingEmailConfigLoading,
  isAllowed
}: {
  updateSubscriptionState: Dispatch<Partial<IEmailSubscriptionState>>;
  subscriptionState: IEmailSubscriptionState;
  subscribingEmailConfigLoading: boolean;
  isAllowed: boolean;
  updateSubscribeEmailConfig: (variables: ISubscriptionVariable) => Promise<void>;
}) => {
  const { feedbackInit } = useFeedbackHandler();
  const { setIsCompleteAfterSave, setSaveFn, setModalStage, setAdditionalContentModified, setShowEmailConfigModal } = useStore(
    store => store as ICreateEmailConfigurationSlice
  );

  const { data } = useQuery(['SUBSCRIBED_EMAILS'], api.getSubscribeEmailConfig, {
    onSuccess(response: IEmailSubscriptionResponse) {
      const state = response?.configuration?.account_report?.transaction_report;
      if (state)
        updateSubscriptionState({
          interval: state.interval,
          enabled: state.enabled,
          email_recipients: state.email_recipients.join(', ').split(',')
        });
    },
    onError(error) {
      logError(error);
      feedbackInit({
        type: 'danger',
        message: 'Something went wrong, please try again'
      } as any);
    }
  });

  const recipients = subscriptionState?.email_recipients.join(',');

  const errorValidationMesage = EmailListValidation(recipients, 3);

  const buttonText = subscriptionState.enabled ? 'Unsubscribe' : 'Subscribe';

  const disabled = subscribingEmailConfigLoading || !!errorValidationMesage;

  return (
    <>
      <p className="description mb-0">Product update reports</p>
      <div className="sub-desc">
        {subscriptionState.enabled ? (
          <>
            <p className="sub-text pl-0 col-9">Send automated product update reports</p>
            <div className="sub-select-wrapper">
              <select
                className="sub-interval"
                disabled={!isAllowed}
                value={subscriptionState.interval}
                onChange={({ target: { value } }) => {
                  updateSubscriptionState({ interval: value, intervalSelected: true });
                  setAdditionalContentModified(true);
                }}
              >
                <option value="weekly">Send Weekly</option>
                <option value="monthly">Send Monthly</option>
              </select>
              <div className="sub-custom-arrow">&#9660;</div>
            </div>
          </>
        ) : (
          <p className="sub-text">
            Receive weekly or monthly product updates on your account transactions. Enter your preferred email(s) and{' '}
            <strong> click the button below to subscribe.</strong>
          </p>
        )}
      </div>
      <div className="configuration-form-heading">
        <div className="form-group mt-0 mb-0">
          <label htmlFor="refund-amount" className="withdraw-label">
            <span className="dark" style={{ color: '#3E4B5B' }}>
              Where should Kora send automated product update emails?
              <ToolTip
                image={InfoIcon}
                type="accepted_amount"
                message={
                  <p>
                    Product Update emails will be sent to the email address(es) below. You can add up to{' '}
                    <strong> three (3) email addresses. </strong> Add multiple emails by separating them with a comma.
                  </p>
                }
              />
            </span>
          </label>
          <div className="subscribe-box">
            <div className="subscribe-box-input ">
              <input
                data-testid="config-email-input"
                value={recipients}
                disabled={!isAllowed}
                placeholder="Enter up to three (3) email addresses."
                onChange={({ target: { value } }) => {
                  const splitValue = value.split(',');
                  if (splitValue.length > 3) {
                    return;
                  }
                  setIsCompleteAfterSave(subscriptionState.enabled);
                  setAdditionalContentModified(true);
                  updateSubscriptionState({
                    email_recipients: splitValue,
                    ...(subscriptionState.enabled ? { emailModified: true, continueSubscription: false } : { continueSubscription: true })
                  });
                }}
                className={`form-control email-input ${!!errorValidationMesage && !!recipients ? 'error' : ''}`}
              />
            </div>
            <button
              className={`btn flex ${subscriptionState.enabled ? 'btn-unsubscribe' : 'btn-primary'}`}
              type="button"
              onClick={async () => {
                if (!isAllowed) return;
                setIsCompleteAfterSave(true);
                if (!subscriptionState.enabled) {
                  setModalStage('email-subscription-interval');
                  setSaveFn(async (interval: string) => {
                    await updateSubscribeEmailConfig({
                      email_recipients: subscriptionState.email_recipients,
                      enabled: !subscriptionState.enabled,
                      interval
                    });
                  });
                  setShowEmailConfigModal(true);
                  return;
                }
                setModalStage('email-unsubscription');
                setSaveFn(async (reason: string) => {
                  await updateSubscribeEmailConfig({
                    email_recipients: subscriptionState.email_recipients,
                    enabled: !subscriptionState.enabled,
                    interval: data?.configuration?.account_report?.transaction_report?.interval as string,
                    unsubscribe_reason: reason
                  });
                });
                setShowEmailConfigModal(true);
              }}
              disabled={disabled || !isAllowed}
            >
              {subscribingEmailConfigLoading ? (
                <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true" />
              ) : (
                buttonText
              )}
            </button>
          </div>
          {subscriptionState?.email_recipients.length > 0 && <p style={{ color: '#ff5661' }}>{errorValidationMesage}</p>}
        </div>
      </div>
    </>
  );
};

export default SubscribeConfiguration;
