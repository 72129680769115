import React, { useState, useRef } from 'react';
import APIRequest from '+services/api-services';
import { APIDownload } from '+utils';
import DownloadIcon from '+assets/img/dashboard/download.svg';
import KpyLoader from '+assets/img/dashboard/kpy-loader.svg';
import Visible from '+assets/img/dashboard/viewicon.svg';
import useBreakpoints from '+hooks/useBreakpoints';
import useFeedbackHandler from '+hooks/feedbackHandler';
import Modal from './Modal';

const api = new APIRequest();
const queries = {
  small: '(max-width: 450px)',
  normal: '(min-width: 700px)'
};
interface IViewAndDownloadType {
  url: string;
  type: string;
  filename: string;
  info: string;
  disableView: boolean;
  disableDownload: boolean;
  showDownloadButton: boolean;
}

function ViewAndDownload({ url, type, filename, info, disableView, disableDownload, showDownloadButton }: IViewAndDownloadType) {
  const { feedbackInit } = useFeedbackHandler();
  const [showView, setShowView] = useState(false);
  const matchPoints = useBreakpoints(queries);
  const loaderRef = useRef();

  const download = async () => {
    try {
      const res = await api.downloadFile(url);
      APIDownload(res, `${info}: ${filename}`, type);

      feedbackInit({
        title: 'Download successful',
        message: (
          <>
            {' '}
            - Successfully downloaded{' '}
            <strong>
              {filename}.{type}
            </strong>
          </>
        ),
        type: 'success'
      });
    } catch (error) {
      feedbackInit({
        title: 'Download failed',
        message: `There has been an error in downloading your file`,
        type: 'danger',
        componentLevel: true
      });
    }
  };

  return (
    <>
      {matchPoints?.small || type === 'pdf' ? (
        <a
          className="download_button"
          href={url}
          target="_blank"
          rel="noopener noreferrer"
          download
          onClick={e => (disableView ? e.preventDefault() : null)}
          disabled={disableView}
        >
          <span>View</span> 
          <img src={Visible} alt="view" />
        </a>
      ) : (
        <button type="button" className="download_button" href={url} onClick={() => setShowView(true)} disabled={disableView}>
          <span>View </span> <img src={Visible} alt="view" />
        </button>
      )}

      {showDownloadButton && (
        <>
          <span className="divider-sm" />
          <button type="button" className="download_button" onClick={download} disabled={disableDownload}>
            <span>Download</span>
            <img alt="" src={DownloadIcon} disabled={disableDownload} />
          </button>
        </>
      )}

      {showView && (
        <Modal
          close={() => setShowView(false)}
          size="lg"
          heading="File Viewer"
          content={
            <section className="iframe-container">
              <img src={KpyLoader} alt="loader" ref={loaderRef} width="40px" />
              <iframe
                src={url}
                title="file-viewer"
                sandbox=""
                width="100%"
                height="500px"
                frameBorder="0"
                onLoad={() => {
                  loaderRef.current.style.display = 'none';
                }}
              />
            </section>
          }
          showButtons={false}
        />
      )}
    </>
  );
}

export default ViewAndDownload;
