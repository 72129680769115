/* eslint-disable camelcase */
/* eslint-disable no-nested-ternary */
import React, { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { Link } from 'react-router-dom';

import { useFeedbackHandler, usePermissions, useSearchQuery } from '+hooks';
import useExportDownloader from '+hooks/useExportDownloader';
import APIRequest from '+services/api-services';
import useStore from '+store';
import {
  APIDownload,
  capitalize,
  capitalizeFirst,
  capitalizeRemovedash,
  daysfromToday,
  filteredOutObjectProperty,
  formatAmount,
  getDate,
  getTime,
  history,
  logBreadCrumb,
  queriesParams
} from '+utils';
import { breadCrumbEvents } from '+utils/bugsnag-events';

import AdvanceExportModal from '../../Shared/AdvanceExportModal';
import CurrencyPicker from '../../Shared/CurrencyPicker';
import ExportFilterModal from '../../Shared/ExportFilterModal';
import Table from '../../Shared/Table';
import SettlementsFilter from '../components/SettlementFilter';

import './index.scss';

const api = new APIRequest();

function SettlementHistory() {
  const searchQuery = useSearchQuery();

  const activeCurrency = searchQuery.value.currency || 'NGN';
  const currentLimit = searchQuery.value.limit || '10';
  const paginationPage = searchQuery.value.page || '1';
  const activeTab = searchQuery.value.tab || 'pending';
  const userAccess = usePermissions('settlement') || '';
  const sortingParams = {
    status: activeTab,
    ...filteredOutObjectProperty(searchQuery.value, [queriesParams.currency, queriesParams.page, queriesParams.limit, queriesParams.tab])
  };

  const { profile } = useStore();
  const [showLargeExportModal, setLargeExportModal] = useState(false);

  const { feedbackInit } = useFeedbackHandler();
  const [exportModalVisible, setExportModalVisible] = useState(false);

  const tabs = ['pending', 'settled'];
  const buffer: any[] = [];
  const anyLoading: Record<string, any> = {};
  const getTabDetails = (tab: string) => buffer.find(data => data.type === tab);

  const MERCHANT_ENV = useStore(store => store.merchantEnv);
  const availableCurrencies = useStore(state => state.availableCurrencies);

  const exportFile = async (format, fieldToExport) => {
    const parameterizeArray = (key, arr) => {
      arr = arr.map(encodeURIComponent);
      return arr.join(`&${key}[]=`);
    };
    const fields = parameterizeArray('fieldsToExport', fieldToExport);

    try {
      const res = await api.exportSettlements(activeTab, format, sortingParams, activeCurrency, fields);
      if (res.status === 202) {
        setLargeExportModal(true);
      } else {
        const type = format === 'csv' ? 'csv' : 'xlsx';
        APIDownload(res, `Settlements at ${getDate(Date.now())}`, type);
        feedbackInit({
          message: `Settlements successfully downloaded`,
          type: 'success'
        });
      }
      setExportModalVisible(false);
    } catch (error) {
      setExportModalVisible(false);
      feedbackInit({
        message: `There has been an error exporting your settlements`,
        type: 'danger'
      });
    }
  };

  const { getDownload } = useExportDownloader('Settlements');

  useEffect(() => {
    getDownload();
  }, []);

  // Settlement summary
  const { data: sum } = useQuery(['SETTLEMENT_SUMMARY', MERCHANT_ENV, activeCurrency], () => api.getSettlementSummary(activeCurrency), {
    onError: () => {
      feedbackInit({
        message: 'There has been an error getting settlement information',
        type: 'danger'
      });
    }
  });

  const { data, isFetching } = useQuery(
    [`${activeTab.toUpperCase()}_SETTLEMENTS`, paginationPage, currentLimit, MERCHANT_ENV, sortingParams, activeCurrency],
    () => api.getSettlements(paginationPage, currentLimit, activeTab, sortingParams, activeCurrency),
    {
      keepPreviousData: true,
      onError: () => {
        feedbackInit({
          message: `There has been an error fetching the ${activeTab} settlements.`,
          type: 'danger'
        });
      }
    }
  );
  anyLoading[activeTab] = isFetching;
  buffer.push({ type: activeTab, ...data });

  const summary = sum?.data || {};

  const tableOfSettlements = () => {
    const activeList = getTabDetails(activeTab);
    return (
      <div>
        {activeList?.data?.map(
          (each: {
            reference: string;
            is_processed: any;
            category: any;
            payment_method: string;
            createdAt: any;
            transactions_amount: any;
            amount: any;
            amount_settled: any;
            expected_settlement_date: any;
            processed_at: any;
          }) => {
            return (
              <div
                key={`settlement_${each.reference}`}
                className="div-table --settlement-table --row"
                onClick={() => {
                  history.push(`/dashboard/settlements/${each.reference}`);
                  logBreadCrumb({
                    event: breadCrumbEvents.settlements.tableClicked(activeTab),
                    data: { tab: activeTab, reference: each?.reference }
                  });
                }}
                onKeyDown={() => {
                  history.push(`/dashboard/settlements/${each.reference}`);
                  logBreadCrumb({
                    event: breadCrumbEvents.settlements.tableClicked(activeTab),
                    data: { tab: activeTab, reference: each?.reference }
                  });
                }}
                role="button"
                tabIndex={0}
              >
                <div>
                  <span className="body-row-header">Settlement ID:</span>
                  <span className={`status-pill smaller ${!each.is_processed ? 'yellow' : 'green'}`} />
                  <span className="trxn-id" style={{ fontWeight: '600', textTransform: 'uppercase' }}>
                    {each.reference && each.reference.toUpperCase()}
                  </span>
                </div>
                <div>
                  <span className="body-row-header">Payment Method:</span>
                  {each.category || each.payment_method ? (
                    capitalize(
                      each.payment_method === 'card'
                        ? `${each.payment_method} Payment`
                        : capitalizeRemovedash(each.payment_method || each.category)
                    )
                  ) : (
                    <span style={{ color: 'rgba(90, 99, 126, 0.49)' }}>Not available</span>
                  )}
                </div>
                {activeTab === 'pending' ? (
                  <div>
                    <span className="body-row-header">Created On:</span>
                    <span>{getDate(each.createdAt)}</span>
                    <span className="annotation" style={{ marginLeft: '5px' }}>
                      {getTime(each.createdAt)}
                    </span>
                  </div>
                ) : (
                  <div>
                    <span className="body-row-header">Total Trans. Amount:</span>
                    <span>
                      <strong>{formatAmount(each.transactions_amount)}</strong>
                      <span className="annotation-currency" style={{ marginLeft: '5px' }}>
                        {activeCurrency}
                      </span>
                    </span>
                  </div>
                )}
                <div>
                  <span className="body-row-header">{activeTab === 'pending' ? 'Settlement Amount' : 'Amount Settled'}:</span>
                  <span>
                    <strong>{activeTab === 'pending' ? formatAmount(each.amount || 0) : formatAmount(each.amount_settled || 0)}</strong>
                    <span className="annotation-currency" style={{ marginLeft: '5px' }}>
                      {activeCurrency}
                    </span>
                  </span>
                </div>
                <div>
                  <span className="body-row-header">{activeTab === 'pending' ? `Expected Settlement Date` : `Settled On`}:</span>
                  {activeTab === 'pending' ? (
                    <span>{each.expected_settlement_date ? capitalizeFirst(daysfromToday(each.expected_settlement_date)) : 'N/A'}</span>
                  ) : (
                    <>
                      <span>{getDate(each.processed_at)}</span>
                      <span className="annotation" style={{ marginLeft: '5px' }}>
                        {getTime(each.processed_at)}
                      </span>
                    </>
                  )}
                </div>
              </div>
            );
          }
        )}
      </div>
    );
  };

  return (
    <>
      <ExportFilterModal close={() => setLargeExportModal(false)} email={profile.email} visible={showLargeExportModal} />
      <section className="os-tabs-w">
        <div className="os-tabs-controls os-tabs-complex settlement-history__tabs">
          <ul className="nav nav-tabs">
            {tabs.map(tab => (
              <li
                className="nav-item"
                key={tab}
                onClick={() => {
                  searchQuery.setQuery({ tab }, true);
                  logBreadCrumb({ event: breadCrumbEvents.settlements.tabClicked(tab) });
                }}
                onKeyUp={() => searchQuery.setQuery({ tab }, true)}
                role="tab"
                tabIndex={0}
              >
                <button type="button" className={`nav-link ${activeTab === tab && 'active'}`}>
                  {capitalize(tab)}
                </button>
              </li>
            ))}
          </ul>
          <CurrencyPicker
            options={availableCurrencies || ['NGN']}
            onChange={value => {
              searchQuery.setQuery({ currency: value, page: '1', tab: activeTab }, true);
            }}
            className="settlement-history__currency-switch"
            activeCurrency={activeCurrency}
            id="settlements-history__currency-switch"
          />
        </div>
      </section>

      <section className="history_summary_details settlement-summary">
        <div className="history_summary_heading">
          {activeTab === 'pending' && (
            <div className="info-summary-item">
              <p className="label">Total Pending Settlements ({activeCurrency})</p>
              <p className="value">{formatAmount(summary?.totalPendingSettlements || 0)}</p>
              <p className="description">Funds waiting to be settled.</p>
            </div>
          )}
          {activeTab === 'settled' && (
            <div className="info-summary-item">
              <p className="label">All Completed Settlements ({activeCurrency})</p>
              <p className="value">{formatAmount(summary?.totalProcessedSettlements || 0)}</p>
              <p className="description">Settlements completed and received.</p>
            </div>
          )}
          <div>
            {userAccess === 'manage' && (
              <Link to="/dashboard/settings/settlements">
                <button type="button" className="btn btn-secondary">
                  <i className="os-icon os-icon-settings" />
                  <span>Configure Settlements</span>
                </button>
              </Link>
            )}
          </div>
        </div>
      </section>

      <SettlementsFilter
        openExportModal={() => setExportModalVisible(true)}
        userAccess={userAccess}
        totalCount={getTabDetails(activeTab)?.paging?.total_items}
      />

      <section className="element-box-tp mt-5">
        <div className="nav-content active">
          <Table
            tableClassName="--settlement-table"
            headings={[
              {
                value: 'Settlement ID'
              },
              {
                value: 'Payment Method'
              },
              {
                value: activeTab === 'pending' ? 'Created On' : 'Total Trans. Amount'
              },
              {
                value: activeTab === 'pending' ? 'Settlement Amount' : 'Amount Settled'
              },
              {
                value: activeTab === 'pending' ? `Expected Settlement Date` : `Settled On`
              }
            ]}
            hasPagination={!anyLoading[activeTab]}
            loading={anyLoading[activeTab]}
            current={parseInt(paginationPage, 10)}
            totalItems={getTabDetails(activeTab)?.paging ? getTabDetails(activeTab).paging.total_items : 0}
            pageSize={getTabDetails(activeTab)?.paging ? getTabDetails(activeTab).paging.page_size : 0}
            actionFn={current => searchQuery.setQuery({ page: String(current) })}
            limitAction={limit => searchQuery.setQuery({ limit: String(limit) })}
            annotation="settlements"
            emptyStateHeading={`There are no ${activeTab} settlements yet.`}
            emptyStateMessage={
              <span>
                <Link to="/dashboard/payment-links">Get paid</Link> to see settlements here.
              </span>
            }
          >
            {tableOfSettlements()}
          </Table>
        </div>
      </section>
      {exportModalVisible && (
        <AdvanceExportModal
          openExport={exportModalVisible}
          setOpenExport={setExportModalVisible}
          exportAction={exportFile}
          type="settlements"
          showSuccessModal={false}
        />
      )}
    </>
  );
}

export default SettlementHistory;
