import { useEffect, useState } from 'react';
import { history } from '+utils';
import ipWhiteListIntro from '+assets/img/dashboard/ipwlisting1.png';
import ipWhiteListIntro2 from '+assets/img/dashboard/ipwlisting2.png';
import ipWhiteListIntro3 from '+assets/img/dashboard/ipwlisting3.png';
import ipWhiteListIntro4 from '+assets/img/dashboard/ipwlisting4.png';
import auditLogTip from '+assets/img/dashboard/auditlogTip.png';
import teamTip from '+assets/img/dashboard/teamTip.png';
import { IGuideListItem, ITipAttribute } from '+types/tips-and-guide';
import useStore from '+store';
import useIpWhiteList from './useIpWhiteList';
import { GuideList } from '+dashboard/TipsAndGuides/data';
import useLocalStore from '+store/localStore';

export const useSecurityToolTip = ({ enableIpWhiteListQuery = true }: { enableIpWhiteListQuery?: boolean } = {}) => {
  const { ipEnabled, isSuccess: isIPSuccess, isError: isIPError } = useIpWhiteList({ enabled: enableIpWhiteListQuery });
  const { authDetails } = useStore();
  const userToken = authDetails?.access_token;
  const [permissions, setPermissions] = useState<{ [property: string]: string }>({});
  const profile = useStore(state => state.profile);
  const ipReader = useLocalStore((state: { ip: ITipAttribute }) => state.ip);
  const teamReader = useLocalStore((state: { team: ITipAttribute }) => state.team);
  const auditReader = useLocalStore((state: { audit: ITipAttribute }) => state.audit);
  const guideListReader = useLocalStore((state: { list: IGuideListItem[] }) => state.list);
  const setList = useLocalStore(state => state.setList);
  const setIp = useLocalStore(state => state.setIp);
  const setTeam = useLocalStore(state => state.setTeam);
  const setAudit = useLocalStore(state => state.setAudit);
  const [securityTipType, setSecurityTipType] = useState(null);
  const [isTipVisible, setIsTipVisible] = useState(false);
  const [openSecurityTip, setOpenSecurityTip] = useState(false);
  const [unClicked, setUnClicked] = useState<number>(GuideList.length);
  const ip = ipReader?.[profile?.email];
  const team = teamReader?.[profile?.email];
  const audit = auditReader?.[profile?.email];
  const guideList = guideListReader?.[profile?.email];

  const isIPQueryComplete = isIPSuccess || isIPError

  const generateSecurityTips = () => {
    if (!profile?.email) return;
    const tips: string[] = [];
    if (!ipEnabled && !ip?.deny && permissions?.ip_address_configuration === 'manage') tips.push('ip');
    if (!team?.deny && permissions?.team === 'manage') tips.push('team');
    if (!audit?.deny && permissions?.audit_log === 'view') tips.push('audit');

    const updatedList = GuideList.filter(({ type }) => {
      if (type === 'ip') return permissions?.ip_address_configuration === 'manage';
      if (type === 'team') return permissions?.team === 'manage';
      if (type === 'audit') return permissions?.audit_log === 'view';
      return true;
    });
    setList({ email: profile.email, value: updatedList });
    const randomTip = tips[Math.floor(Math.random() * tips.length)];
    setSecurityTipType(randomTip);
  };

  useEffect(() => {
    const unClickedTips = [ip, audit, team].reduce((initial, tip) => {
      let tipCounter = initial;
      if (tip?.clicked) tipCounter -= 1;
      return tipCounter;
    }, guideList?.length || 0);
    setUnClicked(unClickedTips);
  }, [ip?.clicked, team?.clicked, audit?.clicked, guideList]);

  useEffect(() => {
    if (userToken) {
      const jwt = JSON.parse(atob(userToken.split('.')[1]));
      const jwtPermissions = jwt?.permissions?.permissions || {};
      setPermissions(jwtPermissions);
    }
  }, [userToken]);

  const getSecurityTipProps = () => {
    switch (securityTipType) {
      case 'ip':
        return {
          checked: ip?.deny,
          onCheckedChange: () => setIp({ email: profile?.email, value: { deny: !ip?.deny } }),
          onSubmit: () => !ipEnabled && history.push('/dashboard/settings/security/ip-whitelist'),
          secondButtonFinalText: ipEnabled ? 'Done' : 'Get Started',
          removeIconOnFinalStep: true,
          hasFirstButton: true,
          data: [
            {
              title: 'Introducing IP Whitelisting!',
              description: 'IP Whitelisting guarantees secure API access: Safeguard your API access in three simple steps.',
              image: ipWhiteListIntro
            },
            {
              title: 'Find the IP Whitelist page',
              description: "Navigate to the 'IP Whitelist' tab within your dashboard's Settings Page.",
              image: ipWhiteListIntro2
            },
            {
              title: 'Add the IP addresses you want to authorise for access',
              description: "Click 'Add IP Address' to include the chosen IP addresses and provide access to your business dashboard.",
              image: ipWhiteListIntro3
            },
            {
              title: 'Enable IP Whitelisting!',
              description:
                "After adding the desired IP addresses for authorisation, click 'Enable IP Whitelist' to ensure access is restricted exclusively to the listed IP addresses",
              image: ipWhiteListIntro4
            }
          ]
        };
      case 'audit':
        return {
          checked: audit?.deny,
          onCheckedChange: () => setAudit({ email: profile?.email, value: { deny: !audit?.deny } }),
          onSubmit: () => history.push('/dashboard/audit-logs'),
          secondButtonFinalText: 'Take me to Audit Logs',
          data: [
            {
              title: 'Track activities with Audit Logs!',
              description:
                'Audit log serves as a documentation for organizations, providing a comprehensive historical record of activities on your dashboard.',
              image: auditLogTip
            }
          ]
        };
      case 'team':
        return {
          checked: team?.deny,
          onCheckedChange: () => setTeam({ email: profile?.email, value: { deny: !team?.deny } }),
          onSubmit: () => history.push('/dashboard/settings/teams'),
          secondButtonFinalText: 'Go to Team Settings',
          data: [
            {
              title: 'Introducing Teams!',
              description:
                'You can add team members to collaborate on your dashboard. Streamline responsibilities with designated roles for each member.',
              image: teamTip
            }
          ]
        };
      default:
        return null;
    }
  };

  return {
    ipEnabled,
    generateSecurityTips,
    unClicked,
    securityTipType,
    setSecurityTipType,
    getSecurityTipProps,
    ip,
    audit,
    team,
    setIp,
    setTeam,
    setAudit,
    isTipVisible,
    setIsTipVisible,
    openSecurityTip,
    setOpenSecurityTip,
    permissions,
    isIPQueryComplete
  };
};
