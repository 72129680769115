import { useState, useEffect } from 'react';
import useFeedbackHandler from '+hooks/feedbackHandler';
import { logError, countryMobileCode, formatAmount } from '+utils';
import { mobileEnquiry } from '+hooks';
import { WithdrawalDetails, IPayoutsModalProps } from '+types';

export default function useMobileValidation({ currency, maxPayoutLimit }: IPayoutsModalProps) {
  const { feedbackInit, closeFeedback } = useFeedbackHandler();
  const [showMobileName, setShowMobileName] = useState(false);
  const [accountNameDisabled, setAccountNameDisabled] = useState(true);
  const [withdrawalDetails, setWithdrawalDetails] = useState<WithdrawalDetails>({
    amount: null,
    description: null,
    pin: '',
    mobileNo: '',
    operatorCode: '',
    username: '',
    mobileMoneyCode: ''
  });

  useEffect(() => {
    if (withdrawalDetails.mobileNo.length !== 9) {
      setWithdrawalDetails(details => ({ ...details, username: '' }));
      setShowMobileName(() => false);
      return;
    }
    handleMobileValidation();
  }, [withdrawalDetails.mobileNo]);

  useEffect(() => {
    if (parseFloat(withdrawalDetails?.amount?.toString() as string) > maxPayoutLimit) {
      feedbackInit({
        message: `Your maximum payout limit is ${formatAmount(maxPayoutLimit)} ${currency} per transaction.`,
        type: 'danger',
        componentLevel: true
      });
    } else {
      closeFeedback();
    }
  }, [withdrawalDetails.amount]);

  useEffect(() => {
    let message = '';
    if (!isValidMobileNumber(withdrawalDetails.mobileNo, currency)) {
      if (currency === 'XOF') {
        message = 'Your mobile number should be 10 digits.';
      } else {
        message = 'Your mobile number should be 9 digits.';
      }
    } else {
      closeFeedback();
      return;
    }

    feedbackInit({
      message,
      type: 'warning',
      componentLevel: true
    });
  }, [withdrawalDetails.mobileNo, currency]);

  const isValidMobileNumber = (mobileNo: string, currency: string) => {
    if (currency === 'XOF') return mobileNo.length <= 10;
    return mobileNo.length <= 9;
  };

  const handleMobileValidation = async () => {
    try {
      if (showMobileName) setShowMobileName(false);
      await validateMobileNumber(withdrawalDetails, setWithdrawalDetails);
      setShowMobileName(true);
    } catch (e) {
      feedbackInit({
        message: (e as { message: string }).message || 'An error occurred while validating mobile number',
        type: 'danger',
        componentLevel: true
      });
    }
  };

  const validateMobileNumber = async (
    withdrawalDetails: WithdrawalDetails,
    setWithdrawalDetails: (value: React.SetStateAction<WithdrawalDetails>) => void
  ) => {
    const phoneNumber = `${countryMobileCode[currency]}${withdrawalDetails.mobileNo}`;
    const mobileMoneyCode = withdrawalDetails.mobileMoneyCode;
    const data = { mobileMoneyCode, phoneNumber, currency };

    if (['GHS'].includes(currency)) {
      try {
        setWithdrawalDetails(details => ({ ...details, bankEnquiry: { visible: true, message: 'Verifying mobile number...' } }));
        const { data: mobileDetails } = await mobileEnquiry(data);
        setWithdrawalDetails(details => ({
          ...details,
          isValidated: true,
          username: mobileDetails?.account_name || ''
        }));
        if (!mobileDetails?.account_name) {
          setAccountNameDisabled(false);
        }
      } catch (error) {
        logError(error);
        setAccountNameDisabled(false);
      }
    }
  };

  return {
    validateMobileNumber,
    isValidMobileNumber,
    withdrawalDetails,
    setWithdrawalDetails,
    showMobileName,
    setShowMobileName,
    handleMobileValidation,
    accountNameDisabled
  };
}
