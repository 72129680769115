/* eslint-disable no-nested-ternary */
import React, { useState } from 'react';
import { useQuery } from 'react-query';
import { Route, Switch } from 'react-router-dom';

import { useSearchQuery } from '+hooks';
import useFeedbackHandler from '+hooks/feedbackHandler';
import APIRequest from '+services/api-services';
import useStore from '+store';
import {
  capitalize,
  filteredOutObjectProperty,
  getDate,
  getDateAndTime,
  getTime,
  history,
  logBreadCrumb,
  logError,
  switchCountry,
  switchStatus
} from '+utils';
import { breadCrumbEvents } from '+utils/bugsnag-events';

import Copyable from '../Shared/Copyable';
import SearchComponent from '../Shared/Search';
import Table from '../Shared/Table';
import TabSwitch from '../Shared/TabSwitch';
import ToolTip from '../Shared/Tooltip';
import VirtualAccountsFilter from './Shared/VirtualAccountsFilter';
import VirtualAccountDetails from './VirtualAccountDetails';
import VirtualAccountHoldersDetails from './VirtualAccountHoldersDetails';
import UpgradeRequestModal from './VirtualAccountsModal/UpgradeRequestModal';

import InfoIcon from '+assets/img/dashboard/information-button.svg';

import './index.scss';

const api = new APIRequest(process.env.REACT_APP_PUBLIC_MERCHANT_MIDDLEWARE_API_BASE);

const VirtualAccounts = () => {
  const searchQuery = useSearchQuery();
  const paginationPage = searchQuery.value.page || '1';
  const limit = searchQuery.value.limit || '10';
  const activeTab = searchQuery.value.tab || 'Account Holder';
  const activeCurrency = searchQuery.value.currency || 'NGN';
  const sortingParams = {
    ...filteredOutObjectProperty(searchQuery.value, ['currency', 'page', 'tab', 'limit'])
  };
  const { feedbackInit } = useFeedbackHandler();
  const buffer = [];
  const anyLoading = {};
  const refetchRefund = {};
  const getTabDetails = tab => buffer.find(data => data.type === tab);
  const tabs = ['Account Holder', 'Account Number', 'Upgrade Request'];
  const [openModal, setOpenModal] = useState(false);
  const [reference, setReference] = useState('');
  const MERCHANT_ENV = useStore(store => store.merchantEnv);
  const availableCurrencies = useStore(storedState => storedState.availableCurrencies);
  const virtualBanksCurrencies = ['NGN', 'USD', 'GBP', 'EUR'];

  const switchAPI = {
    'Account Number': () => api.getVirtualBankAccounts(paginationPage, limit, { ...sortingParams, currency: activeCurrency }),
    'Account Holder': () => api.getAllVirtualBankAccountHolders(paginationPage, limit, sortingParams),
    'Upgrade Request': () => api.upgradeVirtualAccountRequests(paginationPage, limit, sortingParams)
  };

  tabs.forEach(tab => {
    const { data, isFetching, refetch } = useQuery(
      [`${tab.toUpperCase()}`, MERCHANT_ENV, activeCurrency, paginationPage, sortingParams],
      switchAPI[tab],
      {
        keepPreviousData: true,
        onError: error => {
          logError(error);
          feedbackInit({
            message: `There has been an error getting your ${tab}.`,
            type: 'danger'
          });
        },
        enabled: activeTab === tab
      }
    );
    anyLoading[tab] = isFetching;
    refetchRefund[tab] = refetch;
    buffer.push({ type: tab, ...data });
  });

  const getClassName = () => {
    if (['Account Holder', 'Account Number'].includes(activeTab)) return '--vba-account-holder';
    return '--vba-upgrade-request';
  };

  const getTypes = () => {
    if (activeTab === 'Account Number') return 'account_number';
    if (activeTab === 'Upgrade Request') return 'upgrade_request';
    return 'account_holder';
  };

  const redirectOnClick = (route, redirectReference) => {
    history.push(`/dashboard/virtual-accounts/${route}/${redirectReference}`);
  };

  const tableContent = () => {
    const activeList = getTabDetails(activeTab);
    return activeList?.data?.map(each => {
      return (
        <React.Fragment className={`div-table ${getClassName()} --row`} key={each?.reference}>
          {activeTab === 'Account Holder' && (
            <div
              onClick={() => {
                redirectOnClick('holders', each?.reference);
                logBreadCrumb({
                  event: breadCrumbEvents.virtualAccounts.tableClicked(activeCurrency),
                  data: { tab: activeTab, currency: activeCurrency }
                });
              }}
              onKeyDown={() => {
                redirectOnClick('holders', each?.reference);
                logBreadCrumb({
                  event: breadCrumbEvents.virtualAccounts.tableClicked(activeCurrency),
                  data: { tab: activeTab, currency: activeCurrency }
                });
              }}
              role="button"
              tabIndex={0}
              className={`div-table ${getClassName()} --row`}
            >
              <div>
                <span className="body-row-header">Status:</span>
                <span className={`status-pill smaller ${switchStatus(each?.status)}`} />
                <span>{capitalize(each?.status)}</span>
              </div>
              <div>
                <span className="body-row-header">Account Holder:</span>
                <span>
                  <span style={{ fontWeight: 600, color: '#414F5F' }}>{`${each?.first_name} ${each.last_name}`}</span>
                  {'  '}
                  <span style={{ fontWeight: 500, color: '#A9AFBC' }}>{each.email || 'Not Available'}</span>
                </span>
              </div>
              <div>
                <span className="body-row-header">Type:</span>
                <span>{capitalize(each?.account_type)}</span>
              </div>
              <div>
                <span className="body-row-header">Country:</span>
                <span>{switchCountry(each?.nationality)}</span>
              </div>
              <div>
                <span className="body-row-header">Date Created:</span>
                <span style={{ color: '#414F5F' }}>{`${getDate(each.created_at)}`}</span>,{' '}
                <span style={{ fontWeight: 400, color: '#A9AFBC' }}>{`${getTime(each.created_at)}`}</span>
              </div>
            </div>
          )}
          {activeTab === 'Account Number' && (
            <div
              role="button"
              tabIndex={0}
              className={`div-table ${getClassName()} --row`}
              onClick={() => redirectOnClick('details', each?.korapay_reference)}
              onKeyDown={() => redirectOnClick('details', each?.korapay_reference)}
            >
              <div>
                <span className="body-row-header">Status:</span>
                <span className={`status-pill smaller ${switchStatus(each?.status)}`} />
                <span>{capitalize(each?.status)}</span>
              </div>
              <div
                onClick={() => redirectOnClick('details', each?.korapay_reference)}
                onKeyDown={() => redirectOnClick('details', each?.korapay_reference)}
                role="button"
                tabIndex={0}
              >
                <span className="body-row-header">Account Number/Bank:</span>
                <span>
                  <span style={{ fontWeight: 600, color: '#414F5F' }}>{each?.account_number}</span> - {each?.bank_name || 'Not Available'}
                </span>
              </div>
              {!['GBP', 'EUR', 'USD'].includes(activeCurrency) && (
                <div>
                  <span className="body-row-header">Account Name:</span>
                  <span>{each?.account_name || 'Not available'}</span>
                </div>
              )}
              {['GBP', 'EUR', 'USD'].includes(activeCurrency) && (
                <div>
                  <span className="body-row-header">Tier:</span>
                  <span>{`Tier ${each?.tier}` || 'Not available'}</span>
                  {each.pending_upgrade_request && (
                    <ToolTip
                      classname="upgrade-tier-indication"
                      image={InfoIcon}
                      message="This account has a pending upgrade request"
                      type="upgrade-tire-indicator"
                    />
                  )}
                </div>
              )}
              <div>
                <span className="body-row-header">Account Reference:</span>
                <span>
                  <Copyable text={each?.account_reference} showOnHover />
                </span>
              </div>
              <div>
                <span className="body-row-header">Date Created:</span>
                <span>{`${getDateAndTime(each?.created_at)}`}</span>
              </div>
            </div>
          )}
          {activeTab === 'Upgrade Request' && (
            <div
              onClick={() => {
                setReference(each.reference);
                setOpenModal(true);
              }}
              onKeyDown={() => {
                setReference(each.reference);
                setOpenModal(true);
              }}
              role="button"
              tabIndex={0}
              className={`div-table ${getClassName()} --row`}
            >
              <div>
                <span className="body-row-header">Status:</span>
                <span className={`status-pill smaller ${switchStatus(each?.status)}`} />
                <span>{capitalize(each?.status)}</span>
              </div>
              <div>
                <span className="body-row-header">Account Number/Bank:</span>
                <span>
                  <span style={{ fontWeight: 600, color: '#414F5F' }}>{each?.virtual_bank_account?.account_number}</span> -{' '}
                  {each?.virtual_bank_account?.bank_name || 'Not Available'}
                </span>
              </div>
              <div>
                <span className="body-row-header">Account Name:</span>
                <span>{`${each?.account_holder?.first_name} ${each?.account_holder?.last_name}`}</span>
              </div>
              <div>
                <span className="body-row-header">Upgrade From:</span>
                <span>{each?.upgrade_from}</span>
              </div>
              <div>
                <span className="body-row-header">Upgrade To:</span>
                <span>{each?.upgrade_to}</span>
              </div>
              <div>
                <span className="body-row-header">Date Created:</span>
                <span>{`${getDateAndTime(each?.created_at)}`}</span>
              </div>
            </div>
          )}
        </React.Fragment>
      );
    });
  };

  const getTableHeaders = () => {
    if (activeTab === 'Account Number') {
      return [
        {
          value: 'Status'
        },
        {
          value: 'Account Number/Bank'
        },
        ...(!['GBP', 'EUR', 'USD'].includes(activeCurrency)
          ? [
              {
                value: 'Account Name'
              }
            ]
          : []),
        ...(['GBP', 'EUR', 'USD'].includes(activeCurrency) ? [{ value: 'Tier' }] : []),
        {
          value: 'Account Reference'
        },
        {
          value: 'Date Created'
        }
      ];
    }
    if (activeTab === 'Account Holder') {
      return [
        {
          value: 'Status'
        },
        {
          value: 'Account Holder'
        },
        {
          value: 'Type'
        },
        {
          value: 'Country'
        },
        {
          value: 'Date Created'
        }
      ];
    }
    return [
      {
        value: 'Request Status'
      },
      {
        value: 'Account Number/Bank'
      },
      {
        value: 'Account Holder'
      },
      {
        value: 'Upgrade From'
      },
      {
        value: 'Upgrade To'
      },
      {
        value: 'Date Requested'
      }
    ];
  };

  return (
    <>
      <div className="row">
        <div className="col-sm-12">
          <div className="payment-link-summary">
            <div className="heading-box-mmd">
              <h4 className="form-header payment-link-header">Local Virtual Accounts for Global Businesses</h4>
              <p className="form-desc payment-desc">
                Expand your business globally and receive payments just like a local. Set up virtual accounts to receive payments in
                different currencies from all over the world and simplify your payment processes.
                <a
                  href="https://developers.korapay.com/docs/virtual-accounts"
                  target="_blank"
                  style={{ marginLeft: '0.3rem' }}
                  rel="noreferrer"
                >
                  Learn more.
                </a>
                .
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="os-tabs-controls virtual-accounts">
        <ul className="nav">
          <li className="nav-item">
            <h4 className="element-header audit__header d-flex align-items-center">Explore By:</h4>
          </li>
        </ul>
        <ul className="nav nav-pills smaller d-md-flex">
          <TabSwitch
            options={tabs}
            activeTab={activeTab}
            setTab={value => searchQuery.setQuery({ tab: value })}
            className="ml-3"
            id="virtual_accounts_tab"
          />
        </ul>
      </div>
      <div className="content-i">
        <div className="row">
          <div className="col-sm-12">
            <div className="element-wrapper">
              <div
                className="os-tabs-controls os-tabs-complex settlement-tabs"
                style={{
                  flexDirection: 'column',
                  alignItems: 'flex-start',
                  gap: '20px',
                  marginRight: '0px'
                }}
              >
                {activeTab === 'Account Number' && (
                  <ul className="nav nav-tabs" style={{ width: '100%' }}>
                    {(availableCurrencies || ['NGN']).map(currency => {
                      return (
                        <li className="nav-item" key={currency}>
                          <button
                            type="button"
                            data-toggle="tab"
                            onClick={() => searchQuery.setQuery({ currency })}
                            className={`nav-link  ${activeCurrency === currency && 'active'}`}
                            disabled={!virtualBanksCurrencies.includes(currency)}
                          >
                            <span className="tab-label">{currency}</span>
                          </button>
                        </li>
                      );
                    })}
                  </ul>
                )}
                <div style={{ width: '100%' }}>
                  <VirtualAccountsFilter type={getTypes()} totalCount={getTabDetails(activeTab)?.paging?.total_items} />
                </div>
              </div>
              <Table
                tableClassName={getClassName()}
                headings={getTableHeaders()}
                hasPagination
                loading={anyLoading[activeTab]}
                current={getTabDetails(activeTab)?.paging?.current}
                totalItems={getTabDetails(activeTab)?.paging?.total_items}
                limitAction={c => searchQuery.setQuery({ limit: String(c) })}
                pageSize={getTabDetails(activeTab)?.paging?.page_size}
                actionFn={c => searchQuery.setQuery({ page: String(c) })}
                annotation="accounts"
                emptyStateHeading="No virtual bank accounts yet"
                emptyStateMessage="You currently have not created any virtual bank accounts"
                tableWrapperClassName="vba-container sub"
              >
                {tableContent()}
              </Table>
            </div>
          </div>
        </div>
      </div>
      {openModal && activeTab === 'Upgrade Request' && <UpgradeRequestModal close={() => setOpenModal(false)} reference={reference} />}
    </>
  );
};

export default function VirtualBankAccounts() {
  return (
    <div className="virtual-accounts__container">
      <Switch>
        <Route exact path="/dashboard/virtual-accounts" component={VirtualAccounts} />
        <Route path="/dashboard/virtual-accounts/details/:id">
          <VirtualAccountDetails />
        </Route>
        <Route path="/dashboard/virtual-accounts/holders/:id">
          <VirtualAccountHoldersDetails />
        </Route>
      </Switch>
    </div>
  );
}
