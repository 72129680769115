import { useState } from 'react';
import { useQuery } from 'react-query';
import APIRequest from '+services/api-services';
import { IUseIpWhiteListParams } from '+types/tips-and-guide';

const apiRequest = new APIRequest(process.env.REACT_APP_MERCHANT_MIDDLEWARE_API_BASE);

export default function useIpWhiteList({ onError, onSuccess, key = 'GET_IP_WHITELIST', enabled }: IUseIpWhiteListParams = {}) {
  const [ipEnabled, setIpEnabled] = useState<boolean | null>(null);

  const result = useQuery([key], () => apiRequest.fetchIPs(), {
    enabled,
    onSuccess: data => {
      onSuccess?.();
      if (data.data?.active) {
        setIpEnabled(true);
      } else {
        setIpEnabled(false);
      }
    },
    onError
  });

  return { ...result, ipEnabled };
}
