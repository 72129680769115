/* eslint-disable react/jsx-props-no-spreading */
import { useEffect, useState } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useFormik } from 'formik';

import Modal from '+dashboard/Shared/Modal';
import { useFeedbackHandler } from '+hooks';
import APIRequest from '+services/api-services';
import { formatMerchantName, logError, specialisedDate } from '+utils';
import useStore from '+store';

import { CardAccessRequestStageType, CountriesDataListType, IAccessRequestModal, IError, StatesDictType } from '../types';
import { initialValues, requestAccessOptions, stageValidationOptions } from './accessRequestModalData';
import CustomerCardRequestForm from './CustomerCardRequestForm';
import KycForm from './KycForm';
import ReservedCardRequestForm from './ReservedCardRequestForm';

const api = new APIRequest();
const publicApi = new APIRequest(process.env.REACT_APP_PUBLIC_MERCHANT_MIDDLEWARE_API_BASE);

const stages: Record<CardAccessRequestStageType, CardAccessRequestStageType> = {
  INITIAL: 'INITIAL',
  BVN: 'BVN',
  RESERVED_CARDS: 'RESERVED_CARDS',
  CUSTOMER_CARDS: 'CUSTOMER_CARDS'
} as const;

function AccessRequestModal({ onClose, gotoStage = stages.INITIAL }: IAccessRequestModal) {
  const [stage, setStage] = useState<CardAccessRequestStageType>(gotoStage);
  const [option, setOption] = useState<CardAccessRequestStageType>(stages.RESERVED_CARDS);
  const [bvnIsVerified, setBvnIsVerified] = useState(false);
  const [countriesList, setCountriesList] = useState([]);
  const [statesDictionary, setStatesDictionary] = useState<StatesDictType>({});
  const { defaultMerchant, cardIssuanceCardAccess } = useStore.getState();
  const [firstName, lastName] = formatMerchantName(defaultMerchant.name);
  const { feedbackInit, closeFeedback } = useFeedbackHandler();
  const queryClient = useQueryClient();

  useEffect(() => {
    if (stage === stages.RESERVED_CARDS && cardIssuanceCardAccess?.validation.status !== 'success') {
      setStage(stages.BVN);
    }
  }, [stage, cardIssuanceCardAccess?.validation.status]);

  const cleanupForm = () => {
    resetForm({ values: initialValues });
  };

  const goToPrevStageOrClose = () => {
    if (stage === stages.INITIAL) {
      cleanupForm();
      onClose();
    }

    if ([stages.BVN, stages.CUSTOMER_CARDS].includes(stage)) {
      setBvnIsVerified(false);
      cleanupForm();
      setStage(stages.INITIAL);
    }

    if (stage === stages.RESERVED_CARDS) {
      if (cardIssuanceCardAccess?.validation.status === 'success') setStage(stages.INITIAL);
      else setStage(stages.BVN);
    }
  };

  const handleOptionChange = (value: CardAccessRequestStageType) => setOption(value);

  const createStatesDictionary = (listOfCountriesData: Array<CountriesDataListType>) => {
    const newDictionary: StatesDictType = listOfCountriesData?.reduce((accum, next) => ({ ...accum, [next.iso2]: next.states }), {});
    setStatesDictionary(newDictionary);
  };

  const {
    values,
    dirty,
    isValid,
    errors,
    touched,
    handleBlur,
    setTouched,
    submitForm,
    getFieldProps,
    setFieldValue,
    setFieldError,
    setFieldTouched,
    resetForm
  } = useFormik({
    initialValues,
    validate: stageValidationOptions[stage],
    onSubmit: async () => {
      switch (stage) {
        case stages.INITIAL:
          setStage(option);
          setTouched({});
          break;
        case stages.BVN:
          if (bvnIsVerified) {
            setStage(stages.RESERVED_CARDS);
            setTouched({});
          } else {
            await handleBvnVerification();
          }
          break;
        case stages.RESERVED_CARDS:
          await requestReservedCards();
          break;
        case stages.CUSTOMER_CARDS:
        default:
          await requestCustomerCards();
          break;
      }
    }
  });

  useQuery('COUNTRIES_AND_STATES', () => api.fetchCountries(undefined, { includeStates: true, forCardPayment: undefined }), {
    onSuccess: data => {
      const options = data?.data.map((item: { name: string; iso2: string; states: Array<string> }) => ({
        value: item.iso2,
        label: item.name
      }));
      setCountriesList(options);
      createStatesDictionary(data?.data);
    }
  });

  const { mutateAsync: mutateBvnVerification } = useMutation(publicApi.validateBvn, {
    onSuccess: data => {
      setBvnIsVerified(true);
      queryClient.invalidateQueries('VIRTUAL_CARD_ACCESS_STATUS');
      feedbackInit({
        componentLevel: true,
        message: data?.message || 'Your BVN has been successfully verified',
        type: 'success'
      });
      setTimeout(() => {
        closeFeedback();
      }, 1500);
    },
    onError: (e: IError) => {
      logError(e);
      setFieldError('bvn', e.response.data?.message);
      if (e.response?.data?.message?.includes('BVN already validated')) setBvnIsVerified(true);
      setFieldTouched('bvn', true, false);
    }
  });

  const { mutateAsync: mutateCustomerCards } = useMutation(publicApi.requestCardAccess, {
    onSuccess: () => {
      queryClient.invalidateQueries('VIRTUAL_CARD_ACCESS_STATUS');
      cleanupForm();
    },
    onError: (e: IError) => {
      logError(e);
      const errMsg = e.response?.data?.message;
      feedbackInit({
        componentLevel: true,
        message: errMsg || 'We were unable to complete your request. Please try again',
        type: 'danger'
      });
    }
  });

  const { mutateAsync: mutateReservedCards } = useMutation(publicApi.requestCardAccess, {
    onSuccess: () => {
      queryClient.invalidateQueries('VIRTUAL_CARD_ACCESS_STATUS');
      cleanupForm();
    },
    onError: (e: IError) => {
      logError(e);
      const errMsg = e.response?.data?.message;
      feedbackInit({
        componentLevel: true,
        message: errMsg || 'We were unable to complete your request. Please try again',
        type: 'danger'
      });
    }
  });

  let payload = {};

  const handleBvnVerification = async () => {
    payload = {
      first_name: values.bvn_firstName,
      last_name: values.bvn_lastName,
      date_of_birth: specialisedDate(values.dob, 'YYYY-MM-DD'),
      bvn: values.bvn
    };
    await mutateBvnVerification(payload);
  };

  const requestReservedCards = async () => {
    payload = {
      currency: 'USD',
      category: 'reserved',
      monthly_payment_volume: values.reservedCardMonthlyPaymentValue,
      subscription_plan: values.subscriptionPlan,
      category_data: {
        address: {
          street: values.street,
          city: values.city,
          state: values.state,
          country: values.country,
          zip_code: values.postalCode
        },
        use_case: values.useCase,
        ...(values.description && { description: values.description }),
        first_name: firstName,
        last_name: lastName
      }
    };
    await mutateReservedCards(payload);
  };

  const requestCustomerCards = async () => {
    payload = {
      currency: 'USD',
      category: 'customer',
      monthly_payment_volume: values.customerCardMonthlyPaymentValue,
      subscription_plan: values.subscriptionPlan,
      category_data: {
        pci_dss_level: values.pciDssLevel
      }
    };
    await mutateCustomerCards(payload);
  };

  const renderInitialForm = () => (
    <div className="filter-body row">
      <ul className="form-group col-12 stack-md">
        {requestAccessOptions.map(({ title, value, description }) => {
          const isCurrentlyActive = value === option;
          return (
            <li key={value} className="stack-lg">
              <button
                type="button"
                onClick={() => handleOptionChange(value)}
                aria-expanded={isCurrentlyActive}
                aria-controls={`content-${value}`}
                className="block width-full request-access-btn"
                data-active={`${isCurrentlyActive}`}
              >
                <span hidden={!isCurrentlyActive} aria-hidden="true" className="marker--blue" />
                <span>{title}</span>
              </button>
              <div
                id={`content-${value}`}
                role="region"
                aria-labelledby={`label-${value}`}
                hidden={!isCurrentlyActive}
                className="feedback request-access-content"
                style={{ margin: 0 }}
              >
                <p id={`label-${value}-content`} style={{ color: 'hsla(212, 19%, 31%, 1)', fontWeight: 300 }}>
                  {description}
                </p>
              </div>
            </li>
          );
        })}
      </ul>
    </div>
  );

  const modalPropOptions: Partial<Record<CardAccessRequestStageType | 'SHARED', Record<string, any>>> = {
    SHARED: {
      close: () => {
        cleanupForm();
        onClose();
        queryClient.invalidateQueries('VIRTUAL_CARD_ACCESS_STATUS');
      },
      firstButtonAction: goToPrevStageOrClose,
      secondButtonAction: submitForm,
      secondButtonActionIsTerminal: false,
      completedHeading: 'Request submitted',
      completedDescription: 'Your request has been received and will be reviewed by the team shortly.'
    },
    [stages.INITIAL]: {
      heading: 'Issue virtual cards',
      description: 'Select your preferred virtual card issuing option below to get started.',
      secondButtonDisable: !option,
      content: renderInitialForm(),
      secondButtonText: 'Next'
    },
    [stages.CUSTOMER_CARDS]: {
      heading: <div className="text-lg m-0">Issue virtual cards to your customers</div>,
      description: (
        <p className="text-md m-0" style={{ color: 'hsla(212, 19%, 31%, 1)' }}>
          Tell us more about your business to help us understand how you would like to use the Card Issuance service.
        </p>
      ),
      content: <CustomerCardRequestForm formikBag={{ getFieldProps, errors, touched, values }} />,
      firstButtonText: 'Back',
      hideFirstButton: gotoStage === stages.CUSTOMER_CARDS,
      secondButtonText: 'Submit',
      secondButtonDisable: !(dirty && isValid),
      secondButtonActionIsTerminal: true
    },
    [stages.BVN]: {
      heading: <div className="text-lg m-0">Get reserved virtual cards for your business.</div>,
      description: (
        <p className="text-md m-0" style={{ color: 'hsla(212, 19%, 31%, 1)' }}>
          Tell us more about how you would like to use the Card Issuance service.
        </p>
      ),
      content: (
        <KycForm merchantName={defaultMerchant.name} formikBag={{ values, errors, touched, getFieldProps, setFieldValue, handleBlur }} />
      ),
      firstButtonText: 'Back',
      hideFirstButton: gotoStage === stages.RESERVED_CARDS,
      secondButtonText: bvnIsVerified ? 'Next' : 'Verify',
      secondButtonDisable: !(dirty && isValid),
      maxHeight: '720px'
    },
    [stages.RESERVED_CARDS]: {
      heading: <div className="text-lg m-0">Get reserved virtual cards for your business.</div>,
      description: (
        <p className="text-md m-0" style={{ color: 'hsla(212, 19%, 31%, 1)' }}>
          Tell us more about how you would like to use the Card Issuance service.
        </p>
      ),
      content: (
        <ReservedCardRequestForm
          merchantName={[firstName, lastName]}
          countriesList={countriesList}
          statesList={statesDictionary}
          formikBag={{ values, errors, touched, getFieldProps, setFieldValue, handleBlur }}
        />
      ),
      hideFirstButton: gotoStage === stages.RESERVED_CARDS,
      firstButtonText: 'Back',
      secondButtonActionIsTerminal: true,
      secondButtonText: 'Submit',
      secondButtonDisable: !(dirty && isValid),
      maxHeight: '720px'
    }
  };

  const modalProps = {
    ...modalPropOptions.SHARED,
    ...modalPropOptions[stage]
  };

  return (
    <div>
      <Modal {...modalProps} />
    </div>
  );
}

export default AccessRequestModal;
