import React, { useEffect, useState } from 'react';
import DatePicker from 'react-datepicker';
import { IBulkPayoutFilterProps } from '+types/bulk-payouts';
import { cleanInput, filterOutEmptyValues } from '+utils';
import 'react-datepicker/dist/react-datepicker.css';
import arrowRight from '+assets/img/dashboard/arrow-right.svg';
import calendar from '+assets/img/dashboard/calendar.svg';
import search from '+assets/img/dashboard/search-thin.svg';

const BulkPayoutFilter = ({
  openExportModal,
  handleFilterQuery,
  totalCount,
  status,
  activeCurrency,
  userAccess,
  previousFilter,
  setPreviousFilter,
  dropdownOptions,
  disableFilter = false
}: IBulkPayoutFilterProps) => {
  const [queryKeyword, setQueryKeyword] = useState('');
  const [clearFilter, setClearFilter] = useState(false);
  const [startDate, setStartDate] = useState<Date | null>(null);
  const [endDate, setEndDate] = useState<Date | null>(null);
  const [entriesType, setEntriesType] = useState('');
  const [transactionStatus, setTransactionStatus] = useState(''); 

  useEffect(() => {
    handleClearFilter();
  }, [activeCurrency, status]);

  const handleClearFilter = () => {
    setClearFilter(false);
    setQueryKeyword('');
    setStartDate(null);
    setEndDate(null);
    handleFilterQuery({});
  };

  const filterTransactions = async () => {
    const queryParams = {
      keyword: queryKeyword,
      status: [transactionStatus]
    };
    if (status !== 'complete') queryParams['entriesType'] = entriesType;
    if (startDate) queryParams['dateFrom'] = startDate.toISOString().slice(0, 10);
    if (endDate) queryParams['dateTo'] = endDate.toISOString().slice(0, 10);
    await handleFilterQuery(filterOutEmptyValues(filterOutEmptyValues(queryParams)));
    if (Object.keys(filterOutEmptyValues(queryParams)).length > 0) {
      setClearFilter(true);
    }
  };
  return (
    <section className="bulk-payout-filter">
      <div className="bulk-payout-filter__top">
        <div>
          <span>
            {totalCount} {totalCount === 1 ? 'entry' : 'entries'} {(clearFilter || previousFilter) && '(filtered results)'}
          </span>
          {(clearFilter || previousFilter) && (
            <>
              <span className="divider-sm" />
              <button type="button" onClick={() => handleClearFilter()}>
                {' '}
                <svg xmlns="http://www.w3.org/2000/svg" width="21" height="20" fill="none" viewBox="0 0 21 20" style={{ width: '1rem' }}>
                  <path
                    fill="#AABDCE"
                    d="M3.426 2.926c3.902-3.9 10.247-3.9 14.149 0 3.9 3.901 3.9 10.248 0 14.15A9.976 9.976 0 0110.5 20a9.975 9.975 0 01-7.074-2.924c-3.901-3.902-3.901-10.249 0-14.15zM6.374 12.95a.833.833 0 101.179 1.178L10.5 11.18l2.946 2.948a.835.835 0 001.18-1.18l-2.947-2.946 2.947-2.948a.833.833 0 10-1.179-1.179L10.5 8.822 7.553 5.874a.833.833 0 10-1.18 1.18L9.322 10l-2.947 2.948z"
                  />
                </svg>
                &nbsp;
                <span>Clear</span>
              </button>
            </>
          )}
        </div>
        <div>
          {['view', 'export', 'manage'].includes(userAccess?.bulk_payouts) && (
            <button
              type="button"
              className="btn btn-secondary"
              onClick={openExportModal}
              style={{ background: 'none', border: 'none', color: '#2376F3', paddingRight: 0 }}
            >
              <i className="os-icon os-icon-arrow-up-right" />
              <span>Export</span>
            </button>
          )}
        </div>
      </div>

      <div className="settlement-filter__search-w filter-section">
        <div className="element-search-content filter-body w-100">
          <div className="form-group position-relative filter-object filter-object-xl flex-grow-1 w-auto --no-max-width --search-container">
            <img src={search} alt="search icon" aria-hidden />
            <input
              type="search"
              className="form-control"
              placeholder="Search entries"
              value={queryKeyword}
              onChange={e => setQueryKeyword(cleanInput(e.target.value))}
              style={{ border: '1.5px solid #EAF2FE' }}
            />
          </div>

          {['complete'].includes(status) ? (
            <div className="form-group filter-object filter-object-ssm w-auto --no-max-width">
              <select
                name="transaction-status"
                className="form-control"
                onChange={e => setTransactionStatus(e.target.value)}
                value={transactionStatus}
                style={{ border: '1.5px solid #EAF2FE', width: '150px' }}
                data-testid="filter-select"
              >
                {dropdownOptions?.map(d => (
                  <option key={d.label} value={d.value}>
                    {d.label}
                  </option>
                ))}
              </select>
            </div>
          ) : (
            <div className="form-group filter-object filter-object-ssm w-auto --no-max-width">
              <select
                name="type"
                className="form-control"
                onChange={e => setEntriesType(e.target.value)}
                value={entriesType}
                style={{ border: '1.5px solid #EAF2FE', width: '150px' }}
                data-testid="filter-select"
              >
                {dropdownOptions?.map(d => (
                  <option key={d.label} value={d.value}>
                    {d.label}
                  </option>
                ))}
              </select>
            </div>
          )}

          {!['canceled', 'draft'].includes(status) && (
            <div
              className="form-group filter-object d-flex filter-object-sm w-auto"
              style={{ '--calendar-image': `url("${calendar}")`, minWidth: '300px' }}
            >
              <DatePicker
                selected={startDate}
                dateFormat="dd-MM-yyyy"
                popperPlacement="bottom-end"
                style={{ borderRadius: '0px 4px 4px 0px', border: '0.5px solid red' }}
                className="form-control date-select pl-4 date-picker"
                onChange={date => setStartDate(date)}
                placeholderText="From"
                calendarClassName="custom-datepicker"
              />
              <DatePicker
                selected={endDate}
                dateFormat="dd-MM-yyyy"
                popperPlacement="bottom-end"
                style={{ borderRadius: '0px 4px 4px 0px' }}
                className="form-control date-select pl-4 date-picker"
                minDate={startDate || null}
                onChange={date => setEndDate(date)}
                placeholderText="To"
                calendarClassName="custom-datepicker"
              />
            </div>
          )}
          <button
            aria-label="filter transactions"
            type="button"
            className="settlement-filter__filter-button"
            onClick={() => filterTransactions()}
            aria-controls="filtertransactions"
            disabled={disableFilter}
          >
            <img src={arrowRight} alt="arrow right icon" aria-hidden />
          </button>
        </div>
      </div>
    </section>
  );
};

export default BulkPayoutFilter;
