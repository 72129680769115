import { TRenderSvg } from './types';

const CreditCard: TRenderSvg = ({
  width = '24',
  height = '24',
  viewBox = '0 0 24 24',
  titleId,
  title,
  fill,
  descriptionId = '',
  description = '',
  className = ''
}) => (
  <svg
    width={width}
    height={height}
    viewBox={viewBox}
    fill={fill}
    xmlns="http://www.w3.org/2000/svg"
    aria-labelledby={`${titleId} ${descriptionId}`}
    aria-hidden={!!title}
    className={className}
  >
    <title id={titleId}>{title}</title>
    <desc id={descriptionId}>{description}</desc>
    <path
      d="M21 4H3C1.89543 4 1 4.89543 1 6V18C1 19.1046 1.89543 20 3 20H21C22.1046 20 23 19.1046 23 18V6C23 4.89543 22.1046 4 21 4Z"
      stroke="white"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="transparent"
    />
    <path d="M1 10H23" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);

export { CreditCard };
