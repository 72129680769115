import React, { useEffect, useState } from 'react';
import { useMutation } from 'react-query';

import Slider from '+containers/Dashboard/Shared/Slider';
import Table from '+containers/Dashboard/Shared/Table';
import { useBanks, useFeedbackHandler } from '+hooks';
import APIRequest from '+services/api-services';
import useStore from '+store';
import { BankT, BulkDataT, BulkDraftDataT, IBulkPayoutInitTableProps, PaginationT, PagingT } from '+types/bulk-payouts';
import { ErrorResponse } from '+types/common';
import { formatAmount } from '+utils';

import BulkPayoutConfirmModal from './BulkPayoutConfirmModal';
import BulkPayoutEditModal from './BulkPayoutEditModal';
import BulkPayoutFilter from './BulkPayoutFilter';
import { bankTransferTableData, mobileTransferTableData } from './data';
import MobileMoneyEditModal from './MobileMoneyEditModal';

import SuccessIcon from '+assets/img/dashboard/check.png';
import DeleteIcon from '+assets/img/dashboard/delete.svg';
import EditIcon from '+assets/img/dashboard/edit.svg';
import WarningIcon from '+assets/img/dashboard/warning.svg';
import DeletedIcon from '+assets/img/deleted-icon.svg';

const api = new APIRequest(process.env.REACT_APP_PUBLIC_MERCHANT_MIDDLEWARE_API_BASE);

const dropdownOptions = [
  {
    value: 0,
    label: 'All entries'
  },
  {
    value: 1,
    label: 'Entries with errors'
  },
  {
    value: 2,
    label: 'Entries without errors'
  }
];
const BulkPayoutInitTable = ({
  isFetching,
  refetch,
  data,
  permissions,
  reference,
  status,
  payoutValidationStatus,
  payoutType,
  currency
}: IBulkPayoutInitTableProps) => {
  const [pagination, setPagination] = useState<PaginationT>({
    page: 1,
    limit: 10
  });
  const { feedbackInit } = useFeedbackHandler();
  const bulkPayoutDraftData = useStore(state => state.bulkPayoutDraftData);
  const [paging, setPaging] = useState<PagingT>({ total_items: 0, page_size: 1 });
  const [displayedData, setDisplayedData] = useState<Array<BulkDataT>>([]);
  const [tempData, setTempData] = useState<Array<BulkDataT>>([]);
  const [selectedEntry, setSelectedEntry] = useState<BulkDataT>();
  const [newUpdate, setNewUpdate] = useState(false);
  const [editModalVisible, setEditModalVisible] = useState(false);
  const [confimModalState, setConfirmModalState] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);

  const { data: banks } = useBanks(currency);
  const editBulkPayoutMutation = useMutation(
    (editData: { currency: string; payouts: BulkDataT }) => api.editBulkPayout(reference, editData),
    {
      onSuccess: () => {
        const updatedBulkPayoutDraftData = bulkPayoutDraftData?.map((item: BulkDraftDataT) =>
          item.reference === reference ? { ...item, newUpdate: false } : item
        );
        useStore.setState({ bulkPayoutDraftData: updatedBulkPayoutDraftData });
        setNewUpdate(false);
        feedbackInit({
          message: 'Updated entries successfully',
          type: 'success'
        });
        setIsUpdating(false);
        refetch();
      },
      onError: (e: ErrorResponse) => {
        feedbackInit({
          message: e?.response?.data?.message || 'An error occurred while updating entries',
          type: 'danger'
        });
        setIsUpdating(false);
      }
    }
  );

  useEffect(() => {
    const objectForReference = bulkPayoutDraftData?.find((item: BulkDraftDataT) => item.reference === reference);
    if (objectForReference?.data?.length) {
      if (objectForReference?.newUpdate) {
        setTempData(objectForReference?.data);
        setNewUpdate(objectForReference?.newUpdate);
      } else {
        updateBulkPayout(data, false);
      }
    } else {
      setTempData(data);
      useStore.setState({
        bulkPayoutDraftData: [...bulkPayoutDraftData, { data, newUpdate: false, reference, validation: payoutValidationStatus }]
      });
      setNewUpdate(false);
    }
  }, [reference, payoutValidationStatus]);

  useEffect(() => {
    if (tempData) {
      const itemsPerPage = pagination?.limit;
      const totalItems = tempData?.length;
      const totalPages = Math.ceil(totalItems / itemsPerPage);

      setPaging({
        total_items: totalItems,
        page_size: totalPages
      });
    }
  }, [tempData, pagination?.limit]);

  const [isBankTrx, isMobileTrx] = [tempData[0]?.bank_account, tempData[0]?.mobile_money];

  useEffect(() => {
    const { page, limit } = pagination;
    const start = (page - 1) * limit;
    const end = start + limit;
    let newData: Array<BulkDataT> = [];
    if (pagination?.sorterType === 'filter' && pagination?.sortingParams) {
      newData = tempData?.filter((each: BulkDataT) => {
        let matchesReference = true;
        let matchesError = true;

        if (pagination?.sortingParams?.reference) {
          matchesReference = each?.reference?.toLowerCase().includes((pagination.sortingParams?.reference || '').toLowerCase());
        }

        if (pagination.sortingParams?.entriesType !== undefined) {
          if (pagination.sortingParams?.entriesType === '1') {
            matchesError = each?.errors?.length > 0;
          } else if (pagination.sortingParams?.entriesType === '2') {
            matchesError = each?.errors?.length === 0;
          } else {
            matchesError = true;
          }
        }

        return matchesReference && matchesError;
      });
      setPaging({ ...paging, total_items: newData?.length });
    } else {
      newData = tempData;
    }
    setDisplayedData(newData?.slice(start, end));
  }, [pagination, tempData]);

  const getBankName = (code: string) => banks?.data?.find((each: BankT) => [each?.code, each?.slug].includes(code))?.name;

  const tableData = () => {
    return (
      <div>
        {displayedData?.map((each: BulkDataT) => {
          return (
            <div
              className={`div-table --bulk-payout-init-table --row ${each.errors?.length ? 'error' : ''}`}
              key={each?.reference}
              data-testid="bulk-payout-entry"
            >
              <div>
                <span className="body-row-header">Reference:</span>
                <span className="reference-col">
                  <span className="reference-col-icon">
                    <img src={each.errors?.length ? WarningIcon : SuccessIcon} alt="success icon" />
                  </span>

                  <span className="reference-col-text text-ellipsis">{each?.reference}</span>
                </span>
              </div>
              {isBankTrx ? (
                <>
                  <div>
                    <span className="body-row-header">Bank:</span>
                    <span>
                      {
                        each?.bank_account?.bank_name
                        ?? getBankName(each?.bank_account?.bank_slug || each?.bank_account?.bank_code || '')
                        ?? '- -'
                      }
                    </span>
                  </div>
                  <div>
                    <span className="body-row-header">Account No.:</span>
                    <span>
                      <span>{each?.bank_account?.account_number || '- -'}</span>
                    </span>
                  </div>
                  <div>
                    <span className="body-row-header">Account Name:</span>
                    <span className="text-ellipsis">
                      <span>{each?.bank_account?.account_name || '- -'}</span>
                    </span>
                  </div>
                </>
              ) : (
                <>
                  <div>
                    <span className="body-row-header">Operator:</span>
                    <span>
                      <span>{(each?.mobile_money?.operator_slug as string) || '- -'}</span>
                    </span>
                  </div>
                  <div>
                    <span className="body-row-header">Mobile No.:</span>
                    <span>
                      <span>{each?.mobile_money?.mobile_number || '- -'}</span>
                    </span>
                  </div>
                  <div>
                    <span className="body-row-header">Account Name:</span>
                    <span className="text-ellipsis">
                      <span>{each?.customer?.name || '- -'}</span>
                    </span>
                  </div>
                </>
              )}
              <div>
                <span className="body-row-header">Description:</span>
                <span className="text-ellipsis">
                  <span>{each?.narration || '- -'}</span>
                </span>
              </div>
              <div>
                <span className="body-row-header">{`Amount ${currency}:` || '- -'}</span>
                <span>
                  <span>{formatAmount(each?.amount) || '- -'}</span> {currency || ' - -'}
                </span>
              </div>
              <div>
                <button
                  type="button"
                  onClick={() => {
                    setEditModalVisible(true);
                    setSelectedEntry(each);
                  }}
                  data-testid="bulk-payout-edit"
                  disabled={status === 'cancelled'}
                >
                  <img src={EditIcon} alt="Edit" />
                </button>
                <button
                  type="button"
                  onClick={() => {
                    handeAction();
                    setSelectedEntry(each);
                  }}
                  data-testid="bulk-payout-delete"
                  disabled={status === 'cancelled'}
                >
                  <img src={DeleteIcon} alt="Delete" />
                </button>
              </div>
            </div>
          );
        })}
      </div>
    );
  };

  const updateBulkPayout = (newArray: BulkDataT[], newUpdate: boolean) => {
    const updatedBulkPayoutDraftData = bulkPayoutDraftData?.map((item: BulkDraftDataT) =>
      item.reference === reference ? { ...item, data: newArray, newUpdate, validation: payoutValidationStatus } : item
    );
    useStore.setState({ bulkPayoutDraftData: updatedBulkPayoutDraftData });
    setTempData(newArray);
    setNewUpdate(newUpdate);
  };

  const getEntriesWithErrors = () => {
    return tempData?.filter((each: BulkDataT) => each?.errors?.length > 0).length;
  };

  const editBulkPayout = () => {
    setIsUpdating(true);
    const objectForReference = bulkPayoutDraftData?.find((item: BulkDraftDataT) => item.reference === reference);
    if (objectForReference?.data?.length) {
      const formattedEntries = objectForReference?.data?.map((each: BulkDataT) => ({
        amount: each?.amount,
        type: payoutType,
        customer: {
          email: each?.customer?.email || '',
          name: each?.customer?.name || ''
        },
        narration: each?.narration,
        reference: each?.reference,
        ...(isBankTrx && {
          bank_account: {
            account_number: each?.bank_account?.account_number,
            account_name: each?.bank_account?.account_name,
            bank_code: each?.bank_account?.bank_code
          }
        }),
        ...(isMobileTrx && {
          mobile_money: {
            mobile_number: each?.mobile_money?.mobile_number,
            account_name: each?.mobile_money?.account_name,
            operator: each?.mobile_money?.operator_slug
          }
        })
      }));
      editBulkPayoutMutation.mutate({
        currency: currency,
        payouts: formattedEntries
      });
    }
  };

  const checkIfNoErrorInDraft = () => {
    const objectForReference = bulkPayoutDraftData?.find((item: BulkDraftDataT) => item.reference === reference);
    const errors = objectForReference?.data?.filter((item: BulkDataT) => item?.errors?.length);
    return !errors?.length;
  };

  const tableBanner = () => {
    const entriesWithError = getEntriesWithErrors();
    return (
      (entriesWithError || newUpdate) &&
      status !== 'cancelled' && (
        <div className="bulk-payout-table-banner" data-testid="bulk-payout-table-banner">
          {entriesWithError ? (
            <Slider>
              <span>
                Please correct the errors detected on <b>{entriesWithError} entries</b> to continue your bulk payout.
              </span>
            </Slider>
          ) : null}
          {newUpdate && (
            <div className="bulk-payout-table-banner-right">
              <b>New updates on entries</b>
              <button className="refresh-btn" type="button" onClick={editBulkPayout} disabled={isUpdating || !checkIfNoErrorInDraft()}>
                {isUpdating ? (
                  <span className="spinner-border spinner-border-sm" style={{ marginRight: '0.5rem' }} role="status" aria-hidden="true" />
                ) : (
                  'Refresh'
                )}
              </button>
            </div>
          )}
        </div>
      )
    );
  };

  const deleteEntry = () => {
    const newArray = tempData?.filter((each: BulkDataT) => each?.reference !== selectedEntry?.reference);
    setTempData(newArray);
    setSelectedEntry(undefined);
    updateBulkPayout(newArray, true);
  };

  const editEntry = (updatedEntry: BulkDataT) => {
    const index = tempData?.findIndex((each: BulkDataT) => each.reference === updatedEntry?.reference);
    if (index !== -1) {
      const newArray = [...tempData];
      newArray[index] = { ...updatedEntry, errors: [] };
      setTempData(newArray);
      updateBulkPayout(newArray, true);
    }
  };

  const closeConfirmModal = () => {
    setConfirmModalState(false);
    setSelectedEntry(undefined);
  };

  const handeAction = () => {
    setConfirmModalState(true);
  };

  return (
    <section>
      {confimModalState && (
        <BulkPayoutConfirmModal
          heading="Delete this entry?"
          description="Are you sure you want to delete this entry? Deleting this entry will remove it from the list. This action cannot be undone."
          firstButtonText="Cancel"
          secondButtonText="Delete"
          secondButtonAction={deleteEntry}
          completedHeading="Entry deleted"
          completedDescription="The entry/item you selected has been deleted and removed from the list."
          close={closeConfirmModal}
          confirmModal={confimModalState}
          completedImage={DeletedIcon}
        />
      )}
      {editModalVisible && isBankTrx && (
        <BulkPayoutEditModal
          confirmModal={editModalVisible}
          closeModal={setEditModalVisible}
          actionBtn={e => editEntry(e as BulkDataT)}
          editData={selectedEntry as BulkDataT}
          currency={currency || ''}
        />
      )}
      {editModalVisible && isMobileTrx && (
        <MobileMoneyEditModal
          confirmModal={editModalVisible}
          closeModal={setEditModalVisible}
          actionBtn={e => editEntry(e as BulkDataT)}
          editData={selectedEntry as BulkDataT}
          currency={currency || ''}
        />
      )}
      <BulkPayoutFilter
        handleFilterQuery={param => {
          setPagination({ ...pagination, page: 1, sorterType: 'filter', sortingParams: param });
        }}
        openExportModal={() => { }}
        totalCount={payoutValidationStatus ? paging.total_items : 0}
        status="draft"
        activeCurrency="NGN"
        userAccess={permissions?.export}
        previousFilter={false}
        setPreviousFilter={() => { }}
        dropdownOptions={dropdownOptions}
        disableFilter={!payoutValidationStatus}
      />
      <Table
        tableClassName="--bulk-payout-init-table"
        banner={tableBanner()}
        headings={isBankTrx ? bankTransferTableData : mobileTransferTableData}
        hasPagination
        loading={isFetching}
        current={pagination?.page}
        totalItems={payoutValidationStatus ? paging?.total_items : 0}
        pageSize={pagination?.limit || 0}
        actionFn={page => setPagination({ ...pagination, page })}
        limitAction={limit => setPagination({ ...pagination, limit, page: 1 })}
        emptyStateHeading={payoutValidationStatus ? 'No transactions found' : ''}
        emptyStateMessage={
          payoutValidationStatus ? (
            <span>It seems there are no transactions here.</span>
          ) : (
            <span>Entries will show up here once your file has been processed</span>
          )
        }
        tableWrapperClassName="--bulk-payout-init-table"
      >
        {tableData()}
      </Table>
    </section>
  );
};

export default BulkPayoutInitTable;
