import { useQuery } from 'react-query';

import { issuedCardChargebacksFilterField, singleCardTxFilterFields } from '+containers/Dashboard/Issuing/data';
import { getExistingFiltersFromQuery } from '+dashboard/Shared/FilterModal';
import { useFeedbackHandler } from '+hooks';
import APIRequest from '+services/api-services';
import { logError } from '+utils';

import { IError, IssuedCardTabKeyType } from '../types';

type UseFetchIssuedCardTableDataProps = {
  tab: IssuedCardTabKeyType;
  limit: string;
  page: string;
  sortingParams: Record<string, string>;
  cardId: string;
  merchantEnv: string;
};

const publicApi = new APIRequest(process.env.REACT_APP_PUBLIC_MERCHANT_MIDDLEWARE_API_BASE);

export default function useFetchIssuedCardTableData({
  tab,
  limit,
  page,
  sortingParams,
  cardId,
  merchantEnv
}: UseFetchIssuedCardTableDataProps) {
  const { feedbackInit } = useFeedbackHandler();

  const { data: tableData, isFetching, refetch } = useQuery(
    [QueryKeys[tab], limit, page, sortingParams, merchantEnv],
    () => QueryFns[tab]({ ref: cardId, limit, page }),
    {
      onError: (e: IError) => {
        logError(e);
        const message = e.response?.data?.message;
        feedbackInit({
          message,
          type: 'danger',
          action: {
            action: refetch,
            name: 'Try again'
          }
        });
      }
    }
  );

  return {
    tableData,
    isFetching,
    refetch
  };
}

const QueryFns = {
  balance_history: ({ ref, limit, page }: { ref: string; limit: string; page: string }) =>
    publicApi.fetchIssuedCardTransactions(ref, {
      limit,
      page,
      ...getExistingFiltersFromQuery(singleCardTxFilterFields)
    }),
  transactions: ({ ref, limit, page }: { ref: string; limit: string; page: string }) =>
    publicApi.fetchIssuedCardTransactions(ref, {
      limit,
      page,
      ...getExistingFiltersFromQuery(singleCardTxFilterFields)
    }),
  events: ({ ref, limit, page }: { ref: string; limit: string; page: string }) =>
    publicApi.fetchCardEvents(ref, {
      limit,
      page
    }),
  chargebacks: ({ ref, limit, page }: { ref: string; limit: string; page: string }) =>
    publicApi.fetchAllChargebacksForIssuedCard({
      cardId: ref,
      page,
      limit,
      ...getExistingFiltersFromQuery(issuedCardChargebacksFilterField)
    })
};

const QueryKeys = {
  balance_history: 'ISSUED_CARD_BAL_HIST',
  transactions: 'ISSUED_CARD_TRXNS',
  events: 'ISSUED_CARD_EVENTS',
  chargebacks: 'ISSUED_CARD_CHARGEBACKS'
};
