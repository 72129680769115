import FileUpload from '+containers/Dashboard/Shared/FileUpload';
import CustomReactSelect from '+containers/Shared/CustomReactSelect';
import { useReducerState } from '+hooks';
import { ChargebackCategoryType, ChargebackStatusType } from '+types/transaction-types';
import { backwardAmountInput, filterUserInput, formatAmount } from '+utils';

type IssuanceChargebackFormPropType = {
  updateModalState: (args?: any) => void;
  chargebackStatus: ChargebackStatusType;
  warningOnCriteriaFailure: JSX.Element;
  currency: string;
  chargebackAmount: string;
  isDisabled: boolean;
  minChargebackAmount: string;
  maxChargebackAmount: string;
  chargebackCategory: ChargebackCategoryType;
  reasonForChargeback: string;
  evidenceOfFileUpload: string;
  fileName: string;
};

type FormStateType = {
  dropdownIsOpen: boolean;
  fileUploadStatus: 'PENDING' | 'UPLOADING' | 'FAILED';
  fileHasUploaded: boolean;
  selectedOption: { value: ChargebackCategoryType; label: string } | null;
};

const maxFileSize = Number(process.env.REACT_APP_CARD_ISSUANCE_MAX_FILE_SIZE_IN_MB);

const IssuanceChargebackForm = ({
  updateModalState,
  chargebackStatus,
  warningOnCriteriaFailure,
  currency,
  chargebackAmount,
  isDisabled,
  minChargebackAmount,
  maxChargebackAmount,
  reasonForChargeback,
  chargebackCategory,
  evidenceOfFileUpload
}: IssuanceChargebackFormPropType) => {
  const initialFormState = (): FormStateType => ({
    dropdownIsOpen: false,
    fileUploadStatus: 'PENDING',
    fileHasUploaded: false,
    selectedOption: null
  });

  const [state, setState] = useReducerState(initialFormState());

  const chargebackCategories: Array<{ value: ChargebackCategoryType; label: string }> = [
    { value: 'none', label: '- Select chargeback category -' },
    { value: 'fraud', label: 'Fraud' },
    { value: 'consumer_dispute', label: 'Customer Dispute' },
    { value: 'processing_error', label: 'Processing Error Dispute (invalid transaction, invalid currency, duplicate amount)' },
    { value: 'authorization_dispute', label: 'Authorization Dispute (invalid card, blocked card)' }
  ];

  const handleChargebackAmountChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const formattedInput = backwardAmountInput(e.target.value);
    updateModalState({ chargebackAmount: formattedInput as string });
  };

  const handleDescriptionChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const formattedInput = filterUserInput(e.target.value);
    updateModalState({ reasonForChargeback: formattedInput });
  };

  const chargebackCanBeReescalated = ['partially_accepted', 'declined', 'invalid'].includes(chargebackStatus);

  const reactSelectStyles = {
    container: baseStyles => ({
      ...baseStyles,
      ' *': {
        fontSize: '0.85rem'
      },
      ':focus-visible': {
        outlineColor: 'transparent'
      }
    }),
    control: baseStyles => ({
      ...baseStyles,
      backgroundColor: isDisabled || chargebackStatus === 'partially_accepted' ? '#e9ecef' : 'transparent',
      border: '2px solid #dde2ec',
      borderRadius: '4px',
      outlineColor: 'transparent',
      ':hover': {
        borderColor: '#047bf8'
      },
      ':focus': {
        borderColor: '#047bf8'
      }
    }),
    placeholder: baseStyles => ({
      ...baseStyles,
      color: '#0003'
    }),
    dropdownIndicator: baseStyles => ({
      ...baseStyles,
      position: 'static'
    }),
    singleValue: baseStyles => ({
      ...baseStyles,
      color: '#495057'
    }),
    option: baseStyles => ({
      ...baseStyles,
      ':first-of-type': {
        color: '#0003'
      }
    })
  };

  return (
    <div>
      {warningOnCriteriaFailure && (
        <div
          style={{ background: '#FFF7ED', borderRadius: '6px' }}
          className={`element-box chargeback-note p-3 mb-0 ${isDisabled && 'mt-0'}`}
        >
          <p style={{ color: '#915200', fontWeight: '400', opacity: 1 }}>{warningOnCriteriaFailure}</p>
        </div>
      )}

      <div className={`utils-stack-md utils-relative ${isDisabled && ' utils-greyed-out'}`}>
        {isDisabled && <div className="utils-overlay" />}

        <div className="form-group">
          <label className="withdraw-label" htmlFor="chargeback-amount">
            <span className="dark">Chargeback Amount ({currency})</span>
          </label>
          <input
            id="chargeback-amount"
            type="number"
            value={chargebackAmount}
            className="form-control"
            name="amount"
            placeholder="Enter chargeback amount"
            onChange={handleChargebackAmountChange}
            onWheel={(e: React.WheelEvent<HTMLInputElement>) => (e.target as HTMLInputElement).blur()}
            onKeyDown={e => ['e', 'E', '+', '-', 'ArrowDown', 'ArrowUp'].includes(e.key) && e.preventDefault()}
            disabled={isDisabled}
          />
          <label htmlFor="chargeback-amount" className="withdraw-label mt-2 small">
            <span>
              Minimum Amount:{' '}
              <span className={`dark ${Boolean(chargebackAmount) && +chargebackAmount! < +minChargebackAmount! ? 'red' : ''}`} id="min">
                {currency} {formatAmount(minChargebackAmount)}
              </span>
            </span>
            <span>
              Maximum Amount:{' '}
              <span className={`dark ${Boolean(chargebackAmount) && +chargebackAmount! > +maxChargebackAmount! ? 'red' : ''}`}>
                {currency} {formatAmount(maxChargebackAmount)}
              </span>
            </span>
          </label>
        </div>

        <div className="form-group reason-container">
          <label htmlFor="chargeback-category" className="withdraw-label">
            <span className="dark">Chargeback Category</span>
          </label>
          <CustomReactSelect
            id="chargeback-category"
            aria-label="Chargeback Category"
            placeholder="Select chargeback category"
            options={chargebackCategories}
            value={!isDisabled && (chargebackCategory || state.selectedOption?.value)}
            onChange={value => {
              if (value.value !== chargebackCategories[0].value) {
                setState({ selectedOption: value });
                updateModalState({ chargebackCategory: value.value });
              }
            }}
            isDisabled={isDisabled || chargebackStatus === 'partially_accepted'}
            styles={reactSelectStyles}
          />
        </div>

        <div className="form-group">
          <label htmlFor="refund-amount" className="withdraw-label">
            <span className="dark">{chargebackCanBeReescalated ? `Reason for re-escalating` : `Description`}</span>
          </label>
          <textarea
            maxLength={200}
            autoComplete="off"
            id="refund-amount"
            className="form-control"
            name="amount"
            value={reasonForChargeback}
            placeholder={
              !chargebackStatus
                ? `Please provide detailed description of purchase and explanation of dispute`
                : `Tell us why you want to re-escalate this chargeback`
            }
            onChange={handleDescriptionChange}
            disabled={isDisabled}
          />
        </div>

        <div className="form-group chargeback-note">
          <label htmlFor="refund-amount" className="withdraw-label">
            <span className="dark">
              Submit documents to validate this chargeback{' '}
              <span className="grey" style={{ fontWeight: 400 }}>
                (optional)
              </span>
            </span>
          </label>

          <div className="chargeback-note mb-0">
            <p>
              Add all supporting documents in one pdf file. preferably in A4 size for best result. After submitting, you will not be able to
              change your defense.
            </p>
          </div>

          <FileUpload
            onUploadComplete={data => {
              updateModalState({
                evidenceOfFileUpload: data?.data[0]?.path
              });
            }}
            fileUploaded={evidenceOfFileUpload}
            onRemoveUploadedFile={() => updateModalState({ evidenceOfFileUpload: null })}
            fileUploadMessage={<p>Max file size {maxFileSize}MB</p>}
            fileAllowed=".pdf, .jpeg, .jpg"
            maxFileSize={maxFileSize}
          />
        </div>
      </div>
    </div>
  );
};

export default IssuanceChargebackForm;
