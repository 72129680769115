const createMerchantSlice = () => ({
  profile: {},
  defaultMerchant: {},
  cardIssuanceCardAccess: {},
  merchantEnv: '',
  issuanceAccessRequested: false,
  is2FactorEnabled: false,
  canGoLive: false,
  bulkPayoutDraftData: [
    {
      reference: '',
      data: [],
      newUpdate: false
    }
  ]
});

export default createMerchantSlice;
