import React, { Fragment } from 'react';
import { useQueryClient } from 'react-query';

import Table from '+containers/Dashboard/Shared/Table';
import { capitalize } from '+utils';

import './index.scss';

const BankAccounts = () => {
  const queryClient = useQueryClient();

  // const defaultMerchant = queryClient.getQueryData('DEFAULT_MERCHANT');
  const defaultBusiness = queryClient.getQueryData('MERCHANT_KYC');
  // const merchantBank = defaultMerchant?.settlement_bank;
  const nairaAccount = defaultBusiness?.details?.settlement_accounts?.NGN;

  const merchantAccountTable = () => {
    return (
      <>
        {nairaAccount?.map(
          account =>
            account.status === 'active' && (
              <div className="div-table --account-table --row">
                <Fragment key={account?.id}>
                  <div>
                    <span style={{ textTransform: 'capitalize' }}>
                      {account?.account_details?.account_name ? capitalize(account?.account_details?.account_name) : 'nil'}
                    </span>
                  </div>
                  <div>
                    <span>{account?.account_details?.account_number || 'nil'}</span>
                  </div>
                  <div>
                    <span style={{ textTransform: 'capitalize' }}>{account?.bank?.name || 'nil'}</span>
                  </div>
                </Fragment>
              </div>
            )
        )}
      </>
    );
  };

  return (
    <div className="saved-bank-container">
      <h5>Bank Account</h5>
      <span className="description">Details of your bank account to which withdrawals should be settled to.</span>
      <Table
        tableClassName="--account-table"
        headings={[
          {
            value: 'ACCOUNT NAME'
          },
          {
            value: 'ACCOUNT NUMBER'
          },
          {
            value: 'BANK'
          },
          {
            value: ''
          }
        ]}
        loading={false}
        emptyStateMessage={'No Bank Account set yet.'}
        children={merchantAccountTable()}
        background="#f5f6f6"
        tableWrapperClassName="saved-bank"
      />
    </div>
  );
};

export default BankAccounts;
