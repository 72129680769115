import React, { useEffect, useReducer, useState } from 'react';
import DatePicker from 'react-datepicker';
import { useLocation } from 'react-router-dom';
import dayjs from 'dayjs';

import 'react-datepicker/dist/react-datepicker.css';

import { cleanInput, filterOutEmptyValues } from '+utils';

import { parseQueryParams } from '../../Shared/FilterModal';

import arrowRight from '+assets/img/dashboard/arrow-right.svg';
import calendar from '+assets/img/dashboard/calendar.svg';
import search from '+assets/img/dashboard/search-thin.svg';

const cardStatusOptions = {
  active: 'Active',
  suspended: 'Suspended',
  expired: 'Expired',
  terminated: 'Terminated'
};

const txStatusOptions = {
  success: 'Success',
  processing: 'Processing',
  failed: 'Failed',
  pending: 'Pending',
  declined: 'Declined',
  reversed: 'Reversed'
};

const cardBrandOptions = {
  mastercard: 'Mastercard',
  visa: 'Visa'
};

const chargbackStatusOptions = {
  accepted: 'Accepted',
  declined: 'Declined',
  processing: 'Processing',
  pending: 'Pending',
  invalid: 'Invalid',
  pending_pre_arbitration: 'Pre-Arb Pending',
  processing_pre_arbitration: 'Pre-Arb Processing',
  invalid_pre_arbitration: 'Pre-Arb Invalid',
  accepted_pre_arbitration: 'Pre-Arb Accepted',
  declined_pre_arbitration: 'Pre-Arb Declined'
};

const billingStatusOptions: {
  pending: string;
  partially_paid: string;
  fully_paid: string;
} = {
  pending: 'Pending',
  partially_paid: 'Partially Paid',
  fully_paid: 'Fully Paid'
};

type BillingStatusKeys = keyof typeof billingStatusOptions;

export function IssuanceFilter({ type = '', handleFilterQuery = () => {}, setNonFilterParams }) {
  const location = useLocation();

  const [filterItems, setFilterItems] = useReducer((prev, next) => ({ ...prev, ...next }), {
    status: [],
    cardBrand: '',
    keyword: '',
    dateCreatedFrom: '',
    dateCreatedTo: ''
  });

  useEffect(() => {
    const { status = [], cardBrand = '', keyword = '', dateCreatedFrom = '', dateCreatedTo = '' } = parseQueryParams();
    const values = {
      status,
      cardBrand,
      keyword,
      dateCreatedFrom,
      dateCreatedTo
    };
    setFilterItems(values);
  }, [location.search]);

  const extractNonFilterParams = () => {
    const queryParams = parseQueryParams();
    const nonFilterParam = 'tab';
    const { [nonFilterParam]: nonFilterValue, ...others } = queryParams;
    setNonFilterParams({ [nonFilterParam]: nonFilterValue });
  };

  useEffect(() => {
    extractNonFilterParams();
  }, [window.location.search]);

  switch (type) {
    case 'customer_cards':
    case 'reserved_cards':
      return (
        <section className="settlement-filter">
          <div className="settlement-filter__search-w filter-section">
            <div className="element-search-content filter-body w-100">
              <div className="form-group filter-object filter-object-ssm w-auto --no-max-width">
                <select
                  name="type"
                  className="form-control"
                  onChange={e => setFilterItems({ status: e.target.value ? [e.target.value] : [] })}
                  value={filterItems?.status?.[0] || ''}
                  style={{ border: '1.5px solid #EAF2FE', width: '150px' }}
                >
                  <option value="">Card Status</option>
                  {Object.keys(cardStatusOptions || {}).map(value => (
                    <option key={value} value={value}>
                      {cardStatusOptions[value]}
                    </option>
                  ))}
                </select>
              </div>

              <div className="form-group position-relative filter-object filter-object-xl flex-grow-1 w-auto --no-max-width --search-container">
                <img src={search} alt="search icon" aria-hidden />
                <input
                  type="search"
                  className="form-control"
                  placeholder="Search by card ID, cardholder name, anything..."
                  value={filterItems.keyword}
                  onChange={e => setFilterItems({ keyword: cleanInput(e.target.value) })}
                  style={{ border: '1.5px solid #EAF2FE' }}
                />
              </div>

              <div className="form-group filter-object filter-object-ssm w-auto --no-max-width">
                <select
                  name="type"
                  className="form-control"
                  onChange={e => setFilterItems({ cardBrand: e.target.value })}
                  value={filterItems?.cardBrand}
                  style={{ border: '1.5px solid #EAF2FE', width: '150px' }}
                >
                  <option value="">Card Brand</option>
                  {Object.keys(cardBrandOptions || {}).map(option => (
                    <option key={option} value={option}>
                      {cardBrandOptions[option]}
                    </option>
                  ))}
                </select>
              </div>

              <button
                aria-label="filter transactions"
                type="button"
                className="settlement-filter__filter-button"
                onClick={() => handleFilterQuery(filterOutEmptyValues(filterItems))}
                aria-controls="handleFilter"
              >
                <img src={arrowRight} alt="arrow right icon" aria-hidden />
              </button>
            </div>
          </div>
        </section>
      );

    case 'card_transactions':
      return (
        <section className="settlement-filter">
          <div className="settlement-filter__search-w filter-section">
            <div className="element-search-content filter-body w-100">
              <div className="form-group filter-object filter-object-ssm w-auto --no-max-width">
                <select
                  name="type"
                  className="form-control"
                  onChange={e => setFilterItems({ status: e.target.value ? [e.target.value] : [] })}
                  value={filterItems?.status?.[0] || ''}
                  style={{ border: '1.5px solid #EAF2FE', width: '150px' }}
                >
                  <option value="">Status</option>
                  {Object.keys(txStatusOptions || {}).map(value => (
                    <option key={value} value={value}>
                      {txStatusOptions[value]}
                    </option>
                  ))}
                </select>
              </div>

              <div className="form-group position-relative filter-object filter-object-xl flex-grow-1 w-auto --no-max-width --search-container">
                <img src={search} alt="search icon" aria-hidden />
                <input
                  type="search"
                  className="form-control"
                  placeholder="Search by transaction ID, customer name, anything..."
                  value={filterItems.keyword}
                  onChange={e => setFilterItems({ keyword: cleanInput(e.target.value) })}
                  style={{ border: '1.5px solid #EAF2FE' }}
                />
              </div>

              <div
                className="form-group filter-object d-flex filter-object-sm w-auto"
                style={{
                  '--calendar-image': `url("${calendar}")`,
                  minWidth: '300px'
                }}
              >
                <DatePicker
                  selected={filterItems.dateCreatedFrom && new Date(filterItems.dateCreatedFrom)}
                  dateFormat="dd-MM-yyyy"
                  popperPlacement="bottom-end"
                  style={{ borderRadius: '0px 4px 4px 0px', border: '0.5px solid red' }}
                  className="form-control date-select pl-4 date-picker"
                  onChange={date => setFilterItems({ dateCreatedFrom: date && dayjs(date).format('YYYY-MM-DD') })}
                  maxDate={new Date()}
                  placeholderText="From"
                  calendarClassName="custom-datepicker"
                />
                <DatePicker
                  selected={filterItems.dateCreatedTo && new Date(filterItems.dateCreatedTo)}
                  dateFormat="dd-MM-yyyy"
                  popperPlacement="bottom-end"
                  style={{ borderRadius: '0px 4px 4px 0px' }}
                  className="form-control date-select pl-4 date-picker"
                  minDate={filterItems.dateCreatedFrom && new Date(filterItems.dateCreatedFrom)}
                  maxDate={new Date()}
                  onChange={date => setFilterItems({ dateCreatedTo: date && dayjs(date).format('YYYY-MM-DD') })}
                  placeholderText="To"
                  calendarClassName="custom-datepicker"
                />
              </div>

              <button
                aria-label="filter transactions"
                type="button"
                className="settlement-filter__filter-button"
                onClick={() => handleFilterQuery(filterOutEmptyValues(filterItems))}
                aria-controls="handleFilter"
              >
                <img src={arrowRight} alt="arrow right icon" aria-hidden />
              </button>
            </div>
          </div>
        </section>
      );
    case 'chargebacks':
      return (
        <section className="settlement-filter">
          <div className="settlement-filter__search-w filter-section">
            <div className="element-search-content filter-body w-100">
              <div className="form-group filter-object filter-object-ssm w-auto --no-max-width">
                <select
                  name="type"
                  className="form-control"
                  onChange={e => setFilterItems({ status: e.target.value ? [e.target.value] : [] })}
                  value={filterItems?.status?.[0] || ''}
                  style={{ border: '1.5px solid #EAF2FE', width: '150px' }}
                >
                  <option value="">Status</option>
                  {Object.keys(chargbackStatusOptions || {}).map(value => (
                    <option key={value} value={value}>
                      {chargbackStatusOptions[value]}
                    </option>
                  ))}
                </select>
              </div>

              <div className="form-group position-relative filter-object filter-object-xl flex-grow-1 w-auto --no-max-width --search-container">
                <img src={search} alt="search icon" aria-hidden />
                <input
                  type="search"
                  className="form-control"
                  placeholder="Search by transaction ID, customer name, anything..."
                  value={filterItems.keyword}
                  onChange={e => setFilterItems({ keyword: cleanInput(e.target.value) })}
                  style={{ border: '1.5px solid #EAF2FE' }}
                />
              </div>

              <div
                className="form-group filter-object d-flex filter-object-sm w-auto"
                style={{
                  '--calendar-image': `url("${calendar}")`,
                  minWidth: '300px'
                }}
              >
                <DatePicker
                  selected={filterItems.dateCreatedFrom && new Date(filterItems.dateCreatedFrom)}
                  dateFormat="dd-MM-yyyy"
                  popperPlacement="bottom-end"
                  style={{ borderRadius: '0px 4px 4px 0px', border: '0.5px solid red' }}
                  className="form-control date-select pl-4 date-picker"
                  onChange={date => setFilterItems({ dateCreatedFrom: date && dayjs(date).format('YYYY-MM-DD') })}
                  maxDate={new Date()}
                  placeholderText="From"
                  calendarClassName="custom-datepicker"
                />
                <DatePicker
                  selected={filterItems.dateCreatedTo && new Date(filterItems.dateCreatedTo)}
                  dateFormat="dd-MM-yyyy"
                  popperPlacement="bottom-end"
                  style={{ borderRadius: '0px 4px 4px 0px' }}
                  className="form-control date-select pl-4 date-picker"
                  minDate={filterItems.dateCreatedFrom && new Date(filterItems.dateCreatedFrom)}
                  maxDate={new Date()}
                  onChange={date => setFilterItems({ dateCreatedTo: date && dayjs(date).format('YYYY-MM-DD') })}
                  placeholderText="To"
                  calendarClassName="custom-datepicker"
                />
              </div>

              <button
                aria-label="filter transactions"
                type="button"
                className="settlement-filter__filter-button"
                onClick={() => handleFilterQuery(filterOutEmptyValues(filterItems))}
                aria-controls="handleFilter"
              >
                <img src={arrowRight} alt="arrow right icon" aria-hidden />
              </button>
            </div>
          </div>
        </section>
      );
    case 'billing_history':
      return (
        <section className="settlement-filter">
          <div className="settlement-filter__search-w filter-section">
            <div className="element-search-content filter-body w-100">
              <div className="form-group filter-object filter-object-ssm w-auto --no-max-width">
                <select
                  name="type"
                  className="form-control"
                  onChange={e => setFilterItems({ status: e.target.value ? [e.target.value] : [] })}
                  value={filterItems?.status?.[0] || ''}
                  style={{ border: '1.5px solid #EAF2FE', width: '150px' }}
                >
                  <option value="">Status</option>
                  {Object.keys(billingStatusOptions).map(value => (
                    <option key={value} value={value}>
                      {billingStatusOptions[value as BillingStatusKeys]}
                    </option>
                  ))}
                </select>
              </div>

              <div className="form-group position-relative filter-object filter-object-xl flex-grow-1 w-auto --no-max-width --search-container">
                <img src={search} alt="search icon" aria-hidden />
                <input
                  type="search"
                  className="form-control"
                  placeholder="Search by transaction ID, customer name, anything..."
                  value={filterItems.keyword}
                  onChange={e => setFilterItems({ keyword: cleanInput(e.target.value) })}
                  style={{ border: '1.5px solid #EAF2FE' }}
                />
              </div>

              <div
                className="form-group filter-object d-flex filter-object-sm w-auto"
                style={{
                  '--calendar-image': `url("${calendar}")`,
                  minWidth: '300px'
                }}
              >
                <DatePicker
                  selected={filterItems.dateCreatedFrom && new Date(filterItems.dateCreatedFrom)}
                  dateFormat="dd-MM-yyyy"
                  popperPlacement="bottom-end"
                  style={{ borderRadius: '0px 4px 4px 0px', border: '0.5px solid red' }}
                  className="form-control date-select pl-4 date-picker"
                  onChange={date => setFilterItems({ dateCreatedFrom: date && dayjs(date).format('YYYY-MM-DD') })}
                  maxDate={new Date()}
                  placeholderText="From"
                  calendarClassName="custom-datepicker"
                />
                <DatePicker
                  selected={filterItems.dateCreatedTo && new Date(filterItems.dateCreatedTo)}
                  dateFormat="dd-MM-yyyy"
                  popperPlacement="bottom-end"
                  style={{ borderRadius: '0px 4px 4px 0px' }}
                  className="form-control date-select pl-4 date-picker"
                  minDate={filterItems.dateCreatedFrom && new Date(filterItems.dateCreatedFrom)}
                  maxDate={new Date()}
                  onChange={date => setFilterItems({ dateCreatedTo: date && dayjs(date).format('YYYY-MM-DD') })}
                  placeholderText="To"
                  calendarClassName="custom-datepicker"
                />
              </div>

              <button
                aria-label="filter transactions"
                type="button"
                className="settlement-filter__filter-button"
                onClick={() => handleFilterQuery()}
                aria-controls="handleFilter"
              >
                <img src={arrowRight} alt="arrow right icon" aria-hidden />
              </button>
            </div>
          </div>
        </section>
      );
    default:
      return null;
  }
}
