/* eslint-disable no-nested-ternary */
import { useState } from 'react';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';

import Copyable from '+containers/Dashboard/Shared/Copyable';
import FileViewer from '+containers/Dashboard/Shared/FileViewer';
import LoadingPlaceholder from '+containers/Dashboard/Shared/LoadingPlaceholder';
import ToolTip from '+containers/Dashboard/Shared/Tooltip';
import { ScrollToTopSection } from '+containers/Shared/ScrollToTop';
import useFeedbackHandler from '+hooks/feedbackHandler';
import APIRequest from '+services/api-services';
import {
  capitalize,
  capitalizeFirst,
  capitalizeFirstText,
  capitalizeRemovedash,
  findMismatchKeys,
  formatAmount,
  getDateAndTime,
  history,
  lowercaseRemovedash,
  maskAfterSetValue
} from '+utils';
import renderStyledJson from '+utils/render-json';

import { statusIcons, StatusIconsKeyType } from '../data/identity-data';
import { EventType, RegionType } from '../types/types';
import VerificationEventResultModal from './VerificationModal';

import draft from '+assets/img/dashboard/draft-Icon.png';
import InfoSymbol from '+assets/img/dashboard/info-symbol.png';
import copyIcon from '+assets/img/copy-new.svg';

import './index.scss';

const api: APIRequest = new APIRequest(process.env.REACT_APP_PUBLIC_MERCHANT_MIDDLEWARE_API_BASE);

function VerificationDetails() {
  const { id } = useParams<{ id: string }>();
  const [showModal, setShowModal] = useState(false);
  const [copied, setCopied] = useState(false);
  const [showSensitiveData, setShowSensitiveData] = useState(false);
  const [fileToView, setFileToView] = useState<string | null>(null);
  const closeFileViewer = () => {
    setShowModal(true);
    setFileToView(null);
  };
  const openFileViewer = (file: string) => {
    setFileToView(file);
    setShowModal(false);
  };

  const { feedbackInit } = useFeedbackHandler();

  const getMatchingScore = (score: number) => {
    const isScoreLow = !facialMatchingMatch;
    return (
      <div className="d-flex align-items-center ">
        <span className={`d-flex align-items-center rounded mr-2 facial-match_score ${isScoreLow ? 'low' : ''}`}>{score}%</span>
        {isScoreLow && <img className="mr-2" width={14} src={draft} alt="draft_icon" />}
      </div>
    );
  };

  const { data, isLoading } = useQuery(`VERIFICATION_DETAILS_${id}`, () => api.getSingleVerification(id), {
    onError: () => {
      feedbackInit({
        message: `There has been an error fetching the details for the transaction: ${id.toUpperCase()}.`,
        type: 'danger'
      });
      history.goBack();
    }
  });

  const {
    type,
    date_created,
    country,
    class: classType,
    identity_type_description,
    full_name,
    id: idNumber,
    reference,
    status,
    validation,
    billing
  } = data || {};

  const formattedDateCreated = date_created ? getDateAndTime(date_created) : 'N/A';
  const formatedVerificationClass = classType ? capitalizeRemovedash(classType)?.replace('Id ', 'ID ') : 'N/A';
  const formatedIdentityTypeDescription = identity_type_description ? capitalizeRemovedash(identity_type_description) : 'N/A';
  const mismatch = findMismatchKeys(validation || {});
  const getMismatchMessage = (keys: string[]) => {
    const formattedKeys = keys?.map(key => <span style={{ color: '#915200' }}>{capitalizeRemovedash(key)}</span>);
    let mismatchMessage = [<span>Possible mismatch in </span>];
    if (formattedKeys.length > 1) {
      mismatchMessage.push(...formattedKeys.slice(0, -1).map((key, index) => <span key={index}>{key}, </span>));
      mismatchMessage.push(<span> and </span>);
      mismatchMessage.push(formattedKeys.slice(-1)[0]);
    } else if (formattedKeys.length === 1) {
      mismatchMessage.push(formattedKeys[0]);
    }
    return mismatchMessage;
  };

  const isMismatch = (eventField: string) => {
    return mismatch.includes(eventField);
  };
  const checkedEventData = (eventData: string, eventField: string | string[]) => {
    let mismatched = false;
    if (Array.isArray(eventField)) {
      mismatched = eventField.some(field => isMismatch(field));
    }
    if (typeof eventField === 'string') {
      mismatched = isMismatch(eventField);
    }
    return (
      <div className="d-flex align-items-center">
        <span className="mr-2">{capitalizeFirst(eventData || '') || 'N/A'}</span>
        {mismatched && <img className="mr-2" width={12} src={draft} alt="draft_icon" />}
      </div>
    );
  };

  const facialMatchingScore = validation?.selfie?.confidence_rating;
  const facialMatchingMatch = validation?.selfie?.match;
  const result = {
    Type: EventType[type as keyof typeof EventType],
    'Date Created': formattedDateCreated,
    Region: RegionType[country as keyof typeof RegionType],
    'Verification Class': formatedVerificationClass,
    'Checks Done': formatedIdentityTypeDescription,
    Name: checkedEventData(full_name, ['full_name', 'last_name', 'first_name']),
    'ID Number': !showSensitiveData ? maskAfterSetValue(idNumber ?? '', 3) : idNumber ?? 'N/A',
    'Data Mactching': capitalize(validation ? 'Yes' : 'No') || 'No',
    ...(facialMatchingScore !== null && facialMatchingScore !== undefined
      ? { 'Facial Matching Score': getMatchingScore(facialMatchingScore || 0) }
      : {}),
    Reference: reference,
    'Billing Amount': `${formatAmount(billing?.amount) || 0} ${billing?.currency || 'USD'}`,
    'Event Result': capitalize(data?.result ?? 'undefined')
  };
  return (
    <>
      <div className="transaction-details__comp">
        <div className="header-row">
          <div className="col-sm-12" style={{ padding: '0px' }}>
            <button type="button" className="btn btn-link goback-btn" onClick={() => history.goBack()}>
              <i className="os-icon os-icon-arrow-left7" />
              <span>Back to Identity</span>
            </button>
            {!isLoading && (
              <section className="invoice-heading">
                <div className="invoice-details">
                  <span className="amount-heading">
                    {id} <Copyable hideText={true} text={id} buttonClassName="base-copy-button" showCopyText={false} iconSize={50} />
                  </span>
                  <div className={`verification-${data.status}-status identity-status-pill`}>
                    <h6 className="m-0">{capitalizeFirstText(lowercaseRemovedash(data.status))} </h6>
                  </div>
                </div>

                <div className="--lg bg-transparent d-flex align-items-center">
                  <div
                    className={`os-toggler-w ${showSensitiveData ? 'on' : ''}`}
                    onClick={() => setShowSensitiveData(!showSensitiveData)}
                    role="button"
                    onKeyDown={() => setShowSensitiveData(!showSensitiveData)}
                    tabIndex={0}
                  >
                    {true && (
                      <div className="os-toggler-i">
                        <div className="os-toggler-pill" />
                      </div>
                    )}
                  </div>
                  <span className="font-weight-normal">Show sensitive details</span>
                </div>
              </section>
            )}
          </div>
        </div>
        <div className="element-box transaction-details-container">
          <section className="trxn-information">{isLoading ? <LoadingPlaceholder type="text" content={4} /> : <article></article>}</section>
        </div>
      </div>
      <div className="transaction-details__comp_2 mt-0">
        <div className="transaction-details-container-2">
          <section className="customer-information">
            {isLoading ? (
              <LoadingPlaceholder type="text" content={4} />
            ) : (
              <article>
                <ul>
                  <li>
                    <div className="row mb-3">
                      <div className="col-lg-6">
                        <p>These are some details of this verification event. Some sensitive information may have been masked or hidden.</p>
                      </div>
                    </div>
                    {mismatch.length > 0 && (
                      <div className="row mb-5">
                        <div className="p-2 p-lg-3 g-3 g-lg-5 bg-warning matching_error col-12 rounded">
                          <span className="icon-wrapper">
                            <img className="mr-2" width={14} src={draft} alt="draft_icon" />
                          </span>{' '}
                          {getMismatchMessage(mismatch)}
                        </div>
                      </div>
                    )}
                    {Object.keys(result).map((key: string) => (
                      <p key={key}>
                        <span>{key}</span>
                        <span
                          className={`${
                            key === 'Event Result' ? `verification-${data?.result ?? 'undefined'}-status` : ''
                          } verification-value`}
                        >
                          {key === 'Event Result' && data.result && (
                            <i
                              className={`${
                                statusIcons[data?.result?.toLowerCase() as StatusIconsKeyType]
                              } status-icon --${data?.result?.toLowerCase()} mr-1`}
                            />
                          )}
                          {result[key as keyof typeof result]}
                          {key === 'Reference' && (
                            <Copyable hideText={true} text={result['Reference']} buttonClassName="" showCopyText={false} iconSize={14} />
                          )}
                          {key === 'Event Result' && data.result !== 'valid' && (
                            <ToolTip
                              image={InfoSymbol}
                              message={
                                <em>
                                  Result <br />{' '}
                                  {data.result === 'invalid'
                                    ? 'There is a missmatch in the verification data. Please review the data and try again.'
                                    : 'Identity could not be verified. Please review the data and try again.'}
                                </em>
                              }
                            />
                          )}
                        </span>
                      </p>
                    ))}
                  </li>
                </ul>
              </article>
            )}
          </section>
        </div>
      </div>

      {showSensitiveData && (
        <div className="transaction-details__comp_2 mt-0">
          <div className="transaction-details-container-2">
            <section className="customer-information">
              {isLoading ? (
                <LoadingPlaceholder type="text" content={4} />
              ) : (
                <article>
                  <ul>
                    <li>
                      <div className="section-heading">
                        <h5 className="mb-3 mt-4 font-weight-bold">Results</h5>
                      </div>
                      <div className="section-heading dotted-border d-flex justify-content-between mt-5">
                        <div className="d-flex">
                          <h6>{capitalizeRemovedash(data?.identity_type_description)}</h6>
                          <p className={`ml-3 d-flex align-items-center ${data.status !== 'failed' ? 'text-muted' : 'text-warning'}`}>
                            {data.status === 'failed' && <img className="mr-2" width={14} height={14} src={draft} alt="draft_icon" />}
                            {data.status !== 'failed' ? 'Verification complete' : 'Unable to complete verification '}
                          </p>
                        </div>
                        {data.status !== 'failed' ? (
                          <button onClick={() => setShowModal(true)} type="button" className="btn btn-link">
                            See result details
                          </button>
                        ) : (
                          <p className="text-muted font-italic font-weight-light">
                            Results are only available when verification is complete
                          </p>
                        )}
                      </div>
                    </li>
                    <div className="webhook-payload">
                      <div className="payload-codeblock-heading">
                        <div className="d-flex payload-codeblock-header justify-content-between mt-5 mb-3">
                          <h5 className="m-0 font-weight-bold">Event Data</h5>
                        </div>
                      </div>
                      <div className="payload-codeblock">
                        {status !== 'failed' && (
                          <div
                            className="d-flex payload-copy-section align-items-center"
                            onClick={() => {
                              navigator.clipboard.writeText(JSON.stringify(data));
                              (function _() {
                                setCopied(true);
                                setTimeout(() => {
                                  setCopied(false);
                                }, 800);
                              })();
                            }}
                          >
                            {copied ? (
                              <p className="m-0">Copied!</p>
                            ) : (
                              <>
                                <img id="copy-icon" src={copyIcon} alt="" />
                                <p className="m-0">Copy Code</p>
                              </>
                            )}
                          </div>
                        )}
                        <div className="code-block">
                          {data.status !== 'failed' ? (
                            renderStyledJson(data)
                          ) : (
                            <div className="code-block d-flex justify-content-center align-items-center h-100">
                              <p className="text-muted font-weight-light">No event data available yet</p>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </ul>
                </article>
              )}
            </section>
          </div>
        </div>
      )}
      <section className="back-to-top">{ScrollToTopSection()}</section>

      {showModal && (
        <VerificationEventResultModal
          close={() => setShowModal(false)}
          header={data.identity_type_description}
          data={data}
          setFileToView={openFileViewer}
        />
      )}
      <FileViewer url={fileToView as string} setShowView={closeFileViewer} showView={!!fileToView} />
    </>
  );
}

export default VerificationDetails;
