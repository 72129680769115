import React from 'react';
import { capitalize, getDate, getTime, switchStatus } from '+utils';
import LoadingPlaceholder from '+/containers/Dashboard/Shared/LoadingPlaceholder';
import './index.scss';
import Copyable from '+containers/Dashboard/Shared/Copyable';

interface IAccountHolderSummaryProps {
  data: {
    account_status: string;
    account_type: string;
    use_case: string;
    date_of_birth: string;
    date_verified: string;
    employment: {
      status: string;
      employer: string;
      description: string;
    };
  };
  firstName: string;
  lastName: string;
  reference: string;
}

export default function AccountHolderSumary({ data, firstName, lastName, reference }: IAccountHolderSummaryProps) {
  return (
    <section className="virtual-account-summary">
      {!data ? (
        <LoadingPlaceholder type="text" content={4} />
      ) : (
        <article>
          <ul className="virtual-account-summary-list">
            <li>
              <p>First Name</p>
              <p className="summaary-value">{capitalize(firstName)}</p>
            </li>
            <li>
              <p>Last Name</p>
              <p className="summaary-value">{capitalize(lastName)}</p>
            </li>
            <li>
              <p>Account Holder Status</p>
              <div>
                <span className={`status-pill smaller ${switchStatus(data?.account_status)}`} />
                <span>{capitalize(data?.account_status)}</span>
              </div>
            </li>
            <li>
              <p>Account Holder Reference</p>
              <div>
                <Copyable text={reference} />
              </div>
            </li>
            <li>
              <p>Account Type</p>
              <p className="summaary-value">{capitalize(data?.account_type)}</p>
            </li>
            {data?.use_case && (
              <li>
                <p>Use Case</p>
                <p className="summaary-value">{capitalize(data?.use_case)}</p>
              </li>
            )}
            {data?.employment && (
              <li>
                <p>Employment Status</p>
                <p className="summaary-value">{capitalize(data?.employment?.status)}</p>
              </li>
            )}
            {data?.employment && (
              <li>
                <p>Employer</p>
                <p className="summaary-value">{capitalize(data?.employment?.employer)}</p>
              </li>
            )}
            {data?.employment && (
              <li>
                <p>Employment Description</p>
                <p className="summaary-value">{capitalize(data?.employment?.description)}</p>
              </li>
            )}
            <li>
              <p>Date Created</p>
              <p className="summaary-value">
                <span style={{ color: '#414F5F' }}>{getDate(data?.date_of_birth)}</span>{' '}
                <span style={{ color: '#A9AFBC' }}>{getTime(data?.date_of_birth)}</span>
              </p>
            </li>
            <li>
              <p>Date Verified</p>
              {data?.date_verified ? (
                <p className="summaary-value">
                  <span style={{ color: '#414F5F' }}>{getDate(data?.date_verified)}</span>{' '}
                  <span style={{ color: '#A9AFBC' }}>{getTime(data?.date_verified)}</span>
                </p>
              ) : (
                <p>Not Yet Verified</p>
              )}
            </li>
          </ul>
        </article>
      )}
    </section>
  );
}
