import React, { useState } from 'react';
import './index.scss';

/*
 * This component renders the pagination elements according to the view available
 * The pagination control will look like the following:
 * (1) < {4} [5] {6} > (10)
 * pagingTotalItems is the "total_items" object in the paging response from the api
 * pageSize is the "page_size" object in the paging response from the api.
 * the action receives the current page and is responsible for handling the dispatch and updating on page change
 */

interface PaginationComponentProps {
  currentPage: number;
  pagingTotalItems: number;
  pageSize: number;
  action: (arg: number) => void;
  limitAction?: (arg: string) => void;
  showPaginationDescription?: boolean;
  showLimit?: boolean;
  annotation?: string;
  scrollToTop?: () => void;
}
export default function PaginationComponent({
  currentPage = 1,
  pagingTotalItems,
  pageSize,
  action,
  showPaginationDescription = true,
  showLimit = true,
  limitAction,
  annotation = 'transactions',
  scrollToTop
}: PaginationComponentProps) {
  const [point, setPoint] = useState(1);
  const totalPages = Math.ceil(pagingTotalItems / pageSize) || 1;
  const paginate = (go: number | string) => {
    let current = currentPage;
    if (currentPage === go) return null;
    if (typeof go === 'string') {
      if (go === 'next') {
        current += 1;
        action(current);
      } else if (go === 'prev') {
        current -= 1;
        action(current);
      }
    } else {
      action(go);
    }
    return null;
  };

  const paginationLogic = () => {
    let pages: number[];
    const startPage = Math.max(2, currentPage - 1);
    const endPage = currentPage < totalPages - 2;
    const twoEnd = totalPages - 2;
    const preEnd = totalPages - 1;

    switch (startPage) {
      case 2:
        if (startPage === totalPages) {
          pages = [];
        } else if (startPage + 1 >= totalPages) {
          pages = [2];
        } else if (startPage + 2 >= totalPages) {
          pages = [2, 3];
        } else {
          pages = [2, 3, 4];
        }
        break;
      case twoEnd:
        pages = [startPage, currentPage];
        break;
      case preEnd:
        pages = [startPage - 1, startPage];
        break;
      default:
        pages = [startPage, currentPage, currentPage + 1];
        break;
    }
    return { startPage, endPage, pages };
  };

  const renderPagination = () => {
    const { startPage, endPage, pages } = paginationLogic();
    if (totalPages === 1) return null;
    return (
      <div className="paging-row">
        <button onClick={() => paginate(1)} type="button" className={`pagination-button --first ${currentPage === 1 ? 'active' : ''}`}>
          1
        </button>
        {startPage !== 2 && (
          <button onClick={() => paginate('prev')} type="button" className="pagination-button">
            &laquo;
          </button>
        )}
        {pages.map(page => {
          return (
            <button
              key={page}
              type="button"
              onClick={() => paginate(page)}
              className={`pagination-button ${currentPage === page ? 'active' : null}`}
            >
              {page}
            </button>
          );
        })}
        {endPage && (
          <button onClick={() => paginate('next')} type="button" className="pagination-button">
            &raquo;
          </button>
        )}
        <button
          onClick={() => paginate(totalPages)}
          type="button"
          className={`pagination-button --last ${currentPage === totalPages ? 'active' : ''}`}
        >
          {totalPages}
        </button>

        <div className="goto-container" style={{ margin: showPaginationDescription ? '1rem 0 0 0.6rem' : '' }}>
          <span>Go to :</span>
          <input type="text" placeholder="1" onChange={e => setPoint(+e.target.value)} />
          <button
            onClick={() => {
              if (+point > +totalPages) return false;
              return paginate(point);
            }}
            type="button"
          >
            Go
          </button>
        </div>
      </div>
    );
  };

  const getBeginning = () => {
    if (currentPage === 1) return 1;
    const i = currentPage - 1;
    return pageSize * i + 1;
  };

  const scrollToTopSection = () => {
    if (pagingTotalItems < 10 || (pagingTotalItems <= 5 && document.body.clientWidth < 1024)) return null;
    const defaultScroll = () => document.body.scrollIntoView({ behavior: 'smooth', block: 'start' });
    return (
      <div>
        <button className="btn btn-sm pagination-back-to-top" type="button" onClick={scrollToTop || defaultScroll}>
          <span>Back to top</span>
          <i className="os-icon os-icon-arrow-up6" />
        </button>
      </div>
    );
  };

  return (
    <section className="pagination-container" hidden={pagingTotalItems === 0}>
      {pagingTotalItems <= pageSize ? (
        <>
          <div className="dataTables_length">
            <span className="pagination-pages">
              <strong>{pagingTotalItems}</strong> {pagingTotalItems === 1 ? annotation.substr(0, annotation.length - 1) : annotation}
            </span>
          </div>
          {scrollToTopSection()}
        </>
      ) : (
        <>
          <div className="dataTables_length" id="dataTable1_length">
            {showLimit && (
              <>
                <label>
                  Show
                  <select
                    name="dataTable1_length"
                    style={{ width: '60px', padding: '3px' }}
                    onChange={e => limitAction?.(e.target.value)}
                    value={pageSize || '25'}
                    aria-controls="dataTable1_length"
                    className="form-control form-control-sm"
                  >
                    <option value="10">10</option>
                    <option value="25">25</option>
                    <option value="50">50</option>
                  </select>
                  {annotation}
                </label>
                <span className="divider-sm" />
              </>
            )}
            {showPaginationDescription && (
              <span className="pagination-pages">
                Showing <strong>{getBeginning()}</strong> to <strong>{Math.min(pageSize * currentPage, pagingTotalItems)}</strong> of{' '}
                <strong>{pagingTotalItems}</strong> {annotation}
              </span>
            )}
          </div>
          {scrollToTopSection()}
          {renderPagination()}
        </>
      )}
    </section>
  );
}
