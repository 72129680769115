import React from 'react';
interface ActionButtonProps {
    img: string;
    label: string;
    action: () => void;
  }

function ActionButton({ img, label, action }: ActionButtonProps) {
    return (
      <button className="action-btn" onClick={action}>
        <img src={img} alt="" />
        {label}
      </button>
    );
  }

  export default ActionButton;
  