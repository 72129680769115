import Modal from './Modal';

export default function ExportFilterModal({ close, email, visible }){
  return (
    <div>
      <Modal
        close={close}
        visible={visible}
        size="md"
        heading="Large file Download"
        showButtons={false}
        secondButtonActionIsTerminal={false}
        content={
          <>
            <p
              style={{
                color: '#414F5F'
              }}
            >
              The requested export file is too large and will be sent shortly to your email <strong>{email}</strong>. Click the link
              in your email to download your file.
            </p>
            <button onClick={close} className="btn-kpy --full-blue" type="button">
              Okay, Got it!
            </button>
          </>
        }
      />
    </div>
  );
};
