import { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';

import Copyable from '+containers/Dashboard/Shared/Copyable';
import Icon from '+containers/Dashboard/Shared/Icons';
import { useFeedbackHandler } from '+hooks';
import { IBillingHistoryDetails } from '+types';
import { capitalize, capitalizeFirst, capitalizeRemovedash, getDate, getTime, history, switchStatus } from '+utils';

import { publicApi } from '../constants';
import { PaymentDataType } from '../data';
import ReceiptModal from './ReceiptModal';

const BillingHistoryDetails = () => {
  const { id } = useParams<{ id: string }>();
  const { feedbackInit } = useFeedbackHandler();
  const [activePayment, setActivePayment] = useState<PaymentDataType | null>({
    amount: 0,
    created_at: '',
    debit_type: '',
    reference: '',
    status: ''
  });
  const [viewReceipt, setViewReceipt] = useState(false);

  const { data, isLoading } = useQuery<IBillingHistoryDetails>(`TRANSACTION_DETAILS_${id}`, () => publicApi.fetchBillingDetails(id), {
    onError: () => {
      feedbackInit({
        message: `There has been an error fetching the details for the transaction: ${id.toUpperCase()}.`,
        type: 'danger'
      });
    }
  });

  const {
    reference,
    type,
    amount,
    created_at: createdAt,
    last_charge_date: lastChargeDate,
    cycle_start_date: cycleStartDate,
    cycle_end_date: cycleEndDate,
    currency,
    status,
    plan_name: planName,
    card_category: cardCategory,
    payment_value: paymentValue,
    transaction_volume: transactionVolume,
    payments
  } = data || {};

  useEffect(() => {
    if (payments && payments.length) {
      setActivePayment((payments as unknown) as PaymentDataType);
    }
  }, [payments]);

  return (
    <>
      <div className="row">
        {!isLoading && (
          <button type="button" className="btn btn-link" onClick={() => history.goBack()}>
            <i className="os-icon os-icon-arrow-left7 back-to-payouts" />
            <span>Go Back</span>
          </button>
        )}
      </div>
      <div className="row">
        <div className="col-sm-12">
          <div className="element-wrapper">
            <div>
              <div className="content-details-box business-info">
                <div className="info-summary-heading">
                  <div className="d-flex justify-content-between">
                    <div className="content-details-title biz-info-heading">
                      <div className="user-avatar-w">
                        <div className="user-avatar">
                          <Icon name="merchantAvatar" />
                        </div>
                      </div>
                      <div className="cd-title ml-3 info-subheading">
                        <div className="bulk-reference">
                          <h3 className="cd-maintitle main-title">{reference}</h3>
                        </div>
                        <p className="type-subtitle">{capitalizeRemovedash(type ?? '--')}</p>
                      </div>
                    </div>
                    <button
                      type="button"
                      onClick={() => {
                        setViewReceipt(true);
                      }}
                      className="view-receipt-btn"
                    >
                      View Receipt
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="detail-box billing-summary">
        <p className="sec-details-title mb-4">Invoice Details</p>
        {status?.includes('due') && (
          <div className="alert alert-warning text-center" role="alert">
            <strong>
              We were unable to successfully charge you for this payment, kindly top up your issuing balance to avoid service termination.
            </strong>
          </div>
        )}
        <section className="secondary-details__comp">
          <ul className="pr-4">
            <li className="sec-details-list d-flex justify-content-between">
              <span className="sec-details-key">Invoice Reference</span>{' '}
              <span className="sec-details-val" style={{ fontWeight: '500', color: '#2376F3' }}>
                {reference}
              </span>
            </li>
            <li className="sec-details-list d-flex justify-content-between">
              <span className="sec-details-key">Invoice Amount</span>{' '}
              <span className="sec-details-val">
                <strong>
                  {amount}&nbsp;{currency}
                </strong>
              </span>
            </li>
            <li className="sec-details-list d-flex justify-content-between">
              <span className="sec-details-key">Invoice Created At</span>{' '}
              <span className="sec-details-val">
                <strong>{getDate(createdAt ?? '')}</strong>&nbsp;{getTime(createdAt ?? '')}
              </span>
            </li>
            <li className="sec-details-list d-flex justify-content-between">
              <span className="sec-details-key">Last Charged At</span>{' '}
              <span className="sec-details-val">
                <strong>{getDate(lastChargeDate ?? '')}</strong>&nbsp;{getTime(lastChargeDate ?? '')}
              </span>
            </li>
            <li className="sec-details-list d-flex justify-content-between">
              <span className="sec-details-key">Billing Cycle Start date </span>{' '}
              <span className="sec-details-val">
                <strong>{getDate(cycleStartDate ?? '')}</strong>&nbsp;{getTime(cycleStartDate ?? '')}
              </span>
            </li>
            <li className="sec-details-list d-flex justify-content-between">
              <span className="sec-details-key">Billing Cycle End date </span>{' '}
              <span className="sec-details-val">
                <strong>{getDate(cycleEndDate ?? '')}</strong>&nbsp;{getTime(cycleEndDate ?? '')}
              </span>
            </li>
          </ul>
          <ul>
            <li className="sec-details-list d-flex justify-content-between">
              <span className="sec-details-key">Status</span>{' '}
              <span className="sec-details-val">
                {status === 'fully_paid' ? (
                  <span className="status-pill smaller align-baseline green" />
                ) : (
                  <span className="status-pill smaller align-baseline red" />
                )}
                {capitalizeRemovedash(status ?? '--')}
              </span>
            </li>
            <li className="sec-details-list d-flex justify-content-between">
              <span className="sec-details-key">Subscription Plan</span>{' '}
              <span className="sec-details-val">{capitalizeRemovedash(planName ?? '')}</span>
            </li>
            <li className="sec-details-list d-flex justify-content-between">
              <span className="sec-details-key">Billing Type</span>{' '}
              <span className="sec-details-val">{capitalizeRemovedash(type ?? '')} </span>
            </li>
            <li className="sec-details-list d-flex justify-content-between">
              <span className="sec-details-key">Card Category</span>{' '}
              <span className="sec-details-val">{capitalizeRemovedash(cardCategory ?? '')}</span>
            </li>
            <li className="sec-details-list d-flex justify-content-between">
              <span className="sec-details-key">Total Payment Value </span> <span className="sec-details-val">{paymentValue} </span>
            </li>
            <li className="sec-details-list d-flex justify-content-between">
              <span className="sec-details-key">Total Transaction Volume </span>{' '}
              <span className="sec-details-val">{transactionVolume}</span>
            </li>
          </ul>
        </section>
      </div>
      <div className="payments-attempts-comp">
        <h3>Payments Attempts</h3>
        <p>{payments?.length || 0} Attempt(s) recorded</p>
        <div className="payments-attempts-comp__table">
          <div className="payments-attempts-comp__table-left">
            {payments?.map((payment: PaymentDataType) => (
              <button type="button" className={`${activePayment === payment ? 'active' : ''}`} onClick={() => setActivePayment(payment)}>
                <span>
                  Charge attempt at{' '}
                  <strong>
                    {getDate(payment?.created_at)}, {getTime(payment?.created_at)}
                  </strong>
                </span>
                <span className={`status-pill smaller align-baseline ${switchStatus(payment?.status)}`} />
              </button>
            ))}
          </div>
          {activePayment && (
            <div className="payments-attempts-comp__table-right">
              <div className="payments-attempts-comp__table-row">
                <p>Status</p>
                {activePayment?.status && (
                  <span>
                    <strong style={{ color: '#24B314' }}>{capitalizeFirst(activePayment?.status)}</strong>
                  </span>
                )}
              </div>
              <div className="payments-attempts-comp__table-row">
                <p>Amount</p>
                <span>
                  <strong>
                    {activePayment.amount}
                    &nbsp;
                    {currency}
                  </strong>
                </span>
              </div>
              <div className="payments-attempts-comp__table-row">
                <p>Date Created</p>
                <span>
                  {getDate(activePayment?.created_at)} | {getTime(activePayment?.created_at)}
                </span>
              </div>
              <div className="payments-attempts-comp__table-row">
                <p>Debit Type</p>
                <span>{capitalize(activePayment.debit_type || '--')}</span>
              </div>
              <div className="payments-attempts-comp__table-row">
                <p>Reference</p>
                <span style={{ color: '#2376F3', fontWeight: '500' }}>
                  <Copyable text={activePayment.reference} copyText="" spanClassName="reference-text" buttonClassName="" />
                </span>
              </div>
            </div>
          )}
        </div>
      </div>
      {viewReceipt && (
        <ReceiptModal isOpen={viewReceipt} onClose={() => setViewReceipt(false)} receiptData={data ?? ({} as IBillingHistoryDetails)} />
      )}
    </>
  );
};

export default BillingHistoryDetails;
