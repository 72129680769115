import { useState } from 'react';
import { useQuery } from 'react-query';

import Table from '+containers/Dashboard/Shared/Table';
import { Tab, TabList, TabPanel, TabPanels, Tabs } from '+containers/Shared/Tabs';
import { useFeedbackHandler, useSearchQuery } from '+hooks';
import { formatAmount, logError } from '+utils';

import Filter from '../components/Filter/Filter';
import Summary from '../components/Summary';
import { billingHistoryTableProps, computeTableTitle, exportActionOptions, getBillingHistoryFilterProps, publicApi } from '../constants';
import { useFetchIssuingExport } from '../hooks';
import useFetchBilling from '../hooks/useFetchBilling';
import { BillingSubtabKeyType, IError } from '../types';
import { getIssuingPermissions } from '../utils';
import BillingHistoryRow from './components/BillingsHistoryRow';

const Billing = () => {
  const [recordIsFiltered, setRecordIsFiltered] = useState(false);
  const { canViewBillingHistory } = getIssuingPermissions();

  const { feedbackInit } = useFeedbackHandler();
  const { value: searchQueryValue, setQuery } = useSearchQuery();
  const limit = searchQueryValue?.limit || '10';
  const page = searchQueryValue?.limit || '1';
  const currency = searchQueryValue?.currency || 'USD';
  const subtab = (searchQueryValue?.subtab as BillingSubtabKeyType) ?? 'billing_history';
  const exportAction = useFetchIssuingExport({ resourceType: `All-${subtab}`, exportFn: exportActionOptions[subtab] });
  const filterProps = getBillingHistoryFilterProps();

  const { data: billingTrxnStats, refetch: refetchBillingTrxnStats } = useQuery(
    ['BILLING_TRXN_STATISTICS'],
    () => publicApi.fetchBillingStats({ currency }),
    {
      onError: (e: IError) => {
        logError(e);
        const message = e.response?.data?.message;
        feedbackInit({
          message,
          type: 'danger',
          action: {
            action: refetchBillingTrxnStats,
            name: 'Try again'
          }
        });
      },
      enabled: canViewBillingHistory
    }
  );

  const { tableData, isFetching: isFetchingTableData, refetch: refetchTableData } = useFetchBilling({
    page,
    limit,
    tab: subtab,
    queryValue: searchQueryValue
  });

  const paging = tableData?.data?.paging;

  return (
    <div>
      <div className="d-flex w-100 flex-wrap flex-sm-nowrap">
        <div className="flex-grow-1 info-summary-container">
          <Summary
            label={`Customer Cards TPV (${currency})`}
            value={billingTrxnStats?.data?.customer?.total_payment_value}
            valueFormatter={formatAmount}
            description="Total Payment Value for this month"
          />
          <Summary
            label="Customer Cards Count"
            value={billingTrxnStats?.data?.customer?.total_count}
            description="Transactions performed this month"
          />
          <Summary
            label={`Reserved Cards TPV (${currency})`}
            value={billingTrxnStats?.data?.reserved?.total_payment_value}
            valueFormatter={formatAmount}
            description="Total Payment Value for this month"
          />
          <Summary
            label="Reserved Cards Count"
            value={billingTrxnStats?.data?.reserved?.total_count}
            description="Transactions performed this month"
          />
        </div>
      </div>

      <Tabs defaultValue="billing_history" onChange={subtabValue => setQuery({ subtab: subtabValue })}>
        <div>
          <TabList className="mt-4">
            <Tab value="billing_history">
              <div className="d-flex align-items-center">
                <span>Billing History</span>
                <div className="table-counter ml-2">{paging?.total_items || 0}</div>
              </div>
            </Tab>
          </TabList>
          <div className="divide-y" />
        </div>

        <TabPanels>
          <TabPanel value="billing_history" className="transaction_table_comp table-container">
            <Filter
              totalItems={paging?.total_items as number}
              title={computeTableTitle({
                filtered: recordIsFiltered,
                activeTab: subtab as string,
                pageItemCount: paging?.total_items as number,
                singularTitle: 'Billing Invoice',
                pluralTitle: 'Billing Invoices'
              })}
              actions={canViewBillingHistory ? filterProps.actions : []}
              filterModalHeading={filterProps.filterModalHeading}
              filterModalDescription={filterProps.filterModalDescription}
              filterFields={filterProps.filterFields}
              exportType={filterProps.exportType}
              quickFilterType={subtab}
              exportHeading={filterProps.exportHeading}
              exportDescription={filterProps.exportHeading}
              onChangeIsFiltered={setRecordIsFiltered}
              isFiltered={recordIsFiltered}
              exportAction={exportAction}
            />

            <Table
              tableClassName={`--history-table ${billingHistoryTableProps.tableClassName}`}
              headings={billingHistoryTableProps.headings}
              hasPagination
              borderedTable
              loading={isFetchingTableData}
              current={paging?.current}
              limitAction={value => setQuery({ limit: String(value) })}
              pageSize={paging?.page_size}
              actionFn={value => setQuery({ page: String(value) })}
              totalItems={paging?.total_items || 0}
              emptyStateHeading={billingHistoryTableProps.emptyStateHeading}
              emptyStateMessage={
                <>
                  <span>{billingHistoryTableProps.emptyStateMessage}</span>
                  {canViewBillingHistory && (
                    <button type="button" className="refetch-button" onClick={() => refetchTableData()}>
                      <i className="os-icon os-icon-rotate-ccw mr-1" />
                      Refresh
                    </button>
                  )}
                </>
              }
            >
              <BillingHistoryRow rowData={tableData?.data?.data} />
            </Table>
          </TabPanel>
        </TabPanels>
      </Tabs>
    </div>
  );
};

export default Billing;
