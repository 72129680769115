import { parsePhoneNumberFromString } from 'libphonenumber-js';

let accPassword = null;

export const EmailValidation = value => {
  let errorMessage;
  if (!/^[A-Z0-9._+-]+@[A-Z0-9.-]+\.[A-Z]{2,63}$/i.test(value)) {
    errorMessage = 'Invalid email address';
  }
  if (!value) {
    errorMessage = 'Your email is required';
  }
  return errorMessage;
};

export const PhoneNumberValidation = value => {
  let errorMessage;
  if (!value) {
    errorMessage = 'A phone number is required';
    return errorMessage;
  }
  if (parsePhoneNumberFromString(value) && !parsePhoneNumberFromString(value).isValid()) {
    errorMessage = 'A valid phone number is required';
  }
  if (value?.length > 15) {
    errorMessage = 'A valid phone number is required';
  }

  return errorMessage;
};

export const EmailListValidation = (value, limit = Infinity) => {
  let errorMessage;
  const splitValue = value.split(', ');
  if (splitValue.length === 0) {
    errorMessage = 'Your email is required';
  } else if (splitValue.some(email => !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,62}$/i.test(email))) {
    errorMessage = `Invalid email address${splitValue.length > 1 ? 'es' : ''}`;
  } else if (splitValue.length > limit) {
    errorMessage = `You can only add ${limit} email${splitValue.length > 1 ? 's' : ''}`;
  } else if (new Set(splitValue).size !== splitValue.length) {
    errorMessage = `Remove duplicate email${splitValue.length > 1 ? 's' : ''}`;
  }
  return errorMessage;
};

export const PasswordValidation = value => {
  accPassword = value;
  const lowerCaseREGEX = new RegExp(/[a-z]/);
  const upperCaseREGEX = new RegExp(/[A-Z]/);
  // eslint-disable-next-line no-useless-escape
  const specialCharacterREGEX = new RegExp(/[!@#$%^&*()_+\-=\[\]{};':"\\|,.\/?]/);
  const numberREGEX = new RegExp(/[0-9]/);
  const invalidREGEX = new RegExp(/[<>]/);
  let errorMessage;
  if (!lowerCaseREGEX.test(value)) {
    errorMessage = 'Password must contain a lowercase letter';
  }
  if (!upperCaseREGEX.test(value)) {
    errorMessage = 'Password must contain an uppercase letter';
  }
  if (!numberREGEX.test(value)) {
    errorMessage = 'Password must contain a number';
  }
  if (!specialCharacterREGEX.test(value)) {
    errorMessage = 'Password should have a special character.';
  }
  if (invalidREGEX.test(value)) {
    errorMessage = 'Password should not contain angle brackets.';
  }
  if (!value) {
    errorMessage = 'Password is required';
  }
  return errorMessage;
};

export const PhoneValidation = value => {
  let errorMessage;

  if (parsePhoneNumberFromString(value) && !parsePhoneNumberFromString(value).isValid()) {
    errorMessage = 'A valid phone number is required';
  }
  if (value.length < 6) {
    errorMessage = 'A valid phone number is required';
  }
  if (value.length < 8) {
    errorMessage = 'A valid phone number is required';
  }
  if (!value) {
    errorMessage = 'A phone number is required';
  }
  return errorMessage;
};

export const ConfirmPasswordValidation = value => {
  let errorMessage;

  if (!value) {
    errorMessage = 'Confirm password is required';
  }

  if (value !== accPassword && value) {
    errorMessage = 'Confirm password does not match';
  }
  return errorMessage;
};

export const UsernameValidation = value => {
  const emailREGEX = new RegExp(/[A-z0-9._%+-]+@+[A-z0-9.-]+\.[A-z]{2,4}/);
  const numberREGEX = new RegExp(/^[0-9]*$/);
  let errorMessage;

  if (!emailREGEX.test(value)) {
    if (!numberREGEX.test(value)) {
      errorMessage = 'Please enter a valid phone number or email with no spaces';
    }
  }

  if (!value) {
    errorMessage = 'Your email or phone number is required';
  }
  return errorMessage;
};

export const DefaultRequiredValidation = (value, title) => {
  let errorMessage;

  if (!value) {
    errorMessage = `${title} is required`;
  }

  if (title === 'Amount' && value < 100) {
    errorMessage = `Amount should be a minimum of 100`;
  }

  return errorMessage;
};

export const URLValidation = value => {
  let errorMessage;
  if (!value.match(/^(http|https):\/\/([^\s.]+\.\S{2}|localhost[:?\d]*)\S*$/g)) {
    errorMessage = 'Please enter a valid URL';
  }
  if (!value) {
    errorMessage = 'Your business website is required';
  }
  return errorMessage;
};

export const twoFAInputValidation = (type, value) => {
  switch (type) {
    case 'otp':
      return value?.length > 4 && value?.length < 8;
    case 'totp':
      return value?.length === 6;
    case 'totp_recovery_code':
      return value?.length > 6 && value?.length < 13;
  }
};
