import { useRef, useState } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import APIRequest from '+services/api-services';
import useFeedbackHandler from '+hooks/feedbackHandler';
import Modal from '+containers/Dashboard/Shared/Modal';
import { formatAmount, getBase64, logError } from '+utils';
import ToolTip from '+containers/Dashboard/Shared/Tooltip';
import WarningIcon from '+assets/img/dashboard/warningIcon.svg';
import InfoIcon from '+assets/img/dashboard/exclamation.svg';
import TooltipIcon from '+assets/img/dashboard/information-button.svg';
import UpgradeIcon from '+assets/img/dashboard/upgradeIcon.svg';
import FileIcon from '+assets/img/dashboard/fileicon.svg';
import CancelIcon from '+assets/img/dashboard/cancel-icon-black.svg';
import { IModalProps } from '+types/virtual-account-types';
import '../index.scss';

type TError = {
  response: {
    data: {
      message: string;
    };
  };
};

const reasons = [
  { value: '', label: 'Select Reason' },
  { value: 'Fraud', label: 'Fraud' },
  { value: 'Suspected Fraud', label: 'Suspected Fraud' },
  { value: 'Other', label: 'Other' }
];

const upgradeReason = [
  { value: '', label: 'Select use Case' },
  { value: 'Fundraising', label: 'FundRaising' },
  { value: 'Increased Sales', label: 'Increased Sales' },
  { value: 'Other', label: 'Other' }
];

const api = new APIRequest(process.env.REACT_APP_PUBLIC_MERCHANT_MIDDLEWARE_API_BASE);

const ManageAccountsNumberModal = ({ close, type, action, reference, tier, actionReason, data, currency }: IModalProps) => {
  const maxUploadSize = process.env.REACT_APP_MAX_SUPPORTING_DOCUMENT_SIZE || 3;
  const queryClient = useQueryClient();
  const { feedbackInit } = useFeedbackHandler();
  const [state, setState] = useState(type);
  const hiddenFileInput = useRef(null);
  const [formData, setFormData] = useState({
    reason: '',
    action,
    reference
  });

  const [upgradeTierData, setUpgradeTierData] = useState({
    reason: '',
    tier_to: 2,
    use_case: '',
    use_case_document: '',
    uploadFileStatus: false,
    fileName: '',
    status: ''
  });

  const handleFileChange = async e => {
    const file = e.target.files[0];
    const fileName = e.target.files[0].name;
    const fileSize = e.target.files[0].size;
    const data = await getBase64(file);
    const formatFileSize = Math.floor(fileSize / 1024 ** 2);
    if (formatFileSize > Number(maxUploadSize)) {
      setUpgradeTierData(prev => {
        return { ...prev, status: 'error' };
      });
      return;
    }
    setUpgradeTierData(prev => {
      return { ...prev, use_case_document: data, status: 'success', uploadFileStatus: true, fileName };
    });
  };

  const handleClick = () => {
    hiddenFileInput?.current?.click();
  };

  const handleAction = useMutation(value => api.manageAccountNumber(value), {
    onSuccess: () => {
      queryClient.invalidateQueries(`${reference?.toUpperCase()}_DETAILS`);
    },
    onError: (error: TError) => {
      logError(error);
      feedbackInit({
        message: error?.response?.data?.message || `There has been an error`,
        type: 'danger'
      });
    }
  });
  const upgradeTier = useMutation(value => api.upgradeVirtualAccount(value), {
    onSuccess: () => {
      queryClient.invalidateQueries(`${reference?.toUpperCase()}_DETAILS`);
    },
    onError: (error: { response: { data: { message: string } } }) => {
      logError(error);
      feedbackInit({
        message: error?.response?.data?.message || `There has been an error upgrading your account number. Please try again later.`,
        type: 'danger',
        componentLevel: true
      });
    }
  });

  const isUpgradeButtonDisabled = () => {
    if (!upgradeTierData.reason || !upgradeTierData.use_case || !upgradeTierData.use_case_document) return true;
    return false;
  };

  const isSuspendButtonDisabled = () => {
    if (!formData.reason || formData.reason === 'Other') return true;
    return false;
  };

  const modalActions = () => {
    let content;
    switch (state) {
      case 'suspend':
        content = {
          heading: 'Suspend Account Number',
          description:
            'Suspending an account number stops the customer from making payments into the account number. Transactions will be flagged and will not be settled.',
          content: (
            <div className="suspend-account">
              <p style={{ color: '#414F5F', marginBottom: '5px' }}>Why are you suspending this account?</p>
              <select
                placeholder="Select an option ..."
                onChange={e => setFormData({ ...formData, reason: e?.target?.value })}
                value={formData.reason}
                className="form-control"
              >
                {reasons.map(option => (
                  <option key={option.value} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </select>
              {!['Fraud', 'Suspected Fraud', ''].includes(formData.reason) && (
                <>
                  <p style={{ color: '#414F5F', marginBottom: '5px' }}>Please tell us why</p>
                  <input
                    style={{ border: '1px solid #DDE2EC', outline: 'none', boxShadow: 'none' }}
                    type="text"
                    max={90}
                    onChange={e => setFormData({ ...formData, reason: e?.target?.value })}
                  />
                </>
              )}
            </div>
          ),
          firstButtonText: 'Cancel',
          secondButtonText: 'Proceed',
          firstButtonAction: close,
          secondButtonAction: () => setState('confirm-suspension'),
          secondButtonDisable: isSuspendButtonDisabled(),
          secondButtonActionIsTerminal: false,
          firstButtonColor: '#102649',
          firstButtonBackground: '#E0E7F0',
          size: 'md'
        };
        break;
      case 'confirm-suspension':
        content = {
          heading: 'Are you sure you want to suspend this account holder?',
          description:
            'Please confirm that you want to suspend this account number. This action blocks the account number thus transactions will not be settled',
          firstButtonText: 'Cancel',
          firstButtonAction: close,
          secondButtonText: 'Yes, Suspend',
          secondButtonAction: () => handleAction.mutateAsync(formData),
          secondButtonActionIsTerminal: true,
          firstButtonColor: '#102649',
          firstButtonBackground: '#E0E7F0',
          size: 'md'
        };
        break;
      case 'deactivate':
        content = {
          heading: 'Deactivate Account Number',
          description: 'Please note that this account number would be deactivated immediately. ',
          content: (
            <div>
              <p style={{ color: '#414F5F', marginBottom: '5px' }}>Please tell us why</p>
              <input
                onChange={e => setFormData({ ...formData, reason: e?.target?.value })}
                style={{ border: '1px solid #DDE2EC', outline: 'none', boxShadow: 'none' }}
                type="text"
              />
            </div>
          ),
          firstButtonText: 'Cancel',
          secondButtonText: 'Deactivate',
          firstButtonAction: close,
          secondButtonAction: () => setState('confirm-deactivation'),
          secondButtonDisable: !formData.reason,
          secondButtonActionIsTerminal: false,
          secondButtonColor: '#F32345',
          size: 'md'
        };
        break;
      case 'confirm-deactivation':
        content = {
          heading: <h1 style={{ fontSize: '16px' }}>Are you sure you want to deactivate this account number?</h1>,
          description: (
            <div className="confirm-deactivation">
              <img src={WarningIcon} alt="" />
              <p style={{ color: '#F32345', fontSize: '12px', fontWeight: 400 }}>
                Please note that this action is irreversible. Deactivated accounts can not be reactivated, you won't be able to carry out
                transactions with this account number.
              </p>
            </div>
          ),
          firstButtonText: 'Cancel',
          firstButtonAction: close,
          secondButtonText: 'Yes,Deactivate',
          secondButtonAction: () => handleAction.mutateAsync(formData),
          secondButtonActionIsTerminal: true,
          firstButtonColor: '#102649',
          firstButtonBackground: '#F32345',
          secondButtonColor: '#F32345',
          actionColor: '#F32345',
          size: 'sm'
        };
        break;
      case 'reactivate':
        content = {
          heading: 'Request to Reactivate Account Number',
          description: 'Please note that flagged transactions will be reprocessed and settled into this account number ',
          content: (
            <div>
              <p style={{ color: '#414F5F', marginBottom: '5px' }}>Please tell us why</p>
              <input
                onChange={e => setFormData({ ...formData, reason: e?.target?.value })}
                name="reason"
                value={formData.reason}
                style={{ border: '1px solid #DDE2EC', outline: 'none' }}
                type="text"
              />
            </div>
          ),
          firstButtonText: 'Cancel',
          secondButtonText: 'Reactivate',
          firstButtonAction: close,
          secondButtonAction: () => setState('confirm-reactivation'),
          secondButtonActionIsTerminal: false,
          secondButtonDisable: !formData.reason,
          firstButtonColor: '#102649',
          firstButtonBackground: '#F32345',
          size: 'md'
        };
        break;
      case 'confirm-reactivation':
        content = {
          heading: 'Are you sure you want to reactivate this account number?',
          description: 'Please note that account reactivation is subject to review and approval by our team',
          firstButtonText: 'Cancel',
          firstButtonAction: close,
          secondButtonText: 'Yes, Reactivate',
          secondButtonAction: () => handleAction.mutateAsync(formData),
          secondButtonActionIsTerminal: true,
          firstButtonColor: '#102649',
          firstButtonBackground: '#E0E7F0',
          size: 'md'
        };
        break;
      case 'upgrade':
        content = {
          heading: 'Upgrade Account Number to Tier 2',
          description: 'Upgrading to Tier 2 would increase the daily, monthly and transaction funding limits on this account number',
          content: (
            <div style={{ height: '300px', overflow: 'scroll' }}>
              <div className="upgrade-account-number-tier">
                <img src={InfoIcon} alt="" />
                <p style={{ margin: '0px', fontSize: '14px', color: '#414F5F' }}>
                  Please be aware that the upgrade process takes approximately <strong>24 hours</strong> to complete. We kindly request that
                  you verify the accuracy of all provided information to ensure a smooth upgrade.
                </p>
              </div>
              <p className="upgrade-text">Select use case</p>
              <select
                placeholder="Select an option ..."
                onChange={e => setUpgradeTierData({ ...upgradeTierData, use_case: e?.target?.value })}
                value={upgradeTierData.use_case}
                className="form-control"
              >
                {upgradeReason.map(option => (
                  <option key={option.value} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </select>
              {!['Fundraising', 'Increased Sales', ''].includes(upgradeTierData.use_case) && (
                <>
                  <p className="upgrade-text">Enter your use case</p>
                  <input
                    onChange={e => setUpgradeTierData({ ...upgradeTierData, use_case: e?.target?.value })}
                    style={{ boxShadow: 'none' }}
                    className="upgrade-input"
                    type="text"
                  />
                </>
              )}
              <p className="upgrade-text">Reason for upgrade</p>
              <input
                style={{ boxShadow: 'none' }}
                className="upgrade-input"
                onChange={e => setUpgradeTierData({ ...upgradeTierData, reason: e?.target?.value })}
                type="text"
              />
              <p className="upgrade-text">Supporting Document</p>
              <div className={`custom-file-upload ${upgradeTierData.status}`}>
                <p className="file-name">
                  <img src={FileIcon} alt="" />
                  <span>{upgradeTierData.uploadFileStatus ? upgradeTierData?.fileName : 'You have no document yet, drop file here'}</span>
                  <ToolTip
                    type="supporting-document"
                    classname="supporting-document-tooltip"
                    image={TooltipIcon}
                    message={
                      <>
                        <h1 className="supporting-document-header">Supporting Document</h1>
                        <p>
                          Supporting documents should match your reason for upgrading an account number. Please note that upgrade requests
                          may be declined if we notice a mismatch.
                        </p>
                      </>
                    }
                  />
                </p>

                {upgradeTierData.use_case_document ? (
                  <p className="uploaded-files">
                    Uploaded
                    <button
                      type="button"
                      onClick={() =>
                        setUpgradeTierData(prev => {
                          return { ...prev, use_case_document: '', uploadFileStatus: false, fileName: '', status: '' };
                        })
                      }
                    >
                      <img src={CancelIcon} alt="cancel Icon" />
                    </button>
                  </p>
                ) : (
                  <span className="browse-files" onClick={handleClick}>
                    Choose File
                  </span>
                )}
                <input ref={hiddenFileInput} type="file" onChange={e => handleFileChange(e)} alt="" />
              </div>
              <p style={{ fontSize: '12px', marginTop: '3px', color: upgradeTierData.status === 'error' ? '#F32345' : '#94A7B7' }}>
                {upgradeTierData.status === 'error'
                  ? `File size exceeded. Please upload a file that is ${maxUploadSize}MB or smaller`
                  : `Only pdf, docx, and png files are allowed (max ${maxUploadSize}MB each)`}
              </p>
              <div />
            </div>
          ),
          firstButtonText: 'Cancel',
          secondButtonText: 'Proceed',
          firstButtonAction: close,
          secondButtonAction: () =>
            upgradeTier.mutateAsync({
              reason: upgradeTierData.reason,
              tier_to: upgradeTierData.tier_to,
              reference,
              use_case: upgradeTierData.use_case,
              use_case_document: upgradeTierData.use_case_document
            }),
          secondButtonActionIsTerminal: true,
          secondButtonDisable: isUpgradeButtonDisabled(),
          firstButtonColor: '#102649',
          firstButtonBackground: '#E0E7F0',
          size: 'md'
        };
        break;
      case 'reason':
        content = {
          heading: `Reason for ${action}`,
          content: (
            <div>
              <p className="reason_for_action">{actionReason}</p>
            </div>
          ),
          firstButtonText: 'Dismiss',
          firstButtonAction: close,
          secondButtonActionIsTerminal: false,
          hideSecondButton: true,
          size: 'md'
        };
        break;
      default:
        content = {
          heading: (
            <div className="disclaimer-header">
              <p className="">
                <img width="80%" src={UpgradeIcon} alt="" />
              </p>

              <h1 style={{ fontSize: '18px' }}>Upgrade Account Number to Tier 2</h1>
            </div>
          ),
          content: (
            <div>
              <div className="disclaimer-content-wrapper">
                <p className="upgrade-sub-title">Upgrading your account number to Tier {upgradeTierData?.tier_to} would increase your:</p>
                <div className="upgrade-wrapper">
                  <p>Daily Maximum Funding Limit</p>
                  <p>
                    {formatAmount(data?.individual.tier_two?.daily)} {currency}
                  </p>
                </div>
                <div className="upgrade-wrapper">
                  <p>Monthly Maximum Funding Limit</p>
                  <p>
                    {' '}
                    {formatAmount(data?.individual.tier_two?.monthly)} {currency}
                  </p>
                </div>
                <div className="upgrade-wrapper no-border-bottom">
                  <p>Single Maximum Funding Limit</p>
                  <p>
                    {' '}
                    {formatAmount(data?.individual.tier_two?.single)} {currency}
                  </p>
                </div>
              </div>
              <p className="disclaimer-footer">Transaction limits vary and is based on your account type and currency.</p>
            </div>
          ),
          firstButtonText: 'Cancel',
          firstButtonAction: close,
          secondButtonText: 'Continue',
          secondButtonAction: () => setState('upgrade'),
          secondButtonActionIsTerminal: false,
          firstButtonColor: '#102649',
          firstButtonBackground: '#E0E7F0',
          size: 'sm'
        };
        break;
    }

    return {
      close,
      ...content
    };
  };
  return <Modal {...modalActions()} />;
};

export default ManageAccountsNumberModal;
