import React, { useRef } from 'react';

import Modal from './Modal';

import KpyLoader from '+assets/img/dashboard/kpy-loader.svg';

export default function FileViewer({ showView, setShowView, url }: { showView: boolean; setShowView: (e: boolean) => void; url: string }) {
  const loaderRef = useRef<HTMLImageElement>(null);
  return showView ? (
    <Modal
      close={() => setShowView(false)}
      size="md"
      heading="File Viewer"
      content={
        <section className="iframe-container">
          <img src={KpyLoader} alt="loader" ref={loaderRef} width="40px" />
          <iframe
            src={url}
            title="file-viewer"
            sandbox=""
            width="100%"
            height="500"
            style={{ border: 'none' }}
            onLoad={() => {
              if (loaderRef.current) loaderRef.current.style.display = 'none';
            }}
          />
        </section>
      }
      showButtons={false}
      description=""
      secondButtonText=""
    />
  ) : null;
}
