import { useQuery } from 'react-query';
import APIRequest from '+services/api-services';
import useFeedbackHandler from '+hooks/feedbackHandler';
import { currencyToCountryCode } from '+utils';

const api = new APIRequest();

type Options = {
  showError?: boolean;
};

export default function useBanks(currency = '', options: Options = {}) {
  const { feedbackInit } = useFeedbackHandler();
  const countryCode = currencyToCountryCode[currency.toUpperCase()];

  const result = useQuery(['BANKS', countryCode], () => api.fetchBanks(countryCode), {
    enabled: true,
    onError: options?.showError
      ? () => {
          feedbackInit({
            componentLevel: true,
            message: `There has been an error in getting the list of banks`,
            type: 'danger',
            action: {
              action: () => result.refetch(),
              name: 'Try again'
            }
          });
        }
      : undefined
  });

  return result;
}
