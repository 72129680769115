import { StateStorage } from 'zustand/middleware';
import CryptoJS from 'crypto-js';

const key = `${process.env.REACT_APP_ZEUS_TRIDENT}`;
export const encryptContent = (value: string) => {
  return CryptoJS.AES.encrypt(value, key).toString();
};

export const decryptContent = (value: string) => {
  const bytes = CryptoJS.AES.decrypt(value, key);
  return bytes.toString(CryptoJS.enc.Utf8);
};

const storage: StateStorage = {
  getItem: (name: string): string | null => {
    const item = sessionStorage.getItem(name);
    if (!item) return null;
    return decryptContent(item) || null;
  },
  setItem: (name: string, value: string): void => {
    sessionStorage.setItem(name, encryptContent(value));
  },
  removeItem: (name: string): void => {
    sessionStorage.removeItem(name);
  }
};

export const storageLocal: StateStorage = {
  getItem: (name: string): string | null => {
    const item = localStorage.getItem(name);
    if (!item) return null;
    return decryptContent(item) || null;
  },
  setItem: (name: string, value: string): void => {
    localStorage.setItem(name, encryptContent(value));
  },
  removeItem: (name: string): void => {
    localStorage.removeItem(name);
  }
};

export default storage;
