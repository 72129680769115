import { useMutation } from 'react-query';

import { useFeedbackHandler } from '+hooks';
import APIRequest from '+services/api-services';
import { logError } from '+utils';

import {
  CardActionPayloadType,
  CardFundingPayloadType,
  CardOpType,
  CardStatusUpdatePayloadType,
  CardTerminationPayloadType,
  IError,
  OtpResponseType,
  WithoutCardRef
} from '../types';

const publicApi = new APIRequest(process.env.REACT_APP_PUBLIC_MERCHANT_MIDDLEWARE_API_BASE);

type NoCardLimitsActionType = Exclude<CardOpType, 'manageCardLimits'>;

export default function useManageCardActions({
  ref,
  action,
  options
}: {
  ref: string;
  action: CardOpType;
  // eslint-disable-next-line no-unused-vars
  options: { onSuccess?: (data: OtpResponseType) => void; onError?: (err: IError) => void };
}) {
  const { feedbackInit } = useFeedbackHandler();
  const mutation = useMutation<OtpResponseType | any, IError, CardActionPayloadType>(Mutations(ref)[action as NoCardLimitsActionType], {
    onError: (error: IError) => {
      logError(error);
      const errMsg = error.response?.data?.message;
      feedbackInit({
        componentLevel: true,
        message: errMsg || 'We were unable to complete your action. Please try again',
        type: 'danger'
      });
      options.onError?.(error);
    },
    ...options
  });

  return mutation;
}

// eslint-disable-next-line no-unused-vars
const Mutations = (cardRef: string): Record<NoCardLimitsActionType, any> => ({
  terminate: (payload: WithoutCardRef<CardTerminationPayloadType>) => publicApi.terminateCard({ cardRef, ...payload }),
  suspend: (payload: WithoutCardRef<CardStatusUpdatePayloadType>) => publicApi.updateCardStatus({ cardRef, ...payload }),
  reactivate: (payload: WithoutCardRef<Exclude<CardStatusUpdatePayloadType, 'description'>>) =>
    publicApi.updateCardStatus({ cardRef, ...payload }),
  fundCard: (payload: WithoutCardRef<CardFundingPayloadType>) => publicApi.initiateCardFunding({ cardRef, ...payload })
});
