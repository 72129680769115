/* eslint-disable no-undef */
import { MemoExoticComponent, useEffect, useState } from 'react';
import { Link, Route, useLocation } from 'react-router-dom';

import useStore from '+store';

export type RouteToComponentType = {
  [x: string]: any;
  issuing?: any;
  balance?: (string | (() => JSX.Element))[] | undefined;
  payment_link?: (string | (() => JSX.Element))[] | undefined;
  payin?: (string | (() => JSX.Element))[] | undefined;
  payout?: (string | (() => JSX.Element))[] | undefined;
  settlement?: (string | (() => JSX.Element))[] | undefined;
  audit_log?: (string | (() => JSX.Element))[] | undefined;
  setting?: (string | any)[] | undefined;
  settlement_setting?: (string | (() => JSX.Element) | null)[] | undefined;
  api_configuration?: (string | MemoExoticComponent<() => JSX.Element> | null)[] | undefined;
  security?: (string | (() => JSX.Element) | null)[] | undefined;
  compliance?: (string | (() => JSX.Element) | null)[] | undefined;
  team?: (string | (() => JSX.Element) | null)[] | undefined;
  refund?: (string | (() => JSX.Element))[] | undefined;
  bulk_payouts?: (string | (() => JSX.Element))[] | undefined;
  conversions?: (string | (() => JSX.Element))[] | undefined;
};
export default function useRoles(matchRoutesToComponents: RouteToComponentType) {
  const location = useLocation();
  const { authDetails } = useStore() as { authDetails: Record<string, any> };
  const userToken = authDetails?.access_token;
  const [permissionsAccess, setPermissionsAccess] = useState<Record<string, any>>({});
  const [permissions, setPermissions] = useState<string[]>([]);

  const displayPermissions = (value: string) => {
    let routeLabel;
    switch (value) {
      case 'balance':
        routeLabel = 'Balances';
        break;
      case 'payment_link':
        routeLabel = 'Payment Links';
        break;
      case 'virtual_account':
        routeLabel = 'Virtual Accounts';
        break;
      case 'issuing':
        routeLabel = 'Issuing';
        break;
      case 'payin':
        routeLabel = 'Pay-ins';
        break;
      case 'payout':
        routeLabel = 'Payouts';
        break;
      case 'settlement':
        routeLabel = 'Settlements';
        break;
      case 'refund':
        routeLabel = 'Disputes';
        break;
      case 'audit_log':
        routeLabel = 'Audit Logs';
        break;
      case 'setting':
        routeLabel = 'Settings';
        break;
      case 'team':
        routeLabel = 'Team';
        break;
      case 'api_configuration':
        routeLabel = 'API Configuration';
        break;
      case 'security':
        routeLabel = 'Security';
        break;
      case 'identity':
        routeLabel = 'Identity';
        break;
      case 'compliance':
        routeLabel = 'Compliance';
        break;
      case 'checkout_customization':
        routeLabel = 'Checkout Appearance';
        break;
      case 'bulk_payouts':
        routeLabel = 'Bulk Payouts';
        break;
      case 'conversions':
        routeLabel = 'Conversions';
        break;
      default:
        routeLabel = value;
        break;
    }
    return routeLabel;
  };
  const hasSubSettingsPermission = (permissionsList: string[]) =>
    permissionsList.some(permission => permission.endsWith('_setting') || permission.endsWith('_settings'));

  useEffect(() => {
    if (userToken) {
      const jwt = JSON.parse(atob(userToken.split('.')[1]));
      const userPermissions = jwt?.permissions?.permissions || {};
      userPermissions.issuing = 'manage';
      userPermissions.identity = 'manage';
      userPermissions.conversions = 'view';
      userPermissions.bulk_payouts = userPermissions?.payout;
      const permissionKeys = Object.keys(userPermissions);
      if (!userPermissions.setting && hasSubSettingsPermission(permissionKeys)) {
        userPermissions.setting = 'view';
        permissionKeys.push('setting');
      }
      setPermissionsAccess(userPermissions);
      setPermissions(permissionKeys);
      useStore.setState({ permissions: jwt?.permissions?.permissions });
    }
  }, [userToken]);

  const createRoutes = () => {
    const routes: Record<string, any> = {
      base: [],
      settings: []
    };

    const router: any[] = [];

    const checkIfNestedRoute = (route: any) => {
      const [first, ...rest] = route.split('/');

      if (rest.length) {
        return first;
      }
      return 'base';
    };

    permissions.forEach(permission => {
      if (!matchRoutesToComponents[permission]) {
        return;
      }

      if (checkIfNestedRoute(matchRoutesToComponents[permission]?.[0]) !== 'base') {
        return;
      }

      router.push(
        <Route
          path={`/dashboard/${matchRoutesToComponents[permission]?.[0]}`}
          key={`/dashboard/${matchRoutesToComponents[permission]?.[0]}`}
          component={matchRoutesToComponents[permission]?.[2]}
        />
      );
    });

    permissions.forEach(permission => {
      let routeType = 'base';
      if (matchRoutesToComponents[permission] !== undefined) {
        routeType = checkIfNestedRoute(matchRoutesToComponents[permission]?.[0]);

        if (
          permissionsAccess[permission] === 'view' ||
          permissionsAccess[permission] === 'manage' ||
          permissionsAccess[permission] === 'export'
        ) {
          if (routeType === 'base') {
            routes[routeType].push(
              <li
                className={
                  location.pathname.includes(matchRoutesToComponents[permission]?.[0])
                    ? `${(!location.pathname.includes('/settings/') && matchRoutesToComponents[permission]?.[0] !== 'settings') ||
                      matchRoutesToComponents[permission]?.[0] === 'settings'
                      ? 'selected'
                      : ''
                    }`
                    : ''
                }
                key={`/dashboard/${matchRoutesToComponents[permission]?.[0]}`}
              >
                <Link to={{ pathname: `/dashboard/${matchRoutesToComponents[permission]?.[0]}`, state: permissionsAccess[permission] }}>
                  <div className="icon-w">
                    <div className={`os-icon ${matchRoutesToComponents[permission]?.[1]}`} />
                  </div>
                  <span>{displayPermissions(permission)}</span>
                </Link>
              </li>
            );
          } else {
            routes[routeType].push(
              <li key={`/dashboard/${matchRoutesToComponents[permission]?.[0]}`}>
                <Link to={{ pathname: `/dashboard/${matchRoutesToComponents[permission]?.[0]}`, state: permissionsAccess[permission] }}>
                  {displayPermissions(permission)}
                </Link>
              </li>
            );
          }
        }
      }
    });
    return [routes, router];
  };

  return createRoutes();
}
