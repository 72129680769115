import React, { useEffect, useState } from 'react';
import { useQuery, useQueryClient } from 'react-query';
import { Link } from 'react-router-dom';
import useFeedbackHandler from '+hooks/feedbackHandler';
import APIRequest from '+services/api-services';
import { formatAmount, history, logError } from '+utils';
import useStore from '+store';

import CurrencyPicker from '../../Shared/CurrencyPicker';
import AnalyticsGraph from './AnalyticsGraph';
import AnalyticsOverview from './AnalyticsOverview';
import InfoIcon from '+assets/img/dashboard/info-circle.svg';

import './index.scss';

const api = new APIRequest();

const WalletSummary = () => {
  const queryClient = useQueryClient();
  const { feedbackInit } = useFeedbackHandler();
  const [activeCurrency, setActiveCurrency] = useState('NGN');
  const { authDetails } = useStore();
  const userToken = authDetails?.access_token;
  const [allow, setAllow] = useState(false);

  const MERCHANT_ENV = useStore(store => store.merchantEnv);
  const CAN_GO_LIVE = queryClient.getQueryData('CAN_GO_LIVE');

  useEffect(() => {
    if (userToken) {
      const jwt = JSON.parse(atob(userToken.split('.')[1]));
      if (jwt?.permissions?.name === 'Owner' || jwt?.permissions?.name === 'Administrator' || jwt?.permissions?.name === 'Operations') {
        setAllow(true);
      }
    }
  }, [userToken]);

  const { data, refetch } = useQuery(['MERCHANT_BALANCES', MERCHANT_ENV], () => api.getBalances(), {
    onError: e => {
      const error = e.response?.data;
      logError(error?.message);
      const notLive = error?.message?.includes('not been approved');
      const message = notLive ? 'Your account has not been approved yet' : 'There has been an error in getting your balances';
      feedbackInit({
        message,
        type: 'danger',
        action: {
          action: () => (notLive ? history.push('/dashboard/settings/business') : refetch()),
          name: notLive ? 'Complete account setup' : 'Try again'
        }
      });
    },
    enabled: allow
  });

  const balances = data?.data || {};
  return (
    <div className="row element-section">
      <div className="col-sm-12 col-xxl-9">
        <div className="element-wrapper">
          <h4 className="element-header summary__header d-flex align-items-center" style={{zIndex: 3}}>
            Transaction Overview
            {Object.keys(balances).length > 0 && (
              <CurrencyPicker
                options={Object.keys(balances || {})}
                onChange={value => {
                  setActiveCurrency(value);
                }}
                activeCurrency={activeCurrency}
                id="balances__currency-switch"
              />
            )}
          </h4>
          <div className="element-border">
            <div className="element-box summary-container mt-0 pd-sm">
              {MERCHANT_ENV === 'test' ? (
                <div className="test-mode-analytics">
                  <p>
                    Switch to
                    <button
                      type="button"
                      className="btn btn-link"
                      onClick={() => {
                        if (CAN_GO_LIVE) {
                          const toggle = document.getElementById('env-toggler');
                          toggle.click();
                        } else {
                          history.push('/dashboard/settings/compliance');
                        }
                      }}
                    >
                      Live Mode
                    </button>
                    to see analytics.
                  </p>
                </div>
              ) : (
                <AnalyticsGraph currency={activeCurrency} />
              )}
              <div className="balance-container">
                <div className="invoice-desc">
                  <div className="el-tablo">
                    <div className="label">Available Balance ({activeCurrency})</div>
                    <div className="value mb-5">
                      {balances[activeCurrency]?.available_balance ? formatAmount(balances[activeCurrency].available_balance) : '--.--'}
                      {MERCHANT_ENV === 'test' && (
                        <div className="d-flex align-items-center">
                          <img className="mr-1" src={InfoIcon} />
                          <p>
                            <span>Test Balance </span>not actual funds.
                          </p>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <div className="invoice-desc">
                  <div className="el-tablo">
                    <div className="label">Ledger Balance ({activeCurrency})</div>
                    <div className="value">
                      {balances[activeCurrency]?.ledger_balance ? formatAmount(balances[activeCurrency].ledger_balance) : '--.--'}
                      {MERCHANT_ENV === 'test' && (
                        <div className="d-flex align-items-center mb-2">
                          <img className="mr-1" src={InfoIcon} />
                          <p>
                            <span>Test Balance </span>not actual funds.
                          </p>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                {allow && (
                  <Link to="balances">
                    See all balances <i className="os-icon os-icon-chevron-right" style={{ marginLeft: '10px' }} />
                  </Link>
                )}
              </div>
            </div>
            {MERCHANT_ENV === 'live' && <AnalyticsOverview currency={activeCurrency} />}
          </div>
        </div>
      </div>
    </div>
  );
};

export default React.memo(WalletSummary);
