import React, { useEffect, useRef } from 'react';
import tipIcon from '+assets/img/dashboard/tips-guide.svg';
import useStore from '+store';
import { IGuideListItem, ISetUserTipAttribute } from '+types/tips-and-guide';
import useLocalStore from '+store/localStore';
import './index.scss';

interface ITipsAndGuidesProps {
  isOpen: boolean;
  toggle: () => void;
  setStatus: (value: string) => void;
}

const TipsAndGuides: React.FC<ITipsAndGuidesProps> = ({ isOpen, toggle, setStatus }) => {
  const ref = useRef<HTMLDivElement>(null);
  const store = useLocalStore();
  const profile = useStore(state => state.profile);
  const handleOutsideClick = (event: any) => {
    if (!event.target.closest('.tip-guide-wrapper')) {
      toggle();
    }
  };

  const setTypeMapper: Record<string, (value: ISetUserTipAttribute) => void> = {
    ip: store.setIp,
    team: store.setTeam,
    audit: store.setAudit
  };

  useEffect(() => {
    if (isOpen) {
      document.addEventListener('click', handleOutsideClick);
    } else {
      document.removeEventListener('click', handleOutsideClick);
    }
    return () => {
      document.removeEventListener('click', handleOutsideClick);
    };
  }, [isOpen]);

  return (
    <div className={`tip-guide-wrapper ${isOpen ? 'visible' : ''}`} ref={ref} data-testid="tips-guide-wrapper">
      <div className="tip-header">
        <div>
          <img src={tipIcon} alt="" />
          <h4>Tips and Guides</h4>
        </div>
        <p>Stay informed about exciting features, and receive valuable tips on making the most of your Kora dashboard.</p>
      </div>
      <div className="tip-list-wrapper">
        {store.list?.[profile?.email]?.map((guide: IGuideListItem) => (
          <div
            className={`tip-item ${store[guide.type]?.[profile?.email]?.clicked ? 'is-clicked' : ''} `}
            key={guide.title}
            data-testid="tip-item"
            onClick={() => {
              setStatus(guide.type);
              setTypeMapper[guide.type]({ email: profile?.email, value: { clicked: true } });
            }}
            role="button"
            onKeyDown={() => null}
            tabIndex={0}
          >
            <p> {guide.title} </p>
            {!store[guide.type]?.[profile?.email]?.clicked && (
              <div>
                <span />
                <span>NEW</span>
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default TipsAndGuides;
