import { TRenderSvg } from './types';

const ArrowsLeftRight: TRenderSvg = ({
  width = '32',
  height = '33',
  viewBox = '0 0 32 33',
  fill = 'none',
  className = ''
}) => (
  <svg
    width={width}
    height={height}
    viewBox={viewBox}
    fill={fill}
    xmlns="http://www.w3.org/2000/svg"
    role="img"
    className={className}
  >
    <path d="M26.7083 23.5074L22.7083 27.5074C22.5206 27.695 22.2661 27.8004 22.0008 27.8004C21.7354 27.8004 21.4809 27.695 21.2933 27.5074C21.1056 27.3197 21.0002 27.0652 21.0002 26.7999C21.0002 26.5345 21.1056 26.28 21.2933 26.0924L23.587 23.7999H6.00079C5.73557 23.7999 5.48122 23.6945 5.29368 23.507C5.10614 23.3194 5.00079 23.0651 5.00079 22.7999C5.00079 22.5347 5.10614 22.2803 5.29368 22.0928C5.48122 21.9052 5.73557 21.7999 6.00079 21.7999H23.587L21.2933 19.5074C21.1056 19.3197 21.0002 19.0652 21.0002 18.7999C21.0002 18.5345 21.1056 18.28 21.2933 18.0924C21.4809 17.9047 21.7354 17.7993 22.0008 17.7993C22.2661 17.7993 22.5206 17.9047 22.7083 18.0924L26.7083 22.0924C26.8013 22.1852 26.875 22.2955 26.9253 22.4169C26.9757 22.5383 27.0016 22.6685 27.0016 22.7999C27.0016 22.9313 26.9757 23.0614 26.9253 23.1828C26.875 23.3042 26.8013 23.4145 26.7083 23.5074ZM9.29329 15.5074C9.48093 15.695 9.73542 15.8004 10.0008 15.8004C10.2662 15.8004 10.5206 15.695 10.7083 15.5074C10.8959 15.3197 11.0013 15.0652 11.0013 14.7999C11.0013 14.5345 10.8959 14.28 10.7083 14.0924L8.41454 11.7999H26.0008C26.266 11.7999 26.5204 11.6945 26.7079 11.507C26.8954 11.3194 27.0008 11.0651 27.0008 10.7999C27.0008 10.5347 26.8954 10.2803 26.7079 10.0928C26.5204 9.90523 26.266 9.79987 26.0008 9.79987H8.41454L10.7083 7.50737C10.8959 7.31973 11.0013 7.06524 11.0013 6.79987C11.0013 6.53451 10.8959 6.28001 10.7083 6.09237C10.5206 5.90473 10.2662 5.79932 10.0008 5.79932C9.73542 5.79932 9.48093 5.90473 9.29329 6.09237L5.29329 10.0924C5.20031 10.1852 5.12655 10.2955 5.07623 10.4169C5.0259 10.5383 5 10.6685 5 10.7999C5 10.9313 5.0259 11.0614 5.07623 11.1828C5.12655 11.3042 5.20031 11.4145 5.29329 11.5074L9.29329 15.5074Z" fill="#414F5F" />
  </svg>
);

export { ArrowsLeftRight };
