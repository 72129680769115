import { useQuery } from 'react-query';

import { getExistingFiltersFromQuery } from '+containers/Dashboard/Shared/FilterModal';
import { useFeedbackHandler } from '+hooks';
import { Currency } from '+types';
import { filteredOutObjectProperty, logError } from '+utils';
import queryParams from '+utils/queryParams';

import { publicApi } from '../constants';
import { billingHistoryFields } from '../data';
import { getIssuingPermissions } from '../utils';

interface IUseFetchBilling {
  currency?: Currency;
  tab: 'billing_history' | string;
  limit: string;
  page: string;
  queryValue: any;
}

export default function useFetchBilling({ currency = 'USD', tab, limit, page, queryValue }: IUseFetchBilling) {
  const { feedbackInit } = useFeedbackHandler();
  const { canViewBillingHistory } = getIssuingPermissions();

  const sortingParams = {
    ...filteredOutObjectProperty(queryValue, [
      queryParams.currency,
      queryParams.page,
      queryParams.limit,
      queryParams.tab,
      queryParams.subtab,
      queryParams.sorterType
    ])
  };

  const { data: tableData, isFetching, refetch } = useQuery(
    [QueryKeys[tab], limit, page, sortingParams, currency],
    () => QueryFns[tab]({ limit, page, currency }),
    {
      onError: (e: any) => {
        logError(e);
        const message = e.response?.data?.message;
        feedbackInit({
          message,
          type: 'danger',
          action: {
            action: refetch,
            name: 'Try again'
          }
        });
      },
      enabled: canViewBillingHistory
    }
  );

  return {
    tableData,
    isFetching,
    refetch
  };
}

const QueryFns = {
  billing_history: ({ limit, page, currency }: { limit: string; page: string; currency: Currency }) =>
    publicApi.fetchBillingHistory({
      limit,
      page,
      currency,
      ...getExistingFiltersFromQuery(billingHistoryFields)
    })
};

const QueryKeys: { [key: string]: string } = {
  billing_history: 'BILLING_HISTORY'
};
