import WarningIcon from '+assets/img/dashboard/warning-fill.svg';
import Modal from '+containers/Dashboard/Shared/Modal';
import useFeedbackHandler from '+hooks/feedbackHandler';
import APIRequest from '+services/api-services';
import { IModalProps } from '+types/virtual-account-types';
import { logError } from '+utils';
import { useState } from 'react';
import { useMutation, useQueryClient } from 'react-query';

const api = new APIRequest(process.env.REACT_APP_PUBLIC_MERCHANT_MIDDLEWARE_API_BASE);
const ManageAccountsHolderModal = ({ close, type, action, reference, actionReason }: IModalProps) => {
  const queryClient = useQueryClient();
  const { feedbackInit } = useFeedbackHandler();
  const [state, setState] = useState(type);
  const reasons = [
    { value: '', label: 'Select Reason' },
    { value: 'Fraud', label: 'Fraud' },
    { value: 'Suspected Fraud', label: 'Suspected Fraud' },
    { value: 'Other', label: 'Other' }
  ];

  const [formData, setFormData] = useState({
    reason: '',
    action,
    reference
  });

  const handleAction = useMutation(value => api.manageAccountHolder(value), {
    onSuccess: () => {
      queryClient.invalidateQueries(`${reference?.toUpperCase()}_DETAILS`);
    },
    onError: error => {
      logError(error);
      feedbackInit({
        message: error?.response?.data?.message || `There has been an error managing this account holder`,
        type: 'danger'
      });
    }
  });

  const isSuspendButtonDisabled = () => {
    if (!formData.reason || formData.reason === 'Other') return true;
    return false;
  };

  const modalActions = () => {
    let content;
    switch (state) {
      case 'suspend':
        content = {
          heading: 'Suspend Account Holder',
          description:
            'Suspending an account holder blocks the customer from making payments through all of the account numbers created under the account holder.',
          content: (
            <div>
              <p style={{ color: '#414F5F', marginBottom: '5px' }}>Why are you suspending this account holder?</p>
              <select
                placeholder="Select an option ..."
                onChange={e => setFormData({ ...formData, reason: e?.target?.value })}
                value={formData.reason}
                className="form-control"
              >
                {reasons.map(option => (
                  <option key={option.value} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </select>
              {!['Fraud', 'Suspected Fraud', ''].includes(formData.reason) && (
                <>
                  <p style={{ color: '#414F5F', marginBottom: '5px' }}>Please tell us why</p>
                  <input
                    style={{ border: '1px solid #DDE2EC', outline: 'none', boxShadow: 'none' }}
                    type="text"
                    max={90}
                    onChange={e => setFormData({ ...formData, reason: e?.target?.value })}
                  />
                </>
              )}
            </div>
          ),
          firstButtonText: 'Cancel',
          secondButtonText: 'Proceed',
          firstButtonAction: close,
          secondButtonAction: () => setState('confirm-suspension'),
          secondButtonActionIsTerminal: false,
          secondButtonDisable: isSuspendButtonDisabled(),
          firstButtonColor: '#102649',
          firstButtonBackground: '#E0E7F0',
          size: 'md'
        };
        break;
      case 'confirm-suspension':
        content = {
          heading: 'Are you sure you want to suspend this account holder?',
          description: 'Please note that this will suspend all account numbers generated under this account holder immediately',
          firstButtonText: 'Cancel',
          firstButtonAction: close,
          secondButtonText: 'Yes, Suspend',
          secondButtonAction: () => handleAction.mutateAsync(formData),
          secondButtonActionIsTerminal: true,
          firstButtonColor: '#102649',
          firstButtonBackground: '#E0E7F0',
          size: 'md'
        };
        break;
      case 'deactivate':
        content = {
          heading: 'Deactivate Account Holder',
          description: 'Please note that all account numbers generated under this account holder would be deactivated immediately. ',
          content: (
            <div>
              <p style={{ color: '#414F5F', marginBottom: '5px' }}>Please tell us why</p>
              <input
                onChange={e => setFormData({ ...formData, reason: e?.target?.value })}
                style={{ border: '1px solid #DDE2EC', outline: 'none' }}
                type="text"
              />
            </div>
          ),
          firstButtonText: 'Cancel',
          secondButtonText: 'Deactivate',
          firstButtonAction: close,
          secondButtonAction: () => setState('confirm-deactivation'),
          secondButtonActionIsTerminal: false,
          secondButtonDisable: !formData.reason,
          secondButtonColor: '#F32345',
          size: 'md'
        };
        break;
      case 'confirm-deactivation':
        content = {
          heading: <h1 style={{ fontSize: '16px' }}>Are you sure you want to deactivate this account holder?</h1>,
          description: (
            <div
              style={{
                background: '#FFD2DA',
                display: 'flex',
                padding: '10px',
                alignItems: 'flex-start',
                borderRadius: '4px',
                gap: '5px',
                marginTop: '20px'
              }}
            >
              <img src={WarningIcon} alt="" />
              <p style={{ color: '#F32345', fontSize: '14px', fontWeight: 400 }}>
                Please note that this will deactivate all account numbers generated under this account holder immediately
              </p>
            </div>
          ),
          firstButtonText: 'Cancel',
          firstButtonAction: close,
          secondButtonText: 'Yes,Deactivate',
          secondButtonAction: () => handleAction.mutateAsync(formData),
          secondButtonActionIsTerminal: true,
          secondButtonColor: '#F32345',
          size: 'sm'
        };
        break;
      case 'reactivate':
        content = {
          heading: 'Request to Reactivate Account Holder',
          description: 'Please note that all account numbers generated under this account holder would be reactivated immediately. ',
          content: (
            <div>
              <p style={{ color: '#414F5F', marginBottom: '5px' }}>Please tell us why</p>
              <input
                onChange={e => setFormData({ ...formData, reason: e?.target?.value })}
                name="reason"
                value={formData.reason}
                style={{ border: '1px solid #DDE2EC', outline: 'none' }}
                type="text"
              />
            </div>
          ),
          firstButtonText: 'Cancel',
          secondButtonText: 'Reactivate',
          firstButtonAction: close,
          secondButtonAction: () => setState('confirm-reactivation'),
          secondButtonActionIsTerminal: false,
          secondButtonDisable: !formData.reason,
          firstButtonColor: '#102649',
          firstButtonBackground: '#F32345',
          size: 'md'
        };
        break;
      case 'confirm-reactivation':
        content = {
          heading: 'Are you sure you want to reactivate this account holder?',
          description: 'Please note that account reactivation is subject to review and approval by our team',
          firstButtonText: 'Cancel',
          firstButtonAction: close,
          secondButtonText: 'Request Reactivation',
          secondButtonAction: () => handleAction.mutateAsync(formData),
          secondButtonActionIsTerminal: true,
          firstButtonColor: '#102649',
          firstButtonBackground: '#E0E7F0',
          size: 'md'
        };
        break;
      case 'reason':
        content = {
          heading: `Reason for ${action}`,
          content: (
            <div>
              <p className="reason_for_action">{actionReason}</p>
            </div>
          ),
          firstButtonText: 'Dismiss',
          firstButtonAction: close,
          secondButtonActionIsTerminal: false,
          hideSecondButton: true,
          size: 'md'
        };
        break;
      default:
        return null;
    }

    return {
      close,
      ...content
    };
  };
  return <Modal {...modalActions()} />;
};

export default ManageAccountsHolderModal;
