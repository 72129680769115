/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/require-default-props */
import DatePickerPrimitive, { ReactDatePickerProps } from 'react-datepicker';

import 'react-datepicker/dist/react-datepicker.css';
import './index.scss';

interface IDatePicker extends ReactDatePickerProps {
  disabled?: boolean;
  placeholder: string;
  minDate?: Date;
  maxDate?: Date;
  id: string;
  name: string;
  defaultValue?: Date;
  className: string;
  showYearPicker?: boolean;
}

const DatePicker = ({
  disabled,
  placeholder,
  minDate = new Date('1900-01-01'),
  maxDate = new Date(),
  id,
  name,
  defaultValue,
  value,
  selected,
  className,
  showYearPicker,
  onChange,
  onBlur,
  ...props
}: IDatePicker) => {
  const differenceInYears = new Date().getFullYear() - 1900;

  return (
    <div id={`portal-for${name}`}>
      <DatePickerPrimitive
        name={name}
        minDate={minDate}
        maxDate={maxDate}
        selected={selected}
        value={value}
        onChange={onChange}
        onBlur={onBlur}
        disabled={disabled}
        placeholderText={placeholder}
        id={id}
        portalId={`portal-for${name}`}
        showYearDropdown
        showMonthDropdown
        scrollableYearDropdown
        yearDropdownItemNumber={differenceInYears}
        {...props}
      />
    </div>
  );
};

export default DatePicker;
