import React from 'react';

import { IStatusRowProps } from '+types';
import { switchCurrency } from '+utils';

import { payoutMethodMapping } from '../../TransactionDetails/data';

type TSlugs = { [key: string]: { bank?: string; mmo?: string } };

const slugs: TSlugs = {
  NGN: {
    bank: process.env.REACT_APP_NGN_BANK_SLUG
  },
  KES: {
    bank: process.env.REACT_APP_KES_BANK_SLUG,
    mmo: process.env.REACT_APP_KES_MMO_SLUG
  },
  GHS: {
    mmo: process.env.REACT_APP_GHS_MMO_SLUG
  },
  ZAR: {
    bank: process.env.REACT_APP_ZAR_BANK_SLUG
  }
};

const bankPayoutSampleFile = process.env.REACT_APP_SAMPLE_FILE_BANK_PAYOUT;
const mobileMoneySampleFile = process.env.REACT_APP_SAMPLE_FILE_MOBILE_MONEY;

export const allowedCurrencyList = Object.entries(switchCurrency)
  .map(([code, name]) => ({ code, name }))
  .filter(currency => ['NGN', 'KES', 'GHS', 'ZAR'].includes(currency.code));

export const allowedPaymentMethods = Object.entries(payoutMethodMapping)
  .map(([code, name]) => ({ code, name }))
  .filter(paymentMethod => ['bank_account', 'mobile_money'].includes(paymentMethod.code));

export const StatusRow: React.FC<IStatusRowProps> = ({ isComplete, count, message, imgSrc }) => (
  <div>
    <span className="body-row-header" />
    <span className="show-failed-bulk">
      {isComplete && imgSrc && (
        <>
          <span>{count}</span>
          <span>{message}</span>
          <img className="switch-status" src={imgSrc} alt="statusIcon" height={15} width={15} />
        </>
      )}
    </span>
  </div>
);
export const bulkPayoutsSampleFile = (isBankTrx: boolean, currency: string) => {
  if (!currency) return null;

  const sampleFile = isBankTrx ? bankPayoutSampleFile : mobileMoneySampleFile;

  const tags = isBankTrx ? slugs[currency].bank : slugs[currency].mmo;

  return (
    <>
      <a href={sampleFile} className="link-text" download rel="noopener noreferrer">
        Download a sample {isBankTrx ? 'bank transfer' : 'mobile money'} Bulk Payout file
      </a>
      <a href={tags} download className="link-text" rel="noopener noreferrer">
        See {isBankTrx ? 'Bank' : 'Mobile Money'} Tags
      </a>
    </>
  );
};

export const bankTransferTableData = [
  {
    value: 'Reference'
  },
  {
    value: 'Bank'
  },
  {
    value: 'Account No.'
  },
  {
    value: 'Account Name'
  },
  {
    value: 'Description'
  },
  {
    value: 'Amount'
  },
  {
    value: ''
  }
];

export const mobileTransferTableData = [
  {
    value: 'Reference'
  },
  {
    value: 'Operator'
  },
  {
    value: 'Mobile No.'
  },
  {
    value: 'Account Name'
  },
  {
    value: 'Description'
  },
  {
    value: 'Amount'
  },
  {
    value: ''
  }
];
