import { TRenderSvg } from './types';

const ClockBlackFill: TRenderSvg = ({
  width = '20',
  height = '21',
  viewBox = '0 0 20 21',
  fill = 'none',
  className = ''
}) => (
  <svg
    width={width}
    height={height}
    viewBox={viewBox}
    fill={fill}
    xmlns="http://www.w3.org/2000/svg"
    role="icon"
    className={className}
  >
    <path fill-rule="evenodd" clip-rule="evenodd" d="M3.33464 10.7943C3.33464 7.11237 6.3194 4.1276 10.0013 4.1276C10.4616 4.1276 10.8346 3.7545 10.8346 3.29427C10.8346 2.83404 10.4616 2.46094 10.0013 2.46094C5.39893 2.46094 1.66797 6.1919 1.66797 10.7943C1.66797 15.3966 5.39893 19.1276 10.0013 19.1276C12.3022 19.1276 14.3867 18.1939 15.8939 16.6869C16.2193 16.3614 16.2193 15.8338 15.8939 15.5084C15.5684 15.1829 15.0408 15.1829 14.7154 15.5084C13.508 16.7157 11.8425 17.4609 10.0013 17.4609C6.3194 17.4609 3.33464 14.4762 3.33464 10.7943ZM10.8346 5.79427C10.8346 5.33404 10.4616 4.96094 10.0013 4.96094C9.54105 4.96094 9.16797 5.33404 9.16797 5.79427V10.7943C9.16797 11.0153 9.2558 11.2273 9.41205 11.3835L11.9121 13.8835C12.2375 14.2089 12.7651 14.2089 13.0906 13.8835C13.416 13.5581 13.416 13.0304 13.0906 12.705L10.8346 10.4491V5.79427ZM18.1249 13.4338C17.9471 13.9809 17.3595 14.2804 16.8123 14.1026C16.2652 13.9248 15.9658 13.3371 16.1436 12.79C16.3213 12.2429 16.909 11.9434 17.4561 12.1212C18.0032 12.299 18.3027 12.8866 18.1249 13.4338ZM17.5719 10.6499C18.1401 10.5599 18.5278 10.0263 18.4378 9.4581C18.3478 8.88986 17.8142 8.50219 17.2461 8.59219C16.6778 8.68218 16.2901 9.21577 16.3801 9.78402C16.4701 10.3522 17.0037 10.7399 17.5719 10.6499ZM16.9116 5.77362C17.2498 6.23905 17.1466 6.89047 16.6812 7.22862C16.2158 7.56677 15.5644 7.4636 15.2262 6.99817C14.8881 6.53275 14.9912 5.88132 15.4566 5.54317C15.9221 5.20502 16.5736 5.3082 16.9116 5.77362ZM14.3344 4.58464C14.5956 4.07205 14.3917 3.44478 13.8791 3.1836C13.3666 2.92242 12.7393 3.12623 12.4781 3.63882C12.217 4.15142 12.4207 4.77868 12.9334 5.03986C13.446 5.30104 14.0732 5.09723 14.3344 4.58464Z" fill="black" />
  </svg>
);

export { ClockBlackFill };
