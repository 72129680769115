/* eslint-disable no-param-reassign */
import React, { useEffect, useState } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import Tooltip from 'src/containers/Auth/Kyc/components/Tooltip';
import { useFeedbackHandler } from 'src/hooks';

import APIRequest from '+services/api-services';
import Modal from '+shared/Modal';
import useStore from '+store';
import { getDate, getTime, getTimeFromSeconds } from '+utils';

import CurrencyPicker from '../../Shared/CurrencyPicker';
import EmptyStateComponent from '../../Shared/EmptyState';
import DisclaimerModal from './disclaimerModal/index';

import CheckIcon from '+assets/img/dashboard/check-black.svg';
import InfoIcon from '+assets/img/dashboard/exclamation.svg';
import question from '+assets/img/dashboard/question-mark-dark.svg';

import './index.scss';

const api = new APIRequest();

const destinationOptions = {
  underpayment: [
    { name: 'Return All', value: 'return_all' },
    { name: 'Accept', value: 'accept' }
  ],
  overpayment: [
    { name: 'Accept', value: 'accept' },
    { name: 'Return Excess', value: 'return_excess' },

    { name: 'Return All', value: 'return_all' }
  ]
};

const confirmSendEmail = () => (
  <>
    You would need to confirm your changes by sending us an email. <b>Please note that this action will redirect you to your email app.</b>
  </>
);
const formatSettings = {
  accept: 'Accept',
  return_excess: 'Return Excess',
  return_all: 'Return All'
};

const PaymentPreference = () => {
  const defaultMerchant = useStore(state => state.defaultMerchant);
  const MERCHANT_ENV = useStore(state => state.merchantEnv);
  const { feedbackInit } = useFeedbackHandler();
  const QueryClient = useQueryClient();
  const [visible, setVisible] = useState(false);
  const [checked, setChecked] = useState(false);
  const [sendEmailModal, setSendEmailModal] = useState(false);
  const Currencies = QueryClient.getQueryData('CURRENCIES');
  const isBankTransferAvailableOnCurrency = currency => {
    const result = Currencies?.data.reduce((returnValue, element) => {
      if (currency === element.code) {
        const findBankTransfer = element?.meta_data?.payment_types?.payin.find(item => item.value === 'bank_transfer');
        if (findBankTransfer) {
          returnValue = true;
        }
      }
      return returnValue;
    }, false);
    return result;
  };

  const AvailableCurrencies = currencies => {
    const availableCurrencies = [];
    currencies?.data.forEach(currency => {
      if (currency.enabled) {
        availableCurrencies.push(currency.code);
      }
    });
    return availableCurrencies;
  };

  const EnabledCurrency = AvailableCurrencies(Currencies);

  const [activeCurrency, setActiveCurrency] = useState('NGN');
  const [showModal, setShowModal] = useState({
    reset: false,
    save: false,
    information: false,
    isEditing: false
  });
  const [modalstate, setModalState] = useState('');
  const [feedback, setFeedback] = useState(false);
  const [paymentPref, setPaymentPref] = useState({
    underpayment: '',
    overpayment: ''
  });

  const { data: DEFAULT_SETTINGS, refetch: refetchPaymentPreferenceSettings } = useQuery(
    'PAYMENT-SETTINGS',
    () => api.getMerchantPaymentPreference(),
    {
      onSuccess: data => {
        setPaymentPref(prev => {
          return {
            ...prev,
            underpayment: data?.settings?.payment_events?.underpayment?.action,
            overpayment: data?.settings?.payment_events?.overpayment?.action
          };
        });
      },
      onError: () => {
        feedbackInit({
          type: 'danger',
          message: 'Error fetching payment preference settings'
        });
      }
    }
  );

  const updatePaymentPreference = useMutation(data => api.setMerchantPaymentPreference(data), {
    onError: error => {
      throw error;
    },
    onSuccess: () => {
      setShowModal(prev => {
        return { ...prev, save: false, isEditing: false };
      });
      feedbackInit({
        type: 'success',
        message: 'Successfully updated your payment preference settings'
      });
    }
  });

  const resetSettings = useMutation(() => api.resetMerchantPaymentPreference(), {
    onError: error => {
      throw error;
    },
    onSuccess: () => {
      setShowModal(prev => {
        return { ...prev, reset: false };
      });
      feedbackInit({
        type: 'success',
        message: 'Successfully reset your payment preference settings'
      });
    }
  });

  const handleSave = async () => {
    const data = {
      payment_events: paymentPref
    };
    try {
      await updatePaymentPreference.mutateAsync(data);
      refetchPaymentPreferenceSettings();
    } catch (err) {
      feedbackInit({
        type: 'danger',
        message: 'There was an error updating your payment preference'
      });
      setShowModal(prev => {
        return { ...prev, save: false };
      });
      throw err;
    }
  };

  const handleCancel = () => {
    setShowModal(prev => {
      return { ...prev, isEditing: false };
    });
    setPaymentPref(prev => {
      return {
        ...prev,
        underpayment: DEFAULT_SETTINGS?.settings?.payment_events?.underpayment?.action,
        overpayment: DEFAULT_SETTINGS?.settings?.payment_events?.overpayment?.action
      };
    });
  };

  const handleSubmitRequest = () => {
    const recepientEmail = process.env.REACT_APP_SUPPORT_EMAIL || 'support@korapay.com';
    const subject = 'Please Update My Payment Preference';
    const emailBody = [
      'Hi Team,',
      '',
      `I would like to update my preference for handling overpayments and underpayments for Bank Transfers on ${defaultMerchant?.name}’s dashboard.`,
      '',
      `Overpayments: ${formatSettings[paymentPref.overpayment as keyof typeof formatSettings]}`,
      '',
      `Underpayments: ${formatSettings[paymentPref.underpayment as keyof typeof formatSettings]}`,
      '',
      'Thank you.'
    ].join('\n');

    const mailTo = `mailto:${recepientEmail}?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(emailBody)}`;
    window.open(mailTo);

    setPaymentPref(prev => ({
      ...prev,
      overpayment: DEFAULT_SETTINGS?.settings?.payment_events?.overpayment?.action,
      underpayment: DEFAULT_SETTINGS?.settings?.payment_events?.underpayment?.action
    }));
    setSendEmailModal(false);
  };

  const resetConfig = async () => {
    try {
      await resetSettings.mutateAsync();
      refetchPaymentPreferenceSettings();
    } catch (err) {
      feedbackInit({
        type: 'danger',
        message: 'There was an error resetting your payment preference, please try again later'
      });
      throw err;
    }
  };

  const getEnforcement = DEFAULT_SETTINGS?.apply_date;
  const paymentEvents = DEFAULT_SETTINGS?.settings?.payment_events;
  const enforceMentDelay = DEFAULT_SETTINGS?.apply_delay_in_seconds;

  const modalContent = () => {
    switch (modalstate) {
      case 'Underpayment':
        return (
          <section className="payment-preferences__modal-content">
            <article className="mb-4">
              <p>Underpayment occurs when a customer is expected to pay a fixed amount but pays less than the expected amount.</p>
            </article>
            <article className="my-4">
              <h6>Accept All</h6>
              <p>This means you will keep any underpayment made by a customer.</p>
            </article>
            <article className="my-4">
              <h6>Return All</h6>
              <p>We would return the amount paid by the customer instantly.</p>
            </article>
          </section>
        );
      case 'Overpayment':
        return (
          <section className="payment-preferences__modal-content">
            <article className="mb-4">
              <p>Over-payment occurs when a customer is expected to pay a fixed amount but pays more than the expected amount.</p>
            </article>
            <article className="my-4">
              <h6>Accept All</h6>
              <p>This means you will accept any overpayment made by a customer.</p>
            </article>
            <article className="my-4">
              <h6>Return All</h6>
              <p>We would return the amount paid by the customer instantly.</p>
            </article>
            <article className="my-4">
              <h6>Return Excess</h6>
              <p>We would receive the amount expected but return the excess amount</p>
            </article>
          </section>
        );
      default:
        return <div />;
    }
  };

  const resetContent = () => {
    return (
      <p className="modal-text">
        Undoing these changes would mean that they would no longer take effect. Please confirm that you want to undo the changes.
      </p>
    );
  };

  const updateContent = () => {
    return (
      <>
        <p className="modal-text">Please confirm that you want the changes made to your preferences to take effect.</p>
        <p className="modal-text__bold">
          <img src={InfoIcon} className="modal-text__image" alt="info icon" />
          <span>Note that your changes will take effect after {getTimeFromSeconds(enforceMentDelay)}</span>
        </p>
      </>
    );
  };

  const handleDropdownDisabled = () => {
    if (MERCHANT_ENV !== 'live') return true;
    if (paymentEvents?.last_updated_at) return false;
    if (DEFAULT_SETTINGS?.apply_status === 'pending') return true;
    return false;
  };

  // const handleDisabled = () => {
  //   if (paymentEvents?.last_updated_at) return true;
  //   return checked;
  // };

  useEffect(() => {
    const { overpayment, underpayment } = paymentPref;
    if (!underpayment && !overpayment) return;

    const preferenceChanged =
      underpayment !== DEFAULT_SETTINGS?.settings?.payment_events?.underpayment?.action ||
      overpayment !== DEFAULT_SETTINGS?.settings?.payment_events?.overpayment?.action;

    setShowModal(prev => ({ ...prev, isEditing: preferenceChanged }));
  }, [paymentPref.underpayment, paymentPref.overpayment]);

  return (
    <section className="payment-preference-wrapper">
      <div className="element-box payment-preferences">
        <h4 className="form-header">Payment Preference</h4>
        <div className="form-desc">Update and manage your payment preferences here. </div>
        <div>
          <CurrencyPicker
            options={EnabledCurrency}
            onChange={value => {
              setActiveCurrency(value);
            }}
            activeCurrency={activeCurrency}
            label={<span>Preferences for:</span>}
            id="balances__currency-switch"
          />
        </div>
        <legend>
          <span />
        </legend>
        {isBankTransferAvailableOnCurrency(activeCurrency) ? (
          <div className="payment-preferences__banktransfer">
            <div className="payment-preferences__left-header">
              <h5 className="form-header pt-0">Bank Transfer</h5>
              <p className="info-desc no-underline mb-1">
                Set your preferences for customer payments that are made through the Bank Transfer method.
              </p>
            </div>
            <div className="payment-preferences__dest-w grey-section">
              <div className="grey-section__subsection">
                <div className="grey-section__subsection-group">
                  {/* <div className="grey-section__subsection-item">
                    <div className="section-wrapper">
                      <h6 className="section-group">
                        <span className="section-group-text">Customizing Dynamic Virtual Accounts</span>
                        <span className="section-group-icon">
                          <Tooltip image={question}>
                            <div className="customise-tool-tip-wrapper">
                              <p>
                                <img src={CheckIcon} alt="check" />
                              </p>
                              <div>
                                <h1>Customizing Dynamic Virtual Accounts</h1>
                                <p>
                                  You can modify the way you would like to receive payments from your customers via the Dynamic Virtual
                                  Account. This modification can be done for different payment scenarios.
                                </p>
                              </div>
                            </div>
                          </Tooltip>
                        </span>
                      </h6>

                      <button
                        onClick={() => setVisible(true)}
                        data-testid="customise-button"
                        type="button"
                        className="btn"
                        disabled={handleDisabled()}
                      >
                        Customize
                      </button>
                    </div>
                  </div> */}
                  <div className="grey-section__subsection-item">
                    <div>
                      <h6 className="section-group">
                        Overpayments
                        <button
                          onClick={() => {
                            setModalState('Overpayment');
                            setShowModal(prev => {
                              return { ...prev, information: true };
                            });
                          }}
                          type="button"
                          className="ml-2"
                        >
                          <img src={question} alt="more information" aria-hidden />
                        </button>
                      </h6>

                      <p>Set your preference for when a customer makes an overpayment.</p>
                    </div>
                    <div>
                      <div className="form-group mb-0">
                        <select
                          name="destination"
                          className="form-control"
                          disabled={handleDropdownDisabled()}
                          onChange={e => {
                            const value = e?.target?.value;
                            setPaymentPref(prev => ({
                              ...prev,
                              overpayment: value
                            }));
                          }}
                          value={paymentPref.overpayment}
                        >
                          {destinationOptions.overpayment?.map(each => {
                            return (
                              <option key={each.value} value={each.value}>
                                {each.name}
                              </option>
                            );
                          })}
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="grey-section__subsection-group">
                  <div className="grey-section__subsection-item">
                    <div>
                      <h6 className="section-group">
                        Underpayments
                        <button
                          onClick={() => {
                            setModalState('Underpayment');
                            setShowModal(prev => {
                              return { ...prev, information: true };
                            });
                          }}
                          type="button"
                          className="ml-2"
                        >
                          <img src={question} alt="more information" aria-hidden />
                        </button>
                      </h6>
                      <p>Set your preference for when a customer makes an underpayment.</p>
                    </div>
                    <div>
                      <div className="form-group mb-0">
                        <select
                          name="destination"
                          className="form-control"
                          disabled={handleDropdownDisabled()}
                          onChange={e => {
                            const value = e?.target?.value;
                            setPaymentPref(prev => ({
                              ...prev,
                              underpayment: value
                            }));
                          }}
                          value={paymentPref.underpayment}
                        >
                          {destinationOptions.underpayment?.map(each => {
                            return (
                              <option key={each.value} value={each.value}>
                                {each.name}
                              </option>
                            );
                          })}
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
                {DEFAULT_SETTINGS?.apply_status !== 'pending' && showModal.isEditing && (
                  <div className="grey-section__subsection-group">
                    <div className="payment-preferences__feedback">
                      <img src={InfoIcon} alt="feedback icon" />
                      <p>
                        Please note that changes to this preference will not take effect immediately. You will need to send an email to
                        confirm your changes.
                      </p>
                    </div>
                    <div className="payment-preferences__controls">
                      <button className="btn btn-success " type="button" onClick={() => setSendEmailModal(true)}>
                        Continue
                      </button>
                      <span className="payment-preferences__divider-sm" />
                      <button className="btn btn--link" type="button" onClick={handleCancel}>
                        Cancel
                      </button>
                    </div>
                  </div>
                )}
                {DEFAULT_SETTINGS?.apply_status === 'pending' && (
                  <div className="grey-section__subsection-group">
                    <div className="payment-preferences__undocontrols">
                      <div className="payment-preferences__tooltip">
                        <Tooltip image={InfoIcon} title="Your Current Preferences">
                          <p>
                            While your changes will be effected by {`${getTime(getEnforcement)}, ${getDate(getEnforcement)}`}, your current
                            settings are still:
                          </p>
                          <ul>
                            <li>
                              Underpayments - {formatSettings[paymentEvents?.underpayment?.previous_action as keyof typeof formatSettings]}
                            </li>
                            <li>
                              Overpayments - {formatSettings[paymentEvents?.overpayment?.previous_action as keyof typeof formatSettings]}
                            </li>
                          </ul>
                        </Tooltip>
                        <span>
                          Your changes will be effected by <strong>{`${getTime(getEnforcement)}, ${getDate(getEnforcement)}`}</strong>
                        </span>
                      </div>
                      <span className="payment-preferences__divider-sm divide" />
                      <button
                        className="btn btn--link"
                        type="button"
                        onClick={() =>
                          setShowModal(prev => {
                            return { ...prev, reset: true };
                          })
                        }
                      >
                        <i className="os-icon os-icon-rotate-ccw" />
                        <span>Undo Changes</span>
                      </button>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        ) : (
          <EmptyStateComponent heading={null} message="You currently cannot set payment preferences for this currency" />
        )}
      </div>
      <Modal
        size="md"
        close={() =>
          setShowModal(prev => {
            return { ...prev, information: false };
          })
        }
        visible={showModal.information}
        heading={modalstate}
        content={modalContent()}
        showButtons={false}
      />
      <Modal
        heading="Undo Changes?"
        visible={showModal.reset}
        content={resetContent()}
        close={() =>
          setShowModal(prev => {
            return { ...prev, reset: false };
          })
        }
        secondButtonText="Yes, Undo"
        size="sm"
        secondButtonAction={resetConfig}
        showImage
        secondButtonActionIsTerminal={false}
      />
      <Modal
        heading="Confirm Changes?"
        visible={showModal.save}
        content={updateContent()}
        close={() =>
          setShowModal(prev => {
            return { ...prev, save: false };
          })
        }
        secondButtonText="Yes, Confirm"
        size="sm"
        secondButtonAction={handleSave}
        secondButtonActionIsTerminal={false}
      />
      <Modal
        visible={sendEmailModal}
        close={() => setSendEmailModal(false)}
        heading="Confirm Changes via Email"
        secondButtonActionIsTerminal={false}
        secondButtonText="Open Email App"
        secondButtonAction={handleSubmitRequest}
        size="md"
        content={confirmSendEmail()}
        secondButtonColor="#24B314"
      />
      {/* {visible && <DisclaimerModal close={() => setVisible(false)} type="customize-your-preference" onChecked={setChecked} />} */}
    </section>
  );
};

export default PaymentPreference;
