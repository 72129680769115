import React, { useState } from 'react';
import { useQuery } from 'react-query';
import { useHistory } from 'react-router-dom';
import useFeedbackHandler from '+hooks/feedbackHandler';
import { logError } from '+utils';
import useStore from '+store';
import APIRequest from '+services/api-services';

const api = new APIRequest();


export const useMerchantBalances = () => {
  const [balances, setBalances] = useState({});
  const { feedbackInit } = useFeedbackHandler();
  const MERCHANT_ENV = useStore(state => state.merchantEnv);
  const history = useHistory();

  const { data: getBalanceData, isFetching: fetchingBalances, refetch: refetchBalance } = useQuery(
    ['MERCHANT_BALANCES', MERCHANT_ENV],
    () => api.getBalances(),
    {
      onSuccess: data => setBalances(data?.data || {}),
      onError: (e: any) => {
        const error = e.response?.data;
        logError(error?.message);
        const notLive = error?.message?.includes('not been approved');
        const message = notLive ? 'Your account has not been approved yet' : 'There has been an error in getting your balances';
        feedbackInit({
          message,
          type: 'danger',
          action: {
            action: () => (notLive ? history.push('/dashboard/settings/business') : refetchBalance()),
            name: notLive ? 'Complete account setup' : 'Try again'
          }
        });
      }
    }
  );

  return { balances, getBalanceData, fetchingBalances, refetchBalance };
};
