import { useEffect, useState } from 'react';

export interface IQueries {
  [key: string]: string;
}

export interface IQueryMatch {
  [key: string]: boolean;
}

/**
 * @description A custom React Hook that listens for changes in media queries and returns an object
 * that indicates which queries currently match.
 *
 * @param {IQueries} queries - An object where the keys are names for the media queries and the values are the media query strings.
 *
 * @returns {IQueryMatch | null} An object that indicates which queries currently match. The keys are the same as in the `queries` parameter, and the values are booleans that indicate whether each media query currently matches. Returns `null` if `window` or `window.matchMedia` is not available.
 *
 * @example
 * const queries = { small: "(max-width: 599px)", medium: "(min-width: 600px) and (max-width: 1199px)", large: "(min-width: 1200px)" };
 * const queryMatch = useBreakpoints(queries);
 * // queryMatch might be { small: false, medium: true, large: false }
 *
 * @remarks
 * This hook sets up event listeners using `window.matchMedia` for each media query. When a media query's match status changes, it updates the returned `queryMatch` object.
 * It cleans up the event listeners when the component unmounts.
 */

const useBreakpoints = (queries: IQueries): IQueryMatch | null => {
  const [queryMatch, setQueryMatch] = useState<IQueryMatch | null>(null);

  useEffect(() => {
    const mediaQueryLists: { [key: string]: MediaQueryList } = {};
    const keys = Object.keys(queries);
    let isAttached = false;
    const handleQueryListener = () => {
      const updatedMatches = keys.reduce((acc: IQueryMatch, media) => {
        acc[media] = !!(mediaQueryLists[media] && mediaQueryLists[media].matches);
        return acc;
      }, {});
      setQueryMatch(updatedMatches);
    };

    if (window && window.matchMedia) {
      const matches: IQueryMatch = {};
      keys.forEach(media => {
        if (typeof queries[media] === 'string') {
          mediaQueryLists[media] = window.matchMedia(queries[media]);
          matches[media] = mediaQueryLists[media].matches;
        } else {
          matches[media] = false;
        }
      });
      setQueryMatch(matches);
      isAttached = true;
      keys.forEach(media => {
        if (typeof queries[media] === 'string') {
          mediaQueryLists[media].addListener(handleQueryListener);
        }
      });
    }

    return () => {
      if (isAttached) {
        keys.forEach(media => {
          if (typeof queries[media] === 'string') {
            mediaQueryLists[media].removeListener(handleQueryListener);
          }
        });
      }
    };
  }, [queries]);
  return queryMatch;
};

export default useBreakpoints;
