import { QueryClient } from 'react-query';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      cacheTime: (Number(process.env.REACT_APP_CACHE_TIME) || 15) * 60 * 1000,
      refetchOnWindowFocus: false,
      enabled: false,
      retry: 1
    }
  }
});

export default queryClient;
