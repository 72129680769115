import React, { RefObject, useEffect, useMemo, useState } from 'react';

import { useBreakpoints, useClickOutside } from '+hooks';
import { capitalizeRemovedash } from '+utils/formats';

import Modal from './Modal';

import './TabSwitch.scss';

interface ITabOption {
  [key: string]: string;
}
interface ITabSwitchPropsBase {
  setTab: (tab: string | ITabOption) => void;
  activeTab: string;
  className?: string;
  id?: string;
  maxVisibleOptions?: number;
  tabCount?: Record<string, number>;
  modalHeader?: string;
  allowedOptions?: Array<string | ITabOption>;
  showDropDown?: boolean;
}
interface ITabSwitchPropsWithObject extends ITabSwitchPropsBase {
  options: Array<ITabOption>;
  identifier: string;
}
interface ITabSwitchPropsWithoutObject extends ITabSwitchPropsBase {
  options: Array<string>;
  identifier?: string;
}
export type ITabSwitchPropsType = ITabSwitchPropsWithObject | ITabSwitchPropsWithoutObject;

const queries = {
  isMobile: '(max-width: 650px)'
};

const TabSwitch: React.FC<ITabSwitchPropsType> = ({
  options,
  activeTab,
  setTab,
  id,
  identifier = '',
  className,
  allowedOptions = [],
  showDropDown = true,
  maxVisibleOptions = 3,
  modalHeader = 'Select an option'
}) => {
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const [modalSelectedItem, setModalSelectedItem] = useState<string>(activeTab);
  const breakpoint = useBreakpoints(queries);
  const isMobile = ((breakpoint as unknown) as { isMobile: boolean })?.isMobile;
  const wrapperRef = useClickOutside(() => {
    setDropdownVisible(false);
  });

  const tabSwithMaxVisibleOptions = isMobile ? 3 : maxVisibleOptions;

  const visibleOptions = useMemo(() => {
    if (!options || !tabSwithMaxVisibleOptions) return [];

    const visible = options.slice(0, tabSwithMaxVisibleOptions);
    if (!visible.includes(activeTab as string & ITabOption)) {
      visible[tabSwithMaxVisibleOptions - 1] = activeTab as string & ITabOption;
    }

    return visible;
  }, [options, tabSwithMaxVisibleOptions, activeTab]);

  const hasDropdown = visibleOptions?.length < options?.length && showDropDown;

  useEffect(() => {
    if (!isMobile) {
      setDropdownVisible(false);
    }
  }, [activeTab]);
  const getOptionText = (option: string | ITabOption) => (typeof option === 'object' ? option[identifier] : option);

  const toggleDropdown = () => {
    setDropdownVisible(prevState => !prevState);
  };

  const handleModalItemSelect = () => {
    setTab(modalSelectedItem);
    toggleDropdown();
  };

  const getButtonClass = (option: any) => {
    const optionValue = getOptionText(option);
    const isActive = optionValue === activeTab;
    return `tab-switch__button ${isActive ? 'active' : ''}`;
  };

  const getButtonText = (option: any) => {
    const text = getOptionText(option);
    return id === 'audit__logs' ? capitalizeRemovedash(text) : text;
  };
  return (
    <div className={`tab-switch ${className} tab-switch__dropdown`} id={id} ref={wrapperRef as RefObject<HTMLDivElement>}>
      <ul className="tab-switch__list">
        {visibleOptions?.map(option => (
          <li key={getOptionText(option)}>
            <button type="button" className={getButtonClass(option)} onClick={() => setTab(option)} disabled={allowedOptions?.length > 0 ? !allowedOptions?.includes(option) : false}>
              {getButtonText(option)}
            </button>
          </li>
        ))}
        {hasDropdown && (
          <button className="tab-switch__button dropdown" type="button" onClick={toggleDropdown} data-testid="dropdown">
            <svg width="14" height="8" viewBox="0 0 14 8" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M1 1L7 7L13 1" stroke="#2376F3" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
            </svg>
          </button>
        )}
      </ul>
      {hasDropdown && dropdownVisible && !isMobile && (
        <div className="tab-switch__dropdown__content" role="menu">
          {options?.map((option, index) => (
            <button
              type="button"
              className={`tab-switch__button ${getOptionText(option) === activeTab ? 'active' : ''}`}
              onClick={() => setTab(option)}
              key={index}
              role="menuitem"
              aria-label={`Select ${getOptionText(option)}`}
            >
              {getButtonText(option)}
            </button>
          ))}
        </div>
      )}
      {hasDropdown && dropdownVisible && isMobile && (
        <Modal
          heading={modalHeader}
          size="sm"
          secondButtonText="Select"
          secondButtonAction={handleModalItemSelect}
          close={toggleDropdown}
          secondButtonActionIsTerminal
          description=""
          content={
            <div className="tab-switch__modal" role="menu">
              {options?.map((item, index) => {
                const isSelected = getOptionText(modalSelectedItem) === getOptionText(item);
                return (
                  <button
                    type="button"
                    role="menuitem"
                    key={index}
                    className={`tab-switch__modal__item ${isSelected ? 'active' : ''}`}
                    onClick={() => setModalSelectedItem(getOptionText(item))}
                    aria-label={`Select ${getOptionText(item)}`}
                  >
                    <div>
                      <span>{getButtonText(item)}</span>
                    </div>

                    <input type="radio" checked={isSelected} readOnly />
                  </button>
                );
              })}
            </div>
          }
        />
      )}
    </div>
  );
};

export default TabSwitch;
