/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect, useRef, useState } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import PhoneInput, { getCountryCallingCode } from 'react-phone-number-input';
import { useMutation, useQuery } from 'react-query';
import { Link } from 'react-router-dom';
import { Field, Form, Formik } from 'formik';

import APIRequest from '+services/api-services';
import { Storage } from '+services/storage-services';
import CustomReactSelect from '+shared/CustomReactSelect';
import Feedback from '+shared/Feedback';
import { actions, EmailValidation, history, logBreadCrumb, logError, PhoneNumberValidation } from '+utils';
import { breadCrumbEvents } from '+utils/bugsnag-events';

import ImgKorapayLogo from '+assets/img/logos/logo-kpy-ent.png';
import ImgKorapayLogoSvg from '+assets/img/logos/logo-kpy-ent.svg';

import 'react-phone-number-input/style.css';

const api = new APIRequest();

const SignupComponent = () => {
  const recaptchaRef = useRef({});
  const formRef = useRef({});
  const [password, setPassword] = useState('');
  const [focused, setFocused] = useState(false);
  const [emailFocused, setEmailFocused] = useState(false);
  const [cPassword, setCPassword] = useState(false);
  const [userEmail, setUserEmail] = useState('');

  const [state, setState] = useState({
    isLoading: false,
    feedback: {
      message: '',
      visible: false,
      type: 'danger'
    }
  });
  const specialChars = /[!@#$%^&*()_+\-=\]{};':"\\|,.<>?]+/;

  const onFocus = () => setFocused(true);
  const onBlur = () => setFocused(false);

  const onFocusCP = () => setCPassword(true);

  const [borderError, setBorderError] = useState(false);

  const [minChars, setMinChars] = useState(false);
  const [containSC, setContainSC] = useState(false);
  const [containLC, setContainLC] = useState(false);
  const [containUC, setContainUC] = useState(false);
  const [containNum, setContainNum] = useState(false);

  const [validPassword, setValidPassword] = useState(false);

  const checkMark = String.fromCodePoint(0x02713);
  const crossMark = String.fromCodePoint(0x02717);

  const validatePassword = () => {
    if (password.toLowerCase() !== password) setContainUC(true);
    else setContainUC(false);

    if (password.toUpperCase() !== password) setContainLC(true);
    else setContainLC(false);

    if (password.length > 7) setMinChars(true);
    else setMinChars(false);

    if (password.length < 1) setValidPassword(false);
    else setValidPassword(true);

    if (specialChars.test(password)) setContainSC(true);
    else setContainSC(false);

    if (/\d/.test(password)) setContainNum(true);
    else setContainNum(false);
  };

  const handlePassword = () => {
    if (minChars === true && containSC === true && containLC === true && containUC === true && containNum === true) {
      setFocused(false);
    } else if (
      password.length !== 0 &&
      (minChars === false || containSC === false || containLC === false || containUC === false || containNum === false)
    ) {
      setFocused(true);
    }
  };

  useEffect(() => {
    handlePassword();
    refetch();
  }, [minChars, containSC, containLC, containUC, containNum, focused]);

  const handleSubmit = values => {
    logBreadCrumb({ event: breadCrumbEvents.signup.signUpButtonClicked });
    if (recaptchaRef.current.execute) {
      setUserEmail(values.email);
      setState({ ...state, isLoading: true });
      actions.identify(values.email);
      const userData = {
        email: values.email,
        first_name: values.first_name,
        last_name: values.last_name,
        password,
        confirm_password: values.confirm_password,
        business_name: values.bname,
        phone_number: values.phone_number,
        country: values.country,
        phone_country_code: values.phone_country_code,
        sra: values.sra?.length > 0 ? values.sra : undefined
      };
      if (!values.sra) {
        delete userData.sra;
      }
      formRef.current = userData;
      recaptchaRef.current.execute();
    } else {
      setState({
        ...state,
        feedback: {
          message: 'Please wait while recatpcha loads and try again',
          visible: true,
          type: 'warning'
        }
      });
    }
  };

  const signupMutation = useMutation(data => api.signup(data), {
    retry: false,
    onSuccess: () => {
      Storage.setItem('USER_EMAIL', userEmail);
      setState({
        ...state,
        isLoading: false
      });
      actions.register({ email: userEmail });
      actions.track('Merchant signed up', {
        email: userEmail
      });
      return history.push('/auth/email-sent');
    },
    onError: e => {
      const error = e.response?.data;
      logError(error);
      window.scrollTo(0, 0);
      const errorMessage = () => {
        if (error?.error === 'bad_request' && Object.values(error?.data || {})[0]) {
          return Object.values(error?.data)[0].message;
        }
        if (error?.message) {
          return error.message;
        }
        return 'There seems to be an issue signing you up. Please contact support@korapay.com for more info.';
      };
      setState({
        ...state,
        isLoading: false,
        feedback: { ...state.feedback, message: `${errorMessage()}`, visible: true }
      });
    }
  });

  const signupAct = async () => {
    setState({ ...state, feedback: { ...state.feedback, visible: false, message: '' }, isLoading: true });
    signupMutation.mutate(formRef.current);
  };

  const recaptchaError = errorType => {
    recaptchaRef.current.reset();
    setState({
      ...state,
      isLoading: false,
      feedback: {
        message: `Recaptcha verification ${errorType}. Please refresh this page and retry.`,
        visible: true,
        type: 'warning'
      }
    });
  };

  const showFeedback = () => {
    const { feedback } = state;
    return feedback.visible ? <Feedback type={feedback.type} message={feedback.message} /> : '';
  };

  function getSpanColor(minChar, valPassword) {
    let color = '';
    if (minChar) {
      color = '#24B314';
    } else if (valPassword) {
      color = 'red';
    } else {
      color = '#607D8B';
    }
    return color;
  }

  const configErrorMessage = (errors, touched, values) => {
    let msg = null;

    if (errors.first_name && touched.first_name && values.first_name) {
      msg = errors.first_name;
    }
    if (errors.last_name && touched.last_name && values.last_name) {
      msg = errors.last_name;
    }
    if (errors.bname && touched.bname && values.bname) {
      msg = errors.bname;
    }
    if (errors.email && touched.email && values.email) {
      msg = errors.email;
    }
    if (errors.phone_number && touched.phone_number && values.phone_number) {
      msg = errors.phone_number;
    }
    if (errors.phone_country_code && touched.phone_country_code && values.phone_country_code) {
      msg = errors.phone_country_code;
    }
    return msg === null ? null : <Feedback type="danger" message={msg} />;
  };

  const [countries, setCountries] = useState([]);

  const { refetch } = useQuery(
    'countries',
    () => {
      return api.fetchCountries(true);
    },
    {
      onSuccess: data => {
        const options = data?.data.map(item => ({
          value: item.id,
          label: item.name
        }));
        return setCountries(options);
      }
    }
  );

  const defaultValue = (options, val) => {
    return options ? options.find(option => option.value === val)?.value : '';
  };

  return (
    <div className="auth__body">
      <div className="logo__section">
        <Link to="/">
          <img alt="Korapay Logo" src={ImgKorapayLogo} srcSet={ImgKorapayLogoSvg} />
        </Link>
        <h2>
          Sign up your
          <br />
          business on Kora
        </h2>
        <p>Create your business account effortlessly.</p>
      </div>

      <div className="form__section">
        <div className="mid-section">
          <h2>Business Information</h2>
          <p>This information would be used to create your account.</p>

          <Formik
            initialValues={{
              first_name: '',
              last_name: '',
              bname: '',
              email: '',
              country: 165,
              phone_number: '',
              phone_country_code: '',
              confirm_password: '',
              password: '',
              sra: ''
            }}
            onSubmit={handleSubmit}
            validate={values => {
              const errors = {};
              if (values.first_name.length < 2) errors.first_name = 'First Name is invalid';
              if (values.last_name.length < 2) errors.last_name = 'Last Name is invalid';
              if (values.bname.length < 2) errors.bname = 'Business Name is invalid';
              if (!values.email) errors.email = 'Email address is invalid';
              if (!values.country) errors.country = 'Country is invalid';
              if (!values.phone_country_code) errors.phone_country_code = 'Country code is invalid';
              if (values.confirm_password !== values.password) errors.confirm_password = 'Must match your password';
              if (!values.password) errors.password = 'Password is invalid';
              if (!values.tandc) errors.tandc = 'Terms and Conditions are required';
              if (values.sra.length < 3) errors.sra = 'SRA is invalid';
              return errors;
            }}
          >
            {({ errors, values, dirty, isValid, setFieldValue, touched, handleChange }) => {
              const allowOnlyText = (e, fieldName) => {
                setFieldValue(fieldName, e.target.value.replace(/[^a-zA-Z]/gi, ''));
              };
              const allowTextandSpace = (e, fieldName) => {
                setFieldValue(fieldName, e.target.value.replace(/[^A-Za-z ]/gi, ''));
              };
              return (
                <>
                  {<div>{showFeedback()}</div> || configErrorMessage(errors, touched, values)}
                  <Form autoComplete="off">
                    <div className="input_group">
                      <Field name="first_name">
                        {({ field, meta: { touched: touch, error } }) => (
                          <div className="input__wrap">
                            <label htmlFor={field.name} className="screen-reader-only">
                              First Name
                            </label>
                            <input
                              style={{ borderColor: touch && error ? 'red' : '' }}
                              autoComplete="off"
                              maxLength="100"
                              {...field}
                              onChange={e => allowOnlyText(e, field.name)}
                              type="text"
                              required
                              aria-label="first_name"
                            />
                          </div>
                        )}
                      </Field>

                      <Field name="last_name">
                        {({ field, meta: { touched: touch, error } }) => (
                          <div className="input__wrap">
                            <label htmlFor={field.name} className="screen-reader-only">
                              Last Name
                            </label>
                            <input
                              style={{ borderColor: touch && error ? 'red' : '' }}
                              autoComplete="off"
                              maxLength="100"
                              {...field}
                              onChange={e => allowOnlyText(e, field.name)}
                              type="text"
                              required
                              aria-label="last_name"
                            />
                          </div>
                        )}
                      </Field>
                    </div>

                    <Field name="bname">
                      {({ field, meta: { touched: touch, error } }) => (
                        <div className="input__wrap">
                          <label htmlFor="bname" className="screen-reader-only">
                            Business Name
                          </label>

                          <input
                            style={{ borderColor: touch && error ? 'red' : '' }}
                            {...field}
                            autoComplete="off"
                            type="text"
                            aria-label="business name"
                            required
                          />
                        </div>
                      )}
                    </Field>

                    <Field name="email" validate={EmailValidation}>
                      {({ field, meta: { touched: touch, error } }) => (
                        <div className="input__wrap">
                          <label htmlFor="email" className="screen-reader-only">
                            Business Email
                          </label>
                          <input
                            maxLength="100"
                            style={{ borderColor: touch && error ? 'red' : '' }}
                            {...field}
                            autoComplete="off"
                            type="email"
                            aria-label="email"
                            required
                            onFocus={() => setEmailFocused(true)}
                            onBlur={() => setEmailFocused(false)}
                          />
                          {emailFocused && (
                            <div className="email-notification mobile_bottom_space">
                              <p>Please use emails with your company's domain extensions or emails listed on your website.</p>
                            </div>
                          )}
                        </div>
                      )}
                    </Field>

                    <div className="input_group">
                      <div className="input__wrap">
                        <label htmlFor="Select country" className="screen-reader-only">
                          Country of Business Registration
                        </label>
                        <CustomReactSelect
                          id="country"
                          label="Country of Business Registration"
                          options={countries}
                          value={defaultValue(countries, values.country)}
                          placeholder="Nigeria"
                          aria-label="country"
                          onChange={value => {
                            setFieldValue('country', value.value);
                          }}
                        />
                      </div>

                      <Field name="phone_number" validate={PhoneNumberValidation}>
                        {({ field, meta: { touched: touch, error } }) => (
                          <div className="input__wrap phone-class">
                            <label htmlFor="Select country" className="screen-reader-only">
                              Phone Number
                            </label>
                            <PhoneInput
                              {...field}
                              className={`phone-input ${touch && error ? 'error' : ''}`}
                              international
                              defaultCountry="NG"
                              countryCallingCodeEditable={false}
                              country={values.phone_country_code}
                              onCountryChange={country => {
                                const dialCode = country ? getCountryCallingCode(country) : '234';
                                setFieldValue('phone_country_code', dialCode);
                              }}
                              value={values.phone_number || ''}
                              onChange={number => {
                                const code = values.phone_country_code ? values.phone_country_code : '234';
                                setFieldValue('phone_number', number);
                                !values.phone_country_code && setFieldValue('phone_country_code', code);
                              }}
                            />
                          </div>
                        )}
                      </Field>
                    </div>

                    <Field name="password" style={{ position: 'relative' }}>
                      {({ field }) => (
                        <div className="input__wrap">
                          <label htmlFor="password" className="screen-reader-only">
                            Password
                          </label>
                          <input
                            style={{ borderColor: focused === true ? 'red' : '' }}
                            {...field}
                            value={password}
                            name="password"
                            maxLength="100"
                            autoComplete="off"
                            onChange={e => {
                              setPassword(e.target.value);
                              setFieldValue('password', e.target.value);
                            }}
                            type="password"
                            onFocus={onFocus}
                            onBlur={onBlur}
                            onKeyUp={validatePassword}
                            required
                            aria-label="password"
                            data-testid="password-input"
                          />
                          {focused && (
                            <div className="password__strength mobile_bottom_space">
                              <p>Password must contain at least</p>
                              <div>
                                <span style={{ color: getSpanColor(minChars, validPassword) }}>{checkMark}</span>
                                <span style={{ color: getSpanColor(minChars, validPassword) }}> 8 characters </span>
                              </div>
                              <div>
                                <span style={{ color: getSpanColor(containUC, validPassword) }}>{checkMark}</span>
                                <span style={{ color: getSpanColor(containUC, validPassword) }}> 1 upper case letter (A-Z)</span>
                              </div>
                              <div>
                                <span style={{ color: getSpanColor(containLC, validPassword) }}>{checkMark}</span>
                                <span style={{ color: getSpanColor(containLC, validPassword) }}> 1 lower case letter (a-z)</span>
                              </div>
                              <div>
                                <span style={{ color: getSpanColor(containSC, validPassword) }}>{checkMark}</span>
                                <span style={{ color: getSpanColor(containSC, validPassword) }}> 1 special character (*@#-!...)</span>
                              </div>
                              <div>
                                <span style={{ color: getSpanColor(containNum, validPassword) }}>{checkMark}</span>
                                <span style={{ color: getSpanColor(containNum, validPassword) }}> Must contain number</span>
                              </div>
                            </div>
                          )}
                        </div>
                      )}
                    </Field>

                    <Field name="confirm_password" style={{ position: 'relative' }}>
                      {({ field }) => (
                        <div className="input__wrap mb-3 mt-3">
                          <label htmlFor="confirm_password" className="screen-reader-only">
                            Confirm Password
                          </label>
                          <input
                            style={{ borderColor: cPassword === true && values.confirm_password !== values.password ? 'red' : '' }}
                            {...field}
                            autoComplete="off"
                            type="password"
                            required
                            onChange={handleChange}
                            onFocus={onFocusCP}
                            aria-label="confirm_password"
                          />
                          {cPassword === true && values.confirm_password !== values.password ? (
                            <div className="cpassword__strength">
                              {' '}
                              <span
                                style={{ color: values.confirm_password.length !== values.password ? 'red' : '#607D8B' }}
                                className="pl-1"
                              >
                                {crossMark} {errors.confirm_password}
                              </span>
                            </div>
                          ) : null}
                        </div>
                      )}
                    </Field>

                    <Field name="sra">
                      {({ field, meta: { touched: touch, error } }) => (
                        <div className="input__wrap">
                          <label htmlFor="sra" className="screen-reader-only">
                            How did you hear about us?
                          </label>
                          <input
                            maxLength="50"
                            style={{ borderColor: touch && error ? 'red' : '' }}
                            {...field}
                            autoComplete="off"
                            type="text"
                            required
                            aria-label="sra"
                            placeholder="Help us learn!"
                            onChange={e => allowTextandSpace(e, field.name)}
                            minLength="3"
                          />
                        </div>
                      )}
                    </Field>

                    <div className="auth-check mt-3">
                      <input onChange={handleChange} value={values.tandc} name="tandc" type="checkbox" aria-label="terms" required />
                      <div className="check-label ml-2" htmlFor="isLiveTxn" style={{ textAlign: 'left' }}>
                        I agree to Kora&#39;s{' '}
                        <span className="link-text">
                          <a className="btn--link" href="https://korahq.com/merchant-service" target="_blank" rel="noopener noreferrer">
                            Merchant Service Agreement
                          </a>
                        </span>
                        , and the collection and processing of my personal data in accordance with Kora&#39;s{' '}
                        <span className="link-text">
                          <a className="btn--link" href="https://korahq.com/privacy-policy" target="_blank" rel="noopener noreferrer">
                            Privacy Notice
                          </a>
                        </span>
                        .
                      </div>
                    </div>
                    <div className="btn-wrapper mt-3">
                      <button
                        className="btn-kpy --full-blue"
                        type="submit"
                        disabled={!(dirty && isValid && minChars && containUC && containLC && containSC && containNum) || state.isLoading}
                      >
                        {state.isLoading ? <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true" /> : null}
                        {state.isLoading ? <span style={{ marginLeft: '0.5rem' }}>Submitting...</span> : 'Create Account'}
                      </button>
                      <ReCAPTCHA
                        ref={recaptchaRef as React.MutableRefObject<ReCAPTCHA>}
                        size="invisible"
                        sitekey={process.env.REACT_APP_POSEIDON_BOLT || ''}
                        onChange={signupAct}
                        onError={() => recaptchaError('error')}
                        onExpired={() => recaptchaError('expired')}
                      />
                    </div>
                    <div className="auth-check mt-4">
                      <div className="check-label terms-condition">
                        By creating an account you automatically agree to Kora&#39;s{' '}
                        <span className="link-text">
                          <a className="btn--link" href="https://korahq.com/terms-of-use" target="_blank" rel="noopener noreferrer">
                            Terms and Conditions
                          </a>
                        </span>
                        .
                      </div>
                    </div>
                  </Form>
                </>
              );
            }}
          </Formik>
        </div>
        <div className="right-section">
          <p className="account-text">
            Already have an account?{' '}
            <Link className="link-text" to="/auth/login">
              Log In
            </Link>
          </p>
        </div>
      </div>
    </div>
  );
};

export default SignupComponent;
