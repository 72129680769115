import { useCallback, useEffect, useRef, useState } from 'react';
import { CountDownProps } from '+types/common';

const Countdown = ({ countFrom, callbackFn }: CountDownProps) => {
  const [currentCount, setCurrentCount] = useState(countFrom);
  const timerInterval = useRef<NodeJS.Timer | null>(null);

  useEffect(() => {
    timerInterval.current = setInterval(() => {
      if (currentCount === 0) {
        clearInterval(timerInterval.current as NodeJS.Timer);
        callbackFn();
      } else {
        setCurrentCount(currentCount - 1);
      }
    }, 1000);
    return () => clearInterval(timerInterval.current as NodeJS.Timer);
  }, [currentCount]);

  return currentCount;
};

export const useCountdown = ({ isStartCount, delayRestartCountdown, isRestartCountdown, countFrom, callbackFn }: CountDownProps) => {
  const [currentCount, setCurrentCount] = useState<number>(countFrom);
  const timerInterval = useRef<NodeJS.Timer | null>(null);

  const resetCountdown = useCallback(() => {
    setCurrentCount(countFrom);
  }, [countFrom]);

  useEffect(() => {
    if (!isStartCount) return;
    timerInterval.current = setInterval(() => {
      if (currentCount === 0) {
        clearInterval(timerInterval.current as NodeJS.Timer);
        callbackFn();
        isRestartCountdown &&
          setTimeout(() => {
            resetCountdown();
          }, delayRestartCountdown || 1000);
      } else {
        setCurrentCount(currentCount - 1);
      }
    }, 1000);
    return () => clearInterval(timerInterval.current as NodeJS.Timer);
  }, [currentCount, isStartCount]);

  return { currentCount, resetCountdown };
};

export default Countdown;
