import { useState, useCallback, useEffect, useMemo } from 'react';
import { useDebouncedCallback } from 'use-debounce';
import useFeedbackHandler from '+hooks/feedbackHandler';
import { IConversionParams, IValidateAmountParams } from '+types';

const useValidateAmount = ({ payloadCurrency, amount, minConversionLimit, maxConversionLimit, availableBalance }: IConversionParams) => {
  const { feedbackInit, closeFeedback } = useFeedbackHandler();
  const [errorMessage, setErrorMessage] = useState('');

  const validateAmountLogic = ({ amount, payloadCurrency, minConversionLimit, maxConversionLimit, availableBalance, closeFeedback, setErrorMessage }: IValidateAmountParams) => {
    closeFeedback();
    const number = parseFloat(amount);

    if (!amount?.trim()) {
      setErrorMessage("");
      return false;
    }

    if (isNaN(number)) {
      setErrorMessage("Please enter a valid number.");
      return false;
    }

    if (number < minConversionLimit) {
      setErrorMessage(`
      Minimum conversion amount is 
      <strong>${minConversionLimit} ${payloadCurrency.from}</strong>
      `);
      return false;
    }

    if (number > maxConversionLimit) {
      setErrorMessage(`
      Maximum conversion amount is 
      <strong>${maxConversionLimit} ${payloadCurrency.from}</strong>
      `);
      return false;
    }

    if (number > availableBalance) {
      setErrorMessage(`You do not have sufficient funds.`);
      return false;
    }

    if (payloadCurrency.from === payloadCurrency.to) {
      setErrorMessage(`Same currency isn't allowed. Select a different destination currency.`);
      return false;
    }

    setErrorMessage("");
    return true;
  };

  const validateAmount = useCallback(() => {
    return validateAmountLogic({
      amount,
      payloadCurrency,
      minConversionLimit,
      maxConversionLimit,
      availableBalance,
      closeFeedback,
      setErrorMessage
    });
  }, [amount, payloadCurrency.from, minConversionLimit, maxConversionLimit, availableBalance, closeFeedback]);

  const debouncedValidateAmount = useDebouncedCallback(validateAmount, 300);

  useEffect(() => {
    debouncedValidateAmount();
  }, [amount, debouncedValidateAmount]);

  return { errorMessage, validateAmount, setErrorMessage };
};

export default useValidateAmount;
