import RadioButton from '+containers/Dashboard/Shared/RadioButton';

// eslint-disable-next-line no-unused-vars
const UpdateUnsubscribeReason = ({ value, onChange }: { value: string; onChange: (text: string) => void }) => {
  const reasons = ['I receive too many emails', 'Report data is not relevant to my business', 'For security reasons'];

  return (
    <div>
      <p>Are you sure you want to stop receiving product update report emails? If so, please select a reason and proceed.</p>
      <div>
        {reasons.map(reason => (
          <RadioButton
            key={reason}
            label={reason}
            checked={value === reason}
            onChange={() => onChange(reason)}
            className="my-3 reasons_for_failure"
          />
        ))}
      </div>
    </div>
  );
};

export default UpdateUnsubscribeReason;
