import React, { useState } from 'react';

import InfoSvg from '+assets/img/auth/info.svg';

import './index.scss';

interface ITooltipProps {
  title: string;
}

const Tooltip: React.FC<ITooltipProps> = ({ title }) => {
  let timeout: NodeJS.Timeout;
  const [active, setActive] = useState(false);

  const showTip = () => {
    timeout = setTimeout(() => {
      setActive(true);
    }, 400);
  };

  const hideTip = () => {
    clearInterval(timeout);
    setActive(false);
  };

  const displayTooltipContents: { [key: string]: JSX.Element } = {
    'Valid ID': (
      <>
        <p>
          It is important for us to know and verify the individuals running this business. This is why you need to provide a valid means of
          identification.
        </p>
        <p>Any one of these would be sufficient as a means of identification:</p>
        <ul>
          <li>International Passport</li>
          <li>Driver’s License</li>
          <li>Voter’s Card</li>
          <li>Virtual NIN</li>
        </ul>
        <p>The expiry date of presented documents should not be less than 1 month from today.</p>
      </>
    ),
    'Proof of Address': (
      <>
        <p>For extra verification, you are required to submit a proof of address that is a clear evidence of where you reside.</p>
        <p>Any one of these would be sufficient as a proof of address:</p>
        <ul>
          <li>Utility Bill (electric, water, cable bills)</li>
          <li>Bank Statement</li>
          <li>Insurance Policy or Bill</li>
          <li>Rental Contract</li>
        </ul>
        <p>Please note that this document must be recent, showing activity within 3 months prior to the date of submission.</p>
      </>
    ),
    'For Individuals': (
      <>
        <p>
          Individuals can manage payments on Kora without the need to be incorporated. Individual businesses includes consultants,
          freelancers, social media vendors, etc.
        </p>
        <p>To be verified as an individual business, you’ll need to provide the following:</p>
        <ul>
          <li>Valid Id</li>
          <li>Proof of Address</li>
        </ul>
      </>
    ),
    'For NGOs': (
      <>
        <p>
          Non-profit entities that are formed or function independently from any government can also manage business payments on Kora. This
          may include clubs, associations and humanitarion groups.
        </p>
        <p>Verification for NGOs will require:</p>
        <ul>
          <li>Certificate of Incorporation</li>
          <li>Proof of Address</li>
          <li>Approved Constitution</li>
          <li>SCUML Certificate</li>
          <li>AML Policy (optional)</li>
        </ul>
      </>
    ),
    'For Registered Businesses': (
      <>
        <p>
          Registered businesses can accept and manage online payments on Kora. This type of business must be duly with regulators of their
          country, and must be licensed to operate in their respective industries.
        </p>
        <p>Depending on the class of the registered business (SME or Non-SME), some of these documents may be required:</p>
        <ul>
          <li>Business Registration Document</li>
          <li>Certificate of Incorporation</li>
          <li>Memorandum & Articles of Association (MEMART)</li>
          <li>Proof of Address</li>
          <li>Applicable Licenses</li>
        </ul>
      </>
    ),
    'Certificate of Incorporation': (
      <p>A certificate of incorporation is the legal document/license relating to the formation of your company or organization</p>
    ),
    'Approved Constitution': (
      <p>This is the core corporate governance document that governs the management of your company or organization.</p>
    ),
    'SCUML Certificate': (
      <p>
        Designated non Financial Institutions (DNFIs/DNFBs) are required to obtain the SCUML registration certificate in order to enable
        them carry out transactions.
      </p>
    ),
    'Due Diligence Questionnaire': (
      <p>Some users are required to fill out the form below as part of their Onboarding, in order to be verified on the Kora platform. </p>
    ),
    'Add Other Documents': (
      <>
        <p>To share more information with us, you can submit up to 15 additional documents.</p>
        <p>Please note that this document must be recent, showing activity within 3 months prior to the date of submission.</p>
      </>
    ),
    'Add More Documents': (
      <>
        <p>To share more information with us, you can submit up to 3 additional documents.</p>
        <p>Please note that this document must be recent, showing activity within 3 months prior to the date of submission.</p>
      </>
    ),
    'Ultimate Beneficiaries': (
      <>
        <p>As part of our due diligence, we need to know who really owns the company that you are connected to.</p>
        <p>Enter the name of the person who ultimately benefits from the parent or shareholding company.</p>
      </>
    ),
    MEMART: (
      <p>
        These are legal documents that state the object, shareholders, and the number of shares of the company which is signed by all
        subscribers and witnesses to the agreement and formation of the company.
      </p>
    )
  };

  return (
    <div className="tooltip-wrapper" onMouseEnter={showTip} onMouseLeave={hideTip}>
      <img alt="Info" src={InfoSvg} />
      {active && (
        <div className="tooltip-tip right">
          <h3>{title}</h3>
          {displayTooltipContents[title]}
        </div>
      )}
    </div>
  );
};

export default Tooltip;
