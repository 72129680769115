import { TRenderSvg } from './types';

const CompleteTick: TRenderSvg = ({
  width="101",
  height="100", 
  viewBox="0 0 101 100",
  titleId,
  title,
  fill,
  descriptionId = '',
  description = '',
  className = ''
}) => (
 <svg 
  width={width}
  height={height}
  viewBox={viewBox}
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
    aria-labelledby={`${titleId} ${descriptionId}`}
    aria-hidden={!!title}
    className={className}
   >
    <title id={titleId}>{title}</title>
    <desc id={descriptionId}>{description}</desc>
    <path d="M100.5 50C100.5 22.3854 78.1146 0 50.5 0C22.8861 0 0.5 22.3854 0.5 50C0.5 77.6146 22.8861 100 50.5 100C78.1146 100 100.5 77.6146 100.5 50Z" fill="#01B352"/>
    <path d="M47.3843 59.5L39 50.3525L42.1845 47.0956L47.3331 52.7128L58.7666 40.5L62 43.6509L47.3843 59.5Z" fill="white"/>
</svg>
);

export { CompleteTick };
