import { useEffect, useState } from 'react';
import { getDateAndTime } from '+utils';
import { ChargebackStatusType, ISODateString } from '+types/transaction-types';
import clockIcon from '+assets/img/dashboard/clock.svg';
import historyIcon from '+assets/img/dashboard/history.svg';
import { chargebacksTrxnMessage } from '../data';

export type IssuanceChargebackHistoryPropType = {
  data: Array<{ date: ISODateString; status: ChargebackStatusType; evidence: string; description: string; wallet_reference: string }>;
};

const IssuanceChargebackHistory = ({ data }: IssuanceChargebackHistoryPropType) => {
  const MIN_ARRAY_LENGTH = 2;
  const [truncatedState, setTruncatedState] = useState({ isTruncated: true, truncatedLength: MIN_ARRAY_LENGTH });
  const [timelineData, setTimelineData] = useState<typeof data>([]);

  const handleTruncation = () => {
    if (truncatedState.isTruncated) {
      setTruncatedState(prevState => ({ ...prevState, isTruncated: false, truncatedLength: data?.length }));
    } else {
      setTruncatedState(prevState => ({ ...prevState, isTruncated: true, truncatedLength: MIN_ARRAY_LENGTH }));
    }
  };

  useEffect(() => {
    const reversedArray = data?.reduce(
      (accumulator, currentValue: typeof data[number]) => [currentValue, ...accumulator],
      [] as typeof data
    );
    const slicedArray = reversedArray?.slice(0, truncatedState.truncatedLength);
    setTimelineData(slicedArray);
  }, [data, truncatedState.truncatedLength]);

  return timelineData?.length > 0 ? (
    <>
      <div className="timeline__container">
        {timelineData?.map(datum => (
          <p key={`${datum?.date}`} className="timeline-item">
            <span className="timeline__date">
              <img src={clockIcon} alt="" className="timeline__icon" />
              {getDateAndTime(datum?.date)}
            </span>
            <span className="timeline__description">{chargebacksTrxnMessage[datum?.status]?.name}</span>
          </p>
        ))}
      </div>
      {data?.length > MIN_ARRAY_LENGTH && (
        <button type="button" onClick={handleTruncation} className="btn btn-link">
          <img src={historyIcon} alt="" />
          <span>{truncatedState.truncatedLength !== MIN_ARRAY_LENGTH ? `Show less` : `View history`}</span>
        </button>
      )}
    </>
  ) : (
    <p>Your chargeback history is unavailable</p>
  );
};

export default IssuanceChargebackHistory;
