import { useEffect, useState } from 'react';
import { useCopyToClipboard } from 'react-use';

import copyIcon from '+assets/img/dashboard/copy-icon-light.svg';

import './index.scss';

interface ICopyableProps {
  text: string;
  showOnHover?: boolean;
  copyText?: string;
  copyColor?: string;
  spanClassName?: string;
  buttonClassName?: string;
  textModifier?: (text: string) => string;
  showCopyText?: boolean;
  hideText?: boolean;
  iconSize?: number;
}
const Copyable = ({
  text,
  showOnHover,
  copyText,
  copyColor,
  spanClassName,
  buttonClassName,
  textModifier,
  showCopyText,
  hideText,
  iconSize
}: ICopyableProps) => {
  const [copied, setCopied] = useState(false);
  const [value, setValue] = useState('');
  const [hovered, setHovered] = useState(false);
  const [, copyToClipboard] = useCopyToClipboard();

  useEffect(() => {
    setValue(textModifier ? textModifier(text ?? '') : text);
  }, [text]);

  return (
    <span
      aria-label={`Copy ${text}`}
      role="button"
      data-testid="copyable-span"
      tabIndex={0}
      className={`copyable__comp ${spanClassName}`}
      style={copied ? { color: copyColor } : {}}
      onMouseEnter={() => {
        if (showOnHover) {
          setHovered(true);
          const newValue = `${value.substr(0, value.length - 5)}...`;
          setValue(newValue);
        }
      }}
      onMouseLeave={() => {
        if (showOnHover) {
          setHovered(false);
          setValue(textModifier ? textModifier(text) : text);
        }
      }}
      onClick={() => {
        copyToClipboard(text);
        (function _() {
          setCopied(true);
          setTimeout(() => {
            setCopied(false);
            setHovered(false);
            setValue(textModifier ? textModifier(text ?? '') : text);
          }, 800);
        })();
      }}
      onKeyDown={e => {
        if (e.key === 'Enter') {
          e.preventDefault();
          copyToClipboard(text);
          (function _() {
            setCopied(true);
            setTimeout(() => {
              setCopied(false);
              setHovered(false);
              setValue(textModifier ? textModifier(text ?? '') : text);
            }, 800);
          })();
        }
      }}
    >
      {copied ? (
        copyText || 'Copied!'
      ) : (
        <>
          {!hideText && value}
          {(hovered || !showOnHover) && (
            <button
              data-testid="copyable-button"
              aria-label="Copy Button"
              type="button"
              className={`copy-button ${buttonClassName}`}
              style={{ border: 'none' }}
              hidden={text === 'Not Available'}
            >
              <img id="copy-icon" width={iconSize} height={iconSize} src={copyIcon} alt="" />
              {showCopyText && <span>Copy</span>}
            </button>
          )}
        </>
      )}
    </span>
  );
};

export default Copyable;
