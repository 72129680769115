import { TRenderSvg } from './types';

const CaretDown: TRenderSvg = ({
  width = '12',
  height = '9',
  viewBox = '0 0 15 9',
  titleId,
  title,
  fill='none',
  descriptionId = '',
  description = '',
  className = ''
}) => (
 <svg 
  width={width}
  height={height}
  viewBox={viewBox}
  fill={fill}
  xmlns="http://www.w3.org/2000/svg"
    role="icon"
    aria-labelledby={`${titleId} ${descriptionId}`}
    aria-hidden={!!title}
    className={className}
   >
    <title id={titleId}>{title}</title>
    <desc id={descriptionId}>{description}</desc>
    <path d="M1.5 1.5L7.5 7.5L13.5 1.5" stroke="#3E4B5B" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
);

export { CaretDown };
