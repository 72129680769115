/* eslint-disable camelcase */
/* eslint-disable no-nested-ternary */
import { ReactElement, useState } from 'react';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';

import { useFeedbackHandler, useSearchQuery } from '+hooks';
import APIRequest from '+services/api-services';
import useStore from '+store';
import {
  APIDownload,
  capitalize,
  capitalizeRemovedash,
  daysfromToday,
  formatAmount,
  getDate,
  getTime,
  history,
  logBreadCrumb,
  logError
} from '+utils';
import { breadCrumbEvents } from '+utils/bugsnag-events';

import EmptyState from '../../Shared/EmptyState';
import ExportModal from '../../Shared/ExportModal';
import LoadingPlaceholder from '../../Shared/LoadingPlaceholder';
import SecondaryDetails from '../../Shared/SecondaryDetails';
import Table from '../../Shared/Table';

import BusinessAvatar from '+assets/img/dashboard/customer-avatar.svg';

import './index.scss';

const api = new APIRequest();

export default function SettlementDetails() {
  const { id } = useParams();
  const { feedbackInit } = useFeedbackHandler();
  const searchQuery = useSearchQuery();
  const paginationPage = searchQuery.value.page || '1';
  const activeMerchant = useStore(store => store.defaultMerchant);

  const [exportModalVisible, setExportModalVisible] = useState(false);

  const MERCHANT_ENV = useStore(store => store.merchantEnv);

  const { data: detailsData, isLoading: isFetchingDetails } = useQuery(`${id.toUpperCase()}_DETAILS`, () => api.getSettlement(id), {
    enabled: Boolean(MERCHANT_ENV),
    onError: () => {
      feedbackInit({
        message: "There has been an error this settlement's information",
        type: 'danger'
      });
    }
  });

  const { data: settlementTrxns, isFetching } = useQuery(
    [`${id.toUpperCase()}_TRANSACTIONS`, paginationPage],
    () => api.getSingleSettlementTransactions(id, paginationPage),
    {
      enabled: Boolean(MERCHANT_ENV),
      keepPreviousData: true,
      onError: () => {
        feedbackInit({
          message: "There has been an error getting this settlement's transactions",
          type: 'danger'
        });
      }
    }
  );

  const paging = settlementTrxns?.paging || {};
  const transactions = settlementTrxns?.data || [];
  const data = detailsData?.data || {};

  const exportSettlementTransactions = async format => {
    try {
      const res = await api.exportSettlementTransactions(id, format);
      const type = format === 'csv' ? 'csv' : 'xlsx';
      APIDownload(res, `Settlement ${id} transactions at ${getDate(Date.now())}`, type);
    } catch (error) {
      logError(error);
      setExportModalVisible(false);
      feedbackInit({
        message: `There has been an error exporting your transactions`,
        type: 'danger'
      });
      throw error;
    }
  };

  const getSettlementDetails = () => {
    const settlementDetails: { [key: string]: string | ReactElement }[] = [
      {
        'Total Transaction Amount': `${formatAmount(data.transactions_amount)} ${data.currency}`,
        'Total Transaction Fees (+ VAT)': `${formatAmount(parseFloat(data.fee, 10) + parseFloat(data.vat, 10))} ${data.currency}`,
        'Settlement Amount': `${formatAmount(data?.amount || 0)} ${data.currency}`
      },
      {
        'Settlement Status': (
          <>
            <span className={`status-pill smaller align-baseline ${!data.is_processed ? 'yellow' : 'green'}`} />
            {!data.is_processed ? 'Pending' : 'Settled'}
          </>
        ),
        'Payment Method':
          data.payment_method || data.category
            ? capitalize(
                data.payment_method
                  ? `${capitalizeRemovedash(data.payment_method)}${data.payment_method === 'card' ? ' Payment' : ''}`
                  : `${capitalize(data.category)} Payment`
              )
            : 'Not Available',
        'Settlement Cycle': data.type === 'instant' ? 'Instant' : capitalizeRemovedash(data.type || ''),
        'Date Created': (
          <>
            {getDate(data.createdAt)}
            <span className="smaller lighter" style={{ marginLeft: '5px' }}>
              {getTime(data.createdAt)}
            </span>
          </>
        )
      }
    ];

    if (!data.is_processed) {
      settlementDetails[1] = {
        ...settlementDetails[1],
        'Expected Settlement Date': data.expected_settlement_date ? daysfromToday(data.expected_settlement_date) : 'Not Available'
      };
    }
    if (data.is_processed) {
      settlementDetails[0] = {
        ...settlementDetails[0],
        'Settlement Fee (+ VAT)': `${formatAmount(
          parseFloat(data.settlement_payout?.fee || 0) + parseFloat(data.settlement_payout?.vat || 0)
        )} ${data.currency}`
      };
      settlementDetails[1] = {
        ...settlementDetails[1],
        'Settled To':
          data.destination && data.destination.type === 'settlement_account' ? (
            <span className="smaller lighter">
              {data.destination.value ? `${data.destination.value.bank_name} - ${data.destination.value.account_number}` : 'Not Available'}
            </span>
          ) : (
            'Korapay Balance'
          ),
        'Date Settled': data.processed_at ? (
          <>
            {getDate(data.processed_at)}
            <span className="smaller lighter" style={{ marginLeft: '5px' }}>
              {getTime(data.processed_at)}
            </span>
          </>
        ) : (
          'Not yet settled'
        )
      };
    }

    const amountSettled = (
      <span>
        <strong>{`${formatAmount(data.amount_settled || 0)} ${data.currency}`}</strong>
      </span>
    );

    settlementDetails[0] = {
      ...settlementDetails[0],
      ...(data?.sentinal_settlement && {
        'Sentinal VAT': (
          <>
            {data.sentinal_settlement.total_vat} {data.currency}
          </>
        ),
        'Sentinal Fee': (
          <>
            {data.sentinal_settlement.total_processing_fee} {data.currency}
          </>
        ),
        'Amount Settled': amountSettled
      }),
      'Amount Settled': amountSettled
    };
    return settlementDetails;
  };

  const reserveDetails = [
    {
      'Reserve Percentage': `${data?.rolling_reserve?.hold_rate}%`,
      'Reserved Amount': (
        <span>
          <strong>{`${formatAmount(data?.rolling_reserve?.amount)} ${data.currency}`}</strong>
        </span>
      ),
      'Hold/Reserve Period': `${data?.rolling_reserve?.hold_period || 0} ${data?.rolling_reserve?.hold_period === 1 ? 'Day' : 'Days'}`
    },
    {
      [data?.rolling_reserve?.reserved_at ? 'Reserve Date' : 'Created Date']: (
        <>
          {getDate(data?.rolling_reserve?.reserved_at || data?.rolling_reserve?.created_at)}
          <span className="smaller lighter" style={{ marginLeft: '5px' }}>
            {getTime(data?.rolling_reserve?.reserved_at || data?.rolling_reserve?.created_at)}
          </span>
        </>
      ),
      'Expected Release Date': data?.rolling_reserve?.available_on ? (
        <>
          {getDate(data?.rolling_reserve?.available_on)}
          <span className="smaller lighter" style={{ marginLeft: '5px' }}>
            {getTime(data?.rolling_reserve?.available_on)}
          </span>
        </>
      ) : (
        'Not Available'
      )
    }
  ];

  const settlementTransactions = () => {
    return (
      <>
        {transactions.map(each => {
          return (
            <div
              key={`settlement_${each.reference}`}
              className="div-table --column-5 --row --settlement-detail-table"
              onClick={() => {
                history.push(`/dashboard/pay-ins/${each.reference}`);
                logBreadCrumb({
                  event: breadCrumbEvents.settlements.settlementTableClicked,
                  data: { reference: each?.reference }
                });
              }}
              onKeyDown={() => history.push(`/dashboard/pay-ins/${each.reference}`)}
              role="button"
              tabIndex={0}
            >
              <div>
                <span className="body-row-header">Transaction Date:</span>
                <span>{getDate(each.createdAt)}</span>
                <span className="annotation" style={{ marginLeft: '5px' }}>
                  {getTime(each.createdAt)}
                </span>
              </div>
              <div>
                <span className="body-row-header">Transaction ID:</span>
                <span style={{ color: '#007bff', fontWeight: '500', textTransform: 'uppercase' }}>{each.payment?.reference}</span>
              </div>
              <div>
                <span className="body-row-header">Customer:</span>
                <span style={{ color: 'rgba(90, 99, 126, 0.49)' }}>{capitalize(each?.payment?.customer?.name || 'not available')}</span>
              </div>
              <div>
                <span className="body-row-header">Amount Paid:</span>
                <span className="bolder" style={{ fontWeight: '500' }}>
                  {formatAmount(each.amount_charged)}
                </span>
                <span className="annotation-currency" style={{ marginLeft: '5px' }}>
                  {each.currency}
                </span>
              </div>
              <div>
                <span className="body-row-header">Fee (+ Vat):</span>
                <span className="bolder" style={{ fontWeight: '500' }}>
                  {formatAmount(parseFloat(each.fee, 10) + parseFloat(each.vat, 10))}
                </span>
                <span className="annotation-currency" style={{ marginLeft: '5px' }}>
                  {each.currency}
                </span>
              </div>
              <div>
                <span className="body-row-header">Net Amount:</span>
                <span className="bolder" style={{ fontWeight: '500' }}>
                  {formatAmount(each.amount_paid)}
                </span>
                <span className="annotation-currency" style={{ marginLeft: '5px' }}>
                  {each.currency}
                </span>
              </div>
            </div>
          );
        })}
      </>
    );
  };

  return (
    <>
      <div className="row">
        <button type="button" className="btn btn-link" onClick={() => history.goBack()}>
          <i className="os-icon os-icon-arrow-left7" />
          <span>Go Back</span>
        </button>
      </div>
      <div className="row">
        <div className="col-sm-12">
          <div className="element-wrapper">
            <div className="element-box">
              <div className="content-details-box">
                <div className="content-details-head">
                  <div className="content-details-title">
                    <div className="user-avatar-w">
                      <div className="user-avatar">
                        <img alt="" src={BusinessAvatar} />
                      </div>
                    </div>
                    <div className="cd-title ml-3">
                      <h3 className="cd-maintitle">{data.reference && data.reference.toUpperCase()}</h3>
                      <p className="cd-subtitle">
                        {activeMerchant?.name} - {activeMerchant?.id}
                      </p>
                    </div>
                  </div>
                  <div className="cd-action">
                    <button
                      type="button"
                      className="btn btn-secondary"
                      style={{ background: 'none', border: 'none', color: '#2376F3', fontWeight: 600 }}
                      hidden={transactions.length === 0}
                      onClick={() => setExportModalVisible(true)}
                    >
                      <i className="os-icon os-icon-arrow-up-right" />
                      <span>Export Transactions</span>
                    </button>
                  </div>
                </div>
                <div className="detail-box">
                  {isFetchingDetails ? (
                    <LoadingPlaceholder type="text" content={4} />
                  ) : (
                    <>
                      {Object.keys(data || {}).length > 0 ? (
                        <>
                          <SecondaryDetails title="Settlement Summary" data={getSettlementDetails()} />
                          {data.is_processed && Object.keys(data?.rolling_reserve || {}).length > 0 && (
                            <>
                              <br />
                              <SecondaryDetails title="Reserve Summary" data={reserveDetails} />
                            </>
                          )}
                        </>
                      ) : (
                        <EmptyState message="There is an issue fetching this settlement's details" />
                      )}
                    </>
                  )}
                </div>
                <div className="content-details-body mt-4">
                  <div className="box-header mb-3">{`Transactions (${transactions.length})`}</div>
                  <Table
                    tableClassName="--column-5 --settlement-detail-table"
                    headings={[
                      {
                        value: 'Transaction Date'
                      },
                      {
                        value: 'Transaction ID'
                      },
                      {
                        value: 'Customer'
                      },
                      {
                        value: 'Amount Paid'
                      },
                      {
                        value: 'Fee (+ Vat)'
                      },
                      {
                        value: 'Net Amount'
                      }
                    ]}
                    hasPagination
                    loading={isFetching}
                    current={parseInt(paginationPage, 10)}
                    totalItems={paging?.total_items || 0}
                    pageSize={paging?.page_size || 0}
                    actionFn={current => searchQuery.setQuery({ page: current })}
                    annotation="settlements"
                    emptyStateMessage="There are no settlements yet."
                    hidden={transactions.length < 1}
                  >
                    {settlementTransactions()}
                  </Table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {exportModalVisible && <ExportModal close={() => setExportModalVisible(false)} action={exportSettlementTransactions} />}
    </>
  );
}
