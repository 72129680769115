export type PermissionsType = {
  api_configuration: string;
  audit_log: string;
  balance: string;
  bank_transfer_setting: string;
  chargeback: string;
  checkout_customization: string;
  compliance: string;
  documentation: string;
  ip_address_configuration: string;
  live_test_view: string;
  notification_configuration: string;
  payin: string;
  payment_link: string;
  payout: string;
  refund: string;
  security: string;
  setting: string;
  settlement: string;
  settlement_setting: string;
  support: string;
  team: string;
  virtual_account: string;
  conversions: string;
  limits: string;
};

export const PermissionColumnType = {
  view: 'view',
  export: 'export',
  manage: 'manage'
} as const;

export type UserRoleType = {
  id: number;
  name: string;
  usersCount: number;
  invitesCount: number;
  permissions: Record<string, typeof PermissionColumnType>;
  slug: string;
  category: string;
  createdAt: string;
  updatedAt: string;
  deletedAt: string | null;
};

export type UserType = {
  first_name: string;
  last_login: string;
  last_name: string;
  two_factor_enabled: number;
};

export type TeamMemberType = {
  email: string;
  role_id: number;
  status: string;
  user: UserType;
  userRole: UserRoleType;
  user_id: number;
};
