import React from 'react';
import CautionIcon from '+assets/img/dashboard/caution-icon.svg';
import ConfirimIcon from '+assets/img/dashboard/confirm-icon.svg';
import CloseIcon from '+assets/img/dashboard/close-icon.svg';

function IPNotification() {
  return (
    <div className="ip-notification">
      <p>
        <b>IP Whitelisting</b> is currently only available for <b>Payouts API</b>.
      </p>
    </div>
  );
}

export default IPNotification;
