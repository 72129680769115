import { CardAccessRequestStageType, RVCStageValidationType } from '../types';

export const minCharLength = 3;
export const maxDescriptionLength = 100;
export const maxAddressCharLength = 50;
export const maxPostalCodeLength = 10;
export const MAX_BVN_LEN = 11;
export const customerCardPlansDoc = process.env.REACT_APP_RVC_CUSTOMER_CARD_PLANS || '';
export const reservedCardPlansDoc = process.env.REACT_APP_RVC_BUSINESS_CARD_PLANS || '';

export const initialValues = {
  country: '',
  city: '',
  state: '',
  postalCode: '',
  street: '',
  useCase: '',
  reservedCardMonthlyPaymentValue: '',
  description: '',
  dob: '',
  bvn: '',
  bvn_firstName: '',
  bvn_lastName: '',
  customerCardMonthlyPaymentValue: '',
  pciDssLevel: '',
  subscriptionPlan: ''
};

export const stageValidationOptions: Partial<RVCStageValidationType<typeof initialValues>> = {
  BVN: formValues => {
    const { dob, bvn_firstName, bvn_lastName, bvn, subscriptionPlan } = formValues;
    const errors: Partial<typeof initialValues> = {};

    if (!bvn_firstName) errors.bvn_firstName = 'Your first name is required';
    if (!bvn_lastName) errors.bvn_lastName = 'Your last name is required';
    if (!dob) errors.dob = 'Date of birth is required';
    if (!bvn) errors.bvn = 'Your BVN is required';
    if (bvn && bvn.length < MAX_BVN_LEN) errors.bvn = `Your BVN cannot be less than ${MAX_BVN_LEN} digits`;

    return errors;
  },
  RESERVED_CARDS: formValues => {
    const { country, city, state, postalCode, street, useCase, reservedCardMonthlyPaymentValue, subscriptionPlan } = formValues;
    const errors: Partial<typeof initialValues> = {};

    if (!country) errors.country = 'A country is required';
    if (!city) errors.city = 'A city is required';
    if (city && city.length < minCharLength) errors.city = 'A valid city is required';
    if (!state) errors.state = 'A state is required';
    if (!postalCode) errors.postalCode = 'A postal code is required';
    if (postalCode && postalCode.length < minCharLength) errors.postalCode = 'A valid postal code is required';
    if (!street) errors.street = 'A street is required';
    if (street && street.length < minCharLength) errors.street = 'A valid street is required';
    if (!useCase) errors.useCase = 'A use case is required';
    if (!reservedCardMonthlyPaymentValue) errors.reservedCardMonthlyPaymentValue = 'A monthly payment value is required';
    if (!subscriptionPlan) errors.subscriptionPlan = 'A subscription plan is required';

    return errors;
  },
  CUSTOMER_CARDS: formValues => {
    const { customerCardMonthlyPaymentValue, pciDssLevel, subscriptionPlan } = formValues;
    const errors: Partial<typeof initialValues> = {};

    if (!customerCardMonthlyPaymentValue) errors.customerCardMonthlyPaymentValue = 'A monthly payment value is required';
    if (!pciDssLevel) errors.pciDssLevel = 'A PCI DSS level is required';
    if (!subscriptionPlan) errors.subscriptionPlan = 'A subscription plan is required';

    return errors;
  }
};

export const requestAccessOptions: Array<{ title: string; value: CardAccessRequestStageType; description: string }> = [
  {
    title: 'Get reserved virtual cards for your business',
    value: 'RESERVED_CARDS',
    description:
      'You can issue a limited number of cards for your business and employees. These cards can be generated and managed on your dashboard.'
  },
  {
    title: 'Issue virtual cards to your customers',
    value: 'CUSTOMER_CARDS',
    description:
      'You can issue virtual cards to your customers, number of cards issuable is determined by your PCI DSS level. These cards can be generated via API and managed on the dashboard.'
  }
];

export const useCaseOptions = [
  { label: 'Employee expense management', value: 'EmployeeExpenseMgt' },
  { label: 'Procurement', value: 'procurement' },
  { label: 'Payroll', value: 'payroll' },
  { label: 'Personal expense management', value: 'personalExpenseMgt' },
  { label: 'Other', value: 'other' }
];

export const subscriptionPlanOptions = [
  { label: 'Startup', value: 'Startup' },
  { label: 'Growth', value: 'Growth' },
  { label: 'Enterprise', value: 'Enterprise' }
];

export const customerCardMonthlyPaymentOptions = [
  { value: 'LessThan50K', label: '$50,000 and below' },
  { value: 'From50KTo100K', label: 'Between $50,000 - $100,000' },
  { value: 'From100KTo500K', label: 'Between $100,000 - $500,000' },
  { value: 'From500KTo1M', label: 'Between $500,000 - $1,000,000' },
  { value: 'Above1M', label: 'Above $1,000,000' }
];

export const reservedCardMonthlyPaymentOptions = [
  { value: 'LessThan1K', label: '$1,000 and below' },
  { value: 'From1KTo5K', label: 'Between $1,000 - $5,000' },
  { value: 'From5KTo10K', label: 'Between $5,000 - $1,0000' },
  { value: 'From10KTo50K', label: 'Between $10,000 - $50,000' },
  { value: 'Above50K', label: 'Above $50,000' }
];

export const pciDssLevels = [
  { value: 'level_1', label: '1' },
  { value: 'level_2', label: '2' },
  { value: 'level_3', label: '3' },
  { value: 'level_4', label: '4' }
];
