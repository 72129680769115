import { StateCreator } from 'zustand';

import { ICreateEmailConfigurationSlice } from '+types/email-config';

const createEmailConfigurationSlice: StateCreator<ICreateEmailConfigurationSlice> = set => ({
  updateInProgress: false,
  showEmailConfigModal: false,
  modalStage: 'init',
  isCompleteAfterSave: true,
  secondButtonActionIsTerminal: true,
  additionalContentModified: false,
  saveFn: () => Promise.resolve(),
  setUpdateInProgress: value => set(state => ({ ...state, updateInProgress: value })),
  setAdditionalContentModified: value => set(state => ({ ...state, additionalContentModified: value })),
  setModalStage: value => set(state => ({ ...state, modalStage: value })),
  setIsCompleteAfterSave: value => set(state => ({ ...state, isCompleteAfterSave: value })),
  setShowEmailConfigModal: value =>
    set(state => ({ ...state, showEmailConfigModal: value, ...(value === false && { modalStage: 'init' }) })),
  setSaveFn: value => set(state => ({ ...state, saveFn: value }))
});

export default createEmailConfigurationSlice;
