import { MasterCard, Visa } from '+containers/Shared/Icons';
import { capitalizeRemovedash, getDateAndTime, history } from '+utils';

import { AllCardsResponseType, CardStatusType } from '../../types';

const convertStatusToClass = (status: CardStatusType | string) => {
  const options: Record<CardStatusType, string> = {
    terminated: 'failure',
    deactivated: 'warn',
    suspended: 'warn'
  };
  return options[status as CardStatusType] || status;
};

const CustomerCardsRow = ({ rowData }: { rowData: Array<AllCardsResponseType> }) => {
  const handleClick = (reference: string) => history.push(`/dashboard/issuing/customer_cards/${reference}`);

  return (
    <div>
      {rowData?.map?.(card => {
        return (
          <div
            key={`transaction_${card.reference}`}
            className="div-table --history-table --row issued-cards-row"
            role="button"
            tabIndex={0}
            onClick={() => handleClick(card.reference)}
            onKeyUp={e => {
              if (e.key === 'Enter') e.preventDefault();
              handleClick(card.reference);
            }}
          >
            <div className="--txn-status --column d-flex">
              <span className="body-row-header">Status:</span>
              <span className={`status-indicator ${convertStatusToClass(card.status)}`} />
              <span>{capitalizeRemovedash(card.status)}</span>
            </div>
            <div className="--column">
              <span className="body-row-header">Unique Card ID:</span>
              <span className="font-weight-500 text-uppercase" style={{ color: '#007bff' }}>
                {card.reference}
              </span>
            </div>
            <div className="--column">
              <span className="body-row-header">Cardholder:</span>
              <span>{card.holder_name}</span>
            </div>
            <div className="--column">
              <span className="body-row-header">Card Number:</span>
              <span>
                {card.brand === 'visa' ? (
                  <Visa title="visa" titleId="visa" className="card-scheme" />
                ) : (
                  <MasterCard title="mastercard" titleId="mastercard" className="card-scheme" />
                )}
                <span className="card-digits">**** {card.last_four}</span>
              </span>
            </div>
            <div className="--column">
              <span className="body-row-header">Created at:</span>
              <span>{getDateAndTime(card.date_created)}</span>
            </div>
            <div className="--column">
              <span className="body-row-header">Currency:</span>
              <span>
                <span className="ml-1 d-inline">{card.currency}</span>
              </span>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default CustomerCardsRow;
