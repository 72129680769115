/* eslint-disable no-unused-expressions */
/* eslint-disable no-use-before-define */
/* eslint-disable react/require-default-props */
import React, { useState } from 'react';

import LoadingPlaceholder from '+containers/Dashboard/Shared/LoadingPlaceholder';
import { ScrollToTopSection } from '+containers/Shared/ScrollToTop';
import { history } from '+utils';

import ChargebacksTab from './components/ChargebacksTab';
import RefundsTab from './components/RefundsTab';
import ReversalsTab from './components/ReversalsTab';
import { ActionButtons, DisputesType, IDisputes, SummaryItemType } from './types';

import './index.scss';

const TransactionDetails = ({ children }: { children: React.ReactNode }) => {
  return (
    <div className="transaction-details-comp">
      <div>
        <button type="button" className="btn btn-link go-back-btn" onClick={() => history.goBack()}>
          <i className="os-icon os-icon-arrow-left7" />
          <span>Go Back</span>
        </button>
      </div>
      {children}
      <div className="scroll-to-top cluster justify-center items-center">
        <ScrollToTopSection />
      </div>
    </div>
  );
};

const renderActionButtons = (actionButtons: Array<ActionButtons>) => {
  return (
    <ul className="trxn-layout-action-buttons cluster" aria-label="Perform further actions on this transaction">
      {actionButtons.map(({ children, className = '', onClick, variant = 'primary', disabled, hidden = false }, index) => {
        return hidden ? null : (
          <li key={index}>
            <button
              className={`btn btn-${variant} cluster justify-center items-center ${className}`}
              type="button"
              onClick={onClick}
              disabled={disabled}
            >
              {children}
            </button>
          </li>
        );
      })}
    </ul>
  );
};

TransactionDetails.Header = ({
  heading = '--',
  currency,
  status = 'pending',
  statusBg = '#FFF8E1',
  statusColor = '#FA9500',
  actionButtons,
  summaries,
  isLoading
}: {
  heading: string | number;
  currency?: string;
  status?: React.ReactNode;
  statusBg?: string;
  statusColor?: string;
  actionButtons?: Array<ActionButtons>;
  summaries: Array<SummaryItemType>;
  isLoading: boolean;
}) => {
  return (
    <section aria-live="polite" className="trxn-layout-top-section">
      <h2 className="sr-only">Transaction overview</h2>

      {isLoading ? (
        <>
          <span className="sr-only">Overview is Loading</span>
          <div role="presentation">
            <LoadingPlaceholder type="text" content={4} />
          </div>
        </>
      ) : (
        <>
          <span aria-live="polite" className="sr-only">
            Overview has loaded
          </span>

          <div className="trxn-layout-heading cluster justify-between">
            <div className="cluster justify-center items-baseline">
              <div className="cluster gap-xs nowrap items-baseline">
                <span className="heading">{heading}</span>
                <span className="currency" aria-label="currency">
                  {currency}
                </span>
              </div>

              <span className="status" aria-label="Transaction Status" style={{ background: statusBg, color: statusColor }}>
                {status}
              </span>
            </div>

            {actionButtons ? renderActionButtons(actionButtons) : null}
          </div>

          <div className="trxn-layout-summary fade-in cluster items-start">
            {summaries?.length > 0 &&
              summaries.map((summary, index) => {
                return summary.hidden ? null : (
                  <div className="summary-item" key={index}>
                    <div className="summary-label" id={`${index}`}>
                      {summary.label}
                    </div>
                    <div className="summary-value" aria-labelledby={`${index}`}>
                      {summary.value}
                    </div>
                  </div>
                );
              })}
          </div>
        </>
      )}
    </section>
  );
};

TransactionDetails.Section = ({
  heading,
  summaries = [],
  children,
  isLoading
}: {
  heading: React.ReactNode;
  summaries?: Array<SummaryItemType>;
  children?: React.ReactNode;
  isLoading: boolean;
}) => {
  if (isLoading)
    return (
      <>
        <span className="sr-only">Summary is Loading</span>
        <div role="presentation">
          <LoadingPlaceholder type="text" content={4} />
        </div>
      </>
    );

  const List = (
    <ul>
      {summaries?.length > 0 &&
        summaries.map((summary, index) => {
          return summary.hidden ? null : (
            <li className="section-item cluster nowrap" key={summary.label as string}>
              <div className="section-item-label" id={`${index}`}>
                {summary.label}
              </div>
              <div className="section-item-value" aria-labelledby={`${index}`}>
                {summary.value}
              </div>
            </li>
          );
        })}
    </ul>
  );

  return (
    <>
      <span className="sr-only">Summary has loaded</span>
      <section className="trxn-layout-section">
        <h2 className="sr-only">{heading}</h2>
        <div className="section-title">{heading}</div>
        <div className="section-list fade-in">{children ?? List}</div>
      </section>
    </>
  );
};

TransactionDetails.Disputes = <S extends Record<string, any>, T extends Record<string, any>, U extends Record<string, any>>({
  tabs = ['refunds', 'reversals', 'chargebacks'],
  currency = 'USD',
  onGenerateDisputes = generateDefaultDisputes,
  onGenerateSummaryGenerators = generateDefaultSummaryGenerators,
  onGenerateChildrenGenerators = generateDefaultChildrenGenerators
}: IDisputes<S, T, U>) => {
  if ([null, undefined].includes(onGenerateDisputes as null))
    return <p className="cluster empty-state justify-center items-center">No disputes for this transaction</p>;

  const disputes = onGenerateDisputes?.();
  const summaryGenerators = onGenerateSummaryGenerators();
  const childrenGenerators = onGenerateChildrenGenerators();
  const [activeTab, setActiveTab] = useState<DisputesType>(tabs[0]);

  const disputeComponentsByType: Record<DisputesType, React.ReactNode> = {
    refunds: (
      <RefundsTab
        currency={currency}
        data={disputes?.refunds as any}
        summaryGenerator={summaryGenerators.refunds as any}
        childrenGenerator={childrenGenerators.refunds as any}
      />
    ),
    chargebacks: (
      <ChargebacksTab
        currency={currency}
        data={disputes?.chargebacks as any}
        summaryGenerator={summaryGenerators.chargebacks as any}
        childrenGenerator={childrenGenerators.chargebacks as any}
      />
    ),
    reversals: (
      <ReversalsTab
        currency={currency}
        data={disputes?.reversals as any}
        summaryGenerator={summaryGenerators.reversals as any}
        childrenGenerator={childrenGenerators.reversals as any}
      />
    )
  };

  const tabsList = (
    <ul className="tabs switcher">
      {tabs.map(tab => (
        <li className="cluster justify-center items-center" key={tab}>
          <button
            type="button"
            className={`tab cluster justify-between items-center ${tab === activeTab ? 'active' : ''}`}
            onClick={() => setActiveTab(tab)}
            key={tab}
            role="tab"
            id={`${tab}-controller`}
            aria-controls={`${tab}-panel`}
            aria-selected={tab === activeTab}
          >
            <div>
              <span>{tab}</span> <span>({disputes?.[tab]?.length ?? 0})</span>
            </div>
            <span className={`tab-icon ${tab === activeTab ? 'active' : ''}`} />
          </button>
        </li>
      ))}
    </ul>
  );

  return (
    <div className="trxn-layout-disputes with-sidebar nogap">
      <div className="sidebar">{tabsList}</div>
      <div className="not-sidebar" aria-labelledby={`${activeTab}-controller`} id={`${activeTab}-panel`} role="tabpanel">
        {disputeComponentsByType[activeTab]}
      </div>
    </div>
  );
};

export default TransactionDetails;

function generateDefaultDisputes() {
  return {
    refunds: [],
    reversals: [],
    chargebacks: []
  };
}

function generateDefaultSummaryGenerators() {
  return {
    refunds: () => [],
    reversals: () => [],
    chargebacks: () => []
  };
}

function generateDefaultChildrenGenerators() {
  return {
    refunds: () => null,
    reversals: () => null,
    chargebacks: () => null
  };
}
