/* eslint-disable no-unused-vars */
declare const window: {
  clarity: (_: string, value: string) => void;
};
const identifyClarityUser = (userEmail: string) => {
  const isApiAvailable = typeof window?.clarity === 'function';

  if (!isApiAvailable) return;

  window.clarity('identify', userEmail);
};

export default identifyClarityUser;
