import React, { useEffect, useState } from 'react';
import { useMutation } from 'react-query';
import { useFormik } from 'formik';
import { useDebouncedCallback } from 'use-debounce';
import * as Yup from 'yup';

import ListDropdown from '+dashboard/Shared/ListDropdown';
import Modal from '+dashboard/Shared/Modal';
import { useBanks, useFeedbackHandler } from '+hooks';
import APIRequest from '+services/api-services';
import { BulkDataT, IBulkPayoutEditModalProps } from '+types/bulk-payouts';
import { backwardAmountInput, formatAmount, logError } from '+utils';

import './index.scss';

const validationSchema = Yup.object({
  reference: Yup.string().required('Reference is required'),
  bank_code: Yup.string().required('Bank is required'),
  account_number: Yup.string().required('Account number is required'),
  account_name: Yup.string().required('Account name is required'),
  amount: Yup.string()
    .required('Amount is required')
    .matches(/^\d{1,3}(,\d{3})*(.\d{1,2})?$/, 'Amount is not valid'),
  narration: Yup.string().required('Description is required')
});

const api = new APIRequest();
const BulkPayoutEditModal = ({ confirmModal, closeModal, actionBtn, editData, currency }: IBulkPayoutEditModalProps) => {
  const { data: banks, isFetching: isFetchingBanks } = useBanks(currency || 'NGN');
  const { feedbackInit, closeFeedback } = useFeedbackHandler();
  const [bankListOpen, setBankListOpen] = useState(false);
  const [accountNameDisabled, setAccountNameDisabled] = useState(true);

  const getAccountName = useDebouncedCallback((bank: string | number, account: string) => {
    if (bank && account) resolveAccount.mutate({ bank, account, currency });
  }, 1000);

  const resolveAccount = useMutation(data => api.bankEnquiry(data), {
    retry: false,
    onSuccess: data => {
      closeFeedback();
      if (!data.data.account_name) {
        setAccountNameDisabled(false);
      }
      return setFieldValue('account_name', data.data.account_name);
    },
    onError: e => {
      const error = e?.response?.data;
      logError(e);
      const errorMessage = () => {
        if (error?.message === 'invalid request data') {
          return error?.data?.account?.message;
        }
        return 'There seems to be an issue with resolving your bank account';
      };
      feedbackInit({
        message: errorMessage(),
        type: 'danger',
        componentLevel: true
      });
    }
  });

  const getBankName = (code: string) => {
    const bank = banks?.data?.find((item: { code: string }) => item.code === code);
    return bank?.name;
  };

  const formik = useFormik({
    initialValues: {
      bank_code: editData?.bank_account?.bank_code || '',
      account_number: editData?.bank_account?.account_number,
      account_name: editData?.bank_account?.account_name,
      amount: editData?.amount,
      narration: editData?.narration,
      reference: editData?.reference,
      email: editData?.customer?.email
    },
    validationSchema,
    onSubmit: () => { }
  });

  const { setFieldValue, isValid, values, dirty } = formik;
  const canContinue = dirty && isValid;

  const modalContent = () => {
    return (
      <div className="bulk-payout-edit-form" data-testid="bulk-payout-edit-form">
        {editData?.errors?.length ? (
          <div className="bulk-payout-error">
            {editData?.errors?.map(item => (
              <div key={item?.title}>
                <p>{'*Please correct the error(s) in this transaction'}</p>
                <p>
                  {item.messages?.map(message => (
                    <span key={message}>{message}</span>
                  ))}
                </p>
              </div>
            ))}
          </div>
        ) : null}
        <div className="bulk-input-wrapper">
          <label htmlFor="reference">Reference</label>
          <input
            id="reference"
            type="text"
            name="reference"
            placeholder="Enter reference"
            className="form-field form-control"
            disabled
            value={values?.reference}
          />
        </div>
        <div className="bulk-input-wrapper">
          <label htmlFor="email">Email address (optional)</label>
          <input
            id="email"
            type="text"
            name="email"
            placeholder=""
            className="form-field  form-control"
            value={values?.email}
            onChange={e => setFieldValue('email', e.target.value)}
          />
        </div>
        <div className="bulk-input-wrapper">
          <label htmlFor="bank">Bank</label>
          <ListDropdown
            list={banks?.data}
            type="bank"
            className="banks-list"
            value={{ code: values.bank_code, name: getBankName(values.bank_code) }}
            active={bankListOpen}
            setActive={setBankListOpen}
            isFetching={isFetchingBanks}
            onBlur={null}
            setValue={(value: { code: number; name: string }) => {
              setFieldValue('bank_code', value.code);
              setFieldValue('account_name', '');
              getAccountName(value.code, values.account_number);
            }}
          />
        </div>
        <div className="bulk-input-wrapper">
          <label htmlFor="account_no">Account Number</label>
          <input
            id="account_no"
            type="number"
            name="account_no"
            placeholder="Enter account number"
            className="form-field form-control"
            value={values?.account_number}
            maxLength={11}
            onChange={async e => {
              const accountNumber = e.target.value;
              setFieldValue('account_number', accountNumber);
              setFieldValue('account_name', '');
              getAccountName(values.bank_code, accountNumber);
            }}
          />
        </div>
        <div className="bulk-input-wrapper">
          <label htmlFor="account_name">Account Name</label>
          <input
            id="account_name"
            type="text"
            name="account_name"
            placeholder="- -"
            disabled={accountNameDisabled}
            className="form-field  form-control"
            value={values?.account_name}
            onChange={e => {
              const accountName = e.target.value;
              setFieldValue('account_name', accountName)
            }}
          />
        </div>
        <div className="bulk-input-wrapper">
          <label htmlFor="amount">{`Amount ${currency || 'NGN'}`}</label>
          <input
            id="amount"
            type="text"
            name="amount"
            placeholder="eg 1,000.00"
            maxLength={11}
            onChange={e => {
              const formattedAmount = formatAmount(backwardAmountInput(e.target.value));
              setFieldValue('amount', formattedAmount !== 'NaN' ? formattedAmount : '0');
            }}
            value={`${formatAmount(values?.amount)}`}
            className="form-field form-control"
          />
        </div>
        <div className="bulk-input-wrapper">
          <label htmlFor="description">Description</label>
          <input
            id="description"
            type="text"
            name="description"
            placeholder="Enter description"
            className="form-field form-control"
            value={values?.narration}
            onChange={e => {
              setFieldValue('narration', e.target.value);
            }}
          />
        </div>
      </div>
    );
  };

  return (
    <Modal
      size="md"
      close={() => closeModal(false)}
      heading="Edit entry"
      description={
        <p style={{ color: '#414F5F', fontWeight: 400, display: 'block' }}>
          You can edit the details of an entry before you start the bulk payout. Click the ‘Update’ button when you are done to save your
          changes.
        </p>
      }
      content={modalContent()}
      justify="space-between"
      firstButtonText="Cancel"
      firstButtonAction={() => closeModal(false)}
      secondButtonText="Update"
      secondButtonAction={() => {
        let { amount } = values;
        if (typeof amount === 'string') {
          amount = +parseFloat((amount as string).replace(/,/g, '')).toFixed(2);
        }
        const newData: BulkDataT = {
          ...editData,
          reference: values.reference,
          bank_account: { account_name: values?.account_name, account_number: values?.account_number, bank_code: values?.bank_code },
          amount,
          narration: values.narration,
          customer: { email: values.email }
        };
        actionBtn(newData);
      }}
      completedHeading="Entry updated"
      completedDescription="The entry/item you selected has been updated."
      secondButtonDisable={!canContinue}
      visible={confirmModal}
    />
  );
};

export default BulkPayoutEditModal;
