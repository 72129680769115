import { createBrowserHistory } from 'history';

const _ = createBrowserHistory();

const history = {
  ..._,
  previousHistory: null,

  // Function to set the previous path as something else (useful in cases like managing login)
  setPrevious(path, info) {
    history.previousHistory = { path, type: info.type, data: info.data };
  },

  // Util function to get stored information in previous history
  // Only return information if it matches the proper type
  getPathInfo(type) {
    if (type !== history.previousHistory?.type) return null;
    return history.previousHistory?.data;
  },

  // Update the push function to allow you store state in history as well as update the previousHistory
  push(path, state, info = null) {
    const { location } = _;
    history.previousHistory = { path: location.pathname, state: location.state, type: info?.type, data: info?.data };
    return _.push(path, state);
  }
};

export default history;
