/* eslint-disable react/jsx-no-useless-fragment */
import React, { Dispatch } from 'react';
import { Link } from 'react-router-dom';

import {
  ActionButtonType,
  AdditionalInfoType,
  BanksType,
  Card,
  CardTransactionDetailsType,
  Channel,
  CurrencyTypes,
  DataType,
  EventType,
  EventUpdatePayloadType,
  PayInMappingTypes,
  PayInTypes,
  PaymentAmountDetailsType,
  PaymentChannelsType,
  PayOutMappingTypes,
  PayOutTypes,
  RelatedTransactionsT,
  StatusType,
  TabsType,
  TransactionBreakdownOptionsType,
  TransactionT,
  TransactionUtilitiesType,
  txBreakdownOptionsType,
  txType as txTypes
} from '+types/transaction-types';
import {
  capitalize,
  capitalizeFirst,
  capitalizeRemovedash,
  cardStatus,
  daysfromToday,
  formatAmount,
  getDate,
  getDateAndTime,
  getTime,
  getTwoDigitDate,
  history,
  isObjectNotEmpty,
  logBreadCrumb,
  switchCurrency,
  switchStatus,
  switchStatusName
} from '+utils';
import { breadCrumbEvents } from '+utils/bugsnag-events';

import Copyable from '../Shared/Copyable';
import FileDownloadList from '../Shared/FileDownloadList';
import { IReceiptData } from '../Shared/ReceiptTemplate';
import IssuanceChargebackHistory from './components/IssuanceChargebackHistory';
import { ChargebacksTab, RefundsTab, ReversalsTab } from './components/PaymentReversals';

import discoverLogo from '+assets/img/dashboard/discover.png';
import mobileIconPNG from '+assets/img/dashboard/mobile.png';
import bankIcon from '+assets/img/dashboard/bank-icon.svg';
import cardLogo from '+assets/img/dashboard/card-logo.svg';
import GTBank from '+assets/img/dashboard/gtbank.svg';
import Kudabank from '+assets/img/dashboard/kudabank.svg';
import maestroLogo from '+assets/img/dashboard/maestro.svg';
import mastercardLogo from '+assets/img/dashboard/mastercard.svg';
import mobileIconSVG from '+assets/img/dashboard/mobile.svg';
import verveLogo from '+assets/img/dashboard/verve.svg';
import visaLogo from '+assets/img/dashboard/visa.svg';
import walletIcon from '+assets/img/dashboard/wallet.svg';
import Wema from '+assets/img/dashboard/wema.svg';
import Zenith from '+assets/img/dashboard/zenith.svg';

export const switchCard: Record<Card, string> = {
  mastercard: mastercardLogo,
  verve: verveLogo,
  visa: visaLogo,
  maestro: maestroLogo,
  discover: discoverLogo
};

export const switchChannel = (channel: Channel): string => {
  switch (channel) {
    case 'api':
      return 'API';
    case 'modal':
      return 'Kora Checkout';
    case 'web':
      return 'Kora Dashboard';
    case 'bank_transfer':
      return 'Bank Transfer';
    case 'virtual_bank_account':
      return 'Virtual Bank Account';
    case 'card':
      return 'Card';
    case 'reserved_bank_account':
      return 'Reserved Bank Account';
    case 'disbursement_wallet':
      return 'Disbursement Wallet';
    case 'pay_with_bank':
      return 'Pay with Bank';
    case 'mobile_money':
      return 'Mobile Money';
    default:
      return 'Not Available';
  }
};

const bankFullNames = (bank: string) => {
  const list = { gtb: 'Guaranty Trust Bank' };
  return list[bank as keyof typeof list] || bank;
};

export const switchBank: Record<BanksType, string> = {
  default: bankIcon,
  'kuda-mfb': Kudabank,
  zenith: Zenith,
  wema: Wema,
  gtbank: GTBank
};

export const switchDetailsHeading: Record<TransactionT, string> = {
  'pay-ins': 'More Transaction',
  payouts: 'More Transaction',
  refunds: 'Refund',
  chargebacks: 'Chargeback',
  'funding-deposit': 'More Transaction',
  'card-transactions': 'More Transaction',
  'issued-card-chargebacks': 'More Chargeback'
};

export const formatPAN = (value: string): string => {
  const separated = value.match(/.{1,4}/g) || [];
  return separated.join(' ');
};

export const payinMethodMapping: Record<PayInMappingTypes, string> = {
  bank_transfer: 'Bank Transfer',
  wallet: 'Wallet Transfer',
  card: 'Card Payment',
  pay_with_bank: 'Pay with Bank (Direct debit)',
  mobile_money: 'Mobile Money'
};

export const payoutMethodMapping: Record<PayOutMappingTypes, string> = {
  bank_account: 'Bank Transfer',
  wallet: 'Wallet Transfer',
  mobile_money: 'Mobile Money'
};

export const switchTrxnMessage: Record<string, any> = {
  success: { name: `Transaction successful`, color: '#24B314', backgroundColor: '#e4fff1' },
  processing: { name: 'Transaction in progress...', color: '#FA9500', backgroundColor: '#FFF8E1' },
  requires_auth: { name: 'Transaction requires authorization', color: 'rgba(0, 0, 0, 0.5)', backgroundColor: '' },
  failed: { name: 'Transaction failed', color: '#F32345', backgroundColor: '#FFD2DA' },
  expired: { name: 'Transaction expired', color: '#94A7B7', backgroundColor: '#DDE2EC' },
  rejected: { name: 'Transaction rejected', color: '#94A7B7', backgroundColor: '#DDE2EC' },
  abandoned: { name: 'Transaction abandoned', color: '#94A7B7', backgroundColor: '#DDE2EC' },
  pending: { name: 'Action Required', color: '#FA9500', backgroundColor: '#FFF8E1' },
  won: { name: 'Chargeback Won', color: '#24B314', backgroundColor: '#e4fff1' },
  accepted: { name: 'Accepted', color: '#F32345', backgroundColor: '#FFD2DA' },
  auto_accepted: { name: 'Auto Accept', color: '#F32345', backgroundColor: '#FFD2DA' },
  declined: { name: 'Declined', color: '#94A7B7', backgroundColor: '#DDE2EC' },
  partial: { name: 'Partial', color: '#24B314', backgroundColor: '#e4fff1' },
  lost: { name: 'Lost', color: '#F32345', backgroundColor: '#FFD2DA' },
  reversed: { name: 'Transaction reversed', color: '#94A7B7', backgroundColor: '#DDE2EC' },
  flagged: { name: 'Flagged', color: '#24B314', backgroundColor: '#e4fff1' },
  pre_authorized: { name: 'Pre-Authorized', color: '#6474FF', backgroundColor: '#EBEDFF' },
  void_authorization: { name: 'Voided (Auth)', color: '#3E4B5B', backgroundColor: '#F1F6FA' },
  void_capture: { name: 'Voided (Capture)', color: '#3E4B5B', backgroundColor: '#F1F6FA' }
};

export const chargebacksTrxnMessage = {
  pending: { name: 'Chargeback Pending', color: '#FA9500', backgroundColor: '#FFF8E1' },
  processing: { name: 'Chargeback Processing', color: '#FA9500', backgroundColor: '#FFF8E1' },
  accepted: { name: 'Chargeback Accepted', color: '#24B314', backgroundColor: '#E4FFF1' },
  partially_accepted: { name: 'Chargeback Accepted (Partially)', color: '#48CEB0', backgroundColor: '#E4FFF1' },
  invalid: { name: 'Chargeback Invalid', color: '#94A7B7', backgroundColor: '#F3F4F8' },
  declined: { name: 'Chargeback Declined', color: '#F32345', backgroundColor: '#FFD2DA' },
  pending_pre_arbitration: { name: 'Pre-arbitration Pending', color: '#FFB447', backgroundColor: '#FFF8E1' },
  processing_pre_arbitration: { name: 'Pre-arbitration Processing ', color: '#FA9500', backgroundColor: '#FFF8E1' },
  accepted_pre_arbitration: { name: 'Pre-arbitration Accepted', color: '#24B314', backgroundColor: '#E4FFF1' },
  partially_accepted_pre_arbitration: { name: 'Pre-arbitration Accepted (Partially)', color: '#24B314', backgroundColor: '#E4FFF1' },
  delayed_pre_arbitration: { name: 'Pre-arbitration Delayed', color: '#F32345', backgroundColor: '#FFD2DA' },
  invalid_pre_arbitration: { name: 'Pre-arbitration Invalid', color: '#94A7B7', backgroundColor: '#F3F4F8' },
  declined_pre_arbitration: { name: 'Pre-arbitration Declined', color: '#F32345', backgroundColor: '#FFD2DA' },
  accepted_arbitration: { name: 'Arbitration Accepted', color: '#F32345', backgroundColor: '#FFD2DA' },
  declined_arbitration: { name: 'Arbitration Declined', color: '#F32345', backgroundColor: '#FFD2DA' }
};

export const switchReason = (status: string) => {
  switch (status) {
    case 'failed':
      return 'failure';
    case 'expired':
      return 'expiry';
    case 'flagged':
      return 'flagging';
    default:
      return status;
  }
};

export const getTransactionUtilities = (
  data: DataType,
  state: { event: EventType; updateEvent: Dispatch<EventUpdatePayloadType> }
): TransactionUtilitiesType => {
  const {
    transactionType,
    id,
    defaultMerchant,
    permissions,
    reference,
    reason,
    amount,
    message,
    trace_id: traceId,
    amount_charged: amountCharged,
    approved_amount: amountApproved,
    payment_reversals: reversals,
    payment_reversals_type: refundOrChargeback,
    payment_reference: paymentReference,
    payment_source_reference: paymentSourceReference,
    customer_name: customerName,
    deadline,
    status_reason: statusReason,
    amount_paid: netAmount,
    channel,
    transaction_date: createdAt,
    created_at: createdDate,
    payment_source_type: paymentSourceType,
    payment_source: paymentSource,
    amount_collected: amountCollected,
    can_reverse_payment: canReverse,
    reversal_reason: reversalReason,
    currency,
    fee,
    vat,
    source,
    destination,
    completed_at: completedAt,
    payment,
    status,
    narration,
    meta,
    cross_currency: crossCurrency,
    description,
    type: txType = '',
    date,
    transaction_date,
    card,
    payment: korapayRef,
    batch_reference,
    escalation_date: escalationDate,
    expected_resolution_date: expectedResolutionDate,
    actual_resolution_date: resolutionDate,
    transaction_date: transactionDate,
    transaction_amount: transactionAmount,
    transaction_reference: transactionReference,
    accepted_amount: acceptedAmount,
    re_escalated_amount: reescalatedAmount,
    status_history: statusHistory,
    card_holder_name: cardholderName,
    card_reference: cardReference,
    card_expiry_date: cardExpirationDate,
    card_acceptor_name: vendor,
    card_acceptor_country: location,
    card_brand: cardBrand,
    last_four: lastFourDigits,
    refunds: issuanceRefunds,
    chargeback: issuanceChargeback,
    merchant_bears_cost: merchantBearsCost,
    unique_reference,
    source_currency: sourceCurrency,
    destination_currency: destinationCurrency,
    source_amount: sourceAmount,
    converted_amount: convertedAmount,
    exchange_rate: exchangeRate,
    transaction_date: conversionDate,
    customer_name: customer,
    auth_data: authData
  } = (data || {}) as DataType;

  const { event, updateEvent } = state;

  const cardTransactionHasDisputes = () => {
    if (transactionType !== 'card-transactions') return false;
    if (txType !== 'card_transaction') return false;
    if (!issuanceRefunds?.length && !isObjectNotEmpty(issuanceChargeback)) return false;
    return true;
  };

  const switchCount: Record<TabsType[number], string> = {
    Refunds: reversals?.filter(({ type }: { type: string }) => type === 'refund') || issuanceRefunds,
    Reversals: reversals?.filter(({ type }: { type: string }) => type === 'underpayment' || type === 'overpayment'),
    Chargebacks: reversals?.filter(({ type }: { type: string }) => type === 'chargeback') || [issuanceChargeback]
  };

  const renderTabs = {
    Refunds: <RefundsTab data={switchCount.Refunds} currency={currency} transactionType={transactionType} />,
    Reversals: <ReversalsTab data={data} />,
    Chargebacks: <ChargebacksTab data={switchCount.Chargebacks} currency={currency} transactionType={transactionType} />
  };

  const mobileMoneyProvider = transactionType === 'payouts' ? destination?.details?.mobile_name : source?.details?.network_provider;
  const mobileMoneyNumber = transactionType === 'payouts' ? destination?.details?.mobile_number : source?.details?.mobile_number;

  const transactionUtilities = {
    PayInDetails: {
      payment_method: payinMethodMapping[source?.type as PayInMappingTypes] || 'Not Available',
      ...(['bank_transfer', 'pay_with_bank'].includes(source?.type) && {
        bank: (
          <>
            <img
              id="bank-icon"
              src={switchBank[(source?.details?.bank_slug as BanksType) || 'default']}
              alt="bank icon"
              style={{ marginLeft: source?.details?.bank_name ? 0 : '' }}
            />
            <span style={{ display: 'inline', marginLeft: 8, color: '#414f5f' }}>
              {capitalize(bankFullNames(source?.details?.bank_name) || 'No Bank Information Available')}
            </span>
          </>
        )
      }),
      ...(source?.type === 'wallet' && { wallet: null }),
      ...(source?.type === 'card' && { card: null }),
      ...(source?.type === 'mobile_money' && { mobile_money: null }),
      ...(['bank_transfer', 'pay_with_bank', 'wallet'].includes(source?.type) && {
        account_number: source?.details?.account_number || 'Not Available'
      }),
      ...(source?.type === 'bank_transfer' && {
        account_name: <span>{capitalize(source?.details?.account_name || 'Not Available')}</span>
      }),
      ...(['card', 'wallet', 'pay_with_bank', 'mobile_money'].includes(source?.type) && {
        customer_name: <span>{capitalize(payment?.customer?.name || 'Not Available')}</span>
      }),
      email: payment?.customer?.email || 'Not provided'
    },
    PayoutDetails: {
      payment_method: payoutMethodMapping[destination?.type as PayOutMappingTypes] || 'Not Available',
      ...(['bank_account', 'wallet'].includes(destination?.type) && {
        bank: (
          <>
            <img
              id="bank-icon"
              src={switchBank[(destination?.details?.bank_slug as BanksType) || 'default']}
              alt="bank icon"
              style={{ marginLeft: destination?.details?.bank_name ? 0 : '' }}
            />
            <span style={{ display: 'inline', marginLeft: 8, color: '#414f5f' }}>
              {capitalize(destination?.details?.bank_name || destination?.details?.mobile_name || 'Not Available')}
            </span>
          </>
        )
      }),
      ...(destination?.type === 'mobile_money' && {
        mobile_money: (
          <>
            <span>
              <img id="phone-icon" src={mobileIconSVG} alt="phone icon" srcSet={mobileIconPNG} />
              <span>{mobileMoneyProvider || 'Operator Information Unavailable'}</span>
            </span>
            {mobileMoneyNumber && <span>{`${mobileMoneyNumber}` || 'Not Available'}</span>}
          </>
        )
      }),
      ...(['bank_account', 'wallet'].includes(destination?.type) && {
        account_number: destination?.details?.account_number || destination?.details?.mobile_number || 'Not Available'
      }),
      account_name: capitalize(destination?.details?.account_name || payment?.customer?.name || 'Not Available'),
      email: payment?.customer?.email || 'Not provided',
      ...(['USD', 'GBP'].includes(currency as CurrencyTypes) && {
        first_name: capitalize(destination?.details?.first_name || 'Not Provided'),
        last_name: capitalize(destination?.details?.last_name || 'Not Provided'),
        address: capitalize(destination?.details?.address || 'Not Provided'),
        city: capitalize(destination?.details?.city || 'Not Provided'),
        country: destination?.details?.country || 'Not Available'
      })
    },

    cardTransactionDetails: {
      "cardholder's_name": cardholderName || `${card?.card_holder?.first_name} ${card?.card_holder?.last_name}`,
      PAN: (
        <span>
          <img
            src={switchCard[cardBrand] || switchCard.mastercard}
            className="card-logo"
            alt={`${switchCard[cardBrand] || switchCard.mastercard} logo`}
          />
          <Copyable text={`**** ${lastFourDigits || card?.last_four}`} spanClassName="copyable-bold" />
        </span>
      ),

      Card_ID: <Copyable text={cardReference || card?.reference} spanClassName="copyable-blue" />,

      expiry_date: getTwoDigitDate(cardExpirationDate || card?.expiration_date)
    },

    paymentChannels: (item: PaymentChannelsType): React.ReactElement => (
      <>
        {item === 'wallet' && (
          <>
            <span>
              <img id="wallet-icon" src={walletIcon} alt="wallet icon" />
              <span style={{ display: 'inline', marginLeft: '0.5rem' }}>Korapay wallet</span>
            </span>
            <span>
              {transactionType === 'pay-ins' ? payment?.customer?.email || 'Not provided' : destination?.details?.email || 'Not provided'}
            </span>
          </>
        )}

        {item === 'card' &&
          (source?.details?.masked_pan ? (
            <>
              <span>
                <img
                  src={switchCard[source?.details?.card_type as Card]}
                  className="card-logo"
                  alt="card-logo"
                  style={{ width: '1.5rem', marginRight: '8px' }}
                />
                <span style={{ display: 'inline' }}>{capitalize(source?.details?.card_type)}</span>
              </span>
              <span>
                {meta?.card_details?.first_six
                  ? formatPAN(`${meta?.card_details?.first_six}${source.details.masked_pan?.slice(6)}`)
                  : formatPAN(source.details.masked_pan)}
              </span>
            </>
          ) : (
            <span>
              <img src={cardLogo} className="card-logo" alt="card-logo" />
              <span style={{ display: 'inline' }}>No card information available</span>
            </span>
          ))}
        {item === 'mobile_money' && (
          <>
            <span style={{ display: 'inline' }}>
              <img
                id="phone-icon"
                src={mobileIconSVG}
                alt="phone icon"
                srcSet={mobileIconPNG}
                style={{ marginLeft: destination?.details?.network_provider ? 0 : '', height: '23px', width: '13px' }}
              />
              <span style={{ display: 'inline', marginLeft: '0.5rem', color: '#636C72' }}>
                {mobileMoneyProvider || 'Operator Information Unavailable'}
              </span>
            </span>
            {mobileMoneyNumber && <span>{`${mobileMoneyNumber}` || 'Not Available'}</span>}
          </>
        )}

        {item === 'bank_account' && (
          <>
            <span>
              <img id="bank-icon" src={bankIcon} alt="bank" style={{ marginLeft: destination?.details?.bank_name ? 0 : '-1.5rem' }} />
              <span style={{ display: 'inline', marginLeft: '0.2rem', color: '#a9afbc' }}>
                {destination?.details?.bank_name || 'No Bank Information Available'}
              </span>
            </span>
            {destination?.details?.bank_name && <span>{destination?.details?.account_number || 'Not Available'}</span>}
          </>
        )}
      </>
    ),

    virtualAccount: (): React.ReactElement => {
      return (
        <>
          <p className="section-heading">Customer&apos;s Virtual Account</p>
          <p>
            <span>
              <img
                id="bank-icon"
                src={bankIcon}
                alt="bank"
                style={{ marginLeft: source?.virtual_bank_account?.bank_name ? 0 : '-1.5rem' }}
              />
              <span style={{ display: 'inline', marginLeft: 0, color: '#636C72' }}>
                {capitalizeRemovedash(source?.virtual_bank_account?.bank_name || 'No bank information Available')}
              </span>
            </span>
            {source?.virtual_bank_account?.bank_name && <span>{source?.virtual_bank_account?.account_number || 'Not Available'}</span>}
          </p>
          <p>
            <span>Account Name</span>
            <span>{capitalize(source?.virtual_bank_account?.account_name || 'Not Available')}</span>
          </p>
          {source?.virtual_bank_account?.expiry && (
            <p>
              <span>Expired At</span>
              <span>{`${getDate(source.virtual_bank_account.expiry)}, ${getTime(source.virtual_bank_account.expiry)}`}</span>
            </p>
          )}
        </>
      );
    },

    relatedTransactions: ({ uniqueRef, trxns }: { uniqueRef: string; trxns: RelatedTransactionsT[] }): React.ReactElement => {
      const relatedTransactionsLink = '/dashboard/pay-ins?page=1&sorterType=filter&reference=';
      const extraCardStatus = (extraStatus: string) => cardStatus[extraStatus as keyof typeof cardStatus];

      return (
        <div className="related-transactions">
          <div aria-label="related transactions" className="section-heading more-trxn-heading" id="section-title">
            Related Transactions
            <Link to={relatedTransactionsLink + uniqueRef} className="section-heading-link">
              See All Related Transactions <i className="os-icon os-icon-arrow-up-right" />
            </Link>
          </div>
          <ul className="related-transactions__list">
            {trxns.map(trx => (
              <li key={trx.reference} className="related-transactions__list-item">
                <div>
                  {getDate(trx.transaction_date)} {getTime(trx.transaction_date)}
                </div>
                <div>
                  <span>{uniqueRef}</span>
                  <span>{trx.reference}</span>
                </div>
                <div>
                  <span>
                    <i className={`status-pill smaller ${switchStatus(trx.status)}`} />
                    {extraCardStatus(trx.status) || capitalizeRemovedash(trx.status)}
                  </span>
                  {trx.meta?.charge_operation && (
                    <span>{trx.meta.charge_operation === 'pre_authorization' ? 'Auth' : trx.meta.charge_operation.split('_')[0]}</span>
                  )}
                </div>
                <div>
                  {formatAmount(trx.amount)} {trx.currency}
                </div>
              </li>
            ))}
          </ul>
        </div>
      );
    },

    refundsAndReversal: (): React.ReactElement | null => {
      return (
        <>
          <p className="section-heading" id="section-title">
            {transactionType === 'pay-ins' ? 'Refunds, Reversals & Chargebacks' : 'Disputes'}
          </p>
          {transactionType === 'pay-ins' || cardTransactionHasDisputes() ? (
            <>
              <div className="rversal-rfund-cback">
                <div className="reversals-tabs-list" id="reversals-tabs-list">
                  <div className="os-tabs-controls os-tabs-complex">
                    <ul className="nav nav-tabs">
                      {event?.extraPaymentTabs.map((tab: string) => {
                        if (tab === 'Reversals' && cardTransactionHasDisputes()) {
                          return null;
                        }
                        return (
                          <li className={`nav-item ${tab === event.activeTab && 'active'}`} key={tab}>
                            <button
                              id="tab"
                              type="button"
                              aria-expanded="false"
                              className={tab === event.activeTab ? 'nav-link active' : 'nav-link'}
                              data-toggle="tab"
                              onClick={() => updateEvent({ activeTab: tab as TabsType[number] })}
                            >
                              <div>
                                <span className="tab-label">{tab}</span>
                                <span style={{ marginLeft: '3px', fontWeight: '400' }}>{`(${switchCount[tab as TabsType[number]]?.length || '0'
                                  })`}</span>
                              </div>
                              <span className={`tab-icon ${tab === event.activeTab && 'active'}`} />
                            </button>
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                </div>
                <span className="render-tabs">{renderTabs[event.activeTab as TabsType[number]]}</span>
              </div>
            </>
          ) : (
            <div style={{ color: '#a9afbc', fontWeight: 400, fontSize: '1rem', fontStyle: 'italic', textAlign: 'center' }}>
              No disputes for this transaction
            </div>
          )}
        </>
      );
    },

    renderIssuanceChargebackHistory: () => {
      return (
        <>
          <p className="section-heading" id="section-title">
            Chargeback Timeline
          </p>
          <IssuanceChargebackHistory data={statusHistory} />
        </>
      );
    },

    renderIssuanceChargebackDocs: () => {
      const extractChargebackUploadInfo = () => {
        return statusHistory?.reduce((prev, next, index) => {
          if (Object.prototype.hasOwnProperty.call(next, 'evidence')) {
            return [
              ...prev,
              {
                fileName: next.description,
                fileUrl: next.evidence,
                dateOfUpload: next.date
              }
            ];
          }
          return prev;
        }, []);
      };
      const chargebackUploadInfo = extractChargebackUploadInfo();

      return (
        <>
          <p className="section-heading" id="section-title">
            Chargeback Documents
          </p>
          <FileDownloadList data={chargebackUploadInfo} />
        </>
      );
    },

    refundsAndChargebackBreakdown: {
      ...(transactionType === 'refunds'
        ? { refund_amount: `${formatAmount(amount)} ${currency}` }
        : { dispute_amount: `${formatAmount(amount)} ${currency}` }),
      ...(transactionType === 'refunds'
        ? { transaction_amount: `${formatAmount(paymentSource?.amount)} ${currency}` }
        : { accepted_amount: `${formatAmount(amountApproved)} ${currency}` }),
      ...(transactionType === 'chargebacks' && {
        rejected_amount: `${formatAmount(
          status === 'pending' ? 0 : parseFloat(amount || '0') - parseFloat(amountApproved || '0')
        )} ${currency}`
      }),
      ...(transactionType === 'refunds'
        ? { internal_reference: <Copyable text={paymentSource?.reference?.toLowerCase()} /> }
        : { date_contested: `${getDate(createdDate)}, ${getTime(createdDate)}` }),
      customer_name: `${capitalize(transactionType === 'refunds' ? payment?.customer?.name : customerName || 'Not Available')}`,
      transaction_id: (
        <>
          <Copyable text={transactionType === 'refunds' ? payment?.reference?.toUpperCase() : paymentReference?.toUpperCase()} /> <br />
          <button
            type="button"
            className="btn btn-original"
            onClick={() =>
              history.push(`/dashboard/pay-ins/${transactionType === 'refunds' ? paymentSource?.reference : paymentSourceReference}`)
            }
          >
            <span>See original transaction</span>
            <i className="os-icon os-icon-arrow-up-right" />
          </button>
        </>
      )
    }
  };

  const paymentChannelDetails = {
    bankAccountDetails: {
      bank: (
        <>
          <img
            id="bank-icon"
            src={switchBank[(source?.virtual_bank_account?.bank_name as BanksType) || 'default']}
            alt="bank icon"
            style={{ marginLeft: source?.virtual_bank_account?.bank_name ? 0 : '' }}
          />
          <span style={{ display: 'inline', marginLeft: 8, color: '#414f5f' }}>
            {capitalize(source?.virtual_bank_account?.bank_name || 'No Bank Information Available')}
          </span>
        </>
      ),
      account_number: source?.virtual_bank_account?.account_number || 'Not Available',
      account_name: <span>{capitalize(source?.virtual_bank_account?.account_name || 'Not Available')}</span>,
      expired_at: source?.virtual_bank_account?.expiry
        ? `${getDate(source?.virtual_bank_account?.expiry)}, ${getTime(source?.virtual_bank_account?.expiry)}`
        : 'N/A'
    }
  };

  const { PayoutDetails, PayInDetails, cardTransactionDetails } = transactionUtilities;
  const { bankAccountDetails } = paymentChannelDetails;

  const canChargebackBeReescalated = () => {
    if (['partially_accepted', 'declined', 'invalid'].includes(status)) return true;
    return false;
  };
  const chargebackCanBeReescalated = canChargebackBeReescalated();

  const txBreakdownOptions: Record<txBreakdownOptionsType, Partial<TransactionBreakdownOptionsType>> = {
    common: {
      ...(transactionType === 'pay-ins'
        ? { amount_expected: `${formatAmount(amountCharged)} ${currency}` }
        : { net_amount: `${formatAmount(netAmount)} ${currency}` }),
      fee:
        fee !== undefined && vat !== undefined && currency !== undefined
          ? `${formatAmount(parseFloat(fee) + parseFloat(vat))} ${currency}`
          : 'N/A',
      date_completed: completedAt ? `${getDate(completedAt)}, ${getTime(completedAt)}` : 'N/A',
      ...(transactionType === 'payouts' ? {} : { customer_name: `${capitalize(payment?.customer?.name || 'Not Available')}` }),
      transaction_id: (
        <Copyable text={transactionType === 'pay-ins' ? payment?.reference : reference} textModifier={text => text?.toUpperCase()} />
      )
    },
    'card-transactions': {
      net_amount: `${formatAmount(amount)} ${currency}`,
      fee: `${formatAmount(parseFloat(fee || '0') + parseFloat(vat || '0'))} ${currency}`,
      'date_/_time': `${getDateAndTime(date)}`,
      cardholder: `${card?.card_holder?.first_name} ${card?.card_holder?.last_name}`,
      transaction_id: (
        <>
          <Copyable text={reference?.toUpperCase?.()} textModifier={text => `${text.substring(0, 15)}...`} /> <br />
          <button
            type="button"
            className="btn btn-original"
            onClick={() => {
              const cardType = card?.reserved ? 'reservedCards' : 'customerCards';
              history.push(`/dashboard/issuing/${cardType}/${card?.reference}`);
            }}
          >
            <span>See original transaction</span>
            <i className="os-icon os-icon-arrow-up-right" />
          </button>
        </>
      )
    },
    'funding-deposit': {
      net_amount: `${formatAmount(amount)} ${currency}`,
      fee: `${formatAmount(parseFloat(fee || '0') + parseFloat(vat || '0'))} ${currency}`,
      'date_/_time': `${getDateAndTime(date)}`,
      business_name: `${defaultMerchant.name}`,
      transaction_id: (
        <>
          <Copyable text={reference?.toUpperCase?.()} textModifier={text => `${text.substring(0, 15)}...`} /> <br />
        </>
      )
    },
    'issued-card-chargebacks': {
      chargeback_amount: `${formatAmount(amount)} ${currency}`,
      ...(status === 'accepted' && { fee: `${formatAmount(parseFloat(process.env.REACT_APP_CHARGEBACK_FEE || '0'))} ${currency}` }),
      'date_/_time': `${getDateAndTime(escalationDate)}`,
      cardholder: cardholderName || `${card?.card_holder?.first_name} ${card?.card_holder?.last_name}`,
      transaction_id: (
        <>
          <Copyable text={(transactionReference || reference)?.toUpperCase()} textModifier={text => `${text.substring(0, 15)}...`} /> <br />
          <button
            type="button"
            className="btn btn-original"
            onClick={() => history.push(`/dashboard/issuing/card-transactions/${transactionReference}`)}
          >
            <span>See original transaction</span>
            <i className="os-icon os-icon-arrow-up-right" />
          </button>
        </>
      )
    },
    conversions: {
      amount_converted: `${formatAmount(sourceAmount)} ${sourceCurrency}` || 'N/A',
      converted_to: `${formatAmount(convertedAmount)} ${destinationCurrency}` || 'N/A',
      'date_/_time': `${getDateAndTime(conversionDate)}`,
      transaction_id: (
        <Copyable text={transactionType === 'pay-ins' ? payment?.reference : reference} textModifier={text => text?.toUpperCase()} />
      )
    }
  };

  const additionalInfoOptions: Record<txTypes, { title: string; data: PayInTypes | PayOutTypes | CardTransactionDetailsType }> = {
    payouts: {
      title: 'Recipient Information',
      data: PayoutDetails
    },
    'pay-ins': {
      title: "Payer's Information",
      data: PayInDetails
    },
    refunds: {
      title: "Payer's Information",
      data: PayInDetails
    },
    chargebacks: {
      title: "Payer's Information",
      data: PayInDetails
    },
    'issued-card-chargebacks': {
      title: "Cardholder's Information",
      data: cardTransactionDetails
    },
    'card-transactions': {
      title: "Cardholder's Information",
      data: cardTransactionDetails
    }
  };
  const paymentChannelInfoOptions: Record<
    PayInMappingTypes,
    { title: string; data: { bank: React.ReactElement; account_number: string; account_name: React.ReactElement; expired_at: string } }
  > = {
    bank_transfer: {
      title: 'Account Number Generated for Payment',
      data: bankAccountDetails
    }
  };

  const transactionBreakdown = txBreakdownOptions[transactionType as txBreakdownOptionsType] || txBreakdownOptions.common;

  const isCardStatus = Object.keys(cardStatus).includes(status);

  let renderStatus = <>{(status && capitalizeRemovedash(status)) || 'Not Available'}</>;

  if (transactionType === 'issued-card-chargebacks') {
    renderStatus = <>{chargebacksTrxnMessage[status as keyof typeof chargebacksTrxnMessage]?.name}</>;
  } else if (isCardStatus) {
    renderStatus = (
      <>
        {cardStatus[status as keyof typeof cardStatus]}
        <span
          style={{
            background: '#6474FF21',
            color: '#6474FF',
            marginLeft: 5,
            padding: '2px 4px',
            borderRadius: 8,
            fontSize: 12
          }}
        >
          {status.includes('auth') ? 'AUTH' : 'CAPTURE'}
        </span>
      </>
    );
  }

  const authExpiryText = (expiryDate: string) => {
    const diff = daysfromToday(expiryDate, true);
    return diff.includes('in') ? `${diff.split(' ').slice(1).join(' ').split(',').join(' ')} remaining` : diff;
  };

  const moreDetailsOptions = {
    shared: {
      ...(!['refunds'].includes(transactionType) && {
        status: (
          <>
            <span
              className={`status-pill smaller more-details-opt ${
                (status as StatusType) === 'requires_auth' ? 'yellow' : switchStatus(status)
              }`}
            />
            {renderStatus}
          </>
        )
      }),
      ...(['pay-ins', 'payouts'].includes(transactionType) && ['expired', 'failed', 'flagged'].includes(status)
        ? { [`reason_for_${switchReason(status)}`]: message }
        : {}),
      ...(traceId ? { trace_id: traceId } : {}),
      ...(transactionType === 'pay-ins' && {
        amount_paid: `${status === 'success' ? formatAmount(+amountCollected || +amountCharged) : formatAmount(amountCollected)
          } ${currency}`,
        ...(paymentSourceType === 'bank_transfer' && { amount_expected: `${formatAmount(amountCharged)} ${currency}` })
      }),
      ...(transactionType === 'payouts' && {
        amount_charged: `${['success', 'pending', 'processing'].includes(status) ? formatAmount(amountCharged) : formatAmount('0.00')
          } ${currency}`
      }),
      ...(['issued-card-chargebacks'].includes(transactionType) && {
        transaction_amount: `${transactionAmount} ${currency}`,
        ...(!['invalid', 'declined', 'processing', 'pending'].includes(status) && {
          accepted_amount: `${acceptedAmount} ${currency}`
        }),
        ...(!['pending', 'processing', 'accepted', 'partially_accepted', 'invalid', 'declined'].includes(status)
          ? { reescalated_amount: `${formatAmount(reescalatedAmount)} ${currency}` }
          : { escalated_amount: `${amount} ${currency}` })
      }),
      ...(!['conversions'].includes(transactionType) && { currency_charged: switchCurrency[currency as CurrencyTypes] }),
      ...(!['conversions'].includes(transactionType) && { fee_bearer: `${merchantBearsCost ? 'Merchant' : 'Customer'}` }),
      ...(['USD', 'GBP'].includes(currency as CurrencyTypes) && {
        transaction_region: destination?.details?.payment_region || 'Not Available',
        payment_type: destination?.details?.payment_method_type || 'Not Available',
        SWIFT_code: destination?.details?.payment_method_value || 'Not Available'
      }),
      fee_bearer: `${merchantBearsCost ? 'Merchant' : 'Customer'}`
    },
    common: {
      ...(!['pay-ins', 'refunds', 'chargebacks', 'issued-card-chargebacks', 'funding-deposit'].includes(transactionType) && {
        channel: switchChannel(channel as Channel)
      }),
      ...(transactionType === 'chargebacks' && {
        chargeback_due: deadline ? `${capitalizeFirst(daysfromToday(deadline))}` : 'Not Available'
      }),
      ...(transactionType === 'refunds' && { refund_destination: destination || 'Customer' }),
      ...(meta?.stan && { stan: <Copyable text={meta?.stan} /> }),
      ...(meta?.receipt && { rnn: <Copyable text={meta?.receipt} /> }),
      ...(meta?.gateway_code && { approval_code: <Copyable text={meta?.gateway_code} /> }),
      ...(['pay-ins', 'payouts', 'refunds', 'chargebacks'].includes(transactionType) && {
        date_created: `${getDate(createdAt || createdDate)}, ${getTime(createdAt || createdDate)}`
      }),
      ...(['pay-ins', 'payouts'].includes(transactionType) && {
        date_completed: completedAt ? `${getDate(completedAt)}, ${getTime(completedAt)}` : 'Not Available'
      }),
      ...(['pay-ins', 'chargebacks'].includes(transactionType) && {
        internal_reference: <Copyable text={transactionType === 'pay-ins' ? reference : paymentSourceReference} />
      }),
      ...(authData?.pre_auth_expiry && {
        authorization_expiry: (
          <>
            {getDateAndTime(authData?.pre_auth_expiry)}{' '}
            <i style={{ color: '#A9AFBC', fontStyle: 'italic' }}>({authExpiryText(authData?.pre_auth_expiry)})</i>
          </>
        )
      }),
      ...(batch_reference && {
        bulk_id: (
          <span>
            <a type="button" className="bulk-id" onClick={() => history.push(`/dashboard/bulk-payout/${batch_reference}`)}>
              {capitalizeRemovedash(batch_reference).toUpperCase()}
            </a>
          </span>
        )
      }),
      ...(transactionType === 'payouts' && {
        kora_reference: `${capitalize(unique_reference).toUpperCase()}`
      }),
      ...(['issued-card-chargebacks'].includes(transactionType) && {
        purchasing_vendor: vendor || 'N/A',
        'Date, Time of Transaction': getDateAndTime(transactionDate),
        'Date, Time of Escalation': getDateAndTime(escalationDate),
        expected_resolution_date: expectedResolutionDate ? `${getDateAndTime(expectedResolutionDate)}` : `N/A`,
        ...(!['pending', 'processing', 'pending_pre_arbitration', 'processing_pre_arbitration'].includes(status) && {
          'Date, Time of Resolution': `${getDateAndTime(resolutionDate)}`
        }),
        kora_reference: <Copyable text={reference} />
      }),
      ...// transactionType === 'refunds'
      // ? { reason_for_refund: reversalReason || 'Not Available' } :
      (transactionType === 'chargebacks'
        ? { reason_for_chargeback: reason || 'Not Available' }
        : {
          description: `${transactionType === 'pay-ins' && channel === 'web' && source?.type === 'bank_transfer'
              ? 'Balance Top Up'
              : transactionType === 'funding-deposit'
                ? 'Issuing balance funding'
                : narration || description || 'No description provided'
            }`
        })
    },
    'card-transactions': {
      transaction_type: <strong>{capitalizeRemovedash(txType)}</strong>,
      amount_paid: formatAmount(amount),
      ...(txType !== 'card_creation' && { amount_settled: formatAmount(amount) }),
      // 'cross-currency_status': crossCurrency ? 'Yes' : 'No', -- intentionally commented out, do not delete!
      ...(!['card_creation', 'card_funding'].includes(txType) && { purchasing_vendor: vendor || 'Not Available' }),
      'Date, Time': getDateAndTime(date),
      kora_reference: 'Not Available',
      ...(!['card_creation', 'card_funding'].includes(txType) && { location: location || 'Not Available' }),
      ...(txType !== 'card_creation' && { description })
    },
    conversions: {
      exchange_rate: `1 USD  → ${exchangeRate} NGN`,
      converted_from: switchCurrency[sourceCurrency as CurrencyTypes] ?? 'N/A',
      converted_to: switchCurrency[destinationCurrency as CurrencyTypes] ?? 'N/A',
      channel: switchChannel(channel as Channel),
      internal_reference: reference ? <Copyable text={reference} /> : 'Not Available',
      customer_name: customer || 'Not Available'
    }
  };

  const morePaymentDetails = {
    ...moreDetailsOptions.shared,
    ...(moreDetailsOptions[transactionType as Exclude<txBreakdownOptionsType, 'issued-card-chargebacks'>] || moreDetailsOptions.common)
  };

  const additionalInfo: AdditionalInfoType = additionalInfoOptions[transactionType as txTypes];
  const paymentChannelInfo = paymentChannelInfoOptions[source?.type as PayInMappingTypes];

  const prepareTxReceipt = () => {
    const getPayinCustomerInfo = (): string | null => {
      let name;
      if (source?.type === 'bank_transfer') {
        name = source?.details?.account_name;
      } else {
        name = payment?.customer?.name;
      }
      return name ? `Receipt From ${name}` : null;
    };

    const receiptDataOptions: { payouts: IReceiptData; 'pay-ins': IReceiptData } = {
      payouts: {
        id,
        txType: transactionType as Exclude<txTypes, 'card-transactions' | 'refunds' | 'chargebacks' | 'issued-card-chargebacks'>,
        amount: formatAmount(amount),
        currency,
        customerInfo: `Receipt For ${defaultMerchant.name}`,
        summaryInfo: 'Your payment has been successfully processed.',
        isInternalTransaction: true,
        summaryList: [
          {
            label: 'Amount',
            value: formatAmount(amount)
          },
          {
            label: 'Customer Name',
            value: PayoutDetails.account_name
          },
          {
            label: 'Date Completed',
            value: morePaymentDetails.date_completed
          },
          {
            label: 'Transaction Type',
            value: PayoutDetails.payment_method
          },
          ...(destination?.type === 'mobile_money'
            ? [
              {
                label: 'Mobile Number',
                value: `+${destination?.details?.mobile_number}`
              },
              {
                label: 'Operator',
                value: destination?.details?.mobile_name || 'Operator Information Unavailable'
              }
            ]
            : [
              {
                label: 'Account Number',
                value: destination?.details?.account_number
              },
              {
                label: 'Bank',
                value: destination?.details?.bank_name || 'No Bank Information Available'
              }
            ]),
          {
            label: 'Trace ID',
            value: traceId
          },
          {
            label: 'Payout Reference',
            value: reference?.toUpperCase()
          },
          {
            label: 'Description',
            value: morePaymentDetails.description
          }
        ]
      },
      'pay-ins': {
        id,
        txType: transactionType as Exclude<txTypes, 'card-transactions' | 'refunds' | 'chargebacks' | 'issued-card-chargebacks'>,
        amount: formatAmount(netAmount),
        currency,
        customerInfo: getPayinCustomerInfo() as string,
        summaryInfo: 'Payment has been received . View additional details below.',
        isInternalTransaction: true,
        summaryList: [
          {
            label: 'Amount Paid',
            value: formatAmount(status === 'success' ? +amountCollected || +amountCharged : +amountCollected)
          },
          {
            label: 'Fees',
            value: transactionBreakdown.fee
          },
          {
            label: 'Net Amount',
            value: formatAmount(netAmount)
          },
          {
            label: 'Channel',
            value: PayInDetails.payment_method
          },
          {
            label: 'Trace ID',
            value: traceId
          },
          {
            label: 'Date Completed',
            value: transactionBreakdown.date_completed
          },
          {
            label: 'Description',
            value: morePaymentDetails.description
          }
        ]
      }
    };

    updateEvent({ showReceiptModal: true, txReceiptData: receiptDataOptions[transactionType as 'payouts' | 'pay-ins'] });
  };

  const allActionBtns: Record<string, ActionButtonType> = {
    viewReceipt: {
      label: 'View Receipt',
      onClick: () => {
        prepareTxReceipt();
        logBreadCrumb({
          event: breadCrumbEvents.receipt.viewReceiptButton
        });
      },
      color: 'grey'
    },
    refund: {
      label: !canReverse ? 'Refund Requested...' : 'Refund...',
      onClick: () => {
        updateEvent({ refundsModal: true });
        logBreadCrumb({
          event: breadCrumbEvents.disputes.createRefundButton
        });
      },
      disabled: !canReverse,
      leftIcon: <i className="os-icon os-icon-corner-up-left" />,
      color: 'secondary'
    },
    acceptChargebacks: {
      label: 'Accept Chargeback',
      onClick: () => {
        updateEvent({ respondChargebackModal: true, approve: true });
        logBreadCrumb({
          event: breadCrumbEvents.disputes.acceptChargeBackButton
        });
      },
      color: 'dark'
    },
    declineChargebacks: {
      label: 'Decline',
      onClick: () => {
        updateEvent({ respondChargebackModal: true, approve: false });
        logBreadCrumb({
          event: breadCrumbEvents.disputes.declineChargeBackButton
        });
      },
      color: 'primary'
    },
    initiateIssuedCardChargeback: {
      label: 'Escalate...',
      onClick: () => updateEvent({ IssuanceChargebackModalIsVisible: true, approve: false }),
      disabled: (status !== 'success' && Boolean(status)) || cardTransactionHasDisputes(),
      rightIcon: <i className="os-icon os-icon-corner-up-right" />
    },
    reescalateIssuedCardChargeback: {
      label: 'Re-escalate...',
      onClick: () => updateEvent({ IssuanceChargebackModalIsVisible: true, approve: false }),
      disabled: !chargebackCanBeReescalated,
      rightIcon: <i className="os-icon os-icon-corner-up-right" />
    }
  };

  const actionButtons: Array<ActionButtonType> = [
    ...(['payouts', 'pay-ins'].includes(transactionType) && status === 'success' ? [allActionBtns.viewReceipt] : []),
    ...(permissions?.refund && permissions?.refund === 'manage' && transactionType === 'pay-ins' && status === 'success'
      ? [allActionBtns.refund]
      : []),
    ...(permissions?.chargeback && permissions?.chargeback === 'manage' && transactionType === 'chargebacks' && status === 'pending'
      ? [allActionBtns.acceptChargebacks]
      : []),
    ...(permissions?.chargeback && permissions?.chargeback === 'manage' && transactionType === 'chargebacks' && status === 'pending'
      ? [allActionBtns.declineChargebacks]
      : []),
    ...(permissions?.chargeback &&
      permissions?.card_issuance_chargeback === 'manage' &&
      transactionType === 'card-transactions' &&
      txType === 'card_transaction'
      ? [allActionBtns.initiateIssuedCardChargeback]
      : []),
    ...(permissions?.chargeback &&
      permissions?.card_issuance_chargeback === 'manage' &&
      transactionType === 'issued-card-chargebacks' &&
      !['accepted', 'accepted_pre_arbitration', 'declined_pre_arbitration'].includes(status)
      ? [allActionBtns.reescalateIssuedCardChargeback]
      : [])
  ];

  const paymentTypesDetails: PaymentAmountDetailsType = {
    currency,

    ...(transactionType === 'pay-ins' && {
      ...(paymentSourceType === 'bank_transfer' && { amount_expected: `${formatAmount(amountCharged)} ${currency}` }),
      amount_charged: `${formatAmount(amountCharged)} ${currency}`,
      amount_paid: `${formatAmount(status === 'success' ? +amountCollected || +amountCharged : +amountCharged)} ${currency}`
    }),
    ...(meta?.payment_event_action?.event === 'overpayment'
      ? { overpayment: `${formatAmount(amountCollected - amountCharged)} ${currency}` }
      : {}),
    ...(meta?.payment_event_action?.event === 'underpayment'
      ? { underpayment: `${formatAmount(amountCollected - amountCharged)} ${currency}` }
      : {}),
    ...(meta?.additional_fees?.stamp_duty && { stamp_duty_fee: `${formatAmount(meta?.additional_fees?.stamp_duty)} ${currency}` }),
    fees: `${formatAmount(parseFloat(fee || '0') + parseFloat(vat || '0'))} ${currency}`,

    ...(korapayRef?.sentinal_transaction
      ? {
        tax: `${+korapayRef.sentinal_transaction.vat + +korapayRef.sentinal_transaction.processing_fee} ${currency}`,
        net_amount: `${formatAmount(
          netAmount - korapayRef.sentinal_transaction.vat - korapayRef.sentinal_transaction.processing_fee
        )} ${currency}`
      }
      : {
        net_amount: `${formatAmount(netAmount)} ${currency}`
      })
  };

  return {
    ...transactionUtilities,
    additionalInfoOptions,
    paymentChannelInfo,
    txBreakdownOptions,
    transactionBreakdown,
    moreDetailsOptions,
    morePaymentDetails,
    additionalInfo,
    prepareTxReceipt,
    allActionBtns,
    actionButtons,
    paymentTypesDetails
  };
};
