import React, { useEffect, useMemo, useState } from 'react';

import ListDropdown from '+dashboard/Shared/ListDropdown';
import { ForeignBankProps } from '+types/balances-types';
import { cleanInput, inputCharacterLength, stripNonNumeric } from '+utils';

import { bankListMapping, data, defaultForeignCountriesMap } from './ForeignWithdrawalRequestformData';

const ForeignWithdrawalRequestform: React.FC<ForeignBankProps> = ({
  requestFormProps,
  buttonType,
  countriesList,
  statesList,
  currency
}) => {
  const { values, errors, touched, getFieldProps = () => {}, setFieldValue = () => {}, handleBlur } = requestFormProps;

  const [isBankListOpen, setIsBankListOpen] = useState(false);

  const bankList = useMemo(() => {
    return bankListMapping[currency]?.map(bank => ({ code: bank, name: bank, status: 'active' }));
  }, [currency]);

  const getBankListValue = (value?: string) => {
    if (!value) return { code: '', name: '', status: 'active' };
    return bankList?.find(bank => bank.name === value);
  };

  useEffect(() => {
    setFieldValue('country', defaultForeignCountriesMap[currency] || '');
  }, [currency, setFieldValue]);

  return (
    <div className="form-wrapper">
      <div className="form-group mb-4">
        <label className="withdraw-label" htmlFor="recipientName">
          <span className="dark">Recipient&apos;s Name</span>
        </label>
        <div className="recipient-name">
          <input
            id="firstName"
            className="form-control"
            data-testid="firstName"
            type="text"
            name="first_name"
            placeholder="John"
            value={values?.first_name}
            onChange={e => setFieldValue('first_name', cleanInput(e.target.value))}
            onBlur={handleBlur}
          />
          <input
            id="lastName"
            className="form-control"
            data-testid="lastName"
            type="text"
            name="last_name"
            placeholder="Doe"
            value={values?.last_name}
            onChange={e => setFieldValue('last_name', cleanInput(e.target.value))}
            onBlur={handleBlur}
          />
        </div>
        <div className="recipient-name">
          <span id="firstName-error" data-feedback="invalid">
            {touched?.first_name && errors?.first_name}
          </span>
          <span id="lastName-error" data-feedback="invalid">
            {touched?.last_name && errors?.last_name}
          </span>
        </div>
        <div className="form-group mb-4">
          <label className="withdraw-label" htmlFor="accountNumber">
            <span className="dark">Account Number</span>
          </label>
          <input
            id="accountNumber"
            className="form-control"
            data-testid="accountNumber"
            type="number"
            name="account_number"
            placeholder="000123423429"
            value={values?.account_number}
            onChange={e => setFieldValue('account_number', stripNonNumeric(e.target.value))}
            onBlur={handleBlur}
            onKeyDown={e => inputCharacterLength(e, 12, values?.account_number)}
          />
          <span id="accountNumber-error" data-feedback="invalid">
            {touched?.account_number && errors?.account_number}
          </span>
        </div>
      </div>
      <div className="form-group mb-4">
        <label className="withdraw-label" htmlFor="paymentType">
          <span className="dark">Payment Type</span>
        </label>
        <select id="paymentMethod" className="form-control" aria-describedby="withdraw-form" {...getFieldProps('payment_method')}>
          <option value="">- Select a payment type -</option>
          {data
            ?.find(item => item.currency === 'USD')
            ?.paymentMethods.map(({ type, label }) => (
              <option key={type} value={label}>
                {label}
              </option>
            ))}
        </select>
        <span id="paymentMethod-error" data-feedback="invalid">
          {touched?.payment_method && errors?.payment_method}
        </span>
      </div>
      {buttonType === 'international' ? (
        <div className="form-group mb-4">
          <label className="withdraw-label" htmlFor="swiftCode">
            <span className="dark">SWIFT Code</span>
          </label>
          <input
            id="swiftCode"
            className="form-control"
            data-testid="swiftCode"
            type="text"
            name="swift_code"
            placeholder="bofaus3n"
            value={values?.swift_code}
            onChange={e => setFieldValue('swift_code', cleanInput(e.target.value))}
            onBlur={handleBlur}
            maxLength={11}
            onKeyDown={e => inputCharacterLength(e, 11, values?.swift_code)}
          />
          <span id="swiftCode-error" data-feedback="invalid">
            {touched?.swift_code && errors?.swift_code}
          </span>
        </div>
      ) : (
        <div className="form-group mb-4">
          <label className="withdraw-label" htmlFor="routingNumber">
            <span className="dark">Routing number</span>
          </label>
          <input
            id="routingNumber"
            className="form-control"
            data-testid="routingNumber"
            type="number"
            name="routing_number"
            placeholder="00956"
            value={values?.routing_number}
            onChange={e => setFieldValue('routing_number', stripNonNumeric(e.target.value))}
            onBlur={handleBlur}
            onKeyDown={e => inputCharacterLength(e, 9, values?.routing_number)}
          />
          <span id="routingNumber-error" data-feedback="invalid">
            {touched?.routing_number && errors?.routing_number}
          </span>
        </div>
      )}
      <div className="form-group reason-container">
        <label htmlFor="payout currency" className="withdraw-lable">
          <span className="dark form_label">Bank Name</span>
        </label>
        <ListDropdown
          list={bankList || []}
          type="bank"
          className="banks-list form-control"
          value={getBankListValue(values?.bank_name)}
          active={isBankListOpen}
          setActive={(open: boolean) => setIsBankListOpen(open)}
          setValue={(value: { code: number; name: string; status: string }) => {
            setFieldValue('bank_name', value.name);
          }}
        />
        <span id="bankName-error" data-feedback="invalid">
          {touched?.bank_name && errors?.bank_name}
        </span>
      </div>
      <div className="form-group mb-4">
        <label className="withdraw-label" htmlFor="country">
          <span className="dark">Country</span>
        </label>
        <select
          id="country"
          className="form-control"
          aria-describedby="country"
          {...getFieldProps('country')}
          value={getFieldProps('country').value}
          onChange={getFieldProps('country').onChange}
        >
          {countriesList?.map(({ value, label }) => (
            <option key={value} value={value}>
              {label}
            </option>
          ))}
        </select>
      </div>
      <div className="form-group mb-4">
        <label className="withdraw-label" htmlFor="state">
          <span className="dark">State</span>
        </label>
        <select id="state" className="form-control" aria-describedby="state" {...getFieldProps('state')}>
          <option value="">- Select a state -</option>
          {statesList?.[values?.country || '']?.map(({ name }) => (
            <option key={name} value={name}>
              {name}
            </option>
          ))}
        </select>
      </div>
      <div className="form-group mb-4">
        <label className="withdraw-label" htmlFor="city">
          <span className="dark"> City </span>
        </label>
        <input
          id="city"
          className="form-control"
          data-testid="city"
          type="text"
          name="city"
          placeholder="City"
          value={values?.city}
          onChange={e => setFieldValue('city', cleanInput(e.target.value))}
          onBlur={handleBlur}
        />
        <span id="country-error" data-feedback="invalid">
          {touched?.city && errors?.city}
        </span>
      </div>
      <div className="form-group mb-4">
        <label className="withdraw-label" htmlFor="recipientAddress">
          <span className="dark">Recipient&apos;s Address</span>
        </label>
        <input
          id="recipientAddress"
          className="form-control"
          data-testid="recipientAddress"
          type="text"
          name="recipient_address"
          placeholder="123 Street Ave"
          value={values?.recipient_address}
          onChange={e => setFieldValue('recipient_address', cleanInput(e.target.value))}
          onBlur={handleBlur}
        />
        <span id="recipientAdress-error" data-feedback="invalid">
          {touched?.recipient_address && errors?.recipient_address}
        </span>
      </div>
      <div className="form-group mb-4">
        <label className="withdraw-label" htmlFor="zipCode">
          <span className="dark">{currency === 'USD' ? 'Zip Code' : 'Postal Code'}</span>
        </label>
        <input
          id="zipCode"
          className="form-control"
          data-testid="zipcode"
          type="number"
          name="zip_code"
          placeholder="01234"
          value={values?.zip_code}
          onChange={e => setFieldValue('zip_code', stripNonNumeric(e.target.value))}
          onKeyDown={e => inputCharacterLength(e, 7, values?.zip_code)}
        />
      </div>
      <div className="form-group mb-4">
        <label className="withdraw-label" htmlFor="senderEmail">
          <span className="dark">Sender&apos;s Email Address</span>
        </label>
        <input
          id="emailAddress"
          className="form-control"
          data-testid="senderEmail"
          type="email"
          name="sender_email"
          placeholder="name@example.com"
          value={values?.sender_email}
          onChange={e => setFieldValue('sender_email', cleanInput(e.target.value))}
          onBlur={handleBlur}
        />
      </div>
    </div>
  );
};

export default ForeignWithdrawalRequestform;
