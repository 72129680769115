import React from 'react';

import { PermissionColumnType } from '+types';
import { capitalizeRemovedash } from '+utils';

import { serializeSelectedPermission } from './data';

import './index.scss';

const PermissionsTable: React.FC<{
  data: Record<string, Array<typeof PermissionColumnType>>;
  permissions?: Record<string, typeof PermissionColumnType>;
  onSelect?: (key: string, value: typeof PermissionColumnType, permissions: string[]) => void;
}> = ({ permissions, onSelect, data: PermissionsData }) => {
  return (
    <div className="team-permission-table-wrapper">
      <table>
        <thead>
          <tr>
            <th>Permissions</th>
            <th>View</th>
            <th>Export</th>
            <th>Manage</th>
          </tr>
        </thead>
        <tbody>
          {Object.keys(PermissionsData).map((title, index) => (
            <tr key={index}>
              <td>{capitalizeRemovedash(title)}</td>
              {['view', 'export', 'manage'].map((permission, permissionIndex) => (
                <td key={permissionIndex}>
                  {PermissionsData[title].includes(permission) ? (
                    <input
                      type="checkbox"
                      checked={serializeSelectedPermission(permissions?.[title]).includes(permission)}
                      onClick={() => {
                        onSelect?.(title, permission, PermissionsData[title]);
                      }}
                      className="permission-checkbox"
                    />
                  ) : null}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default PermissionsTable;
